import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;


@Component({
  selector: 'app-checklist-section-score-list',
  templateUrl: './checklist-section-score-list.component.html',
  styleUrls: ['./checklist-section-score-list.component.css']
})
export class ChecklistSectionScoreListComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }
  ngOnInit(): void {
    this.getReports()
  }

  getEndDate(data) {
    let value = data.replace(" days", "")
    return parseInt(value)
  }

  // assetDashboard
  pageSize = 10
  tableData
  activePage: any = 1
  getReports() {
    this.apiService.startLoader()
    let body =this.storedData.checklistSectionsScoreList.obj;
    body["limit"] = this.pageSize;
    body["pageNo"] = this.activePage;

    if (this.storedData.checklistSectionsScoreList['apiObjParam']) {
        body["endDate"] = this.storedData.checklistSectionsScoreList['apiObjParam']['endDate'],
        body["startDate"] = this.storedData.checklistSectionsScoreList['apiObjParam']['startDate']
    }
    if(this.storedData.checklistSectionsScoreList.api){
      this.apiService.postCall(this.storedData.checklistSectionsScoreList.api, {}, body).subscribe((result) => {
        this.apiService.stopLoader()
        this.tableData = result['data'];
        this.setPaginationArray(result)
      })
    }
  }
  viewDetail(api,Id){
    //console.log(Id);
  }
  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)
    return array
  }
  goBack(): void {
    this._location.back();
  }
  paginationArray = []
  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    // console.log(result)
    let noOfPAges = Math.ceil(result[this.storedData.checklistSectionsScoreList.rowCountParam] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets = []
      this.activePage = d
      // console.log(d)
      this.getReports()
    }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getReports()
  }



}
