import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-asset-parts-list',
  templateUrl: './asset-parts-list.component.html',
  styleUrls: ['./asset-parts-list.component.css']
})
export class AssetPartsListComponent implements OnInit {

  constructor(public _location: Location,public router:Router,public route:Router ,public apiService:ApiService,) { }
  assetId
  associatPartPermission=false
  notifyAddPermission=false
  pmPermission=false
  ngOnInit(): void {
    this.assetId = this.router.url.split("/")[2]
    this.getAssetDetail()
    this.getPropertyList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Associate Parts" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.associatPartPermission=true
      }
      if(permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.notifyAddPermission=true
      }
      if(permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.pmPermission=true
      }
    }


    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "statusNotIn":"5,8",
    }
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
     
      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
      // idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      // this.statusList.splice(idx1,1)
    })
  }

  viewPart(d){
    this.route.navigate(['/parts-details/'+d.partId])
  }
  tabSelected='allAssociated';
  selectedFiler={name:null , min_qty:null ,  property:null , }
  assetDetails
  getAssetDetail(){
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
      this.getPartsNoList();
      this.getPartsList();
      this.getRecentList();
    })
  }
  filterSelected={titel:null , partNo:null}

  applyFilter(){
    this.applyFlag=true;
    this.activePage= 1;
    this.getPartsList()
    this.getRecentList()
  }
  clear(){
    this.applyFlag= false;
    this.selectedFiler={name:null , min_qty:null ,  property:null , }

    let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
    this.selectedFiler.property = this.propertyList[idx] 
    }
    
    this.getPartsList()
  }

  sortSelected:any='name'
  sort:any='asc'

  sortData(data){
this.sortSelected = data
    if(this.sort == 'asc')this.sort = 'desc'
    else this.sort = 'asc'

    this.getPartsList()
  }
  
  getPartsList(){
    let body={
      limit:this.pageSize,
      pageNo:this.activePage,
      sortby:this.sortSelected,
      sortType:this.sort,
      name:this.selectedFiler.name,
      quentityInHand:this.selectedFiler.min_qty,
      propertyId:this.assetDetails.propertyId,
      number:this.selectedFiler.property ?this.selectedFiler.property.number : null,

    }
    this.apiService.startLoader()
    this.apiService.postCall('parts/index/'+this.apiService.buisnessId+'/'+this.assetId,{} ,body ).subscribe((result: any[])=>{
      // console.log(result);
      this.partsList=result['data']
      this.apiService.stopLoader()
      this.setPaginationArray(result)
      // this.assetDetails = result['assetDetails'];  
    
    })
  }
recentList=[]
  getRecentList(){
    let body={
      limit:this.pageSize,
      pageNo:this.activePage,
      sortby:this.sortSelected,
      sortType:this.sort,
      name:this.selectedFiler.name,
      quentityInHand:this.selectedFiler.min_qty,
      propertyId:this.assetDetails.propertyId,
      number:this.selectedFiler.property ?this.selectedFiler.property.number : null,

    }
    this.apiService.startLoader()
    this.apiService.postCall('parts/recentlyUsedParts/'+this.apiService.buisnessId+'/'+this.assetId,{} ,body ).subscribe((result: any[])=>{
      // console.log(result);
      this.recentList=result['data']
      this.apiService.stopLoader()
    })
  }
  paginationArray=[]
  partsList=[]
  // getFilterStatus(){}
  getPMsList(){}

  applyFlag
  // changePage(ev){}

  getVendorName(data){

    let str =''
    for (let d of data) str= (str!=''? str+', ' : '')+ d.name

    return str;
  }

  totalnoOfPages=0
  activePage=1
  pageSize=10
  setPaginationArray(result){
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] /this.pageSize)
    this.totalnoOfPages= noOfPAges;
    this.paginationArray=[]
    if(!this.totalnoOfPages){
      this.totalnoOfPages=0
    }
    if(noOfPAges <=5)for(let i =1 ; i<= noOfPAges ; i++)this.paginationArray.push(i)
    else if(4+this.activePage < this.totalnoOfPages) {
      for(let i =this.activePage ; i<= 4+this.activePage ; i++)this.paginationArray.push(i)
    }
    else if(4+this.activePage >= 6){

      let start = (4+this.activePage)- noOfPAges 
      for(let i =this.activePage-start ; i<= noOfPAges ; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pagination(d){
    // console.log(d)
    if(d != 0 && d <= this.totalnoOfPages && this.activePage != d){
    // this.selectedAssets=[]
    this.activePage= d
    // console.log(d)
    this.getPartsList()
    }
  }
  changePage(ev){
    // console.log(this.pageSize)
    // this.selectedAssets=[]
    // this.pageSize = d
    this.getPartsList()
  }


  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFilesAsset =file
        
       
  
      }
  
      let testData:FormData = new FormData();
    
     
  // // console.log(obj)
  testData.append('image', this.uploadedFilesAsset);
   
    // status:this.assetDetailsForm.value.status,
    
  
  
  // // console.log(obj)
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
    this.getAssetDetail()
    document.getElementById('dismissPopUp').click()
    
  })
  }
  
  
  changeStatus(){
    let testData:FormData = new FormData();
    
     
    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);
     
      testData.append("status", this.assetDetails.status);
      
    
    
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()
      
    })
  }
  statusList=[
   ]

   

  //  applyFlag
 getFilterStatus(){
  // // console.log(JSON.stringify(this.selectedFiler))
  // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
  // // console.log(length)
  let length=0;
  if(this.selectedFiler.name != null) length = length+1
  if(this.selectedFiler.property!= null) length = length+1
  if(this.selectedFiler.min_qty != null) length = length+1
  
  // for(let d of this.selectedFiler)
  // // console.log(length)
  if(length == 0 || !this.applyFlag) return "(None Selected)"
  else if(this.applyFlag) return `(${length} Filter Selected)`
}

propertFilter=false;
filterSelect=false;
@HostListener('click', ['$event.target'])
onClick(btn) {
  // console.log('button', btn.id, 'number of clicks:');
  if(btn.id != 'custom') {
    this.filterSelect= false
//     this.assetFilter= false;
// this.locationFilter= false;
// this.businessFilter= false;
this.propertFilter= false;
    // this.selectFreq = false
  }
}
closeAll(){
// this.assetFilter= false;
// this.locationFilter= false;
// this.businessFilter= false;
this.propertFilter= false;
}

  PartsNoList=[]
  filterPartsNoList=[]
  getPartsNoList(){
    this.apiService.startLoader()
    let obj={
      "businessId": this.apiService.buisnessId,
      "assetId":this.router.url.split("/")[2],
      "status":1,
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "propertyId": this.assetDetails.propertyId
    }
    this.apiService.postCall('parts/index' ,{},obj  ).subscribe((result: any[])=>{
      // console.log(result);
      this.PartsNoList=result['data']
      this.apiService.stopLoader()
      this.filterPartsNoList = this.PartsNoList;
    
    })
  }

propertyList=[]
filterpropertyList=[]

  getPropertyList(){
    this.apiService.startLoader()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} ,obj ).subscribe((result: any[])=>{
    // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
    
    this.propertyList = result['data']
    this.filterpropertyList = result['data']
    let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
    // this.selectedFiler.property = this.propertyList[idx] 
    // }
    
})



  }
  saveData = false;
  message:any;
  removeAssociation(partId:any){
    let body = {
      partId:partId,
      assetId:this.assetId
    }
    this.apiService.startLoader()
    this.apiService.postCall('parts-assets/deletePartAsset',{} ,body ).subscribe((result: any[])=>{
      this.apiService.stopLoader()
      this.saveData = result['success'];
      this.message =  result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccessArchive')).click();
    });
  }


  // filterpropertyList=[]
  filterPropertySearch
// propertFilter= false;
filterProperty(ev){
  // console.log(ev)
  this.PartsNoList = this.filterPartsNoList.filter((item) => {
    if (item.number != null) {
      return item.number.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
    } } )
}
  }
  
  
  
