import { Component, OnInit } from '@angular/core';

// import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;
@Component({
  selector: 'app-d3-plots',
  templateUrl: './d3-plots.component.html',
  styleUrls: ['./d3-plots.component.css']
})
export class D3PlotsComponent implements OnInit {

  constructor(public apiService: ApiService,) { }


  historyList=[]
  getHistoryList(){
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
  this.apiService.getCall('meter-historys/history-list/'+33,{}  ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.historyList = result['data']['data'];
  // this.meterDetails=  result['meter']

  // this.singleBarPlot()
  this.singleBarPlotCurce()
 
  } )

  }
depreciationData=[]
  getDepreciation(){
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
  this.apiService.getCall('asset-depreciations/view/'+265,{}  ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.depreciationData = result['depreciationYears'];
  // this.meterDetails=  result['meter']

  this.singleBarPlot()
  // this.singleBarPlotCurce()
  })
  }

  ngOnInit(): void {
this.getPieChart()
  // this.getHistoryList()

  // this.getDepreciation()
  this.simpleBarchart()
  // this.groupedBarchart()
  // this.linecurveChart()
  }




  singleBarPlot(){

    // console.log(this.depreciationData)
      
    var data=[]
    // this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('YYYY-MM-DD HH:MM:SS'))
    
          for(let i=0; i< this.depreciationData.length ; i++)data.push({ ser2 : this.depreciationData[i].value , ser1:d3.timeParse("%Y")(this.depreciationData[i].year)})
// console.log(data)
    var margin = {top: 10, right: 30, bottom: 30, left: 50},
    width = 460 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;
 
   //  var parseDate = d3.time.format("%Y-%m-%d %X");
   var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;

   data.forEach(function(d) {
     //// console.log(d.date_time)
     // d.ser1 = moment(d.ser1).format('YYYY-MM-DD HH:MM:SS');
 
   });

    var svg = d3.select("#chart")
   .append("svg")
     .attr("width", width + margin.left + margin.right)
     .attr("height", height + margin.top + margin.bottom)
   .append("g")
     .attr("transform",
           "translate(" + margin.left + "," + margin.top + ")");
 

 
svg.append("text")
.attr("class", "x label")
.attr("text-anchor", "end")
.attr("x", width -50 )
.attr("y", height + 30)
.text("Time Chart");


svg.append("text")
.attr("class", "y_label cart-title")
.attr("text-anchor", "end")
.attr("x", -86)
.attr("y", -46)
.attr("dy", ".75em")
.attr("transform", "rotate(-90)")
.text("Litres");
svg.append("g")
.attr("transform", "translate(0," + height + ")")
.attr("class","myXaxis")

var area = d3.area()
.curve(d3.curveBasis)
.x(function(d) { return x(d.ser1); })
.y0(height)
.y1(function(d) { return y(d.ser2); });
     
///////////////////////////////////////////////////////////
 // Initialise a X axis:
 var x = d3.scaleTime()
     .domain(d3.extent(data, function(d) { return d.ser1; }))
     .range([ 0, width ]);
     
   svg.append("g")
     .attr("transform", "translate(0," + height + ")")
     .call(d3.axisBottom(x))
 .attr("class","myXaxis");

 //   // Add Y axis
   var y = d3.scaleLinear()
     .domain([0, d3.max(data, function(d) { return +d.ser2; })])
     .range([ height, 0 ]);
   svg.append("g")
     .call(d3.axisLeft(y))
     .attr("class","myYaxis");

/////////////////////////////////

//////////////////////////////////////////////////////

   // Add the line
   svg.append("path")
     .datum(data)
     .attr("fill", "none")
     .attr("stroke", "steelblue")
     .attr("stroke-width", 4.5)
     .attr("class","lineTest")
     .attr("d", d3.line()
     .curve(d3.curveBasis)
       .x(function(d) { return x(d.ser1) })
       .y(function(d) { return y(d.ser2) })
       )

 
     svg.append("path")
     .data([data])
     .attr("class", "myArea")
     .attr("d", area);



     d3.selectAll("g.myYaxis g.tick")
   .append("line")
   .attr("class", "gridline")
   .attr("x1", 0)
   .attr("y1", 0)
   .attr("x2", width)
   .attr("y2", 0);

d3.selectAll("g.myXaxis g.tick")
   .append("line")
   .attr("class", "gridline")
   .attr("x1", 0)
   .attr("y1", -height)
   .attr("x2", 0)
   .attr("y2", 0);

 
 
    }

    // dueDate:this.selectedFiler.dueDate ?moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:MM:SS') :null,

    singleBarPlotCurce(){

      // console.log(this.historyList)
      
var data=[]
this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('YYYY-MM-DD HH:MM:SS'))
// console.log(this.historyList)
      for(let i=0; i< this.historyList.length ; i++)data.push({ ser2 : this.historyList[i].lastReading , ser1:d3.timeParse("%Y-%m-%d %H:%M:%S")(this.historyList[i].createdAt)})
    //   var data = [
    //     {ser1: 0.3, ser2: 4},
    //     {ser1: 2, ser2: 16},
    //     {ser1: 3, ser2: 8}
    //  ];
    // console.log(data)

    
     var margin = {top: 10, right: 30, bottom: 30, left: 50},
     width = 460 - margin.left - margin.right,
     height = 400 - margin.top - margin.bottom;
  
    //  var parseDate = d3.time.format("%Y-%m-%d %X");
    var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;

    data.forEach(function(d) {
      //// console.log(d.date_time)
      // d.ser1 = moment(d.ser1).format('YYYY-MM-DD HH:MM:SS');
  
    });

     var svg = d3.select("#chart2")
    .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
    .append("g")
      .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");
  

  
svg.append("text")
.attr("class", "x label")
.attr("text-anchor", "end")
.attr("x", width -50 )
.attr("y", height + 30)
.text("Time Chart");


svg.append("text")
.attr("class", "y label")
.attr("text-anchor", "end")
.attr("x", -86)
.attr("y", -46)
.attr("dy", ".75em")
.attr("transform", "rotate(-90)")
.text("Litres");
svg.append("g")
.attr("transform", "translate(0," + height + ")")
.attr("class","myXaxis")

var area = d3.area()
.curve(d3.curveBasis)
.x(function(d) { return x(d.ser1); })
.y0(height)
.y1(function(d) { return y(d.ser2); });
      
///////////////////////////////////////////////////////////
  // Initialise a X axis:
  var x = d3.scaleTime()
      .domain(d3.extent(data, function(d) { return d.ser1; }))
      .range([ 0, width ]);
      
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
  .attr("class","myXaxis");

  //   // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function(d) { return +d.ser2; })])
      .range([ height, 0 ]);
    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class","myYaxis");

/////////////////////////////////

//////////////////////////////////////////////////////

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4.5)
      .attr("class","lineTest")
      .attr("d", d3.line()
      .curve(d3.curveBasis)
        .x(function(d) { return x(d.ser1) })
        .y(function(d) { return y(d.ser2) })
        )

  
      svg.append("path")
      .data([data])
      .attr("class", "myArea")
      .attr("d", area);

 

      d3.selectAll("g.myYaxis g.tick")
    .append("line")
    .attr("class", "gridline")
    .attr("x1", 0)
    .attr("y1", 0)
    .attr("x2", width)
    .attr("y2", 0);

d3.selectAll("g.myXaxis g.tick")
    .append("line")
    .attr("class", "gridline")
    .attr("x1", 0)
    .attr("y1", -height)
    .attr("x2", 0)
    .attr("y2", 0);

  
      }
    



      
  getTooltipData(data) {

    // let date = new Date(data.group)
    // console.log(data)
    let obj 
    obj ='<div style="display: inline-flex;width:100%"> <div>' + 'Total' + '</div>: <div style="text-align:right;margin-inline-start: auto;"> ' + data['ser1'] + data['ser2'] + '</div> </div> '
   
    var retirnObj: any = obj.toString()
    // console.log(retirnObj)
    return (retirnObj.replaceAll(",", "&nbsp"))
  }





  simpleBarchart(){
var data=[ {name:"Bob",value:33}, {name:"Robin",value:12}, {name:"Anne",value:41}, {name:"Mark",value:16}, {name:"Joe",value:59}, {name:"Eve",value:38}, {name:"Karen",value:21}, {name:"Kirsty",value:25}, {name:"Chris",value:30}, {name:"Lisa",value:47}, {name:"Tom",value:5}, {name:"Stacy",value:20}, {name:"Charles",value:13}, {name:"Mary",value:29}, 
{name:"1Bob",value:33}, {name:"1Robin",value:12}, {name:"1Anne",value:41}, {name:"1Mark",value:16}, {name:"1Joe",value:59}, {name:"1Eve",value:38}, {name:"1Karen",value:21}, {name:"1Kirsty",value:25}, {name:"1Chris",value:30}, {name:"1Lisa",value:47}, {name:"1Tom",value:5}, {name:"1Stacy",value:20}, {name:"1Charles",value:13}, {name:"1Mary",value:29}, 
{name:"2Bob",value:33}, {name:"2Robin",value:12}, {name:"2Anne",value:41}, {name:"2Mark",value:16}, {name:"2Joe",value:59}, {name:"2Eve",value:38}, {name:"2Karen",value:21}, {name:"2Kirsty",value:25}, {name:"2Chris",value:30}, {name:"2Lisa",value:47}, {name:"2Tom",value:5}, {name:"2Stacy",value:20}, {name:"2Charles",value:13}, {name:"2Mary",value:29}, 




]
    // set the dimensions and margins of the graph
var margin = {top: 20, right: 20, bottom: 30, left: 40},
width = (960*data.length)/14,
height = 500 - margin.top - margin.bottom;

// set the ranges
var x = d3.scaleBand()
      .range([0, width])
      .padding(0.1);
      
var y = d3.scaleLinear()
      .range([height, 0]);
      


      
// append the svg object to the body of the page
// append a 'group' element to 'svg'
// moves the 'group' element to the top left margin

var svgAxis = d3.select("#barChartYAxis").append("svg")
.attr("width", 50)
.attr("height", height + margin.top + margin.bottom)
.append("g")
.attr("transform", 
      "translate(" + margin.left + "," + margin.top + ")");
  svgAxis.append("g")
  .attr("class","myYaxis")
  .call(d3.axisLeft(y));

  

var svg = d3.select("#barChart").append("svg")
.attr("width", width)
.attr("height", height + margin.top + margin.bottom)
.append("g")
.attr("transform", 
      "translate(" + 0 + "," + margin.top + ")");

// get the data


// format the data

// Scale the range of the data in the domains

x.domain(data.map(function(d) { return d.name;1 }));
y.domain([0,100]);

// append the rectangles for the bar chart
svg.selectAll(".bar")
  .data(data)
.enter().append("rect")
  .attr("class", "bar")
  .attr("x", function(d) { 
    
    return x(d.name) +15 ;
  
  })
  .attr("width", '20px')
  .attr("y", function(d) { return y(d.value); })
  .attr("height", function(d) { return height - y(d.value); });

// add the x Axis
svg.append("g")
  .attr("transform", "translate(0," + height + ")")
  .attr("class","myXaxis")
  .call(d3.axisBottom(x));

// add the y Axis
// svg.append("g")
//   .call(d3.axisLeft(y));

  }
  


  groupedBarchart(){
    let data=[{group: '01/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '02/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '03/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '04/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '05/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '06/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '07/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '08/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '09/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '10/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    
    
    {group: '11/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '12/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '13/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '14/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '15/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '16/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '17/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '18/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '19/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
  
    {group: '21/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '22/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '23/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '24/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '25/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '26/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '27/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '28/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '29/12/2021', Nitrogen: '19', normal: '6', stress: '1'},


    
    {group: '31/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '32/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '33/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '34/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '35/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '36/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '37/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '38/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '39/12/2021', Nitrogen: '19', normal: '6', stress: '1'},

    {group: '41/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '42/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '43/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '44/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '45/12/2021', Nitrogen: '12', normal: '1', stress: '13'},
    {group: '46/12/2021', Nitrogen: '6', normal: '6', stress: '33'},
    {group: '47/12/2021', Nitrogen: '11', normal: '28', stress: '12'},
    {group: '48/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
    {group: '49/12/2021', Nitrogen: '19', normal: '6', stress: '1'},
  
  
  ]

  var margin = {top: 10, right: 30, bottom: 20, left: 50},
  width =(1200*data.length)/10,
  height = 400 - margin.top - margin.bottom;

  
// append the svg object to the body of the page
var svgAxis = d3.select("#groupedChartYAxis")
.append("svg")
  .attr("width", 50 )
  .attr("height", height + margin.top + margin.bottom)
.append("g")
  .attr("transform",
        "translate(" + 40 + "," + margin.top + ")");
// append the svg object to the body of the page
var svg = d3.select("#groupedChart")
.append("svg")
  .attr("width", width + margin.left + margin.right)
  .attr("height", height + margin.top + margin.bottom)
.append("g")
  .attr("transform",
        "translate(" + 0 + "," + margin.top + ")");

// Parse the Data



// List of subgroups = header of the csv files = soil condition here
var subgroups = ['Nitrogen' , 'normal' ,'stress' ]

var groups = []
for(let i of data)groups.push(i.group)
// List of groups = species here = value of the first column called group -> I show them on the X axis



// console.log('subgroups',subgroups)
// console.log('groups',groups)
// console.log(data)
// Add X axis
var x = d3.scaleBand()
    .domain(groups)
    .range([0, width])
    .padding([0.2])
svg.append("g")
  .attr("transform", "translate(0," + height + ")")
  .call(d3.axisBottom(x).tickSize(0));

// Add Y axis
var y = d3.scaleLinear()
  .domain([0, 40])
  .range([ height, 0 ]);
svg.append("g")
  // .call(d3.axisLeft(y));

  svgAxis.append("g")
  .call(d3.axisLeft(y));


// Another scale for subgroup position?
var xSubgroup = d3.scaleBand()
  .domain(subgroups)
  .range([0, x.bandwidth()])
  .padding([0.05])

// color palette = one color per subgroup
var color = d3.scaleOrdinal()
  .domain(subgroups)
  .range(['#e41a1c','#377eb8','#4daf4a'])

// Show the bars
svg.append("g")
  .selectAll("g")
  // Enter in data = loop group per group
  .data(data)
  .enter()
  .append("g")
    .attr("transform", function(d) { return "translate(" + x(d.group) + ",0)"; })
  .selectAll("rect")
  .data(function(d) { return subgroups.map(function(key) { return {key: key, value: d[key]}; }); })
  .enter().append("rect")
    .attr("x", function(d) { return xSubgroup(d.key); })
    .attr("y", function(d) { return y(d.value); })
    .attr("width", 30)
    .attr("height", function(d) { return height - y(d.value); })
    .attr("fill", function(d) { return color(d.key); });




    }



    linecurveChart(){
    let data=  [ {name:"Bob",value:33}, {name:"Robin",value:12}, {name:"Anne",value:41}, {name:"Mark",value:16}, {name:"Joe",value:59}, {name:"Eve",value:38}, {name:"Karen",value:21}, {name:"Kirsty",value:25}, {name:"Chris",value:30}, {name:"Lisa",value:47}, {name:"Tom",value:5}, {name:"Stacy",value:20}, {name:"Charles",value:13}, {name:"Mary",value:29}, ]
    let data1= [ {name:"Bob",value:23}, {name:"Robin",value:15}, {name:"Anne",value:31}, {name:"Mark",value:26}, {name:"Joe",value:17}, {name:"Eve",value:38}, {name:"Karen",value:42}, {name:"Kirsty",value:22}, {name:"Chris",value:35}, {name:"Lisa",value:33}, {name:"Tom",value:9}, {name:"Stacy",value:12}, {name:"Charles",value:17}, {name:"Mary",value:25}, ]
          var margin = {top: 10, right: 30, bottom: 30, left: 50},
          width =(1200*data.length)/10,
          height = 400 - margin.top - margin.bottom;
       
         //  var parseDate = d3.time.format("%Y-%m-%d %X");
         var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;
      
        
          var svg = d3.select("#chart")
         .append("svg")
           .attr("width", width + margin.left + margin.right)
           .attr("height", height + margin.top + margin.bottom)
         .append("g")
           .attr("transform",
                 "translate(" + margin.left + "," + margin.top + ")");
       
      
       
      svg.append("text")
      .attr("class", "x label")
      .attr("text-anchor", "end")
      .attr("x", width -50 )
      .attr("y", height + 30)
      .text("Time Chart");
      
      
      svg.append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("x", -86)
      .attr("y", -46)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("Litres");
      svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class","myXaxis")
      
     
           
      ///////////////////////////////////////////////////////////
       // Initialise a X axis:
       var x = d3.scaleBand()
       .domain(data.map(function(d) { return d.name;1 }))
       .range([0, width])
       .padding(0.1);
       
 var y = d3.scaleLinear()
 .domain([0, d3.max(data, function(d) { return d.value; })])
       .range([height, 0]);


         svg.append("g")
           .attr("transform", "translate(0," + height + ")")
           .call(d3.axisBottom(x))
       .attr("class","myXaxis");
      
       //   // Add Y axis
        
         svg.append("g")
           .call(d3.axisLeft(y))
           .attr("class","myYaxis");
      
      /////////////////////////////////
      
      //////////////////////////////////////////////////////
      
         // Add the line
         svg.append("path")
           .datum(data)
           .attr("fill", "none")
           .attr("stroke", "steelblue")
           .attr("stroke-width", 4.5)
           .attr("class","lineTestGreen")
           .attr("d", d3.line()
           .curve(d3.curveBasis)
             .x(function(d) { return x(d.name) })
             .y(function(d) { return y(d.value) })
             )
      
          svg.append("path")
           .datum(data1)
           .attr("fill", "none")
           .attr("stroke", "#6FCF97")
           .attr("stroke-width", 4.5)
           .attr("class","lineTest")
           .attr("d", d3.line()
           
             .x(function(d) { return x(d.name) })
             .y(function(d) { return y(d.value) })
             )
            
       
           d3.selectAll("g.myYaxis g.tick")
         .append("line")
         .attr("class", "gridline")
         .attr("x1", 0)
         .attr("y1", 0)
         .attr("x2", width)
         .attr("y2", 0);
      
         d3.selectAll("g.myXaxis g.tick")
         .append("line")
         .attr("class", "gridline")
         .attr("x1", 0)
         .attr("y1", -height)
         .attr("x2", 0)
         .attr("y2", 0);
      
    }
getPieChart(){
let obj={
  invoiceCost: 2.5,
invoiceCostTotalInr: 17500,
laborCost: "3.5 Hours",
laborCostTotalInr: 1300,
purchasePrice: "100000",
totalPartInr: "15422",
totalParts: "41",
}

  var data = [parseInt(obj.totalPartInr), obj.laborCostTotalInr, obj.invoiceCostTotalInr , parseInt(obj.purchasePrice)];

var width = 960,
    height = 500,
    radius = Math.min(width, height) / 2;

var color = d3.scaleOrdinal()
    .range(["#7879f1", "#33CCB2", "#f6f6f6" , "#a5a6f6"]);

var arc = d3.arc()
    .outerRadius(radius - 10)
    .innerRadius(0);

var labelArc = d3.arc()
    .outerRadius(radius - 40)
    .innerRadius(radius - 40);

var pie = d3.pie()
    .sort(null)
    .value(function(d) { return d; });

var svg = d3.select("#piechart").append("svg")
    .attr("width", width)
    .attr("height", height)
  .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  var g = svg.selectAll(".arc")
      .data(pie(data))
    .enter().append("g")
      .attr("class", "arc");

  g.append("path")
      .attr("d", arc)
      .style("fill", function(d) { return color(d.data); });

  g.append("text")
      .attr("transform", function(d) { return "translate(" + labelArc.centroid(d) + ")"; })
      .attr("dy", ".35em")
      .text(function(d) { return d.data; });
}  
}
