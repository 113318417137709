import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { StoredData } from '../common/services/storedData.service';
@Component({
  selector: 'app-meter-details',
  templateUrl: './meter-details.component.html',
  styleUrls: ['./meter-details.component.css']
})
export class MeterDetailsComponent implements OnInit {

  constructor(public _location: Location,public route: Router,public router:Router ,public apiService:ApiService,public storedData:StoredData) { }

  meterId:any;
  editMeterPermission=false
  printBarcodeMeterPermission=false
  readingPermission=false;
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {
    this.meterId = this.router.url.split("/")[2]
    this.getAssetDetail()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==6 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editMeterPermission=true
      }
      if(permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "Print QR Code" && permission[i]['actionStatus'] == 1){
        this.printBarcodeMeterPermission=true
      }
      if(permission[i]['subModuleName'] == "Add Reading" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.readingPermission=true
      }
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }


  meterDetails
  userAssign
  getAssetDetail(){
    this.apiService.startLoader()
    this.apiService.getCall('meters/view/'+this.meterId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.meterDetails = result['data'];
      this.userAssign = this.meterDetails.userId.split(',');
    this.getUserList(this.meterDetails.outletId)
    })
  }

  message;
  updateData(ev){
      
    this.message =ev;
     
       
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
    this.getAssetDetail()
  }
  getDiffrence(){
    var startTime = new Date(this.meterDetails?.readingDate); 
var endTime = new Date();
var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
var resultInMinutes = Math.round(difference / 60000);

var hours = Math.trunc(resultInMinutes/60);
var days = Math.trunc(hours/24);
if (days>=1) return "("+ days+ " days ago )"
else if(hours >=1)return "("+ hours+ " hours ago )" 
else if(resultInMinutes>=1 )return "("+ resultInMinutes+ " minutes ago )"
else if(this.meterDetails?.reading == null) return ""
else return "("+ "Updated few seconds before )"

  }


  getFrequencyName(){
    if(this.meterDetails?.frequency == null) return 'Set a frequency'
    else if(this.meterDetails?.frequency == 'Custom') return 'Every '+(this.meterDetails.frequency_no?this.meterDetails.frequency_no:'') +" "+ (this.meterDetails.frequency_unit?this.meterDetails.frequency_unit:'')
    else return this.meterDetails?.frequency
  }

  userList=[]
  readingTerm:any;
  getUserList(id){
    this.apiService.startLoader()
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":id,
    }
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
  this.apiService.postCall('users/user-list/'+this.apiService.buisnessId+'/'+id,{}  , obj).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.userList = result['data']
 
  } )}

  getTextAssignedto(data){
  
    let str=""
    for(let d of this.userAssign){
if(this.userList[0]){
      let idx= this.userList.findIndex(x=> x.id == parseInt(d))
      // // console.log(idx)
      if(idx != -1)str= str != ''?',':'' + this.userList[idx].fullName 
    }
    
  }
  return str
    // // console.log(data)
    
    
  }


  addReading() {
    
    if(!this.readingTerm){
      return false;
    }
    let obj = {
    
      lastReading:this.readingTerm,
      authId:this.apiService.userId,
      
    }
// console.log(obj)
this.apiService.startLoader()
    this.apiService.postCall('meters/add-reading/'+this.meterId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.readingTerm= undefined;
      this.apiService.stopLoader()
     this.getAssetDetail()
      
    })
  
}
uploadedFiles


openCropperEvent=false;
openCropper(){

  // console.log("assd");
  (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
  this.openCropperEvent= !this.openCropperEvent;
}


fileChange(event) {
  // console.log(event)
  // let fileList: FileList = event.target.files;
  // if(fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFiles =file
      
     

  //   }
  // this.openCropperEvent=false;

  this.uploadedFiles = event;
    let testData:FormData = new FormData();
    testData.append('image', this.uploadedFiles);
    testData.append('meterName', this.meterDetails.meterName);
    testData.append('unitId', this.meterDetails.unitId);
    testData.append('businessId', this.meterDetails.businessId);
    testData.append('frequency', this.meterDetails.frequency);
    testData.append('assetManagementId', this.meterDetails.assetManagementId);
    testData.append('outletId', this.meterDetails.outletId);
    testData.append('locationId', this.meterDetails.locationId);
    testData.append('userIds', this.meterDetails.userId);

    testData.append('userNames', this.meterDetails.userName);
    testData.append('authId', "1");
   
// // console.log(obj)
this.apiService.startLoader()
    this.apiService.postCall('meters/edit/'+this.meterId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
     
      this.getAssetDetail()
    })
}

openQr(){
  this.storedData.qrData={name:this.meterDetails.meterName,image : this.meterDetails.meterQrCode,customerComplaintName:"" }
  this.router.navigate(['print-qr'])
}
omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

}