import { Component, HostListener, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import SignaturePad from 'signature_pad';


//https://stackblitz.com/github/ramsatt/Angular9SignaturePad?file=src%2Fapp%2F_componets%2Fsignature-pad%2Fsignature-pad.component.ts
@Component({
  selector: 'app-feedback-add',
  templateUrl: './feedback-add.component.html',
  styleUrls: ['./feedback-add.component.css']
})

export class FeedbackAddComponent implements OnInit {
  @ViewChild('sPad', { static: true }) canvasElement;

  constructor(
    public router: Router,
    public apiService: ApiService,
    public _location: Location,
    public formBuilder: FormBuilder
  ) { }

  urlData = '';
  feedbackdetails: any;

  siglesQuestionResponce = { feedback: null,responseTypeSignatureSave:null,responseTypeTextSave:null }
  ageList = [{ id: 'Below 20', name: 'Below 20' }, { id: '21 To 30', name: '21 To 30' }, { id: '31 To 40', name: '31 To 40' }, { id: '41 To 50', name: '41 To 50' }, { id: '50 and Above', name: '50 and Above' }]  //{ id: 'Other', name: 'Other' }
  occupationList = [{ id: 'Self Employed', name: 'Self Employed' }, { id: 'Salaried', name: 'Salaried' }, { id: 'Business', name: 'Business' }, { id: 'Student', name: 'Student' }, { id: 'Other', name: 'Other' }]
  addressList = [{ id: 'Vasai (East)', name: 'Vasai (East)' }, { id: 'Vasai (West)', name: 'Vasai (West)' }, { id: 'Nalasopara(East)', name: 'Nalasopara(East)' }, { id: 'Nalasopara(West)', name: 'Nalasopara(West)' }, { id: 'Virar (East)', name: 'Virar (East)' }, { id: 'Virar (West)', name: 'Virar (West)' }, { id: 'Naigaon & Beyond', name: 'Naigaon & Beyond' }, { id: 'Other', name: 'Other' }]
  surveyId
  feedbackForm: FormGroup
  disableSubmit: boolean
  buisnessId = 0;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    // console.log("feedbackUrl :",this.router.url.split("/")[2])
    // var decodedString = atob(this.router.url.split("/")[2]);
    var decodedString = decodeURIComponent(this.router.url.split("/")[2]);
    decodedString = atob(decodedString);
    if (decodedString) {
      var l = decodedString.split('-')[0];
      this.buisnessId = parseInt(l);
    }
    // console.log(this.buisnessId,decodedString);
    if (this.buisnessId == 68) {
      this.feedbackForm = this.formBuilder.group({
        userName: [null, Validators.required],
        email: [null, Validators.required],
        mobile: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        comment: [null],
        feedback: [null],
        authId: this.apiService.authId,
        feedbackQuestionId: [null],
        patientName: [null],
        roomNo: [null],
        companyName: [null],
        floorNo: [null],
        age: [null],
        ageOptions: [null],
        occupation: [null],
        occupationOptions: [null],
        address: [null],
        addressOptions: [null],
        userLocation: [null],
        groups: [this.formBuilder.array([this.initGroups(null)])]
      });
    } else if (this.buisnessId == 80) {
      this.feedbackForm = this.formBuilder.group({
        userName: [null, Validators.required],
        email: [null, Validators.required],
        mobile: [null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        comment: [null],
        feedback: [null],
        authId: this.apiService.authId,
        feedbackQuestionId: [null],
        patientName: [null],
        roomNo: [null],
        companyName: [null],
        floorNo: [null],
        age: [null, Validators.required],
        ageOptions: [null],
        occupation: [null, Validators.required],
        occupationOptions: [null],
        address: [null, Validators.required],
        addressOptions: [null],
        userLocation:[null],
        groups: [this.formBuilder.array([this.initGroups(null)])]
      });
    } else {
      this.feedbackForm = this.formBuilder.group({
        userName: [null, Validators.required],
        email: [null],
        mobile: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        comment: [null],
        feedback: [null],
        authId: this.apiService.authId,
        feedbackQuestionId: [null],
        patientName: [null],
        roomNo: [null],
        companyName: [null],
        floorNo: [null],
        age: [null],
        ageOptions: [null],
        occupation: [null],
        occupationOptions: [null],
        address: [null],
        addressOptions: [null],
        userLocation:[null],
        groups: [this.formBuilder.array([this.initGroups(null)])]
      });
    }
    this.getfeedbackData();

    if (this.router.url.split("/")[1] == 'customer-feedback-view') {
      this.disableSubmit = true
    } else {
      this.disableSubmit = false
    }
  }
  signaturePad: any;
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.canvasElement.nativeElement);
  }
  getAge() {
    if (this.feedbackForm.value.ageOptions != '' && this.feedbackForm.value.ageOptions != 'Other') {
      this.feedbackForm.value.age = this.feedbackForm.value.ageOptions;
    } else {
      this.feedbackForm.value.age = '';
    }
  }
  getOccupation() {
    if (this.feedbackForm.value.occupationOptions != '' && this.feedbackForm.value.occupationOptions != 'Other') {
      this.feedbackForm.value.occupation = this.feedbackForm.value.occupationOptions;
    } else {
      this.feedbackForm.value.occupation = '';
    }
  }
  getAddress() {
    if (this.feedbackForm.value.addressOptions != '' && this.feedbackForm.value.addressOptions != 'Other') {
      this.feedbackForm.value.address = this.feedbackForm.value.addressOptions;
    } else {
      this.feedbackForm.value.address = '';
    }
  }
  clear() {
    this.signaturePad.clear();
    this.signDynamicSet = [];
  }
  checkAPICall() {
    // // console.log(this.apiService.loader)
    return this.apiService.loader;
  }
  getValidation() {
    if (this.disableSubmit) {
      return false;
    }
    // if(!this.feedbackForm.value.userName || !this.feedbackForm.value.mobile){
    //   return false;
    // }
    if (this.buisnessId == 80) {
      if (!this.feedbackForm.value.userName) {
        return false;
      }
    } else {
      if (!this.feedbackForm.value.userName) {
        return false;
      }
    }

    /*ar emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(!emailPattern.test(this.feedbackForm.value.email)){
      return false; 
    }*/
    // console.log(this.feedbackForm.value.mobile.search('_') )
    // return this.feedbackForm.valid && this.feedbackForm.value.mobile.search('_') == -1
    if (this.buisnessId == 80) {
      return this.feedbackForm.valid && this.feedbackForm.value.mobile && this.feedbackForm.value.mobile != "" && this.feedbackForm.value.email && this.feedbackForm.value.email != "" && this.feedbackForm.value.ageOptions && this.feedbackForm.value.ageOptions != 'null' && this.feedbackForm.value.occupationOptions && this.feedbackForm.value.occupationOptions != 'null' && this.feedbackForm.value.addressOptions && this.feedbackForm.value.addressOptions != 'null'
    }
    if (this.buisnessId == 68) {
      return this.feedbackForm.valid && this.feedbackForm.value.mobile && this.feedbackForm.value.mobile != "" && this.feedbackForm.value.email && this.feedbackForm.value.email != ""
    }
    return this.feedbackForm.valid
  }

  groupsArray = []

  feedBackId
  propertyId
  getfeedbackData(): void {
    let id = this.router.url.split("/")[2];
    this.feedBackId = decodeURIComponent(id);
    let propertyIdUrl = atob(this.feedBackId);
    this.propertyId = propertyIdUrl.split("-")[1];
    // console.log(this.propertyId);
    // feedbacks/feedback/MjYtNzEtNDg=
    this.apiService.startLoader()
    this.apiService.getCall('feedbackQuestions/view/' + id, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.feedbackdetails = result['data'];
        this.feedbackForm = this.formBuilder.group({
          userName: '',
          email: [null],
          mobile: [null],
          comment: [null],
          feedback: [null],
          patientName: [null],
          roomNo: [null],
          floorNo: [null],
          age: '',
          ageOptions: [null],
          occupation: '',
          occupationOptions: [null],
          address: [null],
          addressOptions: [null],
          companyName: [null],
          userLocation:[null],
          authId: this.apiService.authId,
          feedbackQuestionId: this.feedbackdetails.id,
          type: this.feedbackdetails.type ? 1 : 0
          // groups: this.formBuilder.array([this.initGroups(null)])
        });
        this.surveyId = result['data'].id
        // for(let d of result['data'].groups){
        //   this.addMoreGroups(d); 
        // }

        this.groupsArray = result['data'].groups
        for (let d of this.groupsArray) {

          if (d.questions[0]) d.questions.map(x => x.feedback = null)
            if (d.questions[0]) d.questions.map(x => x.responseTypeSignatureSave = null)
              if (d.questions[0]) d.questions.map(x => x.responseTypeTextSave = null)
        }

        // console.log(this.feedbackForm)
      } else {
        this.goBack();
      }
    })
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  initGroups(data: any) {
    if (data != null) {
      return this.formBuilder.group({
        groupId: data.feedbackQuestionId,
        groupName: data.groupName,
        questions: new FormArray([
          this.initQuestions(data.questions)
        ]),
      });
    } else {
      return this.formBuilder.group({
        groupId: '',
        groupName: '',
        questions: new FormArray([
          this.initQuestions(null)
        ]),
      });
    }

  }

  get formGroupArr() {
    return this.feedbackForm.get('groups') as FormArray;
  }

  initQuestions(data: any) {
    return this.formBuilder.group({
      questionId: data?.questionId,
      questionName: data?.question,
      feedback: [null],
      responseTypeSignatureSave: [null],
      responseTypeTextSave: [null],
    });
  }

  addMoreGroups(data: any): void {
    this.formGroupArr.push(this.initGroups(data));
  }
  getDoneSignature() {
    const imageURL = this.canvasElement.nativeElement.toDataURL();
    console.log(imageURL);
    this.signDynamicSet.responseTypeSignatureSave = imageURL;
    this.clear();
    // Convert canvas image to Base64
  }
  reload() {
    if (this.router.url.split("/")[1] != 'customer-feedback-view') {
      window.top.close();
    } else {
      window.top.close();
    }
  }
  textResponseTypeTextSaveSingle(signle){
    signle.responseTypeTextSave = $('#responseTypeTextSaveSingle').val();
  }
  textResponseTypeTextSave(dQ,questionId){
    console.log($('#responseTypeTextSave'+questionId).val());
    dQ.responseTypeTextSave = $('#responseTypeTextSave'+questionId).val();
  }
  signDynamicSet
  responseTypeSignatureSaveData(dQ){
    this.signDynamicSet = dQ;
  }
  goBack(): void {
    //window.top.close();
    window.history.back();
  }
  shouldShow = this.buisnessId == 37 ? false : true
  errorFlag = false
  validateEmail() {
    // console.log(this.feedbackForm.value.email)
    if (this.feedbackForm.value.email) {
      const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.errorFlag = !regularExpression.test(String(this.feedbackForm.value.email).toLowerCase());
      return this.errorFlag;
    }
  }
  submitFeedBack() {
    let dataURL = null
    if (this.buisnessId == 37) {
      if (this.signaturePad.isEmpty()) {
      } else {
        dataURL = this.signaturePad.toDataURL();
      }
    }

    let responcearray = []
    for (let d of this.groupsArray) {
      let obj1 = {
        "groupId": d.groupId,
        "questions": []
      }
      for (let e of d.questions) {
        // e.status =  e.status == true ? 1:0;
        obj1['questions'].push({ "questionId": e.questionId, "feedback": e.feedback,"responseTypeSignature":e.responseTypeSignatureSave,"responseTypeText":e.responseTypeTextSave })
      }
      responcearray.push(obj1);
    }

    let obj2 = {
      // "groupId": null,
      "questions": []
    }
    if (this.feedbackForm.value.type == 0) responcearray = [obj2['questions'].push({ "questionId": "", "feedback": this.siglesQuestionResponce.feedback,"responseTypeSignature": this.siglesQuestionResponce.responseTypeSignatureSave,"responseTypeText": this.siglesQuestionResponce.responseTypeTextSave })]
    let obj = {
      "userName": this.feedbackForm.value.userName,
      "email": this.feedbackForm.value.email,
      "mobile": this.feedbackForm.value.mobile,
      "patientName": this.feedbackForm.value.patientName,
      "roomNo": this.feedbackForm.value.roomNo,
      "floorNo": this.feedbackForm.value.floorNo,
      "comment": this.feedbackForm.value.comment,
      "companyName": this.feedbackForm.value.companyName,
      "type": this.feedbackForm.value.type,
      "groups": responcearray,
      "surveyGroupId": this.surveyId,
      "feedback": this.siglesQuestionResponce.feedback,
      "responseTypeSignature": this.siglesQuestionResponce.responseTypeSignatureSave,
      "responseTypeText": this.siglesQuestionResponce.responseTypeTextSave,
      "signature": dataURL,
      "userLocation": this.feedbackForm.value.userLocation,
      "age": this.feedbackForm.value.age,
      "ageOptions": this.feedbackForm.value.ageOptions,
      "occupation": this.feedbackForm.value.occupation,
      "occupationOptions": this.feedbackForm.value.occupationOptions,
      "address": this.feedbackForm.value.address,
      "addressOptions": this.feedbackForm.value.addressOptions,
    }
    this.apiService.startLoader()
    this.apiService.postCall('feedbacks/saveFeedback/' + this.feedBackId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.feedbackForm.reset();
      this.apiService.stopLoader();

      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      //setTimeout(() => { window.close();}, 1000);
    })
  }

  message
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
}
