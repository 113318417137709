import { Component, OnInit,Input, HostListener } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    public apiService:ApiService,
    public _location:Location,
    public router:Router,
    public formBuilder:FormBuilder,
  ) { }
  userForm = this.formBuilder.group({
    oldPassword:[null,[Validators.required]],
    newPassword:[null,[Validators.required]],
    cpassword:[null,[Validators.required]],
  });
  userId:any;
  self:any;
  shide
  hide
  saveData = false;
  message:any;
  validPassword=false
  public passwordMask = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/
  ngOnInit(): void {
    if(this.router.url.split("/")[2] == undefined){
      this.userId = this.apiService.authId;
      this.self = true;
    }else{
      this.self = false;
      this.userId = this.router.url.split("/")[2];
    }
  }
  onSomeAction(event){
    if($(".openSuccessOp").hasClass('show')){
    }else if(event.keyCode===13 && this.getValidation()){
     this.submitPassword();
   }
  }
  getValidation(){
    this.validPassword=false;
    if(!this.passwordMask.test(this.userForm.value.newPassword)){
      this.validPassword=true;
      return false;
    }
    if(this.userForm.value.newPassword != this.userForm.value.cpassword){
      return false;
    }
    else{
      return (this.userForm.valid);
    }
    
  }

  submitPassword(){
    let formData = this.userForm.value;
    formData.userId = this.userId;
    formData.self = this.self;
    this.apiService.startLoader();
    this.apiService.postCall('users/passwordReset', {},formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      if(result['success'] == true){
      this.saveData = result['success'];
      this.message =  result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      else {
        this.saveData = result['success'];
        this.message =  result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
    });
  }

  logout(){
    // console.log('LogOut');
    let obj = localStorage.getItem("userCred")
    localStorage.clear();
    localStorage.setItem("userCred" , obj)
    this.apiService.token=undefined ,
    this.router.navigate(['/login-view'])
  }

}
