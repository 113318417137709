import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../../common/services/storedData.service';
import { ApiService } from '../../common/services/apiCalls.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-parts',
  templateUrl: './edit-parts.component.html',
  styleUrls: ['./edit-parts.component.css']
})
export class EditPartsComponent implements OnInit {
  @Input() partsDetails: any;
  @Input() partsStatus: any;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }

  propertyList = []
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false,
    dateType: 'start'
  }
  todayDate: any = new Date();
  groups: []
  partsDetailsForm = this.fb.group({
    //businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    propertyId: [null, Validators.required],
    partName: [null, Validators.required],
    number: [null, Validators.required],
    serialNo: [null],
    unitOfMeasure: [null, Validators.required],
    quentityInHand: [null, Validators.required],
    pricePerPice: [null, Validators.required],
    minimumQuentity: [null, Validators.required],
    locationId: [null, Validators.required],
    categoryType: [null],
    departmentId: [null],
    purchaseDate: [null, new Date()],
    //groupId: [null],
    expiry: [null, new Date()],
    warrantyExpiry: [null, new Date()],
  });


  ngOnInit(): void {
    // console.log(this.partsDetails)
    this.getPropertyList()
  }

  getValidationForm() {
    // console.log(this.partsDetailsForm)
    return this.partsDetailsForm.valid
  }
  locationHistoryList = []
  message
  submit() {
    // console.log(this.partsDetailsForm.value)
    if (this.partsDetailsForm.value.expiry && this.partsDetailsForm.value.purchaseDate > this.partsDetailsForm.value.expiry) {
      this.partsDetailsForm.patchValue({ expiry: null })
      this.message = "Expiry Date should be greater than purchase date";
      (<HTMLInputElement>document.getElementById('openSuccess2Option')).click();
      // console.log("in");
      return;
    }
    if (this.partsDetailsForm.value.warrantyExpiry && this.partsDetailsForm.value.purchaseDate > this.partsDetailsForm.value.warrantyExpiry) {
      this.partsDetailsForm.patchValue({ warrantyExpiry: null })
      this.message = "Warranty Expiry Date should be greater than purchase date";
      (<HTMLInputElement>document.getElementById('openSuccess2Option')).click();
      // console.log("out");
      return;
    }
    // if(!this.partsDetailsForm.value.warrantyExpiry || !this.partsDetailsForm.value.purchaseDate || !this.partsDetailsForm.value.expiry){
    //   this.message = "Please Select Warranty Expiry or Purchase or Expiry Date";
    //   (<HTMLInputElement>document.getElementById('openSuccess2Option')).click();
    //   // console.log("out");
    //   return;
    // }

    let obj = {
      //businessId: this.apiService.buisnessId,
      propertyId: this.partsDetailsForm.value.propertyId,
      authId: this.apiService.authId,
      partName: this.partsDetailsForm.value.partName,
      number: this.partsDetailsForm.value.number,
      categoryType: this.partsDetailsForm.value.categoryType,
      serialNo: this.partsDetailsForm.value.serialNo,
      unitOfMeasure: this.partsDetailsForm.value.unitOfMeasure,
      quentityInHand: this.partsDetailsForm.value.quentityInHand,
      pricePerPice: this.partsDetailsForm.value.pricePerPice,
      minimumQuentity: this.partsDetailsForm.value.minimumQuentity,
      locationId: this.partsDetailsForm.value.locationId,
      departmentId: this.partsDetailsForm.value.departmentId,
      purchaseDate: this.partsDetailsForm.value.purchaseDate ? moment(this.partsDetailsForm.value.purchaseDate).format('YYYY-MM-DD') : null,
      //groupId: this.partsDetailsForm.value.groupId,
      expiry: this.partsDetailsForm.value.expiry ? moment(this.partsDetailsForm.value.expiry).format('YYYY-MM-DD') : null,
      warrantyExpiry: this.partsDetailsForm.value.warrantyExpiry ? moment(this.partsDetailsForm.value.warrantyExpiry).format('YYYY-MM-DD') : null,
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.message = result['msg']
      this.apiService.stopLoader();
      (<HTMLInputElement>document.getElementById("openSuccess123")).click();
      this.updateData.emit()
      document.getElementById('dismissPopUp').click()

    })

  }
  // statusList = [

  //   { id: 1, name: 'Active' },
  //   { id: 2, name: 'Inactive' },
  // ]
  conditionListing = [{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]

  minDate
  businessId = 0
  ngOnChanges() {
    //// console.log(this.partsDetails.groupId);
    if (this.partsDetails) {
      this.partsDetailsForm.patchValue({

      })
      this.minDate = moment(this.partsDetails.purchaseDate).format('YYYY-MM-DD');
      // console.log(this.partsDetails)
      this.businessId = this.partsDetails.businessId,
        this.partsDetailsForm.patchValue({
          //groupId: this.partsDetails.groupId,
          businessId: this.partsDetails.businessId,
          propertyId: this.partsDetails.outletId,
          authId: this.apiService.authId,
          partName: this.partsDetails.partName,
          categoryType: this.partsDetails.categoryType,
          number: this.partsDetails.number,
          serialNo: this.partsDetails.serialNo,
          unitOfMeasure: this.partsDetails.unitOfMeasure,
          quentityInHand: this.partsDetails.quentityInHand,
          pricePerPice: this.partsDetails.pricePerPice,
          minimumQuentity: this.partsDetails.minimumQuentity,
          locationId: this.partsDetails.locationId,
          departmentId: this.partsDetails.departmentId,
          purchaseDate: this.partsDetails.purchaseDateEdit ? moment(this.partsDetails.purchaseDateEdit).format('YYYY-MM-DD') : '',
          expiry: this.partsDetails.expiryEdit ? moment(this.partsDetails.expiryEdit).format('YYYY-MM-DD') : '',
          warrantyExpiry: this.partsDetails.warrantyExpiryEdit ? moment(this.partsDetails.warrantyExpiryEdit).format('YYYY-MM-DD') : '',
        })

      // console.log(this.partsDetailsForm)
      this.gertLocations()
    }
  }


  locationList = []
  gertLocations() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.partsDetails.outletId,
    }


    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId + "/" + this.partsDetails.outletId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.locationList = result['data']
    })
  }

  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  departmentList: []
  getPropertyList() {
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']
      this.propertyList = result['data']
      this.groups = result['groups']
      this.departmentList = result['departments'];
      // console.log(this.groups)
    })
  }
  openDate(id) {
    (<HTMLInputElement>document.getElementById(id)).click();
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  pDate
  eDate
  wDate
  getValidationDate() {
    if (this.partsDetailsForm.value.purchaseDate) {
      this.pDate = this.partsDetailsForm.value.purchaseDate ? moment(this.partsDetailsForm.value.purchaseDate).format('YYYY-MM-DD') : '';
      this.eDate = this.partsDetailsForm.value.expiry ? moment(this.partsDetailsForm.value.expiry).format('YYYY-MM-DD') : '';
      this.wDate = this.partsDetailsForm.value.warrantyExpiry ? moment(this.partsDetailsForm.value.warrantyExpiry).format('YYYY-MM-DD') : '';
      //   console.log(this.pDate);
      // console.log(this.eDate);
      // console.log(this.wDate);
      if (this.pDate && (this.pDate > this.eDate)) return true
      else if (this.wDate && (this.pDate > this.wDate)) return true
      else return false
    }
    else return false
  }
}
