import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-inventory-moving',
  templateUrl: './inventory-moving.component.html',
  styleUrls: ['./inventory-moving.component.css']
})
export class InventoryMovingComponent implements OnInit {

  constructor(
    public route: Router,
    public apiService: ApiService,
    public storedData: StoredData,
    public _location: Location,
  ) { }

  selectedFiler = { part: null, assets: null, location: null, property: null, endDate: null, startDate: null, number: null, departmentId: null, categoryType: null }
  fromDate: Date = new Date();
  toDate: Date = new Date();

  sortSelected: any = null;
  sort: any = null;

  reportList = [];

  locationList = [];
  filterlocationList = [];
  locationFilter = false;
  filterLocationSearch: any

  applyFlag = false;

  partList = [];
  partListFilter = [];
  partFilter = false;
  partFilterSearch: any;

  filterSelect = false;
  assetNameFileter = false;
  numberFilter = false;

  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch: any

  ngOnInit(): void {
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.applyFlag = true;
    this.getReportListing();
    this.getPropertyList();
  }

  getReportListing() {
    this.apiService.startLoader()
    let body = {
      // sortBy: this.sortSelected,
      // sortType: this.sort,
      // partId: this.selectedFiler.part ? this.selectedFiler.part.id : null,
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate : this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      partName: this.selectedFiler.assets ? this.selectedFiler.assets : null,
      download: 0,
      partNumber: this.selectedFiler.number,
      categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
    }
    this.apiService.postCall('parts/getMovingInventoryStockReport', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data']
      this.apiService.stopLoader()
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { part: null, assets: null, location: null, property: null, endDate: this.toDate, startDate: this.fromDate, number: null, departmentId: null, categoryType: null }
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getReportListing()
  }

  sortData(data: any) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getReportListing()
  }

  applyFilter() {
    this.applyFlag = true;
    this.getReportListing();
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.part != null) length = length + 1
    if (this.selectedFiler.location != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.assets != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.number != null) length = length + 1
    if (this.selectedFiler.number != null) length = length + 1
    if (this.selectedFiler.categoryType != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  departmentList = [];
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      this.departmentList = result['departments'];
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    })
  }

  filterProperty(ev: any) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
    }
  }
  closeAll() {
    this.propertFilter = false;
  }

  downloadReport(): void {
    this.apiService.startLoader()
    let body = {
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate : this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
      partName: this.selectedFiler.assets ? this.selectedFiler.assets : null,
      departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      download: 1,
      partNumber: this.selectedFiler.number,
      categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
    }
    this.apiService.postCall('parts/getMovingInventoryStockReport', {}, body).subscribe((result: any[]) => {
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }

}
