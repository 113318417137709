import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../common/services/apiCalls.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Output() updateCollapse: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router, public apiService: ApiService) {
    this.getSelected()
  }

  assetViewPermission = true
  inspectionPermission = true
  ticketPermission = true
  partsPermission = true
  maintanencePermission = true
  meterPermission = true
  dashboardPermission = true
  reportsPermission = true
  surveyPermission = true

  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  registerFeaturePermissions = false
  tokenFlag = false
  ngOnInit() {
    if (localStorage.getItem('accessToken')) {
      this.tokenFlag = true
    }
    if (localStorage.getItem('theme') == "dark") {
      this.modeType = false
    } else {
      this.modeType = true
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 32 && businessFeatures[i]['permission_allow'] == 1) {
          this.registerFeaturePermissions = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
      this.registerFeaturePermissions = true
    }

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    // console.log(businessPermission)
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 4 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeaturePermit = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 8 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureTraining = true
        }

        if (businessPermission[i]['business_app_feature_id'] == 16 && businessPermission[i]['permission_allow'] == 1) {
          this.businesspartsPermission = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 17 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeaturmaintanence = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 14 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureasset = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 14 && businessPermission[i]['permission_allow'] == 0) {
          this.businessFeatureasset = false
          this.businessFeaturmaintanence = false
          this.businesspartsPermission = false
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }

      if (this.apiService.role == 1) {
        this.businessFeaturePermit = true
        this.businessFeatureAttendance = true
        this.businessFeatureTraining = true
        this.businessFeatureasset = true
        this.businessFeaturmaintanence = true
        this.businesspartsPermission = true
      }
    }


    // console.log(this.router.url)
    let permission: any;
    permission = JSON.parse(localStorage.getItem('permissions'));
    // console.log(permission);
    if (permission) {
      for (var i = 0; i < permission.length; i++) {
        if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.assetViewPermission = true
        }
        if (permission[i]['permissionModuleId'] == 1 && permission[i]['subModuleName'] == "Inspection" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.inspectionPermission = true
        }
        if (permission[i]['permissionModuleId'] == 2 && permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.ticketPermission = true
        }
        if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.partsPermission = true
        }
        if (permission[i]['permissionModuleId'] == 4 && permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.maintanencePermission = true
        }
        if (permission[i]['permissionModuleId'] == 6 && permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.meterPermission = true
        }
        if (permission[i]['permissionModuleId'] == 7 && permission[i]['subModuleName'] == "Dashboard" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.dashboardPermission = true
        }
        if (permission[i]['permissionModuleId'] == 8 && permission[i]['subModuleName'] == "Reports" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.reportsPermission = true
        }
        if (permission[i]['subModuleName'] == "Surveys" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.surveyPermission = true
        }
      }
    }
  }
  modeType = false
  changeMode() {
    const body = document.getElementsByTagName('body')[0];
    if (this.modeType) {
      localStorage.setItem('theme', "lite");
      body.classList.remove('dark');
    } else {
      localStorage.setItem('theme', "dark");
      body.classList.add('dark');
    }
    // console.log(localStorage.getItem('theme'));
  }
  //selected: any = 'dashboard-checklist';
  selected: any = this.router.url.split("/")[1];
  searchPromise2 = null
  navigateTo(rout) {
    localStorage.setItem("tabselection", JSON.stringify({ ticketList: 'openTicket', assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }))
    // clearTimeout(this.searchPromise2);
    // this.searchPromise2 = setTimeout(() => {
    //   document.body.style.overflowY = 'scroll';
    // },1000)
    if (rout == "parts-inventory") {
      localStorage.setItem('partsPagination', 1 + "");
    } else if (rout == "inspection-list") {
      localStorage.setItem('inspectionPagination', 1 + "");
    } else if (rout == "ticket-main-list") {
      localStorage.setItem('ticketMainListPagination', 1 + "");
    } else if (rout == "home") {
      localStorage.setItem('homePagination', 1 + "");
    } else if (rout == "pm-index") {
      localStorage.setItem('pmIndexPagination', 1 + "");
    } else if (rout == "meter-list") {
      localStorage.setItem('meterListPagination', 1 + "");
    }
    this.clearAllFilterLocalStorage()
    this.selected = rout
    this.router.navigate([rout])
    this.getSelected()
  }
  clearAllFilterLocalStorage() {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    localStorage.removeItem("inspectionScheduleListFilter");
    localStorage.setItem("inspectionScheduleListFilter", null);
    localStorage.removeItem("inspectionChecklistFilter");
    localStorage.setItem("inspectionChecklistFilter", null);
    localStorage.removeItem('openTicketFilter');
    localStorage.setItem("openTicketFilter", null);
    localStorage.removeItem('closeTicketFilter');
    localStorage.setItem("closeTicketFilter", null);
    localStorage.removeItem('parkedTicketFilter');
    localStorage.setItem("parkedTicketFilter", null);
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    localStorage.removeItem('traningFilterLocal');
    localStorage.setItem("traningFilterLocal", null);
    localStorage.removeItem('permitRequestLocalFilter');
    localStorage.setItem("permitRequestLocalFilter", null);
    localStorage.removeItem('assetLocalFilter');
    localStorage.setItem("assetLocalFilter", null);
    localStorage.removeItem('localAssetTransfersFilter');
    localStorage.setItem("localAssetTransfersFilter", null);
    localStorage.removeItem('LocalPmTodayFilter');
    localStorage.setItem("LocalPmTodayFilter", null);
    localStorage.removeItem('LocalUpcomingFilter');
    localStorage.setItem("LocalUpcomingFilter", null);
    localStorage.removeItem('localPartsFilter');
    localStorage.setItem("localPartsFilter", null);
    localStorage.removeItem('localPartTransferFilter');
    localStorage.setItem("localPartTransferFilter", null);
    localStorage.removeItem('localMeterFilter');
    localStorage.setItem("localMeterFilter", null);
    localStorage.removeItem('localServyFilter');
    localStorage.setItem("localServyFilter", null);
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    localStorage.removeItem('localPropertyFilter');
    localStorage.setItem("localPropertyFilter", null);
  }
  getSelected() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // // console.log(val.url);
        let activeurl = val.url.split("/")[1]
        // console.log(activeurl)
        // if(   ) this.selected = 'home'
        if (activeurl == 'home' || activeurl == 'migrate-asset' || activeurl == 'asset-details' || activeurl == 'view-destination-location' || activeurl == 'edit-asset' || activeurl == 'amc-support-history' || activeurl == 'amc-support-add-new' || activeurl == 'amc-support-support-history' || activeurl == 'pm-list' || activeurl == 'assets-add-new' || activeurl == 'assets-details-depreciation' || activeurl == 'assets-details-notification-settings' || activeurl == 'tickets-list' || activeurl == 'log-file-list' || activeurl == 'child-asset-list' || activeurl == 'asset-report' || activeurl == 'asset-part-list' || activeurl == 'asset-part-add' || activeurl == 'asset-raise-ticket') this.selected = 'home'
        else if (activeurl == 'meter-list' || activeurl == 'meter-detail' || activeurl == 'meter-add-new' || activeurl == 'meter-trigger-list' || activeurl == 'meter-trigger-add' || activeurl == 'meter-history') this.selected = 'meter-list'
        else if (activeurl == 'inspection-list' || activeurl == 'inspection-view-report-todays' || activeurl == 'inspection-view-report' || activeurl == 'inspection-checklist-list' || activeurl == 'inspection-checklist-create' || activeurl == 'inspection-checklist-edit' || activeurl == 'inspection-checklist-view' || activeurl == 'inspection-schedule-list' || activeurl == 'inspection-schedule-add' || activeurl == 'inspection-schedule-edit' || activeurl == 'inspection-schedule-view' || activeurl == 'inspection-dublicate' || activeurl == 'inspection-schedule-dublicate') this.selected = 'inspection-list'
        else if (activeurl == 'ticket-main-list' || activeurl == 'ticket-raise-ticket' || activeurl == 'ticket-view' || activeurl == 'ticket-escalation') this.selected = 'ticket-main-list'
        else if (activeurl == 'pm-index' || activeurl == 'pm-templates' || activeurl == 'pm-templates' || activeurl == 'pm-view' || activeurl == 'pm-ticket-raise-ticket') this.selected = 'pm-index'
        else if (activeurl == 'login-view' || activeurl == 'dashboard-checklist' || activeurl == 'dashboard-ticket' || activeurl == 'dashboard-asset' || activeurl == 'dashboard-parts' || activeurl == 'dashboard-survey' || activeurl == 'dashboard-index' || activeurl == 'dashboard-pm' || activeurl == '') this.selected = 'dashboard-checklist'
        else if (activeurl == 'survey-add' || activeurl == 'survey-edit' || activeurl == 'feedback-index' || activeurl == 'feedback-index' || activeurl == 'customer-feedback' || activeurl == 'customer-feedback-view' || activeurl == 'department-list' || activeurl == 'department-add-new' || activeurl == 'department' || activeurl == 'setup' || activeurl == 'insurance' || activeurl == 'insurance-list' || activeurl == 'insurance-add' || activeurl == 'insurance-details' || activeurl == 'edit-insurance' || activeurl == 'insurance-notification-setting' || activeurl == 'asset-covered' || activeurl == 'user-index' || activeurl == 'user-add' || activeurl == 'user-edit' || activeurl == 'property-index' || activeurl == 'property-add' || activeurl == 'property-edit' || activeurl == 'checklist-index' || activeurl == 'insurance-files' || activeurl == 'property-details' || activeurl == 'property-locations' || activeurl == 'property-users' || activeurl == 'template-library' || activeurl == 'template-create' || activeurl == 'template-edit' || activeurl == 'template-index' || activeurl == 'add-vendor' || activeurl == 'template-view') this.selected = 'setup'
        else if (activeurl == 'survey-index') this.selected = 'survey-index'
        else if (activeurl == 'part-transfer-add' || activeurl == 'part-transfer-view' || activeurl == 'part-add' || activeurl == 'parts-inventory' || activeurl == 'parts-vendors' || activeurl == 'parts-assets' || activeurl == 'parts-logs' || activeurl == 'parts-reports' || activeurl == 'parts-notify' || activeurl == 'parts-details') this.selected = 'parts-inventory'

        // console.log(this.selected)
      }
    })
  }


  colasFlag = false;
  changeCollapse() {
    if (this.colasFlag) {
      this.colasFlag = false
    } else {
      this.colasFlag = true
    }
    // console.log("clickCollpase")
    this.updateCollapse.emit()
  }
}
