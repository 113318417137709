import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {HashLocationStrategy, Location} from '@angular/common';
import { StoredData } from '../common/services/storedData.service';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
// import { ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.css']
})
export class AssetDetailsComponent implements OnInit {

  searchPromise2 =null;
  constructor(public route: Router,public apiService:ApiService,public router:Router , public _location: Location, public storedData:StoredData,) { 
    router.events.subscribe((val) => {
      clearTimeout(this.searchPromise2);
      this.searchPromise2 = setTimeout(() => {
        this.ngOnInit()
      } ,500)
     });
  }

  assetId:any;
  assetDetails:any
  asseAmcDetails:any=[];
  amcId:any;

  printQrPermission=false
  changeStatusPermission=false
  changeImagePermission=false
  viewEditLocationPermission=false
  editPermission=false
  warrantyeditPermission=false
  amceditPermission=false
  addAmcPermission=false
  addwarrantyPermission=false
  notifyAddPermission=false
  pmPermission=false;
  partsFeaturePermission = false;
  partsPermission = true;
  maintenanceFeaturePermission = false;
  ngOnInit(): void {

    // console.log(this.router.url.split("/")[2])
    this.assetId = this.router.url.split("/")[2]
    this.getUserList()
  this.getAsset()
  

  this.apiService.startLoader()
    this.apiService.getCall('pm-templates/view/1',{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
    })


    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
     
      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
  
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
      // idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      // this.statusList.splice(idx1,1)
     
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Print QR Code" && permission[i]['actionStatus'] == 1){
        this.printQrPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Image" && permission[i]['actionStatus'] == 1){
        this.changeImagePermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View Edit Location" && permission[i]['actionStatus'] == 1){
        this.viewEditLocationPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Warranty Edit" && permission[i]['actionStatus'] == 1){
        this.warrantyeditPermission=true
      }
      
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "AMC Edit" && permission[i]['actionStatus'] == 1){
        this.amceditPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Add AMC Service" && permission[i]['actionStatus'] == 1){
        this.addAmcPermission=true
      }
      if(permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.notifyAddPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Add Warranty Service" && permission[i]['actionStatus'] == 1){
        this.addwarrantyPermission=true
      }
      if(permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.pmPermission=true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    } 
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true;
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true;
      this.maintenanceFeaturePermission = true;
    }
  }

  goToLink(url: string){
    window.open(url, "_blank");
}
getFileName(name){
 if(name) return name.split("/")[7];
}

getFileNameWarranty(name){
  if(name && name.split("/")[7])return name.split("/")[7];
}
  amcType:any;

  getAmcText(id){
    if(this.amcType && id)return this.amcType.find( x=>x.id == id).name
  }
getAsset(){
  this.apiService.startLoader()
  this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    this.apiService.stopLoader()
    this.assetDetails = result['assetDetails'];
    this.asseAmcDetails = result['amcDetails'];
    if(this.asseAmcDetails?.length==0) this.asseAmcDetails = [];
    else if (this.asseAmcDetails?.file == '')this.asseAmcDetails.file=[]
    // console.log(this.asseAmcDetails.file)
    if(this.asseAmcDetails?.length != 0){
      this.amcId = this.asseAmcDetails.id;
    }
    this.amcType = result['amcTypes'];
    // console.log(this.asseAmcDetails)

    if(this.assetDetails.warraantyAttach == '')this.assetDetails.warraantyAttach= undefined
   
    if(!this.storedData.locationList[0])this.getLocationList()
  })
}

userList
getUserList():void{
  this.apiService.startLoader() 
  // this.apiService.startLoader()
  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    // "outletId":this.assetDetailsForm.value.propertyId?this.assetDetailsForm.value.propertyId.id : "",
  }
 
  this.apiService.postCall('users/user-list/'+this.apiService.buisnessId, {} , obj).subscribe((result: any[]) => {
    this.apiService.stopLoader()
    
      this.userList = result['data'];
      // console.log("List of Users",this.userList);
    
  })

}

getUserName(id){
  let idx =  this.userList.findIndex(x=> x.id == id)

  if(idx !=-1 ) return this.userList[idx].fullName
  else return ''
}
  showLoader=true
  updateData(message11?:any){
    // console.log(message11)
    // let obj={msg : message11}
    if(message11){
    this.message = message11;
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
    }
    
    this.ngOnInit()
  }

  getStatus(id){

    if(id)  {
      let idx = this.statusList.findIndex(x => x.id == id)
      if(idx != -1) return this.statusList[idx].name
      else return "Lost"
    
    }
    }


  statusList=[]

locationList:any;
getLocationList(){
  this.apiService.startLoader()
  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    // "outletId":this.assetDetailsForm.value.propertyId?this.assetDetailsForm.value.propertyId.id : "",
  }
 
  this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj ).subscribe((result: any[])=>{
    // console.log(result);
    this.apiService.stopLoader()
    result['data']
    
    var jsonToBeUsed = [];

    
  

  // console.log(jsonToBeUsed)
  this.locationList = result['data']


  this.storedData.locationList = this.locationList;

})
}


gotoPMlisitng(){
  this.route.navigate(['/pm-list/'+this.assetId])
}

uploadedFiles:any
fileName:any;

fileChange(event) {
  // console.log(event.target.files[0].type)
  if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/msword" || event.target.files[0].type == "text/plain" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ){
  let fileList: FileList = event.target.files;
  if(fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles =file
      this.fileName = file.name
     

    }
  }
  else{

    this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose jpg/video" ;
   
     
    (<HTMLInputElement>document.getElementById('openError')).click();
  }
}

removeFileAmc(files , data){
  // let idx = files.findIndex(x=> x == data)
  // // console.log(idx)
  // files.splice(idx,1)
  // let testData:FormData = new FormData();
  let obj={
    fileDAttach:[data]
  }
  // testData.append("fileDAttach",[files])
this.apiService.startLoader()
this.apiService.uploadCall('amcs/edit/'+this.asseAmcDetails.id, {}, obj).subscribe((result: any[]) => {
  // console.log(result);  
  this.apiService.stopLoader()
  this.updateData('Asset AMC details updated successfully')
})
}

removeFileWarranty(files , data){
  // let idx = files.findIndex(x=> x == data)
  // // console.log(idx)
  // files.splice(idx,1)
  // let testData:FormData = new FormData();
  let obj={
    warraantyDAttach:[data],
    authId :this.apiService.authId
  }
  // testData.append("fileDAttach",[files])
this.apiService.startLoader()
this.apiService.uploadCall('asset-managements/editWarrantyDetails/'+this.assetId, {}, obj).subscribe((result: any[]) => {
  // console.log(result);  
  this.apiService.stopLoader()
  this.updateData('Asset warranty details updated successfully')
})
}

upload(){


  let testData:FormData = new FormData();
  testData.append("file",this.uploadedFiles)
this.apiService.startLoader()
this.apiService.uploadCall('amcs/edit/'+this.asseAmcDetails.id, {}, testData).subscribe((result: any[]) => {
  // console.log(result);  
  this.apiService.stopLoader()

  this.message =result["msg"];
  this.uploadedFiles = undefined
  this.fileName = undefined
       
  // (<HTMLInputElement>document.getElementById('openSuccess')).click();
  
  this.getAsset()
})
}

uploadedFilesAsset

// openCropperEvent=false;
// openCropper(){
//   (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
//   this.openCropperEvent= true;
// }

fileChangeAsset(event) {

  // console.log(event.target.files[0].type)
  if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/msword" || event.target.files[0].type == "text/plain" ){
  // const modalRef = this.modalService.open(ImageCropperComponent, { centered: true });
  // modalRef.componentInstance.data = "data";
  // modalRef.result.then((result) => {
  // }, (reason) => {
  // });

  // console.log(event)
  // let fileList: FileList = event.target.files;
  // if(fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFilesAsset =file
      
     

  //   }
  // this.openCropperEvent=false;
  this.uploadedFilesAsset = event;

    // console.log(this.uploadedFilesAsset)

    let testData:FormData = new FormData();
  
   
// // console.log(obj)
testData.append('image', this.uploadedFilesAsset);
  testData.append("assetName", this.assetDetails.assetName);
  testData.append("make", this.assetDetails.make);
  testData.append("specRating", this.assetDetails.specRating);
  testData.append("srNo", this.assetDetails.srNo);
  testData.append("locationId", this.assetDetails.locationId);
  testData.append("purchaseDate", this.assetDetails.purchaseDate);
  testData.append("placeInService", this.assetDetails.placeInService);
  testData.append("propertyId", this.assetDetails.propertyId);
  testData.append("businessId", this.assetDetails.businessId);
  testData.append("model", this.assetDetails.model);
  testData.append("specification", this.assetDetails.specification);
  testData.append("isOperational", this.assetDetails.isOperational);
  testData.append("renewalDate", this.assetDetails.renewalDate);
  testData.append("warrantyExpiration", this.assetDetails.warrantyExpiration);
  testData.append("authId", "1");
  testData.append("vendorName", this.assetDetails.vendorName);
  testData.append("categoryName", this.assetDetails.categoryName);
  testData.append("conditions", this.assetDetails.conditions);
  testData.append("slaExpiryDate", this.assetDetails.slaExpiryDate);
  testData.append("remark", this.assetDetails.remark);
  testData.append("ownership", this.assetDetails.ownership);
  testData.append("assetTagNo", this.assetDetails.assetTagNo);
  testData.append("status", this.assetDetails.status);
  // status:this.assetDetailsForm.value.status,
  


// // console.log(obj)
this.apiService.startLoader()
this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
  // console.log(result);
  this.apiService.stopLoader()
  this.getAsset()
  document.getElementById('dismissPopUp').click()
  
})
  }
  else{

    this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose jpg/video" ;
   
     
    (<HTMLInputElement>document.getElementById('openError')).click();
  }
}

fileChangeWarranty(event) {

  // console.log(event.target.files[0].type)
  if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "application/msword" || event.target.files[0].type == "text/plain" || event.target.files[0].type == "application/vnd.ms-excel" || event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
  let fileList: FileList = event.target.files;
  if(fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles =file
      this.fileName = file.name
     

    }
  }
  else{

    this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose jpg/video" ;
   
     
    (<HTMLInputElement>document.getElementById('openError')).click();
  }

}

uploadWarranty(){


  let testData:FormData = new FormData();
  testData.append("warraantyAttach",this.uploadedFiles)
this.apiService.startLoader()
this.apiService.uploadCall('asset-managements/editWarrantyDetails/'+this.assetId, {}, testData).subscribe((result: any[]) => {
  // console.log(result);  
  this.apiService.stopLoader()

  this.message =result["msg"];
  this.uploadedFiles = undefined
  this.fileName = undefined
       
  // (<HTMLInputElement>document.getElementById('openSuccess')).click();
  
  this.getAsset()
})
}
message:any;

changeStatus(){
  let testData:FormData = new FormData();
  
   
  // // console.log(obj)
  // testData.append('image', this.uploadedFilesAsset);
    testData.append("assetName", this.assetDetails.assetName);
    testData.append("make", this.assetDetails.make);
    testData.append("specRating", this.assetDetails.specRating);
    testData.append("srNo", this.assetDetails.srNo);
    testData.append("locationId", this.assetDetails.locationId);
    testData.append("purchaseDate", this.assetDetails.purchaseDate);
    testData.append("placeInService", this.assetDetails.placeInService);
    testData.append("propertyId", this.assetDetails.propertyId);
    testData.append("businessId", this.assetDetails.businessId);
    testData.append("model", this.assetDetails.model);
    testData.append("specification", this.assetDetails.specification);
    testData.append("isOperational", this.assetDetails.isOperational);
    testData.append("renewalDate", this.assetDetails.renewalDate);
    testData.append("warrantyExpiration", this.assetDetails.warrantyExpiration);
    testData.append("authId", "1");
    testData.append("vendorName", this.assetDetails.vendorName);
    testData.append("categoryName", this.assetDetails.categoryName);
    testData.append("conditions", this.assetDetails.conditions);
    testData.append("slaExpiryDate", this.assetDetails.slaExpiryDate);
    testData.append("remark", this.assetDetails.remark);
    testData.append("ownership", this.assetDetails.ownership);
    testData.append("assetTagNo", this.assetDetails.assetTagNo);
    testData.append("status", this.assetDetails.status);
    
  
  
  // // console.log(obj)
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
    this.getAsset()
    document.getElementById('dismissPopUp').click()
    
  })
}
closeAll(){
  (<HTMLInputElement>document.getElementById('closePopUp')).click();
}

openQR(){
  this.storedData.qrData={name:this.assetDetails.assetName,image : this.assetDetails.QRCode, customerComplaintName:"" }
  this.router.navigate(['print-qr'])
}
}

