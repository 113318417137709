import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset-details-header',
  templateUrl: './asset-details-header.component.html',
  styleUrls: ['./asset-details-header.component.css']
})
export class AssetDetailsHeaderComponent implements OnInit {



  @Input() assetDetails;
  @Input() assetId
  @Input() statusList
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  // @Input()
  constructor(public _location: Location,public router:Router,public apiService:ApiService) { }

  imagechangePermission=false
  changeStatusPermission=false
  ngOnInit(): void {

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 && item['subModuleName'] =="Assets" );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Image" && permission[i]['actionStatus'] == 1){
        this.imagechangePermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
    }
  }


  uploadedFilesAsset

openCropperEvent=false;
openCropper(){
  if(this.imagechangePermission){
    (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
    this.openCropperEvent= true;
  }
}

openCropperWithImage(){
  if(this.imagechangePermission){
  (<HTMLInputElement>document.getElementById('cropeerPopupSuccessWithImage')).click();
  }
}

editImage(){
  // console.log("assd");
  if(this.imagechangePermission){
  (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
  this.openCropperEvent= true;
  }
}


fileChangeAsset(event) {

  

  // const modalRef = this.modalService.open(ImageCropperComponent, { centered: true });
  // modalRef.componentInstance.data = "data";
  // modalRef.result.then((result) => {
  // }, (reason) => {
  // });

  // console.log(event)
  // let fileList: FileList = event.target.files;
  // if(fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFilesAsset =file
      
     

  //   }
  this.openCropperEvent=false;
  this.uploadedFilesAsset = event;

    // console.log(this.uploadedFilesAsset)

    let testData:FormData = new FormData();
  
    document.getElementById('closePopUpCroppper').click()
// // console.log(obj)
testData.append('image', this.uploadedFilesAsset);
testData.append('authId', this.apiService.authId);
  // status:this.assetDetailsForm.value.status,
  


// // console.log(obj)
this.apiService.startLoader()
this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
  // console.log(result);
  this.apiService.stopLoader()
  this.openCropperEvent=false;
  this.updateData.emit()
  
  
})
}

changeStatus(){
  let testData:FormData = new FormData();
  
   
  // // console.log(obj)
  // testData.append('image', this.uploadedFilesAsset);
    // testData.append("assetName", this.assetDetails.assetName);
    // testData.append("make", this.assetDetails.make);
    // testData.append("specRating", this.assetDetails.specRating);
    // testData.append("srNo", this.assetDetails.srNo);
    // testData.append("locationId", this.assetDetails.locationId);
    // testData.append("purchaseDate", this.assetDetails.purchaseDate);
    // testData.append("placeInService", this.assetDetails.placeInService);
    // testData.append("propertyId", this.assetDetails.propertyId);
    // testData.append("businessId", this.assetDetails.businessId);
    // testData.append("model", this.assetDetails.model);
    // testData.append("specification", this.assetDetails.specification);
    // testData.append("isOperational", this.assetDetails.isOperational);
    // testData.append("renewalDate", this.assetDetails.renewalDate);
    // testData.append("warrantyExpiration", this.assetDetails.warrantyExpiration);
    // testData.append("authId", "1");
    // testData.append("vendorName", this.assetDetails.vendorName);
    // testData.append("categoryName", this.assetDetails.categoryName);
    // testData.append("conditions", this.assetDetails.conditions);
    // testData.append("slaExpiryDate", this.assetDetails.slaExpiryDate);
    // testData.append("remark", this.assetDetails.remark);
    // testData.append("ownership", this.assetDetails.ownership);
    // testData.append("assetTagNo", this.assetDetails.assetTagNo);
    testData.append("status", this.assetDetails.status);
    
  
  
  // // console.log(obj)
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
    this.updateData.emit()
    document.getElementById('dismissPopUp').click()
    
  })
}


ngOnDestroy() {
  // // console.log('foo destroy')
  this.updateData.emit()
}
}
