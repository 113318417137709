import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import * as moment from 'moment';
declare var d3: any;
import  jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { data } from 'jquery';


@Component({
  selector: 'app-meter-history',
  templateUrl: './meter-history.component.html',
  styleUrls: ['./meter-history.component.css']
})
export class MeterHistoryComponent implements OnInit {

  constructor(public _location: Location,public route: Router,private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService, ) { }
  tiggerDetailsForm: FormGroup
  meterId
  exportPermission=false
  ngOnInit(): void {
 
    const date = new Date();
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    let starDate = new Date(date.getFullYear(), date.getMonth() -3, 1);
    starDate.setHours(starDate.getHours() + 5);
    starDate.setMinutes(starDate.getMinutes() + 30);

    this.range = [starDate , date];


    this.meterId = this.route.url.split("/")[2]
    this.getHistoryList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==6 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Meter History" && permission[i]['action'] == "Download Report" && permission[i]['actionStatus'] == 1){
        this.exportPermission=true
      }
    }
}

  getIncremtaldata(dtaa , i){
    // // console.log(dtaa.lastReading , this.historyList[i+1].lastReading)

if (this.historyList[i+1]) return (dtaa.lastReading - this.historyList[i+1].lastReading)
else return dtaa.lastReading
  }

  triggerList=[]
  meterDetails:any
  historyList=[]
  getHistoryList(){
    d3.selectAll("#groupedChartYAxis11 > *").remove();
    d3.selectAll("#groupedChart11 > *").remove();
    this.apiService.startLoader()

    let obj={
      start_date: this.range[0],
      end_date:  this.range[1]
    }
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
  this.apiService.postCall('meter-historys/history-list/'+this.meterId,{}, obj  ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.historyList = result['data']['data'];
  this.meterDetails=  result['meter']
 this.sumReading = result['sumReading']
  this.groupedBarchart()
  } )

  }

  sumReading:any

  range:any;
  filterSelect:boolean
  applyFilter(){
    // console.log(this.range[0])
    this.getHistoryList()
    d3.selectAll("#groupedChartYAxis11 > *").remove();
    d3.selectAll("#groupedChart11 > *").remove();
    this.filterSelect = false
  }

  clear(){
    d3.selectAll("#groupedChartYAxis11 > *").remove();
    d3.selectAll("#groupedChart11 > *").remove();
    this.filterSelect = false
    this.ngOnInit()

  }

  chartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: [],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom' },
      hAxis: { title: 'Litres' },
      vAxis: { title: 'Litres' },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };

  imgCount=1
  getFileName(name){
    if(name){
      return 'IMG '+this.imgCount;
      this.imgCount++;
    }
    //let string =name.split("/")
    //if(name) return name.split("/")[string.length-1];
   }
   
  sortData


  singleBarPlotCurce(){

    // console.log(this.historyList)
    
var data=[]

// console.log(this.historyList)
    for(let i=0; i< this.historyList.length ; i++)data.push({ ser2 : this.historyList[i].lastReading , ser1:d3.timeParse("%Y-%m-%d %H:%M:%S")(this.historyList[i].createdAt)})
  //   var data = [
  //     {ser1: 0.3, ser2: 4},
  //     {ser1: 2, ser2: 16},
  //     {ser1: 3, ser2: 8}
  //  ];
  // console.log(data)

  
   var margin = {top: 10, right: 30, bottom: 30, left: 50},
   width = 860,
   height = 400 - margin.top - margin.bottom;

  //  var parseDate = d3.time.format("%Y-%m-%d %X");
  var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;

  data.forEach(function(d) {
    //// console.log(d.date_time)
    // d.ser1 = moment(d.ser1).format('YYYY-MM-DD HH:MM:SS');

  });
  var self = this; // store here
   var svg = d3.select("#chart2")
  .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform",
          "translate(" + 70 + "," + margin.top + ")");



svg.append("text")
.attr("class", "x_label cart-title")
.style("fill", "#33CCB2")
.attr("text-anchor", "end")
.attr("x", width -50 )
.attr("y", height + 30)
.text("Time Chart");


svg.append("text")
.attr("class", "y_label cart-title")
.style("fill", "#33CCB2")
.attr("text-anchor", "end")
.attr("x", -86)
.attr("y", -66)
.attr("dy", ".75em")
.attr("transform", "rotate(-90)")
.text(self.meterDetails?.unitName);


svg.append("g")
.attr("transform", "translate(0," + height + ")")
.attr("class","myXaxis")

var area = d3.area()
.curve(d3.curveBasis)
.x(function(d) { return x(d.ser1); })
.y0(height)
.y1(function(d) { return y(d.ser2); });
    
///////////////////////////////////////////////////////////
// Initialise a X axis:
var x = d3.scaleTime()
    .domain(d3.extent(data, function(d) { return d.ser1; }))
    .range([ 0, width ]);
    
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x))
.attr("class","myXaxis");

//   // Add Y axis
  var y = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return +d.ser2; })])
    .range([ height, 0 ]);
  svg.append("g")
    .call(d3.axisLeft(y))
    .attr("class","myYaxis");

/////////////////////////////////

//////////////////////////////////////////////////////

  // Add the line
  svg.append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", "steelblue")
    .attr("stroke-width", 4.5)
    .attr("class","lineTest")
    .attr("d", d3.line()
    .curve(d3.curveBasis)
      .x(function(d) { return x(d.ser1) })
      .y(function(d) { return y(d.ser2) })
      )


    svg.append("path")
    .data([data])
    .attr("class", "myArea")
    .attr("d", area);



    d3.selectAll("g.myYaxis g.tick")
  .append("line")
  .attr("class", "gridline")
  .attr("x1", 0)
  .attr("y1", 0)
  .attr("x2", width)
  .attr("y2", 0);

d3.selectAll("g.myXaxis g.tick")
  .append("line")
  .attr("class", "gridline")
  .attr("x1", 0)
  .attr("y1", -height)
  .attr("x2", 0)
  .attr("y2", 0);


    }

    graphLaoder=true
    groupedBarchart(){
      let data=[]
      this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('DD-MM-YYYY hh:mm:ss a'))
      for(let i=0; i< this.historyList.length ; i++){
        data.push([this.historyList[i].createdAt ,  this.historyList[i].lastReading ])
      }
// console.log(data)
      this.chartData = {
        type: 'ColumnChart',
        data: data,
        chartColumns: ['Created','Reading'],
        options: {
          responsive: true,
          borderRadius: 15,
          colors: ['#33CCB2', '#EF6B6B'],
          legend: { position: 'bottom' },
          hAxis: { title: '' },
          vAxis: { title: this.meterDetails?.unitName },
          seriesType: 'bars',
          series: { 2: { type: 'line' } }
        },
        width: 1000,
        height: 400
      };
      this.graphLaoder=false
    }
    groupedBarchart2(){
      let data=[]
      this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('DD-MM-YY hh:mm:ss a'))
    for(let i=0; i< this.historyList.length ; i++){
      data.push({'group' :this.historyList[i].createdAt , 'complete': this.historyList[i].lastReading })
    }
  
    // console.log(data)
    var margin = {top: 10, right: 30, bottom: 20, left: 50},
    width =(1200*data.length)/10,
    height = 400 - margin.top - margin.bottom;
  
    
  // append the svg object to the body of the page
  var svgAxis = d3.select("#groupedChartYAxis11")
  .append("svg")
    .attr("width", 50 )
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform",
          "translate(" + 40 + "," + margin.top + ")");
  // append the svg object to the body of the page
  var svg = d3.select("#groupedChart11")
  .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform",
          "translate(" + -10 + "," + margin.top + ")");
  
  // Parse the Data
  
  
  
  // List of subgroups = header of the csv files = soil condition here
  var subgroups = ['complete' ]
  
  var groups = []
  for(let i of data)groups.push(i.group)
  // List of groups = species here = value of the first column called group -> I show them on the X axis
  
  
  
  // console.log('subgroups',subgroups)
  // console.log('groups',groups)
  // console.log(data)
  // Add X axis
  var x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2])
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .attr("class","myXaxis")
    .call(d3.axisBottom(x).tickSize(0));
  
  // Add Y axis
  var y = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return d['complete']; })])
    .range([ height, 0 ]);
  svg.append("g")
  .attr("class","myYaxis")
    .call(d3.axisLeft(y));
  
    svgAxis.append("g")
    .attr("class","myYaxis")
    .call(d3.axisLeft(y));
  
  
  // Another scale for subgroup position?
  var xSubgroup = d3.scaleBand()
    .domain(subgroups)
    .range([0, x.bandwidth()])
    .padding([0.05])
  
  // color palette = one color per subgroup
  var color = d3.scaleOrdinal()
    .domain(subgroups)
    .range(['#6FCF97'])
  
  // Show the bars
  svg.append("g")
    .selectAll("g")
    // Enter in data = loop group per group
    .data(data)
    .enter()
    .append("g")
      .attr("transform", function(d) { return "translate(" + (x(d.group) +40) + ",0)"; })
    .selectAll("rect")
    .data(function(d) { return subgroups.map(function(key) { return {key: key, value: d[key]}; }); })
    .enter().append("rect")
      .attr("x", function(d) { return xSubgroup(d.key); })
      .attr("y", function(d) { return y(d.value); })
      .attr("width", 30)
      .attr("height", function(d) { return height - y(d.value); })
      .attr("fill", function(d) { return color(d.key); });
  
  
      // d3.selectAll("g.myYaxis g.tick")
      // .append("line")
      // .attr("class", "gridline")
      // .attr("x1", 0)
      // .attr("y1", 0)
      // .attr("x2", width)
      // .attr("y2", 0);
   
      // d3.selectAll("g.myXaxis g.tick")
      // .append("line")
      // .attr("class", "gridline")
      // .attr("x1", 0)
      // .attr("y1", -height)
      // .attr("x2", 0)
      // .attr("y2", 0);
  
  
      }
  


    exportAsPDF(div_id)
    {
      document.getElementById("pdfButton").style.display="none";
      // document.getElementById("pdfButton").style.margin="10px 0";
      let data = document.getElementById(div_id);
      // console.log(data.clientHeight)
      html2canvas(data, {
        width: 2480,
        height: data.clientHeight+100
      }).then((canvas) => {
        let imgWidth = 600;
          let pageHeight = 480;
          let imgHeight =
            ((canvas.height * imgWidth) / 2454)*1.24;
          var heightLeft = imgHeight;
  
          const imgData = canvas.toDataURL("image/png");
  
          const pdf = new jspdf({
            orientation: "p",
            unit: "mm",
            format: [400, 480],
          });
  
          let position = 6;
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          pdf.save("Result.pdf");
          document.getElementById("pdfButton").style.display="flex";
        });
  
  
    }
    goToLink(url: string){
      window.open(url, "_blank");
  }
    downloadDiv:boolean;
  
    @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.downloadDiv = false
     
    }
  }
  
}
