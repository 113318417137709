import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-view-permissions',
  templateUrl: './view-permissions.component.html',
  styleUrls: ['./view-permissions.component.css']
})
export class RolesViewPermissionComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService:ApiService,
    public storedData:StoredData,
  ) { }
  rolesForm: FormGroup
  message:any;
  roleId:any;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.roleId = this.route.url.split("/")[2];
    this.getRoles();
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }

  getValidation(){
    return (this.rolesForm.valid);
  }

  roleName=''
  permissionArray=[]
  getRoles(){
    this.apiService.startLoader()
    this.apiService.getCall('roles/view/'+this.roleId, {}).subscribe((result: any[]) => {
        // console.log(result);
        var data= result['data'][0];
        this.roleName=data.name;
        this.permissionArray=data.data
        // console.log(this.permissionArray);
        this.apiService.stopLoader()
        
    });
  }
  submitBusinessForm():void{
    let formData = this.rolesForm.value;
    this.apiService.startLoader() 
    var url ='roles/add';
    
    this.apiService.postCall(url, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.message = result['msg'];
        if(result['roleId']){
          this.roleId = result['roleId'];
        }
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }



  selectAsset(ev, part) {
    var status=0;
    if (ev.target.checked) {
      status=1
    }else{
      status=0
    }
    // console.log(part);
    let formData ={
      "id": part.id,
      "actionStatus": status
    }
    this.apiService.postCall('roles/savePermissions/'+this.roleId, {}, formData).subscribe((result: any[]) => {
      if(part.action == 'View'){
        $("."+part.permissionModuleId+"All"+part.subModuleName).trigger("click");
      }
    });

  }
  goToIndex(){
    this.route.navigate(['/roles-index']);
  }


}