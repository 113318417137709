import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
const { format, utcToZonedTime } = require("date-fns-tz")
@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private http: HttpClient,

		private route: ActivatedRoute,
		public router: Router,
	) { }

	//baseUrl = 'http://localhost/proptor/'
	// baseUrl = environment.baseUrl;
	// permitUrl = environment.permitUrl;
	//baseUrl = 'https://api.proptorapp.com/'
	baseUrl = 'https://stagingapi.proptorapp.com/'

	permitUrl = 'https://stagingapi.proptorapp.com/'
	//permitUrl = "https://permits.proptorapp.com/"
	token: any;
	private headers(): HttpHeaders {


		let headers = new HttpHeaders().set('Authorization', 'bearer ' + this.token);

		return headers;
	}


	private headers2(): HttpHeaders {
		let headers = new HttpHeaders().set('Authorization', 'bearer ' + this.token);
		headers.set('Accept', 'application/json');
		headers.set('content-type', 'application/x-www-form-urlencoded');

		return headers;
	}

	loader: boolean = false
	startLoader() {
		this.loader = true;
	}

	stopLoader() {
		this.loader = false;
	}
	getCall(url, params?) {

		return this.http.get(this.baseUrl + url, { params: (params) as HttpParams, headers: this.headers() })

	}
	graphPostCall(url, params?, body?) {
		let headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'bearer ' + this.token
		}
		return this.http.post(this.baseUrl + url, body, { params: (params) as HttpParams, headers: headers })
	}
	postCall(url, params?, body?) {
		return this.http.post(this.baseUrl + url, body, { params: (params) as HttpParams, headers: this.headers() })
	}
	postCallIpAddress(url, params?, body?) {
		return this.http.post('http://13.235.239.200/stagingapi_proptor/' + url, body, { params: (params) as HttpParams, headers: this.headers() })
	}

	postCall2(url, params?, body?) {
		return this.http.post(this.baseUrl + url, body, { params: (params) as HttpParams, headers: { Accept: 'application/json', 'content-type': 'application/json', 'Authorization': 'bearer ' + this.token } })
	}
	uploadCall(url, params?, body?) {
		return this.http.post(this.baseUrl + url, body, { params: (params) as HttpParams, headers: this.uploadheaders() })
	}

	loginAPi(url, params?, body?) {
		return this.http.post(this.baseUrl + url, body, { params: ({}) as HttpParams, headers: { Accept: 'application/json' } })
	}

	private uploadheaders() {



		let headers = {
			'Accept': 'application/json',
			'Access-Control-Allow-Origin': '*',
			//   'Content-Type': 'form-data;' ,
			'Authorization': 'bearer ' + this.token
		}


		return headers;
	}

	deletFile(url, params?) {

		return this.http.post(this.baseUrl + url, {}, { params: (params) as HttpParams, headers: this.uploadheaders() })

	}
	buisnessId: any;
	userId: any
	authId: any;
	outletId: any;
	role: any;
	roleId: any;
	userType: any;
	userName: any;
	buisnessLogo: any;
	buisnessName: any;
	permit_start_time: any;
	permit_end_time: any;
	permit_user_id: any;
	permitMsgDispaly:any;
	getLoginDetails() {
		// console.log(localStorage.getItem('accessToken'))
		if (localStorage.getItem('accessToken')) {
			this.token = JSON.parse(localStorage.getItem('accessToken'))
			this.buisnessId = JSON.parse(localStorage.getItem('userDetails')).business_id
			this.userName = JSON.parse(localStorage.getItem('userDetails')).full_name
			this.outletId = JSON.parse(localStorage.getItem('userDetails')).outlet_id
			this.userId = JSON.parse(localStorage.getItem('userDetails')).id
			this.authId = JSON.parse(localStorage.getItem('userDetails')).id
			this.role = JSON.parse(localStorage.getItem('userDetails')).role
			this.roleId = JSON.parse(localStorage.getItem('userDetails')).role
			this.userType = JSON.parse(localStorage.getItem('userDetails')).user_type
			this.buisnessLogo = JSON.parse(localStorage.getItem('userDetails')).businessLogoPath
			this.buisnessName = JSON.parse(localStorage.getItem('userDetails')).buisnessName
			if (localStorage.getItem('businessDetailsApiService') && localStorage.getItem("businessDetailsApiService") != null) {
				this.permit_start_time = JSON.parse(localStorage.getItem('businessDetailsApiService')).permit_start_time
				this.permit_end_time = JSON.parse(localStorage.getItem('businessDetailsApiService')).permit_end_time
				this.permit_user_id = JSON.parse(localStorage.getItem('businessDetailsApiService')).permit_user_id
				this.permitMsgDispaly = JSON.parse(localStorage.getItem('businessDetailsApiService')).permitMsgDispaly
			} else {
				localStorage.clear();
			}
		}
	}
	checkUserStatusFlag() {
		if (localStorage.getItem('accessToken')) {
			this.getCall('users/checkUserStatus/' + this.userId, {}).subscribe((result: any[]) => {
				if (result['isActiveFlag'] == 1) {

				} else if (result['isActiveFlag'] == 0) {
					localStorage.setItem('userCred', '');
					localStorage.clear();
					//localStorage.setItem("userCred" , obj)
					this.token = "",
						localStorage.setItem('accessToken', '');
					this.router.navigate(['/login-view']);
				}
			},
				error => {
					if (error.status == 401) {
						this.router.navigate(['/login-view']);
					}
				});
		}
	}
	convertCureency() {
		var currency = localStorage.getItem("currency");
		if (currency && currency != undefined) {
			return currency;
		} else {
			return '₹';
		}
	}
	convertTimeZone(date, amPm) {
		if (date && date != '-' && date != "null" && date != 'Invalid Date' && date != '') {
			let time = date;
			date = new Date(date);
			if (date && date != '-' && date != "null" && date != 'Invalid Date') {
				var timeZone = localStorage.getItem("zone");
				if (timeZone == 'undefined' || timeZone == '' || timeZone == null || timeZone == 'null') {
					timeZone = 'Asia/Kolkata'
				}
				const timeInBrisbane = utcToZonedTime(date, timeZone);
				return format(timeInBrisbane, amPm);//'yyyy-MM-dd HH:mm:ss'+' '+amPm);
			} else if (time.length == 8) {
				return time;
				//date = "2022-08-03 "+time;
				var today = new Date();
				date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.split(":")[0], time.split(":")[1], 0);
				var timeZone = localStorage.getItem("zone");
				if (timeZone == 'undefined' || timeZone == '' || timeZone == null || timeZone == 'null') {
					timeZone = 'Asia/Kolkata'
				}
				const timeInBrisbane = utcToZonedTime(date, timeZone);
				return format(timeInBrisbane, amPm);
			} else {
				return '-';
			}
		} else {
			return '';
		}
	}
}

