import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder,Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-trainings-assign-user',
  templateUrl: './trainings-assign-user.component.html',
  styleUrls: ['./trainings-assign-user.component.css']
})
export class TrainingsAssignUserComponent implements OnInit {
  constructor(
    public apiService:ApiService,
    public _location:Location,
    public router:Router,
    public formBuilder:FormBuilder,

  ) { }

  propertyList=[]
  userList=[]
  trainingList=[]
  trainingForm = this.formBuilder.group({
    trainingId: [null, Validators.required],
    businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    outletId: [null, Validators.required],
    userId:[null ],
    file:[null]
  });

  businessId=0;
  outletId=0
  // ngOnInit(): void {
    ngOnDestroy(){
      document.body.style.overflowY = 'scroll';
    }
    
  trId=null
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    this.trId = this.router.url.split("/")[2];
    

    this.getOutLetList();
    this.getTrainingList();
    if(this.apiService.roleId==1){
      this.getPropertyList(0,0);
    }
    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      this.businessId=this.apiService.buisnessId;
      if(this.apiService.roleId==3){
        this.outletId=this.apiService.outletId;
        this.getPropertyList(this.businessId,this.apiService.userId);
      }else{
        this.getPropertyList(this.businessId,0);
      }
    }
    
  }
  getOutLetList(){
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj  ).subscribe((result: any[])=>{
      this.propertyList = result['data']
      // console.log(result)

      let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)
      if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
        this.trainingForm.patchValue({
          outletId : this.propertyList[idx].id
        })
        this.getUserList()
      }

    })
  }
  getUserList(){
    this.apiService.startLoader()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.trainingForm.value.outletId,
    }
    this.apiService.getCall('subUsers/getSubUsers/'+this.apiService.buisnessId+'/'+this.trainingForm.value.outletId,{}).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
    // // console.log(jsonToBeUsed)
   this.userList = result['data']
},
error =>{
  this.apiService.stopLoader()
});
  }
  getTrainingList(){
    let obj ={
      "businessId": this.apiService.buisnessId,
      //"outletId":outletId,
    }
    this.apiService.postCall('trainings/trainingLists',{} , obj  ).subscribe((result: any[])=>{
      this.trainingList = result['data']
      // console.log(result)
      if(parseInt(this.trId) >0){
        this.trainingForm.patchValue({ trainingId : parseInt(this.trId) })
      }
    })
  }
  goBack():void{
    this._location.back();
  }
  groupList:[]
  getPropertyList(businessId,userId){
    this.apiService.startLoader()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.propertyList = [];
      this.propertyList = result['data'];
      this.groupList = result['groups'];
    })
  }

  validateDate(satrtDate , endDate){
    //  // console.log(satrtDate , endDate)
if(satrtDate && endDate){
    if(endDate <= satrtDate) return true
    else return false
   }
  }

    userSelectedList=[]
    selectUser(){
      if(this.trainingForm.value.userId!=0){
        // console.log(this.userSelectedList.indexOf(this.trainingForm.value.userId))
        if(this.userSelectedList.indexOf(this.trainingForm.value.userId) ==-1){
        // if(this.userSelectedList.length <5){
          this.userSelectedList.push(this.trainingForm.value.userId)
          this.trainingForm.patchValue({userId : null})
          // }else{
          //   this.trainingForm.patchValue({userId : null})
          //   this.showAssigneeLimitFlag= true
          // }
        }else{
          this.trainingForm.patchValue({userId : null})
        }
    }else if(this.trainingForm.value.userId==0){
      for(var i=0;i<this.userList.length;i++){
        this.userSelectedList.push(this.userList[i])
      }
      this.trainingForm.patchValue({userId : null})
    }
  }
    showAssigneeLimitFlag:boolean;
    removeUser(d){
      let idx = this.userSelectedList.findIndex(x=> x == d)
  
      this.userSelectedList.splice(idx,1)
      this.showAssigneeLimitFlag= false
    }
    getValidation(){
      // console.log(this.trainingForm.valid && this.userSelectedList[0])
      return this.trainingForm.valid && this.userSelectedList[0] && this.trainingForm.value.trainingId != ""
    }
  message
  saveTraining():void{

    let bId;
    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      bId=this.apiService.buisnessId
    }
    let userArry:any=[]
    for(let d of this.userSelectedList)userArry.push(d.id)
    // console.log(this.trainingForm.value)
    let testData: FormData = new FormData();
    testData.append('trainingId',this.trainingForm.value.trainingId);
    testData.append('businessId',this.apiService.buisnessId);
    testData.append('propertyId', this.trainingForm.value.outletId);
    testData.append('authId',this.apiService.authId);
    testData.append('assignUsers', JSON.stringify(userArry));
    this.apiService.startLoader()
    this.apiService.postCall('training-users/add',{},testData).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader();
      if(result['success'] == true){
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      }
    })
  }

  openDate(id){
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  backClicked(){
    this.router.navigate(['/training-list'])
  }

  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

}
