import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { FormGroup, FormArray,FormControl, FormBuilder,Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-survey-add',
  templateUrl: './survey-add.component.html',
  styleUrls: ['./survey-add.component.css']
})
export class SurveyAddComponent implements OnInit {

  constructor(
    public router: Router,
    public _location: Location,
    public apiService: ApiService,
    public formBuilder:FormBuilder,
    public storedData:StoredData
  ) { }

  surveyType = true;
  propertyList = [];
  locationList = [];
  surveyForm = this.formBuilder.group({
    businessId:[null , Validators.required],
    outletId : [null , Validators.required],
    feedbackLocation:[null , Validators.required],
    type:1,
    authId:this.apiService.authId,
    surveyName:[null , Validators.required],
    // groups: this.formBuilder.array([this.initGroups()]),
    question:[null],
    responseTypeText:0,
    responseTypeSignature:0
  });

  surveyUrl;
  surveyId;

  disableSubmit:boolean;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    // // console.log("Survey Type :",this.surveyType);
    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      this.getPropertyList();
      this.surveyForm.patchValue({businessId:this.apiService.buisnessId});
    }
    this.surveyUrl = this.router.url.split("/")[2]
    if(this.surveyUrl){
      this.getSurveyDetails1();
    }
    this.getBusinessList();
    
    
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  
  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  groups=[{groupName : null ,id:undefined , data:[{question:null, status:true,responseTypeText:0,responseTypeSignature:0,id:undefined}]}]

  addQuestion(d)
  {
    this.groups[d].data.push({question:null, status:null,responseTypeText:0,responseTypeSignature:0,id:undefined})
  }

  addSection(){
    this.groups.push({groupName : null ,id:undefined, data:[{question:null, status:true,responseTypeText:0,responseTypeSignature:0,id:undefined}]})
  }
  removeQuestion(i , j){
    // console.log(this.groups[i])
    if(this.groups[i].data[j] && this.groups[i].data[j].id && this.groups[i].data[j].id != null){
      this.removeQuestionsId.push(this.groups[i].data[j].id);
    }
    this.groups[i].data.splice(j,1)
  }
  
  removeSection(i ){
    // console.log(this.groups);
    if(this.groups[i].id){
      this.removeSectionIds.push(this.groups[i].id);
    }
    this.groups.splice(i,1)
  }



  getSurveyDetails1(){
    this.groups=[]
    this.apiService.startLoader()
    this.apiService.postCall('feedbackQuestions/view/'+this.surveyUrl,{},{}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.surveyForm.patchValue({
        outletId:result['data']['outletId'],
        feedbackLocation:result['data']['feedbackLocationId'],
        type:result['data']['type'] == 1? true: false,
        question : result['data']['questions'],
        responseTypeText : result['data']['responseTypeText'],
        responseTypeSignature : result['data']['responseTypeSignature'],
        surveyName : result['data']['surveyName'],
        businessId : result['data']['businessId'],
      })

      for( let d of result['data']['groups']){
        let question=[]
        for(let j of d.questions){
          question.push({question:j.question , status:j.status == 1? true:false ,responseTypeText:j.responseTypeText == 1? true:false ,responseTypeSignature:j.responseTypeSignature == 1? true:false , id:j.questionId})
        }
        this.groups.push({ groupName: d.groupName , data:question ,id:d.groupId })
      }
      // console.log(this.groups)
      this.surveyId = result['data']['id']
      this.getPropertyList();
    })
  }
  
  getPropertyList() {
    this.apiService.startLoader()
    let businessId = null;
    let userId = 0;
    if(this.apiService.roleId==1){
      businessId=this.surveyForm.value.businessId;
    }else if(this.apiService.roleId==2){
      businessId = this.apiService.buisnessId
    }else if(this.apiService.roleId==3){
      businessId = this.apiService.buisnessId
      userId = this.apiService.userId
    }
    let obj = {
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": businessId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + businessId,{} ,obj).subscribe((result: any[]) => {
      this.locationList = [];
      this.propertyList = [];
      this.apiService.stopLoader()
      this.propertyList = result['data'];
    })
  }


  getLocationList(event:any){
    let businessId = null;
    if(this.apiService.roleId==1){
      businessId=this.surveyForm.value.businessId;
    }else if(this.apiService.roleId==2){
      businessId = this.apiService.buisnessId
    }else if(this.apiService.roleId==3){
      businessId = this.apiService.buisnessId
    }

    this.apiService.startLoader()
    this.apiService.getCall('feedback-locations/feedback-location-list/'+businessId+'/'+this.surveyForm.value.outletId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.locationList = result['data']
    });
  }

  goBack():void{
    this._location.back();
  }

  initGroups(){
    return this.formBuilder.group({
      groupName:[null],
      data:new FormArray([
        this.initQuestions()
      ]),
    });
  }

  initQuestions(){
    return this.formBuilder.group({
      question:[null],
      status:[null],
      responseTypeText:0,
      responseTypeSignature:0,
    });
  }

  addMoreQuestions(i:any):void{
    const control = <FormArray>this.surveyForm.get('question');
    control.push(this.initQuestions());
  }

  addMoreGroups():void{
    const control = <FormArray>this.surveyForm.get('groups');
    control.push(this.initGroups());
  }

  backClicked(){
    this.goBack();
  }
  message
  removeSectionIds=[]
  removeQuestionsId=[]
  submitSurvey():void{
    // console.log("forms Data : ",this.surveyForm.value);
    let groups = this.groups;
    let groupsArray = [];
    for(let d of groups){
      for(let e of d.data){
         e.status == true ? 1:0;
      }
      groupsArray.push(d);
    }
    let businessId = null;
    if(this.apiService.roleId==1){
      businessId=this.surveyForm.value.businessId;
    }else if(this.apiService.roleId==2){
      businessId = this.apiService.buisnessId
    }else if(this.apiService.roleId==3){
      businessId = this.apiService.buisnessId
    }
  
    let obj={
      "businessId": businessId,
      "outletId": this.surveyForm.value.outletId,
      "surveyName" : this.surveyForm.value.surveyName,
      "feedbackLocation":   this.surveyForm.value.feedbackLocation,
      "type": this.surveyForm.value.type? 1 : 0,  
      "authId": this.apiService.authId? this.apiService.authId : 1,
      "removeSection":this.removeSectionIds,
      "removeQuestionsId":this.removeQuestionsId
      // "question":this.surveyForm.value.question ?this.surveyForm.value.question : '' ,
      // 'authId':1
    }
    if(this.surveyForm.value.type)obj['groups']=groupsArray
    else obj['question'] = this.surveyForm.value.question ?this.surveyForm.value.question : null

    if(!this.surveyId){
    this.apiService.startLoader()
    this.apiService.postCall('feedbackQuestions/questionsAdd',{} ,obj).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      // if(result['success'] == true){
        this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();

    //   setTimeout(() => {
    //     (<HTMLInputElement>document.getElementById('dismissOk')).click();

    // }, 1000);
      // }
    });
  } else{
    this.apiService.startLoader()
    this.apiService.postCall('feedbackQuestions/questionsEdit/'+this.surveyId,{} ,obj).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      
        this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();

    //   setTimeout(() => {
    //     (<HTMLInputElement>document.getElementById('dismissOk')).click();

    // }, 1000);
      
    });
  }
  }

  getValidation(){

    let valid = false;

    let string = JSON.stringify(this.groups)
    // console.log(string)
    let idx = string.indexOf('"question":null'  )
    let idx1 = string.indexOf( '"groupName":null'  )
    let idx2 = string.indexOf( '"groupName":""' )
    let idx3 = string.indexOf(  '"question":""' )
    // console.log(idx)
    if(!this.surveyForm.value.type){
      idx = -1 ;
      idx1 =-1;
      idx2 = -1;
      idx3 = -1
      if(this.surveyForm.value.question == null){
        idx = 1 ;
      }
    }
    // for(let d of this.groups){
    //   if
    // }
    return this.surveyForm.valid  && idx == -1 && idx1 == -1 && idx2 == -1 && idx3 == -1 
  }
}


// groups=[{groupName : null ,id:null , data:[{question:null, status:null,}]}]