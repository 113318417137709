import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-admin-add',
  templateUrl: './business-admin-add.component.html',
  styleUrls: ['./business-admin-add.component.css']
})
export class BusinessAdminAddComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService:ApiService,
    public storedData:StoredData,
  ) { }
  businessList:any;
  businessId:any;
  userId=null;
  selectedTab:any;
  userForm: FormGroup;
  saveData=false;
  message:any;

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.businessId = this.route.url.split("/")[2];
    // console.log("Busin : ",this.businessId);
    this.getUsersList();
    this.userForm = this.fb.group({
      businessId:[null , Validators.required],
      fullName:[null , Validators.required],
      email:[null,[Validators.required,Validators.email]],
      mobile:[null,[Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      password:[null,Validators.required],
      cpassword:[null,Validators.required],
      roleId:2,
      userId:this.apiService.authId
    });
    if(this.businessId){
      this.userForm.patchValue({businessId:this.businessId});
    }
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

  allUser=[]
  users=[]
  getUsersList():void{
    this.apiService.startLoader()
    this.apiService.getCall('users/getBusinessAdmins/'+this.businessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.businessList = []
      if(result['success'] == true){
        this.users = result['data']['users'];
        this.allUser = result['data']['allUser'];
      }
    })
  }
  
  getValidation(){
    if(this.userForm.value.password != this.userForm.value.cpassword){
      return false;
    }
    return (this.userForm.valid);
  }
  submitUserData(){
    let formData = this.userForm.value;
    //formData.businessId = this.businessId;
    // console.log(formData);
    if(this.selectedTab == 'existing'){
      formData.existingId = this.userId;
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/add', {}, formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.saveData = result['success'];
      this.message = result['msg'];
      //this.businessId = result['businessId'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }
  changeHandle(evt:any){
    var target = evt.target.value;
    // console.log("target : ",target);
    this.selectedTab = target; 
  }
  goToIndex(){
    this.route.navigate(['/business-index'])
  }
  nextStep(){
    let bId = this.route.url.split("/")[2];
    this.route.navigate(['/business-index']);
  }

}
