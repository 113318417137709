import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../../common/services/apiCalls.service';
import { StoredData } from '../../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-permit-question-checkpoint-list',
  templateUrl: './permit-question-checkpoint-list.component.html',
  styleUrls: ['./permit-question-checkpoint-list.component.css']
})
export class PermitQuestionCheckpointListComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  statusSelection;
  addPermission = false;
  editPermission = false;
  constructor(public route: Router, public router: Router, public apiService: ApiService, public storedData: StoredData) {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    // for(var i=0;i<permission.length;i++){
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
    //     this.addPermission=true
    //   }
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
    //     this.editPermission=true
    //   }
    //
    // }
  }
  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ permitList: this.tabSelected, assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }))
  }

  ngOnInit(): void {

    // var meterListPagination = localStorage.getItem('meterListPagination');
    // if (meterListPagination) {
    //   this.activePage = parseInt(meterListPagination);
    // }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).permitList
      let tabselection = JSON.parse(localStorage.getItem("tabselection")).permitList
      if (tabselection == undefined) {
        this.tabSelected = 'active';
      }
    }
    if (this.router.url.split("/")[3]) {
      this.tabSelected = this.router.url.split("/")[3];
      if (this.router.url.split("/")[3] == 'active') {
        this.getList(1);
        this.setTabSelection();
      }
      else if (this.router.url.split("/")[3] == 'archived') {
        this.getList(0);
        this.setTabSelection();
      }
    } else {
      if (this.tabSelected == 'active') {
        this.getList(1);
      }
      else if (this.tabSelected == 'archived') {
        this.getList(0);
      }
    }
    this.getPropertyList();
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }

  goBack() {
    this.route.navigate(['/permit-setting']);
  }

  tabSelected = 'active'
  getList(isActive: any = 1) {
    this.apiService.startLoader();
    this.applyFlag = false;
    this.apiService.startLoader();
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      isActive: Number(isActive),
      sortType: this.sort,
      permitId: this.selectedFiler.name,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'roleId': this.apiService.roleId,
    };
    this.apiService.postCall('Questions/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];

      this.apiService.stopLoader();

    });
  }

  message = ''
  changeIsActive(id, isActive?) {
    this.apiService.startLoader();
    let obj = {
      'isActive': Number(isActive),
      'authId': this.apiService.userId,
    };
    this.apiService.startLoader();
    this.apiService.postCall('Questions/edit/' + id, {}, obj).subscribe((result: any[]) => {
      this.message = 'Questions Updated Successfully';
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.apiService.stopLoader();
      this.getList(this.tabSelected == 'active' ? 1 : 0)

    });
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { name: null, property: null, business: null };
    this.getList(this.tabSelected == 'active' ? 1 : 0);
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1;

  totalnoOfPages;

  changePage(ev) {
    // console.log(this.pageSize);
    this.getList(this.tabSelected == 'active' ? 1 : 0)
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }

  workList = [];


  getCustomName(d) {
    return 'Every ' + d.frequency_no + ' ' + d.frequency_unit;
  }

  pagination(d) {
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('meterListPagination', d);
      this.getList(this.tabSelected == 'active' ? 1 : 0)
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList(this.tabSelected == 'active' ? 1 : 0)
  }


  selectedFiler = { name: null, property: null, business: null };

  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }
  }

  filterPropertySearch;
  propertFilter = false;

  filterProperty(ev) {
    // console.log(ev);
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase());
      }
    });
  }

  applyFlag = false;

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      isActive: Number(this.tabSelected == 'active' ? 1 : 0),
      sortType: this.sort,
      question: this.selectedFiler.name,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'roleId': this.apiService.roleId,

    };
    this.apiService.startLoader();
    this.workList = []
    this.apiService.postCall('Questions/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];

      this.apiService.stopLoader();

    });
  }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.name != null) {
      length = length + 1;
    }
    if (this.selectedFiler.property != null) {
      length = length + 1;
    }
    if (length == 0 || !this.applyFlag) {
      return '(None Selected)';
    } else if (this.applyFlag) {
      return `(${length} Filter Selected)`;
    }
  }




  deactiveSelected() {
  }

  dublicateSelected() {
  }

  filterSelect = false;
  propertyList = [];
  filterpropertyList = [];

  getPropertyList() {
    this.apiService.startLoader();
    // console.log(this.selectedFiler.business);
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];

    });


  }

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }


  callGetOutside() {
    // console.log('d');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;

      this.propertFilter = false;
      // this.selectFreq = false
    }
    if (btn.id != 'changeButton') {
      this.statusSelection = undefined;
    }
  }

  closeAll() {

    this.propertFilter = false;
  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }


  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };

    pdfMake.createPdf(documentDefinition).download();

  }

}
