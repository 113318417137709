import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-permit-list',
  templateUrl: './permit-list.component.html',
})
export class PermitListComponent implements OnInit {

  constructor(  public _location: Location,) {
  }
  tabSelected = 'active';
  ngOnInit() {
  }
  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ permitList: this.tabSelected, assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }))
  }

}
