import { Component, HostListener, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
// import { ColDef } from 'ag-grid-community';
// import { ApiService } from '../common/services/apiCalls.service';
import { GoogleChartsModule } from 'angular-google-charts';
import * as moment from 'moment';
declare var d3: any;
import { BrowserModule } from '@angular/platform-browser';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-dashboard-checklist-percentage',
  templateUrl: './dashboard-checklist-percentage.component.html',
  styleUrls: ['./dashboard-checklist-percentage.component.css']
})
export class DashboardChecklistPercentageComponent implements OnInit {
  chartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['Department', 'Avg. Score:', { role: 'annotation' }],
    options: {
      responsive: true,
      borderRadius: 15,
      pieHole: 0.4,
      bar: {
        groupWidth: "75%"
      },
      colors: ['#33CCB2'],
      displayExactValues: true,
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: {
        title: ''
      },
      vAxis: {
        title: ''
      },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };

  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;
  emailAddress
  errorFlag
  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  // filterPropertySearch: any;
  // filterDepartmentSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];

  selectedFiler = { department: null, property: null, user: null, startDate: null, ensDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  assignedFilter = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;

  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      // this.showFilter = false
      this.propertyFiletr = false;
      this.assignedFilter = false;
      this.departmentFilter = false;
      // this.statsuFilter = false;
      // this.typeFilter = false;
      // // this.showAddMenu = false;
      // this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.assignedFilter = false;
      this.departmentFilter = false;
      // this.typeFilter = false;
      // this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }
  closeAll() {
    this.propertyFiletr = false;
    this.assignedFilter = false;
    this.departmentFilter = false;
    // this.typeFilter = false;
    // this.businessFilter = false;
  }
  departmentList
  getDepartmentList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('types/pluckDepartments/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }

  applyFlag = false;
  applyFilter() {
    localStorage.setItem("inspectionChecklistPercentageFilter", null);
    this.applyFlag = true;
    this.getAsset(this.propertyIdFirst);
  }

  clear() {
    localStorage.removeItem('inspectionChecklistPercentageFilter');
    this.applyFlag = false;
    this.selectedFiler = { department: null, property: null, user: null, startDate: null, ensDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)
    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {
      this.selectedFiler.property = this.locationList[idx]
    }
    this.ngOnInit()
  }
  locationList: any;
  propertyIdFirst
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      this.locationList = result['data']
      this.filterpropertyList = result['data']
      this.propertyIdFirst = result['data'][0].id;
      this.propertyNameDisplay = result['data'][0].outletName;
      if (this.apiService.outletId) {
        let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId);
      }
      // console.log(this.apiService.roleId)
      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();
      this.getAsset(this.propertyIdFirst);
    });
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  // propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }
  columnDefs
  rowData
  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData) {
  }
  downloadFlag = false
  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if ((localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == 'null' || localStorage.getItem('accessToken') == '')) {
      this.router.navigate(['/login-view'])
    }
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        const date = new Date();
        let endDate = new Date()
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        firstDay.setMonth(firstDay.getMonth() - 2)
        this.startDateFilterSet = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD');
        this.endDateFilterSet = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');
      }
    }
    if (this.apiService.role == 1) {
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    this.getLocationList()
    this.getDepartmentList()
    this.getUserList()

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
      }
      if (this.apiService.role == 1) {
        this.businessFeatureAttendance = true
      }
    }
    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);
  }
  selected: any = 'dashboard-checklist';
  navigateTo(rout) {
    this.selected = rout
    this.route.navigate([rout])
  }
  filterUserSearch
  filterUsers(ev) {
    this.userList = this.filetrUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  userList
  filetrUserList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.userList = result['data']
      this.filetrUserList = this.userList
    })
  }
  checklistDashboardToday: any;
  checklistDashboard
  propertyNameDisplay
  startDateFilterSet
  endDateFilterSet
  getAsset(propertyIdFirst) {
    this.apiService.startLoader()
    const date = new Date();
    let endDate = new Date()
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    firstDay.setMonth(firstDay.getMonth() - 2)
    let body1 = {};
    if (localStorage.getItem("inspectionChecklistPercentageFilter") && localStorage.getItem("inspectionChecklistPercentageFilter") != null && localStorage.getItem("inspectionChecklistPercentageFilter") != 'null') {
      let inspectionChecklistPercentageFilter = JSON.parse(localStorage.getItem("inspectionChecklistPercentageFilter"));
      this.selectedFiler = inspectionChecklistPercentageFilter;
      this.propertyNameDisplay = this.selectedFiler.property ? this.selectedFiler.property.outletName : this.propertyNameDisplay;
      this.startDateFilterSet = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD');
      this.endDateFilterSet = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');
      body1 = {
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : propertyIdFirst,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
        endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      }
      this.applyFlag = true;
    } else {
      this.propertyNameDisplay = this.selectedFiler.property ? this.selectedFiler.property.outletName : this.propertyNameDisplay;
      this.startDateFilterSet = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD');
      this.endDateFilterSet = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');
      body1 = {
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : propertyIdFirst,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
        endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      }
      localStorage.setItem('inspectionChecklistPercentageFilter', JSON.stringify(this.selectedFiler));
    }
    this.apiService.postCall('todays-checklists/checklistScores', {}, body1).subscribe((result: any[]) => {
      // console.log(result);
      if (result) {
        this.checklistDashboardToday = result['data'].checklist;
      } else {
        this.checklistDashboardToday = {
          "checklist": [
            [
              "Department",
              "Checklist Score"
            ],
            [
              "Dep 1",
              44
            ],
            [
              "Dep 2",
              42
            ],
            [
              "Dep 3",
              41
            ],
            [
              "Dep 4",
              50
            ],
            [
              "Dep 5",
              43
            ]
          ],
          "getDetails": [],
          "rowCount": 0
        }
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      this.groupedBarchart(this.checklistDashboardToday);
    })
  }
  groupedBarchart(checklistDashboardToday) {
    if (checklistDashboardToday) {
      for (let s in checklistDashboardToday) {
        //console.log(checklistDashboardToday[s].coloumValues)
        checklistDashboardToday[s].coloumValues.splice(0, 1)
        // console.log(checklistDashboardToday[s].coloumValues)
        this.chartData = {
          type: 'ColumnChart',
          data: checklistDashboardToday[s].coloumValues,
          chartColumns: ['Department', 'Avg. Score:', { role: 'annotation' }],
          options: {
            responsive: true,
            pieHole: 0.4,
            bar: {
              groupWidth: "75%"
            },
            borderRadius: 15,
            colors: ['#33CCB2'],
            displayExactValues: true,
            legend: { position: 'bottom', alignment: 'center' },
            hAxis: {
              title: ''
            },
            vAxis: {
              title: ''
            },
            seriesType: 'bars',
            series: { 2: { type: 'line' } }
          },
          width: 1000,
          height: 400
        };
      }
    }
    this.apiService.stopLoader();
  }
  onSelect(event, data, propertyId, propertyName) {
    //console.log(propertyId);
    let row = event['selection'][0].row;
    //console.log(data[row][0]);
    let deparment = data[row][0];
    this.complianceStatus(propertyId, propertyName, deparment);
  }

  checklistDashboardChart
  complianceStatus(propertyId, propertyName, deparment) {
    const date = new Date();
    let endDate = new Date()
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    firstDay.setMonth(firstDay.getMonth() - 2)
    var obj1 = {
      "outletId": propertyId,
      "propertyName": propertyName,
      "userIdFilter": this.selectedFiler.user ? this.selectedFiler.user.id : "",
      "departmentName": deparment,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId
    }
    this.storedData.checklistScoreGraphListIndex = {
      headers: ['Property', 'Department Name', 'Checklist Name', 'Total Average Score'],
      obj: obj1,
      api: 'todays-checklists/checklistReportScores',
      tableName: 'Checklist Scoring',
      dataSource: ['propertyName', 'departmentName', 'checklistName', 'totalScore'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("checklistScoreGraphListIndex", JSON.stringify(this.storedData.checklistScoreGraphListIndex));
    this.route.navigate(['/checklist-score-graph-list'])
  }
  bySchedules(outletId, inspectionId, scheduleId) {
    const date = new Date();
    let endDate = new Date()
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    firstDay.setMonth(firstDay.getMonth() - 2)
    var obj1 = {
      "outletId": outletId,
      "scheduleId": scheduleId,
      "inspectionId": inspectionId,
      "startDate": this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
      "endDate": this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "flag": 1,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId
    }
    this.storedData.checklistSchedulesScoreList = {
      headers: ['Property', 'Checklist Name', 'Schedule Name', 'Date', 'Total Checklist Score'],
      obj: obj1,
      api: 'todays-checklists/checklistReportScheduleScores',
      tableName: 'Checklist Schedule Wise Scoring',
      dataSource: ['propertyName', 'checklistName', 'scheduleName', 'date', 'totalScore'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("checklistSchedulesScoreList", JSON.stringify(this.storedData.checklistSchedulesScoreList));
    this.route.navigate(['/checklist-schedule-score-list'])
  }
  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
  showDataHide(className) {
    let cName = 'inspectionNameSchedule-' + className;
    $('.' + cName).slideToggle();
  }
  getDate(d) {
    let pattern = 'YYYY-MM-DDTHH:mm:ss'
    return moment(d, pattern).utc(false)
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 3);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 4);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.downloadReport();
  }
  downloadReport() {
    const date = new Date();
    let endDate = new Date()
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    firstDay.setMonth(firstDay.getMonth() - 2)
    let body = {};
    if (localStorage.getItem("inspectionChecklistPercentageFilter") && localStorage.getItem("inspectionChecklistPercentageFilter") != null && localStorage.getItem("inspectionChecklistPercentageFilter") != 'null') {
      let inspectionChecklistPercentageFilter = JSON.parse(localStorage.getItem("inspectionChecklistPercentageFilter"));
      this.selectedFiler = inspectionChecklistPercentageFilter;
      this.propertyNameDisplay = this.selectedFiler.property ? this.selectedFiler.property.outletName : this.propertyNameDisplay;
      this.startDateFilterSet = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD');
      this.endDateFilterSet = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');
      body = {
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : this.propertyIdFirst,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
        endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "chart1": this.chart1,
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
        checklistDashboardToday: this.checklistDashboardToday
      }
      this.applyFlag = true;
    } else {
      this.propertyNameDisplay = this.selectedFiler.property ? this.selectedFiler.property.outletName : this.propertyNameDisplay;
      this.startDateFilterSet = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD');
      this.endDateFilterSet = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');
      body = {
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : this.propertyIdFirst,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
        endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "chart1": this.chart1,
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
        checklistDashboardToday: this.checklistDashboardToday
      }
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('todays-checklists/dashboardChecklistPercentageDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
}
