import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-stock-statements',
  templateUrl: './stock-statements.component.html',
  styleUrls: ['./stock-statements.component.css']
})
export class StockStatementsComponent implements OnInit {

  constructor(
    public route: Router, 
    public apiService: ApiService, 
    public storedData: StoredData,
    public _location: Location,
  ) { }
  
  selectedFiler = { part: null, assets: null, location: null, property: null,endDate:null,startDate:null,number:null,departmentId:null,categoryType:null }
  fromDate:Date = new Date();
  toDate:Date = new Date();
  
  sortSelected: any = null;
  sort: any = null;

  reportList = [];
  
  locationList = [];
  filterlocationList = [];
  locationFilter = false;
  filterLocationSearch: any
  
  applyFlag = false;
  
  partList = [];
  partListFilter = [];
  partFilter = false;
  partFilterSearch:any;

  filterSelect = false;

  assetList = []
  assetListFilter = []
  assetFilter = false;
  filterAssetSearch:any
  
  numberFilter = false;

  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch:any

  ngOnInit(): void {
    //this.fromDate.setDate(this.fromDate.getDate() - 30);
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.applyFlag = true;
    this.getReportListing();
    this.getPropertyList();
  }

  getReportListing() {
    this.apiService.startLoader()
    let body = {
      sortBy: this.sortSelected,
      sortType: this.sort,
      partId: this.selectedFiler.part ? this.selectedFiler.part.id : null,
      departmentId:this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate :this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
      assetId: this.selectedFiler.assets ? this.selectedFiler.assets.id : null,
      download: 0,
      number: this.selectedFiler.number,
      categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('parts/getStockStatement', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data']
      this.apiService.stopLoader()
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { part: null, assets: null, location: null, property: null,endDate:null,startDate:null,number:null,departmentId:null,categoryType:null }
    this.fromDate = null;
    this.toDate = null;
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getReportListing()
  }

  sortData(data:any) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getReportListing()
  }

  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.locationList = result['data']
      this.filterlocationList = result['data']
      this.storedData.locationList = this.filterlocationList;
    })
  }
 
  filterLocation(ev:any) {
    this.locationList = this.filterlocationList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })
  }

  applyFilter() {
    this.applyFlag = true;
    this.getReportListing();
  }

  getFilterStatus() {
    let length = 0;
    // console.log(this.selectedFiler)
    if (this.selectedFiler.part != null ) length = length + 1
    if (this.selectedFiler.location != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.assets != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.departmentId != null) length = length + 1
    if (this.selectedFiler.number != null &&  this.selectedFiler.number != '') length = length + 1
    if (this.selectedFiler.categoryType != null &&  this.selectedFiler.categoryType != '') length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  getAssetList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      //"statusNotIn":"5,8",
      "allAssetFlag":1
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']
      this.assetListFilter = result['data'];
    })
  }
  
  filterAsset(ev:any) {
    this.assetList = this.assetListFilter.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase())
      }
    })
  }

  getPartsList():void{
    this.apiService.startLoader()
    let obj = {
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "assetId" : this.selectedFiler.assets ? this.selectedFiler.assets.id:'',
    }
    this.apiService.postCall('parts/partsDropdownList', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.partList = []
      if(result['success'] == true){
        this.partList = result['data'];
        this.partListFilter = result['data'];
      }
    })
  }
  
  filterPart(ev:any){
    this.partList = this.partListFilter.filter((item) => {
      if (item.name != null) {
        return item.assetName.toLowerCase().includes(this.partFilterSearch.toLowerCase())
      }
    })
  }
  departmentList = [];
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.departmentList = result['departments'];
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      this.getLocationList()
      this.getAssetList()
    })
  }

  filterProperty(ev:any) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.assetFilter = false;
      this.locationFilter = false;
      this.propertFilter = false;
      this.partFilter = false;
    }
  }
  closeAll() {
    this.assetFilter = false;
    this.locationFilter = false;
    this.propertFilter = false;
    this.partFilter = false;
  }

  downloadReport():void{
    this.apiService.startLoader()
    let body = {
      partId: this.selectedFiler.part ? this.selectedFiler.part.id : null,
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate :this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
      assetId: this.selectedFiler.assets ? this.selectedFiler.assets.id : null,
      departmentId:this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
      download: 1,
      number: this.selectedFiler.number,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('parts/getStockStatement', {}, body).subscribe((result: any[]) => {
      if(result['success'] == true){
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }

}