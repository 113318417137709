import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-stock-statements',
  templateUrl: './automated-list.component.html',
  styleUrls: ['./automated-list.component.css']
})
export class AutomatedListComponent implements OnInit {

  constructor(
    public route: Router,
    public apiService: ApiService,
    public storedData: StoredData,
    public _location: Location,
  ) { }

  selectedFiler = { property: null }
  selectUser = "";
  autocompleteTime = "";
  fromDate: Date = new Date();
  toDate: Date = new Date();
  sortSelected: any = null;
  sort: any = null;

  reportList = [];

  applyFlag = false;

  filterSelect = false;
  userFiletr = false;
  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch: any;
  userList = [];
  selectUserss = [];
  selectFreqName: 'Daily';
  ccEmailSend: '';
  selectFreq = false;
  todayDate: any = new Date();
  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    this.applyFlag = true;
    this.userFiletr = false;
    this.selectUserss = [];
    this.getReportListing();
  }
  getName(d) {
    if (this.editDataUserFlag == true) {
      if (d == this.editDataUser[0].id) {
        let idx = this.editDataUser.find(x => x.id == d)
        return idx?.full_name
      } else {
        let idx = this.filetrUserList.find(x => x.id == d)
        return idx?.full_name
      }
    } else {
      let idx = this.filetrUserList.find(x => x.id == d)
      return idx?.full_name
    }
  }
  removeUser(d) {
    let idx1 = this.selectUserss.findIndex(x => x == d)
    this.selectUserss.splice(idx1, 1);
    var ds = [];
    ds = this.selectUserss;
    this.selectUserss = []
    this.selectUserss = ds;
  }
  clearFilterUserSearch() {
    this.filterUserSearch = "";
    this.filterUsersSerach('');
  }
  filterUserSearch
  filterUsersSerach(ev) {
    this.userList = this.filetrUserList.filter((item) => {
      if (item.full_name != null) {
        return item.full_name.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  selectUsers(d) {
    let id1 = this.selectUserss.findIndex(x => x == d)
    if (id1 != -1) {

    } else {
      this.selectUserss.push(d);
      //return true
    }
    var ds = [];
    ds = this.selectUserss;
    this.selectUserss = []
    this.selectUserss = ds;
  }
  filetrUserList
  getdiasableUser(d) {
    let id1 = this.selectUserss.findIndex(x => x == d)
    return id1 != -1;
  }
  autocompleteTime1
  editDataUser = []
  editDataUserFlag = false;
  getReportListingEdit(editData) {
    this.editDataUserFlag = true;
    this.editDataUser = [];
    this.editDataUser.push({ id: editData.id, full_name: editData.full_name });
    this.selectFreqName = editData.frequency_name;
    if (editData.business.auto_send_report_time) {
      this.autocompleteTime1 = new Date();
      this.autocompleteTime1.setHours(editData.business.auto_send_report_time.split(':')[0])
      this.autocompleteTime1.setMinutes(editData.business.auto_send_report_time.split(':')[1])
      this.autocompleteTime1.setSeconds(editData.business.auto_send_report_time.split(':')[2])
    }
    this.autocompleteTime = this.autocompleteTime1;
    this.ccEmailSend = editData.cc_email_send;
    var ds = [];
    ds.push(editData.id);
    this.selectUserss = []
    this.selectUserss = ds;
  }
  removeEditData(){
    this.editDataUserFlag = false;
      this.autocompleteTime = '';
      this.selectFreqName =  null;
      this.ccEmailSend = '';
      this.selectUserss = [];
      this.editDataUser = [];
  }
  getReportListing() {
    this.apiService.startLoader()
    let body = {
      sortBy: this.sortSelected,
      sortType: this.sort,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      roleId: this.apiService.role,
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
    }
    this.apiService.postCall('users/userAutomatedReports', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data'];
      this.userList = result['pluckData'];
      this.filetrUserList = this.userList;
      this.apiService.stopLoader()
    })
  }
  saveReliever() {
    if (this.selectUserss.length != 0) {
      this.apiService.startLoader();
      for (let i = 0; i < this.selectUserss.length; i++) {
        let formData = {
          "automated_report": 1,
          "frequency_name": this.selectFreqName,
          "autocompleteTime": this.autocompleteTime ? moment(this.autocompleteTime).format('HH:mm:ss') : "",
          //"roleId": this.apiService.role,
          "businessId": this.apiService.buisnessId,
          "ccEmailSend": this.ccEmailSend
        }
        this.apiService.postCall('users/edit/' + this.selectUserss[i], {}, formData).subscribe((result: any[]) => {
          if (result['success']) {
            this.getReportListing();
          }
          else {
            this.getReportListing();
          }
        });
      }
      this.editDataUserFlag = false;
      this.autocompleteTime = '';
      this.selectFreqName =  null;
      this.ccEmailSend = '';
      this.selectUserss = [];
      this.editDataUser = [];
      //this.apiService.stopLoader();
    }
  }
  removeAutomatedUsers(id) {
    let formData = {
      "automated_report": 0,
      "roleId": this.apiService.role,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.startLoader();
    this.apiService.postCall('users/edit/' + id, {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      if (result['success']) {
        this.getReportListing();
      }
      else {
        this.getReportListing();
      }
    });
  }
  clear() {
    this.applyFlag = false;
    this.selectedFiler = { property: null }
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getReportListing()
  }
  selectFreqdata(data) {
    this.selectFreq = !this.selectFreq
    this.selectFreqName = data;
  }
  getFrequencyName() {
    if (this.selectFreqName == null) return 'Set a Frequency'
    else return this.selectFreqName
  }
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false;
      this.propertFilter = false;
      this.selectFreq = false;
    }
  }
  closeAll() {
    //this.propertFilter = false;
    this.userFiletr = false;
  }

}