import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-index',
  templateUrl: './template-index.component.html',
  styleUrls: ['./template-index.component.css']
})
export class TemplateIndexComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }

  businessId=0
  addPermission=false
  editPermission=false
  changeStatusPermission=false
  ngOnInit(): void {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Create Templates" && permission[i]['actionStatus'] == 1){
        this.addPermission=true
      }
      if(permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Edit Templates" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
      if(permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
    }

    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      this.businessId = this.apiService.buisnessId
    }
    this.getTemplateList()
    this.applyFlag = false;
  }

  paginationArray = []
  categorys = []
  departments = []
  pageSize = 10;
  pageNo: any = 1

  templateList = []

  getTemplateList() {
    this.apiService.startLoader()
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name ? this.selectedFiler.name: null,
      categoryId:this.selectedFiler.category ? this.selectedFiler.category.id: null,
      departmentId:this.selectedFiler.department ? this.selectedFiler.department.id : null,
      status:this.selectedFiler.status ? this.selectedFiler.status: null,
      isArchive:this.selectedFiler.isArchive ? this.selectedFiler.isArchive: null,
    }
    this.apiService.postCall('inspection-templates/getTemplateIndex', {}, body).subscribe((result: any[]) => {
      this.templateList = result['data']
      if(result['success'] == true){
        this.categorys = result['category']
        this.departments = result['departments']
      }
      this.setPaginationArray(result)
      this.apiService.stopLoader()
      // console.log(this.templateList);
    })
  }
  closeAll() {
  }
  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)
    return array
  }

  activePage :any= 1
  totalnoOfPages;
  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }


  changePage(ev) {
    // console.log(this.pageSize)
    this.getTemplateList();
  }

  message = '';
  changeStatus(id, flag,from) {
    if (flag) {
      flag = 1;
    } else {
      flag = 0;
    }
    let obj;
    if(from==1){
      obj = {
        id: id,
        isActive: flag,
        authId: this.apiService.authId
      }
    }else{
      obj = {
        id: id,
        isArchive: flag,
        authId: this.apiService.authId
      }
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('inspection-templates/edit/'+id, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.getTemplateList();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })

  }
  pagination(data) {
    if (data != 0 && data <= this.paginationArray.length){
      this.activePage = data
      this.getTemplateList()
    }
  }
  goBack(){
    this.route.navigate(['/template-library'])
  }

  detailTemplate(id){
    this.route.navigate(['/template-view/'+id])
  }
  selectedFiler = { name: null,category:null,department:null,status:null,isArchive:null}
  sortSelected= 'id'
  sort = 'desc'

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getTemplateList()
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.getTemplateList()
  }

  clear() {
    this.activePage=1
    this.jumpToPage=1
    this.selectedFiler = { name: null,category:null,department:null,status:null,isArchive:null }
    this.getTemplateList()
  }


  getFilterStatus() {
    let length = JSON.stringify(this.selectedFiler).split(null).length -1

    if (length == 5 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${5 - length} Filter Selected)`
  }

  openDropdown(d) {
    if ($(".stama" + d).css('display') == 'none') {
      $(".stama" + d).css("display", "block");
    } else {
      $(".stama" + d).css("display", "none");
    }
  }

  statusSelection:any;
  selectStatus(d){
    // console.log(d)
    if( this.statusSelection != 'status'+d.id) this.statusSelection= 'status'+d.id
    else this.statusSelection= undefined

    // console.log(this.statusSelection)
  }
  filterSelect = false;
  showFilter = false;
  categoryFilter = false;
  departmentFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.filterSelect = true;
      this.categoryFilter = false;
      this.departmentFilter = false;
    } else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.categoryFilter = false;
      this.departmentFilter = false;
    }
    if (btn.id == 'changeBUton') {
    }else if(btn.id != 'changeBUton'){
      $(".status-menu1").css("display", "none");
    }
    

  }
}
