import {Component, HostListener, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from '../../../common/services/apiCalls.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {StoredData} from '../../../common/services/storedData.service';

@Component({
  selector: 'app-permit-question-checkpoint-add',
  templateUrl: './permit-question-checkpoint-add.component.html',
  styleUrls: ['./permit-question-checkpoint-add.component.css']
})
export class PermitQuestionCheckpointAddComponent implements OnInit {

  permitQuestionForm: FormGroup;
  permitSubQuestionForm: FormGroup;
  dropdownSettings?: IDropdownSettings;
  workDropdownSettings?: IDropdownSettings;
  associatedDropdownSettings?: IDropdownSettings;
  workList = [];
  propertyList = [];
  associatedPermitList = [];
  subquestionList = [];
  routeId;
  responseQuestionId;
  statusList = [{id: 0, name: 'Deactive'}, {id: 1, name: 'Active'}];
  statusSelection;
  tabSelected = 'step1';

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService, private routes: ActivatedRoute) {
    this.routes.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });

  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.permitQuestionForm= this.fb.group({
      businessId: [null],
      outletId: [null,Validators.required],
      permitId: [null,Validators.required],
      associatedPermitId: [null, Validators.required],
      type: [null, Validators.required],
      authId: [null],
      question: [null, Validators.required],
      isActive: 1
    });
    
    if(this.routeId){
      this.responseQuestionId = this.routeId
    }else if(this.responseQuestionId){
      this.routeId = this.responseQuestionId
    }
    this.permitSubQuestionForm= this.fb.group({
      outletId:[null],
      permitId: [null],
      authId:[null],
      answer:[null,Validators.required],
      questionId:[null]
    });


    this.getPropertyList();
  }
  onClickStep2(){
    if(parseInt(this.responseQuestionId)>0 || parseInt(this.routeId) > 0){
      this.tabSelected='step2'
    }else{
      return false
    }
    
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  onItemSelect(item) {
    this.apiService.startLoader();
    this.workList = [];
    this.associatedPermitList = [];
    this.permitQuestionForm.patchValue({permitId: null, associatedPermitId: null});
    this.apiService.startLoader();
    let body = {
      pageNo: null,
      limit: null,
        outletId: item.target.value,
      'authId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('permits/index', {}, body).subscribe((result: any[]) => {
      this.workList = result['data'];
      this.apiService.stopLoader();
    });
  }



  onItemSelectPermit(item) {
    this.apiService.startLoader();
    this.associatedPermitList = [];
    this.permitQuestionForm.patchValue({associatedPermitId: null});
    let body = {
      pageNo: null,
      limit: null,
      outletId: this.permitQuestionForm.value.outletId,
      permitId: item.target.value,
      'authId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('AssociatedPermits/index', {}, body).subscribe((result: any[]) => {
      this.associatedPermitList = result['data'];
      this.apiService.stopLoader();
    });
  }

  submit() {
    if (!this.routeId) {
      this.permitQuestionForm.value.businessId = this.apiService.buisnessId;
      this.permitQuestionForm.value.authId = this.apiService.authId;
      let form = this.permitQuestionForm.value;
      this.apiService.startLoader();
      this.apiService.postCall('Questions/add', {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        if (result['success']) {
          this.message = result['msg'];
          this.responseQuestionId = result['id'];
          if(this.routeId){
            this.responseQuestionId = this.routeId
          }else if(this.responseQuestionId){
            this.routeId = this.responseQuestionId
          }
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          //this.route.navigate(['/permit-setting/questions-edit/' + this.responseQuestionId]);
          
          this.tabSelected = 'step2';
          // console.log(result['id']);
          if (this.responseQuestionId) {
            this.getSubQuestionList(this.responseQuestionId);

          }

        } else {
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openError')).click();
        }
      });
    } else {
      this.permitQuestionForm.value.businessId = this.apiService.buisnessId;
      this.permitQuestionForm.value.authId = this.apiService.authId;
      let form = this.permitQuestionForm.value;
      this.apiService.startLoader();
      this.apiService.postCall('Questions/edit/' + this.routeId, {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        if (result['success']) {
          this.tabSelected = 'step2';
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        } else {
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openError')).click();
        }
      });
    }
  }

  public getSubQuestionList(id) {
    this.apiService.startLoader();
    let body = {
      outletId: this.permitQuestionForm.value.outletId,
      permitId: this.permitQuestionForm.value.permitId,
      authId: this.apiService.authId,
      'questionId': id,
    };
    this.apiService.postCall('SubQuestions/index', {}, body).subscribe((result: any[]) => {
      this.subquestionList = result['data'];
      this.apiService.stopLoader();
    });
  }


  subQuetionId
  submitSubQuestion() {
    if (!this.subQuetionId) {
      this.permitSubQuestionForm.value.outletId = this.permitQuestionForm.value.outletId;
      this.permitSubQuestionForm.value.permitId = this.permitQuestionForm.value.permitId;
      this.permitSubQuestionForm.value.authId = this.apiService.authId;
      this.permitSubQuestionForm.value.questionId = this.routeId;
      let form = this.permitSubQuestionForm.value;
      this.apiService.startLoader();
      this.apiService.postCall('SubQuestions/add', {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        if (result['success']) {
          this.permitSubQuestionForm.patchValue({answer:null});
          this.message = result['msg'];
          //this.responseQuestionId = result['id'];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          this.tabSelected = 'step2';
          // console.log(result['id']);
          if (this.routeId) {
            this.getSubQuestionList(this.routeId);
          }

        } else {
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openError')).click();
        }
      });
    } else {
      this.permitSubQuestionForm.value.outletId = this.permitQuestionForm.value.outletId;
      this.permitSubQuestionForm.value.permitId = this.permitQuestionForm.value.permitId;
      this.permitSubQuestionForm.value.authId = this.apiService.authId;
      this.permitSubQuestionForm.value.questionId = this.routeId;
      let form = this.permitSubQuestionForm.value;
      this.apiService.startLoader();
      this.apiService.postCall('SubQuestions/edit/' + this.subQuetionId, {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.subQuetionId=undefined
        this.apiService.stopLoader();
        if (result['success']) {
          if (this.routeId) {
            this.getSubQuestionList(this.routeId);
          }
          this.tabSelected = 'step2';
          this.permitSubQuestionForm.patchValue({answer:null});
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        } else {
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openError')).click();
        }
      });
    }


  }

  backClicked() {

  }

  message;

  getPropertyList() {
    this.apiService.startLoader();
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      this.propertyList = result['data'];
      if (this.routeId) {
        this.getByIdDetails();
      }
    });
  }

  getByIdDetails() {
    this.apiService.startLoader();
    this.apiService.postCall('Questions/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {
      this.permitQuestionForm.patchValue({
        outletId:result['data']['outletId'],
        businessId: this.apiService.buisnessId,
        permitId: result['data']['permitId'],
        associatedPermitId:  result['data']['associatedpermitId'],
        type:  result['data']['type'],
        authId: result['data']['updatedBy'],
        question: result['data']['question'],
        isActive: result['data']['isActive']
      });
      this.getSubQuestionList(result['data'].id);

      this.responseQuestionId = this.routeId
      this.apiService.startLoader();
      this.workList = [];
      this.associatedPermitList = [];
      this.apiService.startLoader();
      let body = {
        pageNo: null,
        limit: null,
        outletId: result['data']['outletId'],
        'authId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
      };
      this.apiService.postCall('permits/index', {}, body).subscribe((result2: any[]) => {
        this.workList = result2['data'];
        this.apiService.stopLoader();
      });


      //
      this.apiService.startLoader();
      this.associatedPermitList = [];
      let bodyw = {
        pageNo: null,
        limit: null,
        outletId: this.permitQuestionForm.value.outletId,
        permitId: this.permitQuestionForm.value.permitId,
        'authId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
      };
      this.apiService.postCall('AssociatedPermits/index', {}, bodyw).subscribe((result3: any[]) => {
        this.associatedPermitList = result3['data'];
        this.apiService.stopLoader();
      });

      this.apiService.stopLoader();
    });
  }

  getTextProperty(data) {
    if (data && this.propertyList && this.propertyList[0]) {
      return this.propertyList.find(x => x.id == data) ? this.propertyList.find(x => x.id == data).outletName : '';
    }
  }


  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }
  }

  selectCustom = false;

  getValidation() {
    return this.permitQuestionForm.valid;
  }

  deleteSubQuestion(id) {
    this.apiService.startLoader();
    let body = {
      outletId: this.permitQuestionForm.value.outletId,
      permitId: this.permitQuestionForm.value.permitId,
      authId: this.apiService.authId,
      'questionId': this.responseQuestionId,
    };
    this.apiService.postCall('SubQuestions/delete/' + id, {}, body).subscribe((result: any[]) => {
      this.getSubQuestionList(this.responseQuestionId);
      this.apiService.stopLoader();
    });
  }

  editSubQuestion(val) {
    this.permitSubQuestionForm.patchValue({answer:val.answer});
    this.subQuetionId=val.id
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {

    }
    if (btn.id != 'changeButton') {
      this.statusSelection = undefined;
    }
  }

}
