import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
// import { ColDef } from 'ag-grid-community';
// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-dashboard-ceo',
  templateUrl: './dashboard-ceo.component.html',
  styleUrls: ['./dashboard-ceo.component.css']
})
export class DashboardCeoComponent implements OnInit {

  selectedFiler = { department: null, property: null, startDate: null, ensDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null, name: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;
  downloadFlag = false
  assetViewPermission = false
  inspectionPermission = false
  ticketPermission = false
  partsPermission = false
  maintanencePermission = false
  meterPermission = false
  dashboardPermission = false
  reportsPermission = false
  surveyPermission = false

  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false

  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;


  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  //filterPropertySearch: any;
  // filterDepartmentSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];
  staticDashboardFlag = this.storedData.staticDashboardFlag;

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      //this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
    }

  }
  closeAll() {
    this.propertyFiletr = false;
    this.departmentFilter = false;
  }

  departmentList
  getDepartmentList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('types/pluckDepartments/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }
  staticDashboardstaticDashboardChangeValue = this.storedData.staticDashboardFlag;
  staticDashboardChange() {
    if (this.staticDashboardstaticDashboardChangeValue == false || this.staticDashboardstaticDashboardChangeValue == undefined) {
      this.staticDashboardFlag = true;
      this.storedData.staticDashboardFlag = true;
      this.ngOnInit();
    } else {
      this.staticDashboardFlag = false;
      this.storedData.staticDashboardFlag = false;
      this.ngOnInit();
    }
  }
  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.thiscketChartData = {
      type: 'ColumnChart',
      data: [],
      chartColumns: ['Department', 'Open', 'Closed', 'Parked', 'Not Valid'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 400,
      width_units: '%'
    };

    this.chartDataLine = {
      type: 'AreaChart',
      data: [],
      chartColumns: ['Month', 'Cost'],
      options: {
        isStacked: true,
        hAxis: {
          title: 'Month',
          titleTextStyle: { color: '#000000' }
        },
        vAxis: {
          title: 'Cost',
          titleTextStyle: { color: '#000000' }
        },
        colors: ['#27AE60'],
      },
      // options: {
      //   responsive: true,
      //   borderRadius: 15,
      //   colors: ['#33CCB2', '#EF6B6B'],
      //   legend: { position: 'none' },
      //   hAxis: { title: '' },
      //   vAxis: { title: '' },
      //   seriesType: 'bars',
      //   series: { 2: { type: 'bars' } }
      // },
      width: 1000,
      height: 400
    };

    this.budgetChartData = {
      type: 'ColumnChart',
      data: [],
      chartColumns: ["Date", 'Budget Allocated Cost', 'Budget Used'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 400
    };
    this.ngOnInit()
  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { department: null, property: null, startDate: null, ensDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null, name: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
  }
  locationList: any;
  getLocationList() {
    // this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      // this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']
      this.filterpropertyList = result['data']
      if (this.apiService.outletId) {
        let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      }

      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  // propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }






  columnDefs
  rowData
  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) {



  }
  complainUsersList = [{ name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  ]
  responsesList = [{ item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  ]

  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    d3.selectAll("#groupedChartYAxis111 > *").remove();
    d3.selectAll("#groupedChart111 > *").remove();

    d3.selectAll("#groupedChartYAxis > *").remove();
    d3.selectAll("#chart > *").remove();

    d3.selectAll("#groupedChartYAxis2 > *").remove();
    d3.selectAll("#chart2 > *").remove();

    d3.selectAll("#groupedChartYAxis3 > *").remove();
    d3.selectAll("#chart3 > *").remove();

    d3.selectAll("#groupedChartYAxis4 > *").remove();
    d3.selectAll("#chart4 > *").remove();

    //inspection
    this.getAsset();

    //tickets
    this.getDepartmentGraph();
    this.getAssetMaintences();
    //Assets
    this.getAssetDetails();
    this.getassetMaiantanance();
    this.getAssetswithMaxBraekDown();

    //pm
    this.highestComplains();

    //parts
    this.getParts();

    //feedback
    this.gettop5SurveyEntries();

    this.getLocationList()
    this.getDepartmentList()


    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }
    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }
    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    // console.log(businessPermission)
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 4 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeaturePermit = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 8 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureTraining = true
        }

        if (businessPermission[i]['business_app_feature_id'] == 16 && businessPermission[i]['permission_allow'] == 1) {
          this.businesspartsPermission = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 17 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeaturmaintanence = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 14 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureasset = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 14 && businessPermission[i]['permission_allow'] == 0) {
          this.businessFeatureasset = false
          this.businessFeaturmaintanence = false
          this.businesspartsPermission = false
        }
      }

      if (this.apiService.role == 1) {
        this.businessFeaturePermit = true
        this.businessFeatureAttendance = true
        this.businessFeatureTraining = true
        this.businessFeatureasset = true
        this.businessFeaturmaintanence = true
        this.businesspartsPermission = true
      }
    }
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
      if (permission[i]['permissionModuleId'] == 1 && permission[i]['subModuleName'] == "Inspection" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.inspectionPermission = true
      }
      if (permission[i]['permissionModuleId'] == 2 && permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.ticketPermission = true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
      if (permission[i]['permissionModuleId'] == 4 && permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.maintanencePermission = true
      }
      if (permission[i]['permissionModuleId'] == 6 && permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.meterPermission = true
      }
      if (permission[i]['permissionModuleId'] == 7 && permission[i]['subModuleName'] == "Dashboard" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.dashboardPermission = true
      }
      if (permission[i]['permissionModuleId'] == 8 && permission[i]['subModuleName'] == "Reports" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.reportsPermission = true
      }
      if (permission[i]['subModuleName'] == "Surveys" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.surveyPermission = true
      }
    }
  }




  selected: any = 'dashboard-checklist';
  navigateTo(rout) {
    this.selected = rout
    this.route.navigate([rout])
  }
  pmDashboard
  pmDashboardCount
  getAssetMaintences() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      userId: this.apiService.userId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.startLoader()
    this.apiService.postCall('Pms/pmDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (this.staticDashboardFlag == true) {
        this.pmDashboard = {
          "success": true,
          "msg": "success.",
          "totalPmChecklistsScheduled": "390",
          "totalCompletePmChecklistsScheduled": 320,
          "totalPendingPmChecklistsScheduled": "70",
          "pmComplianceNonCompliantGraph": [
            [
              "Days",
              "Compliant",
              "Non Compliant"
            ],
            [
              "01 Jun 2023",
              13,
              2
            ],
            [
              "02 Jun 2023",
              12,
              3
            ],
            [
              "03 Jun 2023",
              14,
              1
            ],
            [
              "04 Jun 2023",
              10,
              5
            ],
            [
              "05 Jun 2023",
              11,
              4
            ],
            [
              "06 Jun 2023",
              9,
              6
            ],
            [
              "07 Jun 2023",
              0,
              0
            ],
            [
              "08 Jun 2023",
              14,
              1
            ],
            [
              "09 Jun 2023",
              9,
              6
            ],
            [
              "10 Jun 2023",
              12,
              3
            ],
            [
              "11 Jun 2023",
              15,
              0
            ],
            [
              "12 Jun 2023",
              13,
              2
            ],
            [
              "13 Jun 2023",
              11,
              4
            ],
            [
              "14 Jun 2023",
              0,
              0
            ],
            [
              "15 Jun 2023",
              11,
              4
            ],
            [
              "16 Jun 2023",
              15,
              0
            ],
            [
              "17 Jun 2023",
              14,
              1
            ],
            [
              "18 Jun 2023",
              10,
              5
            ],
            [
              "19 Jun 2023",
              13,
              2
            ],
            [
              "20 Jun 2023",
              11,
              4
            ],
            [
              "21 Jun 2023",
              0,
              0
            ],
            [
              "22 Jun 2023",
              15,
              0
            ],
            [
              "23 Jun 2023",
              9,
              6
            ],
            [
              "24 Jun 2023",
              12,
              3
            ],
            [
              "25 Jun 2023",
              14,
              1
            ],
            [
              "26 Jun 2023",
              12,
              3
            ],
            [
              "27 Jun 2023",
              13,
              2
            ],
            [
              "28 Jun 2023",
              0,
              0
            ],
            [
              "29 Jun 2023",
              15,
              0
            ],
            [
              "30 Jun 2023",
              13,
              2
            ]
          ],
          "assetWithNonCompliant": [
            {
              "id": "186",
              "asset_name": "Asset -1111",
              "pmTemplateId": "31"
            },
            {
              "id": "6",
              "asset_name": "Shaper Machine ",
              "pmTemplateId": "9"
            },
            {
              "id": "264",
              "asset_name": "abcnnnnnnnkkkkkkkkkkkkkk",
              "pmTemplateId": "6"
            },
            {
              "id": "15",
              "asset_name": "DG NO 4",
              "pmTemplateId": "3"
            },
            {
              "id": "2",
              "asset_name": "Radial drill machine",
              "pmTemplateId": "2"
            }
          ],
          "userWithHighestCompliance": [],
          "data": [],
          "rowCount": 0
        }
        this.pmDashboardCount = {
          "success": true,
          "msg": "success.",
          "totalPmChecklistsScheduled": "390",
          "totalCompletePmChecklistsScheduled": 320,
          "totalPendingPmChecklistsScheduled": "70",
          "pmComplianceNonCompliantGraph": [
            [
              "Days",
              "Compliant",
              "Non Compliant"
            ],
            [
              "01 Jun 2023",
              13,
              2
            ],
            [
              "02 Jun 2023",
              12,
              3
            ],
            [
              "03 Jun 2023",
              14,
              1
            ],
            [
              "04 Jun 2023",
              10,
              5
            ],
            [
              "05 Jun 2023",
              11,
              4
            ],
            [
              "06 Jun 2023",
              9,
              6
            ],
            [
              "07 Jun 2023",
              0,
              0
            ],
            [
              "08 Jun 2023",
              14,
              1
            ],
            [
              "09 Jun 2023",
              9,
              6
            ],
            [
              "10 Jun 2023",
              12,
              3
            ],
            [
              "11 Jun 2023",
              15,
              0
            ],
            [
              "12 Jun 2023",
              13,
              2
            ],
            [
              "13 Jun 2023",
              11,
              4
            ],
            [
              "14 Jun 2023",
              0,
              0
            ],
            [
              "15 Jun 2023",
              11,
              4
            ],
            [
              "16 Jun 2023",
              15,
              0
            ],
            [
              "17 Jun 2023",
              14,
              1
            ],
            [
              "18 Jun 2023",
              10,
              5
            ],
            [
              "19 Jun 2023",
              13,
              2
            ],
            [
              "20 Jun 2023",
              11,
              4
            ],
            [
              "21 Jun 2023",
              0,
              0
            ],
            [
              "22 Jun 2023",
              15,
              0
            ],
            [
              "23 Jun 2023",
              9,
              6
            ],
            [
              "24 Jun 2023",
              12,
              3
            ],
            [
              "25 Jun 2023",
              14,
              1
            ],
            [
              "26 Jun 2023",
              12,
              3
            ],
            [
              "27 Jun 2023",
              13,
              2
            ],
            [
              "28 Jun 2023",
              0,
              0
            ],
            [
              "29 Jun 2023",
              15,
              0
            ],
            [
              "30 Jun 2023",
              13,
              2
            ]
          ],
          "assetWithNonCompliant": [
            {
              "id": "186",
              "asset_name": "Asset -1111",
              "pmTemplateId": "31"
            },
            {
              "id": "6",
              "asset_name": "Shaper Machine ",
              "pmTemplateId": "9"
            },
            {
              "id": "264",
              "asset_name": "abcnnnnnnnkkkkkkkkkkkkkk",
              "pmTemplateId": "6"
            },
            {
              "id": "15",
              "asset_name": "DG NO 4",
              "pmTemplateId": "3"
            },
            {
              "id": "2",
              "asset_name": "Radial drill machine",
              "pmTemplateId": "2"
            }
          ],
          "userWithHighestCompliance": [],
          "data": [],
          "rowCount": 0
        }
      } else {
        this.pmDashboard = result;
        this.pmDashboardCount = result;
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      this.groupedBarchartPm()
    })
  }
  checklistDashboardToday
  checklistDashboard
  getAsset() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : ""
    }
    this.apiService.postCall('todays-checklists/checklistreport', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      if (this.staticDashboardFlag == true) {
        this.checklistDashboardToday = {
          "checklist": [
            [
              "Days",
              "Completed Inspections",
              "Incomplete Inspections"
            ],
            [
              "Jul 01",
              44,
              6
            ],
            [
              "Jul 02",
              42,
              8
            ],
            [
              "Jul 03",
              41,
              9
            ],
            [
              "Jul 04",
              50,
              0
            ],
            [
              "Jul 05",
              43,
              7
            ],
            [
              "Jul 06",
              40,
              10
            ],
            [
              "Jul 07",
              0,
              0
            ],
            [
              "Jul 08",
              46,
              4
            ],
            [
              "Jul 09",
              43,
              7
            ],
            [
              "Jul 10",
              47,
              3
            ], [
              "Jul 11",
              41,
              9
            ],
            [
              "Jul 12",
              48,
              2
            ],
            [
              "Jul 13",
              42,
              8
            ],
            [
              "Jul 14",
              0,
              0
            ],
            [
              "Jul 15",
              48,
              2
            ],
            [
              "Jul 16",
              43,
              7
            ],
            [
              "Jul 17",
              47,
              3
            ],
            [
              "Jul 18",
              41,
              9
            ],
            [
              "Jul 19",
              45,
              5
            ],
            [
              "Jul 20",
              39,
              11
            ],
            [
              "Jul 21",
              0,
              0
            ],
            [
              "Jul 22",
              41,
              9
            ],
            [
              "Jul 23",
              39,
              9
            ],
            [
              "Jul 24",
              40,
              10
            ],
            [
              "Jul 25",
              50,
              5
            ],
            [
              "Jul 26",
              44,
              6
            ],
            [
              "Jul 27",
              41,
              9
            ],
            [
              "Jul 28",
              0,
              0
            ],
            [
              "Jul 29",
              48,
              2
            ],
            [
              "Jul 30",
              47,
              3
            ]
          ],
          "totalChecklistsScheduled": '1,300',
          "completedChecklists": '1,140',
          "inCompletedChecklists": 160,
          "getDetails": [],
          "rowCount": 0
        }
        this.checklistDashboard = {
          "checklist": [
            [
              "Days",
              "Completed Inspections",
              "Incomplete Inspections"
            ],
            [
              "Jul 01",
              44,
              6
            ],
            [
              "Jul 02",
              42,
              8
            ],
            [
              "Jul 03",
              41,
              9
            ],
            [
              "Jul 04",
              50,
              0
            ],
            [
              "Jul 05",
              43,
              7
            ],
            [
              "Jul 06",
              40,
              10
            ],
            [
              "Jul 07",
              0,
              0
            ],
            [
              "Jul 08",
              46,
              4
            ],
            [
              "Jul 09",
              43,
              7
            ],
            [
              "Jul 10",
              47,
              3
            ], [
              "Jul 11",
              41,
              9
            ],
            [
              "Jul 12",
              48,
              2
            ],
            [
              "Jul 13",
              42,
              8
            ],
            [
              "Jul 14",
              0,
              0
            ],
            [
              "Jul 15",
              48,
              2
            ],
            [
              "Jul 16",
              43,
              7
            ],
            [
              "Jul 17",
              47,
              3
            ],
            [
              "Jul 18",
              41,
              9
            ],
            [
              "Jul 19",
              45,
              5
            ],
            [
              "Jul 20",
              39,
              11
            ],
            [
              "Jul 21",
              0,
              0
            ],
            [
              "Jul 22",
              41,
              9
            ],
            [
              "Jul 23",
              39,
              9
            ],
            [
              "Jul 24",
              40,
              10
            ],
            [
              "Jul 25",
              50,
              5
            ],
            [
              "Jul 26",
              44,
              6
            ],
            [
              "Jul 27",
              41,
              9
            ],
            [
              "Jul 28",
              0,
              0
            ],
            [
              "Jul 29",
              48,
              2
            ],
            [
              "Jul 30",
              47,
              3
            ]
          ],
          "totalChecklistsScheduled": '1,300',
          "completedChecklists": '1,140',
          "inCompletedChecklists": 160,
          "getDetails": [],
          "rowCount": 0
        }
      } else {
        this.checklistDashboardToday = result['data'];
        this.checklistDashboard = result['data'];
      }
      this.groupedBarchart3()
    })
  }
  chart1
  chart2
  chart3
  chart4
  downloadReport() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "chart1": this.chart1,
      "chart2": this.chart2,
      "chart3": this.chart3,
      "chart4": this.chart4,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      checklistDashboardToday: this.checklistDashboardToday,
      checklistDashboard: this.checklistDashboard,
      pmDashboard: this.pmDashboard,
      pmDashboardCount: this.pmDashboardCount,
      ticketDashboard: this.ticketDashboard,
      ticketDashboardData: this.ticketDashboardData,
      assetDashboard: this.assetDashboard,
      assetMaiantanance: this.assetMaiantanance,
      highestComplainPms: this.highestComplainPms,
      maxBearkDown: this.maxBearkDown,
      partsDashboard: this.partsDashboard,
      top5SurveyEntries: this.top5SurveyEntries,
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('AssetManagements/dashboardDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.exportAsPDF1();
  }
  exportAsPDF1() {
    var HTML_Width = $("#divIdCharts1").width();
    var HTML_Height = $("#divIdCharts1").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts1")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage1('img2', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage1(imageName: any, url: any) {
    this.chart2 = url;
    this.exportAsPDF2();
  }
  exportAsPDF2() {
    var HTML_Width = $("#divIdCharts2").width();
    var HTML_Height = $("#divIdCharts2").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts2")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage2('img3', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage2(imageName: any, url: any) {
    this.chart3 = url;
    this.exportAsPDF3();
  }
  exportAsPDF3() {
    var HTML_Width = $("#divIdCharts3").width();
    var HTML_Height = $("#divIdCharts3").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts3")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage3('img4', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage3(imageName: any, url: any) {
    this.chart4 = url;
    this.downloadReport();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }

  ticketDashboard
  getDepartmentGraph() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    // this.apiService.startLoader()
    this.apiService.postCall('todays-checklists/ticketsreportGraphDepartment', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.ticketDashboard = {
          "ticketsByDepartment": [
            [
              "",
              "Open",
              "Closed",
              "Parked",
              "Not Valid"
            ],
            [
              "Housekeeping",
              4,
              9,
              2,
              1
            ],
            [
              "Engineering",
              8,
              10,
              3,
              0
            ],
            [
              "Security",
              4,
              5,
              1,
              0
            ],
            [
              "Parking",
              0,
              2,
              0,
              0
            ],
            [
              "Fire & Safety",
              0,
              0,
              0,
              1
            ]
          ],
          "parkedTickets": 6,
          "notValidTickets": 2,
          "closedTickets": 26,
          "openTickets": 16,
          "totalTickets": 50
        }
        this.ticketDashboardData = {
          "ticketsByDepartment": [
            [
              "",
              "Open",
              "Closed",
              "Parked",
              "Not Valid"
            ],
            [
              "Housekeeping",
              4,
              9,
              2,
              1
            ],
            [
              "Engineering",
              8,
              10,
              3,
              0
            ],
            [
              "Security",
              4,
              5,
              1,
              0
            ],
            [
              "Parking",
              0,
              2,
              0,
              0
            ],
            [
              "Fire & Safety",
              0,
              0,
              0,
              1
            ]
          ],
          "parkedTickets": 6,
          "notValidTickets": 2,
          "closedTickets": 26,
          "openTickets": 16,
          "totalTickets": 50
        }
      } else {
        this.ticketDashboard = result['data'];
        this.ticketDashboardData = result['data'];
      }
      this.groupedBarchart()
    })
  }
  ticketDashboardData
  inspectionChartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['Days', 'Completed', 'Incomplete'],
    options: {
      responsive: true,
      borderRadius: 15,
      pieHole: 0.4,
      bar: { groupWidth: "75%" },
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: {
        title: ''
      },
      vAxis: {
        title: ''
      },
      seriesType: 'bars',
      series: { 2: { type: 'bars' } }
    },
    width: 1000,
    height: 400
  };

  displayFlag3 = true
  groupedBarchart3() {
    this.checklistDashboard['checklist'].splice(0, 1)
    // console.log(this.checklistDashboard['checklist'])
    this.inspectionChartData = {
      type: 'ColumnChart',
      data: this.checklistDashboard['checklist'],
      chartColumns: ['Days', 'Completed', 'Incomplete'],
      options: {
        responsive: true,
        pieHole: 0.4,
        bar: { groupWidth: "75%" },
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: {
          title: ''
        },
        vAxis: {
          title: ''
        },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 300
    };
    this.displayFlag3 = false

  }
  onSelect(event, flg) {
    if (flg == 0) {
      const row = event['selection'][0].row;
      const column = event['selection'][0].column;
      const year = this.checklistDashboard['checklist'][row][0];
      let selectedItem;
      var flag = 1;
      if (column === 1) {
        selectedItem = "completed";
        flag = 1;
      }
      if (column === 2) {
        selectedItem = "inCompleted";
        flag = 2;
      }
      this.complianceStatus(flag, year)
      // console.log("year", year, "SelectedItem", selectedItem, this.checklistDashboard['checklist'][row][column]);
    } else if (flg == 1) {
      const { row, column } = event.selection[0];
      if (column == 1) {
        this.departMentalAnalyasis('Open', '', this.chartData.data[row][0], '');
      } else if (column == 2) {
        this.departMentalAnalyasis('Closed', '', this.chartData.data[row][0], '');
      } else if (column == 3) {
        this.departMentalAnalyasis('Parked', '', this.chartData.data[row][0], '');
      } else if (column == 4) {
        this.departMentalAnalyasis('Not Valid', '', this.chartData.data[row][0], '');
      }
    } else if (flg == 2) {
      const row = event['selection'][0].row;
      const column = event['selection'][0].column;
      const year = this.pmDashboard['pmComplianceNonCompliantGraph'][row][0];
      let selectedItem;
      var flag = 1;
      if (column === 1) {
        selectedItem = "completed";
        flag = 1;
      }
      if (column === 2) {
        selectedItem = "inCompleted";
        flag = 2;
      }
      this.pmDataAnalyasis(flag, year)
    }
  }
  pmDataAnalyasis(flag, grouo) {
    var apiValue = "Pms/pmDashboard/" + this.apiService.buisnessId;
    var tableName1 = 'Compliance Status'
    var obj1 = {
      "assetId": null,
      "date": moment(grouo).format('YYYY-MM-DD'),
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "detailsFlag": 1,
      "type": flag == "complete" ? 1 : 2 //1Completeed, 2=noneCompl
    }

    this.storedData.graphIndex = {
      headers: ['Pm Name', 'Asset Name', 'Outlet Name', 'Conditions', 'Model', 'Make', 'Category Name'],
      obj: obj1,
      api: apiValue,
      tableName: tableName1,
      dataSource: ['name', 'asset_name', 'outlet_name', 'conditions', 'model', 'make', 'category_name'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("graphIndex", JSON.stringify(this.storedData.graphIndex));
    this.route.navigate(['/graph-list']);
  }
  departMentalAnalyasis(flag, type, depart, xAxis) {
    // console.log(flag);
    var apiValue = "";
    var tableName1 = 'Departmental Analysis'
    var departmentName1 = '';
    if (flag == "Open") {
      apiValue = "tickets/openTickets";
      departmentName1 = depart;
    } else if (flag == "Closed") {
      apiValue = "tickets/closeTickets";
      departmentName1 = depart;
    } else if (flag == "Parked") {
      apiValue = "tickets/parkedTickets";
      departmentName1 = depart;
    } else if (flag == "Not Valid") {
      apiValue = "tickets/notValidTickets";
      departmentName1 = depart;
    } else if (type == "openAageing") {
      apiValue = "todays-checklists/ticketsreportGraphStatus";
      tableName1 = 'Open Tickets Ageing'
      departmentName1 = flag;
    } else if (type == "parkedAageing") {
      departmentName1 = flag;
      apiValue = "todays-checklists/ticketsreportGraphStatus";
      tableName1 = 'Parked Tickets Ageing'
    }
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    var obj1 = {
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "departmentId": '',//this.selectedFiler.department?this.selectedFiler.department.id : "",
      "departmentName": departmentName1,//"Housekeeping",
      //"origin": this.selectedFiler.origin ? this.selectedFiler.origin.id : "",
      "xLable": xAxis,//"Housekeeping",
      "dueDate": [this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD')],
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "graphType": type
    }

    this.storedData.graphIndex = {
      // headers: ['Ticket Title', 'Property', 'Department Name', 'Subject', 'Assign To', 'Created Date', 'Days Since Open'],
      headers: ['Ticket Title', 'Property', 'Assign To', 'Created Date', 'Days Since Open'],
      obj: obj1,
      api: apiValue,
      tableName: tableName1,
      //dataSource: ['ticketTitle', 'outletName', 'departmentName', 'subject', 'raisedBy', 'created', 'timeAgo'],
      dataSource: ['ticketTitle', 'outletName', 'raisedBy', 'modified', 'timeAgo'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("graphIndex", JSON.stringify(this.storedData.graphIndex));
    this.route.navigate(['/graph-list'])

  }
  complianceStatus(flag, year) {
    var obj1 = {
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "name": this.selectedFiler.name ? this.selectedFiler.name : "",
      "departmentId": this.selectedFiler.department ? this.selectedFiler.department.id : "",
      "date": moment(year).format('YYYY-MM-DD'),// "2022-03-01",
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "flag": flag
    }

    this.storedData.graphIndex = {
      headers: ['Inspection Name', 'Schedule Name', 'Property'],
      obj: obj1,
      api: 'todays-checklists/checklistreport',
      tableName: 'Compliance Status',
      dataSource: ['inspection_name', 'schedule_name', 'outlet'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("graphIndex", JSON.stringify(this.storedData.graphIndex));
    this.route.navigate(['/graph-list'])
  }

  fullScreen: any = 900
  thiscketChartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['Department', 'Open', 'Closed', 'Parked', 'Not Valid'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'bars' } }
    },
    width: 1000,
    height: 400,
    width_units: '%'
  };
  ticketDisplayFlag = true
  chartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['Days', 'Compliant', 'Non Compliant'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'bars' } }
    },
    width: 1000,
    height: 400
  };
  groupedBarchartPm() {
    this.pmDashboard['pmComplianceNonCompliantGraph'].splice(0, 1)
    this.chartData = {
      type: 'ColumnChart',
      data: this.pmDashboard['pmComplianceNonCompliantGraph'],
      chartColumns: ['Days', 'Compliant', 'Non Compliant'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: 'Time Period' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 400
    };
  }
  groupedBarchart() {
    this.ticketDashboard['ticketsByDepartment'].splice(0, 1)
    this.thiscketChartData = {
      type: 'ColumnChart',
      data: this.ticketDashboard['ticketsByDepartment'],
      chartColumns: ['Department', 'Open', 'Closed', 'Parked', 'Not Valid'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#EF6B6B', '#33CCB2', '#F2994A', '#D1DBDB'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: 'No. of Tickets' },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 400,
      width_units: '%'
    };
    this.ticketDisplayFlag = false
    return;
    let data = []
    let max: number = 0
    for (let i = 1; i < this.ticketDashboard['ticketsByDepartment'].length; i++) {
      data.push({ 'group': this.ticketDashboard['ticketsByDepartment'][i][0], 'Open': this.ticketDashboard['ticketsByDepartment'][i][1], 'Closed': this.ticketDashboard['ticketsByDepartment'][i][2], 'Parked': this.ticketDashboard['ticketsByDepartment'][i][3], 'Not Valid': this.ticketDashboard['ticketsByDepartment'][i][4] })

      if (max < this.ticketDashboard['ticketsByDepartment'][i][1]) max = this.ticketDashboard['ticketsByDepartment'][i][1]
      if (max < this.ticketDashboard['ticketsByDepartment'][i][2]) max = this.ticketDashboard['ticketsByDepartment'][i][2]
      if (max < this.ticketDashboard['ticketsByDepartment'][i][3]) max = this.ticketDashboard['ticketsByDepartment'][i][3]

      if (max < this.ticketDashboard['ticketsByDepartment'][i][4]) max = this.ticketDashboard['ticketsByDepartment'][i][4]

    }

    var margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = this.fullScreen,
      height = 400 - margin.top - margin.bottom;


    // append the svg object to the body of the page
    var svgAxis = d3.select("#groupedChartYAxis111")
      .append("svg")
      .attr("width", 50)
      .attr("height", 500)
      .append("g")
      .attr("transform",
        "translate(" + 40 + "," + margin.top + ")");
    // append the svg object to the body of the page
    var svg = d3.select("#groupedChart111")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 500)
      .append("g")
      .attr("transform",
        "translate(" + -5 + "," + margin.top + ")");

    // List of subgroups = header of the csv files = soil condition here
    var subgroups = ['Open', 'Closed', 'Parked', 'Not Valid']
    var groups = []
    for (let i of data) groups.push(i.group)

    // console.log('subgroups', subgroups)
    // console.log('groups', groups)

    if (data.length < 10) {
      for (let i = data.length; i <= 8; i++) {
        let obj = {}
        let str = " "
        for (let j = 0; j < i; j++) str = str + " "
        obj['group'] = str
        for (let d of subgroups) obj[d] = 0

        // console.log(obj)
        data.push(obj)
        groups.push(str)
      }
    }
    // console.log(data)

    // Add X axis
    var x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2])
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      .call(d3.axisBottom(x).tickSize(0))

      .selectAll("text")
      .style("text-anchor", "end")

      .attr("transform", "rotate(-65)");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, (max + 5)])
      .range([height, 0]);
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));

    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));


    // Another scale for subgroup position?
    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, 80])
      .padding([0.05])

    // color palette = one color per subgroup
    var color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(['#6FCF97', '#7879F1', '#F2994A', '#D6D6D6'])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
      .enter().append("rect")
      .attr("x", function (d) { return xSubgroup(d.key); })
      .attr("y", function (d) { return y(d.value); })
      .on('click', function (s) {
        // console.log(s)
        this.departMentalAnalyasis(s.key, "");
      }.bind(this))
      .attr("width", 19.5)
      .attr("height", function (d) { return height - y(d.value); })
      .attr("fill", function (d) { return color(d.key); });


    svg.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")

      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key) {
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("text")
      .text(function (d) {
        if (d.value != 0) return d.value;
      })
      .attr("x", function (d) {
        let data = (xSubgroup(d.key) + 5)
        return data;
      })
      .attr("y", function (d) {
        return y(d.value) - 5;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("fill", function (d) { return color(d.key); })
      .attr("text-anchor", "middle");
  }




  //Assets


  assetDashboard
  warrantyExpirationList = []
  getAssetDetails() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      businessId: this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      userId: this.apiService.authId,
      roleId: this.apiService.roleId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.startLoader()
    this.apiService.postCall('AssetManagements/assetDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (this.staticDashboardFlag == true) {
        this.assetDashboard = {
          "success": true,
          "msg": "success.",
          "assetTotalValues": "50,00,000",
          "totalAsset": "250",
          "totalAssetActive": "32",
          "totalAssetInActive": "2",
          "totalAssetTemparary": "5",
          "assetMttr": "185 Hrs",
          "assetMtbf": "22,25,750 Hrs",
          "downtimeAvailability": "375 Hrs",
          "assetAmcOverdue": "3",
          "assetWarrantyExp": [
            {
              "id": 208,
              "asset_name": "DG Air Washer No. 1",
              "make": "EDGTECH",
              "spec_rating": "14400 CFM",
              "sr_no": "1",
              "location_id": 8879,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 5992,
              "business_id": 26,
              "manufacturer": null,
              "model": "DAW N",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454395_qeunk_208.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:46:09+05:30",
              "modified_at": "2022-11-26T09:19:55+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221637fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Washer",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Siemens",
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "Aarkays Air Equipment Pvt Ltd",
              "warraanty_contact_no": "7125445450",
              "warraanty_alternate_no": null,
              "warraanty_email": "aarkaysair@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-05-01",
              "amc_cron_date": "2023-05-16"
            },
            {
              "id": 209,
              "asset_name": "Chiller Auxilary Panel",
              "make": "ADLEC",
              "spec_rating": "1000 AMP",
              "sr_no": "2",
              "location_id": 8881,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 5992,
              "business_id": 26,
              "manufacturer": null,
              "model": "CAP",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454396_hzjym_209.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:46:09+05:30",
              "modified_at": "2022-11-26T09:19:56+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221635fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Panel",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Aircon Controls",
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "Aircon Controls",
              "warraanty_contact_no": "7745774588",
              "warraanty_alternate_no": null,
              "warraanty_email": "airconelectronics@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-05-30",
              "amc_cron_date": "2023-05-16"
            },
            {
              "id": 210,
              "asset_name": "Condenser Pump No.1",
              "make": "CE(XYLEM)",
              "spec_rating": "60HP/45KW",
              "sr_no": "1217M80017267",
              "location_id": 8880,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 7150,
              "business_id": 26,
              "manufacturer": null,
              "model": "CPN 1",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454397_fusyo_210.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:44:29+05:30",
              "modified_at": "2022-11-26T09:19:57+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221658fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Pump",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "",
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "Shakti Pumps",
              "warraanty_contact_no": "7784577845",
              "warraanty_alternate_no": "7654688431",
              "warraanty_email": "shakti@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-05-01",
              "amc_cron_date": "2023-03-16"
            },
            {
              "id": 211,
              "asset_name": "Air Blower No. 2 (Pump)",
              "make": "KIRLOSKER",
              "spec_rating": "5.5 KW",
              "sr_no": "U8WMCH00976",
              "location_id": 8880,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 7150,
              "business_id": 26,
              "manufacturer": null,
              "model": "ABN 2 P",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454398_vwlui_211.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:44:29+05:30",
              "modified_at": "2022-11-26T09:19:58+05:30",
              "created_by": 318,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221637fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Pump",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "",
              "budget": null,
              "budget_type": 0,
              "parent_id": 210,
              "warraanty_vendor_name": "Ram Sons Pump",
              "warraanty_contact_no": "7558456985",
              "warraanty_alternate_no": "8946598645",
              "warraanty_email": "ramsons@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 0,
              "warraanty_cron_date": "2023-05-01",
              "amc_cron_date": "2023-07-16"
            },
            {
              "id": 20,
              "asset_name": "CHILLER PLANT 2",
              "make": "DAIKIN",
              "spec_rating": "550TR",
              "sr_no": "6700102",
              "location_id": 4848,
              "purchase_date": "2019-07-15T00:07:00+05:30",
              "plasce_in_service": "2019-07-15T00:07:00+05:30",
              "vendor_id": null,
              "outlet_id": 71,
              "business_id": 26,
              "manufacturer": null,
              "model": "C3612 B2RB418A",
              "specification": "spec",
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658670049_gcfre_20.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 0,
              "is_deleted": 0,
              "created_at": "2022-12-30T07:02:34+05:30",
              "modified_at": "2022-07-24T13:40:49+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": "3",
              "renewal_date": null,
              "warranty_expiration": null,
              "image": null,
              "sla_expiry_date": null,
              "assigned_customer": null,
              "status": 1,
              "is_used": 0,
              "category_name": "HVAC",
              "conditions": "New",
              "ownership": "WMG",
              "asset_tag_no": null,
              "vendor_name": null,
              "budget": null,
              "budget_type": 0,
              "parent_id": 19,
              "warraanty_vendor_name": "abc",
              "warraanty_contact_no": "6666666666",
              "warraanty_alternate_no": "6666666626",
              "warraanty_email": "abc@gmail.com",
              "warraanty_start_date": "2022-12-30",
              "warraanty_sheduled_services": "50",
              "warraanty_attach": "",
              "warraanty_end_date": "2023-04-29",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 750,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-04-19",
              "amc_cron_date": null
            },
            {
              "id": 21,
              "asset_name": "HRU",
              "make": "EDGTECH",
              "spec_rating": "8200CFM",
              "sr_no": "K1805889/K1007501",
              "location_id": 4848,
              "purchase_date": "2020-01-10T00:01:00+05:30",
              "plasce_in_service": "2020-01-10T00:01:00+05:30",
              "vendor_id": null,
              "outlet_id": 5994,
              "business_id": 26,
              "manufacturer": null,
              "model": "ETV100HRU",
              "specification": "spec",
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658671234_lxvzn_21.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 71,
              "is_deleted": 0,
              "created_at": "2023-05-04T11:18:23+05:30",
              "modified_at": "2022-07-24T14:00:34+05:30",
              "created_by": 323,
              "modified_by": 6553,
              "amc_provider_name": null,
              "remarks": "3",
              "renewal_date": null,
              "warranty_expiration": null,
              "image": null,
              "sla_expiry_date": null,
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "HVAC",
              "conditions": "New",
              "ownership": "WMG",
              "asset_tag_no": null,
              "vendor_name": null,
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "ssss",
              "warraanty_contact_no": "6666666666",
              "warraanty_alternate_no": null,
              "warraanty_email": "dsd@gmia.com",
              "warraanty_start_date": "2022-12-30",
              "warraanty_sheduled_services": "5",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-03-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": null,
              "amc_cron_date": null
            },
            {
              "id": 1,
              "asset_name": "Milling Machine",
              "make": "Mahindra",
              "spec_rating": "2250KVA",
              "sr_no": "566",
              "location_id": 106,
              "purchase_date": "2021-01-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 71,
              "business_id": 26,
              "manufacturer": null,
              "model": "Mahindra 5.3 k ",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_zyvqo_1.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 0,
              "is_deleted": 0,
              "created_at": "2022-12-01T10:40:01+05:30",
              "modified_at": "2022-05-24T11:43:46+05:30",
              "created_by": 2125,
              "modified_by": 5654,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 0,
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Zenn Machines",
              "budget": "250",
              "budget_type": 0,
              "parent_id": 5,
              "warraanty_vendor_name": "VJ Machines Ltd.",
              "warraanty_contact_no": "8954612645",
              "warraanty_alternate_no": "7895663541",
              "warraanty_email": "vj@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": "",
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 2244,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 2125,
              "warraanty_cron_date": null,
              "amc_cron_date": null
            }
          ],
          "maxNoOfBreakdown": [
            {
              "id": "5",
              "asset_name": "Generator No. 2",
              "AssetManagementBreakdowns": "2"
            },
            {
              "id": "209",
              "asset_name": "Chiller Plant No. 1",
              "AssetManagementBreakdowns": "1"
            },
            {
              "id": "211",
              "asset_name": "AHU Motor No. 5",
              "AssetManagementBreakdowns": "1"
            }
          ],
          "assetWithVerryLessFull": [
            {
              "id": "2",
              "asset_name": "Generator No. 2",
              "usefullLifeYear": "2",
              "end_date": "2024-6-30"
            },
            {
              "id": "16",
              "asset_name": "HVAC Plant",
              "usefullLifeYear": "3",
              "end_date": "2024-12-31"
            },
            {
              "id": "186",
              "asset_name": "Transformer No. 1",
              "usefullLifeYear": "1",
              "end_date": "2024-12-31"
            },
            {
              "id": "4",
              "asset_name": "Chiller Plant No. 1",
              "usefullLifeYear": "3",
              "end_date": "2025-6-30"
            },
            {
              "id": "1",
              "asset_name": "AHU Motor No. 5",
              "usefullLifeYear": "3",
              "end_date": "2025-09-30"
            }
          ],
          "assetHighestMaintenceCosts": [
            {
              "asset_management_id": "6",
              "quantity": "1",
              "price": "80",
              "id": "6",
              "asset_name": "Shaper Machine ",
              "make": "Zacko",
              "spec_rating": "2251KVA",
              "sr_no": "23",
              "location_id": "107",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "Zacko - 56",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653486936_jkrcu_6.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-10-13 05:40:01",
              "modified_at": "2022-05-24 11:43:49",
              "created_by": "318",
              "modified_by": "318",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221429fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "NY Ltd",
              "budget": null,
              "budget_type": "0",
              "parent_id": "0",
              "warraanty_vendor_name": "Zacko",
              "warraanty_contact_no": "7897864795",
              "warraanty_alternate_no": "8998798771",
              "warraanty_email": "zacko@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "5130",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "318",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "5130"
            },
            {
              "asset_management_id": "3",
              "quantity": "4",
              "price": "300",
              "id": "3",
              "asset_name": "Tool & Cutter Machine",
              "make": "JK",
              "spec_rating": "2250KVA",
              "sr_no": "14236",
              "location_id": "2339",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "JK G89",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392627_twils_3.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-09-21 15:00:01",
              "modified_at": "2022-05-24 11:43:47",
              "created_by": "323",
              "modified_by": "318",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221427fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "HDA Machines",
              "budget": "750",
              "budget_type": "0",
              "parent_id": "0",
              "warraanty_vendor_name": "Sai Machines",
              "warraanty_contact_no": "9564325632",
              "warraanty_alternate_no": "8954612645",
              "warraanty_email": "sai@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "2920",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "323",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "3420"
            },
            {
              "asset_management_id": "19",
              "quantity": "1",
              "price": "100",
              "id": "19",
              "asset_name": "CHILLER PLANT 1",
              "make": "DAIKIN",
              "spec_rating": "550 TR",
              "sr_no": "6700101",
              "location_id": "4848",
              "purchase_date": "2019-07-20 00:07:00",
              "plasce_in_service": "2019-07-20 00:07:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "C3612 BLYY 2",
              "specification": "spec",
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658668545_ozybk_19.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "5994",
              "is_deleted": "0",
              "created_at": "2023-05-04 11:18:23",
              "modified_at": "2022-07-24 13:15:45",
              "created_by": "323",
              "modified_by": "6553",
              "amc_provider_name": null,
              "remarks": "3",
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": null,
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/181020221255fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "1",
              "category_name": "HVAC",
              "conditions": "New",
              "ownership": "WMG",
              "asset_tag_no": null,
              "vendor_name": "KE Ltd.",
              "budget": "2000",
              "budget_type": "0",
              "parent_id": "20",
              "warraanty_vendor_name": "Daikin Ltd.",
              "warraanty_contact_no": "9598989898",
              "warraanty_alternate_no": "7988979798",
              "warraanty_email": "daikin@gmail.com",
              "warraanty_start_date": "2022-08-01",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2022-10-31",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "2771.11",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "323",
              "warraanty_cron_date": "2022-10-30",
              "amc_cron_date": "2022-10-28",
              "finalcost": "3311.11"
            },
            {
              "asset_management_id": "1",
              "quantity": "2",
              "price": "244",
              "id": "1",
              "asset_name": "Milling Machine",
              "make": "Mahindra",
              "spec_rating": "2250KVA",
              "sr_no": "566",
              "location_id": "106",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "Mahindra 5.3 k ",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_zyvqo_1.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-12-01 10:40:01",
              "modified_at": "2022-05-24 11:43:46",
              "created_by": "2125",
              "modified_by": "5654",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Zenn Machines",
              "budget": "250",
              "budget_type": "0",
              "parent_id": "5",
              "warraanty_vendor_name": "VJ Machines Ltd.",
              "warraanty_contact_no": "8954612645",
              "warraanty_alternate_no": "7895663541",
              "warraanty_email": "vj@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": "",
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "2244",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "2125",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "2244"
            },
            {
              "asset_management_id": "2",
              "quantity": "1",
              "price": "80",
              "id": "2",
              "asset_name": "Radial drill machine",
              "make": "Winzz",
              "spec_rating": "2251KVA",
              "sr_no": "78",
              "location_id": "107",
              "purchase_date": "2023-01-01 00:00:00",
              "plasce_in_service": "2023-05-05 00:00:00",
              "vendor_id": null,
              "outlet_id": "5994",
              "business_id": "26",
              "manufacturer": null,
              "model": "Winzz 6.56 ",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_fhnoi_2.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2023-05-04 07:30:01",
              "modified_at": "2022-05-24 11:43:46",
              "created_by": "318",
              "modified_by": "6553",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Winzz Vehicle Ltd",
              "budget": "500",
              "budget_type": "0",
              "parent_id": "1",
              "warraanty_vendor_name": "Lakhani Enterprises",
              "warraanty_contact_no": "7895663541",
              "warraanty_alternate_no": "9564325632",
              "warraanty_email": "le@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "1",
              "warraanty_attach": "",
              "warraanty_end_date": "2024-01-31",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "1130",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "0",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "1130"
            },
            {
              "asset_management_id": "5",
              "quantity": "5",
              "price": "80",
              "id": "5",
              "asset_name": "Shaper Machine ",
              "make": "Zacko",
              "spec_rating": "2251KVA",
              "sr_no": "23",
              "location_id": "107",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "Zacko - 56",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392629_rotma_5.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-12-01 10:10:50",
              "modified_at": "2022-05-24 11:43:49",
              "created_by": "318",
              "modified_by": "5654",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": null,
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "NY Ltd",
              "budget": null,
              "budget_type": "0",
              "parent_id": "1",
              "warraanty_vendor_name": "Zacko",
              "warraanty_contact_no": "7897864795",
              "warraanty_alternate_no": "8998798771",
              "warraanty_email": "zacko@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/2605202214414.jpg",
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "800",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "318",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "160"
            },
            {
              "asset_management_id": "28",
              "quantity": "10",
              "price": "75",
              "id": "28",
              "asset_name": "Cleaning Machine",
              "make": "XYZ Ltd.",
              "spec_rating": "2250KVA",
              "sr_no": "6458564",
              "location_id": "106",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "5992",
              "business_id": "26",
              "manufacturer": null,
              "model": "Zen 2.5",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1661856147_fqvrh_28.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "5992",
              "is_deleted": "0",
              "created_at": "2022-12-22 11:46:09",
              "modified_at": "2022-08-30 10:42:27",
              "created_by": "323",
              "modified_by": "318",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": null,
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "1",
              "category_name": "Cleaner",
              "conditions": "New",
              "ownership": "Shubham",
              "asset_tag_no": "",
              "vendor_name": "ABC Ltd",
              "budget": null,
              "budget_type": "0",
              "parent_id": "0",
              "warraanty_vendor_name": "ABC Ltd",
              "warraanty_contact_no": "6666666666",
              "warraanty_alternate_no": "5555555555",
              "warraanty_email": "pritesh555@aquil.net",
              "warraanty_start_date": "2021-01-15",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "1250",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "323",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "125"
            }
          ],
          "maintenceCostArrayGraph": [
            [
              "Jan 23",
              10000
            ],
            [
              "Feb 23",
              12000
            ],
            [
              "Mar 23",
              3000
            ],
            [
              "Apr 23",
              4500
            ],
            [
              "May 23",
              7500
            ],
            [
              "Jun 23",
              18000
            ]
          ],
          "assetBudgetArrayGraph": [
            [
              "Jan 23",
              20000,
              10000
            ],
            [
              "Feb 2023",
              15000,
              12000
            ],
            [
              "Mar 2023",
              5000,
              3000
            ],
            [
              "Apr 2023",
              5000,
              4500
            ],
            [
              "May 2023",
              10000,
              7500
            ],
            [
              "Jun 2023",
              20000,
              18000
            ]
          ],
          "userId": 6553
        }
      } else {
        this.assetDashboard = result;
      }
      this.curveChart()
      this.linecurveChart()
    })
  }


  chartDataLine = {
    type: 'AreaChart',
    data: [],
    chartColumns: ['Month', 'Cost'],
    // options: {
    //   responsive: true,
    //   borderRadius: 15,
    //   colors: ['#33CCB2', '#EF6B6B'],
    //   legend: { position: 'none' },
    //   hAxis: { title: '' },
    //   vAxis: { title: '' },
    //   seriesType: 'bars',
    //   series: { 2: { type: 'bars' } }
    // },
    options: {
      isStacked: true,
      hAxis: {
        title: 'Month',
        titleTextStyle: { color: '#000000' }
      },
      vAxis: {
        title: 'Cost',
        titleTextStyle: { color: '#000000' }
      },
      colors: ['#27AE60'],
    },
    width: 1000,
    height: 400
  };
  displayFlag2 = true
  curveChart() {

    this.chartDataLine = {
      type: 'AreaChart',
      data: this.assetDashboard.maintenceCostArrayGraph,
      chartColumns: ['Month', 'Cost'],
      options: {
        isStacked: true,
        hAxis: {
          title: 'Month',
          titleTextStyle: { color: '#000000' }
        },
        vAxis: {
          title: 'Cost',
          titleTextStyle: { color: '#000000' }
        },
        colors: ['#27AE60'],
      },
      // options: {
      //   responsive: true,
      //   borderRadius: 15,
      //   colors: ['#33CCB2'],
      //   // legend: { position: 'bottom', alignment: 'center' },
      //   legend: { position: 'none' },
      //   hAxis: { title: 'Cost in INR.' },
      //   vAxis: { title: '' },
      //   seriesType: 'bars',
      //   series: { 2: { type: 'bars' } }
      // },
      width: 1000,
      height: 400
    };
    this.displayFlag2 = false


    return false;
    let jsonToBeUsed = []
    for (var type in this.assetDashboard.maintenceCostArrayGraph) {
      let item = {};
      item['Month'] = this.assetDashboard.maintenceCostArrayGraph[type].Month;
      item['cost'] = this.assetDashboard.maintenceCostArrayGraph[type].cost;
      jsonToBeUsed.push(item);
    }
    // console.log(jsonToBeUsed)
    let data = jsonToBeUsed
    // console.log(data)
    // let data1= [ {name:"Bob",value:23}, {name:"Robin",value:15}, {name:"Anne",value:31}, {name:"Mark",value:26}, {name:"Joe",value:17}, {name:"Eve",value:38}, {name:"Karen",value:42}, {name:"Kirsty",value:22}, {name:"Chris",value:35}, {name:"Lisa",value:33}, {name:"Tom",value:9}, {name:"Stacy",value:12}, {name:"Charles",value:17}, {name:"Mary",value:25}, ]
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;

    //  var parseDate = d3.time.format("%Y-%m-%d %X");
    var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;
    var area = d3.area()
      .curve(d3.curveBasis)
      .x(function (d) { return x(d.Month); })
      .y0(height)
      .y1(function (d) { return y(d.cost); });

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var svg = d3.select("#chart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");

    svg.append("text")
      .attr("class", "x label")
      .attr("text-anchor", "end")
      .attr("x", width - 50)
      .attr("y", height + 30)
    // .text("Time Chart");



    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")


    var svgAxis = d3.select("#groupedChartYAxis")
      .append("svg")
      .attr("width", 70)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + 60 + "," + margin.top + ")");

    svgAxis.append("text")
      .attr("class", "y_label cart-title")
      .attr("text-anchor", "end")
      .attr("x", -86)
      .attr("y", -46)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
    // .text("Cost in INR");

    ///////////////////////////////////////////////////////////
    // Initialise a X axis:
    var x = d3.scaleBand()
      .domain(data.map(function (d) { return d.Month; 1 }))
      .range([0, width])
      .padding(0.1);

    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return d.cost; })])
      .range([height, 0]);


    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .attr("class", "myXaxis");

    //   // Add Y axis

    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    svgAxis.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4.5)
      .attr("transform", "translate(80,0)")
      .attr("class", "lineTest")
      .attr("d", d3.line()
        .curve(d3.curveBasis)
        .x(function (d) { return x(d.Month) })
        .y(function (d) { return y(d.cost) })
      )

    svg.append("path")
      .data([data])
      .attr("class", "myArea")
      .attr("transform", "translate(80,0)")
      .attr("d", area)
    //  .on("mouseover", function(d) {

    var dots = svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("fill", "#5D5FEF")
      .attr("transform", "translate(85,0)")
      .attr("cx", function (d) { return x(d.Month); })
      .attr("cy", function (d) { return y(d.cost); })
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html((d.Month) + "<br/>" + d.cost.toLocaleString())
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }

  budgetChartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ["Date", 'Budget Allocated Cost', 'Budget Used'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'bars' } }
    },
    width: 1000,
    height: 400
  };

  budgetdisplayFlag = true
  linecurveChart() {
    // console.log(this.assetDashboard.assetBudgetArrayGraph)
    this.budgetChartData = {
      type: 'ColumnChart',
      data: this.assetDashboard.assetBudgetArrayGraph,
      chartColumns: ["Date", 'Budget Allocated Cost', 'Budget Used'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#1F7A6B', '#6FCF97'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: 'Value (' + this.apiService.convertCureency() + ')' },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 400
    };
    this.budgetdisplayFlag = false

    return;
    let array = []
    for (var type in this.assetDashboard.assetBudgetArrayGraph) {
      if (this.assetDashboard.assetBudgetArrayGraph[type].Month) {
        let item = {};
        item['Month'] = this.assetDashboard.assetBudgetArrayGraph[type].Month;
        item['budgetAllocatCost'] = parseInt(this.assetDashboard.assetBudgetArrayGraph[type].budgetAllocatCost);
        item['budgetUsed'] = parseInt(this.assetDashboard.assetBudgetArrayGraph[type].budgetUsed);
        array.push(item);


      }

    }
    // let result = {"success":true,"msg":"Data Found","data":{"ticketsByDepartment":[["","Open","Closed","Parked","Not Valid"],["Housekeeping1",65,7,5,1],["Housekeeping",12,0,0,0],["Operations",0,0,0,0],["Engineering",0,0,0,0],["Security",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["RnM",0,0,0,0],["Soft Services",0,0,0,0],["F & B",0,0,0,0],["Engineering",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["Housekeeping",0,0,0,0],["Housekeeping",0,0,0,0],["Engineering",0,0,0,0],["Security",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Technical",0,0,0,0],["Maintenance",0,0,0,0],["IT",0,0,0,0],["FM",0,0,0,0],["IT",0,0,0,0],["Marketing",0,0,0,0],["Electrical",0,0,0,0],["HVAC",0,0,0,0],["Plumbing",0,0,0,0],["Civil",0,0,0,0],["Mechanical",0,0,0,0],["Fire & Safety",0,0,0,0],["Emergency",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["ff",0,0,0,0],["Engineering",0,0,0,0],["MEP",0,0,0,0],["Purchase",0,0,0,0],["Management",0,0,0,0],["Engineering",0,0,0,0],["Housekeeping",0,0,0,0],["Operations",0,0,0,0],["Security",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["General",5,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["test1",1,0,0,0],["payment",0,0,0,0],["Event Management",7,0,0,0],["Gaming",1,0,0,0],["naaa",0,0,0,0],["naa",0,0,0,0],["house hold",1,0,0,0],["field ",0,0,0,0],["jyjyc",0,0,0,0],["game tester ",0,0,0,0],["IT Department",2,0,0,0],["Housekeeping",0,0,0,0],["SSDWDESDDDDWD",0,0,0,0],["Keeping",0,0,0,0],["Technical support",0,0,0,0],["Technical ",0,0,0,0]]}}
    let max: number = 0
    let data = []
    // console.log(array)
    for (let i = 0; i < array.length; i++) {
      // console.log()
      data.push({ 'group': array[i].Month, 'Open': array[i].budgetAllocatCost.toFixed(0), 'Closed': array[i].budgetUsed.toFixed(0) })

      if (max < array[i].budgetAllocatCost) max = array[i].budgetAllocatCost
      if (max < array[i].budgetUsed) max = array[i].budgetUsed


    }

    // console.log(data)

    var margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;


    // append the svg object to the body of the page
    var svgAxis = d3.select("#groupedChartYAxis2")
      .append("svg")
      .attr("width", 80)
      .attr("height", 500)
      .append("g")
      .attr("transform",
        "translate(" + 70 + "," + margin.top + ")");
    // append the svg object to the body of the page
    var svg = d3.select("#chart2")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 500)
      .append("g")
      .attr("transform",
        "translate(" + -5 + "," + margin.top + ")");

    // Parse the Data



    // List of subgroups = header of the csv files = soil condition here
    var subgroups = ['Open', 'Closed']

    var groups = []
    for (let i of data) groups.push(i.group)
    // List of groups = species here = value of the first column called group -> I show them on the X axis



    // console.log('subgroups', subgroups)
    // console.log('groups', groups)

    if (data.length < 10) {
      for (let i = data.length; i <= 8; i++) {
        let obj = {}
        let str = " "
        for (let j = 0; j < i; j++) str = str + " "
        obj['group'] = str
        for (let d of subgroups) obj[d] = 0

        // console.log(obj)
        data.push(obj)
        groups.push(str)
      }
    }
    // console.log(data)

    // Add X axis
    var x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2])
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      .call(d3.axisBottom(x).tickSize(0))

      .selectAll("text")
      .style("text-anchor", "end")

      .attr("transform", "rotate(-65)");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, (max + (max * 0.1))])
      .range([height, 0]);
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));

    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));


    // Another scale for subgroup position?
    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, 80])
      .padding([0.05])

    // color palette = one color per subgroup
    var color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(['#6FCF97', '#7879F1', '#F2994A', '#D6D6D6'])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
      .enter().append("rect")
      .attr("x", function (d) { return xSubgroup(d.key); })
      .attr("y", function (d) { return y(d.value); })
      .attr("width", 19.5)
      .attr("height", function (d) { return height - y(d.value); })
      .attr("fill", function (d) { return color(d.key); });


    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")

      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key) {
          // // console.log(key , d[key])
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("text")
      .text(function (d) {
        if (d.value != 0) return d.value;
      })
      .attr("x", function (d) {
        let data = (xSubgroup(d.key) + 5)
        return data;
      })
      .attr("y", function (d) {
        return y(d.value) - 5;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("fill", function (d) { return color(d.key); })
      .attr("text-anchor", "middle");

  }

  maxBearkDown = []
  getAssetswithMaxBraekDown() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // businessId:this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      limit: 10,
      pageNo: 1

    }
    this.apiService.postCall('AssetManagements/maxBreakdownList', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (this.staticDashboardFlag == true) {
        this.maxBearkDown = [
          {
            "id": "209",
            "asset_name": "Generator No. 2",
            "sr_no": "2",
            "make": "ADLEC",
            "spec_rating": "1000 AMP",
            "AssetManagementBreakdowns": "2",
            "outlet_name": "Site NGP",
            "locationName": "Second Floor",
            "status": "2",
            "downtime": "507"
          },
          {
            "id": "19",
            "asset_name": "Chiller Plant No. 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "spec_rating": "550 TR",
            "AssetManagementBreakdowns": "1",
            "outlet_name": "Site - A.",
            "locationName": "Common Area",
            "status": "2",
            "downtime": "94"
          },
          {
            "id": "264",
            "asset_name": "AHU Motor No. 5",
            "sr_no": "a",
            "make": "",
            "spec_rating": "a",
            "AssetManagementBreakdowns": "1",
            "outlet_name": "Manik Cinemas ",
            "locationName": "Near Dipak Square Tilak Road Akola",
            "status": "2",
            "downtime": "23"
          }
        ]
      } else {
        this.maxBearkDown = result['maxNoOfBreakdown'];
      }
    })
  }

  assetMaiantanance = []
  getassetMaiantanance() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // businessId:this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      limit: 10,
      pageNo: 1

    }
    this.apiService.postCall('AssetManagements/assetHighestMaintenceCosts', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.assetMaiantanance = [
          {
            "asset_management_id": "6",
            "quantity": "1",
            "price": "80",
            "id": "107",
            "asset_name": "Generator No. 2",
            "make": "Zacko",
            "spec_rating": "2251KVA",
            "sr_no": "23",
            "location_id": "107",
            "purchase_date": "2021-01-01 00:00:00",
            "plasce_in_service": "2021-01-10 00:00:00",
            "vendor_id": null,
            "outlet_id": "71",
            "business_id": "26",
            "manufacturer": null,
            "model": "Zacko - 56",
            "specification": null,
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653486936_jkrcu_6.png",
            "is_operational": "1",
            "is_active": "1",
            "migrated_to": "0",
            "is_deleted": "0",
            "created_at": "2022-10-13 05:40:01",
            "modified_at": "2022-05-24 11:43:49",
            "created_by": "1",
            "modified_by": "318",
            "amc_provider_name": null,
            "remarks": null,
            "renewal_date": "1970-01-01 05:30:00",
            "warranty_expiration": "",
            "image": "https://proptors-live.s3.amazonaws.com/enrich-salons---test-account/enrich---four-bungalows/2019/floorplans/Jw4TSUI1548750750New.png",
            "sla_expiry_date": "1970-01-01 00:00:00",
            "assigned_customer": null,
            "status": "1",
            "is_used": "0",
            "category_name": "Machine",
            "conditions": "Used",
            "ownership": "Self-owned",
            "asset_tag_no": "",
            "vendor_name": "NY Ltd",
            "budget": null,
            "budget_type": "0",
            "parent_id": "0",
            "warraanty_vendor_name": "Zacko",
            "warraanty_contact_no": "7897864795",
            "warraanty_alternate_no": "8998798771",
            "warraanty_email": "zacko@gmail.com",
            "warraanty_start_date": "2021-01-10",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "2023-01-15",
            "warraanty_renewal_date": null,
            "down_time": "0",
            "maintemce_cost": "29,500",
            "badget_type": "0",
            "budget_monthly_cost": "0",
            "budget_annual_cost": "0",
            "budget_notify": "0",
            "updated_by": "318",
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "outlet_name": "Site - A.",
            "outlet_code": "DM1",
            "site_code": null,
            "location": "",
            "latitude": "20.7025544360567",
            "longitude": "76.99185782698785",
            "city": "",
            "zone_id": "27",
            "state_id": "92",
            "citie_id": "1072",
            "cluster_id": "56",
            "district_id": "307",
            "client_id": "43",
            "site_type": null,
            "site_group": null,
            "area": "",
            "qr_code": "1571380368_71.png",
            "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
            "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
            "default_users": "4431",
            "default_users_internal_complaints": null,
            "default_users_near_miss": null,
            "default_department": "86",
            "is_ad_hoc": "0",
            "created": "2018-09-10 18:43:16",
            "modified": "2022-05-22 19:35:46",
            "name": "First Floor",
            "qr_image": "https://proptors-live.s3.amazonaws.com/synccit/property_barcode/2022/property_barcode/1653388666_cizwm_107.png",
            "finalcost": 29500,
            "locationName": "First Floor",
            "amccost": 0,
            "additionalCost": 0,
            "partcost": 0
          },
          {
            "asset_management_id": "3",
            "quantity": "4",
            "price": "300",
            "id": "2339",
            "asset_name": "Chiller Plant No. 1",
            "make": "JK",
            "spec_rating": "2250KVA",
            "sr_no": "14236",
            "location_id": "2339",
            "purchase_date": "2021-01-01 00:00:00",
            "plasce_in_service": "2021-01-10 00:00:00",
            "vendor_id": null,
            "outlet_id": "71",
            "business_id": "26",
            "manufacturer": null,
            "model": "JK G89",
            "specification": null,
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392627_twils_3.png",
            "is_operational": "1",
            "is_active": "1",
            "migrated_to": "0",
            "is_deleted": "0",
            "created_at": "2022-09-21 15:00:01",
            "modified_at": "2022-05-24 11:43:47",
            "created_by": "1",
            "modified_by": "318",
            "amc_provider_name": null,
            "remarks": null,
            "renewal_date": "1970-01-01 05:30:00",
            "warranty_expiration": "",
            "image": "https://proptors-live.s3.amazonaws.com/demo-account/site---a/2020/floorplans/gGbm3xy1599892265New.png",
            "sla_expiry_date": "1970-01-01 00:00:00",
            "assigned_customer": null,
            "status": "1",
            "is_used": "0",
            "category_name": "Machine",
            "conditions": "Used",
            "ownership": "Self-owned",
            "asset_tag_no": "",
            "vendor_name": "HDA Machines",
            "budget": "750",
            "budget_type": "0",
            "parent_id": "0",
            "warraanty_vendor_name": "Sai Machines",
            "warraanty_contact_no": "9564325632",
            "warraanty_alternate_no": "8954612645",
            "warraanty_email": "sai@gmail.com",
            "warraanty_start_date": "2021-01-10",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "2023-01-15",
            "warraanty_renewal_date": null,
            "down_time": "0",
            "maintemce_cost": "3420",
            "badget_type": "0",
            "budget_monthly_cost": "0",
            "budget_annual_cost": "0",
            "budget_notify": "0",
            "updated_by": "323",
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "outlet_name": "Site - A.",
            "outlet_code": "DM1",
            "site_code": null,
            "location": "",
            "latitude": "20.7025544360567",
            "longitude": "76.99185782698785",
            "city": "",
            "zone_id": "27",
            "state_id": "92",
            "citie_id": "1072",
            "cluster_id": "56",
            "district_id": "307",
            "client_id": "43",
            "site_type": null,
            "site_group": null,
            "area": "",
            "qr_code": "1571380368_71.png",
            "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
            "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
            "default_users": "4431",
            "default_users_internal_complaints": null,
            "default_users_near_miss": null,
            "default_department": "86",
            "is_ad_hoc": "0",
            "created": "2020-09-12 11:56:20",
            "modified": "2022-05-22 19:36:54",
            "name": "Second Floor",
            "qr_image": "https://proptors-live.s3.amazonaws.com/synccit/property_barcode/2022/property_barcode/1653390312_uinvz_2339.png",
            "finalcost": 18000,
            "locationName": "Second Floor",
            "amccost": 0,
            "additionalCost": 0,
            "partcost": 0
          },
          {
            "asset_management_id": "19",
            "quantity": "1",
            "price": "100",
            "id": "4848",
            "asset_name": "AHU Motor No. 5",
            "make": "DAIKIN",
            "spec_rating": "550 TR",
            "sr_no": "6700101",
            "location_id": "4848",
            "purchase_date": "2019-07-20 00:07:00",
            "plasce_in_service": "2019-07-20 00:07:00",
            "vendor_id": null,
            "outlet_id": "71",
            "business_id": "26",
            "manufacturer": null,
            "model": "C3612 BLYY 2",
            "specification": "spec",
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658668545_ozybk_19.png",
            "is_operational": "1",
            "is_active": "1",
            "migrated_to": "5994",
            "is_deleted": "0",
            "created_at": "2023-05-04 11:18:23",
            "modified_at": "2022-07-24 13:15:45",
            "created_by": "1",
            "modified_by": "6553",
            "amc_provider_name": null,
            "remarks": "3",
            "renewal_date": "1970-01-01 05:30:00",
            "warranty_expiration": null,
            "image": "https://proptors-live.s3.amazonaws.com/enrich-salons---test-account/enrich---four-bungalows/2019/floorplans/Jw4TSUI1548750750New.png",
            "sla_expiry_date": "1970-01-01 00:00:00",
            "assigned_customer": null,
            "status": "1",
            "is_used": "1",
            "category_name": "HVAC",
            "conditions": "New",
            "ownership": "WMG",
            "asset_tag_no": null,
            "vendor_name": "KE Ltd.",
            "budget": "2000",
            "budget_type": "0",
            "parent_id": "20",
            "warraanty_vendor_name": "Daikin Ltd.",
            "warraanty_contact_no": "9598989898",
            "warraanty_alternate_no": "7988979798",
            "warraanty_email": "daikin@gmail.com",
            "warraanty_start_date": "2022-08-01",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "2022-10-31",
            "warraanty_renewal_date": null,
            "down_time": "0",
            "maintemce_cost": "3311.11",
            "badget_type": "0",
            "budget_monthly_cost": "0",
            "budget_annual_cost": "0",
            "budget_notify": "0",
            "updated_by": "323",
            "warraanty_cron_date": "2022-10-30",
            "amc_cron_date": "2022-10-28",
            "outlet_name": "Site - A.",
            "outlet_code": "DM1",
            "site_code": null,
            "location": "",
            "latitude": "20.7025544360567",
            "longitude": "76.99185782698785",
            "city": "",
            "zone_id": "27",
            "state_id": "92",
            "citie_id": "1072",
            "cluster_id": "56",
            "district_id": "307",
            "client_id": "43",
            "site_type": null,
            "site_group": null,
            "area": "",
            "qr_code": "1571380368_71.png",
            "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
            "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
            "default_users": "4431",
            "default_users_internal_complaints": null,
            "default_users_near_miss": null,
            "default_department": "86",
            "is_ad_hoc": "0",
            "created": "2021-07-24 16:27:27",
            "modified": "2022-05-22 19:38:05",
            "name": "Common Area",
            "qr_image": "https://proptors-live.s3.amazonaws.com/synccit/property_barcode/2022/property_barcode/1653392208_vsgwh_4848.png",
            "finalcost": 7500,
            "locationName": "Common Area",
            "amccost": 0,
            "additionalCost": 0,
            "partcost": 0
          }
        ]
      } else {
        this.assetMaiantanance = result['assetHighestMaintenceCosts'];
      }
    })
  }

  openListMaxBraekdoen(id) {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Asset ID', 'Property', 'Location', 'Count', 'Total Time (hrs)'], api: 'AssetManagements/maxBreakdownList', tableName: 'Breakdown Statistics', dataSource: ['asset_name', 'id', 'outlet_name', 'locationName', 'AssetManagementBreakdowns', 'downtime'], apiObj: 'maxNoOfBreakdown', rowCountParam: 'maxBreakdownCounts' }
    this.storedData.dashBoardIndex['apiObjParam'] = { startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'), }
    this.route.navigate(['/dashboard-index/' + id])

  }
  openListassetMaiantanance(id) {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date();
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Asset ID', 'Asset property', 'Location', 'Total Cost', 'Parts Cost', 'AMC Cost', 'Additional Costs'], api: 'AssetManagements/assetHighestMaintenceCosts', tableName: 'Maintenance Cost Incurred', dataSource: ['asset_name', 'id', 'outlet_name', 'locationName', 'finalcost', 'partcost', 'amccost', 'additionalCost'], apiObj: 'assetHighestMaintenceCosts', rowCountParam: 'count' }
    this.storedData.dashBoardIndex['apiObjParam'] = { startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'), }
    this.route.navigate(['/dashboard-index/' + id])
  }



  //pm

  highestComplainPms = []

  highestComplains() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('pms/userWithHighestCompliance', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (this.staticDashboardFlag == true) {
        this.highestComplainPms = [
          {
            "id": "19",
            "asset_name": "Engg. Supervisor Team",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Engg. Supervisor Team",
            "userId": "4431",
            "outlet_name": "Site - A",
            "pmTemplateId": "30",
            "sub_user_id": "7125",
            "totalAssign": 30,
            "percentage": "2 %"
          },
          {
            "id": "211",
            "asset_name": "Air Blower No. 2 (Pump)",
            "sr_no": "U8WMCH00976",
            "make": "KIRLOSKER",
            "full_name": "Nick D'Souza	",
            "userId": "323",
            "outlet_name": "Site - C",
            "pmTemplateId": "26",
            "sub_user_id": "68",
            "totalAssign": 26,
            "percentage": "0 %"
          },
          {
            "id": "19",
            "asset_name": "CHILLER PLANT 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Viraj Gandhi",
            "userId": "5654",
            "outlet_name": "Site - B",
            "pmTemplateId": "22",
            "sub_user_id": "10202",
            "totalAssign": 22,
            "percentage": "1 %"
          },
          {
            "id": "19",
            "asset_name": "CHILLER PLANT 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Sam Patil",
            "userId": "5654",
            "outlet_name": "Site - A",
            "pmTemplateId": "19",
            "sub_user_id": "10202",
            "totalAssign": 19,
            "percentage": "1 %"
          },
          {
            "id": "19",
            "asset_name": "CHILLER PLANT 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Operations Team",
            "userId": "5654",
            "outlet_name": "Site - B",
            "pmTemplateId": "17",
            "sub_user_id": "10202",
            "totalAssign": 17,
            "percentage": "1 %"
          }
        ]
      } else {
        this.highestComplainPms = result['userWithHighestCompliance'];
      }
    })
  }

  openListhighestPMComplaints(id) {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Name', 'Property', 'Assigned', 'Completed', '% Compliance'], api: 'pms/userWithHighestCompliance', tableName: 'Most Compliant Associates/Teams', dataSource: ['asset_name', 'full_name', 'outlet_name', 'totalAssign', 'pmTemplateId', 'percentage'], apiObj: 'userWithHighestCompliance', rowCountParam: 'count' }
    this.storedData.dashBoardIndex['apiObjParam'] = { startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'), }
    this.route.navigate(['/dashboard-index/' + id])
  }

  //Parts

  partsDashboard
  getParts() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      partId: this.selectedFiler.department ? this.selectedFiler.department.partId : null,
      userId: this.apiService.userId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.startLoader()
    this.apiService.postCall('parts/partsDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (this.staticDashboardFlag == true) {
        this.partsDashboard = {
          "success": true,
          "msg": "The data has been saved.",
          "partDataTotalInrs": "1,75,000",
          "noOfItemHamd": "500",
          "noOfPartConsumed": "45",
          "costNoOfPartConsumed": "6,200",
          "mostUsedParts": [
            {
              "id": "7",
              "name": "Drill Head",
              "usedCount": "2"
            },
            {
              "id": "1",
              "name": "Base plates ",
              "usedCount": "1"
            },
            {
              "id": "46",
              "name": "Hexon1",
              "usedCount": "1"
            },
            {
              "id": "5",
              "name": "Handle",
              "usedCount": "1"
            },
            {
              "id": "4",
              "name": "Pump",
              "usedCount": "1"
            }
          ],
          "itemBelowMqt": [
            {
              "id": "7",
              "name": "Drill Head",
              "usedCount": "2"
            },
            {
              "id": "1",
              "name": "Base plates ",
              "usedCount": "1"
            },
            {
              "id": "46",
              "name": "Hexon1",
              "usedCount": "1"
            },
            {
              "id": "5",
              "name": "Handle",
              "usedCount": "1"
            },
            {
              "id": "4",
              "name": "Pump",
              "usedCount": "1"
            }
          ],
          "userId": 6553
        }
      } else {
        this.partsDashboard = result;
      }
    })
  }

  top5SurveyEntries = []
  gettop5SurveyEntries() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/top5SurveyEntries', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (this.staticDashboardFlag == true) {
        this.top5SurveyEntries = [
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Customer's Feedback Form",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.87,
            "NoOfRespondentTo": "63",
            "outletName": "Site - A",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Tenant's Feedback Form",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.79,
            "NoOfRespondentTo": "59",
            "outletName": "Site - C",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Employee Survey",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.62,
            "NoOfRespondentTo": "56",
            "outletName": "Site - B",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Service Survey",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.51,
            "NoOfRespondentTo": "49",
            "outletName": "Site - A",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Management Staff Feedback Form",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.48,
            "NoOfRespondentTo": "44",
            "outletName": "Site - B",
            "srNo": 1
          }
        ]
      } else {
        this.top5SurveyEntries = result['top5SurveyEntries'];
      }
    })
  }

  openListtop5SurveyEntries(id) {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    this.storedData.dashBoardIndex = { headers: ['Question', 'Survey Type', 'Average Score'], api: 'Feedbacks/top5SurveyEntries', tableName: 'Top 5 Surveys', dataSource: ['survey_name', 'question', 'average'], apiObj: 'top5SurveyEntries', rowCountParam: 'count' }
    this.storedData.dashBoardIndex['apiObjParam'] = { startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'), }
    this.route.navigate(['/dashboard-index/' + id])
  }





  // exportAsPDF(div_id) {
  //   let data = document.getElementById(div_id);
  //   html2canvas(data).then(canvas => {
  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
  //     // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
  //     pdf.save('Filename.pdf');
  //   });
  // }


  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 1200) {
      this.marginLeft = 0;
      this.fixedIWdth = 850;
      this.fullScreen = 700;
    }

    else if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 950;
      this.fullScreen = 800;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
      this.fullScreen = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
      this.fullScreen = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 950;
      this.fullScreen = 950;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
      this.fullScreen = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
      this.fullScreen = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
      this.fullScreen = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
      this.fullScreen = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
      this.fullScreen = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
      this.fullScreen = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
      this.fullScreen = 1900;
    }


    d3.selectAll("#groupedChartYAxis111 > *").remove();
    d3.selectAll("#groupedChart111 > *").remove();


    d3.selectAll("#groupedChartYAxis > *").remove();
    d3.selectAll("#chart > *").remove();

    d3.selectAll("#groupedChartYAxis2 > *").remove();
    d3.selectAll("#chart2 > *").remove();

    d3.selectAll("#groupedChartYAxis3 > *").remove();
    d3.selectAll("#chart3 > *").remove();


    d3.selectAll("#groupedChartYAxis4 > *").remove();
    d3.selectAll("#chart4 > *").remove();

    this.groupedBarchart()
    this.groupedBarchartPm()
    this.curveChart()
    this.linecurveChart()
  }

  calculatePercentage() {
    if (this.checklistDashboardToday?.totalChecklistsScheduled != 0 && this.checklistDashboardToday?.completedChecklists != 0) {
      if (this.staticDashboardFlag == true) {
        return ((Number((this.checklistDashboardToday?.completedChecklists.replace(/,/g, "") / this.checklistDashboardToday?.totalChecklistsScheduled.replace(/,/g, "")) * 100).toFixed(0)));
      } else {
        return ((Number((this.checklistDashboardToday?.completedChecklists / this.checklistDashboardToday?.totalChecklistsScheduled) * 100).toFixed(0)));
      }
    } else {
      return 0;
    }
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
}
