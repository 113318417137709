import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;


@Component({
  selector: 'app-dashboard-parts',
  templateUrl: './dashboard-parts.component.html',
  styleUrls: ['./dashboard-parts.component.css']
})
export class DashboardPartsComponent implements OnInit {
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;


  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];

  selectedFiler = { department: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      //this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.statsuFilter = false;
      // this.typeFilter = false;
      // // this.showAddMenu = false;
      // this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.typeFilter = false;
      // this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }
  closeAll() {
    this.propertyFiletr = false;
    this.departmentFilter = false;
    // this.typeFilter = false;
    // this.businessFilter = false;
  }
  navigateToDetails(id) {

    // this.route.navigateByUrl('/parts-reports/'+id);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/parts-reports/${id}`])
    );

    window.open(url, '_blank');
  }
  departmentList
  getDepartmentList() {
    this.apiService.getCall('parts/index/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.ngOnInit()
  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { department: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
  }
  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']
      this.filterpropertyList = result['data']
if(this.apiService.outletId){
  let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
}
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }

      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  // propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.partName != null) {
        return item.partName.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }






  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }
  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    this.getAsset()
    this.getLocationList()
    this.getMostUsedParts()
    this.getItemBelowMqt()
    this.getDepartmentList()

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
      }
      if (this.apiService.role == 1) {
        this.businessFeatureAttendance = true
      }
    }
  }
  staticDashboardstaticDashboardChangeValue = this.storedData.staticDashboardFlag;
  staticDashboardChange() {
    if (this.staticDashboardstaticDashboardChangeValue == false || this.staticDashboardstaticDashboardChangeValue == undefined) {
      this.staticDashboardFlag = true;
      this.storedData.staticDashboardFlag = true;
      this.ngOnInit();
    } else {
      this.staticDashboardFlag = false;
      this.storedData.staticDashboardFlag = false;
      this.ngOnInit();
    }
  }
  partsDashboard
  getAsset() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      partId: this.selectedFiler.department ? this.selectedFiler.department.partId : null,
      userId: this.apiService.userId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      // startDate:this.selectedFiler.startDate ?  moment(this.selectedFiler.startDate).format('YYYY-MM-DD'):moment(satrtDate).format('YYYY-MM-DD'),
      // endDate:this.selectedFiler.ensDate ?  moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),

    }
    this.apiService.startLoader()
    this.apiService.postCall('parts/partsDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.partsDashboard = {
          "success": true,
          "msg": "The data has been saved.",
          "partDataTotalInrs": "1,75,000",
          "noOfItemHamd": "500",
          "noOfPartConsumed": "45",
          "costNoOfPartConsumed": "6,200",
          "mostUsedParts": [
            {
              "id": "7",
              "name": "Drill Head",
              "usedCount": "2"
            },
            {
              "id": "1",
              "name": "Base plates ",
              "usedCount": "1"
            },
            {
              "id": "46",
              "name": "Hexon1",
              "usedCount": "1"
            },
            {
              "id": "5",
              "name": "Handle",
              "usedCount": "1"
            },
            {
              "id": "4",
              "name": "Pump",
              "usedCount": "1"
            }
          ],
          "itemBelowMqt": [
            {
              "id": "7",
              "name": "Drill Head",
              "usedCount": "2"
            },
            {
              "id": "1",
              "name": "Base plates ",
              "usedCount": "1"
            },
            {
              "id": "46",
              "name": "Hexon1",
              "usedCount": "1"
            },
            {
              "id": "5",
              "name": "Handle",
              "usedCount": "1"
            },
            {
              "id": "4",
              "name": "Pump",
              "usedCount": "1"
            }
          ],
          "userId": 6553
        }
      } else {
        this.partsDashboard = result;
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }
  downloadReport() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    let body = {
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      partId: this.selectedFiler.department ? this.selectedFiler.department.partId : null,
      userId: this.apiService.userId,
      "roleId": this.apiService.roleId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      // startDate:this.selectedFiler.startDate ?  moment(this.selectedFiler.startDate).format('YYYY-MM-DD'):moment(satrtDate).format('YYYY-MM-DD'),
      // endDate:this.selectedFiler.ensDate ?  moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      partsDashboard:this.partsDashboard,
      mostUsedParts:this.mostUsedParts,
      itemBelowMqt:this.itemBelowMqt,
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('parts/dashboardPartsDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  mostUsedParts = []
  getMostUsedParts() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      partId: this.selectedFiler.department ? this.selectedFiler.department.partId : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      // startDate:this.selectedFiler.startDate ?  moment(this.selectedFiler.startDate).format('YYYY-MM-DD'):moment(satrtDate).format('YYYY-MM-DD'),
      // endDate:this.selectedFiler.ensDate ?  moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),

    }
    this.apiService.startLoader()
    this.apiService.postCall('parts/mostUsedParts', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.mostUsedParts = [
          {
            "id": "36",
            "qty_hand": "27",
            "min_qty": "10",
            "name": "Voltage Regulators",
            "number": "1",
            "price_per_pice": "10",
            "usedCount": "15",
            "cost": "750",
            "outlet_name": "Site - A.",
            "locationName": "First Floor"
          },
          {
            "id": "4",
            "qty_hand": "35",
            "min_qty": "15",
            "name": "Valve",
            "number": "98,465",
            "price_per_pice": "50",
            "usedCount": "12",
            "cost": "100",
            "outlet_name": "Site - A.",
            "locationName": "Ground Floor"
          },
          {
            "id": "5",
            "qty_hand": "14",
            "min_qty": "20",
            "name": "Exhaust Fans",
            "number": "9,845",
            "price_per_pice": "75",
            "usedCount": "12",
            "cost": "300",
            "outlet_name": "Site - A.",
            "locationName": "First Floor"
          },
          {
            "id": "7",
            "qty_hand": "18",
            "min_qty": "50",
            "name": "Condenser",
            "number": "6,854",
            "price_per_pice": "80",
            "usedCount": "3",
            "cost": "160",
            "outlet_name": "Site - A.",
            "locationName": "Second Floor"
          },
          {
            "id": "20",
            "qty_hand": "11",
            "min_qty": "99",
            "name": "Compressor",
            "number": "74,745",
            "price_per_pice": "111.11",
            "usedCount": "3",
            "cost": "1,222.20,99,99,99,99,998",
            "outlet_name": "Site - A.",
            "locationName": "Ground Floor"
          }
        ]
      } else {
        this.mostUsedParts = result['mostUsedParts'];
      }

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }

  itemBelowMqt = []
  getItemBelowMqt() {
    let satrtDate = new Date()
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()

    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      partId: this.selectedFiler.department ? this.selectedFiler.department.partId : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      // startDate:this.selectedFiler.startDate ?  moment(this.selectedFiler.startDate).format('YYYY-MM-DD'):moment(satrtDate).format('YYYY-MM-DD'),
      // endDate:this.selectedFiler.ensDate ?  moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),

    }
    this.apiService.startLoader()
    this.apiService.postCall('parts/itemBelowMqt', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.itemBelowMqt = [
          {
            "id": "43",
            "name": "Condenser",
            "number": "65",
            "price_per_pice": "150",
            "MTQ": "20",
            "qty_hand": "18",
            "usedCount": "20",
            "cost": "0",
            "outlet_name": "DLF Mall",
            "locationName": "Second Floor"
          },
          {
            "id": "39",
            "name": "Voltage Regulators",
            "number": 0,
            "price_per_pice": "5",
            "MTQ": "35",
            "qty_hand": "27",
            "usedCount": "1",
            "cost": "20",
            "outlet_name": "Tower 1",
            "locationName": "Ground Floor"
          },
          {
            "id": "37",
            "name": "Power Panel	",
            "number": "1",
            "price_per_pice": "10",
            "MTQ": "20",
            "qty_hand": "15",
            "usedCount": "20",
            "cost": "50",
            "outlet_name": "PVR - Juhu",
            "locationName": "5th Floor"
          },
          {
            "id": "35",
            "name": "Compressor",
            "number": 0,
            "price_per_pice": "10",
            "MTQ": "15",
            "qty_hand": "11",
            "usedCount": "11",
            "cost": "0",
            "outlet_name": "Site - A.",
            "locationName": "Common Area"
          },
          {
            "id": "34",
            "name": "Exhaust Fans",
            "number": 0,
            "price_per_pice": "100",
            "MTQ": "25",
            "qty_hand": "14",
            "usedCount": "10",
            "cost": "0",
            "outlet_name": "Site - B",
            "locationName": "First Floor"
          }
        ]
      } else {
        this.itemBelowMqt = result['itemBelowMqt'];
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }



  openListMostUsedParts(id) {
    this.storedData.dashBoardIndex = { headers: ['Part Name', 'Part No', 'Property', 'Location', 'Quantity', 'Cost'], api: 'parts/mostUsedParts', tableName: 'Most Used Parts', dataSource: ['name', 'number', 'outlet_name', 'locationName', 'usedCount', 'cost'], apiObj: 'mostUsedParts', rowCountParam: 'count' }
    localStorage.setItem("parts-dashboard-data", JSON.stringify(this.storedData.dashBoardIndex));
    // this.route.navigate(['/dashboard-index/' + id])
    // console.log(localStorage.getItem("parts-dashboard-data"));
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/parts-dashboard-index/${id}`])
    );

    window.open(url, '_blank');
  }

  openListItemBelowMQT(id) {

    this.storedData.dashBoardIndex = { headers: ['Part Name', 'Part No', 'Quantity In Hand', 'Property', 'Location', 'MTQ Level', 'Qty below MTQ'], api: 'parts/itemBelowMqt', tableName: 'Minimum Threshold Qty', dataSource: ['name', 'number', 'qty_hand', 'outlet_name', 'locationName', 'MTQ', 'usedCount',], apiObj: 'itemBelowMqt', rowCountParam: 'count' }
    localStorage.setItem("parts-dashboard-data", JSON.stringify(this.storedData.dashBoardIndex));
    //this.route.navigate(['/dashboard-index/'+id])    //'Total Cost', 'cost' ,
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/parts-dashboard-index/${id}`])
    );

    window.open(url, '_blank');
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.ZoneListData = []
          this.filteredZoneListData = null;
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
}
