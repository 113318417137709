import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-transfer-report',
  templateUrl: './transfer-report.component.html',
  styleUrls: ['./transfer-report.component.css']
})
export class TransferReportComponent implements OnInit {

  constructor(
    public route: Router, 
    public apiService: ApiService, 
    public storedData: StoredData,
    public _location: Location,
  ) { }
  
  selectedFiler = { part: null, property: null,endDate:null,startDate:null,number:null,department:null }
  fromDate:Date = new Date();
  toDate:Date = new Date();
  
  sortSelected: any = null;
  sort: any = null;

  reportList = [];
  
  applyFlag = false;
  
  partList = [];
  partListFilter = [];
  partFilter = false;
  partFilterSearch:any;

  filterSelect = false;
  
  numberFilter = false;

  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch:any

  ngOnInit(): void {
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.applyFlag = true;
    this.getStockListing();
    this.getPropertyList();
  }
  openTop(id){
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  getStockListing() {
    this.apiService.startLoader()
    let body = {
      sortBy: this.sortSelected,
      sortType: this.sort,
      partId: this.selectedFiler.part ? this.selectedFiler.part.id : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(this.fromDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD')  : moment(this.toDate).format('YYYY-MM-DD'),
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      download: 0, 
      number: this.selectedFiler.number,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
      typeId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
    }
    this.apiService.postCall('parts/getTransferReport', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data']
      this.apiService.stopLoader()
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { part: null, property: null,endDate:this.toDate,startDate:this.fromDate,number:null,department:null }
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getStockListing()
  }

  sortData(data:any) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getStockListing()
  }

  applyFilter() {
    this.applyFlag = true;
    this.getStockListing();
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.part != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.number != null) length = length + 1
    if (this.selectedFiler.department != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  getPartsList():void{
    this.apiService.startLoader()
    let obj = {
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('parts/partsDropdownList', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.partList = []
      if(result['success'] == true){
        this.partList = result['data'];
        this.partListFilter = result['data'];
      }
    })
  }
  
  filterPart(ev:any){
    this.partList = this.partListFilter.filter((item) => {
      if (item.name != null) {
        return item.assetName.toLowerCase().includes(this.partFilterSearch.toLowerCase())
      }
    })
  }

  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      this.departmentList = result['departments'];
      this.filterdepartmentList = result['departments'];

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    })
  }

  departmentList=[]
  filterdepartmentList=[]
  
  filterDepartmentSearch
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }

  filterProperty(ev:any) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  departmentFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
      this.partFilter = false;
      this.departmentFilter = false;
    }
  }
  closeAll() {
    this.propertFilter = false;
    this.partFilter = false;
    this.departmentFilter = false;
  }

  downloadReport():void{
    this.apiService.startLoader()
    let body = {
      partId: this.selectedFiler.part ? this.selectedFiler.part.id : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(this.fromDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD')  : moment(this.toDate).format('YYYY-MM-DD'),
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      download: 1,
      number: this.selectedFiler.number,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
      typeId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
    }
    this.apiService.postCall('parts/getTransferReport', {}, body).subscribe((result: any[]) => {
      if(result['success'] == true){
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }

}
