import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from '../../../common/services/apiCalls.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-employee-designation-add',
  templateUrl: './employee-designation-add.component.html',
  styleUrls: ['./employee-designation-add.component.css']
})
export class EmployeeDesignationAddComponent implements OnInit {
  shide = false;
  hide = false;
  form: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  businessId = 0;
  statusList = [{id: 0, name: 'Deactive'}, {id: 1, name: 'Active'}];
  routeId;
  departmentList = [];

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.form = this.fb.group({
      businessId: [null],
      name: [null, Validators.required],
      authId: [null],
      empDepartmentId: [null],
      status: [1],
    });
    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };


    this.getDetailsById();
    this.getDepartmentList();

    // if (this.apiService.outletId) {
    //   this.onItemSelect({id: this.apiService.outletId});
    // }

  }

  goBack(): void {
    this._location.back();
  }


  save(): void {

    if (!this.routeId) {
      this.form.value.authId = this.apiService.authId;
      this.form.value.businessId = this.apiService.buisnessId;

      let formData = this.form.value;
      this.apiService.startLoader();
      this.apiService.postCall('EmpDesignations/add', {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    } else {
      this.form.value.authId = this.apiService.authId;
      this.form.value.businessId = this.apiService.buisnessId;
      // this.form.value.status = this.form.value.status[0].id;
      // this.form.value.empDepartmentId = this.form.value.empDepartmentId[0].id;
      let formData = this.form.value;
      this.apiService.startLoader();
      this.apiService.postCall('EmpDesignations/edit/' + this.routeId, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    }


  }

  getValidation() {
    return this.form.valid;
  }

  getDetailsById() {
    if (this.routeId) {
      this.apiService.postCall('EmpDesignations/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {

        this.apiService.stopLoader();
        this.form.patchValue(result['data']);
        // var findStatus = this.statusList.filter(dat => dat.id == result['data'].status);
        // this.form.patchValue({status: findStatus});
      });
    }
  }

  getDepartmentList() {
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('EmpDepartments/index', {}, body).subscribe((result: any[]) => {
      this.departmentList = result['data'];
      this.apiService.stopLoader();
    });
  }

  backClicked() {
    this.router.navigate(['/employee/designation-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
