import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import  jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
declare var d3: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  assetId: string;

  constructor(public _location: Location,public router:Router,public route:Router ,public apiService:ApiService) { }

  range:any;
  exportPermission=false
  notifyAddPermission=false
  pmPermission=false;
  partsFeaturePermission = false;
  partsPermission = true;
  maintenanceFeaturePermission = false;
  ngOnInit(): void {

    // this.screenWidth= window.innerWidth;

    // if (this.screenWidth <= 1380) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 750;
    // }
    // else if (this.screenWidth <= 1450) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 900;
    // }
    // else if (this.screenWidth <= 1500) {
    //   this.marginLeft = 2;
    //   this.fixedIWdth = 950;
    // }
    // else if (this.screenWidth <= 1600) {
    //   this.marginLeft = 3;
    //   this.fixedIWdth = 950;
    // }
    // else if (this.screenWidth <= 1700) {
    //   this.marginLeft = 4;
    //   this.fixedIWdth = 1050;
    // }
    // else if (this.screenWidth <= 1800) {
    //   this.marginLeft = 6;
    //   this.fixedIWdth = 1100;
    // }

    // else if (this.screenWidth <= 1920) {
    //   this.marginLeft = 8;
    //   this.fixedIWdth = 1150;
    // }

    // else if (this.screenWidth <= 2048) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1200;
    // }
    // else if (this.screenWidth <= 2100) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1300;
    // }
    // else if (this.screenWidth <= 2400) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1500;
    // }
    // else if (this.screenWidth <= 2800) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1900;
    // }

    let endDate =new Date(); 
    const date = new Date();
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    let starDate = new Date(date.getFullYear(), date.getMonth(), 1);
    starDate.setHours(starDate.getHours() + 5);
    starDate.setMinutes(starDate.getMinutes() + 30);

    this.range = [starDate , endDate];


    // this.range = [starDate , date];



    this.assetId = this.router.url.split("/")[2]
    this.getAssetDetail()

    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }

    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
     
      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
      // idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      // this.statusList.splice(idx1,1)
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Reports" && permission[i]['action'] == "Download Report" && permission[i]['actionStatus'] == 1){
        this.exportPermission=true
      }
      if(permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.notifyAddPermission=true
      }
      if(permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.pmPermission=true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true
    }

  }



  
  chartDataLine = {
    type: 'Line',
    data: [],
    chartColumns: [],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'none' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 0: {pointShape: 'circle' } },
      curveType: 'function',
      pointSize: 20,
    },
    width: 500,
    height: 400
  };

  statusList=[]

  reports:any;
  depreciation:any;

  assetDetails
  depriciationList

  applyFilter(){
    // console.log(this.range[0])
    this.getAssetDetail()
    d3.selectAll("#chart2YAxis > *").remove();
    d3.selectAll("#chart2 > *").remove();
    d3.selectAll("#piechart > *").remove();
    this.filterSelect = false
  }
  filterSelect:boolean;

  getAssetDetail(){
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
    
    })


    this.apiService.startLoader()
    let obj={
      startDate:moment(this.range[0]).format('YYYY-MM-DD'),
endDate:moment(this.range[1]).format('YYYY-MM-DD'),

    }
    this.apiService.postCall('asset-managements/asset-reports/'+this.assetId,{} , obj ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.reports = result;
      this.apiService.startLoader()
      this.apiService.postCall('asset-managements/asset-report-graph/'+this.assetId,{} , obj ).subscribe((result: any[])=>{
        // console.log(result);
        this.apiService.stopLoader()
        this.depreciation = result;

        this.depriciationList = result['depreciationYears']
        this.pieChartData = result['totalCostOnwerShip']
        this.singleBarPlot()
        this.getPieChart()
      
      })
    })

  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart2
  saveGraphImage1(imageName: any, url: any) {
    this.chart2 = url;
    this.getReportsDownload();
  }
  exportAsPDF1() {
    var HTML_Width = $("#divIdCharts1").width();
    var HTML_Height = $("#divIdCharts1").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts1")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage1('img2', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.exportAsPDF1();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  getReportsDownload() {
    this.apiService.startLoader()
    let obj = {
      download: true,
      assetName: this.assetDetails?.assetName,
      chart1:this.chart1,
      chart2:this.chart2,
      depreciationRate:this.depreciation?.depreciationRate,
      endofUsefulLife:this.depreciation?.endofUsefulLife,
      endofUsefulLifeInr:this.depreciation?.endofUsefulLifeInr,
      depreciationYears:this.depreciation?.depreciationYears,
      assetDepreciation:this.depreciation?.assetDepreciation,
      totalCostOnwerShip:this.depreciation?.totalCostOnwerShip,
      assetTicket:this.reports?.assetTicket,
      parts:this.reports?.parts,
      assetDowntime:this.reports?.assetDowntime,
      depressionRate:this.reports?.depressionRate
    }
    this.apiService.graphPostCall('asset-managements/assetReporsDownload', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }

  // exportAsPDF(div_id)
  // {
  //   document.getElementById("pdfButton1").style.opacity="0";
    
  //   let data = document.getElementById(div_id);
  //   // console.log(data.clientHeight)
  //   html2canvas(data, {
  //     width: 2480,
  //     height: data.clientHeight+100
  //   }).then((canvas) => {
  //     let imgWidth = 500;
  //       let pageHeight = 500;
  //       let imgHeight =
  //         ((canvas.height * imgWidth) / 2454)*1.24;
  //       var heightLeft = imgHeight;

  //       const imgData = canvas.toDataURL("image/png");

  //       const pdf = new jspdf({
  //         orientation: "p",
  //         unit: "mm",
  //         format: [400, 500],
  //       });

  //       let position = 10;
  //       pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         pdf.addPage();
  //         pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       pdf.save("Result.pdf");
  //       document.getElementById("pdfButton1").style.opacity="1";
  //     });


  // }

  downloadDiv:boolean;

  @HostListener('click', ['$event.target'])
onClick(btn) {
  // console.log('button', btn.id, 'number of clicks:');
  if (btn.id != 'custom') {
    this.downloadDiv = false
   
  }
}


singleBarPlot(){
  var data=[]
  if(this.depriciationList){
    for(let i=0; i< this.depriciationList.length ; i++)data.push([this.depriciationList[i].year,this.depriciationList[i].value])
  }
          
  this.chartDataLine = {
    type: 'Line',
    data: data,
    chartColumns: ['Year','Value'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'none' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 0: {pointShape: 'circle' } },
      curveType: 'function',
      pointSize: 20,
    },
    width: 500,
    height: 300
  };
}
singleBarPlot2(){

  // console.log(this.depriciationList)
    if(this.depriciationList){
  var data=[]
  // this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('YYYY-MM-DD HH:MM:SS'))
  
        for(let i=0; i< this.depriciationList.length ; i++)data.push({ ser2 : this.depriciationList[i].value , ser1:d3.timeParse("%Y")(this.depriciationList[i].year)})
// console.log(data)
  var margin = {top: 10, right: 30, bottom: 30, left: 50},
  width = (this.fixedIWdth*0.6),
  height = 400 - margin.top - margin.bottom;

 //  var parseDate = d3.time.format("%Y-%m-%d %X");
 var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;

 data.forEach(function(d) {
   //// console.log(d.date_time)
   // d.ser1 = moment(d.ser1).format('YYYY-MM-DD HH:MM:SS');

 });

 var svgAxis = d3.select("#chart2YAxis").append("svg")
.attr("width", 80)
.attr("height", height + margin.top + margin.bottom)
.append("g")
.attr("transform", 
    "translate(" + 80 + "," + margin.top + ")");

    var y = d3.scaleLinear()
    .domain([0, d3.max(data, function(d) { return +d.ser2; })])
    .range([ height, 0 ]);
    svgAxis.append("g")
.call(d3.axisLeft(y))
.attr("class","myYaxis");

var div = d3.select("body").append("div")
.attr("class", "tooltip")
.style("opacity", 0);



  var svg = d3.select("#chart2")
 .append("svg")
   .attr("width", width + margin.left + margin.right)
   .attr("height", (height + margin.top + margin.bottom + 100))
 .append("g")
   .attr("transform", "translate(" + 0 + "," + margin.top + ")");



// svg.append("text")
// .attr("class", "x label cart-title")
// .style("fill", "#5D5FEF")
// .attr("text-anchor", "end")
// .attr("x", width -50 )
// .attr("y", height + 30)
// .text("Time Chart");


svgAxis.append("text")
.attr("class", "y label cart-title")
.style("fill", "#33ccb2")
.attr("text-anchor", "end")
.attr("x", -86)
.attr("y", -76)
.attr("dy", ".75em")
.attr("transform", "rotate(-90)")
.text("Value");
svg.append("g")
// .attr("transform", "translate(0," + height + ")")
.attr("class","myXaxis")

var area = d3.area()
.curve(d3.curveBasis)
.x(function(d) { return x(d.ser1); })
.y0(height)
.y1(function(d) { return y(d.ser2); });
   
///////////////////////////////////////////////////////////
// Initialise a X axis:
var x = d3.scaleTime()
   .domain(d3.extent(data, function(d) { return d.ser1; }))
   .range([ 0, width ]);
   
 svg.append("g")
   .attr("transform", "translate(0," + height + ")")
   .call(d3.axisBottom(x))
.attr("class","myXaxis")
.selectAll("text")  
.style("text-anchor", "end")

.attr("transform", "rotate(-65)");

 // Add Y axis
 var y = d3.scaleLinear()
   .domain([0, d3.max(data, function(d) { return +d.ser2; })])
   .range([ height, 0 ]);
 svg.append("g")
   .call(d3.axisLeft(y))
   .attr("class","myYaxis");

/////////////////////////////////

//////////////////////////////////////////////////////

 // Add the line
 svg.append("path")
   .datum(data)
   .attr("fill", "none")
   .attr("stroke", "steelblue")
   .attr("stroke-width", 4.5)
   .attr("class","lineTest")
   .attr("d", d3.line()
   .curve(d3.curveBasis)
     .x(function(d) { return x(d.ser1) })
     .y(function(d) { return y(d.ser2) })
     )

     var dots=  svg.selectAll("dot")
     .data(data)
   .enter().append("circle")
     .attr("r", 5)
     .attr("fill", "#5D5FEF")
    //  .attr("transform", "translate(80,0)")
     .attr("cx", function(d) { return x(d.ser1); })
     .attr("cy", function(d) { return y(d.ser2); })
     .on("mouseover", function(d) {
       div.transition()
         .duration(200)
         .style("opacity", .9);
       div.html((moment(d.ser1).format('YYYY-MM-DD')) + "<br/>" + d.ser2)
         .style("left", (d3.event.pageX) + "px")
         .style("top", (d3.event.pageY - 28) + "px");
       })
     .on("mouseout", function(d) {
       div.transition()
         .duration(500)
         .style("opacity", 0);
       });


   svg.append("path")
   .data([data])
   .attr("class", "myArea")
   .attr("d", area);



//    d3.selectAll("g.myYaxis g.tick")
//  .append("line")
//  .attr("class", "gridline")
//  .attr("x1", 0)
//  .attr("y1", 0)
//  .attr("x2", width)
//  .attr("y2", 0);

// d3.selectAll("g.myXaxis g.tick")
//  .append("line")
//  .attr("class", "gridline")
//  .attr("x1", 0)
//  .attr("y1", -height)
//  .attr("x2", 0)
//  .attr("y2", 0);


    }
  }

  pieChartData:any;
  getTwoDecimal(data){
    return data.toFixed(2)
  }

  getPieChart(){
    let obj=this.pieChartData
    
    var legendRectSize = 18;
    var legendSpacing = 4;
    
    var data = [parseInt(obj['totalParts']), parseInt(obj['additionalCost']) , parseInt(obj['purchasePrice'])];
    var data = [parseInt(this.reports?.parts.partCost), parseInt(obj['additionalCost']) , parseInt(obj['purchasePrice'])];

    // console.log(data)
    var sum =0
    for(let d of data ) sum = sum + d

    var width = 660,
      height = 300,
      radius = Math.min(width, height) / 2;

    var color = d3.scaleOrdinal()
      .range(["#33CCB2",  "#a9d6cf" , "#04ac92"]);

    var arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(0);

    var labelArc = d3.arc()
      .outerRadius(radius - 40)
      .innerRadius(radius - 40);

    var pie = d3.pie()
      .sort(null)
      .value(function(d) { return d; });

                                  // NEW

    var svg = d3.select("#piechart").append("svg")
      .attr("width", width)
      .attr("height", height)
    .append("g")
      .attr("transform", "translate(" + 180 + "," + height / 2 + ")")
      


    var g = svg.selectAll(".arc")
        .data(pie(data))
      .enter().append("g")
        .attr("class", "arc");

    g.append("path")
        .attr("d", arc)
        .style("fill", function(d) { return color(d.data); })
        .on("mouseover", function(){return tooltip.style("visibility", "visible");})
      .on("mousemove", function(d){
        // console.log(d ,sum)
        return tooltip.style("top", (d3.event.pageY - 1500)+"px").style("left",(d3.event.pageX -200)+"px") .text(((d.data / sum) * 100).toFixed(2) + '%');})
      .on("mouseout", function(){return tooltip.style("visibility", "hidden");});

        var path = svg.selectAll('path')
        .data(pie(data))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', function(d, i) { 
          return color(d.data.label); 
        })
        
        
        var tooltip = d3.select("#piechart")
        .append("div")
          .style("position", "absolute")
          .style("visibility", "hidden");
          

            
            // svg.select("#circleBasicTooltip")
           
            
          // var legend = svg.selectAll('.legend')
          //   .data(color.domain())
          //   .enter()
          //   .append('g')
          //   .attr('class', 'legend')
          //   .attr('transform', function(d, i) {
          //     var height = legendRectSize + legendSpacing;
          //     var offset =  height * color.domain().length / 2;
          //     var horz = -2 * legendRectSize;
          //     var vert = i * height - offset;
          //     return 'translate(' + horz + ',' + vert + ')';
          //   });

          // legend.append('rect')
          //   .attr('width', legendRectSize)
          //   .attr('height', legendRectSize)                                   
          //   .style('fill', color)
          //   .style('stroke', color);
            
          // legend.append('text')
          //   .attr('x', legendRectSize + legendSpacing)
          //   .attr('y', legendRectSize - legendSpacing)
          //   .text(function(d) { return d; });

       
    
      // g.append("text")
      //     .attr("transform", function(d) { return "translate(" + labelArc.centroid(d) + ")"; })
      //     .attr("dy", ".35em")
          // .text(function(d) { return d.data; })
          
          ;
    }  


    screenWidth:any
    fixedIWdth:any=1200;
    marginLeft:any
    @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth= event.target.innerWidth;

    if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#chart2YAxis > *").remove();
    d3.selectAll("#chart2 > *").remove();

    this.singleBarPlot()
  }
}
