import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../common/services/apiCalls.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { StoredData } from '../../common/services/storedData.service';

@Component({
  selector: 'app-parts-notify',
  templateUrl: './parts-notify.component.html',
  styleUrls: ['./parts-notify.component.css']
})
export class PartsNotifyComponent implements OnInit {

  constructor(public router: Router, public apiService: ApiService, private fb: FormBuilder, public _location: Location, public storedData: StoredData) { }

  partsId: any;
  partsDetails: any
  partsStatus=[]
  vendors=[]
  partsVendorName=''
  notifyAdd:FormGroup
  saveNotify=false

  ngOnInit(): void {
    this.notifyAdd = this.fb.group({
      businessId: this.apiService.buisnessId,
      notifyUser: [null,Validators.required],
      authId: this.apiService.authId
    });
    this.apiService.startLoader()
    this.partsId = this.router.url.split("/")[2]
    this.apiService.getCall('parts/showDetails/' + this.partsId + "/" + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.partsDetails = result['part']
      this.partsStatus = result['statusList']
      let idx2 = this.partsStatus.findIndex(x=>x.name== "Breakdown")
      this.partsStatus.splice(idx2,1)
      
      this.vendors = this.partsDetails.vendors;
      if(this.partsDetails.vendors && this.partsDetails.vendors.length > 0){
        let vendors = this.partsDetails.vendors;
        if(this.partsDetails.vendors.length > 1)this.partsVendorName = "Multiple"
        else this.partsVendorName = this.partsDetails.vendors[0].name
      }

      this.apiService.stopLoader();
      this.getUserList();

      let permission = [];
      permission = JSON.parse(localStorage.getItem('permissions'));
      permission = permission.filter(item => item['permissionModuleId'] ==5 );
      // console.log(permission);
      for(var i=0;i<permission.length;i++){
        if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Update Notify" && permission[i]['actionStatus'] == 1){
          this.saveNotify=true
        }
      }

    })

  }

  getDisabledUser(id){
    if(this.userArry[0]){
      let idx = this.userArry.findIndex(x=> x.id == id)
      if(idx != -1) return true
      else return false
      }
  }



  getAssetDetail(){
    this.ngOnInit()
  }


  openQR() {
    this.storedData.qrData = { name: this.partsDetails.partName, image: this.partsDetails.partQrCode, customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }

  uploadedFiles: any
  fileName: any;
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name
    }
    let testData: FormData = new FormData();

    testData.append('image', this.uploadedFiles);
    testData.append("partId", this.partsDetails.partId);
    testData.append("authId", this.apiService.authId);
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.ngOnInit();
      document.getElementById('dismissPopUp').click()

    })

  }

  message
  changeStatus() {
    let testData: FormData = new FormData();
    testData.append("status", this.partsDetails.status);
    testData.append("authId", this.apiService.authId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/changeStatus/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      document.getElementById('dismissPopUp').click()

    })
  }
  closeAll() {
    (<HTMLInputElement>document.getElementById('closePopUp')).click();
  }


  // addUser(id) {
  //   if (id.target.value != "Select User") {
  //     this.submit(id.target.value);
  //   }
  // }

  userArry=[]
  notifyFlag
  removeUser(i){
    this.notifyFlag = 0;
    this.userArry.splice(i,1)
    this.submit()
  }
  selectUser(d){
    this.notifyFlag = 1;
    // // console.log(d.target.value)
    this.userArry.push(this.notifyAdd.value.notifyUser)
    this.notifyAdd.patchValue({notifyUser : null})
    this.submit()
  }

  submit() {
    if(this.saveNotify==false){
      return;
    }
    if(this.userArry.length <0){
      return;
    }
    let userId=[]
    for(let d =0;d<this.userArry.length;d++){
      userId.push(this.userArry[d].id);
    }
    let obj = {
      //businessId: this.apiService.buisnessId,
      notifyUser: userId,
      authId: this.apiService.authId
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if(this.notifyFlag == 1){
        this.message = 'User added to the notification list.';
      }else if(this.notifyFlag == 0){
        this.message = 'User removed from the notification list.';
      }else{
        this.message = result["msg"];
      }
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })

  }











  

  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]
  conditionListing = [{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]


  goToLink(url: string) {
    window.open(url, "_blank");
  }
  getFileName(name) {
    if (name) {
      return name.split("/")[5];
    }
  }

  getFileNameWarranty(name) {
    if (name && name.split("/")[6]) return name.split("/")[6];
  }
  amcType: any;

  getAmcText(id) {
    if (this.amcType && id) return this.amcType.find(x => x.id == id).name
  }


  userList
  getUserList(): void {
    this.apiService.startLoader()
    this.apiService.startLoader()
    this.apiService.getCall('users/user-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.userList = result['data'];
      if(this.partsDetails.notifyUser && this.partsDetails.notifyUser.length >0){
        let nameArr = [];
        nameArr = this.partsDetails.notifyUser.split(',');
        for(let d=0;d<nameArr.length;d++){
          var name ="";
          this.userList.filter((item) => {
            if (item.id == nameArr[d]) {
              name= item.fullName;
            }
          })
          // console.log(name)
          this.userArry.push({id:nameArr[d],fullName:name})
          // console.log(this.userArry)
        }
      }
      // console.log("List of Users", this.userList);

    })

  }

  getUserName(id) {
    let idx = this.userList.findIndex(x => x.id == id)

    if (idx != -1) return this.userList[idx].fullName
    else return ''
  }
  showLoader = true
  updateData() {
    this.ngOnInit()
  }

  getStatus(id) {

    if (id) {
      let idx = this.statusList.findIndex(x => x.id == id)
      if (idx != -1) return this.statusList[idx].name
      else return "Lost"

    }
  }

  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      var jsonToBeUsed = [];




      // console.log(jsonToBeUsed)
      this.locationList = result['data']


      this.storedData.locationList = this.locationList;

    })
  }





  // upload() {


  //   let testData: FormData = new FormData();
  //   testData.append("file", this.uploadedFiles)
  //   this.apiService.startLoader()
  //   this.apiService.uploadCall('amcs/edit/' + this.asseAmcDetails.id, {}, testData).subscribe((result: any[]) => {
  //     // console.log(result);
  //     this.apiService.stopLoader()

  //     this.message = result["msg"];
  //     this.uploadedFiles = undefined
  //     this.fileName = undefined

  //     // (<HTMLInputElement>document.getElementById('openSuccess')).click();

  //   })
  // }

  //uploadedFilesAsset

  // fileChangeAsset(event) {
  //   // console.log(event)
  //   let fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFilesAsset = file



  //   }

  //   let testData: FormData = new FormData();


  //   // // console.log(obj)
  //   testData.append('image', this.uploadedFilesAsset);
  //   testData.append("assetName", this.assetDetails.assetName);
  //   testData.append("make", this.assetDetails.make);
  //   testData.append("specRating", this.assetDetails.specRating);
  //   testData.append("srNo", this.assetDetails.srNo);
  //   testData.append("locationId", this.assetDetails.locationId);
  //   testData.append("purchaseDate", this.assetDetails.purchaseDate);
  //   testData.append("placeInService", this.assetDetails.placeInService);
  //   testData.append("propertyId", this.assetDetails.propertyId);
  //   testData.append("businessId", this.assetDetails.businessId);
  //   testData.append("model", this.assetDetails.model);
  //   testData.append("specification", this.assetDetails.specification);
  //   testData.append("isOperational", this.assetDetails.isOperational);
  //   testData.append("renewalDate", this.assetDetails.renewalDate);
  //   testData.append("warrantyExpiration", this.assetDetails.warrantyExpiration);
  //   testData.append("authId", "1");
  //   testData.append("vendorName", this.assetDetails.vendorName);
  //   testData.append("categoryName", this.assetDetails.categoryName);
  //   testData.append("conditions", this.assetDetails.conditions);
  //   testData.append("slaExpiryDate", this.assetDetails.slaExpiryDate);
  //   testData.append("remark", this.assetDetails.remark);
  //   testData.append("ownership", this.assetDetails.ownership);
  //   testData.append("assetTagNo", this.assetDetails.assetTagNo);
  //   testData.append("status", this.assetDetails.status);
  //   // status:this.assetDetailsForm.value.status,



  //   // // console.log(obj)
  //   this.apiService.startLoader()
  //   this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
  //     // console.log(result);
  //     this.apiService.stopLoader()
  //     this.getAsset()
  //     document.getElementById('dismissPopUp').click()

  //   })
  // }

  fileChangeWarranty(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }

  



}

