import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from '../../../common/services/apiCalls.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-property-cities-add',
  templateUrl: './property-cities-add.component.html',
  styleUrls: ['./property-cities-add.component.css']
})
export class PropertyCitiesAddComponent implements OnInit {
  shide = false;
  hide = false;
  form: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  zoneDropdownSettings?: IDropdownSettings;
  stateDropdownSettings?: IDropdownSettings;
  businessId = 0;
  statusList = [];
  zoneList = [];
  stateList = [];
  clusterList = [];
  routeId;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  zoneId=null
  stateId=null
  clusterId=null
  districtId=null
  ngOnInit(): void {
    this.statusList = [{id: 0, name: 'InActive'}, {id: 1, name: 'Active'}];
    document.body.style.overflow = 'hidden';
    this.form = this.fb.group({
      businessId: [null],
      zoneId: [null, Validators.required],
      stateId: [null, Validators.required],
      clusterId:[null, Validators.required],
      districtId:[null, Validators.required],
      name: [null, Validators.required],
      isActive: [1],
      authId: [null],
    });
    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };
    this.zoneDropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'title',
      allowSearchFilter: true
    };
    this.stateDropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'title',
      allowSearchFilter: true
    };

    this.getZoneList();

    // if (this.apiService.outletId) {
    //   this.onItemSelect({id: this.apiService.outletId});
    // }

  }

  goBack(): void {
    this._location.back();
  }


  save(): void {
    if(!this.routeId){
      this.form.value.authId = this.apiService.authId;
      this.form.value.businessId = this.apiService.buisnessId;
      let formData = this.form.value;
      this.apiService.startLoader();
      this.apiService.postCall('cities/add', {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });

    }else{
      this.form.value.authId = this.apiService.authId;
      this.form.value.businessId = this.apiService.buisnessId;
      let formData = this.form.value;
      this.apiService.startLoader();
      this.apiService.postCall('cities/edit/'+this.routeId, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    }

  }

  getValidation() {
    return this.form.valid;
  }

  getDetailsById() {
    if (this.routeId) {
      this.apiService.startLoader();
      this.apiService.postCall('cities/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {
        var da =result['data'];
        this.apiService.stopLoader();

        this.form.patchValue({businessId:da['businessId'],
        zoneId: da['zoneId'],
        stateId: da['stateId'],
        clusterId:da['clusterId'],
        name: da['title'],
        districtId:da['districtId'],
        isActive: da['isActive'],
        authId: this.apiService.authId});

        this.zoneId=da['zoneId'];
        this.stateId=da['stateId'];
        this.clusterId=da['clusterId'];
        this.districtId=da['districtId'];
        //var find = this.zoneList.filter(dat => dat.id == result['data'].zoneId);

        //var findStatus = this.statusList.filter(dat => dat.id == result['data'].isActive);
        //this.form.patchValue({name: result['data'].title, zoneId: find, isActive: findStatus});
        this.getStateList();
      });
    }
  }

  getZoneList() {
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('zones/getZonesPluck', {}, body).subscribe((result: any[]) => {
      this.zoneList = result['data'];
      this.getDetailsById();
      this.apiService.stopLoader();

    });
  }

  getStateList(item?) {
    // console.log(item);
    if(this.zoneId){
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      zoneId: this.zoneId,
    };
    this.apiService.postCall('states/getStatesPluck', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.stateList = result['data'];
      // if (this.routeId) {
      //   var find = this.stateList.filter(dat => dat.id == item.stateId);
      //   this.form.patchValue({stateId: find});
      // }
      if(this.zoneId && this.stateId){
        this.getclusterList();
      }
      this.apiService.stopLoader();

    });
  }
  }

  getclusterList(item?) {
    // console.log(item);
    if(this.zoneId && this.stateId){
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      zoneId: this.zoneId,
      stateId: this.stateId
    };
    this.apiService.postCall('clusters/getClustersPluck', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.clusterList = result['data'];
      if (this.routeId) {
        // var find = this.stateList.filter(dat => dat.id == item.stateId);
        // this.form.patchValue({stateId: find});
      }
      if(this.zoneId && this.stateId && this.clusterId){
        this.getDistrict();
      }
      this.apiService.stopLoader();

    });
  }
  }

  districtList=[]
  getDistrict(item?) {
    // console.log(item);
    if(this.zoneId && this.stateId && this.clusterId){
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      zoneId: this.zoneId,
      stateId: this.stateId,
      clusterId: this.clusterId
    };
    this.apiService.postCall('districts/getDistrictsPluck', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.districtList = result['data'];
      if (this.routeId) {
        // var find = this.stateList.filter(dat => dat.id == item.stateId);
        // this.form.patchValue({stateId: find});
      }
      this.apiService.stopLoader();

    });
  }
  }

  backClicked() {
    this.router.navigate(['/master-property'], {queryParams: {filter: 'cities'}});
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
