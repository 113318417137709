import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from '../../common/services/apiCalls.service';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lost-and-found-lost-article-add',
  templateUrl: './lost-article-add.component.html',
  styleUrls: ['./lost-article-add.component.css'],
})
export class LostArticleAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  businessId = this.apiService.buisnessId;
  routeId;
  date;
  time;
  detailsArticleLost;
  personFillingComplaint;
  locationArticleLost;
  addressComplaint;
  mobileNumber;
  contactNumber;
  todayDate: any = new Date();
  remark = ''
  articleFoundFlag
  foundArticleNo = ''
  dateArticle
  sGReceived = ''
  ceeReceived = ''
  complaint = ''
  userId
  foundArticleNoName
  claimedDate
  claimedTime
  nameDepositingPerson
  areaArticleFound
  nameAddressClaimant
  articleInSecurity
  articleInOperation
  handoverNameOfReceiver
  handoverContactNumber
  handoverIdProof
  handoverIdProofValue
  handoverAddressOfReceiver
  handoverSignature
  signatureFile;
  signatureFileUrl;
  signatureBoolean = false;
  @ViewChild('canvas') public canvas: ElementRef;
  private cx: CanvasRenderingContext2D;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }
  onItemSelect(event) {
    var t = event.target.value
    this.onItemSelect2(t);
  }
  onItemSelect2(id) {
    this.apiService.startLoader();
    this.apiService.getCall('LostFounds/view/' + id, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      this.claimedDate = result['data'].claimedDate;
      this.claimedTime = result['data'].claimedTime;
      this.nameDepositingPerson = result['data'].nameDepositingPerson;
      this.areaArticleFound = result['data'].areaArticleFound;
      this.nameAddressClaimant = result['data'].nameAddressClaimant;
      this.articleInSecurity = result['data'].articleInSecurity;
      this.articleInOperation = result['data'].articleInOperation;
    });
  }
  foundArticleNoList = [];
  userListSecurity = [];
  userListOperation = [];
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId
    }
    this.apiService.postCall('users/user-list-roles/' + this.apiService.buisnessId + '/' + 0, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.foundArticleNoList = result['data']['foundArticleNoList'];
      this.userListSecurity = result['data']['userListSecurity'];
      this.userListOperation = result['data']['userListOperation'];
    })
    this.getTicektDetails();
    if (!this.routeId) {
      setTimeout(() => {
        this.handOverProcessTo();
      }, 500);
    }
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    // set the width and height
    // canvasEl.width = this.width;
    // canvasEl.height = this.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }

  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const date = new Date();
    document.body.style.overflow = 'hidden';
    if (!this.routeId) {
      this.tenantForm = this.fb.group({
        date: [date, [Validators.required]],
        time: [null, [Validators.required]],
        detailsArticleLost: [null, [Validators.required]],
        personFillingComplaint: [null, [Validators.required]],
        locationArticleLost: [null, [Validators.required]],
        addressComplaint: [null, [Validators.required]],
        mobileNumber: [null, [Validators.required]],
        contactNumber: [null],
        remark: [null],
        articleFoundFlag: [null],
        foundArticleNo: [null],
        dateArticle: [date],
        sGReceived: [null],
        ceeReceived: [null],
        complaint: [null],
        businessId: [null],
        userId: [null],
        handoverNameOfReceiver: [null],
        handoverContactNumber: [null],
        handoverIdProof: [null],
        handoverIdProofValue: [null],
        handoverIdProofValueAttach: [null],
        handoverAddressOfReceiver: [null],
        handoverSignature: [null],
        articleInHandhoverFlag: [0],
        claimedDate: [date],
        claimedTime: [null],
        detailsArticleFound: [null],
        nameDepositingPerson: [null],
        areaArticleFound: [null],
        //nameAddressClaimant: [null, [Validators.required]],
        articleInSecurity: [null],
        articleInOperation: [null]
      });
    } else {
      this.tenantForm = this.fb.group({
        date: [date, [Validators.required]],
        time: [null, [Validators.required]],
        detailsArticleLost: [null, [Validators.required]],
        personFillingComplaint: [null, [Validators.required]],
        locationArticleLost: [null, [Validators.required]],
        addressComplaint: [null, [Validators.required]],
        mobileNumber: [null, [Validators.required]],
        contactNumber: [null],
        remark: [null],
        articleFoundFlag: [null],
        foundArticleNo: [null],
        dateArticle: [date],
        sGReceived: [null],
        ceeReceived: [null],
        complaint: [null],
        businessId: [null],
        userId: [null],
        handoverNameOfReceiver: [null],
        handoverContactNumber: [null],
        handoverIdProof: [null],
        handoverIdProofValue: [null],
        handoverIdProofValueAttach: [null],
        handoverAddressOfReceiver: [null],
        handoverSignature: [null],
        articleInHandhoverFlag: [0],
        claimedDate: [date],
        claimedTime: [null],
        detailsArticleFound: [null],
        nameDepositingPerson: [null],
        areaArticleFound: [null],
        //nameAddressClaimant: [null, [Validators.required]],
        articleInSecurity: [null],
        articleInOperation: [null]
      });
    }
    this.getUserList();
  }
  fileName
  uploadedFiles
  msg = null
  fileChange(event) {
    this.msg = null
    var succ = false
    // console.log(event.target.files[0].type)
    var ext = event.target.files[0].name.split('.').pop();
    if (ext == "jpg" || ext == "gif" || ext == "jpeg" || ext == "png") {
      var span = document.getElementById('images');
      var img = document.createElement('img');
      img.src = URL.createObjectURL(event.target.files[0]);
      img.id = "firstId";
      if (document.getElementById('firstId')) {
        document.getElementById('firstId').remove();
      }
      span.appendChild(img);
      // span.removeChild(img);
      img.style.height = "88px"
      // console.log(event)
      succ = true;
    } else {
      this.msg = "Please select Image format only";
    }
    if (succ) {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        file = file
        this.uploadedFiles = file;
        this.fileName = file.name
      }
    }
  }
  removeImage() {
    this.fileName = null;
    this.uploadedFiles = [];
    if (document.getElementById('firstId')) {
      document.getElementById('firstId').remove();
    }
  }
  lostArticleDetails
  getTicektDetails() {
    if (this.routeId) {
      this.apiService.startLoader()
      this.apiService.getCall('LostArticleComplaints/view/' + this.routeId, {}).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.lostArticleDetails = result['data'];
        let reminder = new Date()
        if (this.lostArticleDetails.time) {
          reminder.setHours(this.lostArticleDetails.time.split(':')[0])
          reminder.setMinutes(this.lostArticleDetails.time.split(':')[1])
          reminder.setSeconds(0)
        }
        if (this.lostArticleDetails.handoverIdProofValueAttach) {
          this.fileName = this.lostArticleDetails.handoverIdProofValueAttach;
        }
        this.tenantForm.patchValue({
          businessId: this.businessId,
          date: this.lostArticleDetails.date ? new Date(this.lostArticleDetails.dateEdit) : null,
          time: reminder,
          detailsArticleLost: this.lostArticleDetails.detailsArticleLost,
          personFillingComplaint: this.lostArticleDetails.personFillingComplaint,
          locationArticleLost: this.lostArticleDetails.locationArticleLost,
          addressComplaint: this.lostArticleDetails.addressComplaint,
          mobileNumber: this.lostArticleDetails.mobileNumber,
          contactNumber: this.lostArticleDetails.contactNumber,
          remark: this.lostArticleDetails.remark != null ? this.lostArticleDetails.remark : '',
          articleFoundFlag: this.lostArticleDetails.articleFoundFlag,
          foundArticleNo: this.lostArticleDetails.foundArticleNo,
          sGReceived: this.lostArticleDetails.sGReceived,
          ceeReceived: this.lostArticleDetails.ceeReceived,
          complaint: this.lostArticleDetails.complaint,
          handoverNameOfReceiver: this.lostArticleDetails.handoverNameOfReceiver,
          handoverContactNumber: this.lostArticleDetails.handoverContactNumber,
          handoverIdProof: this.lostArticleDetails.handoverIdProof,
          handoverIdProofValue: this.lostArticleDetails.handoverIdProofValue,
          handoverAddressOfReceiver: this.lostArticleDetails.handoverAddressOfReceiver
        });
        setTimeout(() => {
          this.handOverProcessTo();
        }, 500);
        this.articleFoundFlag = this.lostArticleDetails.articleFoundFlag;
        if(this.lostArticleDetails.foundArticleNoName){
          this.foundArticleNoName = this.lostArticleDetails.foundArticleNoName;
        }
        this.claimedDate = this.lostArticleDetails.claimedDate;
        this.claimedTime = this.lostArticleDetails.claimedTime;
        this.nameDepositingPerson = this.lostArticleDetails.nameDepositingPerson;
        this.areaArticleFound = this.lostArticleDetails.areaArticleFound;
        this.nameAddressClaimant = this.lostArticleDetails.nameAddressClaimant;
        this.articleInSecurity = this.lostArticleDetails.articleInSecurity;
        this.articleInOperation = this.lostArticleDetails.articleInOperation;
        this.handoverSignature = this.lostArticleDetails.handoverSignature;
      })
    }
  }
  handOverProcessTo() {
    if (this.tenantForm.value.date && this.tenantForm.value.time && this.tenantForm.value.detailsArticleLost && this.tenantForm.value.personFillingComplaint && this.tenantForm.value.locationArticleLost && this.tenantForm.value.addressComplaint && this.tenantForm.value.mobileNumber) {
      $('#handoverprocess').css('display', 'block');
    } else {
      $('#handoverprocess').css('display', 'none');
    }
  }
  goBack(): void {
    this._location.back();
  }
  getDoneSignature() {
    const imageURL = this.canvas.nativeElement.toDataURL();
    // Convert canvas image to Base64
    this.signatureFile = this.dataURItoBlob(imageURL);
    this.signatureFileUrl = imageURL;
    this.signatureBoolean = true;
  }
  clearSignature() {
    this.cx.clearRect(0, 0, 300, 100);

  }
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number, y: number },
    currentPos: { x: number, y: number }
  ) {
    // incase the context is not set
    if (!this.cx) {
      return;
    }

    // start our drawing path
    this.cx.beginPath();

    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from

      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);

      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  }
  save(): void {
    let time = new Date(this.tenantForm.value.time)
    time.setHours(new Date(this.tenantForm.value.time).getHours())
    time.setMinutes(new Date(this.tenantForm.value.time).getMinutes())
    time.setSeconds(new Date(this.tenantForm.value.time).getSeconds())
    if (!this.routeId) {
      var form = new FormData();
      form.append('date', moment(this.tenantForm.value.date).format('YYYY-MM-DD'));
      form.append('time', moment(this.tenantForm.value.time).format('HH:mm:ss'));
      form.append('detailsArticleLost', this.tenantForm.value.detailsArticleLost);
      form.append('personFillingComplaint', this.tenantForm.value.personFillingComplaint);
      form.append('locationArticleLost', this.tenantForm.value.locationArticleLost ? this.tenantForm.value.locationArticleLost : '');
      form.append('addressComplaint', this.tenantForm.value.addressComplaint);
      form.append('mobileNumber', this.tenantForm.value.mobileNumber);
      form.append('contactNumber', this.tenantForm.value.contactNumber);
      form.append('remark', this.tenantForm.value.remark);
      //form.append('articleFoundFlag', 'Yes');
      form.append('articleInHandhoverFlag', this.tenantForm.value.articleInHandhoverFlag);

      form.append('claimedDate', moment(this.tenantForm.value.claimedDate).format('YYYY-MM-DD'));
      form.append('claimedTime', moment(this.tenantForm.value.claimedTime).format('HH:mm:ss'));
      form.append('detailsArticleFound', this.tenantForm.value.detailsArticleFound);
      form.append('nameDepositingPerson', this.tenantForm.value.nameDepositingPerson);
      form.append('areaArticleFound', this.tenantForm.value.areaArticleFound ? this.tenantForm.value.areaArticleFound : '');
      //form.append('nameAddressClaimant', this.tenantForm.value.nameAddressClaimant);
      form.append('articleInSecurity', this.tenantForm.value.articleInSecurity);
      form.append('articleInOperation', this.tenantForm.value.articleInOperation);

      form.append('foundArticleNo', this.tenantForm.value.foundArticleNo);
      form.append('dateArticle', this.tenantForm.value.dateArticle ? moment(this.tenantForm.value.dateArticle).format('YYYY-MM-DD') : '');
      form.append('sGReceived', this.tenantForm.value.sGReceived ? this.tenantForm.value.sGReceived : '');
      form.append('ceeReceived', this.tenantForm.value.ceeReceived ? this.tenantForm.value.ceeReceived : '');
      form.append('complaint', this.tenantForm.value.complaint ? this.tenantForm.value.complaint : '');
      form.append('businessId', this.businessId);
      form.append('userId', this.apiService.authId);
      form.append('handoverNameOfReceiver', this.tenantForm.value.handoverNameOfReceiver ? this.tenantForm.value.handoverNameOfReceiver : '');
      form.append('handoverContactNumber', this.tenantForm.value.handoverContactNumber ? this.tenantForm.value.handoverContactNumber : '');
      form.append('handoverIdProof', this.tenantForm.value.handoverIdProof ? this.tenantForm.value.handoverIdProof : '');
      form.append('handoverIdProofValue', this.tenantForm.value.handoverIdProofValue ? this.tenantForm.value.handoverIdProofValue : '');
      form.append('handoverAddressOfReceiver', this.tenantForm.value.handoverAddressOfReceiver ? this.tenantForm.value.handoverAddressOfReceiver : '');
      if (this.uploadedFiles) {
        form.append('handoverIdProofValueAttach', this.uploadedFiles ? this.uploadedFiles : '');
      } else {
        form.append('handoverIdProofValueAttach', '');
      }
      form.append('handoverSignature', this.signatureFileUrl ? this.signatureFileUrl : '');
      this.apiService.startLoader();
      this.apiService.postCall('LostArticleComplaints/add', {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        if (result['success']) {
          this.backClicked();
        }
      }, error => {
        this.apiService.stopLoader();
      });
    } else {
      var form = new FormData();
      form.append('date', moment(this.tenantForm.value.date).format('YYYY-MM-DD'));
      form.append('time', moment(this.tenantForm.value.time).format('HH:mm:ss'));
      form.append('detailsArticleLost', this.tenantForm.value.detailsArticleLost);
      form.append('personFillingComplaint', this.tenantForm.value.personFillingComplaint);
      form.append('locationArticleLost', this.tenantForm.value.locationArticleLost ? this.tenantForm.value.locationArticleLost : '');
      form.append('addressComplaint', this.tenantForm.value.addressComplaint);
      form.append('mobileNumber', this.tenantForm.value.mobileNumber);
      form.append('contactNumber', this.tenantForm.value.contactNumber);
      form.append('remark', this.tenantForm.value.remark);
      //form.append('articleFoundFlag', 'Yes');
      form.append('foundArticleNo', this.tenantForm.value.foundArticleNo ? this.tenantForm.value.foundArticleNo : '');
      form.append('dateArticle', this.tenantForm.value.dateArticle ? moment(this.tenantForm.value.dateArticle).format('YYYY-MM-DD') : '');
      form.append('sGReceived', this.tenantForm.value.sGReceived ? this.tenantForm.value.sGReceived : '');
      form.append('ceeReceived', this.tenantForm.value.ceeReceived ? this.tenantForm.value.ceeReceived : '');
      form.append('complaint', this.tenantForm.value.complaint ? this.tenantForm.value.complaint : '');
      form.append('businessId', this.businessId);
      form.append('userId', this.apiService.authId);
      form.append('handoverNameOfReceiver', this.tenantForm.value.handoverNameOfReceiver ? this.tenantForm.value.handoverNameOfReceiver : '');
      form.append('handoverContactNumber', this.tenantForm.value.handoverContactNumber ? this.tenantForm.value.handoverContactNumber : '');
      form.append('handoverIdProof', this.tenantForm.value.handoverIdProof ? this.tenantForm.value.handoverIdProof : '');
      form.append('handoverIdProofValue', this.tenantForm.value.handoverIdProofValue ? this.tenantForm.value.handoverIdProofValue : '');
      if (this.uploadedFiles) {
        form.append('handoverIdProofValueAttach', this.uploadedFiles ? this.uploadedFiles : '');
      } else {
        form.append('handoverIdProofValueAttach', '');
      }
      form.append('handoverAddressOfReceiver', this.tenantForm.value.handoverAddressOfReceiver ? this.tenantForm.value.handoverAddressOfReceiver : '');
      form.append('handoverSignature', this.signatureFileUrl ? this.signatureFileUrl : '');
      this.apiService.startLoader();
      this.apiService.postCall('/LostArticleComplaints/edit/' + this.routeId, {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        if (result['success']) {
          this.backClicked();
        }
      }, error => {
        this.apiService.stopLoader();
      });
    }
  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  getValidation() {
    setTimeout(() => {
      this.handOverProcessTo();
    }, 500);
    if ((this.tenantForm.value.articleInHandhoverFlag == 1) && (this.tenantForm.value.claimedDate == null || this.tenantForm.value.claimedTime == null || this.tenantForm.value.detailsArticleFound == null || this.tenantForm.value.nameDepositingPerson == null || this.tenantForm.value.areaArticleFound == null || this.tenantForm.value.articleInSecurity == null || this.tenantForm.value.articleInOperation == null)) {
      //return this.tenantForm.valid;
      return false;
    } else {
      return this.tenantForm.valid;
    }
  }

  backClicked() {
    this.router.navigate(['/lost-article-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
