//import { Component, HostListener, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { NgModule, Component, OnInit, HostListener, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
imports: [NgSwitch, NgSwitchCase]
import * as moment from 'moment';
//import { addMonths, startOfMonth, endOfMonth, eachDayOfInterval, format, isSameDay } from 'date-fns';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  subMonths,
  addMonths
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#3cceb5',
    secondary: '#3cceb5',
  },
  blue: {
    primary: '#3cceb5',
    secondary: '#3cceb5',
  },
  yellow: {
    primary: '#3cceb5',
    secondary: '#3cceb5',
  },
};
interface Event {
  date: Date;
  title: string;
}
@Component({
  selector: 'app-pm-view-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pm-view-calendar.component.html',
  styleUrls: ['./pm-view-calendar.component.css'],
})
export class PmViewCalendarComponent implements OnInit {
  currentMonth: Date = new Date();
  constructor(private fb: FormBuilder, public router: Router, public _location: Location, public apiService: ApiService, private modal: NgbModal, private cd: ChangeDetectorRef) { }
  events: CalendarEvent[] = [];
  pmYearScheduleForm = this.fb.group({
    //businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    scheduleDate: [null, Validators.required]
  });
  minDate = new Date();
  propertFilter = false;
  filterSelect = false;
  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];
  propertyList = []
  filterpropertyList = []
  filterPropertySearch
  applyFlag
  selectedFiler = { property: null }
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
    }
  }
  ngOnInit(): void {
    this.getPropertyList();
    this.getDynamicEvents();
    // this.events = this.getDynamicEvents();
    // this.refresh
  }
  applyFilter() {
    localStorage.removeItem('LocalPmCalendorFilter');
    localStorage.setItem("LocalPmCalendorFilter", null);
    this.applyFlag = true;
    this.getDynamicEvents();
  }
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  clear() {
    localStorage.removeItem('LocalPmCalendorFilter');
    localStorage.setItem("LocalPmCalendorFilter", null);
    this.applyFlag = false;
    this.selectedFiler = { property: null };
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    if (idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.getPropertyList()
      }
      else {
        this.selectedFiler.property = this.propertyList[idx1]
      }
    }
    this.getDynamicEvents();
  }
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    })
  }
  nextMonth(): void {
    this.currentMonth = this.viewDate;
    this.ngOnInit();
    // if (this.view === CalendarView.Month) {
    //   this.currentMonth = addMonths(this.currentMonth, 1);
    //   this.ngOnInit();
    // }
  }
  todaysMonth(): void {
    this.currentMonth = new Date();
    this.ngOnInit();
  }
  previousMonth(): void {
    this.currentMonth = this.viewDate;
    this.ngOnInit();
    // if (this.view === CalendarView.Month) {
    //   this.currentMonth = subMonths(this.currentMonth, 1);
    //   this.ngOnInit();
    // }
  }
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  // actions: CalendarEventAction[] = [
  //   {
  //     label: '<i class="fas fa-fw fa-pencil-alt"></i>',
  //     a11yLabel: 'Edit',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.handleEvent('Edited', event);
  //     },
  //   },
  //   {
  //     label: '<i class="fas fa-fw fa-trash-alt"></i>',
  //     a11yLabel: 'Delete',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.events = this.events.filter((iEvent) => iEvent !== event);
  //       this.handleEvent('Deleted', event);
  //     },
  //   },
  // ];

  refresh = new Subject<void>();
  //events: CalendarEvent[] = [];
  pmSchedulesData
  getDynamicEvents() {
    let arrayEvent = []
    let obj = {}
    if (localStorage.getItem("LocalPmCalendorFilter") && localStorage.getItem("LocalPmCalendorFilter") != null && localStorage.getItem("LocalPmCalendorFilter") != 'null') {
      let LocalPmCalendorFilter = JSON.parse(localStorage.getItem("LocalPmCalendorFilter"));
      obj = LocalPmCalendorFilter;
      if (LocalPmCalendorFilter.propertyId && LocalPmCalendorFilter.propertyId != null && LocalPmCalendorFilter.propertyId != 'null') {
        this.selectedFiler.property ? LocalPmCalendorFilter.propertyId : null
      }
      if (LocalPmCalendorFilter.propertyId && LocalPmCalendorFilter.propertyId != null && LocalPmCalendorFilter.propertyId != 'null') {
        this.selectedFiler.property = { id: LocalPmCalendorFilter.propertyId, outletName: LocalPmCalendorFilter.frontPropertyNameDp }
      }
      this.applyFlag = true;
    } else {
      obj = {
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "date": this.currentMonth,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        frontPropertyNameDp: this.selectedFiler.property ? this.selectedFiler.property.outletName : null
      }
      localStorage.setItem('LocalPmCalendorFilter', JSON.stringify(obj));
    }
    this.apiService.startLoader()
    this.apiService.postCall('PmSchedules/userPmScheduleYearlyGet', {}, obj).subscribe((result: any[]) => {
      this.pmSchedulesData = result['data']
      if (result['success'] == true && this.pmSchedulesData.length != 0) {
        for (var i = 0; i < this.pmSchedulesData.length; i++) {
          // console.log(this.pmSchedulesData[i].userDayPmTempleteId.length);
          if (this.pmSchedulesData[i].userDayPmTempleteId.length != 0) {
            // console.log(this.pmSchedulesData[i]['userDayPmTempleteName']);
            //let dateDay = this.pmSchedulesData[i].date.split("-");
            // console.log(dateDay);
            for (var j = 0; j < this.pmSchedulesData[i]['userDayPmTempleteName'].length; j++) {
              if (this.pmSchedulesData[i].isSchedule == 1) {
                arrayEvent.push({ start: new Date(this.pmSchedulesData[i].date), pm_template_id: this.pmSchedulesData[i]['userDayPmTempleteName'][j].pm_template_id, id: this.pmSchedulesData[i]['userDayPmTempleteName'][j].id, title: this.pmSchedulesData[i]['userDayPmTempleteName'][j].pm_template.name, isSchedule: 1, color: { ...colors.yellow } }) //actions: this.actions
              } else {
                arrayEvent.push({ start: new Date(this.pmSchedulesData[i].date), pm_template_id: this.pmSchedulesData[i]['userDayPmTempleteName'][j].pm_template_id, id: this.pmSchedulesData[i]['userDayPmTempleteName'][j].id, title: this.pmSchedulesData[i]['userDayPmTempleteName'][j].name, isSchedule: 0, color: { ...colors.yellow } }) //actions: this.actions
              }
            }
          }
        }
      }
      this.apiService.stopLoader()
      this.events = [...arrayEvent];

      this.refresh.next();
    },
      error => {
        this.apiService.stopLoader()
        this.events = [...arrayEvent];
        this.refresh.next();
      });
    //return arrayEvent;
    // console.log(arrayEvent);
    // this.events = [...arrayEvent];
    // this.cd.detectChanges()
  }

  // events: CalendarEvent[] = [
  //   {
  //     start: startOfDay(new Date('2024-09-01')),
  //     title: 'An event with no end date',
  //     color: { ...colors.yellow },
  //     actions: this.actions,
  //   },
  //   {
  //     start: startOfDay(new Date('2024-09-04')),
  //     title: 'An event with no end date',
  //     color: { ...colors.yellow },
  //     actions: this.actions,
  //   },
  //   {
  //     start: startOfDay(new Date('2024-09-05')),
  //     title: 'A long event that spans 2 months',
  //     color: { ...colors.yellow },
  //     actions: this.actions,
  //   }
  // ];

  activeDayIsOpen: boolean = false;
  // dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  //   if (isSameMonth(date, this.viewDate)) {
  //     if (
  //       (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
  //       events.length === 0
  //     ) {
  //       this.activeDayIsOpen = false;
  //     } else {
  //       this.activeDayIsOpen = true;
  //     }
  //     this.viewDate = date;
  //   }
  // }

  // eventTimesChanged({
  //   event,
  //   newStart,
  //   newEnd,
  // }: CalendarEventTimesChangedEvent): void {
  //   this.events = this.events.map((iEvent) => {
  //     if (iEvent === event) {
  //       return {
  //         ...event,
  //         start: newStart,
  //         end: newEnd,
  //       };
  //     }
  //     return iEvent;
  //   });
  //   this.handleEvent('Dropped or resized', event);
  // }

  // handleEvent(action: string, event: CalendarEvent): void {
  //   this.modalData = { event, action };
  //   this.modal.open(this.modalContent, { size: 'lg' });
  // }

  // addEvent(): void {
  //   this.events = [
  //     ...this.events,
  //     {
  //       title: 'New event',
  //       start: startOfDay(new Date()),
  //       end: endOfDay(new Date()),
  //       color: colors.red,
  //       draggable: true,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     },
  //   ];
  // }

  // deleteEvent(eventToDelete: CalendarEvent) {
  //   this.events = this.events.filter((event) => event !== eventToDelete);
  // }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  goBack(): void {
    document.body.style.overflowY = 'scroll';
    this.router.navigate(['pm-index'])
  }
  showAll = false;
  EVENT_LIMIT = 2;
  moreEvents: Event[] = [];
  //moreEventStyles = { top: '0px', left: '0px' };  // Declare this property
  handleMoreEvent(e: any, events: Event[]) {
    this.moreEvents = events;
    // this.moreEventStyles.top = `${e.srcElement.offsetTop}px`;
    // this.moreEventStyles.left = `${e.srcElement.offsetLeft}px`;
    // this.moreEventStyles = {...this.moreEventStyles};
    (<HTMLInputElement>document.getElementById('moreEventDisplay')).click();
  }
  editScheduleDataDispaly
  handleEditEventSchedule(events: Event[]) {
    if (events['isSchedule'] == 1) {
      this.router.navigate(['pm-view-new/' + events['id']])
    } else {
      this.pmYearScheduleForm = this.fb.group({
        //businessId: this.apiService.buisnessId,
        authId: this.apiService.authId,
        scheduleDate: [null, Validators.required]
      });
      //this.editScheduleDataDispaly = { start: null, pm_template_id: null, title: null, id: null};
      this.editScheduleDataDispaly = {};
      this.editScheduleDataDispaly = events;
      (<HTMLInputElement>document.getElementById('editPmScheduleData')).click();
    }
  }
  getValidationForm() {
    return this.pmYearScheduleForm.valid
  }
  message
  submit() {
    if (this.getValidationForm()) {
      let obj = {
        authId: this.apiService.authId,
        pmTemplateId: this.editScheduleDataDispaly.pm_template_id,
        id: this.editScheduleDataDispaly.id,
        pmName: this.editScheduleDataDispaly.title,
        scheduleDate: this.pmYearScheduleForm.value.scheduleDate ? moment(this.pmYearScheduleForm.value.scheduleDate).format('YYYY-MM-DD') : null
      }
      // console.log(obj)
      this.apiService.startLoader()
      this.apiService.postCall('pmSchedules/update-year-data/' + this.editScheduleDataDispaly.id, {}, obj).subscribe((result: any[]) => {
        // console.log(result);
        //this.message = result['msg']
      });
      this.apiService.stopLoader();
      this.editScheduleDataDispaly = {};
      (<HTMLInputElement>document.getElementById('navigattohomeEdit')).click();
      (<HTMLInputElement>document.getElementById('navigattohomeDisplay')).click();
      this.currentMonth = this.viewDate;
      this.ngOnInit();
    }
  }
}
