import { Component, HostListener,ChangeDetectionStrategy,ViewChild,TemplateRef } from '@angular/core';
import { ApiService } from './common/services/apiCalls.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
//import { OneSignal } from 'onesignal-ngx';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  searchPromise2 = null
  title = 'phpWeb';
  fullScreen = false;
  forLogin = false;
  tokenFlag = false;
  constructor(public apiService: ApiService, private route: ActivatedRoute, public router: Router) { //public on: OneSignal
    this.apiService.getLoginDetails()
    localStorage.setItem("tabselection", JSON.stringify({ ticketList: 'openTicket', assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }))
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // console.log(val.url.split("/")[1]);
        if (val.url == '/login-view') {
          this.forLogin = true
        } else {
          this.forLogin = false
        }
        if (val.url == '/migrate-asset' || val.url == '/login-view' || val.url == '/asset-qr-html' || val.url == '/schedules-qr-html' || val.url == '/property-qr-html' || val.url == '/' || val.url == '/not-found' || val.url == '/print-qr' || val.url == '/training-details') {
          this.fullScreen = true
          if (val.url.split('/')[1] == 'asset-qr-html') {
            //setTimeout((<HTMLInputElement>document.getElementById('freshworks-container')).style.display="none", 10000);
          }
        }
        else if (val.url.split('/')[1] == 'qr-generate-ticket') { }
        else if (val.url.split('/')[1] == 'customer-feedback' && (<HTMLInputElement>document.getElementById('freshworks-container'))) {
          (<HTMLInputElement>document.getElementById('freshworks-container')).style.display = "none"
        } else if (val.url.split("/")[1] == "training-details" && val.url.split("/")[3]) {
          this.fullScreen = true
          if ((<HTMLInputElement>document.getElementById('freshworks-container'))) {
            (<HTMLInputElement>document.getElementById('freshworks-container')).style.display = "none"
          }
        }
        else {
          this.fullScreen = false
          //this.getBusinessList();
          if ((<HTMLInputElement>document.getElementById('freshworks-container'))) {
            (<HTMLInputElement>document.getElementById('freshworks-container')).style.display = "block"
          }
        }
        if (val.url.split('/')[1] == 'asset-qr-html' || val.url.split('/')[1] == 'property-qr-html' || val.url.split('/')[1] == 'schedules-qr-html') {
          setTimeout((<HTMLInputElement>document.getElementById('freshworks-container')).style.display = "none", 20000);
        }
        if (val.url.split('/')[1] == 'permits-view') {
          this.fullScreen = true
          if ((<HTMLInputElement>document.getElementById('freshworks-container'))) {
            (<HTMLInputElement>document.getElementById('freshworks-container')).style.display = "none"
          }
          //setTimeout((<HTMLInputElement>document.getElementById('freshworks-container')).style.display="none", 20000);
        }
      }
      if (localStorage.getItem('accessToken') == null) {
        this.tokenFlag = false;
      }else{
        this.tokenFlag = true;
      }
    });
  }

  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  ngOnInit() {
    this.router.events.subscribe(val => {
      if (localStorage.getItem('accessToken') == null) {
        this.tokenFlag = false;
      }else{
        this.tokenFlag = true;
      }
      if (val instanceof NavigationEnd) {
        let activeurl = val.url.split("/")[1];
        if ((localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == 'null') && activeurl != 'customer-feedback' && activeurl != 'ticket-view' && activeurl != 'training-details') {
          this.router.navigate(['/login-view'])
        }
        if (activeurl == 'dashboard-ceo' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'dashboard-checklist' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'dashboard-ticket' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'dashboard-asset' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'dashboard-pm' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'dashboard-parts' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'dashboard-survey' && this.dashboardFeaturePermission == true) {

        } else if (activeurl == 'inspection-list') {

        } else if (activeurl == 'ticket-main-list') {

        } else if (activeurl == 'home' && this.assetsFeaturePermission == true) {

        } else if (activeurl == 'parts-inventory' && this.partsFeaturePermission == true) {

        } else if (activeurl == 'pm-index' && this.maintenanceFeaturePermission == true) {

        } else if (activeurl == 'meter-list' && this.meterFeaturePermission == true) {

        } else if (this.businessFeaturePermit == true) {

        } else if (this.businessFeatureAttendance == true) {

        } else if (this.businessFeatureTraining == true) {

        } else if (this.businessFeatureasset == true) {

        } else if (this.businessFeaturmaintanence == true) {

        } else if (this.businesspartsPermission == true) {

        } else if (this.dashboardFeaturePermission == true) {

        } else if (this.assetsFeaturePermission == true) {

        } else if (this.partsFeaturePermission == true) {

        } else if (this.meterFeaturePermission == true) {

        } else if (this.maintenanceFeaturePermission == true) {

        } else if (this.adHockChecklistFeaturePermission == true) {

        } else if (this.qrcklistFeaturePermission == true) {

        } else if (this.ticketsFeaturePermission == true) {

        } else if (this.attendanceFeaturePermission == true) {

        } else if (this.permitFeaturePermission == true) {

        } else if (this.feedbackFeaturePermission == true) {

        } else if (this.trainingFeaturePermission == true) {

        } else if (this.employeeDatabaseFeaturePermission == true) {

        } else if (activeurl == 'customer-feedback') {

        } else if (activeurl == 'training-details') {

        } else if (activeurl == 'ticket-view') {

        } else {
          if (!localStorage.getItem('accessToken') || localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == 'null' || localStorage.getItem('accessToken') == '') {
            this.router.navigate(['/login-view']);
          }
          //this.router.navigate(['/login-view'])
        }
      }
    });
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    const body = document.getElementsByTagName('body')[0];
    if (localStorage.getItem('theme') == "dark") {
      localStorage.setItem('theme', "dark");
      body.classList.add('dark');
    } else {
      localStorage.setItem('theme', "lite");
      body.classList.remove('dark');
    }
    // console.log(body.classList)
    document.body.style.overflowY = 'scroll';
    document.body.style.overflowX = 'hidden';
    var OneSignal = window['OneSignal'] || [];
    var t = this;
    OneSignal.sendTag("tech", "test").then(() => {
      var userId = this.apiService.userId;
      var t = this;
      OneSignal.getUserId(function (ids) {
        let obj = {
          "token": ids,
          "userId": userId
        }
        localStorage.setItem("onsignalToken", ids);
        if (t.apiService.userId) {
          t.saveToken(obj);
        }
      });
    });
    // console.log((<HTMLInputElement>document.getElementById('freshworks-frame')));
    clearTimeout(this.searchPromise2);
    this.searchPromise2 = setTimeout(() => {
      // console.log(this.router.url.split("/"))
      // if(this.router.url.split("/")[2])
      if (this.router.url.split("/")[2] == '/migrate-asset' || this.router.url.split("/")[2] == '/login-view' || this.router.url.split("/")[2] == '/') {
        this.fullScreen = true
      }
    }, 1000)
    var OneSignal = window['OneSignal'] || [];
    OneSignal.init({
      appId: "8cd065ef-b24d-43a7-b19b-eb65da319709"
    });
    // (<HTMLInputElement>document.getElementById('freshworks-frame')).style.display="none"
  }
  saveToken(obj) {
    this.apiService.postCall('users/saveOnesignalToken', {}, obj).subscribe((result: any[]) => { });
  }
  // getBusinessList() {
  //   this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
  //     // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
  //     // console.log(result);
  //     result['data']
  //     // this.apiService.stopLoader()
  //     // this.router.navigate(['/home'])
  //   },

  //   error =>{
  //     // console.log(error)
  //     this.router.events.subscribe(val => {
  //       if (val instanceof NavigationEnd) {

  //         // console.log(val.url)
  //       }
  //       })

  //     // if()
  //     this.router.navigate(['/login-view'])
  //     this.apiService.stopLoader()
  //   });
  // }

  checkAPICall() {
    return this.apiService.loader;
  }


  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'customLogout' && (<HTMLInputElement>document.getElementById('customLogoutBox'))) {
      (<HTMLInputElement>document.getElementById('customLogoutBox')).style.display = 'none';
    }
    if (this.apiService.authId) {
      this.apiService.checkUserStatusFlag();
    }
  }

  collapseSidebar: boolean;
  changeCollapse() {
    // console.log("check")
    this.collapseSidebar = !this.collapseSidebar;
  }
}
