import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-pm-templates-edit',
  templateUrl: './pm-templates-edit.component.html',
  styleUrls: ['./pm-templates-edit.component.css']
})
export class PmTemplatesEditComponent implements OnInit {

  constructor(public apiService: ApiService, public formBuilder: FormBuilder, public router: Router, public _location: Location) { }
  pmTemplateId;
  frequencyList = []
  responseType = []
  pmTemplateForm = this.formBuilder.group({
    businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    propertyId: [null, Validators.required],
    assetManagementId: [null, Validators.required],

    name: [null, Validators.required],
    frequency: [null, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    startTime: [null],
    endTime: [null],
    frequencyNumber: [null],
    frequencyUnit: [null],
    checklist: this.formBuilder.array([]),
    serviceTeam: this.formBuilder.array([]),
    assignee: [null],
    saturdayWeekFlag: false,
    sundayWeekFlag: false,
    checklistStartTime: [null],
    checklistEndTime: [null],
    checklistScheduleUserIdNotify: [null],
    reminderTime: [null],
    isEndless: false,
    isOpenDayFlag: false,
    additionalSettingFlag: false,
    dayContinueSchedules: false,
    multipleTimesHoliday: this.formBuilder.array([]),
    remindersNotificationFlag: false,
    sendNotificationFlag: false,
    isReminder: false
  });

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  removeChecklists = []
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    this.pmTemplateId = this.router.url.split("/")[3];
    // console.log("this is PM EDIT Template",this.pmTemplateId);
    //this.getPmTemplatesList()
    this.getPropertyList();
    this.getPmTemplate();
    this.getMultichoiceresponcetypeLis()
    // this.getUserList()
    for (let i = 1; i <= 20; i++)this.scoreList.push(i)
  }

  todayDate: any = new Date();
  removeChecklist(i) {
    // console.log(this.formChecklistArr)
    if (this.formChecklistArr.at(i).get('id')) {
      this.removeChecklists.push(this.formChecklistArr.at(i).get('id')['value']);
    }
    //if(i != 0)
    this.formChecklistArr.removeAt(i)
  }

  scoreList = []

  getSelected() {
    let statusImages = []
    for (let d of this.imagesArray) { if (d.selected) statusImages.push(d.id) }

    if (statusImages[0]) return true
    else false;
  }


  propertyList = []
  templateList = []
  statusGroup = []

  getDisabledUser(d) {
    if (this.userArry[0]) {
      //// console.log(d , this.userArry)
      let idx = this.userArry.findIndex(x => x.id == d.id)
      if (idx != -1) return true
      else return false
    }
    else return false
  }


  getPmTemplatesList() {
    let templetBody = {
      // assetId:this.assetId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/index', {}, templetBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.apiService.stopLoader()
      this.templateList = []
      this.frequencyList = []
      this.responseType = []
      this.statusGroup = []
      this.templateList = result['data']
      this.frequencyList = result['frequency']
      this.statusGroup = result['statusGroup']
      this.getPropertyList();
      // console.log("Template : ",this.templateList);
      this.getPmTemplate();

      // this.pmTemplateList = result['data']

    })
  }

  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
    })
  }

  get formUserArr() {
    return this.pmTemplateForm.get('serviceTeam') as FormArray;
  }

  get formChecklistArr() {
    return this.pmTemplateForm.get('checklist') as FormArray;
  }


  initUser(data?) {
    if (data != undefined) {
      // console.log(data.name);
      return this.formBuilder.group({
        serviceTeamId: data['id'],
        userId: data['userId'],
      });
    } else {
      return this.formBuilder.group({
        serviceTeamId: '',
        userId: [""],
      });
    }

  }

  initChecklist(data: any | null) {
    if (data != undefined) {
      // console.log(data.name);
      return this.formBuilder.group({
        checklistId: data['id'],
        name: [data['name'], Validators.required],
        response: data['response'],
        responseType: [1],
        id: [null]
      });
    } else {
      return this.formBuilder.group({
        checklistId: [null],
        name: [null, Validators.required],
        response: [null],
        responseType: [1],
        responceGroup: [null],
        id: [null]
      });
    }


  }

  addMoreUser(): void {

    this.formUserArr.push(this.initUser());
  }

  addMoreChecklist(data?): void {
    this.formChecklistArr.push(this.initChecklist(data));
  }

  goBack(): void {
    this._location.back();
  }

  userList = []
  checklistStartTime1
  checklistEndTime1
  getPmTemplate(): void {
    this.apiService.startLoader()
    this.apiService.getCall('pm-templates/view/' + this.pmTemplateId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if (result['success'] == true) {

        this.pmTemplateForm.reset()
        let data = result['data'];

        this.getUserListoutletId(data['propertyId'], data)
        // console.log("PM Template :",result);
        this.frequencyList = [];
        this.responseType = [];
        this.frequencyList = result['frequency']
        this.responseType = result['reasponseType'];
        let reminder = new Date();
        if (result['data'].reminderTime) {
          reminder.setHours(result['data'].reminderTime.split(':')[0])
          reminder.setMinutes(result['data'].reminderTime.split(':')[1])
          reminder.setSeconds(result['data'].reminderTime.split(':')[2])
        }
        if (result['data'].checklistStartTime) {
          this.checklistStartTime1 = new Date();
          this.checklistStartTime1.setHours(result['data'].checklistStartTime.split(':')[0])
          this.checklistStartTime1.setMinutes(result['data'].checklistStartTime.split(':')[1])
          this.checklistStartTime1.setSeconds(result['data'].checklistStartTime.split(':')[2])
        }
        if (result['data'].checklistEndTime) {
          this.checklistEndTime1 = new Date();
          this.checklistEndTime1.setHours(result['data'].checklistEndTime.split(':')[0])
          this.checklistEndTime1.setMinutes(result['data'].checklistEndTime.split(':')[1])
          this.checklistEndTime1.setSeconds(result['data'].checklistEndTime.split(':')[2])
        }
        this.pmTemplateForm.patchValue({
          businessId: this.apiService.buisnessId,
          authId: this.apiService.authId,
          propertyId: data['propertyId'],
          assetManagementId: data['assetId'],
          name: data['name'],
          frequency: data['scheduleFrequency'],
          assignee: [null],
          // startDate:data['startDate'],
          // endDate:data['endDate'],
          // startTime:data['startTime'],
          // endTime:data['endTime'],
          // checklist:this.formBuilder.array([]),  
          // serviceTeam:this.formBuilder.array([])  ,

          // frequency: id.scheduleFrequency,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          frequencyNumber: data.frequencyNo,
          frequencyUnit: data.frequencyUnit,
          saturdayWeekFlag: result['data'].saturdayWeekFlag == "0" ? false : true,
          sundayWeekFlag: result['data'].sundayWeekFlag == "0" ? false : true,
          additionalSettingFlag: result['data'].additionalSettingFlag == "0" ? false : true,
          remindersNotificationFlag: result['data'].remindersNotificationFlag == "0" ? false : true,
          isOpenDayFlag: result['data'].isOpenDayFlag == "0" ? false : true,
          dayContinueSchedules: result['data'].dayContinueSchedules,
          sendNotificationFlag: result['data'].sendNotificationFlag == "0" ? false : true,
          isReminder: result['data'].isReminder == "0" ? false : true,
          isEndless: result['data'].isInfinite == "0" ? false : true,
          checklistStartTime: this.checklistStartTime1,
          checklistEndTime: this.checklistEndTime1,
          checklistScheduleUserIdArry: parseInt(result['data'].checklistScheduleUserIdNotify),
          reminderTime: reminder,
        });
        this.getUserListWithArray(result);
        if (result['data'].isMultipleTimesHoliday != null) {
          if (result['data'].isMultipleTimesHoliday[0]) {
            for (let d of result['data'].isMultipleTimesHoliday) this.addDefaultChecklistHoliday(d)
          }
        }

        setTimeout(() => { this.pmTemplateForm.patchValue({ assignee: null }); }, 2000);

        this.getAssetList()

        if (data.checklists[0]) {
          for (let d of data.checklists) this.addDefaultChecklist(d)
        }
        else this.addMoreChecklist()



      }

    })
  }
  removeAm() {
    // console.log(this.scheduleAdd.value.reminderTime)
    // console.log(this.scheduleAdd.value.reminderTime.replace("am", ""))
    if (this.pmTemplateForm.value.reminderTime) {
      this.pmTemplateForm.value.reminderTime = this.pmTemplateForm.value.reminderTime.replace("am", "");
      this.pmTemplateForm.value.reminderTime = this.pmTemplateForm.value.reminderTime.replace("pm", "");
    }
  }
  removeAmcheCklistStartTime() {
    if (this.pmTemplateForm.value.checklistStartTime) {
      this.pmTemplateForm.value.checklistStartTime = this.pmTemplateForm.value.checklistStartTime.replace("am", "");
      this.pmTemplateForm.value.checklistStartTime = this.pmTemplateForm.value.checklistStartTime.replace("pm", "");
    }
  }
  removeAmcheChecklistEndTime() {
    if (this.pmTemplateForm.value.checklistEndTime) {
      this.pmTemplateForm.value.checklistEndTime = this.pmTemplateForm.value.checklistEndTime.replace("am", "");
      this.pmTemplateForm.value.checklistEndTime = this.pmTemplateForm.value.checklistEndTime.replace("pm", "");
    }
  }
  getUserListWithArray(result1) {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.pmTemplateForm.value.property,
      "onlyUsers": 1
    }

    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.pmTemplateForm.value.property, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.userList = result['data'];
      this.userListNotify = result['data'];
      if (result1['data'].checklistScheduleUserIdNotify) {
        const myArray1 = result1['data'].checklistScheduleUserIdNotify.split(",");
        for (let d of myArray1) {
          let idx = this.userListNotify.findIndex(x => x.id == d)
          if (idx != -1) this.checklistScheduleUserIdArry.push(this.userListNotify[idx])
        }
      }
    })
  }
  getassetDate(id) {

    // console.log(this.assetList, id)
    let idx = this.assetList.findIndex(x => x.id == id)
    // console.log()
    if (idx != -1) return this.assetList[idx]
  }

  getPropertyDetails(id) {
    // console.log(this.propertyList, id)
    let idx = this.propertyList.findIndex(x => x.id == id)
    if (idx != -1) return this.propertyList[idx]
  }
  userArry = []

  removeUser(i) {
    this.userArry.splice(i, 1)
    this.showAssigneeLimitFlag = false;
  }
  selectUser() {

    if (this.userArry.length < 5) {

      this.userArry.push(this.pmTemplateForm.value.assignee)
      this.pmTemplateForm.patchValue({ assignee: null })
    }
    else {
      this.pmTemplateForm.patchValue({ assignee: null })
      this.showAssigneeLimitFlag = true
    }

    //// console.log(this.pmTemplateForm)
    //this.userArry.push(this.pmTemplateForm.value.assignee)
    //this.pmTemplateForm.patchValue({assignee : null})
  }

  getUserList(event: any): void {
    let outletId = event.target.value.id;
    // console.log("Outlet : ",outletId);
    // this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": outletId,
    }

    this.userArry = []
    this.getAssetList()
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.stopLoader()
      // this.assetList = []
      // console.log("vaulesss", result);

      this.userList = result['data'];

    })
  }

  getUserListoutletId(outletId, data): void {
    // let outletId = event.target.value.id;
    // console.log("Outlet : ",outletId);

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": outletId,
    }
    // this.apiService.startLoader()
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.stopLoader()
      // this.assetList = []
      // console.log("vaulesss", result);

      this.userList = result['data'];

      let idx = this.userList.findIndex(x => x.id == data.serviceTeams[0].userId)

      this.pmTemplateForm.patchValue({
        assignee: idx != -1 ? this.userList[idx] : null,
      })
      for (let d of data.serviceTeams) {
        let idx = this.userList.findIndex(x => x.id == d.userId)
        if (idx != -1) this.userArry.push(this.userList[idx])
      }

    })
  }

  initChecklistDefalut(d) {
    return this.formBuilder.group({
      name: d.name,
      response: d.response,
      responseType: d.responseType,
      responceSearch: [null],
      responceGroup: d.statusGroupId,
      id: d.id
    });
  }



  addDefaultChecklist(d): void {
    this.formChecklistArr.push(this.initChecklistDefalut(d));
  }


  assetList
  getAssetList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "statusNotIn": "5,8",
      "outletId": this.pmTemplateForm.value.propertyId,
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']
      // this.assetListFilter= result['data']['data']
    })
  }


  message
  getFrequencyName() {
    if (this.pmTemplateForm.value.frequency == null) return 'Set a frequency'
    else if (this.pmTemplateForm.value.frequency == 'Custom') return 'Every ' + (this.pmTemplateForm.value.frequencyNumber ? this.pmTemplateForm.value.frequencyNumber : '') + " " + (this.pmTemplateForm.value.frequencyUnit ? this.pmTemplateForm.value.frequencyUnit : '')
    else return this.pmTemplateForm.value.frequency
  }
  showAssigneeLimitFlag: boolean;
  saveTemplate(): void {
    // let formData = this.pmTemplateForm.value;
    // // console.log("formDate",formData);

    // // console.log(this.pmTemplateForm.value)
    let partsArray = []
    // // console.log("selected Items",this.selectedItems);
    let teamArray = []
    let checklistScheduleUserIdArryId = []
    let isMultipleTimessHoliday = []
    for (let i of this.userArry) teamArray.push(i.id)
    for (let d of this.pmTemplateForm.value.checklist) { partsArray.push({ id: d.id, name: d.name, response_type: d.responseType, response: d.response, responseGroup: d.responceGroup }) }
    for (let d of this.checklistScheduleUserIdArry) checklistScheduleUserIdArryId.push(d.id)
    for (let d of this.pmTemplateForm.value.multipleTimesHoliday) { isMultipleTimessHoliday.push({ name: d.name ? moment(d.name).format('YYYY-MM-DD') : "" }) }
    let obj = {
      businessId: this.apiService.buisnessId,
      propertyId: this.pmTemplateForm.value.propertyId,
      assetManagementId: this.pmTemplateForm.value.assetManagementId,
      name: this.pmTemplateForm.value.name,
      frequency: this.pmTemplateForm.value.frequency,
      startDate: this.pmTemplateForm.value.startDate,
      endDate: this.pmTemplateForm.value.endDate,
      authId: this.apiService.userId,
      checklist: partsArray,
      serviceTeam: this.userArry,//[this.pmTemplateForm.value.assignee],
      users: this.pmTemplateForm.value.users,

      frequencyUnit: this.pmTemplateForm.value.frequencyUnit,

      frequencyNo: this.pmTemplateForm.value.frequencyNumber,
      //new work
      inFinite: this.pmTemplateForm.value.isEndless ? 1 : 0,
      additionalSettingFlag: this.pmTemplateForm.value.additionalSettingFlag ? 1 : 0,
      remindersNotificationFlag: this.pmTemplateForm.value.remindersNotificationFlag ? 1 : 0,
      isOpenDayFlag: this.pmTemplateForm.value.isOpenDayFlag ? 1 : 0,
      dayContinueSchedules: this.pmTemplateForm.value.dayContinueSchedules,
      sendNotificationFlag: this.pmTemplateForm.value.sendNotificationFlag ? 1 : 0,
      saturdayWeekFlag: this.pmTemplateForm.value.saturdayWeekFlag ? 1 : 0,
      sundayWeekFlag: this.pmTemplateForm.value.sundayWeekFlag ? 1 : 0,
      isReminder: this.pmTemplateForm.value.isReminder ? 1 : 0,
      reminderTime: this.pmTemplateForm.value.reminderTime ? moment(this.pmTemplateForm.value.reminderTime).format('HH:mm:ss') : "",
      isMultipleTimessHoliday: isMultipleTimessHoliday,
      checklistStartTime: this.pmTemplateForm.value.checklistStartTime ? moment(this.pmTemplateForm.value.checklistStartTime).format('HH:mm:ss') : "",
      checklistEndTime: this.pmTemplateForm.value.checklistEndTime ? moment(this.pmTemplateForm.value.checklistEndTime).format('HH:mm:ss') : "",
      checklistScheduleUserIdNotify: checklistScheduleUserIdArryId,

      removeChecklist: this.removeChecklists
    }

    // console.log(obj)


    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/edit/' + this.pmTemplateId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess11')).click();
      // if(result['success'] = true){
      //   alert(result['msg']);
      //   this.router.navigate(['/pm-templates'])
      // }else{
      //   alert(result['msg']);
      // }
    });
  }
  userListNotify
  checklistScheduleUserIdArry = []
  selectUserNotify() {
    if (this.checklistScheduleUserIdArry.length < 32) {
      this.checklistScheduleUserIdArry.push(this.pmTemplateForm.value.checklistScheduleUserIdNotify)
      this.pmTemplateForm.patchValue({ checklistScheduleUserIdNotify: null })
    } else {
      this.pmTemplateForm.patchValue({ checklistScheduleUserIdNotify: null })
    }
  }
  getUserNames() {
    var str = "";
    for (var i = 0; i < this.userArry.length; i++) {
      if (i == 0) {
        str = this.userArry[i].fullName;
      } else {
        str = str + ", " + this.userArry[i].fullName;
      }
    }
    return str;
  }
  getUserNamesNotify() {
    var str = "";
    for (var i = 0; i < this.checklistScheduleUserIdArry.length; i++) {
      if (i == 0) {
        str = this.checklistScheduleUserIdArry[i].fullName;
      } else {
        str = str + ", " + this.checklistScheduleUserIdArry[i].fullName;
      }
    }
    return str;
  }
  getMultipleTimesHoliday() {
    var str = "";
    for (var i = 0; i < this.pmTemplateForm.value.multipleTimesHoliday.length; i++) {
      if (i == 0) {
        str = this.pmTemplateForm.value.multipleTimesHoliday[i].name ? moment(this.pmTemplateForm.value.multipleTimesHoliday[i].name).format('DD-MM-YYYY') : "";
      } else {
        str = str + ", " + this.pmTemplateForm.value.multipleTimesHoliday[i].name ? moment(this.pmTemplateForm.value.multipleTimesHoliday[i].name).format('DD-MM-YYYY') : "";
      }
    }
    return str;
  }
  get formChecklistArrHoliday() {
    return this.pmTemplateForm.get('multipleTimesHoliday') as FormArray;
  }
  removeChecklistsHoliday = []
  removeChecklistHoliday(i) {
    // console.log(this.formChecklistArr)
    if (this.formChecklistArrHoliday.at(i).get('id')) {
      this.removeChecklistsHoliday.push(this.formChecklistArrHoliday.at(i).get('id')['value']);
    }
    //if(i != 0)
    this.formChecklistArrHoliday.removeAt(i)
  }
  initChecklistHoliday(data: any | null) {
    if (data != undefined) {
      // console.log(data.name);
      return this.formBuilder.group({
        name: [data['name'], Validators.required]
      });
    } else {
      return this.formBuilder.group({
        checklistId: [null],
        name: [null, Validators.required]
      });
    }
  }
  initChecklistDefalutHoliday(d) {
    if (d.name) {
      let scheduleTimeHoliday = new Date(d.name);
      return this.formBuilder.group({
        name: scheduleTimeHoliday
      });
    }
  }
  addDefaultChecklistHoliday(d): void {
    this.formChecklistArrHoliday.push(this.initChecklistDefalutHoliday(d));
  }
  addMoreChecklistHoliday(data?): void {
    this.formChecklistArrHoliday.push(this.initChecklistHoliday(data));
  }
  getDisabledUserNotify(d) {
    if (this.checklistScheduleUserIdArry[0]) {
      let idx = this.checklistScheduleUserIdArry.findIndex(x => x.id == d.id)
      if (idx != -1) return true
      else return false
    }
    else return false
  }
  removeUserNotifyUser(i) {
    this.checklistScheduleUserIdArry.splice(i, 1)
  }
  selectFreq = false;
  selectCustom = false;
  unitOfMeasure = false;


  selectUnit = false;
  selectFreqdata(data) {
    this.selectFreq = !this.selectFreq
    this.pmTemplateForm.patchValue({ frequency: data })
  }
  selectCustomData(data) {
    this.pmTemplateForm.patchValue({ frequency: data })
  }


  selectFreqUnitdata(data) {
    this.unitOfMeasure = !this.unitOfMeasure
    this.pmTemplateForm.patchValue({ frequencyUnit: data })
  }


  selectResponceTypr(i, data) {
    // console.log(i,data)
    this.formChecklistArr.at(i).patchValue({
      responseType: 1,
      response: data.name,
      responceGroup: data.id

    })

    // console.log(this.pmTemplateForm.value)
  }
  selectResponse(data, i) {
    let dataText = '';
    dataText = data;
    if (data == 'Text Field') {
      data = 2;
    } else if (data == 'Digital Signature') {
      data = 3;
    } else if (data == 'Numeric') {
      data = 4;
    } else if (data == 'Image') {
      data = 5;
    } else if (data == 'Date') {
      data = 6;
    }
    this.formChecklistArr.at(i).patchValue({
      // responseType:data.id,
      //responseType: data == 'Text Field' ? 2:3,
      //response:data
      responseType: data,
      response: dataText

    })
  }


  statusListresponceType = []
  statusListresponceTypeFilter = []
  imagesArray = []
  imagePath: any;

  getMultichoiceresponcetypeLis() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }
      let idx = jsonToBeUsed.findIndex(x => x.name == "Add a Response")
      jsonToBeUsed.splice(idx, 1)

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;

    })

    this.apiService.getCall('inspections/groupAllStatus/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

      this.imagesArray = result['data']
      this.imagePath = result['path']

      // console.log(this.imagesArray)
      this.imagesArray.map(x => x.selected = false)
    })
    this.apiService.getCall('inspections/groupAllStatusImages', {}).subscribe((result: any[]) => {

      this.allImageArray = result['data']
      this.allImageArray.map(x => x.selected = false)
      this.allImagePath = result['path']

      // console.log(this.imagesArray)
      // this.imagesArray.map(x => x.selected = false)
    })
  }

  addnewStatusGroup() {
    let obj = {
      "name": this.selectedNewStaus.name,
      "value": this.selectedNewStaus.score,
      "statusImageId": this.selectedNewStaus.imageId,
      "isAlert": parseInt(this.selectedNewStaus.alert),
      "businessId": this.apiService.buisnessId,

    }

    // console.log(obj)

    this.apiService.startLoader()
    this.apiService.postCall('inspections/addNewStatus', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('dismisssNewStatusPopup')).click();
      this.apiService.getCall('inspections/groupAllStatus/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

        this.imagesArray = result['data']
        this.imagePath = result['path']

        // console.log(this.imagesArray)
        this.imagesArray.map(x => x.selected = false)
      })
    })

  }

  allImageArray = []
  allImagePath: any;

  selectedNewStaus = { name: null, imageId: null, score: null, alert: null }


  responceType
  responcetypePopup
  openResponceTypePopup(name) {
    // console.log(this.responcetypePopup, name)

    if (this.responcetypePopup == name) {
      this.responceType = null;
      this.responcetypePopup = null;
    }
    else this.responcetypePopup = name

  }
  openResponceType(name) {
    // console.log(this.responceType, name)
    if (this.responceType == name) this.responceType = null
    else this.responceType = name
    // var modal = document.getElementById(name);

    // if(modal.style.display == 'none')modal.style.display = "block";
    // else modal.style.display = "none";
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.selectUnit = false
      this.selectFreq = false
      this.responceType = null
      this.responcetypePopup = null
    }
  }


  newGroupName: any;

  addnewStatus() {
    // console.log(this.imagesArray, this.newGroupName)
    let statusImages = []
    for (let d of this.imagesArray) if (d.selected) statusImages.push(d.id)
    let obj = {
      name: this.newGroupName,
      businessId: this.apiService.buisnessId,
      status: statusImages

    }
    this.apiService.startLoader()
    this.apiService.postCall('inspections/addNewGroup', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.message = result["msg"];

      (<HTMLInputElement>document.getElementById('openSuccess11111111New')).click();
      (<HTMLInputElement>document.getElementById('cancelSTatusPopup')).click();
      this.getMultichoiceresponcetypeLis()
    })

  }


  getValidation() {
    if (this.userArry.length == 0) {
      return false;
    }

    return this.pmTemplateForm.valid && !this.validateDate(this.pmTemplateForm.value.startDate, this.pmTemplateForm.value.endDate)
  }

  validateDate(satrtDate, endDate) {
    // console.log(satrtDate , endDate)
    if (satrtDate && endDate) {
      if (endDate <= satrtDate) return true
      else return false
    }
  }
}
