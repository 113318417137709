import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../../common/services/apiCalls.service';
import { StoredData } from '../../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-permit-tenant-list',
  templateUrl: './permit-tenant-list.component.html',
  styleUrls: ['./permit-tenant-list.component.css']
})
export class PermitTenantListComponent implements OnInit {
  constructor(
    public route: Router, 
    public router: Router,
    public apiService: ApiService,
    public storedData: StoredData
  ) {
  }

  showAddMenu = false;
  // pagination Veriables
  paginationArray = [];
  activePage: any = 1;
  pageSize = 10;
  totalnoOfPages;
  message: any;
  saveData = false;
  pageSizeArchive = 10;
  activePageArchive: any = 1;
  paginationArrayArchive = [];
  totalnoOfPagesArchive;
  propertyFiletr = false;
  // array list veriables
  activeList: any;
  archiveList: any;
  roleList: any;
  propertyList: any;
  businessList: any;

  // Sorting variables
  sortType = 'desc';
  sortBy = 'id';

  // Filter Object
  selectedFiler = { userName: null, property: null, business: null, role: null };
  applyFlag = false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role: any;

  // Tab Selectio veriable
  tabSelected = '';
  statusSelection: any;

  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ permitList: this.tabSelected, assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }))
  }
  ngOnInit(): void {
    // document.body.style.overflow = 'hidden';
    document.body.style.overflowY = 'scroll';

    // var userIndexPagination = localStorage.getItem('userIndexPagination');
    // if (userIndexPagination) {
    //   this.activePage = parseInt(userIndexPagination);
    // }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).permitList
      let tabselection = JSON.parse(localStorage.getItem("tabselection")).permitList
      if (tabselection == undefined) {
        this.tabSelected = 'active';
      }
    }
    if (this.router.url.split("/")[3]) {
      this.tabSelected = this.router.url.split("/")[3];
      this.activePage = 1;
      this.jumpToPage = 1;
      if (this.router.url.split("/")[3] == 'active') {
        this.getActiveUsers();
        this.setTabSelection();
      }
      else if (this.router.url.split("/")[3] == 'archived') {
        this.getArchiveUsers();
        this.setTabSelection();
      }
    } else {
      if (this.tabSelected == 'active') {
        this.getActiveUsers();
      }
      else if (this.tabSelected == 'archived') {
        this.getArchiveUsers();
      }
    }
    this.role = 6;
    this.getPropertyList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);

  }

  goBack() {
    this.route.navigate(['/permit-setting']);
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  sortData(data) {
    this.sortBy = data;
    if (this.sortType == 'asc') {
      this.sortType = 'desc';
    } else {
      this.sortType = 'asc';
    }

    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.userName != null) {
      length = length + 1;
    }
    if (this.selectedFiler.property != null) {
      length = length + 1;
    }
    if (this.selectedFiler.business != null) {
      length = length + 1;
    }
    if (this.selectedFiler.role != null) {
      length = length + 1;
    }

    if (length == 0 || !this.applyFlag) {
      return '(None Selected)';
    } else if (this.applyFlag) {
      return `(${length} Filter Selected)`;
    }
  }

  setPaginationArrayArchive(result: any) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeArchive);
    this.totalnoOfPagesArchive = noOfPAges;
    this.paginationArrayArchive = [];

    this.activePageArchive = parseInt(this.activePageArchive);

    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArrayArchive.push(i);
      }
    } else if (4 + this.activePageArchive < this.totalnoOfPagesArchive) {
      for (let i = this.activePageArchive; i <= 4 + this.activePageArchive; i++) {
        this.paginationArrayArchive.push(i);
      }
    } else if (4 + this.activePageArchive >= 6) {

      let start = (4 + this.activePageArchive) - noOfPAges;
      for (let i = this.activePageArchive - start; i <= noOfPAges; i++) {
        this.paginationArrayArchive.push(i);
      }
    }
    // console.log('Pagination Archive : ', this.paginationArrayArchive);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    // console.log(noOfPAges);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }


  jumpToParked;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }

  jumpToParkedN;

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }

  jumpToPage;

  clear() {
    this.jumpToPage = 1;
    this.selectedFiler = { userName: null, property: null, business: null, role: null };
    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }

  pagination(d) {
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('userIndexPagination', d);
      if (this.tabSelected == 'active') {
        this.getActiveUsers();
      } else {
        this.getArchiveUsers();
      }
    }
  }


  changePage(ev) {
    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }

  getBusinessList(): void {
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      this.businessList = [];
      this.businessList = result['data'];
    });
  }

  getPropertyList(): void {
    let formData = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId + '/' + this.apiService.authId, {}, formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.propertyList = [];
      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];
    });
  }

  filterpropertyList = [];
  outletAddLimit = false;
  exeedMsg = '';

  getActiveUsers(): void {
    this.apiService.startLoader();
    this.tabSelected = 'active';
    const body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortType: this.sortType,
      sortBy: this.sortBy,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
      'roleId': 6,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,

    };

    this.apiService.startLoader();
    this.apiService.postCall('users/userActive/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.activeList = [];
      this.activeList = result['data']['data'];
      this.setPaginationArray(result);
    });

  }

  filterPropertySearch;

  filterProperty(ev) {
    // console.log(ev);
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase());
      }
    });
  }


  getArchiveUsers(): void {
    this.apiService.startLoader();
    this.tabSelected = 'archived';
    const body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortType: this.sortType,
      sortBy: this.sortBy,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
      'roleId': 6,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader();
    this.apiService.postCall('users/userArchive/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.archiveList = [];
      this.archiveList = result['data']['data'];
      this.setPaginationArray(result);
      //this.setPaginationArrayArchive(result);
    });
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    } else if (btn.id != 'custom') {
      this.showFilter = false;
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    if (btn.id == 'changeButton') {
    } else if (btn.id != 'changeButton') {
      this.statusSelection = undefined;
    }

  }

  changeStatus(id: any, status): void {

    this.apiService.startLoader();
    this.apiService.getCall('users/deactivateUser/' + id + '/' + status, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.saveData = result['success'];
      this.message = result['msg'];
      if (this.tabSelected == 'active') {
        this.getActiveUsers();
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.getArchiveUsers();
      }
    });
  }

  closeAll() {
    this.propertyFilter = false;
    this.roleFilter = false;
    this.businessFilter = false;
  }

  selectStatus(d) {
    // console.log(d);
    if ('status' + d.id != this.statusSelection) {
      this.statusSelection = 'status' + d.id;
    } else {
      this.statusSelection = undefined;
    }
    // console.log(this.statusSelection);
  }


  dropButton = false;
  uploadedFiles;
  fileName;
  type = null;

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file);
      this.uploadedFiles = file;
      this.fileName = file.name;
    }
  }

  getOutletName(id) {
    var d = this.propertyList.filter(x => x.id == id)
    if (d.length > 0) {
      return d[0]?.outletName;
    }
  }
  changeType(e) {
    if (e.target.value == '0: null') {
      this.type = null;
    } else {
      this.type = e.target.value;
    }
    this.uploadedFiles = null;
    this.fileName = null;
  }

  getValidation() {
    // console.log(this.uploadedFiles);
    // console.log(this.type);
    // console.log(this.apiService.roleId != 1 && this.type == 2);
    if (!this.uploadedFiles) {
      return false;
    }
    if (this.type == null) {
      return false;
    }
    if (this.apiService.roleId != 1 && this.type == 2) {
      this.message = 'You dont have access to upload Business Admin';
      (<HTMLInputElement>document.getElementById('openSuccessArchive')).click();
      return false;
    }
    return true;
  }

  errorMessages = [];

  uploadWarranty() {
    let testData: FormData = new FormData();
    this.errorMessages = [];
    testData.append('file', this.uploadedFiles);
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    testData.append('type', this.type);
    this.apiService.startLoader();
    this.apiService.postCall('users/bulkupload', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.getActiveUsers();
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.apiService.stopLoader()
      this.uploadedFiles = null
      this.fileName = null
    });
  }

}
