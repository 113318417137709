import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;



@Component({
  selector: 'app-dashboard-survey',
  templateUrl: './dashboard-survey.component.html',
  styleUrls: ['./dashboard-survey.component.css']
})
export class DashboardSurveyComponent implements OnInit {
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;


  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];

  selectedFiler = { department: null, name: null, property: null, startDate: null, endDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      //this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.statsuFilter = false;
      // this.typeFilter = false;
      // // this.showAddMenu = false;
      // this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.typeFilter = false;
      // this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }
  closeAll() {
    this.propertyFiletr = false;
    this.departmentFilter = false;
    // this.typeFilter = false;
    // this.businessFilter = false;
  }

  departmentList = [{ id: 2, name: 'Single-Question' }, { id: 1, name: 'Multi-Question' }]
  getDepartmentList() {
    this.departmentList = [{ id: 2, name: 'Single-Question' }, { id: 1, name: 'Multi-Question' }]
    // this.apiService.getCall('asset-managements/index/'+this.apiService.buisnessId, {}).subscribe((result: any[]) => {
    //   // console.log("Response Listing :",result);
    //   if(result['success'] == true){
    //     this.departmentList = result['data']['data'];
    //     this.filterdepartmentList = result['data']; 
    //   }
    // });
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.getAsset()
    // this.simpleBarchart()
    this.getsurveyEntiroesWithMostNegative()
    this.gettop5SurveyEntries()
    this.getDepartmentList()
  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { department: null, name: null, property: null, startDate: null, endDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }


    this.ngOnInit()
  }
  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']
      this.filterpropertyList = result['data']
if(this.apiService.outletId){
  let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
}
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }

      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  // propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }



  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }
  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()
    this.selectedFiler.startDate = moment(satrtDate).format('YYYY-MM-DD'),
      this.selectedFiler.endDate = moment(endDate).format('YYYY-MM-DD'),

      this.getAsset()
    this.getsurveyEntiroesWithMostNegative()
    this.gettop5SurveyEntries()
    this.getLocationList();

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
      }
      if (this.apiService.role == 1) {
        this.businessFeatureAttendance = true
      }
    }
  }
  staticDashboardstaticDashboardChangeValue = this.storedData.staticDashboardFlag;
  staticDashboardChange() {
    if (this.staticDashboardstaticDashboardChangeValue == false || this.staticDashboardstaticDashboardChangeValue == undefined) {
      this.staticDashboardFlag = true;
      this.storedData.staticDashboardFlag = true;
      this.ngOnInit();
    } else {
      this.staticDashboardFlag = false;
      this.storedData.staticDashboardFlag = false;
      this.ngOnInit();
    }
  }
  surveyDashboard
  getAsset() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()
    // console.log(this.selectedFiler)
    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/feedbackDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.surveyDashboard = result;

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }
  downloadReport() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()

    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "download": 1,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/top5SurveyEntries', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }

  top5SurveyEntries = []
  gettop5SurveyEntries() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()

    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/top5SurveyEntries', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.top5SurveyEntries = [
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Customer's Feedback Form",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.87,
            "NoOfRespondentTo": "63",
            "outletName": "Site - A",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Tenant's Feedback Form",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.79,
            "NoOfRespondentTo": "59",
            "outletName": "Site - C",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Employee Survey",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.62,
            "NoOfRespondentTo": "56",
            "outletName": "Site - B",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Service Survey",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.51,
            "NoOfRespondentTo": "49",
            "outletName": "Site - A",
            "srNo": 1
          },
          {
            "id": "124",
            "type": "1",
            "feedback_id": "10179",
            "question": "Multiple",
            "survey_name": "Management Staff Feedback Form",
            "outlet_id": "71",
            "feedback": "63",
            "average": 4.48,
            "NoOfRespondentTo": "44",
            "outletName": "Site - B",
            "srNo": 1
          }
        ]
      } else {
        this.top5SurveyEntries = result['top5SurveyEntries'];
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }

  negativeSurveyEntries = []

  getsurveyEntiroesWithMostNegative() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()

    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/negativeSurveyEntries', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.negativeSurveyEntries = result['negativeSurveyEntries'];

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }

  openListnegativeSurveyEntries(id) {
    this.storedData.dashBoardIndex = { headers: ['Question', 'Survey Type', 'Average Score'], api: 'Feedbacks/negativeSurveyEntries', tableName: 'Bottom-5 Surveys', dataSource: ['survey_name', 'question', 'average'], apiObj: 'negativeSurveyEntries', rowCountParam: 'count' }
    this.route.navigate(['/dashboard-index/' + id])

  }

  openListtop5SurveyEntries(id) {
    this.storedData.dashBoardIndex = { headers: ['Question', 'Survey Type', 'Average Score'], api: 'Feedbacks/top5SurveyEntries', tableName: 'Top 5 Surveys', dataSource: ['survey_name', 'question', 'average'], apiObj: 'top5SurveyEntries', rowCountParam: 'count' }
    this.route.navigate(['/dashboard-index/' + id])

  }


  dn = []
  view(d, flag) {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()
    // this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      // startDate: moment(satrtDate).format('YYYY-MM-DD'),
      // endDate: moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "flag": flag,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    localStorage.setItem('body', JSON.stringify(body));
    localStorage.setItem('SurveyName', d.survey_name);
    localStorage.setItem('id', d.id);
    this.route.navigate(['/dashboard-survey-graph'])

    // this.apiService.startLoader()
    // this.apiService.postCall('Feedbacks/getNewReport/' + d.id, {}, body).subscribe((result: any[]) => {
    //   // console.log(result);

    //   this.ticketDisplayFlag=true
    //   this.displayFlag2=true
    //   this.dn=result['data'];
    //   this.feebackBarchart(result['data']['bar']);
    //   this.feedbackLine(result['data']['line']);
    //   this.apiService.stopLoader();
    // (<HTMLInputElement>document.getElementById('openSuccess')).click();
    // })

  }

  thiscketChartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['date', 'avg'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#2EB8A1', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400,
    width_units: '%'
  };

  ticketDisplayFlag = false
  feebackBarchart(data2) {
    data2.splice(0, 1);
    this.thiscketChartData = {
      type: 'ColumnChart',
      data: data2,
      chartColumns: ['date', 'avg'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#2EB8A1'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: 'Rating' },
        seriesType: 'bars',
        series: { 2: { type: 'line' } }
      },
      width: 1000,
      height: 400,
      width_units: '%'
    };
    this.ticketDisplayFlag = false;
  }

  chartDataLine = {
    type: 'Line',
    data: [],
    chartColumns: ['date', 'count'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#2EB8A1', '#EF6B6B'],
      legend: { position: 'none' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };
  displayFlag2 = false
  feedbackLine(data2) {
    // console.log(data2)
    data2.splice(0, 1)
    this.chartDataLine = {
      type: 'Line',
      data: data2,
      chartColumns: ['date', 'avg'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#2EB8A1'],
        // legend: { position: 'bottom', alignment: 'center' },
        legend: { position: 'none' },
        hAxis: { title: 'No. of Responses.' },
        vAxis: { title: '' },
        series: { 2: { type: 'line' } }
      },
      width: 500,
      height: 700
    };
    this.displayFlag2 = false

  }







  simpleBarchart() {
    var data = [{ name: "Bob", value: 33 }, { name: "Robin", value: 12 }, { name: "Anne", value: 41 }, { name: "Mark", value: 16 }, { name: "Joe", value: 59 }, { name: "Eve", value: 38 }, { name: "Karen", value: 21 }, { name: "Kirsty", value: 25 }, { name: "Chris", value: 30 }, { name: "Lisa", value: 47 }, { name: "Tom", value: 5 }, { name: "Stacy", value: 20 }, { name: "Charles", value: 13 }, { name: "Mary", value: 29 },
    { name: "1Bob", value: 33 }, { name: "1Robin", value: 12 }, { name: "1Anne", value: 41 }, { name: "1Mark", value: 16 }, { name: "1Joe", value: 59 }, { name: "1Eve", value: 38 }, { name: "1Karen", value: 21 }, { name: "1Kirsty", value: 25 }, { name: "1Chris", value: 30 }, { name: "1Lisa", value: 47 }, { name: "1Tom", value: 5 }, { name: "1Stacy", value: 20 }, { name: "1Charles", value: 13 }, { name: "1Mary", value: 29 },
    { name: "2Bob", value: 33 }, { name: "2Robin", value: 12 }, { name: "2Anne", value: 41 }, { name: "2Mark", value: 16 }, { name: "2Joe", value: 59 }, { name: "2Eve", value: 38 }, { name: "2Karen", value: 21 }, { name: "2Kirsty", value: 25 }, { name: "2Chris", value: 30 }, { name: "2Lisa", value: 47 }, { name: "2Tom", value: 5 }, { name: "2Stacy", value: 20 }, { name: "2Charles", value: 13 }, { name: "2Mary", value: 29 },




    ]
    // set the dimensions and margins of the graph
    var margin = { top: 20, right: 20, bottom: 30, left: 40 },
      width = (960 * data.length) / 14,
      height = 500 - margin.top - margin.bottom;

    // set the ranges
    var x = d3.scaleBand()
      .range([0, width])
      .padding(0.1);

    var y = d3.scaleLinear()
      .range([height, 0]);




    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    var svgAxis = d3.select("#barChartYAxis").append("svg")
      .attr("width", 50)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");
    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));



    var svg = d3.select("#barChart").append("svg")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");

    // get the data


    // format the data

    // Scale the range of the data in the domains

    x.domain(data.map(function (d) { return d.name; 1 }));
    y.domain([0, d3.max(data, function (d) { return d.value; })]);

    // append the rectangles for the bar chart
    svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", function (d) {

        return x(d.name) + 15;

      })
      .attr("width", '20px')
      .attr("y", function (d) { return y(d.value); })
      .attr("height", function (d) { return height - y(d.value); });

    // add the x Axis
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      .call(d3.axisBottom(x));

    // add the y Axis
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));



    d3.selectAll("g.myYaxis g.tick")
      .append("line")
      .attr("class", "gridline")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width)
      .attr("y2", 0);

    d3.selectAll("g.myXaxis g.tick")
      .append("line")
      .attr("class", "gridline")
      .attr("x1", 0)
      .attr("y1", -height)
      .attr("x2", 0)
      .attr("y2", 0);

  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.ZoneListData = []
          this.filteredZoneListData = null;
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
}
