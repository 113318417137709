import { Component, OnInit,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-asset-parts-add',
  templateUrl: './asset-parts-add.component.html',
  styleUrls: ['./asset-parts-add.component.css']
})
export class AssetPartsAddComponent implements OnInit {

  constructor(private fb: FormBuilder,public _location: Location,public router:Router,public route:Router ,public apiService:ApiService,) { }
  assetDetailsForm: FormGroup
  partId:any;

  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.partId = this.router.url.split("/")[3]
    // console.log(this.partId)
    
this.getAsset()
    
    this.assetDetailsForm = this.fb.group({
      selected:[null ,Validators.required],
      filterPropertySearch:null
    })

    

    
  }
  assetDetails:any;
  getAsset(){
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.router.url.split("/")[2],{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
      this.getPartsList()
    })
  }


  partsList=[]
  getPartsList(){
    this.apiService.startLoader()
    let obj={
      "businessId": this.apiService.buisnessId,
      "assetId":this.router.url.split("/")[2],
      "status":1,
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "propertyId": this.assetDetails.propertyId
      
    }
    this.apiService.postCall('parts/index' ,{},obj  ).subscribe((result: any[])=>{
      // console.log(result);
      this.partsList=result['data']
      this.apiService.stopLoader()
      // this.assetDetails = result['assetDetails'];
      // console.log(this.partId)
      if(this.partId){
        let idx =  this.partsList.findIndex(x=> x.partId == this.partId )
        // console.log(idx)
        this.assetDetailsForm.patchValue({
          selected: this.partsList[idx]
        })
        this.selectedPart = this.assetDetailsForm.value.selected
      }
      this.filterPartsList = this.partsList;
    
    })
  }
  message
  attchPart():void{
    // console.log(this.selectedPart)
    let formData ={
      partId:this.selectedPart.partId,
assetManagementId:this.router.url.split("/")[2],
authId:this.apiService.userId,
    }
    // console.log("formData : ",formData);
    this.apiService.startLoader()
    this.apiService.postCall('parts-assets/add', {},formData).subscribe((result: any[]) => {
      this.apiService.stopLoader()
     this.message = result['msg'];
      // if(result['success'] == true){
      //   this._location.back();
      // }
      this.message = result["msg"];
     
       
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      // console.log("Part Saved : ",result);
    })
  }
  selectedPart:any;


  backClicked(){this._location.back()}

  getVendorName(data){

    let str =''
    for (let d of data) str= (str!=''? str+', ' : '')+ d.name

    return str;
  }
  getValidatiom(){
    // // console.log(this.selectedPart)
    // if(this.selectedPart){
    //   return true;
    // }else{
    //   return false;
    // }
    return this.assetDetailsForm.valid
  }

  propertyFiletr=false
  partName=null
  filterPropertySearch
  filterLocationSearch: any
  filterPartsList=[]
  filterLocation(ev) {
    this.partsList = this.filterPartsList.filter((item) => {
      if (item.partName != null) {
        return item.partName.toLowerCase().includes(this.assetDetailsForm.value.filterPropertySearch.toLowerCase())
      }
    });
    // console.log(this.partsList)
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFiletr = false;
    }
    else if (btn.id != 'custom') {
      this.propertyFiletr = false;
    }
    
  }
}
