import { Component, OnInit,HostListener } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checklist-index',
  templateUrl: './checklist-index.component.html',
  styleUrls: ['./checklist-index.component.css']
})
export class ChecklistIndexComponent implements OnInit {

  constructor(
    private http: HttpClient, 
    private route: ActivatedRoute, 
    public apiService: ApiService,
    public root: Router
  ) { }
  propertyFiletr = false;
  businessId=0
  unarchivePermission=false
  ngOnInit(): void {

    var checklistIndexPagination = localStorage.getItem('checklistIndexPagination');
    if(checklistIndexPagination){
      this.activePage = parseInt(checklistIndexPagination);
    }

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Unarchive" && permission[i]['actionStatus'] == 1){
        this.unarchivePermission=true
      }
    }

    if(this.apiService.roleId==1){

    }else if(this.apiService.roleId==2 || this.apiService.roleId==3){
      this.businessId= this.apiService.buisnessId
      this.getPropertyList()
    }
    this.getBusinessList();
    this.getChecklists();
  }
  tabSelected = 'archived';
  activePage:any = 1;
  pageSize = 10;
  selectedFiler = { name: null,business:null, outletId:null, isActive:null,departmentId:null,floorPlanId:null,created:null }
  paginationArray = [];
  statusSelection:any;
  applyFlag=false;


  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }

  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    let userId=0;
    if(this.selectedFiler.business){
      this.businessId = this.selectedFiler.business.id
    }
    if(this.apiService.roleId==3){
      userId= this.apiService.authId
    }
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.businessId+"/"+userId, {}).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)
    return array
  }

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('checklistIndexPagination',d);
      // console.log("Active Page :", d)
      this.getChecklists()
    }
  }

  filterBusinessSearch
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }
  filterPropertySearch
  filterLocation(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  totalnoOfPages:any
  setPaginationArray(result:any){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  selectStatus(d){
    if('status'+d.id != this.statusSelection ) this.statusSelection = 'status'+d.id
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }

  getFilterStatus() {
    let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    if(length == 7 || !this.applyFlag) return "(No Filter Selected)"
    else if(this.applyFlag) return `(${7-length} Filter Selected)`
  }

  departmentList=[]
  checklistData=[]
  getChecklists(){
    if(this.selectedFiler.business){
      this.businessId = this.selectedFiler.business.id
    }
    let userId=0;
    if(this.apiService.roleId==3 || this.apiService.roleId==3){
      userId= this.apiService.authId
    }
    let body = {
      bussinessId:this.businessId,
      pageNo:this.activePage,
      limit:this.pageSize,
      //isArchive:1,
      sortBy:this.sortBy,
      sortType:this.sortType,
      name:this.selectedFiler.name,
      isActive:0,//this.selectedFiler.isActive,
      outletId: this.selectedFiler.outletId ? this.selectedFiler.outletId.id : null,
      departmentId:this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      floorPlanId:this.selectedFiler.floorPlanId,
      // roleId:this.apiService.roleId,
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('inspections/index/'+userId, {},body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.checklistData = result['data'];
        this.departmentList = result['departments'];
        this.setPaginationArray(result)
      }else{
        this.checklistData = [];
      }
    })
  }
  sortBy='id'
  sortType='desc'
  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    this.getChecklists();
  }

  applyFilter() {
    this.applyFlag = true;
    if(this.selectedFiler.business){
      this.businessId = this.selectedFiler.business.id
    }
    let userId=0;
    if(this.apiService.roleId==3 || this.apiService.roleId==3){
      userId= this.apiService.authId
    }
    let body = {
      bussinessId:this.businessId,
      pageNo:this.activePage,
      limit:this.pageSize,
      isArchive:1,
      name:this.selectedFiler.name,
      isActive:this.selectedFiler.isActive,
      outletId: this.selectedFiler.outletId ? this.selectedFiler.outletId.id : null,
      departmentId:this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      floorPlanId:this.selectedFiler.floorPlanId,
      sortBy:this.sortBy,
      sortType:this.sortType,
      roleId:this.apiService.roleId
    } 
    // console.log(body)
    this.apiService.startLoader()
    this.apiService.postCall('inspections/index/'+userId, {},body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.checklistData = result['data'];
        this.setPaginationArray(result)
      }else{
        this.checklistData = [];
        this.setPaginationArray(result)
      }
    })

  }
  changePage(ev) {
    //console.log(this.pageSize)
    this.getChecklists()
  }
  goBack(){
    this.root.navigate(['/setup'])
  }
  clear() {
    this.activePage=1
    this.jumpToPage=1
    this.selectedFiler = { name: null,business:null, outletId:null, isActive:null,departmentId:null,floorPlanId:null,created:null }
    this.filterSelect = false;
    this.getChecklists();
   
  }
  selectedInspectionIds=[]
  selectAllAssets(ev){
    this.selectedInspectionIds = []
    if (ev.target.checked) {
      this.checklistData.map(x => x.selected = true)
      for (let part of this.checklistData) { 
        // console.log(part);
        this.selectedInspectionIds.push(part.id)
      }
    } else {
      this.checklistData.map(x => x.selected = false)
      this.selectedInspectionIds = []
    }
    // console.log(this.selectedInspectionIds)
  }
  selectProperty(ev, templateId : any) {
    // console.log(ev.target.checked, templateId)
    if (ev.target.checked) {
      this.selectedInspectionIds.push(templateId)
    }else {
      this.selectedInspectionIds.splice(this.selectedInspectionIds.findIndex(x => x == templateId), 1)
    }
  }

  message
  goToTransfer(){
    this.applyFlag = true;
    let body = {
      inspectionIds:this.selectedInspectionIds,
      isArchive:0,
      isActive:1,
    } 
    // console.log(body)
    this.apiService.startLoader()
    this.apiService.postCall('inspections/changeToUnArchive', {},body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.getChecklists();
        this.message ="Checklist Unarchived Successfully.";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        this.message =result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
    })
  }
  businessFilter = false;
  filterSelect = false;
  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      // this.showFilter = false
      //this.filterSelect = false;
      this.businessFilter = false;
      this.propertFilter = false;
    }else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.businessFilter = false;
      this.propertFilter = false;
    }
  }
  closeAll() {
    this.businessFilter = false;
    this.propertFilter = false;
  }

}
