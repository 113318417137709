import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }

  ngOnInit(): void {
  }
  navigateTo(rout){
    this.route.navigate([rout])
  }
  getDepartmentList() {
    this.apiService.startLoader()
    this.applyFlag = false;
    this.selectedFiler = { name: null }
    let body = {
      pageNo: this.pageNo,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,
    }
    this.apiService.postCall('types/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      this.departmentList = result['data']
      this.setPaginationArray(result)
      this.apiService.stopLoader()
      // console.log(this.departmentList);
    })
  }
  message='';
  changeStatus(id,flag) {
    if(flag){
      flag=1;
    }else{
      flag=0;
    }
    let obj = {
      id:id,
      isActive:flag,
      authId:this.apiService.authId
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('types/changeStatus', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.getDepartmentList();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })

  }

  goBack(){
    this.route.navigate(['/setup'])
  }
  paginationArray = []

  pageSize = 10;
  pageNo: any = 1
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.paginationArray = []
    for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    // console.log(this.paginationArray)
  }
  departmentList = []
  selectedAssets = []

  pagination(data) {
    if (data != 0 && data <= this.paginationArray.length)
      this.pageNo = data
    this.getDepartmentList()
  }

  selectedFiler = { name: null }
  sortSelected: any = 'name'
  sort: any = 'asc'

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getDepartmentList()
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.pageNo = 1;
    let body = {
      pageNo: this.pageNo,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,

    }
    this.apiService.startLoader()
    this.apiService.postCall('types/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.departmentList = result['data']
      this.setPaginationArray(result)
      this.apiService.stopLoader()

    })
  }
  
  getFilterStatus() {
    // console.log(JSON.stringify(this.selectedFiler))
    let length = JSON.stringify(this.selectedFiler).split(null).length - 1

    if (length == 1 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${1 - length} Filter Selected)`
  }


  assetList = []

  navigateToDetails(d) {
    this.route.navigate(['/meter-detail/' + d.meterId])
  }
  openDropdown(d){
    if($(".stama"+d).css('display') == 'none'){
      $(".stama"+d).css("display","block");
    }else{
      $(".stama"+d).css("display","none");
    }
  }

  deactiveSelected() { }
  dublicateSelected() { }
  selectAllAssets

  filterSelect = false;
}
