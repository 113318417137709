import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-insurance',
  templateUrl: './edit-insurance.component.html',
  styleUrls: ['./edit-insurance.component.css']
})
export class EditInsuranceComponent implements OnInit {
  @Input() insuranceDetails: any;
  @Input() statusList: any;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();
  constructor(private fb: FormBuilder, public route: Router, public storedData: StoredData, public apiService: ApiService,) { }
  // assetDetailsForm: FormGroup

  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false,
    dateType: 'start'
  }

  existfileName
  insuranceDetailsForm = this.fb.group({
    insuranceId: [null],
    insuranceProvider: [null],
    conditionIns: [null],
    status: [null],
    type: [null],
    startDate: [null],
    endDate: [null],
    insuranceCost: [null],
    assetId: [null],
    outletId: [null],
    businessId:[null]
  });
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {
    // document.body.style.overflow = 'hidden';
    // console.log(this.insuranceDetails)
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  navigateToFileUpload(){
    this.route.navigate([`insurance-files/${this.insuranceDetails.insuranceId}`])
  }

  conditionListing = [{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]
  ngOnChanges() {
    // console.log(this.insuranceDetails);
    if (this.insuranceDetails) {
      this.insuranceDetailsForm.patchValue({
        insuranceId: this.insuranceDetails.insuranceId,
        insuranceProvider: this.insuranceDetails.insuranceProvider,
        conditionIns: this.insuranceDetails.conditionIns,
        insuranceCost: this.insuranceDetails.insuranceCost,
        status: this.insuranceDetails.status,
        startDate: new Date(this.insuranceDetails.startDate),
        endDate: new Date(this.insuranceDetails.endDate),
        type: this.insuranceDetails.type,
        outletId: this.insuranceDetails.outletId,
        businessId: Number(this.insuranceDetails.businessId)
      })
      

      this.existfileName = this.insuranceDetails.fileName
      this.submitAsset = [];
      this.selectedAssets = [];
      for (let d of this.insuranceDetails.insurancePropertiesAssets) {
        this.selectedAssets.push({ id: d.assetManagementId, assetName: d.assetManagementName });
        this.submitAsset.push(d.assetManagementId);
      }
      this.getAssetByIdList(this.insuranceDetails.outletId);
      // console.log(this.selectedAssets)
      this.fileName=null
      if(this.apiService.roleId==1){
        this.getBusinessList()
        this.getPropertyList();
        this.insuranceDetailsForm.patchValue({businessId:Number(this.insuranceDetails.businessId)});
      }else if(this.apiService.roleId==2 || this.apiService.roleId==3){
        this.insuranceDetailsForm.patchValue({businessId:this.apiService.buisnessId});
        this.getPropertyList();
      }
    }
  }

  bussnissList = [] 
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  message
  msg
  submit() {
    // console.log(this.insuranceDetailsForm.value)
    /*let imagesArray:any =[]
    for(let d of this.attchments)imagesArray.push(d.file)
    let obj = {
      insuranceProvider:this.insuranceDetailsForm.value.insuranceProvider,
      startDate:moment(this.insuranceDetailsForm.value.startDate).format('YYYY-MM-DD'),
      endDate:moment(this.insuranceDetailsForm.value.endDate).format('YYYY-MM-DD'),
      conditionIns:this.insuranceDetailsForm.value.conditionIns,
      insuranceCost:this.insuranceDetailsForm.value.insuranceCost,
      status: this.insuranceDetailsForm.value.status,
      fileName: imagesArray[0] ? imagesArray[0] : null,
      insuranceAssets:this.submitAsset,
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      type: this.insuranceDetails.type,
      outletId:this.insuranceDetailsForm.value.outletId,
    }*/
    if(this.insuranceDetailsForm.value.startDate && this.insuranceDetailsForm.value.startDate > this.insuranceDetailsForm.value.endDate){
      this.insuranceDetailsForm.patchValue({endDate : null})
      this.msg = "End Date should be greater than Start date";
      return;
    }

    let testData: FormData = new FormData();
    testData.append('businessId', this.apiService.buisnessId);
    testData.append('status', this.insuranceDetailsForm.value.status);
    testData.append('insuranceProvider', this.insuranceDetailsForm.value.insuranceProvider);
    testData.append('startDate', moment(this.insuranceDetailsForm.value.startDate).format('YYYY-MM-DD'));
    testData.append('endDate', moment(this.insuranceDetailsForm.value.endDate).format('YYYY-MM-DD'));
    // testData.append('conditionIns', this.insuranceDetailsForm.value.conditionIns);
    if(this.uploadedFiles){
      testData.append('fileName', this.uploadedFiles);
    }else{
      testData.append('fileName', '');
    }
    testData.append('insuranceCost', this.insuranceDetailsForm.value.insuranceCost);
    testData.append('userTo', "");
    testData.append('userToName', "");
    testData.append('type', this.insuranceDetailsForm.value.type);
    testData.append('authId', this.apiService.authId);
    testData.append('outletId', this.insuranceDetailsForm.value.outletId);
    testData.append('insuranceAssets', JSON.stringify(this.submitAsset));
  
    // console.log(testData)
    this.apiService.startLoader()
    this.apiService.postCall('insurances/edit/' + this.insuranceDetails.insuranceId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.updateData.emit();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      document.getElementById('dismissPopUp').click()

    })

  }

  backClicked() { this.route.navigate(['/insurance-details/'+ this.insuranceDetails.insuranceId]) }

  fileName
  uploadedFiles
  attchments = []
  fileChange(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0]; 
      // console.log(file)
      file = file
      this.uploadedFiles = file
      this.fileName = file.name
      this.attchments.push({ file: file, fileName: file.name })
    }
  }
  removeImage() {
    this.fileName = null;
    this.attchments = [];
  }

  propertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj={
      "businessId": this.apiService.buisnessId,
      "userId": this.apiService.userId,
      "roleId":this.apiService.roleId
    }
    this.apiService.postCall('outlets/outlet-list/' + this.insuranceDetailsForm.value.businessId, {},obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.propertyList = result['data']
    })
  }

  selectedAssets = []
  submitAsset = []
  selectAssets() {
    if(this.submitAsset.indexOf(this.insuranceDetailsForm.value.assetId.id) == -1){
    // console.log(this.insuranceDetailsForm.value.assetId);
    this.selectedAssets.push(this.insuranceDetailsForm.value.assetId)
    this.submitAsset.push(this.insuranceDetailsForm.value.assetId.id)
    this.insuranceDetailsForm.patchValue({
      assetId:null
    })
  }else{
    this.insuranceDetailsForm.patchValue({
      assetId:null
    })
  }
  }
  removeAssets(d) {
    let idx = this.selectedAssets.findIndex(x => x == d)
    this.selectedAssets.splice(idx, 1)

    let idx1 = this.submitAsset.findIndex(x => x == d)
    this.submitAsset.splice(idx1, 1)
  }
  assetList = []
  getAssetList(t) {
    this.selectedAssets=[]
    var outletId = t.target.value;
    this.apiService.startLoader()
    this.apiService.getCall('insurancePropertiesAssets/assetList/' + this.insuranceDetailsForm.value.buisnessId + '/' + outletId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = result['data']
    })
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  getAssetByIdList(outletId) {
    this.apiService.startLoader()
    this.apiService.getCall('insurancePropertiesAssets/assetList/' + this.apiService.buisnessId + '/' + outletId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = result['data']
    })
  }

  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

  getValidation() {
    // console.log(this.insuranceDetailsForm.valid)
    return (this.insuranceDetailsForm.valid && !this.validateDate(this.insuranceDetailsForm.value.startDate,this.insuranceDetailsForm.value.endDate))
  }
  validateDate(satrtDate , endDate){
    // console.log(satrtDate , endDate)
    if(satrtDate && endDate){
      if(endDate <= satrtDate) return true
      else return false
      }
  }

  getdiasableAsset(d){
    let idx = this.selectedAssets.findIndex(x=> x.id == d.id)
 
    if(idx != -1) return true
  }
}
