import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
@Component({
  selector: 'setup-settings',
  templateUrl: './setup-settings.component.html',
  styleUrls: ['./setup-settings.component.css']
})
export class SetupSettingsComponent implements OnInit {

  constructor(public route: Router,
    public apiService:ApiService) { }

  ngOnInit(): void {
    this.getBusinessSettings();
    
  }

  curencyList=[];
  regionList=[];
  region=null;
  cureency=null;
  fileName
  data
  getBusinessSettings(){
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessSettings/'+this.apiService.buisnessId, {}).subscribe((result: any[]) => {
     if(result['success']){
      this.data = result['data'];
      this.region=this.data.region_id;
      this.cureency=this.data.currency_id;
     }
      this.apiService.stopLoader()
      this.getCurencys();
    })
  }



  getCurencys(){
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getRegionCurrencys', {}).subscribe((result: any[]) => {
      // console.log(result);
      if(result['success']){
        this.regionList = result['data']['regions'];
        this.curencyList= result['data']['currency'];
      }
      this.apiService.stopLoader()
    })
  }

  chekVal(){
    if(this.region == null || this.cureency == null){
      return true;
    }
    return false;
  }
  message
  saveData(){
    let formData = {
      businessId : this.apiService.buisnessId,
      regionId : this.region,
      currencyId:this.cureency
    }
    this.apiService.startLoader()
    this.apiService.postCall('businesses/saveBusinessSettings', {},formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = 'Business settings saved successfully';
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }

}
