import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-roles-add',
  templateUrl: './roles-add.component.html',
  styleUrls: ['./roles-add.component.css']
})
export class RolesAddComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService:ApiService,
    public storedData:StoredData,
  ) { }
  rolesForm: FormGroup
  message:any;
  roleId:any;
  roleId2:any;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.roleId2 = this.route.url.split("/")[2];
    if(this.roleId2){
      this.getRoles(this.roleId2);
    }
    this.rolesForm = this.fb.group({
      name:[null , Validators.required],
      isActive:1,
      type:[null , Validators.required],
      origionalType:[null,Validators.required],
      businessId:[null,Validators.required]
    });
    if(this.apiService.buisnessId){
      this.rolesForm.patchValue({businessId:this.apiService.buisnessId});
    }
    this.getBusinessList();
  }

  businessList=[]
  getBusinessList() {
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      this.businessList= result['data'];
    });
  }

  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }

  getValidation(){
    return (this.rolesForm.valid);
  }

  getRoles(roleId){
    this.apiService.startLoader()
    this.apiService.getCall('roles/view/'+roleId, {}).subscribe((result: any[]) => {
        // console.log(result);
        var data= result['data'][0];
        this.apiService.stopLoader()
        this.rolesForm.patchValue({name:data.name,isActive:data.isActive,type:data.type,origionalType:data.origionalType});
    });
  }
  submitBusinessForm():void{
    let formData = this.rolesForm.value;
    this.apiService.startLoader()
    var url ='roles/add';
    if(this.roleId2){
      url ='roles/edit/'+this.roleId2;
    }
    this.apiService.postCall(url, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.message = result['msg'];
        if(result['roleId']){
          this.roleId = result['roleId'];
        }
        if(this.roleId2){
          this.roleId=this.roleId2;
        }
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }
  goToIndex(){
    this.route.navigate(['/roles-index']);
  }


}