import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public formBuilder: FormBuilder,

  ) { }

  vendorForm = this.formBuilder.group({
    vendorName: [null, Validators.required],
    authId: this.apiService.authId,
    email: [null,[Validators.required, Validators.email]],
    mobile: [null, [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    partId: [null],
  });

  partsId
  ngOnInit(): void {
    this.partsId = this.router.url.split("/")[2]
  }
  goBack(): void {
    this.router.navigate(['/parts-vendors/' + this.partsId])
  }

  getValidation() {
    return (this.vendorForm.valid)
  }

  message
  savePart(): void {
    let testData: FormData = new FormData();
    testData.append('vendorName', this.vendorForm.value.vendorName);
    testData.append('authId',this.apiService.authId);
    testData.append('email',this.vendorForm.value.email);
    testData.append('mobile',this.vendorForm.value.mobile);
    testData.append('partId',this.partsId);
    this.apiService.startLoader()
    this.apiService.postCall('vendors/add',{},testData).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess1')).click();
    })
  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  public onlyText =[!/^[a-zA-Z]*$/g]
  backClicked() {
    this.router.navigate(['/parts-vendors/' + this.partsId])
  }

}
