import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app.routing';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './common/services/apiCalls.service';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartsInventoryComponent } from './parts/parts-inventory/parts-inventory.component';

import { MigrateAssetComponent } from './migrate-asset/migrate-asset.component';
import { PartsConsumeComponent } from './part-consume/part-consume.component';
// import SignaturePad from 'signature_pad';
// import { MaterialModule } from '@angular/material';
// import { DatePickerModule } from 'angular-material-datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import * as $ from "jquery";
import {
  IgxDateRangePickerModule,
  IgxInputGroupModule,
  IgxTimePickerModule
} from "igniteui-angular";
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { ViewDestinationLocationComponent } from './view-destination-location/view-destination-location.component';
import { EditAssetComponent } from './edit-asset/edit-asset.component';
import { AmcSupportHistoryComponent } from './amc-support-history/amc-support-history.component';
import { AmcSupportAddNewComponent } from './amc-support-add-new/amc-support-add-new.component';
import { AddNewAssetComponent } from './add-new-asset/add-new-asset.component';
import { PreventiveMaintenaceComponent } from './preventive-maintenace/preventive-maintenace.component';
import { AssetsDetailsPMCreateTemplateComponent } from './assets-details-pm-create-template/assets-details-pm-create-template.component';
import { AssetsDetailsDepreciationComponent } from './assets-details-depreciation/assets-details-depreciation.component';
import { NotificationSettingsComponent } from './assets-details-notification-settings/assets-details-notification-settings.component';
import { LogsFilesListingComponent } from './logs-files-listing/logs-files-listing.component';
import { TicketsListingComponent } from './tickets-listing/tickets-listing.component';
import { NgxFileDropModule } from 'ngx-file-drop';

import { ChildAssetNewComponent } from './child-asset-new/child-asset-new.component';
import { ReportComponent } from './report/report.component';
import { MetersListComponent } from './meters-list/meters-list.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

import { MeterDetailsComponent } from './meter-details/meter-details.component';
import { MeterDetailsEditComponent } from './meter-details-edit/meter-details-edit.component';
import { MeterAddNewComponent } from './meter-add-new/meter-add-new.component';
import { MeterTriggerListComponent } from './meter-trigger-list/meter-trigger-list.component';
import { MeterHistoryComponent } from './meter-history/meter-history.component';
import { MeterAddTriggerComponent } from './meter-add-trigger/meter-add-trigger.component';
import { InspectionListComponent } from './inspection-list/inspection-list.component';
import { InspectionScheduleListComponent } from './inspection-schedule-list/inspection-schedule-list.component';
import { InspectionChecklistListComponent } from './inspection-checklist-list/inspection-checklist-list.component';
import { PmTemplateAddComponent } from './pm-template-add/pm-template-add.component';
import { InspectionScheduleAddComponent } from './inspection-schedule-add/inspection-schedule-add.component';
import { AssetPartsListComponent } from './asset-parts-list/asset-parts-list.component';
import { AssetPartsAddComponent } from './asset-parts-add/asset-parts-add.component';
import { TicketListComponent } from './tickets/ticket-list/ticket-list.component';
import { TicketListCustomerComponent } from './tickets/ticket-list-customer/ticket-list-customer.component';
import { IndividualViewReportComponent } from './individual-view-report/individual-view-report.component';
import { IndividualViewReportNewComponent } from './individual-view-report-new/individual-view-report-new.component';
import { CommonModule, DatePipe } from "@angular/common";
import { PmTemplatesComponent } from './pm-templates/pm-templates.component';
import { PmTemplatesEditComponent } from './pm-templates-edit/pm-templates-edit.component';
import { PmViewComponent } from './pm-view/pm-view.component';
import { PmViewNewComponent } from './pm-view-new/pm-view-new.component';
import { PmIndexComponent } from './pm-index/pm-index.component';
import { PartTransferComponent } from './part-transfer/part-transfer.component';
import { PartTransferViewComponent } from './part-transfer-view/part-transfer-view.component';
import { PartsAddComponent } from './parts/parts-add/parts-add.component';

import { DashboardCeoComponent } from './dashboard-ceo/dashboard-ceo.component';
import { EditAmcComponent } from './edit-amc/edit-amc.component';
import { AssociatePartComponent } from './associate-part/associate-part.component';
import { RaiseTicketComponent } from './raise-ticket/raise-ticket.component';
import { TicketEscalationComponent } from './tickets/ticket-escalation/ticket-escalation.component';
import { TicketViewComponent } from './tickets/ticket-view/ticket-view.component';
import { InspectionScheduleViewComponent } from './inspection-schedule-view/inspection-schedule-view.component';
import { InspectionChecklistViewComponent } from './inspection-checklist-view/inspection-checklist-view.component';
import { InspectionChecklistCreateComponent } from './inspection-checklist-create/inspection-checklist-create.component';
import { TextMaskModule } from 'angular2-text-mask';
import { PmViewTemplatesComponent } from './pm-view-templates/pm-view-templates.component';
import { InspectionDublicateComponent } from './inspection-dublicate/inspection-dublicate.component';
import { SurveyDublicateComponent } from './survey-dublicate/survey-dublicate.component';
import { QrHtmlComponent } from './common/qr-html/qr-html.component';
import { QrPermitHtmlComponent } from './common/qr-permit-html/qr-permit-html.component';
import { QrCodeComponent } from './common/qr-code/qr-code.component';
import { SurveyIndexComponent } from './survey-index/survey-index.component';
import { SurveyAddComponent } from './survey-add/survey-add.component';
import { FeedbackIndexComponent } from './feedback-index/feedback-index.component';
import { FeedbackAddComponent } from './feedback-add/feedback-add.component';
import { EditWarrantyComponent } from './edit-warranty/edit-warranty.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
// import { PmIndexTemplatesComponent } from './pm-index-templates/pm-index-templates.component';
import { DepartmentListComponent } from './department-list/department-list.component';
import { DepartmentAddNewComponent } from './department-add-new/department-add-new.component';
import { DepartmentEditComponent } from './department-edit/department-edit.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SetupComponent } from './setup/setup.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { InsuranceListComponent } from './insurance-list/insurance-list.component';
import { InsuranceAddComponent } from './insurance-add/insurance-add.component';
import { InsuranceDetailsComponent } from './insurance-details/insurance-details.component';
import { EditInsuranceComponent } from './edit-insurance/edit-insurance.component';
import { InsuranceNotificationSettingComponent } from './insurance-notification-setting/insurance-notification-setting.component';
import { AssetCoveredComponent } from './asset-covered/asset-covered.component';
import { UserIndexComponent } from './user-index/user-index.component';
import { UserAddComponent } from './user-add/user-add.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { PropertyIndexComponent } from './property-index/property-index.component';
import { PropertyAddComponent } from './property-add/property-add.component';
import { LocationChecklistsComponent } from './location-checklists/location-checklist-add.component';
import { ChecklistIndexComponent } from './checklist-index/checklist-index.component';
import { InsuranceFilesComponent } from './insurance-files/insurance-files.component';
import { SubUserIndexComponent } from './sub-user-index/sub-user-index.component';
import { SubUserEditComponent } from './sub-user-edit/sub-user-edit.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { PropertyLocationsComponent } from './property-locations/property-locations.component';
import { PropertyUsersComponent } from './property-users/property-users.component';
import { TemplateLibraryComponent } from './template-library/template-library.component';
import { PartsDetailsComponent } from './parts/parts-details/parts-details.component';
import { EditPartsComponent } from './parts/edit-parts/edit-parts.component';
import { PartsVendorsComponent } from './parts/parts-vendors/parts-vendors.component';
import { EditVendorsComponent } from './parts/edit-vendors/edit-vendors.component';
// import { PartsAssetsComponent } from './parts/parts-assets/parts-assets.component';
import { PartsLogsComponent } from './parts/parts-logs/parts-logs.component';
import { PartsReportsComponent } from './parts/parts-reports/parts-reports.component';
import { PartsNotifyComponent } from './parts/parts-notify/parts-notify.component';
import { PartsLocationHistoryComponent } from './parts/parts-location-history/parts-location-history.component';
import { PartsAssetsComponent } from './parts/parts-assets/parts-assets.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MeterDetailsHeaderComponent } from './meter-details-header/meter-details-header.component';
import { AssetDetailsHeaderComponent } from './asset-details-header/asset-details-header.component';
import { UpdateQuantityComponent } from './parts/update-quantity/update-quantity.component';
import { DashboardChecklistComponent } from './dashboard-checklist/dashboard-checklist.component';
import { DashboardChecklistPercentageComponent } from './dashboard-checklist-percentage/dashboard-checklist-percentage.component';
import { D3PlotsComponent } from './d3-plots/d3-plots.component';
import { DashboardTicketComponent } from './dashboard-ticket/dashboard-ticket.component';
import { TemplateCreateComponent } from './templates/template-create/template-create.component';
import { AddVendorComponent } from './parts/add-vendor/add-vendor.component';
import { TemplateViewComponent } from './templates/template-view/template-view.component';
import { SchedulesHolidaysComponent } from './schedules-holidays/schedules-holidays.component';

import { DateTimeAdapter,  OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
//import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
//import { OwlMomentDateTimeModule } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module';

import { AgGridModule } from 'ag-grid-angular';



export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY hh:mm a',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'hh:mm',
  monthYearLabel: 'MMM-YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY'
};



import { TemplateIndexComponent } from './templates/template-index/template-index.component';
import { DashboardAssetComponent } from './dashboard-asset/dashboard-asset.component';
import { DashboardPartsComponent } from './dashboard-parts/dashboard-parts.component';
import { DashboardSurveyComponent } from './dashboard-survey/dashboard-survey.component';
import { DashboardPmComponent } from './dashboard-pm/dashboard-pm.component';
import { DashboardIndexComponent } from './dashboard-index/dashboard-index.component';
import { DashboardChecklistIndexComponent } from './dashboard-checklist-index/dashboard-checklist-index.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { BusinessIndexComponent } from './business-index/business-index.component';
import { BusinessAddComponent } from './business-add/business-add.component';
import { BusinessDeleteComponent } from './business-delete/business-delete.component';
import { BusinessEditComponent } from './business-edit/business-edit.component';
import { BusinessAdminAddComponent } from './business-admin-add/business-admin-add.component';
import { BusinessFeaturesComponent } from './business-features/business-features.component';
import { BusinessAdminIndexComponent } from './business-admin-index/business-admin-index.component';
import { BusinessPropertyComponent } from './business-property/business-property.component';
import { ReportsComponent } from './reports/reports.component';
import { AutomatedListComponent } from './automated-list/automated-list.component';
import { StockStatementsComponent } from './stock-statements/stock-statements.component';
import { RecivedInventoryExpenseComponent } from './recived-inventory-expense/recived-inventory-expense.component';
import { PartsInventoryHeaderComponent } from './parts-inventory-header/parts-inventory-header.component';
import { QrMultipleHtmlComponent } from './qr-multiple-html/qr-multiple-html.component';
import { TransferReportComponent } from './transfer-report/transfer-report.component';
import { AssetExpenseReportComponent } from './asset-expense-report/asset-expense-report.component';
import { AssetDueReturnComponent } from './asset-due-return/asset-due-return.component';
import { AssetDiscardSummaryComponent } from './asset-discard-summary/asset-discard-summary.component';
import { AssetTransferReportsComponent } from './asset-transfer-reports/asset-transfer-reports.component';
import { InventoryMovingComponent } from './inventory-moving/inventory-moving.component';
import { VendorListReportComponent } from './vendor-list-report/vendor-list-report.component';
import { AssetVendorListComponent } from './asset-vendor-list/asset-vendor-list.component';
import { PartVendorListComponent } from './part-vendor-list/part-vendor-list.component';
import { TicketStatusReportComponent } from './ticket-status-report/ticket-status-report.component';
import { AddSubUserComponent } from './add-sub-user/add-sub-user.component';
import { SetupSettingsComponent } from './setup-settings/setup-settings.component';
// import { DashboardPartComponent } from './dashboard-part/dashboard-part.component';
import { RolesIndexComponent } from './roles/roles-index/roles-index.component';
import { RolesAddComponent } from './roles/roles-add/roles-add.component';
import { RolesViewPermissionComponent } from './roles/view-permissions/view-permissions.component';
import { QrGenerateTicketComponent } from './qr-generate-ticket/qr-generate-ticket.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WarrantySupportHistoryComponent } from './warranty-support-history/warranty-support-history.component';
import { WarrantySupportHistoryAddComponent } from './warranty-support-history-add/warranty-support-history-add.component';
import { TrainingsListComponent } from './trainings-list/trainings-list.component';
import { TrainingsAddComponent } from './trainings-add/trainings-add.component';
import { TrainingsAssignUserComponent } from './trainings-assign-user/trainings-assign-user.component';

import { GraphListComponent } from './graph-list/graph-list.component';
import { CheckListScoreGraphListComponent } from './checklist-score-graph-list/checklist-score-graph-list.component';
import { ChecklistScheduleScoreListComponent } from './checklist-schedule-score-list/checklist-schedule-score-list.component';
import { ChecklistSectionScoreListComponent } from './checklist-section-score-list/checklist-section-score-list.component';
import { PermitListComponent } from './permit-setting/permit-list.component';
import { PermitTenantAddComponent } from './permit-setting/permit-tenant/permit-tenant-add/permit-tenant-add.component';
import { PermitWorkAddComponent } from './permit-setting/permit-work/permit-work-add/permit-work-add.component';
import { PermitWorkListComponent } from './permit-setting/permit-work/permit-work-list/permit-work-list.component';
import { PermitAssociatedAddComponent } from './permit-setting/permit-associated/permit-associated-add/permit-associated-add.component';
import { PermitAssociatedListComponent } from './permit-setting/permit-associated/permit-associated-list/permit-associated-list.component';
import {
  PermitQuestionCheckpointListComponent
} from './permit-setting/permit-question-checkpoint/permit-question-checkpoint-list/permit-question-checkpoint-list.component';
import {
  PermitQuestionCheckpointAddComponent
} from './permit-setting/permit-question-checkpoint/permit-question-checkpoint-add/permit-question-checkpoint-add.component';
import {
  PermitQuestionAddComponent
} from './permit-setting/permit-question-checkpoint/permit-question-checkpoint-add/permit-question-add/permit-question-add.component';
import {
  PermitSubQuestionAddComponent
} from './permit-setting/permit-question-checkpoint/permit-question-checkpoint-add/permit-sub-question-add/permit-sub-question-add.component';
import { PermitTenantListComponent } from './permit-setting/permit-tenant/permit-tenant-list/permit-tenant-list.component';
import { AssetQrHtmlComponent } from './asset-qr-html/asset-qr-html.component';
import { MasterPropertyComponent } from './master-property/master-property.component';
import { PropertyZoneListComponent } from './master-property/property-zone/property-zone-list/property-zone-list.component';
import { PropertyZoneAddComponent } from './master-property/property-zone/property-zone-add/property-zone-add.component';
import { PropertyStatesAddComponent } from './master-property/property-states/property-states-add/property-states-add.component';
import { PropertyStatesListComponent } from './master-property/property-states/property-states-list/property-states-list.component';
import { PropertyClientListComponent } from './master-property/property-client/property-client-list/property-client-list.component';
import { PropertyClientAddComponent } from './master-property/property-client/property-client-add/property-client-add.component';
import { PropertyClusterAddComponent } from './master-property/property-cluster/property-cluster-add/property-cluster-add.component';
import { PropertyClusterListComponent } from './master-property/property-cluster/property-cluster-list/property-cluster-list.component';
import { PropertyDistrictsAddComponent } from './master-property/property-districts/property-districts-add/property-districts-add.component';
import { PropertyDistrictsListComponent } from './master-property/property-districts/property-districts-list/property-districts-list.component';
import { PropertyCitiesListComponent } from './master-property/property-cities/property-cities-list/property-cities-list.component';
import { PropertyCitiesAddComponent } from './master-property/property-cities/property-cities-add/property-cities-add.component';
import { PropertySitesAddComponent } from './master-property/property-sites/property-sites-add/property-sites-add.component';
import { PropertySitesListComponent } from './master-property/property-sites/property-sites-list/property-sites-list.component';
import { AttendanceListComponent } from './attendance-management-master/attendance-list/attendance-list.component';
import { ShipmentsListComponent } from './shifts/shipments/shipments-list/shipments-list.component';
import { ShipmentsAddComponent } from './shifts/shipments/shipments-add/shipments-add.component';
import { PermitIndexListComponent } from './permits/permit-list/permit-index-list.component';
import { PermitAddComponent } from './permits/permit-add/permit-add.component';
import { PermitViewComponent } from './permits/permit-view/permit-view.component';
import { TrainingsDetailsComponent } from "./trainings-details/trainings-details.component";
import { TicketViewNewComponent } from './tickets/ticket-view-new/ticket-view-new.component';
import { DashboardAttendanceComponent } from './dashboard-attendance/dashboard-attendance.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { GoogleChartsModule } from 'angular-google-charts'
import { EmployeeDesignationAddComponent } from './employee/employee-designation/employee-designation-add/employee-designation-add.component';
import {
  EmployeeDesignationListComponent
} from './employee/employee-designation/employee-designation-list/employee-designation-list.component';
import { EmployeeDepartmentAddComponent } from './employee/employee-department/employee-department-add/employee-department-add.component';
import { EmployeeDepartmentListComponent } from './employee/employee-department/employee-department-list/employee-department-list.component';
import { EmployeeDbListComponent } from './employee/employee-db/employee-db-list/employee-db-list.component';
import { DashboardSurveyGraphComponent } from './dashboard-survey-graph/dashboard-survey-graph.component';
import { PropertyQrHtmlComponent } from './property-qr-html/property-qr-html.component';
import { PartsDashboardIndexComponent } from './parts-dashboard-index/parts-dashboard-index.component';
import { FilterComponentComponent } from './filter-component/filter-component.component';
import { EmployeeSurveyAddComponent } from './employee-survey-add/employee-survey-add.component';
import { EmployeeSurveyComponent } from './employee-survey/employee-survey.component';
import { EmployeeSurveyViewComponent } from './employee-survey-view/employee-survey-view.component';
import { EmployeeQuestionDetailComponent } from './employee-question-detail/employee-question-detail.component';
import { LostArticleIndexListComponent } from './lost-and-found/lost-articles/lost-article-index-list.component';
import { FoundArticleIndexListComponent } from './lost-and-found/found-articles/found-article-index-list.component';
import { LostArticleAddComponent } from './lost-and-found/lost-article-add/lost-article-add.component';
import { FoundArticleAddComponent } from './lost-and-found/found-article-add/found-article-add.component';
import { LostArticleViewComponent } from './lost-and-found/lost-article-view/lost-article-view.component';
import { FoundArticleViewComponent } from './lost-and-found/found-article-view/found-article-view.component';
import { PermitsListComponent } from './permits-list/permits-list.component';
import { inspectionMonthlyDataComponent } from './inspection-monthly-data/inspection-monthly-data.component';
import { ConsolidatedReportComponent } from './consolidated-report/consolidated-report.component';
import { PartsConsumedReportsComponent } from './parts-consumed-reports/parts-consumed-reports.component';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { SchedulesQrHtmlComponent } from './schedules-qr-html/schedules-qr-html.component';
import { InspectionsReportsComponent } from './inspections-reports/inspections-reports.component';
import { TicketsReportsComponent } from './tickets-reports/tickets-reports.component';
import { AssetsReportsComponent } from './assets-reports/assets-reports.component';
import { PartReportsComponent } from './part-reports/part-reports.component';
import { PmViewCalendarComponent } from './pm-view-calendar/pm-view-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    HomeComponent,
    PartsInventoryComponent,
    MigrateAssetComponent,
    PartsConsumeComponent,
    AssetDetailsComponent,
    ViewDestinationLocationComponent,
    EditAssetComponent,
    AmcSupportHistoryComponent,
    AmcSupportAddNewComponent,
    AddNewAssetComponent,
    PreventiveMaintenaceComponent,
    AssetsDetailsPMCreateTemplateComponent,
    AssetsDetailsDepreciationComponent,
    NotificationSettingsComponent,
    LogsFilesListingComponent,
    TicketsListingComponent,

    ChildAssetNewComponent,

    ReportComponent,

    MetersListComponent,

    MeterDetailsComponent,

    MeterDetailsEditComponent,

    MeterAddNewComponent,

    MeterTriggerListComponent,

    MeterHistoryComponent,
    MeterAddTriggerComponent,
    InspectionListComponent,
    InspectionScheduleListComponent,
    InspectionChecklistListComponent,
    PmTemplateAddComponent,
    InspectionScheduleAddComponent,
    AssetPartsListComponent,
    AssetPartsAddComponent,
    TicketListComponent,
    TicketListCustomerComponent,
    IndividualViewReportComponent,
    IndividualViewReportNewComponent,
    PmTemplatesComponent,
    PmTemplatesEditComponent,
    PmViewComponent,
    PmIndexComponent,
    PartTransferComponent,
    PartTransferViewComponent,
    PartsAddComponent,
    EditAmcComponent,
    AssociatePartComponent,
    RaiseTicketComponent,
    TicketEscalationComponent,
    TicketViewComponent,
    InspectionScheduleViewComponent,
    InspectionChecklistViewComponent,
    InspectionChecklistCreateComponent,
    PmViewTemplatesComponent,
    InspectionDublicateComponent,
    SurveyDublicateComponent,
    QrHtmlComponent,
    QrPermitHtmlComponent,
    QrCodeComponent,
    EditWarrantyComponent,
    // PmIndexTemplatesComponent,
    SurveyIndexComponent,
    SurveyAddComponent,
    FeedbackIndexComponent,
    FeedbackAddComponent,
    DepartmentListComponent,
    DepartmentAddNewComponent,
    DepartmentEditComponent,
    SetupComponent,
    InsuranceComponent,
    InsuranceListComponent,
    InsuranceAddComponent,
    InsuranceDetailsComponent,
    EditInsuranceComponent,
    InsuranceNotificationSettingComponent,
    AssetCoveredComponent,
    UserIndexComponent,
    UserAddComponent,
    UserEditComponent,
    PropertyIndexComponent,
    PropertyAddComponent,
    LocationChecklistsComponent,
    ChecklistIndexComponent,
    InsuranceFilesComponent,
    PartsDetailsComponent,
    EditPartsComponent,
    SubUserIndexComponent,
    SubUserEditComponent,
    PropertyDetailsComponent,
    PropertyLocationsComponent,
    PropertyUsersComponent,
    TemplateLibraryComponent,
    PartsVendorsComponent,
    EditVendorsComponent,
    PartsAssetsComponent,
    PartsLogsComponent,
    PartsReportsComponent,
    PartsNotifyComponent,
    PartsLocationHistoryComponent,
    ImageCropperComponent,
    MeterDetailsHeaderComponent,
    AssetDetailsHeaderComponent,
    UpdateQuantityComponent,
    DashboardChecklistComponent,
    D3PlotsComponent,
    DashboardTicketComponent,
    TemplateCreateComponent,
    AddVendorComponent,
    TemplateIndexComponent,
    TemplateViewComponent,
    DashboardAssetComponent,
    DashboardPartsComponent,
    DashboardSurveyComponent,
    DashboardPmComponent,
    DashboardIndexComponent,
    DashboardChecklistIndexComponent,
    LoginComponentComponent,
    SubscriptionsComponent,
    BusinessIndexComponent,
    BusinessAddComponent,
    BusinessDeleteComponent,
    BusinessEditComponent,
    BusinessAdminAddComponent,
    BusinessFeaturesComponent,
    BusinessAdminIndexComponent,
    FeedbackDetailsComponent,
    BusinessPropertyComponent,
    ReportsComponent,
    StockStatementsComponent,
    AutomatedListComponent,
    RecivedInventoryExpenseComponent,
    PartsInventoryHeaderComponent,
    QrMultipleHtmlComponent,
    TransferReportComponent,
    AssetExpenseReportComponent,
    AssetDueReturnComponent,
    AssetDiscardSummaryComponent,
    AssetTransferReportsComponent,
    InventoryMovingComponent,
    VendorListReportComponent,
    AssetVendorListComponent,
    PartVendorListComponent,
    TicketStatusReportComponent,
    AddSubUserComponent,
    SetupSettingsComponent,
    // DashboardPartComponent,
    RolesIndexComponent,
    RolesAddComponent,
    RolesViewPermissionComponent,
    QrGenerateTicketComponent,
    ResetPasswordComponent,
    WarrantySupportHistoryComponent,
    WarrantySupportHistoryAddComponent,
    TrainingsListComponent,
    TrainingsAddComponent,
    TrainingsAssignUserComponent,
    GraphListComponent,
    CheckListScoreGraphListComponent,
    ChecklistScheduleScoreListComponent,
    ChecklistSectionScoreListComponent,
    PermitListComponent,
    PermitTenantAddComponent,
    PermitTenantListComponent,
    PermitWorkAddComponent,
    PermitWorkListComponent,
    PermitAssociatedAddComponent,
    PermitAssociatedListComponent,
    PermitQuestionCheckpointListComponent,
    PermitQuestionCheckpointAddComponent,
    PermitQuestionAddComponent,
    PermitSubQuestionAddComponent,
    AssetQrHtmlComponent,
    MasterPropertyComponent,
    PropertyZoneListComponent,
    PropertyZoneAddComponent,
    PropertyClientListComponent,
    PropertyClientAddComponent,
    PropertyClusterAddComponent,
    PropertyClusterListComponent,
    PropertyStatesAddComponent,
    PropertyStatesListComponent,
    PropertyDistrictsAddComponent,
    PropertyDistrictsListComponent,
    PropertyCitiesListComponent,
    PropertyCitiesAddComponent,
    PropertySitesAddComponent,
    PropertySitesListComponent,
    AttendanceListComponent,
    ShipmentsListComponent,
    ShipmentsAddComponent,
    PermitIndexListComponent,
    PermitAddComponent,
    TrainingsDetailsComponent,
    TicketViewNewComponent,
    PermitViewComponent,
    EmployeeDesignationAddComponent,
    EmployeeDesignationListComponent,
    EmployeeDepartmentAddComponent,
    EmployeeDepartmentListComponent,
    EmployeeDbListComponent,
    DashboardAttendanceComponent,
    NotFoundComponent,
    DashboardCeoComponent,
    PmViewNewComponent,
    DashboardSurveyGraphComponent,
    PropertyQrHtmlComponent,
    PartsDashboardIndexComponent,
    FilterComponentComponent,
    EmployeeSurveyAddComponent,
    EmployeeSurveyComponent,
    EmployeeSurveyViewComponent,
    EmployeeQuestionDetailComponent,
    LostArticleIndexListComponent,
    FoundArticleIndexListComponent,
    LostArticleAddComponent,
    FoundArticleAddComponent,
    LostArticleViewComponent,
    FoundArticleViewComponent,
    PermitsListComponent,
    inspectionMonthlyDataComponent,
    ConsolidatedReportComponent,
    PartsConsumedReportsComponent,
    TwoDigitDecimaNumberDirective,
    SchedulesQrHtmlComponent,
    InspectionsReportsComponent,
    TicketsReportsComponent,
    AssetsReportsComponent,
    PartReportsComponent,
    SchedulesHolidaysComponent,
    DashboardChecklistPercentageComponent,
    PmViewCalendarComponent
  ],
  imports: [
    OwlNativeDateTimeModule,
    ImageCropperModule,
    // NgbModule,
    TextMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxFileDropModule,
    // MaterialModule.forRoot(),
    // DatePickerModule,.
    AngularDateTimePickerModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    IgxDateRangePickerModule,
    IgxTimePickerModule,
    IgxInputGroupModule,
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgGridModule.withComponents(null),
    GoogleChartsModule.forRoot(),
    NgbModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    ApiService,
    DatePipe,
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
