import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class StoredData {
  [x: string]: any;

  selectedAssetsDetailsList:any;
  selectedSchedulesList:any;
  selectedTransferArray:any;
  selectedTransferPermanentArray: any[];
  locationList=[];
  selectedCheckList: any[];
  selectedScheduleList: any[];
  amcDetails:any;
  qrData={name:null , image:null, customerComplaintName:null}
  qrDataEmp={name:null , image:null,qRCodeEmpVoice:null,qRCodeEmpVoiceWhistleblowerPolicy:null}
  statusList=[{id:1 , name :"Active"},{id:2 , name :"Inactive"},{id:3 , name :"Inmigration"},{id:4 , name :"Lost"}]
  staticDashboardFlag:boolean
  dashBoardIndex={headers:['Asset Name' , 'Warranty Expiring on'] , api:'AssetManagements/assetWarrantyList' , tableName:'Asset with Warranty Expiring' , dataSource:['asset_name' , 'outlet_id'] , apiObj:'assetWarrantyExp' , rowCountParam:'assetWarrantyCount' }
  graphIndex={headers:['Asset Name' , 'Warranty Expiring on'] ,obj:{} , api:'' , tableName:'' , dataSource:['asset_name' , 'outlet_id'] , apiObj:'getDetails' , rowCountParam:'assetWarrantyCount' }
  checklistScoreGraphListIndex={headers:['Property','Department Name', 'Checklist Name', 'Total Score'] ,obj:{} , api:'' , tableName:'' , dataSource:['propertyName', 'departmentName', 'checklistName','totalScore'] , apiObj:'getDetails' , rowCountParam:'rowCount' }
  checklistSchedulesScoreList={headers:['Property', 'Checklist Name', 'Schedule Name','Total Score'] ,obj:{} , api:'' , tableName:'' , dataSource:['propertyName', 'checklistName','ScheduleName','totalScore'] , apiObj:'getDetails' , rowCountParam:'rowCount' }
  checklistSectionsScoreList={headers:['Property', 'Checklist Name', 'Section Name','Total Score'] ,obj:{} , api:'' , tableName:'' , dataSource:['propertyName', 'checklistName','SectionName','totalScore'] , apiObj:'getDetails' , rowCountParam:'rowCount' }
}