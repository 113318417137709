import { Component, HostListener, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
// import { ColDef } from 'ag-grid-community';
// import { ApiService } from '../common/services/apiCalls.service';
import { GoogleChartsModule } from 'angular-google-charts';
import * as moment from 'moment';
declare var d3: any;
import { BrowserModule } from '@angular/platform-browser';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-dashboard-checklist',
  templateUrl: './dashboard-checklist.component.html',
  styleUrls: ['./dashboard-checklist.component.css']
})
export class DashboardChecklistComponent implements OnInit {
  /* chartData = {
     type: 'ColumnChart',
     data: [],
     chartColumns: ['Days', 'Completed', 'Incomplete'],
     options: {
       responsive: true,
       borderRadius: 15,
       pieHole: 0.4,
       bar: { groupWidth: "75%" },
       colors: ['#33CCB2', '#EF6B6B'],
       legend: { position: 'bottom', alignment: 'center' },
       hAxis: {
         title: ''
       },
       vAxis: {
         title: ''
       },
       seriesType: 'bars',
       series: { 2: { type: 'line' } }
     },
     width: 1000,
     height: 400
   };
 */
  pieBarCompletion = {
    type: 'PieChart',
    data: [
      ['Washroom Checklist', 100],
      ['Opening Checklist', 100]
    ],
    options: {
      title: '',
      slices: { 0: { color: '#6fcf97' }, 1: { color: '#EF6B6B' } },
      legend: { position: 'right', maxLines: 1 },
    }
  };
  chartBarCompletion = {
    type: 'BarChart',
    data: [
      ["Security", 20, 30],
      ["houskiping", 25, 50]
    ],
    chartColumns: ['Status', 'Completed', 'In-Completed'],
    options: {
      // hAxis: {
      //   title: 'Department Performance'
      // },
      vAxis: {
        minValue: 0
      },
      legend: { position: 'bottom', maxLines: 1 },
      colors: ['#6fcf97', '#EF6B6B'],
      isStacked: true
    }
  };
  chartBarComplance = {
    type: 'ColumnChart',
    data: [
      // ['Month', 'Completed', 'Incompleted'],
      ['Jan', 0, 0],
      ['Feb', 0, 0],
      ['Mar', 0, 0],
      ['Apr', 0, 0],
      ['May', 0, 0],
      ['Jun', 0, 0],
      ['Jul', 0, 0],
      ['Aug', 0, 0],
      ['Sep', 0, 0],
      ['Oct', 0, 0],
      ['Nov', 0, 0],
      ['Dec', 0, 0],
    ],
    chartColumns: ['Month', 'In-Completed', 'Completed'],
    options: {
      hAxis: {
        title: 'Month',
        //ticks: [0, 20, 40, 60, 80, 100]
      },
      vAxis: {
        title: 'Total',
        minValue: 0
      },
      legend: { position: 'right', maxLines: 1 },
      colors: ['#ff4a73', '#3776b9'],
      isStacked: true
    }
  };
  displayFlag2 = false
  displayFlag3 = false
  pieChartData = []
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  percentageCompletionFilter = false;
  isState = false;
  emailAddress
  errorFlag
  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  // filterPropertySearch: any;
  // filterDepartmentSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];
  completionStatusLists = [{ id: 1, name: 'Completed' }, { id: 0, name: 'Incompleted' }];
  groupByList = [{ id: 'schedule_name', name: 'Schedule Name' }, { id: 'checklist_name', name: 'Checklist Name' }];
  sortByList =  [{ id: 'count', name: 'Pending Count ' }, { id: 'percentage', name: 'Pending Percentage' }];
  completionPercentageLists = [{ id: 1, name: '25%' }, { id: 2, name: '50%' }, { id: 3, name: '75%' }];
  frequencyLists = [{ id: 1, name: 'Once' }, { id: 2, name: 'Daily' }, { id: 3, name: 'Weekly' }, { id: 4, name: 'Monthly' }, { id: 5, name: 'Quarterly' }, { id: 6, name: 'Custom' }];
  selectedFiler = { department: null, property: null, user: null, startDate: null, ensDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null, name: null, inspectionsName: null, completionStatus: null, location: null, percentageCompletion: null, frequency: null, dateRange: null, groupBy: null, sortBy: null }
  propertyFiletr = false;
  assignedFilter = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;
  locationFilter = false
  completionStatusFilter = false
  frequencyFilter = false
  groupByFilter = false;
  sortByFilter = false;
  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  businessName = this.apiService.buisnessName;
  propertyNameFilterDisplay = ''
  startDateFilterDisplay = ''
  endDateFilterDisplay = ''
  departmentNameFilterDisplay = ''
  scheduleNameFilterDisplay = ''
  inspectionNameFilterDisplay = ''
  statusNameFilterDisplay = ''
  locationNameFilterDisplay = ''
  groupByFilterDisplay = ''
  sortByFilterDisplay = ''
  frequencyNameFilterDisplay = ''
  userNameFilterDisplay = ''
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      // this.showFilter = false
      this.propertyFiletr = false;
      this.assignedFilter = false;
      this.departmentFilter = false;
      this.groupByFilter = false;
      this.sortByFilter = false;
      // this.statsuFilter = false;
      // this.typeFilter = false;
      // // this.showAddMenu = false;
      // this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.assignedFilter = false;
      this.departmentFilter = false;
      this.groupByFilter = false;
      this.sortByFilter = false;
      // this.typeFilter = false;
      // this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }
  filterLocationSearch
  filterLocation(ev) {
    this.locationList = this.locationListData.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })
  }
  closeAll() {
    this.propertyFiletr = false;
    this.assignedFilter = false;
    this.departmentFilter = false;
    this.sortByFilter = false;
    this.groupByFilter = false;
    // this.typeFilter = false;
    // this.businessFilter = false;
  }
  staticDashboardstaticDashboardChangeValue = this.storedData.staticDashboardFlag;
  staticDashboardChangeTo() {
    if (this.staticDashboardstaticDashboardChangeValue == true || this.staticDashboardstaticDashboardChangeValue == undefined) {
      this.staticDashboardFlag = true;
      this.storedData.staticDashboardFlag = true;
    } else {
      this.staticDashboardFlag = false;
      this.storedData.staticDashboardFlag = false;
    }
    this.clear();
  }
  departmentList
  getDepartmentList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('types/pluckDepartments/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    if(this.showTableDashboardFlag == true){
      this.showTableDashboard()
    }else{
      this.ngOnInit()
    }

  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.propertyNameFilterDisplay = ''
    this.departmentNameFilterDisplay = ''
    this.selectedFiler = { department: null, property: null, user: null, startDate: null, ensDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null, name: null, inspectionsName: null, completionStatus: null, location: null, percentageCompletion: null, frequency: null, dateRange: null, groupBy:null,sortBy:null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
  }
  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      this.locationList = result['data']
      this.filterpropertyList = result['data']
      if (this.apiService.outletId) {
        let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId);
      }
      // console.log(this.apiService.roleId)
      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })
  }
  locationListData: any;
  getLocationListData() {
    this.apiService.startLoader()
    this.locationListData = []
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('locations/locationList/' + this.apiService.buisnessId + "/" + this.selectedFiler.property.id, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      this.locationListData = result['data'];
    })
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }
  columnDefs
  rowData
  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData) {
  }
  complainUsersList = [{ name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  ]
  responsesList = [{ item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  { item_name: "Complete Hostel Inspection", markdown_count: "01" },
  ]
  paginationArray = [];
  downloadFlag = false
  ngOnInit(): void {

    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if ((localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == 'null' || localStorage.getItem('accessToken') == '')) {
      this.router.navigate(['/login-view'])
    }
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    d3.selectAll("#groupedChartYAxisChecklist > *").remove();
    d3.selectAll("#groupedChartChecklist > *").remove();
    this.showCahrtbarDashboard();
    // this.groupedBarchart()
    this.getLocationList()
    this.getDepartmentList()
    this.getUserList()

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
      }
      if (this.apiService.role == 1) {
        this.businessFeatureAttendance = true
      }
    }

    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }
    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }
    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);
  }
  jumpToPagePre = 1
  jumpToPage = 1
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }
  // openListWarranty(id) {
  //   const date = new Date();
  //   let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
  //   let endDate = new Date()
  //   this.storedData.dashBoardIndex = { headers: ['Name', 'Property', 'Completed', 'Completion (%)'], api: 'todays-checklists/checklistmostcompleteuserreport', tableName: 'Most Compliant Associates/Teams', dataSource: ['full_name', 'outlet_name', 'assigned_scheduled', 'percent'], apiObj: 'checklistmostcompleteuserreport', rowCountParam: 'count' }
  //   this.storedData.dashBoardIndex['apiObjParam'] = { outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
  //   departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
  //   startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
  //   endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
  //   "roleId": this.apiService.roleId,
  //   "userId": this.apiService.userId,
  //   "businessId": this.apiService.buisnessId, }
  //   this.route.navigate(['/dashboard-checklist-index/' + id])
  // }
  showGraphFlag = true;
  showTableDashboardFlag = false;
  showCahrtbarDashboard() {
    this.showGraphFlag = true;
    this.showTableDashboardFlag = false;
    this.getAsset()
    this.getInspectionReportMonthlyDataFind()
    this.getMostIncompleteInspectionReport()
  }
  showTableDashboard() {

    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    if (this.selectedFiler.property?.id) {
      this.propertyNameFilterDisplay = this.selectedFiler.property?.outletName;
    }
    if (this.selectedFiler.department?.id) {
      this.departmentNameFilterDisplay = this.selectedFiler.department?.name;
    }
    this.startDateFilterDisplay = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('DD-MM-YYYY') : moment(satrtDate).format('DD-MM-YYYY');
    this.endDateFilterDisplay = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('DD-MM-YYYY') : moment(endDate).format('DD-MM-YYYY')
    this.scheduleNameFilterDisplay = this.selectedFiler.name ? this.selectedFiler.name : ""
    this.inspectionNameFilterDisplay = this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : ""
    this.statusNameFilterDisplay = this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.name : ""
    this.locationNameFilterDisplay = this.selectedFiler.location ? this.selectedFiler.location.name : ""
    this.groupByFilterDisplay = this.selectedFiler.groupBy ? this.selectedFiler.groupBy.name : ""
    this.sortByFilterDisplay = this.selectedFiler.sortBy ? this.selectedFiler.sortBy.name : ""
    this.frequencyNameFilterDisplay = this.selectedFiler.frequency ? this.selectedFiler.frequency.name : ""
    this.userNameFilterDisplay = this.selectedFiler.user ? this.selectedFiler.user.full_name : ""
    this.showGraphFlag = false;
    this.showTableDashboardFlag = true;
    this.getInspectionReportDataFind();
    this.getMostCompaltCust()
    this.getFreqMarkedRespon()
  }
  mostComplainCust
  getMostCompaltCust() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1);//date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : ""

    }
    this.apiService.startLoader()
    this.apiService.postCall('todays-checklists/checklistmostcompleteuserreport', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.mostComplainCust = [
          {
            "id": 1460097,
            "schedule_id": 329,
            "inspection_id": 415,
            "inspection_name": "Fire and Life Safety Inspection Checklist",
            "department_id": 261,
            "template_category_id": 9,
            "schedule_name": "Fire Audit",
            "schedule_type": 2,
            "start_date": "2018-09-24",
            "end_date": null,
            "continue_days": null,
            "custom_type": null,
            "repeat_days": null,
            "is_reminder": 0,
            "reminder_date": null,
            "reminder_time": null,
            "ticket_summary": null,
            "outlet_id": 71,
            "floor_plan_id": 106,
            "date": "2023-07-02",
            "user_id": "323",
            "sub_user_id": null,
            "user_parent_id": 0,
            "is_infinite": 1,
            "is_completed": 0,
            "count_completed": 0,
            "count_incompleted": 93,
            "completed_percentage": null,
            "score_percentage": null,
            "total_score": 0,
            "created": "2023-07-02T02:00:18+05:30",
            "modified": "2023-07-02T02:00:18+05:30",
            "is_active": 1,
            "is_adhoc": 0,
            "sub_inspection_id": 11123,
            "checklist_start_date": null,
            "checklist_end_date": null,
            "latitued": null,
            "longitued": null,
            "qr_allow_group_flag": 0,
            "group_name": null,
            "is_multiple": 0,
            "is_multiple_times": null,
            "user": {
              "id": 323,
              "first_name": null,
              "last_name": null,
              "full_name": "HK Supervisor Team",
              "email": "rdsouza@proptor.co.in",
              "mobile": "9821127732",
              "designation": "",
              "parent_id": null,
              "pin": null,
              "outlet_id": 7150,
              "business_id": 26,
              "role": 2,
              "user_type": 1,
              "user_type_mobile": 1,
              "is_active": 1,
              "shipment_id": 0,
              "quickblox": null,
              "is_verified": 1,
              "is_archive": 0,
              "verification_code": "9SHXJ",
              "firebase_token": "6e406fc2-b440-4a86-8bc8-9eb3e7ca43ff",
              "firebase_token_desktop": "cQ0jjZ2KFao:APA91bEE7jKq_nUdI0glxXB0nsMITA9Xa61xxBQkrwHEoxwo7Itj8vZuaW6wrLB1ID9ZZPWwkfBtpACgs43cNwcMI0M1X1rycZqm8WFXmsW1Xa6whX3n7vCJROb7Ll3-oKoNJ3qEBc5Z",
              "ticket_notification_date": "2023-04-26T18:40:20+05:30",
              "reminder_notification_date": "2023-04-26T18:40:13+05:30",
              "request_notification_date": "2023-05-21T16:03:57+05:30",
              "schedule_assigned": 1,
              "automated_report": 0,
              "frequency_name": "Daily",
              "created": "2018-09-10T18:49:41+05:30",
              "modified": "2023-07-01T10:25:40+05:30",
              "modified_by": 323,
              "one_signal_token": null
            },
            "outlet": {
              "id": 71,
              "business_id": 26,
              "outlet_name": "Site - A",
              "outlet_code": "DM1",
              "site_code": null,
              "location": "",
              "latitude": "20.7025544360567",
              "longitude": "76.99185782698785",
              "city": "",
              "zone_id": 27,
              "state_id": 92,
              "citie_id": 1072,
              "cluster_id": 56,
              "district_id": 307,
              "client_id": 43,
              "site_type": null,
              "site_group": null,
              "area": "",
              "qr_code": "1571380368_71.png",
              "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
              "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
              "default_users": "4431",
              "default_users_internal_complaints": null,
              "default_users_near_miss": null,
              "default_department": "86",
              "is_ad_hoc": 0,
              "is_active": 1,
              "created": "2018-09-10T18:42:08+05:30",
              "modified": "2023-02-13T15:47:40+05:30"
            },
            "assigned_scheduled": "25",
            "completed_scheduled": "25",
            "percent": 100
          },
          {
            "id": 1460098,
            "schedule_id": 336,
            "inspection_id": 377,
            "inspection_name": "Washroom Inspection Checklist",
            "department_id": 267,
            "template_category_id": 13,
            "schedule_name": "Washroom Inspection",
            "schedule_type": 2,
            "start_date": "2018-10-24",
            "end_date": null,
            "continue_days": null,
            "custom_type": null,
            "repeat_days": null,
            "is_reminder": 0,
            "reminder_date": null,
            "reminder_time": null,
            "ticket_summary": null,
            "outlet_id": 73,
            "floor_plan_id": 109,
            "date": "2023-07-02",
            "user_id": "324",
            "sub_user_id": null,
            "user_parent_id": 0,
            "is_infinite": 1,
            "is_completed": 0,
            "count_completed": 0,
            "count_incompleted": 12,
            "completed_percentage": null,
            "score_percentage": null,
            "total_score": 0,
            "created": "2023-07-02T02:00:21+05:30",
            "modified": "2023-07-02T02:00:21+05:30",
            "is_active": 1,
            "is_adhoc": 0,
            "sub_inspection_id": 10607,
            "checklist_start_date": null,
            "checklist_end_date": null,
            "latitued": null,
            "longitued": null,
            "qr_allow_group_flag": 0,
            "group_name": null,
            "is_multiple": 0,
            "is_multiple_times": null,
            "user": {
              "id": 324,
              "first_name": null,
              "last_name": null,
              "full_name": "Vinay Mehta",
              "email": "ragarwal@proptor.co.in",
              "mobile": "1234567890",
              "designation": "",
              "parent_id": null,
              "pin": null,
              "outlet_id": 1,
              "business_id": 26,
              "role": 5,
              "user_type": 2,
              "user_type_mobile": 1,
              "is_active": 1,
              "shipment_id": 0,
              "quickblox": null,
              "is_verified": 1,
              "is_archive": 0,
              "verification_code": "nvcVU",
              "firebase_token": "",
              "firebase_token_desktop": null,
              "ticket_notification_date": "2021-11-15T20:26:42+05:30",
              "reminder_notification_date": "2021-11-15T20:26:46+05:30",
              "request_notification_date": "2021-03-16T11:47:50+05:30",
              "schedule_assigned": 1,
              "automated_report": 0,
              "frequency_name": "Daily",
              "created": "2018-09-10T18:50:22+05:30",
              "modified": "2023-04-21T08:06:33+05:30",
              "modified_by": 318,
              "one_signal_token": null
            },
            "outlet": {
              "id": 73,
              "business_id": 26,
              "outlet_name": "Site - C",
              "outlet_code": "DM3",
              "site_code": null,
              "location": "",
              "latitude": "19.974851",
              "longitude": "73.836043",
              "city": null,
              "zone_id": null,
              "state_id": null,
              "citie_id": null,
              "cluster_id": null,
              "district_id": null,
              "client_id": null,
              "site_type": null,
              "site_group": null,
              "area": "",
              "qr_code": "1571380368_73.png",
              "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456240_hnfde_73.png",
              "permit_qr_code": "1599834783_73.png",
              "default_users": "",
              "default_users_internal_complaints": null,
              "default_users_near_miss": null,
              "default_department": "",
              "is_ad_hoc": 1,
              "is_active": 0,
              "created": "2018-09-10T18:44:39+05:30",
              "modified": "2021-11-08T19:39:43+05:30"
            },
            "assigned_scheduled": "20",
            "completed_scheduled": "17",
            "percent": 85
          },
          {
            "id": 1460101,
            "schedule_id": 345,
            "inspection_id": 374,
            "inspection_name": "Common Area Inspection",
            "department_id": 242,
            "template_category_id": 1,
            "schedule_name": "Common Area Checklist",
            "schedule_type": 2,
            "start_date": "2018-11-20",
            "end_date": null,
            "continue_days": null,
            "custom_type": null,
            "repeat_days": null,
            "is_reminder": 1,
            "reminder_date": null,
            "reminder_time": "11:00:00",
            "ticket_summary": null,
            "outlet_id": 71,
            "floor_plan_id": 106,
            "date": "2023-07-02",
            "user_id": "322",
            "sub_user_id": null,
            "user_parent_id": 0,
            "is_infinite": 1,
            "is_completed": 0,
            "count_completed": 0,
            "count_incompleted": 41,
            "completed_percentage": null,
            "score_percentage": null,
            "total_score": 0,
            "created": "2023-07-02T02:00:26+05:30",
            "modified": "2023-07-02T02:00:26+05:30",
            "is_active": 1,
            "is_adhoc": 0,
            "sub_inspection_id": 10545,
            "checklist_start_date": null,
            "checklist_end_date": null,
            "latitued": null,
            "longitued": null,
            "qr_allow_group_flag": 0,
            "group_name": null,
            "is_multiple": 0,
            "is_multiple_times": null,
            "user": {
              "id": 322,
              "first_name": null,
              "last_name": null,
              "full_name": "Engg. Supervisor Team",
              "email": "plohiya@proptor.com",
              "mobile": "9699393299",
              "designation": "",
              "parent_id": null,
              "pin": null,
              "outlet_id": 5992,
              "business_id": 26,
              "role": 3,
              "user_type": 1,
              "user_type_mobile": 1,
              "is_active": 1,
              "shipment_id": 0,
              "quickblox": null,
              "is_verified": 1,
              "is_archive": 0,
              "verification_code": "osuhA",
              "firebase_token": "",
              "firebase_token_desktop": null,
              "ticket_notification_date": "2021-11-29T12:24:51+05:30",
              "reminder_notification_date": "2021-11-29T12:24:51+05:30",
              "request_notification_date": "2022-11-29T13:25:35+05:30",
              "schedule_assigned": 1,
              "automated_report": 0,
              "frequency_name": "Daily",
              "created": "2018-09-10T18:47:33+05:30",
              "modified": "2023-06-19T16:48:11+05:30",
              "modified_by": 322,
              "one_signal_token": null
            },
            "outlet": {
              "id": 71,
              "business_id": 26,
              "outlet_name": "Site - B",
              "outlet_code": "DM1",
              "site_code": null,
              "location": "",
              "latitude": "20.7025544360567",
              "longitude": "76.99185782698785",
              "city": "",
              "zone_id": 27,
              "state_id": 92,
              "citie_id": 1072,
              "cluster_id": 56,
              "district_id": 307,
              "client_id": 43,
              "site_type": null,
              "site_group": null,
              "area": "",
              "qr_code": "1571380368_71.png",
              "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
              "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
              "default_users": "4431",
              "default_users_internal_complaints": null,
              "default_users_near_miss": null,
              "default_department": "86",
              "is_ad_hoc": 0,
              "is_active": 1,
              "created": "2018-09-10T18:42:08+05:30",
              "modified": "2023-02-13T15:47:40+05:30"
            },
            "assigned_scheduled": "16",
            "completed_scheduled": "12",
            "percent": 75
          },
          {
            "id": 1460281,
            "schedule_id": 87969,
            "inspection_id": 75565,
            "inspection_name": "Technical",
            "department_id": 1,
            "template_category_id": 1,
            "schedule_name": "HVAC - Chiller 1 - PM Check",
            "schedule_type": 2,
            "start_date": "2023-04-01",
            "end_date": null,
            "continue_days": 1,
            "custom_type": null,
            "repeat_days": null,
            "is_reminder": 0,
            "reminder_date": null,
            "reminder_time": "12:23:14",
            "ticket_summary": null,
            "outlet_id": 71,
            "floor_plan_id": 106,
            "date": "2023-07-02",
            "user_id": "939",
            "sub_user_id": null,
            "user_parent_id": 0,
            "is_infinite": 1,
            "is_completed": 0,
            "count_completed": 0,
            "count_incompleted": 1,
            "completed_percentage": null,
            "score_percentage": null,
            "total_score": 0,
            "created": "2023-07-02T02:02:54+05:30",
            "modified": "2023-07-03T02:02:57+05:30",
            "is_active": 0,
            "is_adhoc": 0,
            "sub_inspection_id": 2088024,
            "checklist_start_date": null,
            "checklist_end_date": null,
            "latitued": null,
            "longitued": null,
            "qr_allow_group_flag": 0,
            "group_name": null,
            "is_multiple": 0,
            "is_multiple_times": null,
            "user": {
              "id": 322,
              "first_name": null,
              "last_name": null,
              "full_name": "Raj Shah",
              "email": "plohiya@proptor.com",
              "mobile": "9699393299",
              "designation": "",
              "parent_id": null,
              "pin": null,
              "outlet_id": 5992,
              "business_id": 26,
              "role": 3,
              "user_type": 1,
              "user_type_mobile": 1,
              "is_active": 1,
              "shipment_id": 0,
              "quickblox": null,
              "is_verified": 1,
              "is_archive": 0,
              "verification_code": "osuhA",
              "firebase_token": "",
              "firebase_token_desktop": null,
              "ticket_notification_date": "2021-11-29T12:24:51+05:30",
              "reminder_notification_date": "2021-11-29T12:24:51+05:30",
              "request_notification_date": "2022-11-29T13:25:35+05:30",
              "schedule_assigned": 1,
              "automated_report": 0,
              "frequency_name": "Daily",
              "created": "2018-09-10T18:47:33+05:30",
              "modified": "2023-06-19T16:48:11+05:30",
              "modified_by": 322,
              "one_signal_token": null
            },
            "outlet": {
              "id": 71,
              "business_id": 26,
              "outlet_name": "Site - A",
              "outlet_code": "DM1",
              "site_code": null,
              "location": "",
              "latitude": "20.7025544360567",
              "longitude": "76.99185782698785",
              "city": "",
              "zone_id": 27,
              "state_id": 92,
              "citie_id": 1072,
              "cluster_id": 56,
              "district_id": 307,
              "client_id": 43,
              "site_type": null,
              "site_group": null,
              "area": "",
              "qr_code": "1571380368_71.png",
              "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
              "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
              "default_users": "4431",
              "default_users_internal_complaints": null,
              "default_users_near_miss": null,
              "default_department": "86",
              "is_ad_hoc": 0,
              "is_active": 1,
              "created": "2018-09-10T18:42:08+05:30",
              "modified": "2023-02-13T15:47:40+05:30"
            },
            "assigned_scheduled": "15",
            "completed_scheduled": "11",
            "percent": 73
          },
          {
            "id": 1460282,
            "schedule_id": 87974,
            "inspection_id": 629,
            "inspection_name": "Food Safety Daily Checklist",
            "department_id": 244,
            "template_category_id": 15,
            "schedule_name": "Food Safety Check - Detailed",
            "schedule_type": 2,
            "start_date": "2020-09-23",
            "end_date": null,
            "continue_days": null,
            "custom_type": null,
            "repeat_days": null,
            "is_reminder": 0,
            "reminder_date": null,
            "reminder_time": null,
            "ticket_summary": null,
            "outlet_id": 73,
            "floor_plan_id": 109,
            "date": "2023-07-02",
            "user_id": "2125",
            "sub_user_id": null,
            "user_parent_id": 0,
            "is_infinite": 1,
            "is_completed": 0,
            "count_completed": 0,
            "count_incompleted": 20,
            "completed_percentage": null,
            "score_percentage": null,
            "total_score": 0,
            "created": "2023-07-02T02:02:54+05:30",
            "modified": "2023-07-02T02:02:54+05:30",
            "is_active": 1,
            "is_adhoc": 0,
            "sub_inspection_id": 20269,
            "checklist_start_date": null,
            "checklist_end_date": null,
            "latitued": null,
            "longitued": null,
            "qr_allow_group_flag": 0,
            "group_name": null,
            "is_multiple": 0,
            "is_multiple_times": null,
            "user": {
              "id": 2125,
              "first_name": null,
              "last_name": null,
              "full_name": "Daksh Singh",
              "email": "skapadia@proptor.co.in",
              "mobile": "1234567890",
              "designation": "",
              "parent_id": null,
              "pin": null,
              "outlet_id": 1,
              "business_id": 26,
              "role": 3,
              "user_type": 2,
              "user_type_mobile": 1,
              "is_active": 0,
              "shipment_id": 0,
              "quickblox": null,
              "is_verified": 1,
              "is_archive": 0,
              "verification_code": "80bH7",
              "firebase_token": "",
              "firebase_token_desktop": null,
              "ticket_notification_date": "2022-02-22T17:57:01+05:30",
              "reminder_notification_date": "2022-02-14T17:42:33+05:30",
              "request_notification_date": "2021-04-24T19:24:18+05:30",
              "schedule_assigned": 1,
              "automated_report": 0,
              "frequency_name": "Daily",
              "created": "2020-09-23T23:18:49+05:30",
              "modified": "2022-08-16T15:57:17+05:30",
              "modified_by": 318,
              "one_signal_token": null
            },
            "outlet": {
              "id": 73,
              "business_id": 26,
              "outlet_name": "Site - B",
              "outlet_code": "DM3",
              "site_code": null,
              "location": "",
              "latitude": "19.974851",
              "longitude": "73.836043",
              "city": null,
              "zone_id": null,
              "state_id": null,
              "citie_id": null,
              "cluster_id": null,
              "district_id": null,
              "client_id": null,
              "site_type": null,
              "site_group": null,
              "area": "",
              "qr_code": "1571380368_73.png",
              "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456240_hnfde_73.png",
              "permit_qr_code": "1599834783_73.png",
              "default_users": "",
              "default_users_internal_complaints": null,
              "default_users_near_miss": null,
              "default_department": "",
              "is_ad_hoc": 1,
              "is_active": 0,
              "created": "2018-09-10T18:44:39+05:30",
              "modified": "2021-11-08T19:39:43+05:30"
            },
            "assigned_scheduled": "14",
            "completed_scheduled": "10",
            "percent": 71
          }
        ]
      } else {
        this.mostComplainCust = result['data']['compliantTeam'];
      }

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)

    })
  }

  freqMarkedCust
  getFreqMarkedRespon() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1);//date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : ""

    }
    this.apiService.startLoader()
    this.apiService.postCall('todays-checklists/checklistfrequentlydownreport', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.freqMarkedCust = [
          {
            "id": 30422696,
            "subInspectionId": 11742,
            "inspectionName": "Gents Washroom Checklist - Floor is dirty.",
            "count": 4
          },
          {
            "id": 30422698,
            "subInspectionId": 11744,
            "inspectionName": "Sink works fine?",
            "count": 3
          },
          {
            "id": 30422700,
            "subInspectionId": 11746,
            "inspectionName": "Garbage cans are emptied",
            "count": 3
          },
          {
            "id": 30422701,
            "subInspectionId": 11747,
            "inspectionName": "Ladies Washroom Checklist - Soap Dispenser is empty.",
            "count": 1
          },
          {
            "id": 30422703,
            "subInspectionId": 11749,
            "inspectionName": "Leakage in common area",
            "count": 1
          }
        ]
      } else {
        this.freqMarkedCust = result['data']['recurring'];
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)

    })
  }



  selected: any = 'dashboard-checklist';
  navigateTo(rout) {
    this.selected = rout
    this.route.navigate([rout])
  }
  filterUserSearch
  filterUsers(ev) {
    this.userList = this.filetrUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  userList
  filetrUserList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.userList = result['data']
      this.filetrUserList = this.userList
    })
  }
  checklistDashboardToday: any;
  checklistDashboard
  getAsset() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    if (this.selectedFiler.property?.id) {
      this.propertyNameFilterDisplay = this.selectedFiler.property?.outletName;
    }
    if (this.selectedFiler.department?.id) {
      this.departmentNameFilterDisplay = this.selectedFiler.department?.name;
    }
    this.startDateFilterDisplay = this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('DD-MM-YYYY') : moment(satrtDate).format('DD-MM-YYYY');
    this.endDateFilterDisplay = this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('DD-MM-YYYY') : moment(endDate).format('DD-MM-YYYY')
    this.scheduleNameFilterDisplay = this.selectedFiler.name ? this.selectedFiler.name : ""
    this.inspectionNameFilterDisplay = this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : ""
    this.statusNameFilterDisplay = this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.name : ""
    this.locationNameFilterDisplay = this.selectedFiler.location ? this.selectedFiler.location.name : ""
    this.groupByFilterDisplay = this.selectedFiler.groupBy ? this.selectedFiler.groupBy.name : ""
    this.sortByFilterDisplay = this.selectedFiler.sortBy ? this.selectedFiler.sortBy.name : ""
    this.frequencyNameFilterDisplay = this.selectedFiler.frequency ? this.selectedFiler.frequency.name : ""
    this.userNameFilterDisplay = this.selectedFiler.user ? this.selectedFiler.user.full_name : ""
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      onlyGraph: 1
    }
    this.apiService.startLoader();
    this.apiService.postCall('todays-checklists/checklistreport', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      if (this.staticDashboardFlag == true) {
        this.checklistDashboardToday = {
          "checklist": [
            [
              "Days",
              "Completed Inspections",
              "Incomplete Inspections"
            ],
            [
              "Jul 01 2024",
              44,
              6
            ],
            [
              "Jul 02 2024",
              42,
              8
            ],
            [
              "Jul 03 2024",
              41,
              9
            ],
            [
              "Jul 04 2024",
              50,
              0
            ],
            [
              "Jul 05 2024",
              43,
              7
            ],
            [
              "Jul 06 2024",
              40,
              10
            ],
            [
              "Jul 07 2024",
              0,
              0
            ],
            [
              "Jul 08 2024",
              46,
              4
            ],
            [
              "Jul 09",
              43,
              7
            ],
            [
              "Jul 10 2024",
              47,
              3
            ], [
              "Jul 11 2024",
              41,
              9
            ],
            [
              "Jul 12 2024",
              48,
              2
            ],
            [
              "Jul 13 2024",
              42,
              8
            ],
            [
              "Jul 14 2024",
              0,
              0
            ],
            [
              "Jul 15 2024",
              48,
              2
            ],
            [
              "Jul 16 2024",
              43,
              7
            ],
            [
              "Jul 17 2024",
              47,
              3
            ],
            [
              "Jul 18 2024",
              41,
              9
            ],
            [
              "Jul 19 2024",
              45,
              5
            ],
            [
              "Jul 20 2024",
              39,
              11
            ],
            [
              "Jul 21 2024",
              0,
              0
            ],
            [
              "Jul 22 2024",
              41,
              9
            ],
            [
              "Jul 23 2024",
              39,
              9
            ],
            [
              "Jul 24 2024",
              40,
              10
            ],
            [
              "Jul 25 2024",
              50,
              5
            ],
            [
              "Jul 26 2024",
              44,
              6
            ],
            [
              "Jul 27 2024",
              41,
              9
            ],
            [
              "Jul 28 2024",
              0,
              0
            ],
            [
              "Jul 29 2024",
              48,
              2
            ],
            [
              "Jul 30 2024",
              47,
              3
            ]
          ],
          "totalChecklistsScheduled": '1,300',
          "completedChecklists": '1,140',
          "inCompletedChecklists": 160,
          "getDetails": [],
          "rowCount": 0
        }
        this.apiService.stopLoader()
      } else {
        this.checklistDashboardToday = result['data'];
        this.pieBarCompletion = {
          type: 'PieChart',
          data: [
            ['Washroom Checklist', this.checklistDashboardToday?.inCompletedChecklists],
            ['Opening Checklist', this.checklistDashboardToday?.completedChecklists]
          ],
          options: {
            title: '',
            legend: { position: 'right', maxLines: 1 },
            slices: { 0: { color: '#EF6B6B' }, 1: { color: '#6fcf97' } }
          }
        };
        this.chartBarCompletion = {
          type: 'BarChart',
          data: this.checklistDashboardToday?.departWiseScheduleData,
          chartColumns: ['Status', 'Completed', 'In-Completed'],
          options: {
            // hAxis: {
            //   title: 'Department Performance'
            // },
            vAxis: {
              minValue: 0
            },
            legend: { position: 'bottom', maxLines: 1 },
            colors: ['#6fcf97', '#EF6B6B'],
            isStacked: true
          }
        };
        this.apiService.stopLoader()
      }

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      //this.groupedBarchart();
    })


    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body1 = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: (this.selectedFiler.startDate == this.selectedFiler.ensDate) ? moment(firstDay).format('YYYY-MM-DD') : moment(this.selectedFiler.startDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      onlyGraph: 0
    }
    this.apiService.startLoader()
    this.apiService.postCall('todays-checklists/checklistreport', {}, body1).subscribe((result: any[]) => {
      // console.log(result);
      if (this.staticDashboardFlag == true) {
        this.checklistDashboard = {
          "checklist": [
            [
              "Days",
              "Completed Inspections",
              "Incomplete Inspections"
            ],
            [
              "Jul 01 2024",
              44,
              6
            ],
            [
              "Jul 02 2024",
              42,
              8
            ],
            [
              "Jul 03 2024",
              41,
              9
            ],
            [
              "Jul 04 2024",
              50,
              0
            ],
            [
              "Jul 05 2024",
              43,
              7
            ],
            [
              "Jul 06 2024",
              40,
              10
            ],
            [
              "Jul 07 2024",
              0,
              0
            ],
            [
              "Jul 08 2024",
              46,
              4
            ],
            [
              "Jul 09",
              43,
              7
            ],
            [
              "Jul 10 2024",
              47,
              3
            ], [
              "Jul 11 2024",
              41,
              9
            ],
            [
              "Jul 12 2024",
              48,
              2
            ],
            [
              "Jul 13 2024",
              42,
              8
            ],
            [
              "Jul 14 2024",
              0,
              0
            ],
            [
              "Jul 15 2024",
              48,
              2
            ],
            [
              "Jul 16 2024",
              43,
              7
            ],
            [
              "Jul 17 2024",
              47,
              3
            ],
            [
              "Jul 18 2024",
              41,
              9
            ],
            [
              "Jul 19 2024",
              45,
              5
            ],
            [
              "Jul 20 2024",
              39,
              11
            ],
            [
              "Jul 21 2024",
              0,
              0
            ],
            [
              "Jul 22 2024",
              41,
              9
            ],
            [
              "Jul 23 2024",
              39,
              9
            ],
            [
              "Jul 24 2024",
              40,
              10
            ],
            [
              "Jul 25 2024",
              50,
              5
            ],
            [
              "Jul 26 2024",
              44,
              6
            ],
            [
              "Jul 27 2024",
              41,
              9
            ],
            [
              "Jul 28 2024",
              0,
              0
            ],
            [
              "Jul 29 2024",
              48,
              2
            ],
            [
              "Jul 30 2024",
              47,
              3
            ]
          ],
          "totalChecklistsScheduled": '1,300',
          "completedChecklists": '1,140',
          "inCompletedChecklists": 160,
          "getDetails": [],
          "rowCount": 0
        }
      } else {
        this.checklistDashboard = result['data'];
      }
      this.apiService.stopLoader()
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      this.groupedBarchart()
    })
  }
  /* groupedBarchart() {
    this.checklistDashboard['checklist'].splice(0, 1)
    // console.log(this.checklistDashboard['checklist'])
    this.chartData = {
      type: 'ColumnChart',
      data: this.checklistDashboard['checklist'],
      chartColumns: ['Days', 'Completed', 'Incomplete'],
      options: {
        responsive: true,
        pieHole: 0.4,
        bar: { groupWidth: "75%" },
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: {
          title: ''
        },
        vAxis: {
          title: ''
        },
        seriesType: 'bars',
        series: { 2: { type: 'line' } }
      },
      width: 1000,
      height: 300
    };
    this.displayFlag3 = false
  } */
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.downloadReport();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  downloadReport() {

    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : "",
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "chart1": this.chart1,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      checklistDashboardToday: this.checklistDashboardToday,
      mostComplainCust: this.mostComplainCust,
      freqMarkedCust: this.freqMarkedCust,
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      onlyGraph: 1
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('todays-checklists/dashboardInspectionDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  onSelect(event, flg) {
    console.log(event.selection[0])
    if (flg == 0) {
      const row = event['selection'][0].row;
      const column = event['selection'][0].column;
      const year = this.checklistDashboard['checklist'][row][0];
      let selectedItem;
      var flag = 1;
      if (column === 1) {
        selectedItem = "completed";
        flag = 1;
      }
      if (column === 2) {
        selectedItem = "inCompleted";
        flag = 2;
      }
      this.complianceStatus(flag, year)
      //console.log("year", year, "SelectedItem", selectedItem, this.checklistDashboard['checklist'][row][column]);
    } else if (flg == 1) {
      const { row, column } = event.selection[0];
      console.log(event.selection[0])
      console.log(this.checklistDashboardToday?.departWiseScheduleData[row][0]);
      const departmentNameClick = this.checklistDashboardToday?.departWiseScheduleData[row][0];
      let completionStatusDepartmentNameClick = 0;
      if (column == 1) {
        completionStatusDepartmentNameClick = 1;
      } else if (column == 2) {
        completionStatusDepartmentNameClick = 0;
      }
      this.departmentNameClick = departmentNameClick;
      this.completionStatusDepartmentNameClick = completionStatusDepartmentNameClick;
      this.checklistDepartmentReport();
    }
  }
  allGraphClickDataShow
  completionStatusDepartmentNameClick
  departmentNameClick
  checklistDepartmentReport() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      departmentNameClick: this.departmentNameClick ? this.departmentNameClick : "",
      completionStatusDepartmentNameClick: this.completionStatusDepartmentNameClick,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      //departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      //completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      pageNo: this.activePageAllGraph,
      limit: this.pageSizeAllGraph,
    }
    this.apiService.startLoader();
    this.apiService.postCall('todays-checklists/checklistDepartmentReport', {}, body).subscribe((result: any[]) => {
      this.allGraphClickDataShow = result['data'];
      this.setPaginationArray(result)
      this.apiService.stopLoader()
    })
  }
  totalnoOfPagesAllGraph
  activePageAllGraph: any = 1
  pageSizeAllGraph = 10;
  paginationArrayAllGraph = [];
  paginationAllGraph(d) {
    if (d != 0 && d <= this.totalnoOfPagesAllGraph && this.activePageAllGraph != d) {
      this.activePageAllGraph = d;
      this.checklistDepartmentReport()
    }
  }
  changePageAllGraph(ev) {
    this.getInspectionReportDataFind()
  }
  setPaginationArrayAllGraph(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPagesAllGraph = noOfPAges;
    this.paginationArray = []
    this.activePageAllGraph = parseInt(this.activePageAllGraph)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayAllGraph.push(i)
    else if (4 + this.activePageAllGraph < this.totalnoOfPagesAllGraph) {
      for (let i = this.activePageAllGraph; i <= 4 + this.activePageAllGraph; i++)this.paginationArrayAllGraph.push(i)
    }
    else if (4 + this.activePageAllGraph >= 6) {

      let start = (4 + this.activePageAllGraph) - noOfPAges
      for (let i = this.activePageAllGraph - start; i <= noOfPAges; i++)this.paginationArrayAllGraph.push(i)
    }
  }

  checklistDashboardChart
  groupedBarchart() {
    let data = []
    let max: number = 0
    if (this.checklistDashboard['checklist']) {
      for (let i = 1; i < this.checklistDashboard['checklist'].length; i++) {
        data.push({ 'group': this.checklistDashboard['checklist'][i][0], 'Completed Inspections': this.checklistDashboard['checklist'][i][1], 'Incomplete Inspections': this.checklistDashboard['checklist'][i][2] })

        if (max < this.checklistDashboard['checklist'][i][1]) max = this.checklistDashboard['checklist'][i][1]
        if (max < this.checklistDashboard['checklist'][i][2]) max = this.checklistDashboard['checklist'][i][2]
      }
    }
    var subgroups = ['Completed Inspections', 'Incomplete Inspections']
    var groups = []
    for (let i of data) groups.push(i.group)
    if (data.length < 10) {
      for (let i = data.length; i <= 10; i++) {
        let obj = {}
        let str = " "
        for (let j = 0; j < i; j++) str = str + " "
        obj['group'] = str
        for (let d of subgroups) obj[d] = 0


        data.push(obj)
        groups.push(str)
      }
    }

    var margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = this.fixedIWdth,
      height = 500 - margin.top - margin.bottom;
    // append the svg object to the body of the page
    var svgAxis = d3.select("#groupedChartYAxisChecklist")
      .append("svg")
      .attr("width", 50)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + 40 + "," + margin.top + ")");
    // append the svg object to the body of the page
    var svg = window.d3.select("#groupedChartChecklist")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 600)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");
    var x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2])
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      // .attr("transform", "rotate(-65)")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .style("text-anchor", "end")

      .attr("transform", "rotate(-80)");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, (max + 5)])
      .range([height, 0]);
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));

    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));


    // Another scale for subgroup position?
    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    // color palette = one color per subgroup
    var color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(['#6FCF97', '#EF6B6B'])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key, i) {
          return { key: key, value: d[key], date: d['group'] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function (d) {
        let data = (d.key != 'Incomplete Inspections') ? xSubgroup(d.key) : (xSubgroup(d.key))
        return data;
      })
      .on('click', function (s, i) {
        var flag = 1;
        if (s.key == 'Incomplete Inspections') {
          flag = 2
        }
        this.complianceStatus(flag, s.date);
      }.bind(this))
      .attr("y", function (d) { return y(d.value); })
      .attr("width", 15)
      .attr("height", function (d) { return height - y(d.value); })
      .attr("fill", function (d) { return color(d.key); })


    // .text(function(d) { return d.value });
    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key) {

          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("text")
      .text(function (d) {
        if (d.value != 0) return d.value;
      })
      .attr("x", function (d) {
        let data = (xSubgroup(d.key) + 5)

        return data;
      })
      .attr("y", function (d) {
        return y(d.value) - 5;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("fill", function (d) { return color(d.key); })
      .attr("text-anchor", "middle");


    //  this.exportAsPDF();
    // let data2 = "data:image/svg+xml;base64,";
    // let svgString = svg['_parents'][0][0].outerHTML;
    // let base64 = window.btoa(svgString);
    // let dataURI = data2 + base64;


    // document.querySelector("iframe").src = dataURI;

    // d3.selectAll("g.myYaxis g.tick")
    // .append("line")
    // .attr("class", "gridline")
    // .attr("x1", 0)
    // .attr("y1", 0)
    // .attr("x2", width)
    // .attr("y2", 0);

    // d3.selectAll("g.myXaxis g.tick")
    // .append("line")
    // .attr("class", "gridline")
    // .attr("x1", 0)
    // .attr("y1", -height)
    // .attr("x2", 0)
    // .attr("y2", 0);
  }

  complianceStatus(flag, year) {
    var obj1 = {
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "name": this.selectedFiler.name ? this.selectedFiler.name : "",
      "departmentId": this.selectedFiler.department ? this.selectedFiler.department.id : "",
      "date": moment(year).format('YYYY-MM-DD'),// "2022-03-01",
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "flag": flag
    }

    this.storedData.graphIndex = {
      headers: ['Inspection Name', 'Schedule Name', 'Property'],
      obj: obj1,
      api: 'todays-checklists/checklistreport',
      tableName: 'Compliance Status',
      dataSource: ['inspection_name', 'schedule_name', 'outlet'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("graphIndex", JSON.stringify(this.storedData.graphIndex));
    this.route.navigate(['/graph-list'])
  }

  openMostComplainCusatomer(id) {
    // this.storedData.dashBoardIndex={headers:['Name' , 'Property' , 'Checklist submission count'] , api:'todays-checklists/checklistmostcompleteuserreport' , tableName:'Most Complaining Customers' , dataSource:['userName' ,'outletName' , 'completedScheduled'] , apiObj:['data']['compliantTeam'] , rowCountParam:'count' }
    // this.route.navigate(['/dashboard-index/'+id])    
  }
  openListfreqMarkedUser(id) { }


  // exportAsPDF(div_id) {
  //   let data = document.getElementById(div_id);
  //   html2canvas(data).then(canvas => {
  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
  //     // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
  //     pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
  //     pdf.save('Filename.pdf');
  //   });
  // }


  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;

    if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#groupedChartYAxisChecklist > *").remove();
    d3.selectAll("#groupedChartChecklist > *").remove();

    this.groupedBarchart()
  }

  calculatePercentage() {
    if (this.checklistDashboardToday) {
      if (this.checklistDashboardToday.totalChecklistsScheduled != 0 && this.checklistDashboardToday.completedChecklists != 0) {
        if (this.staticDashboardFlag == true) {
          return ((Number((this.checklistDashboardToday.completedChecklists.replace(/,/g, "") / this.checklistDashboardToday.totalChecklistsScheduled.replace(/,/g, "")) * 100).toFixed(0)));
        } else {
          return ((Number((this.checklistDashboardToday.completedChecklists / this.checklistDashboardToday.totalChecklistsScheduled) * 100).toFixed(0)));
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }

  getIncompleteInspectionReportMonthlyData
  getMostIncompleteInspectionReport() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      pageNo: this.activePage,
      limit: this.pageSize,
    }
    this.apiService.startLoader();
    this.apiService.postCall('todays-schedules/getMostIncompleteInspectionReport', {}, body).subscribe((result: any[]) => {
      this.getIncompleteInspectionReportMonthlyData = result['data']['mostIncompleteChecklist'];
      this.apiService.stopLoader()
    })
  }
  getInspectionReportMonthlyDataGraph
  getInspectionReportMonthlyDataFind() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      pageNo: this.activePage,
      limit: this.pageSize,
    }
    this.apiService.startLoader();
    this.apiService.postCall('todays-schedules/getInspectionReportMonthlyDataFind', {}, body).subscribe((result: any[]) => {
      this.getInspectionReportMonthlyDataGraph = result['data']['dashboardMonthData']
      if (this.getInspectionReportMonthlyDataGraph.length != 0) {
        this.chartBarComplance = {
          type: 'ColumnChart',
          data: this.getInspectionReportMonthlyDataGraph,
          chartColumns: ['Month', 'In-Completed', 'Completed'],
          options: {
            hAxis: {
              title: 'Month',
              //ticks: [0, 20, 40, 60, 80, 100]
            },
            vAxis: {
              title: 'Total',
              minValue: 0
            },
            legend: { position: 'right', maxLines: 1 },
            colors: ['#ff4a73', '#3776b9'],
            isStacked: true
          }
        };
      } else {
        this.chartBarComplance = {
          type: 'ColumnChart',
          data: [
            // ['Month', 'Completed', 'Incompleted'],
            ['Jan', 0, 0],
            ['Feb', 0, 0],
            ['Mar', 0, 0],
            ['Apr', 0, 0],
            ['May', 0, 0],
            ['Jun', 0, 0],
            ['Jul', 0, 0],
            ['Aug', 0, 0],
            ['Sep', 0, 0],
            ['Oct', 0, 0],
            ['Nov', 0, 0],
            ['Dec', 0, 0],
          ],
          chartColumns: ['Month', 'In-Completed', 'Completed'],
          options: {
            hAxis: {
              title: 'Month',
              //ticks: [0, 20, 40, 60, 80, 100]
            },
            vAxis: {
              title: 'Total',
              minValue: 0
            },
            legend: { position: 'right', maxLines: 1 },
            colors: ['#ff4a73', '#3776b9'],
            isStacked: true
          }
        };
      }
      this.apiService.stopLoader()
    })
  }
  totalnoOfPages
  activePage: any = 1
  pageSize = 10;
  inspectionList
  getInspectionReportDataFind() {
    const date = new Date();
    let satrtDate = new Date()
    let endDate = new Date()
    if (this.selectedFiler.dateRange) {
      this.selectedFiler.startDate = moment(this.selectedFiler.dateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.ensDate = moment(this.selectedFiler.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      groupBy: this.selectedFiler.groupBy ? this.selectedFiler.groupBy.id : "",
      sortBy: this.selectedFiler.sortBy ? this.selectedFiler.sortBy.id : "",
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      name: this.selectedFiler.name ? this.selectedFiler.name : "",
      inspectionsName: this.selectedFiler.inspectionsName ? this.selectedFiler.inspectionsName : "",
      completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
      frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
      percentageCompletion: this.selectedFiler.percentageCompletion ? this.selectedFiler.percentageCompletion.id : "",
      pageNo: this.activePage,
      limit: this.pageSize,
    }
    this.apiService.startLoader();
    this.apiService.postCall('todays-checklists/inspectionReportDataFind2', {}, body).subscribe((result: any[]) => {
      this.inspectionList = result['data']
      this.setPaginationArray(result)
      this.apiService.stopLoader()
    })
  }
  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      this.getInspectionReportDataFind()
    }
  }
  changePage(ev) {
    this.getInspectionReportDataFind()
  }
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
  }
  getFrequency(d) {
    if (d.scheduleType == 1) return "Once"
    if (d.scheduleType == 2) return "Every day"
    if (d.scheduleType == 3) return "Every week"
    if (d.scheduleType == 4) return "Every month"
    if (d.scheduleType == 7) return "Every quarterly"
    if (d.scheduleType == 6) {

      let str = ""

      if (d.customType == 1) return "Every " + d.repeatDays + " Days"
      else if (d.customType == 2) return "Every " + d.repeatDays + " Week"
      else if (d.customType == 3) return "Every " + d.repeatDays + " Month"
      else if (d.customType == 4) return "Every " + d.repeatDays + " Year"
    }
  }
}
