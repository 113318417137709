import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parts-inventory',
  templateUrl: './parts-inventory.component.html',
  styleUrls: ['./parts-inventory.component.css']
})
export class PartsInventoryComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, public router: Router, public apiService: ApiService, public root: Router) { }

  businessId = 0
  outletId = 0

  migratePermission = false
  addPermission = false
  bulkAddPermission = false
  ViewPermission = true
  changeStatusPermission = false


  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ ticketList: 'openTicket', assetList: 'allasset', inspectionList: 'todaysReport', partsList: this.tabSelected, maintananceList: 'today' }))

  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    // console.log(k)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 48 || (k > 48 && k <= 57));
  }

  getIds(ev) {
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property = ev.property
    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);

    // this.applyFilter();

  }

  ngOnInit() {
    if (this.router.url.split("/")[2]) {
      this.tabSelected = this.router.url.split("/")[2];
      if (this.tabSelected == 'allparts') {
        this.allPartsTabSelect();
      } else if (this.tabSelected == 'migrate') {
        this.migrateTabSelect();
      }
    }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).partsList
    }
    var partsPagination = localStorage.getItem('partsPagination');
    if (partsPagination) {
      this.activePage = parseInt(partsPagination);
    }

    if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      this.businessId = this.apiService.buisnessId;
      if (this.apiService.roleId == 3) {
        this.outletId = this.apiService.outletId;
        this.getPropertyList(this.businessId, this.apiService.authId);
      } else {
        this.getPropertyList(this.businessId, 0);
      }
    }

    if (this.apiService.roleId == 1) {
      this.getPropertyList(0, 0);
    }
    if (this.tabSelected == 'allparts') {
      this.getParts();
    } else if (this.tabSelected == 'partConsume') {
      this.partConsumeList();
    } else {
      this.getMigrationList()
    }
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 5 && item['subModuleName'] == "Parts & Inventory");
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.addPermission = true
      }
      if (permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Bulk Add" && permission[i]['actionStatus'] == 1) {
        this.bulkAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Migrate" && permission[i]['actionStatus'] == 1) {
        this.migratePermission = true
      }
      if (permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1) {
        this.changeStatusPermission = true
      }
    }
  }


  selectedParts = []
  selectedPartsDetailsList = []
  paginationArray = []
  outletList = []
  activePage: any = 1
  pageSize = 10
  assetList: any;
  selectedFiler = { business: null, property: null, status: null, departmentId: null, name: null, type: null, partNo: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, categoryType: null }
  sortType = "desc"
  sortBy = 'partId'
  tabSelected = 'allparts'
  partsList = []
  statusList = []

  applyFlag = false;
  getAssets() {

  }

  jumpToOpen

  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }


  statusSelection: any;
  selectStatus(d) {
    // console.log(d)
    if (this.statusSelection != 'status' + d.id) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }

  message
  changeStatusInitRe(status, d, type) {
    // console.log(d.statusCheck)
    this.statusSelection = undefined;
    if (status != 'ReInitialize') {
      const body = {
        authId: this.apiService.userId,
        id: d.id,
        type: d.type
      };
      if (status == 'markasretunr') body['status'] = 3
      else if (status == 'markaslost') body['status'] = 4
      else if (status == 'Approve') body['status'] = 1
      else if (status == 'Reject') body['status'] = 2

      this.apiService.startLoader()
      this.apiService.postCall('part-transfers/changeStatus/' + d.id, {}, body).subscribe((result: any[]) => {
        // console.log(result);

        this.apiService.stopLoader()
        // this.message = result["msg"];  
        if (status == 'markasretunr') this.message = "Part returned to original location successfully";
        else if (status == 'markaslost') this.message = "Part Marked as Lost";
        else if (status == 'Reject') this.message = "Part(s) transfer cancelled successfully";
        else if (status == 'Approve') this.message = "Part successfully transferred";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.getMigrationList()
        this.getAssets()
      }, error => {
        this.apiService.stopLoader()
      }
      )
    } else {

      // console.log(d)

      this.root.navigateByUrl('/part-transfer-add?partTId=' + d.id);

      // d.assetId = d.assetManagementId
      // d.assetName = d.assetManagementName

      // d.propertyId = d.outletId
      // d.propertyName = d.outletName
      // this.storedData.selectedTransferArray = [d];
      // this.storedData.selectedTransferPermanentArray = [d];
      // this.route.navigate(['/migrate-asset'])


      // const body ={ authId:1 ,id:d.id };

      // this.apiService.startLoader()
      // this.apiService.postCall('asset-transfers/reInitialize',{} , body ).subscribe((result: any[])=>{
      //   // console.log(result);

      //   this.apiService.stopLoader()
      //   this.message = result["msg"];  
      //   (<HTMLInputElement>document.getElementById('openSuccess')).click();
      //   this.getTransitList()
      // }
      // )
    }
  }


  trPartId = 0;
  clickOnReturn(d) {
    this.totalTransfer = d.quantity
    this.trPartId = d.id
    this.usedQuantity = 0
    this.lostQuantity = 0
  }

  totalTransfer = 0
  usedQuantity = 0
  lostQuantity = 0
  finalReturnQuantity = 0
  makeReturn() {
    const body = {
      authId: this.apiService.userId,
      id: this.trPartId,
      type: 1,
      status: 3,
      totalTransfer: this.totalTransfer,
      usedQuantity: this.usedQuantity,
      lostQuantity: this.lostQuantity,
      finalReturnQuantity: (this.totalTransfer - this.usedQuantity - this.lostQuantity)
    };

    this.apiService.startLoader()
    this.apiService.postCall('part-transfers/changeStatus/' + this.trPartId, {}, body).subscribe((result: any[]) => {
      // console.log(result);

      this.apiService.stopLoader();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getMigrationList()
      this.getAssets()
    }, error => {
      this.apiService.stopLoader()
    }
    )
  }
  validateDate() {
    if (this.totalTransfer < this.usedQuantity || this.totalTransfer < this.lostQuantity || 0 > (this.totalTransfer - this.usedQuantity - this.lostQuantity)) {
      return true;
    } else {
      return false;
    }
  }



  propertyList = []
  filterpropertyList = []
  departmentList = [];
  getPropertyList(bId, userId) {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      this.departmentList = result['departments'];
    })
  }

  partNoList = []
  filterpartNoList = []
  getPartsNoList() {
    this.apiService.startLoader()
    let obj = {
      "businessId": this.apiService.buisnessId,
      "status": 1,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "propertyId": this.apiService.outletId ? this.apiService.outletId : 0,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
    }
    this.apiService.postCall('parts/index', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.partNoList = result['data']
      this.apiService.stopLoader()
      this.filterpartNoList = this.partNoList;

    })
  }
  filterPartNoSearch
  filterProperty(ev) {
    // console.log(ev)
    this.partNoList = this.filterpartNoList.filter((item) => {
      if (item.number != null) {
        return item.number.toLowerCase().includes(this.filterPartNoSearch.toLowerCase())
      }
    })
  }


  filterPropertySearch
  filterLocation(ev) {
    // console.log(ev)

    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    }
    )

  }


  getParts(): void {
    this.tabSelected = 'allparts';
    this.setTabSelection();
    let body = {}
    if (localStorage.getItem("localPartsFilter") && localStorage.getItem("localPartsFilter") != null && localStorage.getItem("localPartsFilter") != 'null') {
      let localPartsFilter = JSON.parse(localStorage.getItem("localPartsFilter"));
      body = localPartsFilter;
      if (localPartsFilter.propertyId && localPartsFilter.propertyId != null && localPartsFilter.propertyId != 'null') {

        this.selectedFiler.property = { id: localPartsFilter.propertyId, outletName: localPartsFilter.frontOutletNameFilter }
      }
      this.selectedFiler.name ? localPartsFilter.name : null
      this.selectedFiler.categoryType ? localPartsFilter.categoryType : null
      this.selectedFiler.partNo ? localPartsFilter.partNo : null
      if (localPartsFilter.status && localPartsFilter.status != null && localPartsFilter.status != 'null') {
        this.selectedFiler.status = { id: localPartsFilter.status, name: localPartsFilter.frontStatusName }
      }
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        businessId: this.apiService.buisnessId,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
        name: this.selectedFiler.name ? this.selectedFiler.name : null,
        categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
        partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
        status: this.selectedFiler.status ? parseInt(this.selectedFiler.status) : null,
        userId: this.apiService.authId,
        "roleId": this.apiService.roleId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localPartsFilter', JSON.stringify(body));
    }

    this.partsList = []
    // console.log("Body", body);
    this.apiService.startLoader()
    this.apiService.postCall('parts/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.partsList = result["data"];
      this.statusList = result['statusList'];

      if (this.statusList) {
        // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
        // this.statusList.splice(idx,1)
        // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
        // this.statusList.splice(idx1,1)
        let idx2 = this.statusList.findIndex(x => x.name == "Breakdown")
        this.statusList.splice(idx2, 1)
      }
      this.setPaginationArray(result)
    })
  }
  pagination(d) {
    localStorage.removeItem('localPartsFilter');
    localStorage.setItem("localPartsFilter", null);
    localStorage.removeItem('localPartTransferFilter');
    localStorage.setItem("localPartTransferFilter", null);
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.partsList = [];
      this.activePage = d
      localStorage.setItem('partsPagination', d);
      if (this.tabSelected == 'migrate') {
        this.getMigrationList();
      } else if (this.tabSelected == 'partConsume') {
        this.partConsumeList();
      } else {
        this.getParts();
      }
    }
  }
  changePage(ev) {
    localStorage.removeItem('localPartsFilter');
    localStorage.setItem("localPartsFilter", null);
    localStorage.removeItem('localPartTransferFilter');
    localStorage.setItem("localPartTransferFilter", null);
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    // console.log(this.pageSize)
    if (this.tabSelected == 'migrate') {
      this.getMigrationList();
    } else if (this.tabSelected == 'partConsume') {
      this.partConsumeList();
    } else {
      this.getParts();
    }
  }


  showFilter() {
    let ele = (<HTMLInputElement>document.getElementById('filterSection'))
    if (ele.style.display == "block") ele.style.display = "none"
    else ele.style.display = "block"
  }

  getFilterStatus() {
    // let count = 0;
    // if (this.applyFor == 1) {
    //   if (this.tabSelected == 'migrate') {
    //     return `(None Selected)`
    //   }
    // } else if (this.applyFor == 2) {
    //   if (this.tabSelected == 'allparts') {
    //     return `(None Selected)`
    //   }
    // } else if (this.applyFor == 3) {
    //   if (this.tabSelected == 'partConsume') {
    //     return `(None Selected)`
    //   }
    // }

    // // // console.log(JSON.stringify(this.selectedFiler))
    // let length = JSON.stringify(this.selectedFiler).split(null).length - 1
    // if (length == 11 || !this.applyFlag) return "(None Selected)"
    // else if (this.applyFlag) return `(${11 - length} Filter Selected)`
    let length = 0;
    if (this.selectedFiler.partNo != null) length = length + 1
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.categoryType != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.departmentId != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  selectAllAssets(ev) {
    this.selectedParts = []
    if (ev.target.checked) {
      for (let part of this.partsList) {
        this.selectedParts.push(part.partId)
        this.selectedPartsDetailsList.push(part)
      }
    } else {
      this.selectedParts = []
      this.selectedPartsDetailsList = []
    }
    // console.log(this.selectedParts)
  }

  selectAsset(ev, part, i) {

    this.partsList.map(x => x.selected = false)
    // console.log(ev.target.checked, part)

    this.partsList[i].selected = ev.target.checked;
    if (ev.target.checked) {
      // this.selectedParts.push(part.partId)
      // this.selectedPartsDetailsList.push(part)

      this.selectedParts[0] = part.partId
      this.selectedPartsDetailsList[0] = part.partId
    } else {
      this.selectedParts = []
      this.selectedPartsDetailsList = []
      // this.selectedParts.splice(this.selectedParts.findIndex(x => x == part.partId), 1)
      // this.selectedPartsDetailsList.splice(this.selectedParts.findIndex(x => x.partId == part.partId), 1)
    }

    // console.log(this.selectedParts)
  }

  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  applyFor = 0
  applyFilter() {
    localStorage.removeItem('localPartsFilter');
    localStorage.setItem("localPartsFilter", null);
    localStorage.removeItem('localPartTransferFilter');
    localStorage.setItem("localPartTransferFilter", null);
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    this.applyFlag = true;
    // console.log(this.selectedFiler)
    this.activePage = 1;
    // let outlId = this.outletId;
    // if(this.selectedFiler.property){
    //   outlId = this.selectedFiler.property.id
    // }

    if (this.tabSelected == 'migrate') {
      this.applyFor = 2
      let body = {}
      if (localStorage.getItem("localPartTransferFilter") && localStorage.getItem("localPartTransferFilter") != null && localStorage.getItem("localPartTransferFilter") != 'null') {
        let localPartTransferFilter = JSON.parse(localStorage.getItem("localPartTransferFilter"));
        body = localPartTransferFilter;
        if (localPartTransferFilter.propertyId && localPartTransferFilter.propertyId != null && localPartTransferFilter.propertyId != 'null') {

          this.selectedFiler.property = { id: localPartTransferFilter.propertyId, outletName: localPartTransferFilter.frontOutletNameFilter }
        }
        this.selectedFiler.name ? localPartTransferFilter.name : null
        this.selectedFiler.partNo ? localPartTransferFilter.partNo : null
        this.selectedFiler.categoryType ? localPartTransferFilter.categoryType : null
        if (localPartTransferFilter.type && localPartTransferFilter.type != null && localPartTransferFilter.type != 'null') {
          this.selectedFiler.type ? localPartTransferFilter.type : null
        }
      } else {
        body = {
          pageNo: this.activePage,
          limit: this.pageSize,
          sortType: this.sortType,
          sortBy: this.sortBy,
          businessId: this.apiService.buisnessId ? this.apiService.buisnessId : null,
          propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
          departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
          partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
          name: this.selectedFiler.name ? this.selectedFiler.name : null,
          status: this.selectedFiler.status ? parseInt(this.selectedFiler.status) : null,
          type: this.selectedFiler.type ? this.selectedFiler.type : null,
          categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
          roleId: this.apiService.roleId,
          userId: this.apiService.authId,
          frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
          zoneId: this.selectedFiler.zoneId,
          cluserId: this.selectedFiler.cluserId,
          stateId: this.selectedFiler.stateId,
          cityId: this.selectedFiler.cityId,
          clientId: this.selectedFiler.clientId,
          //frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        };
        localStorage.setItem('localPartTransferFilter', JSON.stringify(body));
      }
      // console.log("Body", body);
      this.apiService.startLoader()
      this.apiService.postCall('part-transfers/index/' + this.businessId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.partsList = result["data"];
        this.statusList = result['statusList'];
        this.setPaginationArray(result)
      })
    } else if (this.tabSelected == 'partConsume') {
      this.applyFor = 3;
      let body = {}
      if (localStorage.getItem("localPartConsumeFilter") && localStorage.getItem("localPartConsumeFilter") != null && localStorage.getItem("localPartConsumeFilter") != 'null') {
        let localPartConsumeFilter = JSON.parse(localStorage.getItem("localPartConsumeFilter"));
        body = localPartConsumeFilter;
        if (localPartConsumeFilter.propertyId && localPartConsumeFilter.propertyId != null && localPartConsumeFilter.propertyId != 'null') {

          this.selectedFiler.property = { id: localPartConsumeFilter.propertyId, outletName: localPartConsumeFilter.frontOutletNameFilter }
        }
        this.selectedFiler.name ? localPartConsumeFilter.name : null
        this.selectedFiler.partNo ? localPartConsumeFilter.partNo : null
        this.applyFlag = true;
      } else {
        body = {
          pageNo: this.activePage,
          limit: this.pageSize,
          sortType: this.sortType,
          sortBy: this.sortBy,
          partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
          propertyId: this.selectedFiler.property ? parseInt(this.selectedFiler.property.id) : null,
          departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
          name: this.selectedFiler.name ? this.selectedFiler.name : null,
          roleId: this.apiService.roleId,
          userId: this.apiService.authId,
          "businessId": this.apiService.buisnessId,
          frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
          frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
          zoneId: this.selectedFiler.zoneId,
          cluserId: this.selectedFiler.cluserId,
          stateId: this.selectedFiler.stateId,
          cityId: this.selectedFiler.cityId,
          clientId: this.selectedFiler.clientId,
        };
        localStorage.setItem('localPartConsumeFilter', JSON.stringify(body));
      }
      let bId = this.apiService.buisnessId;
      if (this.selectedFiler.business) {
        bId = this.selectedFiler.business.id;
      }
      this.partsList = []
      // console.log("Body", body);
      this.apiService.startLoader()
      this.apiService.postCall('part-consumes/index/' + bId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.partsList = result["data"];
        this.statusList = result['statusList'];
        this.setPaginationArray(result)
      });
    } else {
      let body = {}
      console.log(localStorage.getItem("localPartsFilter"));
      console.log(this.selectedFiler);
      if (localStorage.getItem("localPartsFilter") && localStorage.getItem("localPartsFilter") != null && localStorage.getItem("localPartsFilter") != 'null') {
        let localPartsFilter = JSON.parse(localStorage.getItem("localPartsFilter"));
        localPartsFilter.push({
          zoneId: this.selectedFiler.zoneId,
          cluserId: this.selectedFiler.cluserId,
          stateId: this.selectedFiler.stateId,
          cityId: this.selectedFiler.cityId,
          clientId: this.selectedFiler.clientId
        });
        body = localPartsFilter;
        if (localPartsFilter.propertyId && localPartsFilter.propertyId != null && localPartsFilter.propertyId != 'null') {

          this.selectedFiler.property = { id: localPartsFilter.propertyId, outletName: localPartsFilter.frontOutletNameFilter }
        }
        this.selectedFiler.name ? localPartsFilter.name : null
        this.selectedFiler.categoryType ? localPartsFilter.categoryType : null
        this.selectedFiler.partNo ? localPartsFilter.partNo : null
        if (localPartsFilter.status && localPartsFilter.status != null && localPartsFilter.status != 'null') {
          this.selectedFiler.status = { id: localPartsFilter.status, name: localPartsFilter.frontStatusName }
        }

      } else {
        body = {
          pageNo: this.activePage,
          limit: this.pageSize,
          sortType: this.sortType,
          sortBy: this.sortBy,
          businessId: this.apiService.buisnessId,
          propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
          departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
          partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
          name: this.selectedFiler.name ? this.selectedFiler.name : null,
          categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
          status: this.selectedFiler.status ? parseInt(this.selectedFiler.status) : null,
          roleId: this.apiService.roleId,
          userId: this.apiService.authId,
          frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
          frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
          zoneId: this.selectedFiler.zoneId,
          cluserId: this.selectedFiler.cluserId,
          stateId: this.selectedFiler.stateId,
          cityId: this.selectedFiler.cityId,
          clientId: this.selectedFiler.clientId,
        };
        localStorage.setItem('localPartsFilter', JSON.stringify(body));
      }
      this.applyFor = 1
      this.apiService.startLoader()
      this.apiService.postCall('parts/index/' + this.businessId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.partsList = []
        this.partsList = result['data']
        this.setPaginationArray(result)
      })
    }

  }

  goToTransfer() {
    if (this.selectedParts[0]) {
      this.root.navigateByUrl('/part-transfer-add?partId=' + this.selectedParts[0]);
    }
  }
  migrateTabSelect() {
    this.tabSelected = 'migrate';
    this.activePage = 1;
    this.pageSize = 10;
    this.clear();
    this.getMigrationList();
  }
  partConsumeTabSelect() {
    this.tabSelected = 'partConsume';
    this.activePage = 1;
    this.pageSize = 10;
    this.clear();
    this.partConsumeList();
  }
  allPartsTabSelect() {
    this.tabSelected = 'allparts';
    this.activePage = 1;
    this.pageSize = 10;
    this.clear();
    this.getParts();
  }
  clear() {
    localStorage.removeItem('localPartsFilter');
    localStorage.setItem("localPartsFilter", null);
    localStorage.removeItem('localPartTransferFilter');
    localStorage.setItem("localPartTransferFilter", null);
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    this.applyFor = 0
    this.activePage = 1
    this.selectedFiler = { business: null, property: null, status: null, departmentId: null, name: null, type: null, partNo: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, categoryType: null }
    this.filterSelect = false;
    if (this.tabSelected == 'migrate') {
      this.getMigrationList();
    } else if (this.tabSelected == 'partConsume') {
      this.partConsumeList();
    } else {
      this.getParts();
    }
  }

  sortData(data) {
    localStorage.removeItem('localPartsFilter');
    localStorage.setItem("localPartsFilter", null);
    localStorage.removeItem('localPartTransferFilter');
    localStorage.setItem("localPartTransferFilter", null);
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    if (this.tabSelected == 'migrate') {
      this.getMigrationList();
    } else if (this.tabSelected == 'partConsume') {
      this.partConsumeList();
    } else {
      this.getParts();
    }
  }

  getData() {
    if (this.tabSelected == 'migrate') {
      this.getMigrationList();
    } else if (this.tabSelected == 'partConsume') {
      this.partConsumeList();
    } else {
      this.getParts();
    }
  }
  partConsumeList(): void {
    this.tabSelected = 'partConsume';
    this.setTabSelection();
    let body = {}
    if (localStorage.getItem("localPartConsumeFilter") && localStorage.getItem("localPartConsumeFilter") != null && localStorage.getItem("localPartConsumeFilter") != 'null') {
      let localPartConsumeFilter = JSON.parse(localStorage.getItem("localPartConsumeFilter"));
      body = localPartConsumeFilter;
      if (localPartConsumeFilter.propertyId && localPartConsumeFilter.propertyId != null && localPartConsumeFilter.propertyId != 'null') {

        this.selectedFiler.property = { id: localPartConsumeFilter.propertyId, outletName: localPartConsumeFilter.frontOutletNameFilter }
      }
      this.selectedFiler.name ? localPartConsumeFilter.name : null
      this.selectedFiler.partNo ? localPartConsumeFilter.partNo : null
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
        propertyId: this.selectedFiler.property ? parseInt(this.selectedFiler.property.id) : null,
        name: this.selectedFiler.name ? this.selectedFiler.name : null,
        roleId: this.apiService.roleId,
        userId: this.apiService.authId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localPartConsumeFilter', JSON.stringify(body));
    }
    let bId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      bId = this.selectedFiler.business.id;
    }
    this.partsList = []
    // console.log("Body", body);
    this.apiService.startLoader()
    this.apiService.postCall('part-consumes/index/' + bId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.partsList = result["data"];
      this.statusList = result['statusList'];
      this.setPaginationArray(result)
    })
  }
  getMigrationList(): void {
    this.tabSelected = 'migrate';
    this.setTabSelection();
    let body = {}
    if (localStorage.getItem("localPartTransferFilter") && localStorage.getItem("localPartTransferFilter") != null && localStorage.getItem("localPartTransferFilter") != 'null') {
      let localPartTransferFilter = JSON.parse(localStorage.getItem("localPartTransferFilter"));
      body = localPartTransferFilter;
      if (localPartTransferFilter.propertyId && localPartTransferFilter.propertyId != null && localPartTransferFilter.propertyId != 'null') {

        this.selectedFiler.property = { id: localPartTransferFilter.propertyId, outletName: localPartTransferFilter.frontOutletNameFilter }
      }
      this.selectedFiler.name ? localPartTransferFilter.name : null
      this.selectedFiler.partNo ? localPartTransferFilter.partNo : null
      if (localPartTransferFilter.type && localPartTransferFilter.type != null && localPartTransferFilter.type != 'null') {
        this.selectedFiler.type ? localPartTransferFilter.type : null
      }
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        propertyId: this.selectedFiler.property ? parseInt(this.selectedFiler.property.id) : null,
        name: this.selectedFiler.name ? this.selectedFiler.name : null,
        status: this.selectedFiler.status ? parseInt(this.selectedFiler.status) : null,
        type: this.selectedFiler.type ? this.selectedFiler.type : null,
        roleId: this.apiService.roleId,
        userId: this.apiService.authId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        //frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",

      };
      localStorage.setItem('localPartTransferFilter', JSON.stringify(body));
    }
    let bId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      bId = this.selectedFiler.business.id;
    }
    this.partsList = []
    // console.log("Body", body);
    this.apiService.startLoader()
    this.apiService.postCall('part-transfers/index/' + bId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.partsList = result["data"];
      this.statusList = result['statusList'];
      this.setPaginationArray(result)
    })
  }

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }

  navigateToDetails(d) {
    this.root.navigateByUrl('/parts-details/' + d.partId);
  }
  showConsumeData
  showConsumesDatas(data) {
    this.showConsumeData = data;
  }
  changeTransferStatusSingle(d): void {
    const body = {
      status: d.status,
      authId: this.apiService.authId
    };
    // console.log(d);
    if (this.tabSelected == 'migrate') {
      // if(d.status == 4){ //ifInitilize
      //   this.root.navigateByUrl('/part-transfer-add?partTId='+d.id);
      // }
      this.apiService.startLoader()
      this.apiService.postCall('part-transfers/changeStatus/' + d.id, {}, body).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        if (result['success'] == true) {
          this.tabSelected = '';
          this.getMigrationList();
        }
      })
    } else {
      this.apiService.startLoader()
      this.apiService.postCall('parts/changeStatus/' + d.partId, {}, body).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        if (result['success'] == true) {
          this.tabSelected = '';
          this.getParts();
        }
      })
    }
  }
  filterSelect = false;
  businessFilter = false;
  propertyFiletr = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.filterSelect = true;
      this.businessFilter = false;
      this.propertyFiletr = false;
    } else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.businessFilter = false;
      this.propertyFiletr = false;
      this.dropdownShow = false;
    }
    if (btn.id == 'changeBUton') {
    } else if (btn.id != 'changeBUton') {
      this.statusSelection = undefined
    }

    // console.log(this.dropdownShow)
  }
  closeAll() {
  }


  dropdownShow = false;
  uploadedFiles
  fileName
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }
  getLocationName(locationName) {
    if (locationName) {
      return locationName.length <= 15 ? locationName : locationName.substring(0, 15) + '...';
    } else {
      return "";
    }
  }
  getValidation() {
    if (!this.uploadedFiles) {
      return false;
    }
    return true;
  }
  errorMessages = []
  uploadWarranty() {

    let testData: FormData = new FormData();
    if (!this.uploadedFiles) {
      return;
    }
    this.errorMessages = []
    testData.append('file', this.uploadedFiles);
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/bulkupload', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.uploadedFiles = null
      this.fileName = null
      this.clear()
    })
  }

}
