import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-schedules-holidays',
  templateUrl: './schedules-holidays.component.html',
  styleUrls: ['./schedules-holidays.component.css']
})
export class SchedulesHolidaysComponent implements OnInit {

  constructor(public router: Router, private fb: FormBuilder, public _location: Location, public storedData: StoredData, public apiService: ApiService) { }
  scheduleHoliday: FormGroup
  scheduleList = []
  type: any;
  message = ""
  multipleTimesHoliday = []
  dropdownSettings: IDropdownSettings;
  dropdownSettingsProperty: IDropdownSettings;
  ngOnInit(): void {
    this.type = this.router.url.split("/")[1]
    this.scheduleHoliday = this.fb.group({
      property: [null, Validators.required],
      multipleTimesHoliday: this.fb.array([]),
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'scheduleName',
      enableCheckAll: true,
      allowSearchFilter: true
    };
    this.dropdownSettingsProperty = {
      singleSelection: true,
      idField: 'id',
      textField: 'outletName',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.getOutLetList();
  }

  propertyList = []
  getOutLetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data'];
      this.apiService.stopLoader()
    },
      error => {
        this.apiService.stopLoader()
      });
  }
  propertySelected
  onItemSelectProperty(item: any) {
    this.propertySelected = item.id;
    this.scheduleList = [];
    this.scheduleListArray = [];
    this.onUnSelectAll();
    this.scheduleHoliday.value.property = item.id;
    this.getScheduleList(item.id);
  }
  onItemDeSelectProperty(item: any) {
    this.propertySelected = null;
    this.onUnSelectAll();
    this.scheduleHoliday.value.property = null;
  }
  scheduleListArray = [];
  getScheduleList(propertyId) {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": propertyId,
    }
    this.apiService.postCall('Schedules/scheduleListPropertyWise/' + propertyId, {}, obj).subscribe((result: any[]) => {
      this.scheduleListArray = result['data'];
      this.apiService.stopLoader()
    },
      error => {
        this.apiService.stopLoader()
      });
  }
  onSelectAll(items: any) {
    for (let i = 0; i < items.length; i++) {
      this.scheduleList.push(items[i].id);
    }
  }
  onUnSelectAll() {
    this.scheduleList = []
  }
  onItemSelect(item: any) {
    this.selectingFlag = true;
    if (this.scheduleList.indexOf(item.id) == -1) {
      this.scheduleList.push(item.id)
    }
    console.log(this.scheduleList);
  }
  selectingFlag
  onItemDeSelect(item: any) {
    let idx = this.scheduleList.findIndex(x => x.id == item.id)
    this.scheduleList.splice(idx, 1)
    if (this.scheduleList.length == 0) {
      this.selectingFlag = false;
    }
    console.log(this.scheduleList);
  }
  initChecklistHoliday(data: any | null) {
    if (data != undefined) {
      // console.log(data.name);
      return this.fb.group({
        name: [data['name'], Validators.required]
      });
    } else {
      return this.fb.group({
        checklistId: [null],
        name: [null, Validators.required]
      });
    }
  }
  get formChecklistArrHoliday() {
    return this.scheduleHoliday.get('multipleTimesHoliday') as FormArray;
  }
  addMoreChecklistHoliday(data?): void {
    this.formChecklistArrHoliday.push(this.initChecklistHoliday(data));
  }
  removeChecklistsHoliday = []
  removeChecklistHoliday(i) {
    // console.log(this.formChecklistArr)
    if (this.formChecklistArrHoliday.at(i).get('id')) {
      this.removeChecklistsHoliday.push(this.formChecklistArrHoliday.at(i).get('id')['value']);
    }
    //if(i != 0)
    this.formChecklistArrHoliday.removeAt(i)
  }
  submit() {
    let isMultipleTimessHoliday = [];
    for (let d of this.scheduleHoliday.value.multipleTimesHoliday) { isMultipleTimessHoliday.push({ name: d.name ? moment(d.name).format('YYYY-MM-DD') : "" }) }
    let body = {
      outletId: this.scheduleHoliday.value.property,
      scheduleListId: this.scheduleList,
      isMultipleTimessHoliday: isMultipleTimessHoliday,
    }
    // console.log(body)
    this.apiService.startLoader()
    this.apiService.postCall('schedules/addMultipleHoliday', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    }
    )
  }

  validateFor() {
    if (this.scheduleList.length == 0) {
      return false
    }
    return this.scheduleHoliday.valid
  }

  goBack(): void {
    this._location.back();
  }


}
