


import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder,Validators } from '@angular/forms';
import * as moment from 'moment';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-warranty-support-history-add',
  templateUrl: './warranty-support-history-add.component.html',
  styleUrls: ['./warranty-support-history-add.component.css']
})
export class WarrantySupportHistoryAddComponent implements OnInit {

  
  constructor(
    public router: Router,
    public _location: Location,
    public apiService: ApiService,
    public formBuilder:FormBuilder,
    public storedData:StoredData
  ){}

  settings = {
    bigBanner: false,
    timePicker: true,
    format: 'hh:mm a',
    defaultOpen: false
}


  assetId
  amcId
  partList
  userList
  reasonList=[  ]

  amcServiceForm = this.formBuilder.group({
    assetId:[null],
    amcId:[null],
    reason:[null,Validators.required],
    startDate:[null ,Validators.required],
    startTime:[null ,Validators.required],
    endTime:[null ,Validators.required],
    endDate:[null ,Validators.required],
    site:[false],
    comments:[null],
    authId:this.apiService.authId,
    userId:[null ,  Validators.required],
    // teams: this.formBuilder.array([this.initTeams()]),
    partsRepaired: this.formBuilder.array([this.initPartsRepaired()]),
    partsReplaced: this.formBuilder.array([this.initPartsReplaced()]),
    images:this.formBuilder.array([this.initImages()]),
    ticket:[null]
  });
amcHistoryId:any;
ngOnDestroy(){
  document.body.style.overflowY = 'scroll';
}
  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    // console.log(this.todayDate)
    document.body.style.overflow = 'hidden';
    this.assetId = this.router.url.split("/")[2]; 
    //this.amcId = this.router.url.split("/")[3];
    this.amcHistoryId = this.router.url.split("/")[3];
    this.getUserList();
    // this.getPartList();
    
 
  }

  patchValueAMC(){
    // this.storedData.amcDetails= {"id":116,"assetId":80,"amcId":61,"reason":2,"startDate":"2021-12-14T15:46:59+05:30","endDate":"2021-12-31T17:47:00+05:30","site":1,"comments":null,"createdAt":"31-12-2021 03:47:00 PM","modifiedAt":"31-12-2021 10:17:00 AM","partCount":2,"teams":"asasa,asas,,asasa","partsRepaired":[{"id":68,"name":"test name","partId":53,"quentity":2,"price":400,"type":1}],"partsReplaced":[],"images":[["http://52.14.126.174/synccit/img/311220211547Screenshot (5).png"]],"warrantyExpirations":[],"amcExpirations":[],"assetLocationChange":[]}
    // console.log("++++++++++++++++++++++++++++++++++++++"+JSON.stringify(this.storedData.amcDetails))
    if(this.storedData.amcDetails){
    this.amcServiceForm.patchValue({
      assetId:this.storedData.amcDetails.assetId,
      amcId:this.storedData.amcDetails.amcId,
      reason:this.storedData.amcDetails.reason,
      startDate:this.storedData.amcDetails.startDate,
      startTime:this.storedData.amcDetails.startDate,
      endTime:this.storedData.amcDetails.endDate,
      endDate:this.storedData.amcDetails.endDate,
      site:this.storedData.amcDetails.site? 2 : 1,
      comments:this.storedData.amcDetails.comments,
      authId:this.apiService.authId,
      userId:this.storedData.amcDetails.teams,
      // teams: this.formBuilder.array([this.initTeams()]),
      // partsRepaired: ,
      // partsRepaired: this.formBuilder.array([]),
      // partsReplaced: this.formBuilder.array([]),
      ticket:this.storedData.amcDetails.ticket
    });

    this.formPartRepairedArr.removeAt(0)
    this.formPartsReplacedArr.removeAt(0)
    // this.removePartsPeplaced(1)

    for(let d of this.storedData.amcDetails.images) {
      d = JSON.stringify(d)
      // let string:any = d.split("/")[5]
      this.attchments.push({fileName:d.split("/")[5]})
    
    }
    this.addMorePartsReplacedDefault(this.storedData.amcDetails.partsReplaced)
    this.addMorePartsRepairedDefault(this.storedData.amcDetails.partsRepaired)

    // console.log(this.amcServiceForm.value.partsRepaired)
  }else{
    this.getAmcServiceDetail();
  }
  }
  getAmcServiceDetail(){
    this.apiService.startLoader()
    this.apiService.getCall('amc-services/warrantyView/'+this.amcHistoryId,{}  ).subscribe((result: any[])=>{
      //
      result = result['data']
      this.apiService.stopLoader();

      this.amcServiceForm.patchValue({
        assetId:result['assetId'],
        amcId:result['amcId'],
        reason:result['reason'],
        startDate:result['startDate'],
        startTime:result['startDate'],
        endTime:result['endDate'],
        endDate:result['endDate'],
        site:result['site'] == 1? true : false,
        scheduled:result['schedule_unschedule'] == "" ? 0 : 1,
        comments:result['comments'],
        authId:this.apiService.authId,
        userId:result['teams'],
        // teams: this.formBuilder.array([this.initTeams()]),
        // partsRepaired: ,
        // partsRepaired: this.formBuilder.array([]),
        // partsReplaced: this.formBuilder.array([]),
        ticket:result['ticket']
      });
  
      this.formPartRepairedArr.removeAt(0)
      this.formPartsReplacedArr.removeAt(0)
  
      for(let d of result['images']) {
        d = JSON.stringify(d)
        //
        this.attchments.push({fileName:d.split("/")[7]})
      }
      this.addMorePartsReplacedDefault(result['partsReplaced'])
      this.addMorePartsRepairedDefault(result['partsRepaired'])
      

    })
  }
  userSelectedList=[]
  selectUser(){
    // console.log(this.amcServiceForm.value.userId)
    this.userSelectedList.push(this.amcServiceForm.value.userId)

    this.amcServiceForm.patchValue({userId : null})
  }

  removeUser(d){
    let idx = this.userSelectedList.findIndex(x=> x == d)

    this.userSelectedList.splice(idx,1)
  }

  initTeams(){
    return this.formBuilder.group({
      userId:[null , Validators.required],
    });
  }
  initPartsRepairedDefault(d){
    

    let idx  = this.partList.findIndex(x=> x.partId==d.partId)
    var price =d.price * d.quentity;
   
    return this.formBuilder.group({
      id:d.id,
      partId:this.partList[idx],
      partPrice:d.price,
      quentity:d.quentity,
      partNo:this.partList[idx].number,
      totalPrice:(Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2),
      type:d.type
    })
  }

  initPartsReplacedDefault(d){

    let idx  = this.partList.findIndex(x=> x.partId==d.partId)
    var price =d.price * d.quentity;
    return this.formBuilder.group({
      id:d.id,
      partId:this.partList[idx],
      partPrice:d.price,
      quentity:d.quentity,
      partNo:this.partList[idx].number,
      totalPrice:(Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2),
      type:d.type
    })
  }
  // initPartsRepairedDefault(d){
    

  //   let idx  = this.partList.findIndex(x=> x.partId)
  //   var price =d.price * d.quentity;
   
  //   return this.formBuilder.group({
  //     partId:this.partList[idx],
  //     partPrice:d.price,
  //     quentity:d.quentity,
  //     partNo:this.partList[idx].number,
  //     totalPrice:(Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2),
  //     type:d.type
  //   })
  // }

  // initPartsReplacedDefault(d){

  //   let idx  = this.partList.findIndex(x=> x.partId)
  //   var price =d.price * d.quentity;
  //   return this.formBuilder.group({
  //     partId:this.partList[idx],
  //     partPrice:d.price,
  //     quentity:d.quentity,
  //     partNo:this.partList[idx].number,
  //     totalPrice:(Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2),
  //     type:d.type
  //   })
  // }

  addMorePartsReplacedDefault(array):void{
    if(array[0]){
    for(let d of array)this.formPartsReplacedArr.push(this.initPartsReplacedDefault(d));
    }
    else{
      this.addMorePartsReplaced()
    }
  }

  addMorePartsRepairedDefault(array):void{
    if(array[0]){
    for(let d of array) this.formPartRepairedArr.push(this.initPartsRepairedDefault(d));
    }
    else{
      this.addMorePartsRepaired()
    }
  }

  initPartsRepaired(){
    return this.formBuilder.group({
      partId:[null ],
      partPrice:[null],
      quentity:[null ,],
      partNo:[null],
      totalPrice:[null],
      type:1
    })
  }
  initPartsReplaced(){
    return this.formBuilder.group({
      partId:[null ],
      partPrice:[null],
      quentity:[null ] ,
      partNo:[null],
      totalPrice:[null],
      type:0
    })
  }

  initImages(){
    return this.formBuilder.group({
      image:['']
    })
  }

  get formUserArr() {
    return this.amcServiceForm.get('teams') as FormArray;
  }

  get formPartRepairedArr() {
    return this.amcServiceForm.get('partsRepaired') as FormArray;
  }
  get formPartsReplacedArr() {
    return this.amcServiceForm.get('partsReplaced') as FormArray;
  }

  get formImageArr() {
    return this.amcServiceForm.get('images') as FormArray;
  }

  goBack():void{
    this._location.back();
  }

  addMoreTeam():void{
    this.formUserArr.push(this.initTeams());
  }

  addMorePartsReplaced():void{
    this.formPartsReplacedArr.push(this.initPartsReplaced());
  }

  addMorePartsRepaired():void{
    this.formPartRepairedArr.push(this.initPartsRepaired());
  }

// usersListing

assetDetails
  getUserList():void{
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
    this.assetDetails = result['assetDetails']
     this.getPartList()
     this.getAssetTicektList();
    
    this.apiService.startLoader()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":result['assetDetails']['propertyId'],
    }
    this.apiService.postCall('users/user-list/'+this.apiService.buisnessId+'/'+result['assetDetails']['propertyId'], {} , obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      
        this.userList = result['data'];
        // console.log("List of Users",this.userList);
      
    });
    if(this.amcHistoryId){
      this.getAmcServiceDetail();
    }
  })
  }

  removeParts(i){
    // console.log(i)
    if(i != 0)this.formPartRepairedArr.removeAt(i)
  }
  removePartsPeplaced(i){
    // console.log(i)
    if(i != 0)this.formPartsReplacedArr.removeAt(i)
  }
  // partListing
  getPartList():void{
    this.apiService.startLoader()
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
      "status":1
    }
    this.apiService.postCall('parts/index/'+this.apiService.buisnessId,{}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
        this.partList = result['data'];
        this.partList = this.partList.filter(item => item.status !=5 && item.status !=8 );//exclude lost parts and discard
        // console.log("List of Parts",this.partList);
        this.amcHistoryId = this.router.url.split("/")[3]; 
        if(this.amcHistoryId)this.patchValueAMC()
    })

    this.apiService.startLoader()
  this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.reasonList = result['reasonServices']
    this.apiService.stopLoader()
  
})
  }

  selectPartId(i){
    this.formPartRepairedArr.at(i).patchValue({
      partPrice:this.amcServiceForm.value.partsRepaired[i].partId.pricePerPice,
      partNo:this.amcServiceForm.value.partsRepaired[i].partId.number,
    })
  }

  calculatePrice(i){
    var price =this.amcServiceForm.value.partsRepaired[i].partPrice * this.amcServiceForm.value.partsRepaired[i].quentity;
    this.formPartRepairedArr.at(i).patchValue({
      totalPrice:(Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2),
    })   
  }

  validateQuantity(i){
    // console.log(this.formPartRepairedArr.at(i).value)
    if(this.formPartRepairedArr.at(i).value.partId ){

    if(this.formPartRepairedArr.at(i).value.quentity > this.formPartRepairedArr.at(i).value.partId.quentityInHand) return true
    else return false
    }
  }


  
  selectPartIdReplaced(i){
    this.formPartsReplacedArr.at(i).patchValue({
      partPrice:this.amcServiceForm.value.partsReplaced[i].partId.pricePerPice,
      partNo:this.amcServiceForm.value.partsReplaced[i].partId.number,
    })
  }

  

  calculatePriceReplaced(i){

    var price =this.amcServiceForm.value.partsReplaced[i].partPrice * this.amcServiceForm.value.partsReplaced[i].quentity;
    this.formPartsReplacedArr.at(i).patchValue({
      totalPrice:(Math.round((price + Number.EPSILON) * 100) / 100).toFixed(2),
    })  


    // this.formPartsReplacedArr.at(i).patchValue({
    //   totalPrice:this.amcServiceForm.value.partsReplaced[i].partPrice * this.amcServiceForm.value.partsReplaced[i].quentity,
    // })   
  }

  validateQuantityPartReplaced(i){
    // console.log(this.formPartsReplacedArr.at(i).value)
    if(this.formPartsReplacedArr.at(i).value.partId ){

    if(this.formPartsReplacedArr.at(i).value.quentity > this.formPartsReplacedArr.at(i).value.partId.quentityInHand) return true
    else return false
    }
  }

  showFiles=true;
  attchments=[]
  addAttchmnet(){
    if(this.attchments.length<3){
      this.attchments.push({file:null , fileName:null})
    }
    if(this.attchments.length==3){
      this.showFiles=false
    }
  }


  editService():void{
    // let formData = this.amcServiceForm.value;
    // formData.amcId = this.amcId;
    // formData.assetId = this.assetId;
    // if(formData.site == true){
    //   formData.site=1;
    // }else{
    //   formData.site=0;
    // }
    // // console.log("AMC Form Data:",formData);

    let startDateTime=new Date(this.amcServiceForm.value.startDate)
    startDateTime.setHours(new Date(this.amcServiceForm.value.startTime).getHours())
    startDateTime.setMinutes(new Date(this.amcServiceForm.value.startTime).getMinutes())
    startDateTime.setSeconds(new Date(this.amcServiceForm.value.startTime).getSeconds())
    // console.log(startDateTime)

    let endDateTime=new Date(this.amcServiceForm.value.endDate)
    endDateTime.setHours(new Date(this.amcServiceForm.value.endTime).getHours())
    endDateTime.setMinutes(new Date(this.amcServiceForm.value.endTime).getMinutes())
    endDateTime.setSeconds(new Date(this.amcServiceForm.value.endTime).getSeconds())
    // console.log(endDateTime)

// let teamArry=[]
// for(let d of this.userSelectedList) teamArry.push(d.id)
let partsArray=[]
// console.log(this.amcServiceForm.value)
if( this.amcServiceForm.value.partsRepaired[0].partId)for(let d of this.amcServiceForm.value.partsRepaired){ partsArray.push({id:d.id ,partId:d.partId.partId ,price:d.partPrice ,quentity:d.quentity ,type: d.type  })}
if( this.amcServiceForm.value.partsReplaced[0].partId)for(let d of this.amcServiceForm.value.partsReplaced){ partsArray.push({id:d.id ,partId:d.partId.partId ,price:d.partPrice ,quentity:d.quentity ,type: d.type  })}
let imagesArray:any =[]
for(let d of this.attchments)imagesArray.push(d.file)



    let body={
          assetId:this.assetId,
          //amcId:this.amcId,
          reason:this.amcServiceForm.value.reason,
          startDate:moment(startDateTime).format('YYYY-MM-DD HH:mm:ss'),
          endDate:moment(endDateTime).format('YYYY-MM-DD HH:mm:ss'),
          site:this.amcServiceForm.value.site? 2 : 1,
          comments:this.amcServiceForm.value.comments,
          authId:this.apiService.userId,
          
          teams:this.amcServiceForm.value.userId,
          parts:partsArray,
         
          images:imagesArray[0],
          ticket : this.amcServiceForm.value.ticket
          
    }
    // console.log(this.amcServiceForm.value)
    this.apiService.startLoader()
    this.apiService.postCall('amc-services/edit/'+this.amcHistoryId,{} , body  ).subscribe((result: any[])=>{
    
        // console.log(result);
        this.apiService.stopLoader()
        this.message =result["msg"];
     
       
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        if(imagesArray.length != 0){
          let arr = []
          let testData:FormData = new FormData();
          for (let i=0 ; i <= imagesArray.length-1 ; i++ )testData.append('images['+i+']',imagesArray[i])
          
        // testData.append("images",imagesArray[0])

        this.apiService.postCall('amc-services/edit/'+result['amcId'],{} , testData  ).subscribe((result1: any[])=>{
          // console.log(result1)
        })
        }
    })
    

    
  }
  
  saveAmcService():void{
    // let formData = this.amcServiceForm.value;
    // formData.amcId = this.amcId;
    // formData.assetId = this.assetId;
    // if(formData.site == true){
    //   formData.site=1;
    // }else{
    //   formData.site=0;
    // }
    // // console.log("AMC Form Data:",formData);

    let startDateTime=new Date(this.amcServiceForm.value.startDate)
    startDateTime.setHours(new Date(this.amcServiceForm.value.startTime).getHours())
    startDateTime.setMinutes(new Date(this.amcServiceForm.value.startTime).getMinutes())
    startDateTime.setSeconds(new Date(this.amcServiceForm.value.startTime).getSeconds())
    // console.log(startDateTime)

    let endDateTime=new Date(this.amcServiceForm.value.endDate)
    endDateTime.setHours(new Date(this.amcServiceForm.value.endTime).getHours())
    endDateTime.setMinutes(new Date(this.amcServiceForm.value.endTime).getMinutes())
    endDateTime.setSeconds(new Date(this.amcServiceForm.value.endTime).getSeconds())
    // console.log(endDateTime)

// let teamArry=[]
// for(let d of this.userSelectedList) teamArry.push(d.id)
let partsArray=[]
// console.log(this.amcServiceForm.value)
if( this.amcServiceForm.value.partsRepaired[0].partId)for(let d of this.amcServiceForm.value.partsRepaired){ partsArray.push({id:d.id ,partId:d.partId.partId ,price:d.partPrice ,quentity:d.quentity ,type: d.type  })}
if( this.amcServiceForm.value.partsReplaced[0].partId)for(let d of this.amcServiceForm.value.partsReplaced){ partsArray.push({id:d.id ,partId:d.partId.partId ,price:d.partPrice ,quentity:d.quentity ,type: d.type  })}
let imagesArray:any =[]
for(let d of this.attchments)imagesArray.push(d.file)



    let body={
          assetId:this.assetId,
          // amcId:this.amcId,
          reason:this.amcServiceForm.value.reason,
          startDate:moment(startDateTime).format('YYYY-MM-DD HH:mm:ss'),
          endDate:moment(endDateTime).format('YYYY-MM-DD HH:mm:ss'),
          site:this.amcServiceForm.value.site? 1 : 0,
          comments:this.amcServiceForm.value.comments,
          authId:this.apiService.userId,
          
          teams:this.amcServiceForm.value.userId,
          parts:partsArray,
         
          images:imagesArray[0],
          ticket : this.amcServiceForm.value.ticket,
          warrentyType:1
          
    }
    // console.log(this.amcServiceForm.value)
    this.apiService.startLoader()
    this.apiService.postCall('amc-services/add',{} , body  ).subscribe((result: any[])=>{
    
        // console.log(result);
        this.apiService.stopLoader()
        this.message ="Asset warranty service added successfully";
     
       
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        if(imagesArray.length != 0){
          let arr = []
          let testData:FormData = new FormData();
          for (let i=0 ; i <= imagesArray.length-1 ; i++ ){
          if(imagesArray[i])  testData.append('images['+i+']',imagesArray[i])
          }
          
        // testData.append("images",imagesArray[0])

        this.apiService.postCall('amc-services/edit/'+result['amcId'],{} , testData  ).subscribe((result1: any[])=>{
          // console.log(result1)
        })
        }
    })
    

    
  }

  upload(){
    let imagesArray:any =[]
for(let d of this.attchments)imagesArray.push(d.file)
     let testData:FormData = new FormData();
     for (let i=0 ; i <= imagesArray.length-1 ; i++ )testData.append('images['+i+']',imagesArray[i])
          
        // testData.append("images",imagesArray[0])

        this.apiService.postCall('amc-services/edit/'+106,{} , testData  ).subscribe((result1: any[])=>{
          // console.log(result1)
        })
        
  }

  message

  getValidation(){
    // console.log(this.formPartRepairedArr , this.formPartsReplacedArr)
    let errorFlag:boolean
    if(this.formPartRepairedArr.value[0].partId){
    for(let d of this.formPartRepairedArr.value){
      if(d.quentity > d.partId.quentityInHand) {
        errorFlag = true;
        break;
      }
    }
  }

    let errorFlag2:boolean
    if(this.formPartsReplacedArr.value[0].partId){
    for(let d of this.formPartsReplacedArr.value){
      if(d.quentity > d.partId.quentityInHand) {
        errorFlag2 = true;
        break;
      }
    }
  }

    return this.amcServiceForm.valid && !this.validateDate(this.amcServiceForm.value.startDate,this.amcServiceForm.value.endDate) && !errorFlag && !errorFlag2 && this.ValidateDaterWithTIme() && !this.partValidation()
  }
  // this.attchments.push({file:null , fileName:null})

  todayDate:any = new Date();
  fileChange(event , d) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        d.file = file
        d.fileName = file.name
       
      }
    }

    removeImage(d){
      d.file= null;
      d.fileName= null;
    }

    ticletList:any
getAssetTicektList(){
  this.apiService.startLoader()

  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
  }
 
  this.apiService.postCall('tickets/index/'+this.apiService.buisnessId, {} , obj).subscribe((result: any[]) => {
    this.apiService.stopLoader()
    
      this.ticletList = result['data'];
      // console.log("List of Parts",this.partList);
    
  })
}

validateDate(satrtDate , endDate){
  // console.log(satrtDate , endDate)
if(satrtDate && endDate){
 if(endDate < satrtDate) return true
 else return false
}
}


omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}


ValidateDaterWithTIme(){

  if(this.amcServiceForm.value.startDate && this.amcServiceForm.value.endDate && this.amcServiceForm.value.startTime && this.amcServiceForm.value.endTime  ){
  let startDateTime=new Date(this.amcServiceForm.value.startDate)
  startDateTime.setHours(new Date(this.amcServiceForm.value.startTime).getHours())
  startDateTime.setMinutes(new Date(this.amcServiceForm.value.startTime).getMinutes())
  //startDateTime.setSeconds(new Date(this.amcServiceForm.value.startTime).getSeconds())
  // console.log(startDateTime)

  let endDateTime=new Date(this.amcServiceForm.value.endDate)
  endDateTime.setHours(new Date(this.amcServiceForm.value.endTime).getHours())
  endDateTime.setMinutes(new Date(this.amcServiceForm.value.endTime).getMinutes())
  //endDateTime.setSeconds(new Date(this.amcServiceForm.value.endTime).getSeconds())
  // console.log(endDateTime)

 // console.log(startDateTime , endDateTime)
  if(startDateTime && endDateTime){
    if(startDateTime < endDateTime) return true
    else return false
   }
  }
  else return true
}

partFlag
partValidation(){
  let partsArray=[]
  // let flag
  this.partFlag= undefined
  // console.log(this.amcServiceForm.value.partsReplaced[0])
  if( this.amcServiceForm.value.partsRepaired[0] && this.amcServiceForm.value.partsRepaired[0].partId)for(let d of this.amcServiceForm.value.partsRepaired){ partsArray.push({partData:d.partId, partId:d.partId.partId ,price:d.partPrice ,quentity:d.quentity ,type: d.type  })}
if( this.amcServiceForm.value.partsReplaced[0] && this.amcServiceForm.value.partsReplaced[0].partId)for(let d of this.amcServiceForm.value.partsReplaced){ 

  // console.log(partsArray , d)
  if(d.partId){
let idx = partsArray.findIndex(x=>x.partId == d.partId.partId)
let totalQuantity
if(idx != -1){
   totalQuantity = partsArray[idx].quentity + d.quentity
  if(totalQuantity > d.partId.quentityInHand) {
    this.partFlag = d.partId
  break;
  }
  }
  }


  // partsArray.push({partData:d.partId, partId:d.partId.partId ,price:d.partPrice ,quentity:d.quentity ,type: d.type 
  
  }
  

  if(this.partFlag)return true
  else return false;
  // )}



}

}
