import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { compileComponentFromMetadata } from '@angular/compiler';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-pm-templates',
  templateUrl: './pm-templates.component.html',
  styleUrls: ['./pm-templates.component.css']
})
export class PmTemplatesComponent implements OnInit {
  pageSizeClosed: number;

  constructor(public _location: Location, private route: Router, public router: Router, public apiService: ApiService, public formBuilder: FormBuilder) { }
  frequencyList = []
  responseType = []
  templateList = []
  statusGroup = []
  propertyList = []
  paginationArray = []
  pageSize = 10;
  pageNo: any = 1

  // assetList=[]
  locationList = []
  // sortSelected
  filterLocation(ev) { }
  // activePageClosed
  getSerialNoclosed(i) {
    return this.pageSize * (this.activePage - 1) + i
  }

  applyFilter() {
    localStorage.removeItem('LocalPmTemplateFilter');
    localStorage.setItem("LocalPmTemplateFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    this.getPmTemplatesList()
  }

  getMetersList(ev) { }
  applyFlag: boolean

  // sortData(ev){}

  dublicateSelected() { }
  deactiveSelected() { }

  addTempaletPermission = false
  viewTemplatePermission = false
  editTemplatePermission = false
  changeStatusTemplatePermission = false

  ngOnInit(): void {

    setTimeout(() => {
      document.body.style.overflowY = 'scroll';

    }, 4000);


    var pmtemplatesPagination = localStorage.getItem('pmtemplatesPagination');
    if (pmtemplatesPagination) {
      this.activePage = parseInt(pmtemplatesPagination);
    }

    this.getPmTemplatesList();
    this.getBusinessList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 4);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Preventive Maintenance Template" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.addTempaletPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance Template" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.editTemplatePermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance Template" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.viewTemplatePermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance Template" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1) {
        this.changeStatusTemplatePermission = true
      }
    }
  }

  // setPaginationArray(result){
  //   let noOfPAges = Math.ceil(result['rowCount'] /this.pageSize)
  //   this.paginationArray=[]
  //   for(let i =1 ; i<= noOfPAges ; i++)this.paginationArray.push(i)
  //   // console.log(this.paginationArray)
  // }
  statusSelection
  selectStatus(d) {

    this.assetFilter = false;
    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.assetFilter)
  }


  // pagination(data){
  //   if(data != 0 && data <= this.paginationArray.length)
  // this.pageNo = data
  // this.getPmTemplatesList()
  // }

  getPmTemplatesList() {
    let templetBody = {}
    if (localStorage.getItem("LocalPmTemplateFilter") && localStorage.getItem("LocalPmTemplateFilter") != null && localStorage.getItem("LocalPmTemplateFilter") != 'null') {
      let LocalPmTemplateFilter = JSON.parse(localStorage.getItem("LocalPmTemplateFilter"));
      templetBody = LocalPmTemplateFilter;
      if (LocalPmTemplateFilter.assetId && LocalPmTemplateFilter.assetId != null && LocalPmTemplateFilter.assetId != 'null') {
        this.getAssetList();
        this.selectedFiler.assetName = { id: LocalPmTemplateFilter.assetId, assetName: LocalPmTemplateFilter.frontAssetNameDp }
      }
      if (LocalPmTemplateFilter.assignedTo && LocalPmTemplateFilter.assignedTo != null && LocalPmTemplateFilter.assignedTo != 'null') {
        this.getUserList();
        this.selectedFiler.assignedTo = { id: LocalPmTemplateFilter.assignedTo, fullName: LocalPmTemplateFilter.frontUserNameFilter }
      }
      this.selectedFiler.dueDate ? moment(LocalPmTemplateFilter.dueDate).format('YYYY-MM-DD HH:MM:SS') : null
      this.selectedFiler.name = LocalPmTemplateFilter.name
      this.applyFlag = true;
    } else {
      templetBody = {
        businessId: this.apiService.buisnessId,
        // businessId:this.apiService.buisnessId,
        // listType:this.listBy,
        sortBy: this.sortSelected,
        sortType: this.sort,
        assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetId : null,
        pageNo: this.activePage,
        limit: this.pageSize,
        name: this.selectedFiler.name,
        assignedTo: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.id : null,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        endDate: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:MM:SS') : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "isTemplateFlag": 1,
        frontUserNameFilter: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.fullName : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : ""
      }
      localStorage.setItem('LocalPmTemplateFilter', JSON.stringify(templetBody));
    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/index', {}, templetBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.templateList = []
      this.frequencyList = []
      this.responseType = []
      this.statusGroup = []
      this.propertyList = []
      this.templateList = result['data']
      this.frequencyList = result['frequency']
      this.statusGroup = result['statusGroup']
      this.propertyList = result['propertyList']
      // console.log("Template : ",this.templateList);
      this.setPaginationArray(result)
    })
  }

  deletTemplateId: any
  copyTemplateId: any;
  copyTemplate(id): void {

    let copyForn = {
      pmTemplateId: id,
      authId: this.apiService.authId
    }

    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/copyPMTemplate', {}, copyForn).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess11')).click();
    })


  }

  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }


  message
  deleteTemplate(id): void {

    // let response = confirm("Are you shure you want to delete this template");
    let copyForn = {
      authId: this.apiService.authId
    }
    // if(response == true){
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/delete/' + id, {}, copyForn).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.statusSelection = undefined;
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess11')).click();
      this.getPmTemplatesList();
      // if(result['success'] == true){
      //   alert(result['msg']);
      // }else{
      //   alert(result['msg'])
      // }
    })
    // }
  }



  assetList = []
  assetListFilter = []
  getAssetList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : '',
      "statusNotIn": "5,8",
    }
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']['data']
      this.assetListFilter = result['data']['data']
    })
  }

  pagination(d) {
    localStorage.removeItem('LocalPmTemplateFilter');
    localStorage.setItem("LocalPmTemplateFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets=[]
      this.activePage = d
      localStorage.setItem('pmtemplatesPagination', d);
      // console.log(d)
      this.getPmTemplatesList()
    }
  }

  sortData(data) {
    localStorage.removeItem('LocalPmTemplateFilter');
    localStorage.setItem("LocalPmTemplateFilter", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getPmTemplatesList()
  }

  changePage(ev) {
    localStorage.removeItem('LocalPmTemplateFilter');
    localStorage.setItem("LocalPmTemplateFilter", null);
    // console.log(this.pageSize)
    // this.selectedAssets=[]
    // this.pageSize = d
    this.getPmTemplatesList()
  }


  selectedFiler = { name: null, dueDate: null, assignedTo: null, business: null, property: null, assetName: null }
  sortSelected = "name"
  sort = 'asc'



  activePage = 1
  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }



  clear() {
    localStorage.removeItem('LocalPmTemplateFilter');
    localStorage.setItem("LocalPmTemplateFilter", null);
    this.activePage = 1
    this.applyFlag = false;
    this.selectedFiler = { name: null, dueDate: null, assignedTo: null, business: null, property: null, assetName: null };


    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx]
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
        // this.getPropertyList()

      }
      else {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
      }
    }

    this.getPmTemplatesList()
  }


  filterSelect: boolean
  //  closeAll(){}

  //  applyFlag
  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.assignedTo != null) length = length + 1
    if (this.selectedFiler.dueDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.assetName != null) length = length + 1

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.assignedFilter = false;
      this.assetFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.statusSelection=undefined;
      this.filterSelect = false
    }
    if (btn.id == 'changeBUton') {
    } else if (btn.id != 'changeBUton') {
      this.statusSelection = undefined
    }

  }
  assignedFilter = false;
  assetFilter = false;
  closeAll() {
    this.assignedFilter = false;
    this.assetFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
    this.statusSelection = false;
  }


  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    // this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      // this.apiService.stopLoader()

      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
      // if(idx != -1 ){
      //     if(this.apiService.roleId == '2') {
      //       this.selectedFiler.business =  this.bussnissList[idx]
      //       this.getPropertyList()
      //       }
      //       else if(this.apiService.roleId == '3')  {
      //         this.selectedFiler.business = this.bussnissList[idx] 
      //         // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //         this.getPropertyList()

      //       }
      //       else{
      //         this.selectedFiler.business = this.bussnissList[idx] 
      //         // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //         this.getPropertyList()
      //       }

      //     }

      this.getPropertyList()
    })
  }

  getPropertyList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // if(idx != -1){
      // if(this.apiService.roleId == '3')  {

      //   //  this.selectedFiler.property = this.propertyList[idx] 
      //    // this.getPropertyList()
      //    this.getUserList();this.getAssetList()

      //  }
      //  else{

      //   //  this.selectedFiler.property = this.propertyList[idx] 
      //    this.getUserList();this.getAssetList()
      //  }

      // }

      this.getUserList(); this.getAssetList()
    })



  }


  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : '',
    }
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.userList = result['data']
      this.filterUserList = result['data']

    })
  }



  // propertyList=[]
  filterpropertyList = []
  filterPropertySearch
  // propertFilter= false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;

  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  filterAssetSearch
  // businessFilter= false;
  filterAsset(ev) {
    // console.log(ev)
    this.assetList = this.assetListFilter.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase())
      }
    })
  }


  userList = []
  filterUserList = []
  filterUserSearch
  // propertFilter= false;
  filterUser(ev) {
    // console.log(ev)
    this.userList = this.filterUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
}
