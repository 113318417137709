import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-child-asset-new',
  templateUrl: './child-asset-new.component.html',
  styleUrls: ['./child-asset-new.component.css']
})
export class ChildAssetNewComponent implements OnInit {

  constructor(public _location: Location,public router:Router,public route:Router ,public apiService:ApiService,) { }
  assetId:any;
  childAssetList:any;
  paginationArray=[]

  searchTearm
 
  addPermission=false
  removePermission=false
  notifyAddPermission=false
  pmPermission=false;
  partsFeaturePermission = false;
  maintenanceFeaturePermission = false;
  partsPermission = true;
  backClicked(){}
  ngOnInit(): void {

    this.assetId = this.router.url.split("/")[2]

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3  );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Child Assets" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.addPermission=true
      }
      if(permission[i]['subModuleName'] == "Child Assets" && permission[i]['action'] == "Remove" && permission[i]['actionStatus'] == 1){
        this.removePermission=true
      }
      if(permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.notifyAddPermission=true
      }
      
      if(permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.pmPermission=true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true
    }
    
    this.getChildAssetsList()
    this.getAssetDetail()
    // this.getAssets()

    
  }


  sortSelected:any='name'
  sort:any='asc'


  sortData(data){
    this.sortSelected = data
        if(this.sort == 'asc')this.sort = 'desc'
        else this.sort = 'asc'
    
        this.getChildAssetsList()
      }

  getChildAssetsList(): void {

    // console.log("its a call")
    // this.authService.loader= true;
  
    let body ={ pageNo:this.activePage, limit:this.pageSize,assetName:this.searchTearm,
      // sortBy:this.sortSelected,
      // sortType:this.sort
      
    };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/childAssetListing/'+this.assetId+'/'+this.apiService.buisnessId,{} ,body ).subscribe((result: any[])=>{
      // console.log(result);
      this.childAssetList = result['data']['data']
      this.apiService.stopLoader()
      // console.log(this.childAssetList)
        this.statusList = result['statusList']
        /*let idx = this.statusList.findIndex(x=>x .name== "In Migration")
        this.statusList.splice(idx,1)
        let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
        this.statusList.splice(idx1,1)
        idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
        this.statusList.splice(idx1,1)*/
       this.setPaginationArray(result)
      // this.setPaginationArray(result)
    })
  }

  serachPromise = null
  search(){
    clearTimeout(this.serachPromise);
    this.serachPromise = setTimeout(() => {
    // let body ={ pageNo:1, limit:10,      name:this.searchTearm};
    this.getChildAssetsList()

  
}, 1000);
  }
  assetDetails:any;
  getAssetDetail(){
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
    this.getAssets()
    })
  }
  statusList=[]
assetList=[]
  getAssets(): void {

    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
      "categoryName":this.assetDetails.categoryName,
      "statusNotIn":"5,8"
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList=result['data']
      this.assetList = this.assetList.filter(x=> x.id != this.assetId);

      // let idx2 =this.assetList.findIndex(x=> x.id == this.assetId)
      // if(idx2 != -1) this.assetList.splice(idx2,1)
      // this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
    })

		// // console.log("its a call")
    // // this.authService.loader= true;
    
    // const body ={ pageNo:this.activePage, limit:this.pageSize,};
    // // if(this.search != '')body['assetName'] = this.search
    // this.apiService.startLoader()
		// this.apiService.postCall('asset-managements/index/'+this.apiService.buisnessId,{} , body ).subscribe((result: any[])=>{
    //   // console.log(result);
    //   this.apiService.stopLoader()
    //  this.assetList=result['data']['data']
     
    // })
  }

  selectedAsset=null

  selected:any;
  assignChild(){
    if(!this.selected){
      return false;
    }
      // console.log(this.selected)
      let body={
        

      childAssetId:this.selected.id,
      parentAssetId:this.assetId,
      authId:this.apiService.userId,

      }
    this.apiService.startLoader()
		this.apiService.postCall('asset-managements/addChild',{} , body ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
    //  this.assetList=result['data']['data']
      this.selectedAsset=null
     let body ={ pageNo:1, limit:10, };
     this.getChildAssetsList()
     this.getAssets()
    })
  }

  message
  deleteChildAsset(data){
    let body={
      childAssetId:data.assetId,
      parentAssetId:this.assetId,
      authId:this.apiService.userId
    }
    this.apiService.startLoader()
		this.apiService.postCall('asset-managements/deleteChild',{} , body ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
    //  this.assetList=result['data']['data']
    this.message = result["msg"];  
  (<HTMLInputElement>document.getElementById('openSuccess')).click();

     let body ={ pageNo:1, limit:10, };
     this.getChildAssetsList()
     this.getAssets()
    })
  }



  totalnoOfPages
  activePage=1
  pageSize=10
  setPaginationArray(result){
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] /this.pageSize)
    this.totalnoOfPages= noOfPAges;
    this.paginationArray=[]

    if(noOfPAges <=5)for(let i =1 ; i<= noOfPAges ; i++)this.paginationArray.push(i)
    else if(4+this.activePage < this.totalnoOfPages) {
      for(let i =this.activePage ; i<= 4+this.activePage ; i++)this.paginationArray.push(i)
    }
    else if(4+this.activePage >= 6){

      let start = (4+this.activePage)- noOfPAges 
      for(let i =this.activePage-start ; i<= noOfPAges ; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pagination(d){
    // console.log(d)
    if(d != 0 && d <= this.totalnoOfPages && this.activePage != d){
    // this.selectedAssets=[]
    this.activePage= d
    // console.log(d)
    this.getChildAssetsList()
    }
  }
  changePage(ev){
    // console.log(this.pageSize)
    // this.selectedAssets=[]
    // this.pageSize = d
    this.getChildAssetsList()
  }



  
  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFilesAsset =file
        
       
  
      }
  
      let testData:FormData = new FormData();
    
     
  // // console.log(obj)
  testData.append('image', this.uploadedFilesAsset);
   
    // status:this.assetDetailsForm.value.status,
    
  
  
  // // console.log(obj)
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
    this.getAssetDetail()
    document.getElementById('dismissPopUp').click()
    
  })
  }
  
  
  changeStatus(){
    let testData:FormData = new FormData();
    
     
    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);
     
      testData.append("status", this.assetDetails.status);
      
    
    
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()
      
    })
  }
 
}
