import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.css']
})
export class PropertyDetailsComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public router: Router,
    public _location:Location,
  ) { }
  message
  fileName
  id = '';
  propertyDetailse:any

  ngOnInit(): void {
    this.id = this.router.url.split("/")[2];
    this.getDetailse();
  }

  getDetailse(){
    this.apiService.startLoader()
    this.apiService.getCall('outlets/view/'+this.id, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.propertyDetailse = result['data'];
      }else{
        alert(result['msg']);
        this.goBack();
      }
    })
  }

  goBack():void{
    this._location.back();
  }

}
