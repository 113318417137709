import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { compileComponentFromMetadata } from '@angular/compiler';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { json } from 'd3';

@Component({
  selector: 'app-pm-index',
  templateUrl: './pm-index.component.html',
  styleUrls: ['./pm-index.component.css']
})
export class PmIndexComponent implements OnInit {

  constructor(
    public route: Router,
    public router: Router,
    public apiService: ApiService,
    public formBuilder: FormBuilder
  ) { }

  listBy = 'today'
  pmList
  pmUList
  paginationArray = []
  pageSize = 10;
  pageNo: any = 1
  locationList = []
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;
  propertyFiletr = false;

  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];
  // assetList=[]
  // sortSelected;
  filterLocation(ev) { }
  applyFilter() {
    localStorage.removeItem('LocalPmTodayFilter');
    localStorage.setItem("LocalPmTodayFilter", null);
    localStorage.removeItem('LocalUpcomingFilter');
    localStorage.setItem("LocalUpcomingFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    if (this.listBy == 'upcoming') {
      this.getPmUList();
    } else {
      this.getPmList()
    }
    // this.getPmUList()
  }

  getMetersList() { }
  applyFlag
  // sortData(ev){}

  dublicateSelected() { }
  deactiveSelected() { }


  pmAddPermission = false
  pmEditPermission = false
  pmViewPermission = true
  pmViewCalendorPermissions = false
  searchPromise2 = null

  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ ticketList: 'openTicket', assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: this.listBy }));
    //(<HTMLInputElement>document.getElementById('clearFilter')).click();
  }
  getIds(ev) {
    // this.selectedFiler.zoneId = ev.zoneId
    // this.selectedFiler.cluserId = ev.clusterId
    // this.selectedFiler.stateId = ev.stateId
    // this.selectedFiler.cityId = ev.cityId
    // this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.Clients = ev.Clients
    this.selectedFiler.cluster = ev.cluster
    this.selectedFiler.state = ev.stateId
    this.selectedFiler.city = ev.city
    this.selectedFiler.Zone = ev.Zone
    this.selectedFiler.property = ev.property
    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);

    //this.applyFilter();

  }

  ngOnInit(): void {

    clearTimeout(this.searchPromise2);
    this.getClients();
    this.getZoneListData();
    this.searchPromise2 = setTimeout(() => {
      // document.body.style.overflowY = 'scroll';
    }, 1000)
    if (this.router.url.split("/")[2]) {
      this.listBy = this.router.url.split("/")[2];
      //this.clear();
      this.activePage = 1;
      this.setTabSelection();
      if (this.listBy == 'upcoming') {
        this.getPmUList();
      } else {
        this.getPmList();
      }
    } else {
      this.getPmList();
    }
    if (localStorage.getItem("tabselection")) {
      this.listBy = JSON.parse(localStorage.getItem("tabselection")).maintananceList
    }

    var pmIndexPagination = localStorage.getItem('pmIndexPagination');
    if (pmIndexPagination) {
      this.activePage = parseInt(pmIndexPagination);
    }
    // this.getPmUList();
    this.getBusinessList()
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 31 && businessFeatures[i]['permission_allow'] == 1) {
          this.pmViewCalendorPermissions = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.pmViewCalendorPermissions = true;
    }

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 4);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.pmAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.pmEditPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance Template" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.pmViewPermission = true
      }
    }
  }

  navigateToDetails(d) {
    document.body.style.overflow = 'hidden'
    if (this.listBy == 'upcoming') {
      this.router.navigate(['/pm-upcoming-view-new/' + d.id])
    }
    this.router.navigate(['/pm-view-new/' + d.id])
  }
  assetList = []
  assetListFilter = []
  getAssetList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetId : null,
    }

    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId + '/', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']['data']
      this.assetListFilter = result['data']['data']
    })
  }

  pagination(d) {
    localStorage.removeItem('LocalPmTodayFilter');
    localStorage.setItem("LocalPmTodayFilter", null);
    localStorage.removeItem('LocalUpcomingFilter');
    localStorage.setItem("LocalUpcomingFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('pmIndexPagination', d);
      // console.log(d)
      if (this.listBy == 'upcoming') {
        this.getPmUList();
      } else {
        this.getPmList()
      }
      // this.getPmUList()
    }
  }

  sortData(data) {
    localStorage.removeItem('LocalPmTodayFilter');
    localStorage.setItem("LocalPmTodayFilter", null);
    localStorage.removeItem('LocalUpcomingFilter');
    localStorage.setItem("LocalUpcomingFilter", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    if (this.listBy == 'upcoming') {
      this.getPmUList();
    } else {
      this.getPmList()
    }
    // this.getPmUList()
  }

  changePage(ev) {
    localStorage.removeItem('LocalPmTodayFilter');
    localStorage.setItem("LocalPmTodayFilter", null);
    localStorage.removeItem('LocalUpcomingFilter');
    localStorage.setItem("LocalUpcomingFilter", null);
    // console.log(this.pageSize)
    // this.selectedAssets=[]
    // this.pageSize = d
    if (this.listBy == 'upcoming') {
      this.getPmUList();
    } else {
      this.getPmList()
    }
    // this.getPmUList()
  }


  selectedFiler = { name: null, dueDate: null, assignedTo: null, business: null, property: null, assetName: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  sortSelected;
  sort;
  getClients() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.ClientsListData = []
      //this.locationList = result['data'] 
      //this.filterpropertyList = result['data']
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

      // let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // // console.log(this.apiService.roleId)

    })
  }
  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;

    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
  getPmList(): void {
    this.pmList = []
    let getListBody = {}
    // console.log(localStorage.getItem("LocalPmTodayFilter"));
    // console.log(this.selectedFiler);
    if (localStorage.getItem("LocalPmTodayFilter") && localStorage.getItem("LocalPmTodayFilter") != null && localStorage.getItem("LocalPmTodayFilter") != 'null') {
      let LocalPmTodayFilter = JSON.parse(localStorage.getItem("LocalPmTodayFilter"));
      getListBody = LocalPmTodayFilter;
      if (LocalPmTodayFilter.propertyId && LocalPmTodayFilter.propertyId != null && LocalPmTodayFilter.propertyId != 'null') {
        this.selectedFiler.property ? LocalPmTodayFilter.propertyId : null
      }
      if (LocalPmTodayFilter.assetId && LocalPmTodayFilter.assetId != null && LocalPmTodayFilter.assetId != 'null') {
        this.getAssetList();
        this.selectedFiler.assetName = { id: LocalPmTodayFilter.assetId, assetName: LocalPmTodayFilter.frontAssetNameDp }
      }
      if (LocalPmTodayFilter.clientId && LocalPmTodayFilter.clientId != null && LocalPmTodayFilter.clientId != 'null') {
        this.selectedFiler.Clients = { id: LocalPmTodayFilter.clientId, name: LocalPmTodayFilter.frontClientNameDp }
      }
      if (LocalPmTodayFilter.propertyId && LocalPmTodayFilter.propertyId != null && LocalPmTodayFilter.propertyId != 'null') {
        this.selectedFiler.property = { id: LocalPmTodayFilter.propertyId, outletName: LocalPmTodayFilter.frontPropertyNameDp }
      }
      if (LocalPmTodayFilter.zoneId && LocalPmTodayFilter.zoneId != null && LocalPmTodayFilter.zoneId != 'null') {
        this.selectedFiler.Zone = { id: LocalPmTodayFilter.zoneId, name: LocalPmTodayFilter.frontZoneNameDp }
      }
      if (LocalPmTodayFilter.stateId && LocalPmTodayFilter.stateId != null && LocalPmTodayFilter.stateId != 'null') {
        this.selectedFiler.state = { id: LocalPmTodayFilter.stateId, name: LocalPmTodayFilter.frontStateNameDp }
      }
      if (LocalPmTodayFilter.clusterId && LocalPmTodayFilter.clusterId != null && LocalPmTodayFilter.clusterId != 'null') {
        this.selectedFiler.cluster = { id: LocalPmTodayFilter.clusterId, name: LocalPmTodayFilter.frontClusterNameDp }
      }
      if (LocalPmTodayFilter.cityId && LocalPmTodayFilter.cityId != null && LocalPmTodayFilter.cityId != 'null') {
        this.selectedFiler.city = { id: LocalPmTodayFilter.cityId, name: LocalPmTodayFilter.frontCityNameDp }
      }
      this.selectedFiler.name = LocalPmTodayFilter.name
      this.selectedFiler.dueDate ? moment(LocalPmTodayFilter.dueDate).format('YYYY-MM-DD HH:MM:SS') : null
      if (LocalPmTodayFilter.assignedTo && LocalPmTodayFilter.assignedTo != null && LocalPmTodayFilter.assignedTo != 'null') {
        this.getUserList();
        this.selectedFiler.assignedTo = { id: LocalPmTodayFilter.assignedTo, fullName: LocalPmTodayFilter.frontUserNameFilter }
      }
      this.applyFlag = true;
    } else {
      getListBody = {
        businessId: this.apiService.buisnessId,
        listType: this.listBy,
        sortBy: this.sortSelected,
        sortType: this.sort,
        pageNo: this.activePage,
        limit: this.pageSize,
        name: this.selectedFiler.name,
        assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetId : null,
        assignedTo: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.id : null,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        frontPropertyNameDp: this.selectedFiler.property ? this.selectedFiler.property.outletName : null,
        dueDate: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:MM:SS') : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        frontUserNameFilter: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.fullName : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",
        frontClientNameDp: this.selectedFiler.Clients ? this.selectedFiler.Clients.name : "",
        frontZoneNameDp: this.selectedFiler.Zone ? this.selectedFiler.Zone.name : "",
        frontStateNameDp: this.selectedFiler.state ? this.selectedFiler.state.name : "",
        frontClusterNameDp: this.selectedFiler.cluster ? this.selectedFiler.cluster.name : "",
        frontCityNameDp: this.selectedFiler.city ? this.selectedFiler.city.name : "",
        // zoneId: this.selectedFiler.zoneId,
        // cluserId: this.selectedFiler.cluserId,
        // stateId: this.selectedFiler.stateId,
        // cityId: this.selectedFiler.cityId,
        // clientId: this.selectedFiler.clientId,
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      }
      localStorage.setItem('LocalPmTodayFilter', JSON.stringify(getListBody));
    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-schedules/index', {}, getListBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.pmList = []
      this.pmList = result['data']
      // console.log("Template : ", this.pmList);
      this.setPaginationArray(result)
    })
  }

  getPmUList(): void {
    // this.listBy = status;
    // console.log('else');
    // console.log(localStorage.getItem("LocalPmTodayFilter"));
    // console.log(this.selectedFiler);
    this.pmList = []
    let getListBody = {}
    if (localStorage.getItem("LocalUpcomingFilter") && localStorage.getItem("LocalUpcomingFilter") != null && localStorage.getItem("LocalUpcomingFilter") != 'null') {
      let LocalUpcomingFilter = JSON.parse(localStorage.getItem("LocalUpcomingFilter"));
      getListBody = LocalUpcomingFilter;
      if (LocalUpcomingFilter.propertyId && LocalUpcomingFilter.propertyId != null && LocalUpcomingFilter.propertyId != 'null') {
        this.selectedFiler.property ? LocalUpcomingFilter.propertyId : null
      }
      if (LocalUpcomingFilter.assetId && LocalUpcomingFilter.assetId != null && LocalUpcomingFilter.assetId != 'null') {
        this.getAssetList();
        this.selectedFiler.assetName = { id: LocalUpcomingFilter.assetId, assetName: LocalUpcomingFilter.frontAssetNameDp }
      }
      if (LocalUpcomingFilter.clientId && LocalUpcomingFilter.clientId != null && LocalUpcomingFilter.clientId != 'null') {
        this.selectedFiler.Clients = { id: LocalUpcomingFilter.clientId, name: LocalUpcomingFilter.frontClientNameDp }
      }
      if (LocalUpcomingFilter.propertyId && LocalUpcomingFilter.propertyId != null && LocalUpcomingFilter.propertyId != 'null') {
        this.selectedFiler.property = { id: LocalUpcomingFilter.propertyId, outletName: LocalUpcomingFilter.frontPropertyNameDp }
      }
      if (LocalUpcomingFilter.zoneId && LocalUpcomingFilter.zoneId != null && LocalUpcomingFilter.zoneId != 'null') {
        this.selectedFiler.Zone = { id: LocalUpcomingFilter.zoneId, name: LocalUpcomingFilter.frontZoneNameDp }
      }
      if (LocalUpcomingFilter.stateId && LocalUpcomingFilter.stateId != null && LocalUpcomingFilter.stateId != 'null') {
        this.selectedFiler.state = { id: LocalUpcomingFilter.stateId, name: LocalUpcomingFilter.frontStateNameDp }
      }
      if (LocalUpcomingFilter.clusterId && LocalUpcomingFilter.clusterId != null && LocalUpcomingFilter.clusterId != 'null') {
        this.selectedFiler.cluster = { id: LocalUpcomingFilter.clusterId, name: LocalUpcomingFilter.frontClusterNameDp }
      }
      if (LocalUpcomingFilter.cityId && LocalUpcomingFilter.cityId != null && LocalUpcomingFilter.cityId != 'null') {
        this.selectedFiler.city = { id: LocalUpcomingFilter.cityId, name: LocalUpcomingFilter.frontCityNameDp }
      }
      this.selectedFiler.name = LocalUpcomingFilter.name
      this.selectedFiler.dueDate ? moment(LocalUpcomingFilter.dueDate).format('YYYY-MM-DD HH:MM:SS') : null
      if (LocalUpcomingFilter.assignedTo && LocalUpcomingFilter.assignedTo != null && LocalUpcomingFilter.assignedTo != 'null') {
        this.getUserList();
        this.selectedFiler.assignedTo = { id: LocalUpcomingFilter.assignedTo, fullName: LocalUpcomingFilter.frontUserNameFilter }
      }
      this.applyFlag = true;
    } else {
      getListBody = {
        // assetId:this.assetId,
        listType: this.listBy,
        sortBy: this.sortSelected,
        sortType: this.sort,
        pageNo: this.activePage,
        limit: this.pageSize,
        name: this.selectedFiler.name,
        assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetId : null,
        assignedTo: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.id : null,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        frontPropertyNameDp: this.selectedFiler.property ? this.selectedFiler.property.outletName : null,
        dueDate: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:MM:SS') : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        businessId: this.apiService.buisnessId,
        frontUserNameFilter: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.fullName : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",
        frontClientNameDp: this.selectedFiler.Clients ? this.selectedFiler.Clients.name : "",
        frontZoneNameDp: this.selectedFiler.Zone ? this.selectedFiler.Zone.name : "",
        frontStateNameDp: this.selectedFiler.state ? this.selectedFiler.state.name : "",
        frontClusterNameDp: this.selectedFiler.cluster ? this.selectedFiler.cluster.name : "",
        frontCityNameDp: this.selectedFiler.city ? this.selectedFiler.city.name : "",
        // zoneId: this.selectedFiler.zoneId,
        // cluserId: this.selectedFiler.cluserId,
        // stateId: this.selectedFiler.stateId,
        // cityId: this.selectedFiler.cityId,
        // clientId: this.selectedFiler.clientId,
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      }
      localStorage.setItem('LocalUpcomingFilter', JSON.stringify(getListBody));
    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/index', {}, getListBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.pmList = result['data']
      // console.log("Template : ", this.pmList);
      this.setPaginationArray(result)
    })
  }
  activePage: any = 1
  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }


  clear() {
   // console.log('clear');
    localStorage.removeItem('LocalPmTodayFilter');
    localStorage.setItem("LocalPmTodayFilter", null);
    localStorage.removeItem('LocalUpcomingFilter');
    localStorage.setItem("LocalUpcomingFilter", null);
    this.applyFlag = false;
    this.selectedFiler = { name: null, dueDate: null, assignedTo: null, business: null, property: null, assetName: null, Clients: null, Zone: null, state: null, cluster: null, city: null };

    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx]
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
        // this.getPropertyList()

      }
      else {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
      }
    }

    if (this.listBy == 'upcoming') {
      this.getPmUList();
    } else {
      this.getPmList()
    }
    // this.getPmUList()
  }


  filterSelect: boolean
  //  closeAll(){}

  //  applyFlag
  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.name != null && this.selectedFiler.name != "") length = length + 1
    if (this.selectedFiler.assignedTo != null) length = length + 1
    //if (this.selectedFiler.dueDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    // if(this.selectedFiler.business != null) length = length+1
    if (this.selectedFiler.assetName != null && this.selectedFiler.assetName != "") length = length + 1
    // if (this.selectedFiler.zoneId != null) length = length + 1
    // if (this.selectedFiler.cluserId != null) length = length + 1
    // if (this.selectedFiler.stateId != null) length = length + 1
    // if (this.selectedFiler.cityId != null) length = length + 1
    // if (this.selectedFiler.clientId != null) length = length + 1
    if (this.selectedFiler.Clients != null) length = length + 1;
    if (this.selectedFiler.Zone != null) length = length + 1;
    if (this.selectedFiler.city != null) length = length + 1;
    if (this.selectedFiler.cluster != null) length = length + 1;
    if (this.selectedFiler.state != null) length = length + 1;

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.assetFilter = false;
      this.assignedFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
  }
  assignedFilter = false;
  assetFilter = false;
  closeAll() {
    this.assignedFilter = false;
    // this.assetFilter= false;
    this.businessFilter = false;
    this.propertFilter = false;
  }


  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    // this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      // this.apiService.stopLoader()

      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      // if(idx != -1 ){
      //     if(this.apiService.roleId == '2') {
      //       this.selectedFiler.business =  this.bussnissList[idx]
      //       this.getPropertyList()
      //       }
      //       else if(this.apiService.roleId == '3')  {
      //         this.selectedFiler.business = this.bussnissList[idx] 
      //         // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //         this.getPropertyList()

      //       }
      //       else{
      //         this.selectedFiler.business = this.bussnissList[idx] 
      //         // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 

      //       }
      //     }
      this.getPropertyList()

    })
  }

  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // zoneId: this.selectedFiler.zoneId,
      // cluserId: this.selectedFiler.cluserId,
      // stateId: this.selectedFiler.stateId,
      // cityId: this.selectedFiler.cityId,
      // clientId: this.selectedFiler.clientId,
      // "outletId":outletId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }

    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // if(idx != -1){
      // if(this.apiService.roleId == '3')  {

      //    this.selectedFiler.property = this.propertyList[idx] 
      //    // this.getPropertyList()
      //    this.getUserList();this.getAssetList()

      //  }
      //  else{

      //    this.selectedFiler.property = this.propertyList[idx] 
      //  this.getUserList();this.getAssetList()
      //  }
      // }
      this.getUserList(); this.getAssetList()
    })



  }
  getUserArrasy(array, flag) {
    if (array) {
      let str = ""
      for (let d of array) str = str + (str == "" ? "" : ",") + d.userName

      if (flag == 1) {
        return str
      }
      if (str.length > 45) str = str.substring(0, 45) + '..'
      return str
    }
  }


  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : null,
    }

    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/', {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.userList = result['data']
      this.filterUserList = result['data']

    })
  }



  propertyList = []
  filterpropertyList = []
  filterPropertySearch
  // propertFilter= false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;

  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  filterAssetSearch
  // businessFilter= false;
  filterAsset(ev) {
    // console.log(ev)
    this.assetList = this.assetListFilter.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase())
      }
    })
  }


  userList = []
  filterUserList = []
  filterUserSearch
  // propertFilter= false;
  filterUser(ev) {
    // console.log(ev)
    this.userList = this.filterUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }

}
