import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-permit-index-list',
  templateUrl: './permit-index-list.component.html',
  styleUrls: ['./permit-index-list.component.css']
})
export class PermitIndexListComponent implements OnInit {
  tabSelected = 'archived';
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  statusSelection;
  addPermission = false;
  editPermission = false;
  message;
  buisnessIdPermit = this.apiService.buisnessId;
  permit_start_time = this.apiService.permit_start_time;
  permit_end_time = this.apiService.permit_end_time;
  permit_user_id = this.apiService.permit_user_id;
  userLoginId = this.apiService.userId;
  permitstarttimeSet = new Date();
  hour = this.permitstarttimeSet.getHours();
  minuts = this.permitstarttimeSet.getMinutes();
  permitstarttime = this.hour + ":" + this.minuts;
  permitUrl
  tenantList = [];
  todaysDate = new Date();
  constructor(public route: Router, public apiService: ApiService, public router: Router, public storedData: StoredData) {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    // for(var i=0;i<permission.length;i++){
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
    //     this.addPermission=true
    //   }
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
    //     this.editPermission=true
    //   }
    //
    // }
  }

  getIds(ev) {
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property = ev.property
    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);
    //this.applyFilter();
  }
  permitAddFlag = false;
  ngOnInit(): void {
    var meterListPagination = localStorage.getItem('meterListPagination');
    if (meterListPagination) {
      this.activePage = parseInt(meterListPagination);
    }
    if (this.permitstarttime > this.permit_start_time && this.permitstarttime < this.permit_end_time || this.apiService.userId == this.apiService.permit_user_id) {
      this.permitAddFlag = false;
    } else if (this.permit_start_time != '' && this.apiService.permit_user_id != '') {
      this.permitAddFlag = true;
    }
    if (this.router.url.split("/")[2]) {
      this.tabSelected = this.router.url.split("/")[2];
      this.clear();
      this.getList();
      this.getBusinessList();
    } else {
      this.getList();
      this.getBusinessList();
    }

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }
  getCheck(text) {
    return text.includes("Rejected");
  }
  clickOnTab(tabName) {
    this.tabSelected = tabName;
    this.clear();
  }

  requestList = []
  getList() {
    var isActive = 0;
    if (this.tabSelected == 'active') {
      isActive = 1;
    } else if (this.tabSelected == 'rejected') {
      isActive = 2;
    }
    // if (this.router.url.split("/")[2]) {
    //   this.tabSelected = this.router.url.split("/")[2];
    //   if (this.router.url.split("/")[2] == 'active') {
    //     isActive = 1
    //   } else if (this.router.url.split("/")[2] == 'rejected') {
    //     isActive = 2
    //   } else if (this.router.url.split("/")[2] == 'archived') {
    //     isActive = 0;
    //   }
    // } else {
    //   if (this.tabSelected == 'active') {
    //     isActive = 1
    //   } else if (this.tabSelected == 'rejected') {
    //     isActive = 2
    //   }
    // }
    var tId = this.selectedFiler.tenant ? this.selectedFiler.tenant.id : null;
    if (this.apiService.roleId == 6) {
      tId = this.apiService.authId
    }
    this.apiService.startLoader();
    // this.applyFlag=false;
    this.workList = []
    let body = {}
    if (localStorage.getItem("permitRequestLocalFilter") && localStorage.getItem("permitRequestLocalFilter") != null && localStorage.getItem("permitRequestLocalFilter") != 'null') {
      let permitRequestLocalFilter = JSON.parse(localStorage.getItem("permitRequestLocalFilter"));
      body = permitRequestLocalFilter;
      if (permitRequestLocalFilter.outletId && permitRequestLocalFilter.outletId != null && permitRequestLocalFilter.outletId != 'null') {
        this.selectedFiler.property = { id: permitRequestLocalFilter.outletId, outletName: permitRequestLocalFilter.frontOutletNameFilter }
      }
      if (permitRequestLocalFilter.request && permitRequestLocalFilter.request != null && permitRequestLocalFilter.request != 'null') {
        this.selectedFiler.request = { id: permitRequestLocalFilter.request, name: permitRequestLocalFilter.frontRequestNameFilter }
      }
      if (permitRequestLocalFilter.associated && permitRequestLocalFilter.associated != null && permitRequestLocalFilter.associated != 'null') {
        this.selectedFiler.associated = { id: permitRequestLocalFilter.associated, name: permitRequestLocalFilter.frontRequestAssociatedNameFilter }
      }
      this.selectedFiler.name = permitRequestLocalFilter.name
      this.selectedFiler.permitNo = permitRequestLocalFilter.permitNo;
      this.selectedFiler.vendorId = permitRequestLocalFilter.vendorId;
      this.selectedFiler.endDate = permitRequestLocalFilter.endDate
      this.selectedFiler.startDate = permitRequestLocalFilter.startDate
      this.selectedFiler.startDate = permitRequestLocalFilter.startDate
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        status: isActive,
        userId: this.apiService.userId,
        businessId: this.apiService.buisnessId,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        name: this.selectedFiler.name,
        permitNo: this.selectedFiler.permitNo,
        vendorId: this.selectedFiler.vendorId,
        endDate: this.selectedFiler.endDate,
        startDate: this.selectedFiler.startDate,
        tenant: tId,
        request: this.selectedFiler.request ? this.selectedFiler.request.id : null,
        associated: this.selectedFiler.associated ? this.selectedFiler.associated.id : null,

        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRequestNameFilter: this.selectedFiler.request ? this.selectedFiler.request.name : "",
        frontRequestAssociatedNameFilter: this.selectedFiler.associated ? this.selectedFiler.associated.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('permitRequestLocalFilter', JSON.stringify(body));
    }
    this.apiService.postCall('PermitRequests/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];
      this.requestList = result['permitDropdown'];
      this.filterrequestList = result['permitDropdown'];
      this.apiService.stopLoader();

    });

    setTimeout(() => {
      this.permitUrl = this.router.url.split("/")[2]
      this.permitUrl = this.router.url.split("/")[1]
      if (this.permitUrl == 'permits-list') {
        this.getList();
      }
    }, 70000);
  }

  clear() {
    localStorage.removeItem('permitRequestLocalFilter');
    localStorage.setItem("permitRequestLocalFilter", null);
    this.activePage = 1
    this.pageSize = 10
    this.sortSelected = 'id'
    this.sort = 'desc'
    this.applyFlag = false;
    this.selectedFiler = { name: null, request: null, associated: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, permitNo: null, vendorId: null, startDate: null, endDate: null, tenant: null, property: null, business: null, status: null };
    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId);
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId);
    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx];
        this.getPropertyList();
      } else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx];
        // this.selectedFiler.property = this.propertyList[idx1]
        // this.getPropertyList()
      } else {
        this.selectedFiler.business = this.bussnissList[idx];
        // this.selectedFiler.property = this.propertyList[idx1]
      }
    }

    (<HTMLInputElement>document.getElementById('clearFilter')).click();
    this.getList();
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1;

  totalnoOfPages;

  changePage(ev) {
    localStorage.removeItem('permitRequestLocalFilter');
    localStorage.setItem("permitRequestLocalFilter", null);
    this.selectedAssets = [];
    // this.pageSize = d
    this.getList();
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }

  workList = [];
  selectedAssets = [];


  getCustomName(d) {
    return 'Every ' + d.frequency_no + ' ' + d.frequency_unit;
  }

  pagination(d) {
    localStorage.removeItem('permitRequestLocalFilter');
    localStorage.setItem("permitRequestLocalFilter", null);
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('meterListPagination', d);
      this.getList();
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    localStorage.removeItem('permitRequestLocalFilter');
    localStorage.setItem("permitRequestLocalFilter", null);
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList();
  }


  selectedFiler = { name: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, request: null, associated: null, startDate: null, endDate: null, property: null, permitNo: null, vendorId: null, tenant: null, business: null, status: null };

  locationList = [];
  filterlocationList = [];


  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }

    // console.log(this.statusSelection);

    // if($(".stama"+d).css('display') == 'none'){
    //   $(".stama"+d).css("display","block");
    // }else{
    //   $(".stama"+d).css("display","none");
    // }
  }

  changeIsActive(id, isActive?) {
    this.apiService.startLoader();
    let obj = {
      'isActive': Number(isActive),
      'authId': this.apiService.userId,
    };
    this.apiService.postCall('PermitRequests/edit/' + id, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      this.getList();
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.apiService.stopLoader();

    });
  }

  getBusinessList() {
    this.apiService.startLoader();
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.bussnissList = result['data'];
      this.bussnissListFilter = result['data'];
      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId);

      if (idx != -1) {
        if (this.apiService.roleId == '2') {
          this.selectedFiler.business = this.bussnissList[idx];
          this.getPropertyList();
        } else if (this.apiService.roleId == '3') {
          this.selectedFiler.business = this.bussnissList[idx];
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId)
          this.getPropertyList();

        } else {
          this.selectedFiler.business = this.bussnissList[idx];
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId)
          this.getPropertyList();
        }

      } else {
        // this.getMainPropertyList();
      }

    });
  }




  filterPropertySearch;
  propertFilter = false;

  filterTenantSearch;
  statusFilter = false
  tenantFilter = false;
  requestFilter = false;
  requestassociatedFilter = false;
  filterProperty(ev) {
    // console.log(ev);
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase());
      }
    });
  }

  filterrequestSearch = "";
  filterRequest(ev) {
    // console.log(ev);
    this.requestList = this.filterrequestList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterrequestSearch.toLowerCase());
      }
    });
  }

  filterTenant(ev) {
    // console.log(ev);
    this.tenantList = this.filterTenantList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterTenantSearch.toLowerCase());
      }
    });
  }

  filterBusinessSearch;
  businessFilter = false;

  filterBuisness(ev) {
    // console.log(ev);
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase());
      }
    });
  }


  applyFlag = false;

  applyFilter() {
    localStorage.removeItem('permitRequestLocalFilter');
    localStorage.setItem("permitRequestLocalFilter", null);
    var isActive = 0
    if (this.tabSelected == 'active') {
      isActive = 1
    } else if (this.tabSelected == 'rejected') {
      isActive = 2
    }
    var tId = this.selectedFiler.tenant ? this.selectedFiler.tenant.id : null;
    if (this.apiService.roleId == 6) {
      tId = this.apiService.authId
    }
    this.workList = []
    this.applyFlag = true;
    this.activePage = 1;
    let body = {}
    if (localStorage.getItem("permitRequestLocalFilter") && localStorage.getItem("permitRequestLocalFilter") != null && localStorage.getItem("permitRequestLocalFilter") != 'null') {
      let permitRequestLocalFilter = JSON.parse(localStorage.getItem("permitRequestLocalFilter"));
      body = permitRequestLocalFilter;
      if (permitRequestLocalFilter.outletId && permitRequestLocalFilter.outletId != null && permitRequestLocalFilter.outletId != 'null') {
        this.selectedFiler.property = { id: permitRequestLocalFilter.outletId, outletName: permitRequestLocalFilter.frontOutletNameFilter }
      }
      if (permitRequestLocalFilter.request && permitRequestLocalFilter.request != null && permitRequestLocalFilter.request != 'null') {
        this.selectedFiler.request = { id: permitRequestLocalFilter.request, name: permitRequestLocalFilter.frontRequestNameFilter }
      }
      if (permitRequestLocalFilter.associated && permitRequestLocalFilter.associated != null && permitRequestLocalFilter.associated != 'null') {
        this.selectedFiler.associated = { id: permitRequestLocalFilter.associated, name: permitRequestLocalFilter.frontRequestAssociatedNameFilter }
      }
      this.selectedFiler.name = permitRequestLocalFilter.name
      this.selectedFiler.permitNo = permitRequestLocalFilter.permitNo;
      this.selectedFiler.vendorId = permitRequestLocalFilter.vendorId;
      this.selectedFiler.endDate = permitRequestLocalFilter.endDate
      this.selectedFiler.startDate = permitRequestLocalFilter.startDate
      this.selectedFiler.startDate = permitRequestLocalFilter.startDate
    } else {
      body = {
        activePage: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        userId: this.apiService.userId,
        businessId: this.apiService.buisnessId,
        name: this.selectedFiler.name,
        permitNo: this.selectedFiler.permitNo,
        vendorId: this.selectedFiler.vendorId,
        endDate: this.selectedFiler.endDate,
        startDate: this.selectedFiler.startDate,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        tenant: tId,
        status: isActive,
        request: this.selectedFiler.request ? this.selectedFiler.request.id : null,
        associated: this.selectedFiler.associated ? this.selectedFiler.associated.id : null,
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
    }
    this.apiService.startLoader();
    this.apiService.postCall('PermitRequests/index/' + this.apiService.userId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];
      this.requestList = result['permitDropdown'];
      this.filterrequestList = result['permitDropdown'];
      this.apiService.stopLoader();

    });
  }



  downloadReport() {
    var isActive = 0
    if (this.tabSelected == 'active') {
      isActive = 1
    } else if (this.tabSelected == 'rejected') {
      isActive = 2
    }
    var tId = this.selectedFiler.tenant ? this.selectedFiler.tenant.id : null;
    if (this.apiService.roleId == 6) {
      tId = this.apiService.authId
    }
    let body = {}
    if (localStorage.getItem("permitRequestLocalFilter") && localStorage.getItem("permitRequestLocalFilter") != null && localStorage.getItem("permitRequestLocalFilter") != 'null') {
      let permitRequestLocalFilter = JSON.parse(localStorage.getItem("permitRequestLocalFilter"));
      permitRequestLocalFilter.downloadExcel = 1
      permitRequestLocalFilter.limit = 0;
      body = permitRequestLocalFilter;
      if (permitRequestLocalFilter.outletId && permitRequestLocalFilter.outletId != null && permitRequestLocalFilter.outletId != 'null') {
        this.selectedFiler.property = { id: permitRequestLocalFilter.outletId, outletName: permitRequestLocalFilter.frontOutletNameFilter }
      }
      if (permitRequestLocalFilter.request && permitRequestLocalFilter.request != null && permitRequestLocalFilter.request != 'null') {
        this.selectedFiler.request = { id: permitRequestLocalFilter.request, name: permitRequestLocalFilter.frontRequestNameFilter }
      }
      if (permitRequestLocalFilter.associated && permitRequestLocalFilter.associated != null && permitRequestLocalFilter.associated != 'null') {
        this.selectedFiler.associated = { id: permitRequestLocalFilter.associated, name: permitRequestLocalFilter.frontRequestAssociatedNameFilter }
      }
      this.selectedFiler.name = permitRequestLocalFilter.name
      this.selectedFiler.permitNo = permitRequestLocalFilter.permitNo
      this.selectedFiler.vendorId = permitRequestLocalFilter.vendorId
      this.selectedFiler.endDate = permitRequestLocalFilter.endDate
      this.selectedFiler.startDate = permitRequestLocalFilter.startDate
      this.selectedFiler.startDate = permitRequestLocalFilter.startDate
    } else {
      body = {
        activePage: 1,
        limit: 0,
        sortBy: this.sortSelected,
        sortType: this.sort,
        userId: this.apiService.userId,
        businessId: this.apiService.buisnessId,
        name: this.selectedFiler.name,
        permitNo: this.selectedFiler.permitNo,
        vendorId: this.selectedFiler.vendorId,
        endDate: this.selectedFiler.endDate,
        startDate: this.selectedFiler.startDate,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        tenant: tId,
        status: isActive,
        downloadExcel: 1,
        request: this.selectedFiler.request ? this.selectedFiler.request.id : null,
        associated: this.selectedFiler.associated ? this.selectedFiler.associated.id : null,
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
    }
    this.apiService.startLoader();
    this.apiService.postCall('PermitRequests/index/' + this.apiService.userId, {}, body).subscribe((result: any[]) => {
      // console.log(result);

      const link = document.createElement('a');
      link.setAttribute('href', result['data']);
      link.setAttribute('download', `report.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.apiService.stopLoader();

    });
  }

  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.permitNo != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.tenant != null) length = length + 1
    if (this.selectedFiler.request != null) length = length + 1
    if (this.selectedFiler.associated != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.vendorId != null) length = length + 1
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length = JSON.stringify(this.selectedFiler).split(null).length - 1
    // if (length == 7 || !this.applyFlag) return "(No Filter Selected)"
    // else if (this.applyFlag) return `(${7 - length} Filter Selected)`

    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }


  navigateToDetails(id) {
    this.route.navigate(['/permit-setting/work-edit/' + id]);
  }
  gotToView(d, flag) {
    var url = ""
    if (flag == 1) {
      if (d.status == 1) {
        url = this.apiService.permitUrl + "permits/permit-approvals-view/";
      } else {
        url = this.apiService.permitUrl + "permits/permit-approvals/";
      }
    } else {
      url = this.apiService.permitUrl + "permits/permit-approvals-view/";
    }
    // if(this.apiService.roleId !== 6){
    if (true) {
      if (d.level_1_user == null) { //if permit is not aprove then call following url
        //console.log(d.permit.level_1_user.split(","));
        if (d.permit.level_1_user instanceof Array) {
          url = url + d.id + "/" + d.permit.level_1_user[0] + "/level_1_user" + "/0/" + this.apiService.authId;
        } else if (d.permit.level_1_user instanceof String) {
          d.permit.level_1_user = d.permit.level_1_user.split(",");
          url = url + d.id + "/" + d.permit.level_1_user[0] + "/level_1_user" + "/0/" + this.apiService.authId;
        } else {
          if (d.permit.level_1_user != null && d.permit.level_1_user != '') {
            d.permit.level_1_user = d.permit.level_1_user.split(",");
            url = url + d.id + "/" + d.permit.level_1_user[0] + "/level_1_user" + "/0/" + this.apiService.authId;
          } else {
            if (d.permit.level_1_user == '') {
              url = url + d.id + "/null/level_1_user";
            } else {
              url = url + d.id + "/" + d.permit.level_1_user + "/level_1_user" + "/0/" + this.apiService.authId;
            }
          }
        }
      } else if (d.level_2_user == null) {
        if (d.permit.level_2_user instanceof Array) {
          url = url + d.id + "/" + d.permit.level_2_user[0] + "/level_2_user" + "/0/" + this.apiService.authId;
        } else if (d.permit.level_2_user instanceof String) {
          d.permit.level_2_user = d.permit.level_2_user.split(",");
          url = url + d.id + "/" + d.permit.level_2_user[0] + "/level_2_user" + "/0/" + this.apiService.authId;
        } else {
          if (d.permit.level_2_user != null && d.permit.level_2_user != '') {
            d.permit.level_2_user = d.permit.level_2_user.split(",");
            url = url + d.id + "/" + d.permit.level_2_user[0] + "/level_2_user" + "/0/" + this.apiService.authId;
          } else {
            if (d.permit.level_2_user == '') {
              url = url + d.id + "/null/level_2_user";
            } else {
              url = url + d.id + "/" + d.permit.level_2_user + "/level_2_user" + "/0/" + this.apiService.authId;
            }
          }
        }
      } else if (d.level_3_user == null) {
        if (d.permit.level_3_user instanceof Array) {
          url = url + d.id + "/" + d.permit.level_3_user[0] + "/level_3_user" + "/0/" + this.apiService.authId;
        } else if (d.permit.level_3_user instanceof String) {
          d.permit.level_3_user = d.permit.level_3_user.split(",");
          url = url + d.id + "/" + d.permit.level_3_user[0] + "/level_3_user" + "/0/" + this.apiService.authId;
        } else {
          if (d.permit.level_3_user != null && d.permit.level_3_user != '') {
            d.permit.level_3_user = d.permit.level_3_user.split(",");
            url = url + d.id + "/" + d.permit.level_3_user[0] + "/level_3_user" + "/0/" + this.apiService.authId;
          } else {
            if (d.permit.level_4_user == '') {
              url = url + d.id + "/null/level_3_user";
            } else {
              url = url + d.id + "/" + d.permit.level_3_user + "/level_3_user" + "/0/" + this.apiService.authId;
            }
          }
        }
      } else if (d.level_4_user == null) {
        if (d.permit.level_4_user instanceof Array) {
          url = url + d.id + "/" + d.permit.level_4_user[0] + "/level_4_user" + "/0/" + this.apiService.authId;
        } else if (d.permit.level_4_user instanceof String) {
          d.permit.level_4_user = d.permit.level_4_user.split(",");
          url = url + d.id + "/" + d.permit.level_4_user[0] + "/level_4_user" + "/0/" + this.apiService.authId;
        } else {
          if (d.permit.level_4_user != null && d.permit.level_4_user != '') {
            d.permit.level_4_user = d.permit.level_4_user.split(",");
            url = url + d.id + "/" + d.permit.level_4_user[0] + "/level_4_user" + "/0/" + this.apiService.authId;
          } else {
            if (d.permit.level_4_user == '') {
              url = url + d.id + "/null/level_4_user";
            } else {
              url = url + d.id + "/" + d.permit.level_4_user + "/level_4_user" + "/0/" + this.apiService.authId;
            }
          }
        }
      } else if (d.level_5_user == null) {
        if (d.permit.level_5_user instanceof Array) {
          url = url + d.id + "/" + d.permit.level_5_user[0] + "/level_5_user" + "/0/" + this.apiService.authId;
        } else if (d.permit.level_5_user instanceof String) {
          d.permit.level_5_user = d.permit.level_5_user.split(",");
          url = url + d.id + "/" + d.permit.level_5_user[0] + "/level_5_user" + "/0/" + this.apiService.authId;
        } else {
          if (d.permit.level_5_user != null && d.permit.level_5_user != '') {
            d.permit.level_5_user = d.permit.level_5_user.split(",");
            url = url + d.id + "/" + d.permit.level_5_user[0] + "/level_5_user" + "/0/" + this.apiService.authId;
          } else {
            if (d.permit.level_5_user == '') {
              url = url + d.id + "/null/level_5_user";
            } else {
              url = url + d.id + "/" + d.permit.level_5_user + "/level_5_user" + "/0/" + this.apiService.authId;
            }
          }
        }
        /*if(d.permit.level_5_user instanceof Array){
          url =url+d.id+"/"+d.permit.level_5_user[0]+"/level_5_user";
        }else{
          url =url+d.id+"/"+d.permit.level_5_user+"/level_5_user";
        }*/
      } else {
        if (d.permit.level_1_user instanceof Array) {
          url = url + d.id + "/" + d.permit.level_1_user[0] + "/level_1_user" + "/0/" + this.apiService.authId;
        } else if (d.permit.level_1_user instanceof String) {
          d.permit.level_1_user = d.permit.level_1_user.split(",");
        } else {
          if (d.permit.level_1_user != null && d.permit.level_1_user != '') {
            url = url + d.id + "/" + d.permit.level_1_user[0] + "/level_1_user" + "/0/" + this.apiService.authId;
          } else {
            if (d.permit.level_1_user == '') {
              url = url + d.id + "/null/level_1_user";
            } else {
              url = url + d.id + "/" + d.permit.level_1_user + "/level_1_user" + "/0/" + this.apiService.authId;
            }
          }
        }
      }
    }
    window.open(url, "_blank");
    /*if(flag==1){
      window.open(url, "_blank"); 
    }else{
      localStorage.setItem("PermitUrl",url);
      window.open('/permits-view/' + d.id, "_blank");
      // this.route.navigate(['/permits-view/' + d.id]);
    }*/

  }

  deactiveSelected() {

  }

  showAddMenu: boolean

  dublicateSelected() {
  }

  selectAllAssets;

  filterSelect = false;


  bussnissList = [];
  bussnissListFilter = [];
  propertyList = [];
  filterpropertyList = [];
  filterTenantList = [];
  filterrequestList = [];

  getPropertyList() {
    this.apiService.startLoader();
    // console.log(this.selectedFiler.business);
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId);


      //  this.selectedFiler.property = this.propertyList[idx]
    });


  }

  getTenantList(): void {
    this.apiService.startLoader();
    this.apiService.getCall('permits/applicationFormFilter/' + this.selectedFiler.property.id).subscribe((result: any[]) => {

      this.apiService.stopLoader();
      this.tenantList = result['tenants'];
      this.requestList = result['permits'];
    });
  }

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }

  callGetOutside() {
    // console.log('d');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;
      this.businessFilter = false;
      this.propertFilter = false;
      this.tenantFilter = false;
      this.requestFilter = false;
      this.showAddMenu = false;
      this.requestassociatedFilter = false;
    }
    if (btn.id != "changeButton") {
      this.statusSelection = undefined
    }
  }

  getName(flag) {
    if (flag == 1) {
      return "Approved";
    } else if (flag == 0) {
      return "Pending";
    } else if (flag == 2) {
      return "Reject";
    }
    return "Select"
  }
  closeAll() {
    this.businessFilter = false;
    this.propertFilter = false;
    this.statusFilter = false
    this.tenantFilter = false
  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }
  associtatedPermitList = [];
  onItemSelectPermit() {
    this.apiService.startLoader();
    this.apiService.getCall('permits/applicationFormFilterIndex/' + this.selectedFiler.request.id)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.associtatedPermitList = result['associated_permits'];
      });
  }

  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };

    pdfMake.createPdf(documentDefinition).download();

  }

}
