import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../../common/services/apiCalls.service';
import { StoredData } from '../../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-property-sites-list',
  templateUrl: './property-sites-list.component.html',
  styleUrls: ['./property-sites-list.component.css']
})
export class PropertySitesListComponent implements OnInit {
  tabSelected = 'active';
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  statusSelection;
  addPermission = false;
  editPermission = false;
  message;
  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    // for(var i=0;i<permission.length;i++){
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
    //     this.addPermission=true
    //   }
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
    //     this.editPermission=true
    //   }
    //
    // }
  }


  ngOnInit(): void {

    var meterListPagination = localStorage.getItem('meterListPagination');
    if (meterListPagination) {
      this.activePage = parseInt(meterListPagination);
    }


    this.getList(1);


    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }

  // let obj ={
  //   "roleId":this.apiService.roleId,
  //   "userId":this.apiService.userId,
  //   "businessId": this.apiService.buisnessId,
  //   "outletId":this.tiggerDetailsForm.value.property?this.tiggerDetailsForm.value.property : "",
  // }
  statusSites;
  getList(isActive: any = 1) {
    this.apiService.startLoader();
    // this.applyFlag=false;
    this.statusSites = isActive;
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,
      id: this.selectedFiler.id,
      isActive: Number(this.statusSites),
      "roleId": this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('siteTypes/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.allList = result['data'];

      this.apiService.stopLoader();

    });
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { name: null, id: null };
    var d = this.tabSelected == 'active' ? 1 : 0;
    this.getList(d);
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1;

  totalnoOfPages;

  changePage(ev) {
    // this.pageSize = d
    var d = this.tabSelected == 'active' ? 1 : 0;
    this.getList(d);
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }

  allList = [];



  pagination(d) {
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('meterListPagination', d);
      var d1 = this.tabSelected == 'active' ? 1 : 0;
      this.getList(d1);
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }
    var d = this.tabSelected == 'active' ? 1 : 0;
    this.getList(d);
  }


  selectedFiler = { name: null, id: null };


  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }

    // console.log(this.statusSelection);

    // if($(".stama"+d).css('display') == 'none'){
    //   $(".stama"+d).css("display","block");
    // }else{
    //   $(".stama"+d).css("display","none");
    // }
  }






  applyFlag = false;

  applyFilter() {
    this.applyFlag = true;
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,
      id: this.selectedFiler.id,
      isActive: Number(this.statusSites),
      "roleId": this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.startLoader();
    this.apiService.postCall('siteTypes/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.allList = result['data'];

      this.apiService.stopLoader();

    });
  }

  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;

    if (this.selectedFiler.name != null && this.selectedFiler.name != 'null' && this.selectedFiler.name != '') {
      length = length + 1;
    }

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) {
      return '(None Selected)';
    } else if (this.applyFlag) {
      return `(${length} Filter Selected)`;
    }
  }




  navigateToDetails(d) {
    this.route.navigate(['/master-property/sites-edit/' + d.id]);
  }

  deactiveSelected() {
  }

  dublicateSelected() {
  }


  filterSelect = false;


  changeIsActive(id, isActive?) {
    this.apiService.startLoader();
    let obj = {
      'isActive': Number(isActive),
      'authId': this.apiService.userId,
    };
    this.apiService.postCall('siteTypes/edit/' + id, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      var d = this.tabSelected == 'active' ? 1 : 0;
      this.getList(d);
      this.statusSelection = null
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.apiService.stopLoader();

    });
  }

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }





  callGetOutside() {
    // console.log('d');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;
    }
  }

  closeAll() {


  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }


  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };

    pdfMake.createPdf(documentDefinition).download();

  }

}
