import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;


@Component({
  selector: 'app-dashboard-index',
  templateUrl: './dashboard-index.component.html',
  styleUrls: ['./dashboard-index.component.css']
})
export class DashboardIndexComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }
  checklistDashboard = [{ name: "Asset name", property: "Site-A", exp_date: "12-12-2021" }]
  ngOnInit(): void {
    this.getAsset()
    this.getLocationList()
  }

  getEndDate(data) {
    let value = data.replace(" days", "")
    return parseInt(value)
  }

  // assetDashboard
  pageSize = 10
  tableData
  activePage: any = 1
  getAsset() {
    this.apiService.startLoader()
    let body = {
      // businessId:this.apiService.buisnessId,


      limit: this.pageSize,
      pageNo: this.activePage,

      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,

    }

    if (this.storedData.dashBoardIndex['apiObjParam']) {

      body["endDate"] = this.storedData.dashBoardIndex['apiObjParam']['endDate'],
        body["startDate"] = this.storedData.dashBoardIndex['apiObjParam']['startDate']
    }
    this.apiService.postCall(this.storedData.dashBoardIndex.api, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.tableData = result[this.storedData.dashBoardIndex.apiObj];
      // console.log(this.tableData)
      this.setPaginationArray(result)

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.curveChart()
    })
  }
  downloadReportButton() {
    let body = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "downloadData": this.tableData,
      "headers":this.storedData.dashBoardIndex.headers,
      "dataSource":this.storedData.dashBoardIndex.dataSource,
      "tableName":this.storedData.dashBoardIndex.tableName
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('todays-checklists/dashboardWidgetsDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']

    })
  }

  getNumber(data) {
    // // console.log(data.)
    let dataS = parseInt(data);
    if (dataS) return true
    else return false
  }

  getPropertyName(id) {
    let idx = this.locationList.findIndex(x => x.id == id)
    if (idx != -1) return this.locationList[idx].outletName
  }


  goBack(): void {
    this._location.back();
  }


  paginationArray = []
  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    // console.log(result)
    let noOfPAges = Math.ceil(result[this.storedData.dashBoardIndex.rowCountParam] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets = []
      this.activePage = d
      // console.log(d)
      this.getAsset()
    }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getAsset()
  }



}
