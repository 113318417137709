import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { FormGroup, FormArray,FormControl, FormBuilder,Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-employee-survey-add',
  templateUrl: './employee-survey-add.component.html',
  styleUrls: ['./employee-survey-add.component.css']
})
export class EmployeeSurveyAddComponent implements OnInit {

  constructor(
    public router: Router,
    public _location: Location,
    public apiService: ApiService,
    public formBuilder:FormBuilder,
    public storedData:StoredData
  ) { }

  surveyForm = this.formBuilder.group({
    businessId:[this.apiService.buisnessId , Validators.required],
    authId:this.apiService.authId,
    empsurveyName:[null , Validators.required],
  });
  surveyId=null
  ngOnInit(): void {
    this.surveyId = this.router.url.split("/")[2]
    if(this.surveyId){
      this.surveyForm.patchValue({empsurveyName:localStorage.getItem("empSurveyName")})
    }
  }
  getValidation(){
    return this.surveyForm.valid && this.surveyForm.value.empsurveyName != null && this.surveyForm.value.empsurveyName.trim();
  }

  goBack():void{
    this._location.back();
  }

  backClicked(){
    this.router.navigate(['/employee-survey']);
  }
  message="";
  submitSurvey():void{
    // console.log("forms Data : ",this.surveyForm.value);
   
    let obj={
      "businessId": this.apiService.buisnessId,
      "name" : this.surveyForm.value.empsurveyName,
      "authId": this.apiService.authId? this.apiService.authId : 1,
    }
    this.apiService.postCall('EmpSurveys/add',{} ,obj).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      if(result['success'] == true){
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        (<HTMLInputElement>document.getElementById('openSuccesserror')).click();
      }
    });
   } 

   editSurvey():void{
    // console.log("forms Data : ",this.surveyForm.value);
   
    let obj={
      "businessId": this.apiService.buisnessId,
      "name" : this.surveyForm.value.empsurveyName,
      "authId": this.apiService.authId? this.apiService.authId : 1,
    }
    this.apiService.postCall('EmpSurveys/add/'+this.surveyId,{} ,obj).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      if(result['success'] == true){
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        (<HTMLInputElement>document.getElementById('openSuccesserror')).click();
      }
    });
   } 
  
  }

