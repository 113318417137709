import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var require: any;
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lost-and-found-lost-articles',
  templateUrl: './lost-article-index-list.component.html',
  styleUrls: ['./lost-article-index-list.component.css']
})
export class LostArticleIndexListComponent implements OnInit {
  tabSelected = 'lostArticles';
  addPermission = false;
  editPermission = false;
  message;
  tenantList = [];
  todaysDate = new Date();
  constructor(public route: Router, public apiService: ApiService, public router: Router, public storedData: StoredData) {
    let permission = [];
    // permission = JSON.parse(localStorage.getItem('permissions'));
    // permission = permission.filter(item => item['permissionModuleId'] == 9);
  }
  LostArticleComplaintsFilter = false;
  ngOnInit(): void {
    var LostArticlePagination = localStorage.getItem('LostArticlePagination');
    if (LostArticlePagination) {
      this.activePage = parseInt(LostArticlePagination);
    } else {
      this.activePage = 1
    }
    this.getList();
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }
  getCheck(text) {
    return text.includes("Rejected");
  }

  requestList = []
  getList() {
    this.apiService.startLoader();
    this.workList = []
    let body = {}
    if (localStorage.getItem("LostArticleComplaintsFilter") && localStorage.getItem("LostArticleComplaintsFilter") != null && localStorage.getItem("LostArticleComplaintsFilter") != 'null') {
      let LostArticleComplaintsFilter = JSON.parse(localStorage.getItem("LostArticleComplaintsFilter"));
      body = LostArticleComplaintsFilter;
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        userId: this.apiService.userId,
        businessId: this.apiService.buisnessId,
        articleFoundFlag: this.selectedFiler.articleFoundFlag ? this.selectedFiler.articleFoundFlag.name : "",
      };
      localStorage.setItem('LostArticleComplaintsFilter', JSON.stringify(body));
    }
    this.apiService.postCall('LostArticleComplaints/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];
      this.apiService.stopLoader();
    });
  }
  downloadReport(): void {
    this.apiService.startLoader();
    let body = {
      detailsArticleLost: this.selectedFiler.detailsArticleLost ? this.selectedFiler.detailsArticleLost : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : "",
      articleFoundFlag: this.selectedFiler.articleFoundFlag ? this.selectedFiler.articleFoundFlag.name : "",
      limit: this.pageSize,
      pageNo: this.activePage,
      sortBy: this.sortSelected,
      sortType: this.sort,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      download: 1,
    };
    this.apiService.postCall('LostArticleComplaints/index', {}, body).subscribe((result: any[]) => {
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `LostArticleLists.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader();
    });
  }
  foundArticleNoFilter: boolean
  businessId
  clear() {
    localStorage.removeItem('LostArticleComplaintsFilter');
    localStorage.setItem("LostArticleComplaintsFilter", null);
    localStorage.removeItem('LostArticlePagination');
    localStorage.setItem("LostArticlePagination", null);
    this.activePage = 1
    this.pageSize = 10
    this.sortSelected = 'id'
    this.sort = 'desc'
    this.applyFlag = false;
    this.selectedFiler = { detailsArticleLost: null, startDate: null, endDate: null, articleFoundFlag: { id: 'No', name: 'No' }, businessId: null };
    this.getList();
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1

  totalnoOfPages;

  changePage(ev) {
    localStorage.removeItem('LostArticleComplaintsFilter');
    localStorage.setItem("LostArticleComplaintsFilter", null);
    this.selectedAssets = [];
    // this.pageSize = d
    this.getList();
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray);
  }

  workList = [];
  selectedAssets = [];


  getCustomName(d) {
    return 'Every ' + d.frequency_no + ' ' + d.frequency_unit;
  }

  pagination(d) {
    localStorage.removeItem('LostArticleComplaintsFilter');
    localStorage.setItem("LostArticleComplaintsFilter", null);
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('LostArticlePagination', d);
      this.getList();
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    localStorage.removeItem('LostArticleComplaintsFilter');
    localStorage.setItem("LostArticleComplaintsFilter", null);
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList();
  }


  selectedFiler = { detailsArticleLost: null, startDate: null, endDate: null, articleFoundFlag: { id: 'No', name: 'No' }, businessId: null };


  applyFlag = false;

  applyFilter() {
    localStorage.removeItem('LostArticleComplaintsFilter');
    localStorage.setItem("LostArticleComplaintsFilter", null);
    this.workList = []
    this.applyFlag = true;
    this.activePage = 1;
    let body = {}
    body = {
      detailsArticleLost: this.selectedFiler.detailsArticleLost ? this.selectedFiler.detailsArticleLost : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : "",
      articleFoundFlag: this.selectedFiler.articleFoundFlag ? this.selectedFiler.articleFoundFlag.name : "",
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
    };
    this.apiService.startLoader();
    this.apiService.postCall('LostArticleComplaints/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];
      this.apiService.stopLoader();

    });
  }

  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc
  foundArticleNoList = [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }];
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.articleFoundFlag != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  showAddMenu: boolean

  dublicateSelected() {
  }

  selectAllAssets;

  filterSelect: boolean;


  bussnissList = [];
  bussnissListFilter = [];

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }

  callGetOutside() {
    // console.log('d');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;
      this.LostArticleComplaintsFilter = false;
    }
  }
  closeAll() {
    this.LostArticleComplaintsFilter = false;
  }

}
