import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-meter-details-header',
  templateUrl: './meter-details-header.component.html',
  styleUrls: ['./meter-details-header.component.css']
})
export class MeterDetailsHeaderComponent implements OnInit {

  @Input() meterDetails;
  @Input() meterId
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  // @Input()
  constructor(public _location: Location,public router:Router,public apiService:ApiService) { }


  changeImagePermission=false
  ngOnInit(): void {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==6 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "Change Image" && permission[i]['actionStatus'] == 1){
        this.changeImagePermission=true
      }
    }
  }



  uploadedFiles


openCropperEvent=false;
openCropper(){
  if(this.changeImagePermission){
  // console.log("assd");
  (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
  this.openCropperEvent= true;
  }
}

openCropperWithImage(){
  if(this.changeImagePermission){
  (<HTMLInputElement>document.getElementById('cropeerPopupSuccessWithImage')).click();
  }
}

editImage(){
  if(this.changeImagePermission){
  // console.log("assd");
  (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
  this.openCropperEvent= true;
  }
}

fileChange(event) {
  // console.log(event)
  // let fileList: FileList = event.target.files;
  // if(fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFiles =file
      
     

  //   }
  // this.openCropperEvent=false;

  this.uploadedFiles = event;
    let testData:FormData = new FormData();
    testData.append('image', this.uploadedFiles);
   
    testData.append('authId', this.apiService.userId);
   
// // console.log(obj)
this.apiService.startLoader()
    this.apiService.postCall('meters/edit/'+this.meterId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.openCropperEvent= false;
      this.updateData.emit()
    })
}
}
