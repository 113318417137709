import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import {HashLocationStrategy, Location} from '@angular/common';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;


@Component({
  selector: 'app-dashboard-checklist-index',
  templateUrl: './dashboard-checklist-index.component.html',
  styleUrls: ['./dashboard-checklist-index.component.css']
})
export class DashboardChecklistIndexComponent implements OnInit {

  constructor(public route: Router,public apiService:ApiService,public router:Router , public _location: Location, public storedData:StoredData,) { }
checklistDashboard=[{name:"Asset name",property:"Site-A",exp_date:"12-12-2021"}]
  ngOnInit(): void {
    this.getMostCompaltCust()
  }

  getEndDate(data){
    let value= data.replace(" days","")
    return parseInt(value)
  }

  // assetDashboard
  pageSize=10
  mostComplainCust
  activePage:any=1
  getMostCompaltCust(){
    this.apiService.startLoader()
    let body={
      // businessId:this.apiService.buisnessId,


limit:this.pageSize,
pageNo:this.activePage,

"roleId":this.apiService.roleId,
"userId":this.apiService.userId,
"businessId": this.apiService.buisnessId,

    }

    if(this.storedData.dashBoardIndex['apiObjParam']){
      
      body["endDate"]=this.storedData.dashBoardIndex['apiObjParam']['endDate'],
      body["startDate"]=this.storedData.dashBoardIndex['apiObjParam']['startDate'],
      body["outletId"]=this.storedData.dashBoardIndex['apiObjParam']['outletId'],
      body["departmentId"]=this.storedData.dashBoardIndex['apiObjParam']['departmentId']
    }
    this.apiService.postCall(this.storedData.dashBoardIndex.api,{} , body ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.mostComplainCust = result['data']['compliantTeam'];
      // console.log(this.mostComplainCust)
      this.setPaginationArray(result)
    })
  }
  downloadReportButton() {
    let body = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "downloadData": this.mostComplainCust,
      "headers":this.storedData.dashBoardIndex.headers,
      "tableName":this.storedData.dashBoardIndex.tableName
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('todays-checklists/dashboardWidgetsDownload2', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }

  jumpToParked
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)

    return array
  }

  getNumber(data){
    // // console.log(data.)

    if(parseInt(data) != NaN) return true
    else return false  
  }
  

  goBack():void{
    this._location.back();
  }


  paginationArray=[]
  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    // console.log(result)
    let noOfPAges = Math.ceil(result[this.storedData.dashBoardIndex.rowCountParam] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets = []
      this.activePage = d
      // console.log(d)
      this.getMostCompaltCust()
    }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getMostCompaltCust()
  }
}
