import { Component, EventEmitter, OnInit, Input,Output } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parts-location-history',
  templateUrl: './parts-location-history.component.html',
  styleUrls: ['./parts-location-history.component.css']
})
export class PartsLocationHistoryComponent implements OnInit {
  @Input() locationList: any;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();
  constructor(public router: Router, public apiService: ApiService) { }

  partsId: any;
  locationId: any = null;
  locationHistoryList = []
  ngOnInit(): void {
    this.partsId = this.router.url.split("/")[2]
    this.gertLocationHistory()
  }

  gertLocationHistory() {
    this.apiService.getCall('location-historys/index/0/' + this.partsId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.locationHistoryList = result['data']
    })


  }

  message;
  update() {
    if(this.locationId){
      let obj = {
        partsId: this.partsId,
        locationId: parseInt(this.locationId),
        authId: this.apiService.authId
      }
      this.apiService.startLoader()
      // console.log(this.apiService.loader)
      this.apiService.postCall('parts/editLocation', {}, obj).subscribe((result: any[]) => {
        // console.log(result);
        this.locationId = null;
        this.apiService.stopLoader();
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess1')).click();
        (<HTMLInputElement>document.getElementById('duplicate12')).click();
        this.gertLocationHistory()
        this.updateData.emit()
      })
    }
  }
  closeAll(){
    (<HTMLInputElement>document.getElementById('closePopUp')).click();
  }

  ngOnDestroy() {
    // console.log('foo destroy')
  }
}
