import { Component, HostListener, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { DatePipe } from "@angular/common";
import html2canvas from 'html2canvas';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.css']
})
export class FilterComponentComponent implements OnInit {

  @Output() zoneId = new EventEmitter<any>();

  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;
  propertyZone1 = false;
  propertyClients1 = false;
  isState1 = false;
  isCluster1 = false;
  isCityForFilter1 = false;
  isDepartmentFilter1 = false;
  isPropertyFilter1 = false;
  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterPropertySearch: any;
  filterDepartmentSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];


  selectedFiler = { startDate: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      //this.showFilter = false
      this.propertyFiletr = false;
      this.propertFilter = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      // this.showAddMenu = false;
      this.businessFilter = false;
      this.propertyZone = false;
      this.isState = false;
      this.isCluster = false;
      this.isCityForFilter = false;
      this.isDepartmentFilter = false;
      this.isPropertyFilter = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertFilter = false;
      this.propertyFiletr = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
      this.propertyZone = false;
      this.isState = false;
      this.isCluster = false;
      this.isCityForFilter = false;
      this.isDepartmentFilter = false;
      this.isPropertyFilter = false
    }

  }

  filter = {}
  closeAll() {
    this.propertyFiletr = false;
    this.statsuFilter = false;
    this.typeFilter = false;
    this.businessFilter = false;

    this.propertyZone = false;
    this.isState = false;
    this.isCluster = false;
    this.isCityForFilter = false;
    this.isDepartmentFilter = false;
    this.isPropertyFilter = false
    this.propertyFiletr = false;
  }
  downloadFlag = false;
  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.filter = {
      "flag": 0, "zoneId": this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      "clusterId": this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      "stateId": this.selectedFiler.state ? this.selectedFiler.state.id : null,
      "cityId": this.selectedFiler.city ? this.selectedFiler.city.id : null,
      "clientId": this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      "property": this.selectedFiler.property ? { id: this.selectedFiler.property.id, name: this.selectedFiler.property.outletName } : null
    }
    this.zoneId.emit(this.filter)
    //this.getIds.emit();
  }

  onchangeFunction() {
    this.applyFlag = true;

    this.filter = {
      "flag": 1, "zoneId": this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      "clusterId": this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      "stateId": this.selectedFiler.state ? this.selectedFiler.state.id : null,
      "cityId": this.selectedFiler.city ? this.selectedFiler.city.id : null,
      "clientId": this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
    }
    this.zoneId.emit(this.filter)
    //this.getIds.emit();
  }


  clear() {
    this.applyFlag = false;
    this.locationList = [];
    this.filteredpropertyListData = []
    this.clusterListData = [];
    this.cityListData = []
    this.stateListData = []
    this.selectedFiler = { startDate: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
    this.filter = {
      "flag": 1, "zoneId": null,
      "clusterId": null,
      "stateId": null,
      "cityId": null,
      "clientId": null,
      "property": null,
    }
    this.zoneId.emit(this.filter)
  }
  businessFeaturePermit = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData, public datepipe: DatePipe) { }

  ngOnInit(): void {

    this.getLocationList()
    this.getZoneListData();
    this.getClients();
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }

  getClients() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.ClientsListData = []
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      if (this.ClientsListData.length > 0) {
        this.filteredClientsListData = this.ClientsListData.slice();
      }
      // let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // // console.log(this.apiService.roleId)

    })
  }

  locationList: any;
  getLocationList() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      citieId: this.selectedFiler.city ? this.selectedFiler.city.id : ""
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.locationList = []
      this.locationList = result['data']
      if (this.locationList) {
        this.propertyListData = this.locationList.slice();
        this.filteredpropertyListData = this.locationList.slice();
      }
      // if (result["clients"]) {
      //   this.ClientsListData = result["clients"];
      // }
      //this.filteredClientsListData = this.ClientsListData.slice();
      let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)

    })
  }

  getPropertyName(id) {
    let idx = this.locationList.findIndex(x => x.id == id)
    if (idx != -1) return this.locationList[idx].outletName
  }


  propertFilter = false;
  filterProperty(ev) {
    this.locationList = this.filteredpropertyListData.filter((item: any) => {
      if (item.outletName != null) {
        return item.outletName
          .toLowerCase()
          .includes(this.filterPropertySearch.toLowerCase());
      }
    });
  }


}
