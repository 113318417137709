import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset-covered',
  templateUrl: './asset-covered.component.html',
  styleUrls: ['./asset-covered.component.css']
})
export class AssetCoveredComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }

  type:any;
  insuranceId:any;
  insuranceDetails
  saveNotify=false
  message
  insurancePropertiesAssets = []
  assetId=[]
  ngOnInit(): void {
    this.insurancePropertiesAssets2=[]
    //this.type = this.route.url.split("/")[2]
    this.insuranceId = this.route.url.split("/")[2]
    this.apiService.startLoader()
    this.apiService.getCall('insurances/view/'+this.insuranceId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.insuranceDetails = result['data']
      this.insurancePropertiesAssets =this.insuranceDetails.insurancePropertiesAssets;
      for(var i=0;i<this.insurancePropertiesAssets.length;i++){
          this.assetId.push(this.insurancePropertiesAssets[i]['assetManagementId']);
      }
      this.apiService.stopLoader()
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Notification Setting" && permission[i]['actionStatus'] == 1){
        this.saveNotify=true
      }
    }

  }

  sortSelected: any = 'id'
  sort: any = 'desc'

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getAsset();
  }
  
  insurancePropertiesAssets2=[]
  getAsset(){
    this.insurancePropertiesAssets=[]
    const body = {
      limit: 10000,
      sortType: this.sort,
      sortBy: this.sortSelected,
      idsIn:this.assetId
    };
   
     this.apiService.startLoader()
     this.apiService.postCall('asset-managements/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.insurancePropertiesAssets2 = result['data']['data'];
    })
  }
  navigateToDetails(d) {
    this.route.navigate(['/meter-detail/' + d.meterId])
  }
}
