import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-feedback-index',
  templateUrl: './feedback-index.component.html',
  styleUrls: ['./feedback-index.component.css']
})
export class FeedbackIndexComponent implements OnInit {

  constructor(public _location: Location, http: HttpClient, public route: Router, public apiService: ApiService) { }

  tabSelected: String;

  paginationArray = [];
  activePage: any = 1;
  pageSize = 10;
  businessIdCheck = this.apiService.buisnessId;
  showFilter = false;
  message
  sortType = 'desc';
  // sortBy = 'id';

  search: any;
  selectedFiler = { buisness: null, startDate: null, ensDate: null }

  feedbackArray = [];
  surveyId
  todayDate: any;
  ngOnInit(): void {

    this.surveyId = this.route.url.split("/")[2]
    this.getSurveyList();
    if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      this.getPropertyList();
    }
    this.getBusinessList()
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }


  clear() {
    localStorage.removeItem('selectedFilerFeedbackIndex');
    localStorage.setItem("selectedFilerFeedbackIndex", null);
    this.selectedFiler = { buisness: null, startDate: null, ensDate: null }
    this.getSurveyList()
  }

  applyFlag = false;
  applyFilter() {
    localStorage.removeItem('selectedFilerFeedbackIndex');
    localStorage.setItem("selectedFilerFeedbackIndex", null);
    this.applyFlag = true;
    // console.log(this.selectedFiler)
    this.activePage = 1;
    this.getSurveyList()

  }

  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  sortSelected: any = 'id';
  // sortType='asc'
  sortData(d) {
    this.sortSelected = d
    if (this.sortType == 'asc') this.sortType = 'desc';
    else this.sortType = 'asc'
    this.getSurveyList()
  }

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    let length = JSON.stringify(this.selectedFiler).split(null).length - 1
    if (length == 2 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${2 - length} Filter Selected)`
  }


  // activePage=1
  totalnoOfPages
  //  pageSize=10
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  changePage() {
    localStorage.removeItem('selectedFilerFeedbackIndex');
    localStorage.setItem("selectedFilerFeedbackIndex", null);
    // console.log(this.pageSize)
    this.getSurveyList();
  }

  pagination(d) {
    localStorage.removeItem('selectedFilerFeedbackIndex');
    localStorage.setItem("selectedFilerFeedbackIndex", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets=[]
      this.activePage = d
      // console.log(d)
      this.getSurveyList()
    }
  }

  average: any;
  outletNameFirst
  surveyNameFirst
  locationFirst
  getSurveyList() {
    // localStorage.removeItem('selectedFilerFeedbackIndex');
    // localStorage.setItem("selectedFilerFeedbackIndex", null);
    this.tabSelected = 'feedback';
    let businessId = null;
    let userId = 0;
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.buisness ? this.selectedFiler.buisness.id : null;
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId
      userId = this.apiService.userId
    }
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1);//date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date();
    let body = {};
    if (localStorage.getItem("selectedFilerFeedbackIndex") && localStorage.getItem("selectedFilerFeedbackIndex") != null && localStorage.getItem("selectedFilerFeedbackIndex") != 'null') {
      let selectedFilerFeedbackIndex = JSON.parse(localStorage.getItem("selectedFilerFeedbackIndex"));
      body = selectedFilerFeedbackIndex;
      this.selectedFiler.startDate = body['startDate'] ? moment(body['startDate']).format('YYYY-MM-DD') : '';
      this.selectedFiler.ensDate = body['endDate'] ? moment(body['endDate']).format('YYYY-MM-DD') : '';
      this.applyFlag = true;
      this.activePage = 1;
      if (this.downloadflag == true) {
        body['downloadReport'] = true;
      }
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortSelected,
        businessId: businessId,
        // question : this.selectedFiler.survayName,
        // outletId:this.selectedFiler.property ? this.selectedFiler.property.id : null,
        // type:this.selectedFiler.type  ? this.selectedFiler.type.id : null,
        startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : '',
        endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : '',
        // email :this.selectedFiler.email,
        // userName: this.selectedFiler.userName,
        surveyGroupId: this.surveyId,
        downloadReport: this.downloadflag
      };
      if (this.selectedFiler.startDate) {
        localStorage.setItem('selectedFilerFeedbackIndex', JSON.stringify(body));
      }
    }
    if (this.search != '') body['assetName'] = this.search
    this.apiService.startLoader()
    this.apiService.postCall('feedbacks/report', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.feedbackArray = [];
      this.feedbackArray = result['data'];
      this.average = result['averageScore'].toFixed(2);
      this.outletNameFirst = result['outletName'];
      this.surveyNameFirst = result['surveyName'];
      this.locationFirst = result['location'];
      if (this.downloadflag) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = result['url'];
        a.download = "feedback.csv";
        a.click();
        document.body.removeChild(a);
        this.downloadflag = false
      }
      this.setPaginationArray(result);
    })
  }
  downloadflag = false
  downloadReport() {
    this.downloadflag = true
    this.getSurveyList();
  }

  downloadReportPDF() {
    this.downloadflag = true
    this.getSurveyListPDF();
  }

  getSurveyListPDF() {
    this.showAddMenu = this.showAddMenu ? false : true;
    this.tabSelected = 'feedback';
    let businessId = null;
    let userId = 0;
    this.apiService.startLoader();
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.buisness ? this.selectedFiler.buisness.id : null;
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId
      userId = this.apiService.userId
    }
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1);//date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    const body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortType: this.sortType,
      sortBy: this.sortSelected,
      businessId: businessId,
      // question : this.selectedFiler.survayName,
      // outletId:this.selectedFiler.property ? this.selectedFiler.property.id : null,
      // type:this.selectedFiler.type  ? this.selectedFiler.type.id : null,
      // email :this.selectedFiler.email,
      // userName: this.selectedFiler.userName,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : '',
      endDate: this.selectedFiler.ensDate ? moment(this.selectedFiler.ensDate).format('YYYY-MM-DD') : '',
      surveyGroupId: this.surveyId,
      downloadReport: this.downloadflag
    };

    if (this.search != '') body['assetName'] = this.search

    // this.apiService.startLoader()
    this.apiService.uploadCall('feedbacks/reportPdf', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      // this.apiService.stopLoader()
     // this.feedbackArray = [];
      if (result['data']) {
        //this.feedbackArray = result['data'];
        // this.average = result['averageScore'].toFixed(2);
        // if(this.downloadflag){
        // let a = document.createElement("a");
        // a.href = environment.BASE_API_URL + "downloadUnderlying"
        // a.target = "_blank";

        this.apiService.stopLoader();
        let a = document.createElement("a");
        // document.body.appendChild(a);
        a.href = result['url'];
        a.download = "feedback.pdf";
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
        this.downloadflag = false
        // }
      }else{
        this.apiService.stopLoader();
      }
    })

  }


  // pagination(d) {
  //   this.activePage = d
  //   // console.log("Active Page :",d)
  //   this.getSurveyList()
  // }

  getFeedbackList() {
    // if(this.tabSelected != 'feedback'){
    //   this.tabSelected == 'feedback';
    // }
  }
  goToAddSurvey() {
    this.route.navigateByUrl('/survey-add');
  }


  // selectedFiler={}

  showAddMenu: boolean
  businessFilter
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.showFilter = false
      this.propertFilter = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.showAddMenu = false;
      this.businessFilter = false;
      // // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertFilter = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
      // this.selectFreq = false
      $(".hideDic").css("display", "none");
    }

  }
  typeFilter = false;
  statsuFilter = false;
  closeAll() {
    this.propertFilter = false;
    // this.statsuFilter = false;
    this.typeFilter = false;
    this.businessFilter = false;
  }

  filterBusinessSearch
  // businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  typeList = [{ id: 0, name: 'Single-Question' }, { id: 1, name: 'Multi-Question' },]

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }


  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let businessId = null;
    let userId = 0;
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.buisness.id;
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId
      userId = this.apiService.userId
    }
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": businessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + businessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  filterPropertySearch
  propertFilter = false;
  filterLocation(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

}
