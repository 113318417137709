import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-qr-generate-ticket',
  templateUrl: './qr-generate-ticket.component.html',
  styleUrls: ['./qr-generate-ticket.component.css']
})
export class QrGenerateTicketComponent implements OnInit {
  addTicektForm: FormGroup
  constructor(public _location: Location, public route: Router, public router: Router, private fb: FormBuilder, public apiService: ApiService, public storedData: StoredData,) { }

  outletId
  businessId
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  ngOnInit(): void {

    document.body.style.overflow = 'hidden';
    this.outletId = this.router.url.split("/")[2];
    this.businessId = this.router.url.split("/")[3];
    this.getOutLetList()

    this.addTicektForm = this.fb.group({
      outletId: [null, Validators.required],
      departmentId: [null, Validators.required],
      businessId: [null, Validators.required],
      name: [null, Validators.required],
      floor: [null, Validators.required],
      location: [null, Validators.required],
      email:[null,[Validators.required,Validators.email]],
      mobile:[null,[Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      subject: [null, Validators.required],
      description: [null,],
      priorityId: [1],
    });

    
  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  backClicked() {
    this._location.back();
  }

  getOutLetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.businessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
      this.departmentList = result['departments']
      this.addTicektForm.patchValue({ outletId: this.outletId, businessId: this.businessId })
    })
  }




  vedioSection: boolean;
  imageSection: boolean
  // departmentList=[]
  departmentList = []
  daysList = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  statusList = []
  assetList = []
  propertyList = []
  userList = []
  message: any;
  createTicket() {
    let userArry: any = []
    // console.log(this.addTicektForm.value)
    let testData: FormData = new FormData();

    testData.append('createdBy', this.apiService.userId);
    testData.append('outletId', this.addTicektForm.value.outletId);
    testData.append('departmentId', this.addTicektForm.value.departmentId);
    testData.append('businessId', this.businessId);
    testData.append('name', this.addTicektForm.value.name);
    testData.append('floor', this.addTicektForm.value.floor);
    testData.append('userId', userArry);
    testData.append('location', this.addTicektForm.value.location);
    testData.append('mobile', this.addTicektForm.value.mobile);
    testData.append('email', this.addTicektForm.value.email);
    testData.append('subject', this.addTicektForm.value.subject);
    testData.append('description', this.addTicektForm.value.description);
    testData.append('priorityId', this.addTicektForm.value.priorityId);
    if (this.uploadedFiles) testData.append('attach1', this.uploadedFiles);
    if (this.uploadedFiles2) testData.append('attach2', this.uploadedFiles2);
    if (this.uploadedFiles3) testData.append('attach3', this.uploadedFiles3);

    this.apiService.startLoader()
    this.apiService.postCall('tickets/qrTicketAdd', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) this.message = 'Ticket raised successfully';
      else this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }

  clearFields(){
    this.addTicektForm.reset();
    this.removeFile1();;
    this.removeFile2();;
    this.removeFile3();
    this.addTicektForm.patchValue({ outletId: this.outletId, businessId: this.businessId,priorityId:1 })
  }
  uploadedFiles: any;
  uploadedFiles2: any;
  uploadedFiles3: any;
  fileName: any
  fileName2: any;
  fileName3: any;

  vedioUploadFile: any;
  vedioUploadFileName: any
  fileChangeVedio(event) {
    // console.log(event.target.files[0].type)
    if (event.target.files[0].type == "video/mp4" || event.target.files[0].type == "video/quicktime" || event.target.files[0].type == "video/x-ms-wmv") {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.vedioUploadFile = file
        this.vedioUploadFileName = file.name




      }
    }
    else {

      this.message = "File with format " + event.target.files[0].type + " not acceptable, please choose Vedio";


      (<HTMLInputElement>document.getElementById('openError')).click();
    }
  }
  fileChange(event) {
    // console.log(event.target.files[0].type)

    // .png, .jpg, .jpeg ,  .mp4, .mov, .wmv,.pdf 
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "application/pdf") {
      var field = (<HTMLInputElement>document.getElementById('before_image'));


      // var image =<HTMLInputElement> document.getElementById('before_image');
      // image.src = URL.createObjectURL(event.target.files[0]);
      // console.log(this.fileName)
      if (!this.fileName) {
        var span = document.getElementById('images');
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
          var img = document.createElement('img');
          img.src = URL.createObjectURL(event.target.files[0]);
          img.id = "firstId";
          if (document.getElementById('firstId')) {
            document.getElementById('firstId').remove();
          }
          span.appendChild(img);

          // span.removeChild(img);
          img.style.height = "88px"
          // console.log(event)
        }
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles = file
          this.fileName = file.name




        }
      }
      else if (!this.fileName2) {
        var span = document.getElementById('images2');
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
          var img = document.createElement('img');
          img.src = URL.createObjectURL(event.target.files[0]);
          img.id = "firstId2";
          if (document.getElementById('firstId2')) {
            document.getElementById('firstId2').remove();
          }
          span.appendChild(img);

          // span.removeChild(img);
          img.style.height = "88px"
          // console.log(event)
        }
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles2 = file
          this.fileName2 = file.name




        }
      }
      else if (!this.fileName3) {
        var span = document.getElementById('images3');
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
          var img = document.createElement('img');
          img.src = URL.createObjectURL(event.target.files[0]);
          img.id = "firstId3";
          if (document.getElementById('firstId3')) {
            document.getElementById('firstId3').remove();
          }
          span.appendChild(img);

          // span.removeChild(img);
          img.style.height = "88px"
          // console.log(event)
        }
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles3 = file
          this.fileName3 = file.name




        }
      }
    }
    else {

      this.message = "File with format " + event.target.files[0].type + " not acceptable, please choose jpg";


      (<HTMLInputElement>document.getElementById('openError')).click();
    }
  }

  getValidation() {
    return this.addTicektForm.valid && this.addTicektForm.value.subject.trim() != ""
  }

  openQr() {
    let idx = this.assetList.findIndex(x => x.assetId == this.addTicektForm.value.assetId)
    this.storedData.qrData = { name: this.assetList[idx].assetName, image: this.assetList[idx].QRCode, customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }



  assignPriority(id) {
    this.addTicektForm.patchValue({
      priorityId: id
    })
  }

  priorityList = [{ id: 1, name: 'High' }, { id: 2, name: 'Medium' }, { id: 3, name: 'Low' }]


  fileChangeWarranty(event) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFiles = file
        this.fileName = file.name


      }
    }
    else {

      this.message = "File with format " + event.target.files[0].type + " not acceptable, please choose jpg/video";


      (<HTMLInputElement>document.getElementById('openError')).click();
    }

  }





  goToLink(link) {

    const fileURL = URL.createObjectURL(link);
    // console.log(fileURL)
    window.open(fileURL, "_blank");
  }


  removeFile1() {

    if (document.getElementById('firstId')) {
      document.getElementById('firstId').remove();
    }
    this.uploadedFiles = undefined
    this.fileName = undefined
  }

  removeFile2() {

    if (document.getElementById('firstId2')) {
      document.getElementById('firstId2').remove();
    }
    this.uploadedFiles2 = undefined
    this.fileName2 = undefined
  }
  removeFile3() {

    if (document.getElementById('firstId3')) {
      document.getElementById('firstId3').remove();
    }
    this.uploadedFiles3 = undefined
    this.fileName3 = undefined
  }

  removeVedio() {
    this.vedioUploadFile = undefined
    this.vedioUploadFileName = undefined
  }
}




