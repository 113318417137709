import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-tickets-listing',
  templateUrl: './tickets-listing.component.html',
  styleUrls: ['./tickets-listing.component.css']
})
export class TicketsListingComponent implements OnInit {
  assetId: string;

  constructor(public _location: Location,public route: Router,public router:Router ,public apiService:ApiService,) { }
  paginationArray=[1,2,3]
  childAssetList=[]

  // changePage
  pageSize=10
  closedTicket=[]
  openTicket=[]
  parkedTicket=[]
  tabSelected:any= 'openTicket'
  selectedFiler={subject:null , ticketId:null ,  dueDate:null,priority:null, assignto:null  }
  priorityFilter = false;
  createTicketPermission=false
  notifyAddPermission=false
  pmPermission=false;
  partsFeaturePermission = false;
  partsPermission = true;
  maintenanceFeaturePermission = false;
  ngOnInit(): void {
    this.assetId = this.router.url.split("/")[2]
    this.getOpenList()
    this.getClosedTicekt()
    this.getParkedTicekt()
    this.getnotValidTicket()
    // this.getAssetDetail()
    this.getOtherDetails()

   this.getAssetDetails()

   this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
   
    this.statusList = result['statusList']
    // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
    // this.statusList.splice(idx,1)
    // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
    //   this.statusList.splice(idx1,1)
    //   idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
    //   this.statusList.splice(idx1,1)
  })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Tickets" && permission[i]['action'] == "Raised Tickets" && permission[i]['actionStatus'] == 1){
        this.createTicketPermission=true
      }
      if(permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.notifyAddPermission=true
      }
      if(permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.pmPermission=true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true;
      this.maintenanceFeaturePermission = true;
    }

  }

  // getFilterStatus(){
  //   // console.log(JSON.stringify(this.filterSelected))
  //   let length =JSON.stringify(this.filterSelected).split(null).length - 1

  //   if(length == 2 || !this.applyFlag) return "(No Filter Selected)"
  //   else if(this.applyFlag) return `(${2-length} Filter Selected)`
  // }
  applyFlag=false;

  // filterSelected={titel:null , ticketId:null}

  // applyFilter(){
  //   this.applyFlag= true;
  //    let body = {
  //     subject:this.filterSelected.titel,
  //     ticketId:this.filterSelected.ticketId
      
  //    }
  //   this.apiService.startLoader()
	// 	this.apiService.postCall('tickets/assetTicket/'+this.assetId,{},body  ).subscribe((result: any[])=>{
  //     // console.log(result);
  //     this.apiService.stopLoader()
  //     this.closedTicket= result['closedTicke'];
  //     this.openTicket= result['openTicket'];
  //     this.parkedTicket= result['parkedTicke'];
      

  //     // this.setPaginationArray(result)
  //   })
  // }

  getFilterStatus(){
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length=0;
    if(this.selectedFiler.ticketId != null) length = length+1
    if(this.selectedFiler.subject!= null) length = length+1
    if(this.selectedFiler.dueDate != null) length = length+1
    if(this.selectedFiler.priority != null) length = length+1
    if(this.selectedFiler.assignto != null) length = length+1
    
    // for(let d of this.selectedFiler)
    // // console.log(length)
    if(length == 0 || !this.applyFlag) return "(None Selected)"
    else if(this.applyFlag) return `(${length} Filter Selected)`
  }

  activePage=1

  sortBy='tickedId'
  sortType='desc'
  getOpenList(){
    let body={
        
      pageNo:this.activePage, limit:this.pageSize,
      businessId:this.apiService.buisnessId,
      assetManagementId:this.assetId,
      subject:this.selectedFiler.subject , 
      ticketId:this.selectedFiler.ticketId  , 
       dueDate:this.selectedFiler.dueDate ,
       sortType: this.sortType,
       priorityId:this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
       assignedTo:this.selectedFiler.assignto ? this.selectedFiler.assignto.id : null,
       sortBy: this.sortBy,
       "roleId":this.apiService.roleId,
       "userId":this.apiService.userId,
            }
              
      
              this.apiService.startLoader()
              this.apiService.postCall('tickets/openTickets',{} ,body ).subscribe((result: any[])=>{
                // console.log(result);
                this.apiService.stopLoader()
                this.openTicket=result["data"]
                
          
                // result=10
                this.setPaginationArray(result)
                // console.log("Result is "+ result)
              })
  }


  propertyList:any;
  departmentList:any;
  userList:any;




  getOtherDetails(){
    // this.apiService.postCall('asset-managements/index/'+this.apiService.buisnessId,{} , {pageNo:1, limit:200} ).subscribe((result: any[])=>{
    //   // console.log(result);
      
    //   // this.assetList=[]
    //   this.assetList= result['data']['data'];
    //   // console.log(this.assetList)
    //   this.statusList = result["statusList"];
  
    // })
   
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj ).subscribe((result: any[])=>{
      this.propertyList = result['data']
      // console.log(result)
      this.departmentList = result['departments']
    })
   
    
    let obj1 ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.apiService.outletId,
    }
    this.apiService.postCall('users/user-list/'+this.apiService.buisnessId+'/'+this.apiService.outletId,{} , obj1  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      
    // // console.log(jsonToBeUsed)
   this.userList = result['data']
  },
  error =>{
  this.apiService.stopLoader()
  // console.log(error)
  });
  }

  getOutletName(id){
    let idx =this.propertyList.findIndex(x=> x.id == id)
    if(idx != -1) return this.propertyList[idx].outletName
  }

  getPriorityName(id){
    let idx = this.priorityList.findIndex(x=>x.id == id)

    if(idx != -1) return this.priorityList[idx].name
    else 'Low'
  }

  priorityList=[{id:1 , name:'High'} , {id:2 , name:'Medium'} , {id:3 , name:'Low'}] 
  assigntoList=[]
  assigntoFilter=false
  getClosedTicekt(){

    // console.log("its a call")
    // this.authService.loader= true;
  
    let body={
        
      pageNo:this.activePageClosed, limit:this.pageSizeClosed,
      businessId:this.apiService.buisnessId,
      assetManagementId:this.assetId,
      subject:this.selectedFiler.subject , 
      ticketId:this.selectedFiler.ticketId  , 
       dueDate:this.selectedFiler.dueDate ,
       sortType: this.sortType,
       priorityId:this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
       assignedTo:this.selectedFiler.assignto ? this.selectedFiler.assignto.id : null,
       sortBy: this.sortBy,
       "roleId":this.apiService.roleId,
       "userId":this.apiService.userId,
            }
              
              
    this.apiService.startLoader()
    this.apiService.postCall('tickets/closeTickets',{},body  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.closedTicket=result["data"]
      

      this.setPaginationArrayClosed(result)
    })
    
  }

  getParkedTicekt(){
    let body={
        
      pageNo:this.activePageParked, limit:this.pageSizeParked,
      businessId:this.apiService.buisnessId,
      assetManagementId:this.assetId,
      subject:this.selectedFiler.subject , 
      ticketId:this.selectedFiler.ticketId  , 
       dueDate:this.selectedFiler.dueDate ,
       priorityId:this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
       assignedTo:this.selectedFiler.assignto ? this.selectedFiler.assignto.id : null,
       sortType: this.sortType,
       sortBy: this.sortBy,
       "roleId":this.apiService.roleId,
       "userId":this.apiService.userId,
            }
    this.apiService.startLoader()
    this.apiService.postCall('tickets/parkedTickets',{},body ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.parkedTicket=result["data"]
      

      this.setPaginationArrayParked(result)
    });
  }

  notValid=[]
  getnotValidTicket(){
    let body={
        
      pageNo:this.activePageParked, limit:this.pageSizeParked,
      businessId:this.apiService.buisnessId,
      assetManagementId:this.assetId,
      subject:this.selectedFiler.subject , 
      ticketId:this.selectedFiler.ticketId  , 
       dueDate:this.selectedFiler.dueDate ,
       priorityId:this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
       assignedTo:this.selectedFiler.assignto ? this.selectedFiler.assignto.id : null,
       sortType: this.sortType,
       sortBy: this.sortBy,
       "roleId":this.apiService.roleId,
              "userId":this.apiService.userId,
            }
    this.apiService.startLoader()
    this.apiService.postCall('tickets/notValidTickets',{},body).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.notValid=result["data"]
      

      this.setPaginationArraynotValid(result)
    });
  }

  paginationnotValid(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPagesnotValid && this.activePagenotValid != d) {
      // this.selectedAssets = []
      this.activePagenotValid = d
      // console.log(d)
      this.getnotValidTicket()
    }
  }
  changePagenotValid(ev) {
    // console.log(this.pageSizenotValid)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getnotValidTicket()
  }

  totalnoOfPagesnotValid
  pageSizenotValid=10
  activePagenotValid:any=1
  paginationArraynotValid=[]
  setPaginationArraynotValid(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizenotValid)
    this.totalnoOfPagesnotValid = noOfPAges;
    this.paginationArraynotValid = []
this.activePagenotValid = parseInt(this.activePagenotValid)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArraynotValid.push(i)
    else if (4 + this.activePagenotValid < this.totalnoOfPagesnotValid) {
      for (let i = this.activePagenotValid; i <= 4 + this.activePagenotValid; i++)this.paginationArraynotValid.push(i)
    }
    else if (4 + this.activePagenotValid >= 6) {

      let start = (4 + this.activePagenotValid) - noOfPAges
      for (let i = this.activePagenotValid - start; i <= noOfPAges; i++)this.paginationArraynotValid.push(i)
    }
    // console.log(this.paginationArraynotValid)
  }
  

  getPMsList(): void {

		// console.log("its a call")
		

   


           
  
  
         
  }

  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }



  totalnoOfPagesClosed
  pageSizeClosed=10
  activePageClosed=1
  paginationArrayClosed=[]
  setPaginationArrayClosed(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeClosed)
    this.totalnoOfPagesClosed = noOfPAges;
    this.paginationArrayClosed = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayClosed.push(i)
    else if (4 + this.activePageClosed < this.totalnoOfPagesClosed) {
      for (let i = this.activePageClosed; i <= 4 + this.activePageClosed; i++)this.paginationArrayClosed.push(i)
    }
    else if (4 + this.activePageClosed >= 6) {

      let start = (4 + this.activePageClosed) - noOfPAges
      for (let i = this.activePageClosed - start; i <= noOfPAges; i++)this.paginationArrayClosed.push(i)
    }
    // console.log(this.paginationArrayClosed)
  }


  totalnoOfPagesParked
  pageSizeParked=10
  activePageParked=1
  paginationArrayParked=[]
  setPaginationArrayParked(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeParked)
    this.totalnoOfPagesParked = noOfPAges;
    this.paginationArrayParked = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayParked.push(i)
    else if (4 + this.activePageParked < this.totalnoOfPagesParked) {
      for (let i = this.activePageParked; i <= 4 + this.activePageParked; i++)this.paginationArrayParked.push(i)
    }
    else if (4 + this.activePageParked >= 6) {

      let start = (4 + this.activePageParked) - noOfPAges
      for (let i = this.activePageParked - start; i <= noOfPAges; i++)this.paginationArrayParked.push(i)
    }
    // console.log(this.paginationArrayParked)
  }

  
  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets = []
      this.activePage = d
      // console.log(d)
      this.getOpenList()
    }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getOpenList()
  }


  paginationParked(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPagesParked && this.activePageParked != d) {
      // this.selectedAssets = []
      this.activePageParked = d
      // console.log(d)
      this.getParkedTicekt()
    }
  }
  changePageParked(ev) {
    // console.log(this.pageSizeParked)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getParkedTicekt()
  }


  paginationClosed(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPagesClosed && this.activePageClosed != d) {
      // this.selectedAssets = []
      this.activePageClosed = d
      // console.log(d)
      this.getClosedTicekt()
    }
  }
  changePageClosed(ev) {
    // console.log(this.pageSizeClosed)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getOpenList()
  }

  assetDetails
  getAssetDetail(){
   
    this.apiService.startLoader()
    this.apiService.getCall('parts/parts-list/15',{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
    
    })
  }

  getAssetDetails(){
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.assetDetails = result['assetDetails'];
      this.apiService.stopLoader()
    })
  }


  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFilesAsset =file
        
       
  
      }
  
      let testData:FormData = new FormData();
    
     
  // // console.log(obj)
  testData.append('image', this.uploadedFilesAsset);
   
    // status:this.assetDetailsForm.value.status,
    
  
  
  // // console.log(obj)
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
    this.getAssetDetails()
    document.getElementById('dismissPopUp').click()
    
  })
  }
  
  
  changeStatus(){
    let testData:FormData = new FormData();
    
     
    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);
     
      testData.append("status", this.assetDetails.status);
      
    
    
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetails()
      document.getElementById('dismissPopUp').click()
      
    })
  }
  statusList=[
   
   ]


   applyFilter(){
    this.applyFlag=true;
    // this.activePage= 1;
    // this.getPMsList()

    this.getParkedTicekt()
    this.getOpenList()
    this.getClosedTicekt()
    this.getnotValidTicket()
  }

  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    if(this.tabSelected == 'openTicket'){
      this.getOpenList();
    }
    else  if(this.tabSelected == 'parkedTicket'){
      this.getParkedTicekt();
    }
    else  if(this.tabSelected == 'closedTicket'){
      this.getClosedTicekt();
    }
  }

  clear(){
  this.applyFlag= false;
  this.selectedFiler={subject:null , ticketId:null ,  dueDate:null,priority:null,assignto:null  }
  this.getParkedTicekt()
    this.getOpenList()
    this.getClosedTicekt()
    this.getnotValidTicket()

}

filterSelect:boolean
@HostListener('click', ['$event.target'])
onClick(btn) {
  // console.log('button', btn.id, 'number of clicks:');
  if(btn.id != 'custom') {
    this.filterSelect= false
//     this.assetFilter= false;
// this.locationFilter= false;
// this.businessFilter= false;
// this.propertFilter= false;
    // this.selectFreq = false
  }
}

}
