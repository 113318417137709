import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { StoredData } from '../common/services/storedData.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-part-consume',
  templateUrl: './part-consume.component.html',
  styleUrls: ['./part-consume.component.css']
})
export class PartsConsumeComponent implements OnInit {
  partList = [];
  constructor(
    public apiService: ApiService,
    public _location: Location,
    public formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
  ) { }
  buisnessId = 0
  partConsumesArray
  submitButton = false;
  dropdownProperty: IDropdownSettings;
  dropdownDepartment: IDropdownSettings;
  dropdownSettingsParts: IDropdownSettings;
  ngOnInit(): void {
    this.dropdownProperty = {
      singleSelection: true,
      idField: 'id',
      textField: 'outletName',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownDepartment = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsParts = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.getOutLetList();
  }
  plusFlag = 1;
  AddMoreNew(data?) {
    this.partConsumesArray.push(this.addnewData(data));
  }
  addnewData(data: any | null) {
    return ({ outletId: null, departmentId: null, partId: null, name: null, availableQty: 0, descriptions: null, quantity: null, isAdded: null });
  }
  removeMore(i): void {
    this.partConsumesArray.splice(i, 1);
  }
  propertyList = []
  departmentList = []
  getOutLetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
      this.departmentList = result['departments'];
    })
    this.partConsumesArray = [{ outletId: null, departmentId: null, partId: null, name: null, availableQty: 0, descriptions: null, quantity: null, isAdded: null }];
  }
  propertySelected
  onItemSelectProperty(item: any, d, index) {
    d.outletId = item.id;
    this.getPartList(item.id, index);
  }
  onItemDeSelectProperty(item: any, d, index) {
    d.outletId = null;
    this.getPartList(0, index);
  }
  onItemSelectDepartment(item: any, d, index) {
    d.departmentId = item.id;
    this.getPartListWithDepartment(d.outletId, index, item.id);
  }
  onItemDeSelectDepartment(item: any, d, index) {
    d.departmentId = null;
    this.getPartListWithDepartment(d.outletId, index, 0);
  }
  getPartListWithDepartment(outletId, index, departmentId) {
    if (outletId != 0 && departmentId != 0) {
      this.apiService.startLoader()
      this.apiService.getCall('parts/partsList/' + this.apiService.buisnessId + '/' + outletId + '/' + departmentId, {}).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        this.partList[index] = []
        // console.log("vaulesss", result);
        if (result['success'] == true) {
          let outletArry = [];
          if (this.partConsumesArray[0]) {
            for (let d2 of result['data']) {
              let idx = this.partConsumesArray.findIndex(x => x.partId == d2.id)
              if (idx != -1) {

              } else {
                outletArry.push(d2);
              }
            }
            this.partList[index] = outletArry;
          } else {
            this.partList[index] = result['data'];
          }
        }
      });
    } else if (outletId != 0) {
      this.apiService.startLoader()
      this.apiService.getCall('parts/partsList/' + this.apiService.buisnessId + '/' + outletId, {}).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        this.partList[index] = []
        // console.log("vaulesss", result);
        if (result['success'] == true) {
          let outletArry = [];
          if (this.partConsumesArray[0]) {
            for (let d2 of result['data']) {
              let idx = this.partConsumesArray.findIndex(x => x.partId == d2.id)
              if (idx != -1) {

              } else {
                outletArry.push(d2);
              }
            }
            this.partList[index] = outletArry;
          } else {
            this.partList[index] = result['data'];
          }
        }
      });
    } else {
      this.partList[index] = [];
    }
  }
  onItemSelectParts(item: any, d, index) {
    //console.log(item);
    for (let x of this.partList[index]) {
      if (x.id == item.id) {
        d.availableQty = x.quentityInHand;
      }
    }
    d.partId = item.id;

  }
  onItemDeSelectParts(item: any, d, index) {
    d.partId = null;
    d.availableQty = 0;
  }
  getPartList(outletId, index): void {
    if (outletId != 0) {
      this.apiService.startLoader()
      this.apiService.getCall('parts/partsList/' + this.apiService.buisnessId + '/' + outletId, {}).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        this.partList[index] = []
        // console.log("vaulesss", result);
        if (result['success'] == true) {
          let outletArry = [];
          if (this.partConsumesArray[0]) {
            for (let d2 of result['data']) {
              let idx = this.partConsumesArray.findIndex(x => x.partId == d2.id)
              if (idx != -1) {

              } else {
                outletArry.push(d2);
              }
            }
            this.partList[index] = outletArry;
          } else {
            this.partList[index] = result['data'];
          }
        }
      });
    } else {
      this.partList[index] = [];
    }
  }
  getVlaidation() {
    if (this.partConsumesArray) {
      let flag = false;
      // let idx = JSON.stringify(this.partConsumesArray).search('"partId":null')
      for (let d of this.partConsumesArray) {
        if (d.outletId != '' && d.outletId != null && d.partId != '' && d.partId != null && d.name != '' && d.name != null && d.quantity != '' && d.quantity != null && d.isAdded != '' && d.isAdded != null) {
          flag = true;
        } else {
          flag = false;
        }
      }
      //idx != -1
      if (flag == false) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  message
  savePartConsumes() {
    let body = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      partConsumes: []
    };
    for (let d of this.partConsumesArray) {
      body.partConsumes.push({ "outletId": d.outletId, "departmentId": d.departmentId, "partId": d.partId, "name": d.name, "quantity": d.quantity, "descriptions": d.descriptions, "isAdded": d.isAdded })
    }
    //console.log(body);
    this.apiService.startLoader()
    this.apiService.postCall('part-consumes/add', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // console.log(result)
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  goBack(): void {
    this._location.back();
  }
  // getDisabledParts(d) {
  //   if (this.partConsumesArray[0]) {
  //     // console.log(d, this.userArry)
  //     let idx = this.partConsumesArray.findIndex(x => x.partId == d.id)
  //     if (idx != -1) return true
  //     else return false
  //   }
  //   else return false
  // }
  ValidateQuantity(quantity, d, index) {
    if (quantity != '' && quantity != null) {
      if (d.partId != '' && d.partId != null) {
        for (let pId of this.partList[index]) {
          if (pId.id == d.partId && d.isAdded == 1) {
            if (pId.quentityInHand >= quantity) {
              $('#quantityErrorMsg' + index).text('');
              $('#quantityErrorMsg' + index).css('display', 'none');
            } else {
              d.quantity = '';
              $('#quantityErrorMsg' + index).text('Please enter quantity. quantity is gretter than quantity in hand (' + pId.quentityInHand + ')');
              $('#quantityErrorMsg' + index).css('display', 'block');
            }
          } else if (pId.id == d.partId && d.isAdded == 2) {
            $('#quantityErrorMsg' + index).css('display', 'none');
          }
        }
      }
    }
  }

}
