import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.css']
})
export class FeedbackDetailsComponent implements OnInit {

  constructor(public _location: Location, http: HttpClient, public route: Router, public apiService: ApiService) { }

  paginationArray = [];
  activePage = 1;
  pageSize = 10;
  showAddMenu=false
  showFilter = false;
  businessIdCheck = this.apiService.buisnessId;
  message
  sortType = 'desc';
  // sortBy = 'id';

  search: any;
  selectedFiler = { buisness: null, property: null, survayName: null, type: null, status: null, email: null, userName: null }

  feedbackArray = [];
  surveyId
  ngOnInit(): void {
    this.surveyId = this.route.url.split("/")[2]
    this.getSurveyList();
  }


  clear() {
    this.selectedFiler = { buisness: null, property: null, survayName: null, type: null, status: null, email: null, userName: null }
    this.getSurveyList()
  }



  sortSelected: any = 'id';
  sortData(d) {
    this.sortSelected = d
    if (this.sortType == 'asc') this.sortType = 'desc';
    else this.sortType = 'asc'
    this.getSurveyList()
  }



  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  changePage() {
    // console.log(this.pageSize)
    this.getSurveyList();
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      // console.log(d)
      this.getSurveyList()
    }
  }

  downloadReportPDFFile(){
    this.showAddMenu= this.showAddMenu ? false : true
    const body = {
      "downloadReport": this.downloadflag
    };
    this.apiService.startLoader()
    this.apiService.postCall2('feedbacks/feedbackDetailsPdf/'+this.surveyId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if(result['url']){
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = result['url'];
      a.target = "_blank";
      a.download = "feedback-details.pdf";
      a.click();
      document.body.removeChild(a);
      }
      this.downloadflag = false
    })
  }
  feedbackDetail
  average: any;
  surveyName
  outletName
  getSurveyList() {
    const body = {
      downloadReport: this.downloadflag
    };

    this.apiService.startLoader()
    this.apiService.postCall('feedbacks/feedbackDetails/'+this.surveyId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.feedbackArray = [];
      this.feedbackArray = result['data'];
      this.average = result['averageScore'].toFixed(2);
      this.outletName = result['feedback']['outletName'];
      this.surveyName = result['feedback']['surveyName'];
      if(result['feedback']){
        this.feedbackDetail =result['feedback']
      }
      if (this.downloadflag) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = result['url'];
        a.download = "feedback.csv";
        a.click();
        document.body.removeChild(a);
        this.downloadflag = false
      }
      this.setPaginationArray(result);
    })
  }

  downloadflag = false
  downloadReport() {
    this.downloadflag = true
    this.getSurveyList();
  }

  downloadReportPDF() {
    this.downloadflag = true
    this.downloadReportPDFFile();
  }

  businessFilter
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.showFilter = false
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
    }

  }
  typeFilter = false;
  statsuFilter = false;
  closeAll() {
    this.typeFilter = false;
    this.businessFilter = false;
  }

}
