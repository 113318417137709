import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-inspection-checklist-view',
  templateUrl: './inspection-checklist-view.component.html',
  styleUrls: ['./inspection-checklist-view.component.css']
})
export class InspectionChecklistViewComponent implements OnInit {

  constructor(public router: Router, public _location: Location, public apiService: ApiService) { }


  cheklistName;
  department = null;
  property = null;
  sections = []


  checklistId
  ngOnDestroy() {
    // document.body.style.overflowY = 'scroll'; 
  }
  editPermission = false
  ngOnInit(): void {
    // document.body.style.overflow = 'hidden';
    this.checklistId = this.router.url.split("/")[2];
    this.getPropertyList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 1);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.editPermission = true
      }
    }
  }

  inspectionDetails
  getChecklistData() {
    // this.apiService.startLoader()
    this.apiService.getCall('inspections/view/' + this.checklistId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      // console.log(result);
      this.inspectionDetails = result['data'];
      this.cheklistName = this.inspectionDetails.name;
      this.property = this.inspectionDetails.outletName
      this.department = this.inspectionDetails.departmentName

      if (this.inspectionDetails.section[0]) {
        for (let d of this.inspectionDetails.section) {
          let task = []
          for (let d2 of d.item) {
            let myArray
            if (d2.statusName) {
              myArray = d2.statusName.split('/')
              // // console.log(myArray)
            }
            task.push({ parking: d2.name, part: null, isDescription: d2.description ? true : false, description: d2.description, responseType: parseInt(d2.isSignature), response: d2.isSignature == "1" ? 'Digital Signature' : d2.isSignature == "3" ? 'Text Field' : d2.isSignature == "4" ? 'Numeric' : d2.isSignature == "5" ? 'Image' : d2.isSignature == "6" ? 'Date' : d.statusName, responseSeelcted: 'Mutiple Choice', responceGroup: d2.statusId, attachments: d2.attachments, attachments_1: d2.attachments1, attachments_2: d2.attachments2, attachments_3: d2.attachments3, attachments_4: d2.attachments4, searchResponceType: null, buttons: myArray })
          }
          this.sections.push({ sectionName: d.sectionName, task: task })


        }
      }

      // console.log()
      // if(!this.sections[0])this.sections=[{sectionName: null , task :[{parking : null , part:null , isDescription:false , description:null ,responseType: null, response: null,responseSeelcted:null, responceGroup : null,searchResponceType:null }]}]


    })
  }

  propertyList = []
  departmentList = []
  getPropertyList() {
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.propertyList = result['data']
      this.departmentList = result['departments']
      this.getChecklistData()

    })
  }

  statusListresponceType
  statusListresponceTypeFilter
  getMultichoiceresponcetypeLis() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;

    })
  }

  openEditChecklist() {
    this.router.navigate(['/inspection-checklist-edit/' + this.checklistId])
  }
}
