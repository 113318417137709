import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { Location } from '@angular/common';

import * as moment from 'moment';
declare var d3: any;



@Component({
  selector: 'app-assets-details-depreciation',
  templateUrl: './assets-details-depreciation.component.html',
  styleUrls: ['./assets-details-depreciation.component.css']
})
export class AssetsDetailsDepreciationComponent implements OnInit {
  assetId: any;

  constructor(public _location: Location, public route: Router, public router: Router, public apiService: ApiService) { }
  editDepreciation = false
  pmPermission = false
  notifyAddPermission = false;
  partsFeaturePermission = false;
  partsPermission = true;
  maintenanceFeaturePermission = false;
  ngOnInit(): void {
    // this.screenWidth= window.innerWidth;
    // if (this.screenWidth <= 1000) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 550;
    // }
    // else if (this.screenWidth <= 1380) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 750;
    // }
    // else if (this.screenWidth <= 1450) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 900;
    // }
    // else if (this.screenWidth <= 1500) {
    //   this.marginLeft = 2;
    //   this.fixedIWdth = 950;
    // }
    // else if (this.screenWidth <= 1600) {
    //   this.marginLeft = 3;
    //   this.fixedIWdth = 1000;
    // }
    // else if (this.screenWidth <= 1700) {
    //   this.marginLeft = 4;
    //   this.fixedIWdth = 1050;
    // }
    // else if (this.screenWidth <= 1800) {
    //   this.marginLeft = 6;
    //   this.fixedIWdth = 1100;
    // }

    // else if (this.screenWidth <= 1920) {
    //   this.marginLeft = 8;
    //   this.fixedIWdth = 1150;
    // }

    // else if (this.screenWidth <= 2048) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1200;
    // }
    // else if (this.screenWidth <= 2100) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1300;
    // }
    // else if (this.screenWidth <= 2400) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1500;
    // }

    // else if (this.screenWidth <= 2800) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1900;
    // }

    this.assetId = this.router.url.split("/")[2]

    this.getAssetDetail()
    this.getAssetIndex()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 3);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Depreciation" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.editDepreciation = true
      }
      if (permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.notifyAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.pmPermission = true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true;
      this.maintenanceFeaturePermission = true;
    }
  }


  chartDataLine = {
    type: 'Line',
    data: [],
    chartColumns: [],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'none' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 0: { pointShape: 'circle' } },
      curveType: 'function',
      pointSize: 20,
    },
    width: 500,
    height: 400
  };

  depricationDetails
  depriciationList

  purchasePrice
  residualPrice
  usefullLife
  depriciationId: any

  validateDate() {
    // this.purchasePrice = result['data'].purchasePrice
    if (this.residualPrice && this.purchasePrice) {
      if (parseInt(this.residualPrice) > parseInt(this.purchasePrice)) return true
    }

  }
  getAssetIndex() {
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x => x.name == "DormIn Migrationant")
      // this.statusList.splice(idx, 1)

      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
      // idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      // this.statusList.splice(idx1,1)
    })
  }

  assetDetails
  getAssetDetail() {
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/' + this.assetId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];

    })

    this.apiService.startLoader()
    this.apiService.getCall('asset-depreciations/view/' + this.assetId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.depricationDetails = result;
      this.depricationDetails.depreciationRate = this.depricationDetails.depreciationRate.toFixed(2)
      this.depricationDetails.depreciationRate = this.depricationDetails.depreciationRate.toLocaleString('en-IN')
      this.purchasePrice = result['data'].purchasePrice
      this.purchasePrice = this.getComo(this.purchasePrice);
      this.residualPrice = result['data'].residualPrice
      this.residualPrice = this.residualPrice > 0 ? this.residualPrice.toLocaleString('en-IN') : ''
      this.usefullLife = result['data'].usefullLifeYear
      this.depriciationId = result['data'].id

      var jsonToBeUsed = [];

      // for (var type in result['depreciationYears']) {
      //   let item = {};
      //   item['key'] = type;
      //   item['value'] = result['depreciationYears'][type];
      //   jsonToBeUsed.push(item);


      // }
      d3.selectAll("#chart2YAxis1234 > *").remove();
      d3.selectAll("#chart21234 > *").remove();
      // console.log(jsonToBeUsed)

      this.depriciationList = result['depreciationYears']
      this.singleBarPlot()
    })


  }
  getComo(x) {
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  }
  backClicked() { }
  message

  errFlag: boolean = false;
  updateData() {
    this.errFlag = true
    if (!this.validateDate()) this.update()
  }
  update() {

    let body = {
      purchasePrice: this.purchasePrice,
      residualPrice: this.residualPrice,
      usefullLife: this.usefullLife,
      authId: this.apiService.userId,
      assetManagementId: this.assetId
    }
    // console.log(body, this.purchasePrice)

    if (this.depriciationId) {
      this.apiService.startLoader()
      this.apiService.postCall('asset-depreciations/edit/' + this.depriciationId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.errFlag = false;
        this.apiService.stopLoader()
        this.getAssetDetail()
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        document.getElementById('duplicate1').click();

      })
    }
    else {
      this.apiService.startLoader()
      this.apiService.postCall('asset-depreciations/add', {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.getAssetDetail()
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        document.getElementById('duplicate1').click();

      })
    }

  }


  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFilesAsset = file



    }

    let testData: FormData = new FormData();


    // // console.log(obj)
    testData.append('image', this.uploadedFilesAsset);

    // status:this.assetDetailsForm.value.status,



    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()

    })
  }


  changeStatus() {
    let testData: FormData = new FormData();


    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);

    testData.append("status", this.assetDetails.status);



    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()

    })
  }
  statusList = [
  ]





  singleBarPlot() {
    var data = []
    for (let i = 0; i < this.depriciationList.length; i++)data.push([this.depriciationList[i].year, this.depriciationList[i].value])

    this.chartDataLine = {
      type: 'Line',
      data: data,
      chartColumns: ['Year', 'Value'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'none' },
        hAxis: { title: '' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 0: { pointShape: 'circle' } },
        curveType: 'function',
        pointSize: 20,
      },
      width: 600,
      height: 400
    };
  }
  singleBarPlot2() {

    // console.log(this.depriciationList)

    var data = []
    // this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('YYYY-MM-DD HH:MM:SS'))

    for (let i = 0; i < this.depriciationList.length; i++)data.push({ ser2: this.depriciationList[i].value, ser1: d3.timeParse("%Y")(this.depriciationList[i].year) })
    // console.log(data)
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
      width = this.fixedIWdth / 2,
      height = 400 - margin.top - margin.bottom;

    //  var parseDate = d3.time.format("%Y-%m-%d %X");
    var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;

    data.forEach(function (d) {
      //// console.log(d.date_time)
      // d.ser1 = moment(d.ser1).format('YYYY-MM-DD HH:MM:SS');

    });

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    var svgAxis = d3.select("#chart2YAxis1234").append("svg")
      .attr("width", 60)
      .attr("height", (height + margin.top + margin.bottom + 100))
      .append("g")
      .attr("transform",
        "translate(" + 60 + "," + margin.top + ")");

    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return +d.ser2; })])
      .range([height, 0]);
    svgAxis.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");




    var svg = d3.select("#chart21234")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + 10 + "," + margin.top + ")");



    // svg.append("text")
    // .attr("class", "x label cart-title")
    // .style("fill", "#33CCB2")
    // .attr("text-anchor", "end")
    // .attr("x", width -50 )
    // .attr("y", height + 30)
    // .text("Time Chart");


    svgAxis.append("text")
      .attr("class", "y label cart-title")
      .style("fill", "#33CCB2")
      .attr("text-anchor", "end")
      .attr("x", -86)
      .attr("y", -56)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("Value");
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")

    var area = d3.area()
      .curve(d3.curveBasis)
      .x(function (d) { return x(d.ser1); })
      .y0(height)
      .y1(function (d) { return y(d.ser2); });

    ///////////////////////////////////////////////////////////
    // Initialise a X axis:
    var x = d3.scaleTime()
      .domain(d3.extent(data, function (d) { return d.ser1; }))
      .range([0, width]);

    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .attr("class", "myXaxis")
      .selectAll("text")
      .style("text-anchor", "end")

      .attr("transform", "rotate(-65)");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return +d.ser2; })])
      .range([height, 0]);
    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    /////////////////////////////////

    //////////////////////////////////////////////////////

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4.5)
      .attr("class", "lineTest")
      .attr("d", d3.line()
        .curve(d3.curveBasis)
        .x(function (d) { return x(d.ser1) })
        .y(function (d) { return y(d.ser2) })
      )


    svg.append("path")
      .data([data])
      .attr("class", "myArea")
      .attr("d", area);

    var dots = svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("fill", "#33CCB2")
      //  .attr("transform", "translate(80,0)")
      .attr("cx", function (d) { return x(d.ser1); })
      .attr("cy", function (d) { return y(d.ser2); })
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html((moment(d.ser1).format('YYYY-MM-DD')) + "<br/>" + d.ser2)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });


    //      d3.selectAll("g.myYaxis g.tick")
    //    .append("line")
    //    .attr("class", "gridline")
    //    .attr("x1", 0)
    //    .attr("y1", 0)
    //    .attr("x2", width)
    //    .attr("y2", 0);

    // d3.selectAll("g.myXaxis g.tick")
    //    .append("line")
    //    .attr("class", "gridline")
    //    .attr("x1", 0)
    //    .attr("y1", -height)
    //    .attr("x2", 0)
    //    .attr("y2", 0);



  }


  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 1000) {
      this.marginLeft = 0;
      this.fixedIWdth = 550;
    }
    else if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#chart2YAxis1234 > *").remove();
    d3.selectAll("#chart21234 > *").remove();



    this.singleBarPlot()
    // this.linecurveChart()
  }
}
