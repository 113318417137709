import { Component, OnInit, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResponseContentType } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-pm-template-add',
  templateUrl: './pm-template-add.component.html',
  styleUrls: ['./pm-template-add.component.css']
})
export class PmTemplateAddComponent implements OnInit {
  @Input() pmTemplateList: any
  @Input() frequencyList: any
  @Input() responseType: any
  @Input() statusGroup: any
  @Input() propertyList: any
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _location: Location, public apiService: ApiService, public formBuilder: FormBuilder, public router: Router, private http: HttpClient,) { }
  assetId;
  propertyId;
  scheduleFrequency;
  templateName;
  startDate;
  endDate;
  serviceTeam;
  checkList;
  assetList = []
  showDropDown = false;
  pmTemplateForm = this.formBuilder.group({
    businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    propertyId: [null, Validators.required],
    assetId: [null],
    users: [null],
    assignee: [null],
    name: [null, Validators.required],
    frequency: [null, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null],
    frequencyNumber: [null],
    frequencyUnit: [null],
    checklist: this.formBuilder.array([this.initChecklist()]),
    serviceTeam: this.formBuilder.array([this.initUser()]),
    saturdayWeekFlag: false,
    sundayWeekFlag: false,
    checklistStartTime: [null],
    checklistEndTime: [null],
    checklistScheduleUserIdNotify: [null],
    reminderTime: [null],
    isEndless: false,
    isOpenDayFlag:false,
    additionalSettingFlag: false,
    dayContinueSchedules: false,
    multipleTimesHoliday: this.formBuilder.array([]),
    remindersNotificationFlag: false,
    sendNotificationFlag: false,
    isReminder: false,
  });
  userList = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;

  frequencyListNew = ['Once', 'Daily', 'Weekly', 'Monthly', 'Custom']

  todayDate: any = new Date();
  getFrequencyName() {
    if (this.pmTemplateForm.value.frequency == null) return 'Select'
    else if (this.pmTemplateForm.value.frequency == 'Custom') return 'Every ' + (this.pmTemplateForm.value.frequencyNumber ? this.pmTemplateForm.value.frequencyNumber : '') + " " + (this.pmTemplateForm.value.frequencyUnit ? this.pmTemplateForm.value.frequencyUnit : '')
    else return this.pmTemplateForm.value.frequency
  }



  changeIndex() {

    // console.log("aaaaaa");
    (<HTMLInputElement>document.getElementById('sectionIndex')).style.zIndex = "999999";

    setTimeout(() => {
      document.body.style.overflow = 'hidden';

    }, 200);

  }
  backClicked() {

    this.updateData.emit()
    this.pmTemplateForm.reset()
    this.formChecklistArr.clear()
    this.addMoreChecklist()
    this.tabselected = 'step1';
    this.ngOnDestroy();
    (<HTMLInputElement>document.getElementById('sectionIndex')).style.zIndex = "5";

  }
  removeAm() {
    // console.log(this.scheduleAdd.value.reminderTime)
    // console.log(this.scheduleAdd.value.reminderTime.replace("am", ""))
    if (this.pmTemplateForm.value.reminderTime) {
      this.pmTemplateForm.value.reminderTime = this.pmTemplateForm.value.reminderTime.replace("am", "");
      this.pmTemplateForm.value.reminderTime = this.pmTemplateForm.value.reminderTime.replace("pm", "");
    }
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  scoreList = []
  copyTemplatePermission = false
  ngOnInit(): void {
    this.showAssigneeLimitFlag = false
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    // console.log("PM add component called");
    this.assetId = this.router.url.split("/")[2];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: false
    };


    this.getMultichoiceresponcetypeLis()
    // console.log(this.assetId);
    // this.getAssetList();
    // http://13.234.3.239/dev_nexus/img/statuses/2th7u7f.png
    if (this.assetId) this.getAssetDetails()

    // console.log(this.pmTemplateForm.value)

    for (let i = 1; i <= 20; i++)this.scoreList.push(i)

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 4);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Preventive Maintenance Template" && permission[i]['action'] == "Copy" && permission[i]['actionStatus'] == 1) {
        this.copyTemplatePermission = true
      }
    }
  }

  ngOnChanges() {
    //   let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
    //   if(idx != -1){
    //   if(this.apiService.roleId == '3')  {
    //     this.pmTemplateForm.patchValue({
    //      propertyId : this.propertyList[idx].id
    //     })
    //    //  this.selectedFiler.
    //     // this.getPropertyList()

    //   }
    //   else{

    //      this.pmTemplateForm.patchValue({
    //        propertyId : this.propertyList[idx].id
    //     })
    //   }
    // }
    //   setTimeout(() => {
    //     document.body.style.overflow = 'hidden';

    // }, 200);

  }


  imageToShow: any; assetMainLists
  myURL: any

  base64Image
  imagesArray = []
  imagePath: any;

  statusListresponceType = []
  statusListresponceTypeFilter = []

  onItemSelect(item: any) {
    // console.log(item);

  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  addnewStatusGroup() {
    let obj = {
      "name": this.selectedNewStaus.name,
      "value": this.selectedNewStaus.score,
      "statusImageId": this.selectedNewStaus.imageId,
      "isAlert": parseInt(this.selectedNewStaus.alert),
      "businessId": this.apiService.buisnessId,

    }

    // console.log(obj)

    this.apiService.startLoader()
    this.apiService.postCall('inspections/addNewStatus', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('dismisssNewStatusPopup')).click();
      this.apiService.getCall('inspections/groupAllStatus/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

        this.imagesArray = result['data']
        this.imagePath = result['path']

        // console.log(this.imagesArray)
        this.imagesArray.map(x => x.selected = false)
      })
    })

  }

  selectedNewStaus = { name: null, imageId: null, score: null, alert: null }

  getMultichoiceresponcetypeLis() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      let idx = jsonToBeUsed.findIndex(x => x.name == "Add a Response")
      jsonToBeUsed.splice(idx, 1)

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;

    })

    this.apiService.getCall('inspections/groupAllStatus/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

      this.imagesArray = result['data']
      this.imagePath = result['path']

      // console.log(this.imagesArray)
      this.imagesArray.map(x => x.selected = false)
    })

    this.apiService.getCall('inspections/groupAllStatusImages', {}).subscribe((result: any[]) => {

      this.allImageArray = result['data']
      this.allImageArray.map(x => x.selected = false)
      this.allImagePath = result['path']

      // console.log(this.imagesArray)
      // this.imagesArray.map(x => x.selected = false)
    })
  }

  allImageArray = []
  allImagePath: any;

  assetDetails: any
  getAssetDetails(): void {
    this.apiService.getCall('asset-managements/view/' + this.assetId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      let data = result['assetDetails'];
      this.assetDetails = result['assetDetails'];
      this.propertyId = data.propertyId;
      this.pmTemplateForm.patchValue({ propertyId: this.propertyId })
      this.getUserListoutletId(this.propertyId)
    })
  }

  getUserbyId(id) {

    return this.userList.findIndex(x => x.id == id)
  }
  selectedTemplate
  checklistStartTime1
  checklistEndTime1
  copyPmTemplate(id): void {

    // console.log(id)

    this.getUserListoutletId(id.propertyId, id)
    this.pmTemplateForm.reset()
    let reminder = new Date();
    if (id.reminderTime) {
      reminder.setHours(id.reminderTime.split(':')[0])
      reminder.setMinutes(id.reminderTime.split(':')[1])
      reminder.setSeconds(id.reminderTime.split(':')[2])
    }
    if (id.checklistStartTime) {
      this.checklistStartTime1 = new Date();
      this.checklistStartTime1.setHours(id.checklistStartTime.split(':')[0])
      this.checklistStartTime1.setMinutes(id.checklistStartTime.split(':')[1])
      this.checklistStartTime1.setSeconds(id.checklistStartTime.split(':')[2])
    }
    if (id.checklistEndTime) {
      this.checklistEndTime1 = new Date();
      this.checklistEndTime1.setHours(id.checklistEndTime.split(':')[0])
      this.checklistEndTime1.setMinutes(id.checklistEndTime.split(':')[1])
      this.checklistEndTime1.setSeconds(id.checklistEndTime.split(':')[2])
    }
    this.pmTemplateForm.patchValue({
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      assetId: id.assetId,
      propertyId: id.propertyId,
      name: id.name,
      frequency: id.scheduleFrequency,
      startDate: id.startDate,//new Date(),//id.startDate
      endDate: id.endDate,//new Date(id.endDate),
      frequencyNumber: id.frequencyNo,
      frequencyUnit: id.frequencyUnit,
      assignee: [null],
      saturdayWeekFlag: id.saturdayWeekFlag == "0" ? false : true,
          sundayWeekFlag: id.sundayWeekFlag == "0" ? false : true,
          additionalSettingFlag: id.additionalSettingFlag == "0" ? false : true,
          remindersNotificationFlag: id.remindersNotificationFlag == "0" ? false : true,
          isOpenDayFlag: id.isOpenDayFlag == "0" ? false : true,
          dayContinueSchedules: id.dayContinueSchedules,
          sendNotificationFlag: id.sendNotificationFlag == "0" ? false : true,
          isReminder: id.isReminder == "0" ? false : true,
          isEndless: id.isInfinite == "0" ? false : true,
          checklistStartTime: this.checklistStartTime1,
          checklistEndTime: this.checklistEndTime1,
          checklistScheduleUserIdArry: parseInt(id.checklistScheduleUserIdNotify),
          reminderTime: reminder,

    });
    setTimeout(() => { this.pmTemplateForm.patchValue({ assignee: null }) }, 2000);


    this.getAssetList(); this.getUserList()
    this.formChecklistArr.clear()
    for (let d of id.checklists) this.addDefaultChecklist(d)

    for (let d of id.serviceTeams) {
      let idx = this.userList.findIndex(x => x.id == d.userId)
      if (idx != -1) this.userArry.push(this.userList[idx])
    }

    // console.log(this.pmTemplateForm.value)
    this.tabselected = 'step2'
    if (id.startDate <= new Date()) {
    } else {
      this.message = "Maintenance template copied successfully";
      (<HTMLInputElement>document.getElementById('openSuccess11111111New')).click();
    }
    // let copyForn = {
    //   pmTemplateId: id,
    //   authId: this.apiService.authId
    // }

    // this.apiService.startLoader()
    // this.apiService.postCall('pm-templates/copyPMTemplate', {}, copyForn).subscribe((result: any[]) => {
    //   this.apiService.stopLoader()
    //   // if(result['success'] == true){
    //   //   alert(result['msg']);
    //   // }else{
    //   //   alert(result['msg'])
    //   // }
    //   this.getPmTemplatesList()
    //   this.message = result['msg'];
    //   (<HTMLInputElement>document.getElementById('openSuccess')).click();
    // })


  }

  initChecklistDefalut(d) {
    return this.formBuilder.group({
      name: d.name,
      response: d.response,
      responseType: d.responseType,
      responceSearch: [null],
      responceGroup: d.statusGroupId,
    });
  }



  addDefaultChecklist(d): void {
    this.formChecklistArr.push(this.initChecklistDefalut(d));
  }

  getPmTemplatesList() {
    let templetBody = {
      assetId: this.assetId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/index', {}, templetBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.pmTemplateList = result['data']

    })
  }

  // saveTemplate(): void {
  //   // console.log("Selected values",this.selectedItems);
  //   let formData = this.pmTemplateForm.value;
  //   formData.propertyId = this.propertyId
  //   formData.assetManagementId = this.assetId
  //   // console.log("Form Data : ", formData);
  //   this.apiService.startLoader()
  //   this.apiService.postCall('pm-templates/add', {}, formData).subscribe((result: any[]) => {
  //     this.apiService.stopLoader()
  //     if (result['success'] == true) {
  //       // console.log("response :", result);
  //       let data = result['data'];
  //       this.scheduleFrequency = data.scheduleFrequency;
  //       this.templateName = data.name;
  //       this.startDate = data.startDate;
  //       this.endDate = data.endDae;
  //       this.checkList = data.checklists;
  //       this.serviceTeam = data.serviceTeams;
  //       this.pmTemplateForm.reset();
  //       alert(result['msg']);
  //     } else {
  //       alert(result['msg'])
  //     }
  //   })
  // }


  assetIdForm = ''
  tabselected = 'step1'
  savePM() {
    this.apiService.startLoader()
    // console.log(this.pmTemplateForm.value)
    let partsArray = []
    let checklistScheduleUserIdArryId = []
    let isMultipleTimessHoliday = []
    // console.log("selected Items",this.selectedItems);
    let teamArray = []
    for (let i of this.userArry) teamArray.push(i.id)
    for (let d of this.pmTemplateForm.value.checklist) { partsArray.push({ name: d.name, response_type: d.responseType, response: d.response, responseGroup: d.responceGroup }) }
    for (let d of this.checklistScheduleUserIdArry) checklistScheduleUserIdArryId.push(d.id)
      for (let d of this.pmTemplateForm.value.multipleTimesHoliday) { isMultipleTimessHoliday.push({ name: d.name ? moment(d.name).format('YYYY-MM-DD') : "" }) }
    let obj = {
      businessId: this.apiService.buisnessId,
      propertyId: this.pmTemplateForm.value.propertyId ? this.pmTemplateForm.value.propertyId : this.propertyId,
      assetManagementId: this.assetId ? this.assetId : this.pmTemplateForm.value.assetId,
      name: this.pmTemplateForm.value.name,
      frequency: this.pmTemplateForm.value.frequency,
      startDate: this.pmTemplateForm.value.startDate,
      endDate: this.pmTemplateForm.value.endDate,
      authId: this.apiService.userId,
      checklist: partsArray,
      serviceTeam: teamArray,
      users: this.pmTemplateForm.value.users,
      frequencyUnit: this.pmTemplateForm.value.frequencyUnit,
      frequencyNo: this.pmTemplateForm.value.frequencyNumber,

      //new work
      inFinite: this.pmTemplateForm.value.isEndless ? 1 : 0,
      additionalSettingFlag: this.pmTemplateForm.value.additionalSettingFlag ? 1 : 0,
      remindersNotificationFlag: this.pmTemplateForm.value.remindersNotificationFlag ? 1 : 0,
      isOpenDayFlag: this.pmTemplateForm.value.isOpenDayFlag ? 1 : 0,
      dayContinueSchedules:this.pmTemplateForm.value.dayContinueSchedules,
      sendNotificationFlag: this.pmTemplateForm.value.sendNotificationFlag ? 1 : 0,
      saturdayWeekFlag: this.pmTemplateForm.value.saturdayWeekFlag ? 1 : 0,
      sundayWeekFlag: this.pmTemplateForm.value.sundayWeekFlag ? 1 : 0,
      isReminder: this.pmTemplateForm.value.isReminder ? 1 : 0,
      reminderTime: this.pmTemplateForm.value.reminderTime ? moment(this.pmTemplateForm.value.reminderTime).format('HH:mm:ss') : "",
      isMultipleTimessHoliday: isMultipleTimessHoliday,
      checklistStartTime: this.pmTemplateForm.value.checklistStartTime ? moment(this.pmTemplateForm.value.checklistStartTime).format('HH:mm:ss') : "",
      checklistEndTime: this.pmTemplateForm.value.checklistEndTime ? moment(this.pmTemplateForm.value.checklistEndTime).format('HH:mm:ss') : "",
      checklistScheduleUserIdNotify: checklistScheduleUserIdArryId
    }

    // console.log(obj)
    // return false;
    this.apiService.postCall('pm-templates/add', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // this.getPmTemplatesList()
      if (result['success']) {
        this.message = 'Maintenance created successfully';
        // this.reloadCurrentRoute();
      }
      else this.message = result["msg"];
      // this.pmTemplateForm.reset();

      (<HTMLInputElement>document.getElementById('openSuccess11111111')).click();
    })
  }
  getFullName(id) {
    if (this.userList) {
      let idx = this.userList.findIndex(x => x.id == id)

      if (idx != -1) return this.userList[idx].fullName
    }
  }
  removeAmcheCklistStartTime() {
    if (this.pmTemplateForm.value.checklistStartTime) {
      this.pmTemplateForm.value.checklistStartTime = this.pmTemplateForm.value.checklistStartTime.replace("am", "");
      this.pmTemplateForm.value.checklistStartTime = this.pmTemplateForm.value.checklistStartTime.replace("pm", "");
    }
  }
  removeAmcheChecklistEndTime() {
    if (this.pmTemplateForm.value.checklistEndTime) {
      this.pmTemplateForm.value.checklistEndTime = this.pmTemplateForm.value.checklistEndTime.replace("am", "");
      this.pmTemplateForm.value.checklistEndTime = this.pmTemplateForm.value.checklistEndTime.replace("pm", "");
    }
  }
  getDisabledUser(d) {
    if (this.userArry[0]) {
      // console.log(d , this.userArry)
      let idx = this.userArry.findIndex(x => x.id == d.id)
      if (idx != -1) return true
      else return false
    }
    else return false
  }

  userArry = []
  reloadCurrentRoute() {
    // $("#myModal").modal("hide");
    // let currentUrl = this.router.url;
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //     this.router.navigate([currentUrl]);
    // });

    // this.tabselected = 'step1'
    // this.getPmTemplatesList() 
    // 



    (<HTMLInputElement>document.getElementById('closeButon')).click();
    document.body.style.overflowY = 'scroll';
    this.tabselected = 'step1'
    this.pmTemplateForm.reset();
    this.formChecklistArr.clear();
    this.addMoreChecklist()
    this.updateData.emit()
    // this.router.navigate(['/pm-list/'+this.assetId])
  }
  removeUser(i) {
    this.userArry.splice(i, 1)
    this.showAssigneeLimitFlag = false;
  }
  selectUser() {
    if (this.userArry.length < 5) {

      this.userArry.push(this.pmTemplateForm.value.assignee)
      this.pmTemplateForm.patchValue({ assignee: null })
    }
    else {
      this.pmTemplateForm.patchValue({ assignee: null })
      this.showAssigneeLimitFlag = true
    }
  }
  showAssigneeLimitFlag: boolean;

  goBack() {
    this.router.navigate(['pm-list/' + this.assetId])
  }
  message
  get formUserArr() {
    return this.pmTemplateForm.get('serviceTeam') as FormArray;
  }

  get formChecklistArr() {
    return this.pmTemplateForm.get('checklist') as FormArray;
  }


  initUser() {
    return this.formBuilder.group({
      userId: [""],
    });
  }

  initChecklist() {
    return this.formBuilder.group({
      name: [null, Validators.required],
      response: [null, Validators.required],
      responseType: [null],
      responceSearch: [null],
      responceGroup: [null],
    });
  }

  addMoreUser(): void {
    this.formUserArr.push(this.initUser());
  }

  addMoreChecklist(): void {
    this.formChecklistArr.push(this.initChecklist());
  }

  getAssetList(): void {
    let outletId = this.pmTemplateForm.value.propertyId;

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
      "outletId": outletId,
      "statusNotIn": "5,8",
    }
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.assetList = []
      // console.log("vaulesss", result);

      this.assetList = result['data'];

    })
  }

  getUserList(): void {
    let outletId = this.pmTemplateForm.value.propertyId;
    // console.log("Outlet : ",outletId);
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": outletId,
      "onlyUsers": 1
    }

    this.userArry = []
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // this.assetList = []
      // console.log("vaulesss", result);

      this.userList = result['data'];
      this.userListNotify = result['data'];
      if (result['data'].assignTo) {
        const myArray = result['data'].assignTo.split(",");
        // console.log(myArray)
        for (let d of myArray) {
          let idx = this.userList.findIndex(x => x.id == d)
          if (idx != -1) this.userArry.push(this.userList[idx])
        }
      }
      if (result['data'].checklistScheduleUserIdNotify) {
        const myArray1 = result['data'].checklistScheduleUserIdNotify.split(",");
        for (let d of myArray1) {
          let idx = this.userListNotify.findIndex(x => x.id == d)
          if (idx != -1) this.checklistScheduleUserIdArry.push(this.userListNotify[idx])
        }
      }

    })
  }
  userListNotify
  checklistScheduleUserIdArry = []
  selectUserNotify() {
    if (this.checklistScheduleUserIdArry.length < 32) {
      this.checklistScheduleUserIdArry.push(this.pmTemplateForm.value.checklistScheduleUserIdNotify)
      this.pmTemplateForm.patchValue({ checklistScheduleUserIdNotify: null })
    } else {
      this.pmTemplateForm.patchValue({ checklistScheduleUserIdNotify: null })
    }
  }
  getUserNames(flag) {
    var str = "";
    for (var i = 0; i < this.userArry.length; i++) {
      if (i == 0) {
        str = this.userArry[i].fullName;
      } else {
        str = str + ", " + this.userArry[i].fullName;
      }
    }
    if(flag==1){
      str =str.length <= 15 ? str : str.substring(0, 10)+'...' 
      return str;
    }
    return str;
  }
  getUserNamesNotify(flag) {
    var str = "";
    for (var i = 0; i < this.checklistScheduleUserIdArry.length; i++) {
      if (i == 0) {
        str = this.checklistScheduleUserIdArry[i].fullName;
      } else {
        str = str + ", " + this.checklistScheduleUserIdArry[i].fullName;
      }
    }
    if(flag==1){
      str =str.length <= 15 ? str : str.substring(0, 10)+'...' 
      return str;
    }
    return str;
  }
  getMultipleTimesHoliday(flag){
    var str = "";
    for (var i = 0; i < this.pmTemplateForm.value.multipleTimesHoliday.length; i++) {
      if(i==0){
        str = moment(this.pmTemplateForm.value.multipleTimesHoliday[i].name).format('DD-MM-YYYY');
      }else{
        str = str+", "+ moment(this.pmTemplateForm.value.multipleTimesHoliday[i].name).format('DD-MM-YYYY');
      }
    }
    if(flag==1){
      str =str.length <= 15 ? str : str.substring(0, 10)+'...' 
      return str;
    }
    return str;
  }
  get formChecklistArrHoliday() {
    return this.pmTemplateForm.get('multipleTimesHoliday') as FormArray;
  }
  removeChecklistsHoliday = []
  removeChecklistHoliday(i) {
    // console.log(this.formChecklistArr)
    if (this.formChecklistArrHoliday.at(i).get('id')) {
      this.removeChecklistsHoliday.push(this.formChecklistArrHoliday.at(i).get('id')['value']);
    }
    //if(i != 0)
    this.formChecklistArrHoliday.removeAt(i)
  }
  initChecklistHoliday(data: any | null) {
    if (data != undefined) {
      // console.log(data.name);
      return this.formBuilder.group({
        name: [data['name'], Validators.required]
      });
    } else {
      return this.formBuilder.group({
        checklistId: [null],
        name: [null, Validators.required]
      });
    }
  }
  initChecklistDefalutHoliday(d) {
    if (d.name) {
      let scheduleTimeHoliday = new Date(d.name);
      return this.formBuilder.group({
        name: scheduleTimeHoliday
      });
    }
  }
  addDefaultChecklistHoliday(d): void {
    this.formChecklistArrHoliday.push(this.initChecklistDefalutHoliday(d));
    console.log(this.formChecklistArrHoliday);
  }
  addMoreChecklistHoliday(data?): void {
    this.formChecklistArrHoliday.push(this.initChecklistHoliday(data));
  }
  getDisabledUserNotify(d) {
    if (this.checklistScheduleUserIdArry[0]) {
      let idx = this.checklistScheduleUserIdArry.findIndex(x => x.id == d.id)
      if (idx != -1) return true
      else return false
    }
    else return false
  }
  removeUserNotifyUser(i) {
    this.checklistScheduleUserIdArry.splice(i, 1)
  }
  getUserListoutletId(outletId, data?): void {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": outletId,
      "onlyUsers": 1
    }
    // let outletId = event.target.value.id;
    // console.log("Outlet : ",outletId);
    // this.apiService.startLoader()
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + outletId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.stopLoader()
      // this.assetList = []
      // console.log("vaulesss", result);

      this.userList = result['data'];
      this.userArry = []
      if (data) {
        for (let d of data.serviceTeams) {
          let idx = this.userList.findIndex(x => x.id == d.userId)
          if (idx != -1) this.userArry.push(this.userList[idx])
        }
      }


    })
    this.getAssetList2(outletId);
  }
  getAssetList2(outletId) {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": outletId,
      "statusNotIn": "5,8",
    }
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data'];
    })
  }

  responceType
  responcetypePopup
  openResponceTypePopup(name) {
    // console.log(this.responcetypePopup, name)

    if (this.responcetypePopup == name) {
      this.responceType = null;
      this.responcetypePopup = null;
    }
    else this.responcetypePopup = name

  }
  getFreqtext(d) {
    // // console.log(d)
    if (d) {
      if (d.scheduleFrequency != "Custom") return d.scheduleFrequency
      else return d.frequencyNo + " " + d.frequencyUnit
    }
  }

  createFreshTemplate() {
    this.tabselected = 'step2';
    this.userArry = []
    this.pmTemplateForm.reset()
    this.formChecklistArr.clear()
    this.addMoreChecklist()
  }
  openResponceType(name) {
    // console.log(this.responceType, name)
    if (this.responceType == name) this.responceType = null
    else this.responceType = name
    // var modal = document.getElementById(name);

    // if(modal.style.display == 'none')modal.style.display = "block";
    // else modal.style.display = "none";
  }
  selectResponceTypr(i, data) {
    // console.log(i,data)
    this.formChecklistArr.at(i).patchValue({
      responseType: 1,
      response: data.name,
      responceGroup: data.id

    })

    // console.log(this.pmTemplateForm.value)
  }
  selectResponse(data, i) {
    // console.log("Data :",data);
    let dataText = '';
    dataText = data;
    if (data == 'Text Field') {
      data = 2;
    } else if (data == 'Digital Signature') {
      data = 3;
    } else if (data == 'Numeric') {
      data = 4;
    } else if (data == 'Image') {
      data = 5;
    } else if (data == 'Date') {
      data = 6;
    }
    this.formChecklistArr.at(i).patchValue({
      // responseType:data.id,
      //responseType: data == 'Text Field' ? 2:3,
      //response:data
      responseType: data,
      response: dataText

    })

  }
  newGroupName: any;
  newGroupIcon: any = [];

  getSelected() {
    let statusImages = []
    for (let d of this.imagesArray) { if (d.selected) statusImages.push(d.id) }

    if (statusImages[0]) return true
    else false;
  }

  addnewStatus() {
    // console.log(this.imagesArray, this.newGroupName)
    let statusImages = []
    for (let d of this.imagesArray) if (d.selected) statusImages.push(d.id)
    let obj = {
      name: this.newGroupName,
      businessId: this.apiService.buisnessId,
      status: statusImages

    }
    this.apiService.startLoader()
    this.apiService.postCall('inspections/addNewGroup', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.message = result["msg"];

      (<HTMLInputElement>document.getElementById('openSuccess11111111New')).click();
      (<HTMLInputElement>document.getElementById('cancelSTatusPopup')).click();
      this.getMultichoiceresponcetypeListLast()
    })

  }
  selectReponceSectionIDId
  selectReponceTaskIDId
  getMultichoiceresponcetypeListLast() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      let idx = jsonToBeUsed.findIndex(x => x.name == "Add a Response")
      jsonToBeUsed.splice(idx, 1)

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;
      // console.log(this.statusListresponceType.length)
      this.selectResponceTypr(this.selectReponceSectionIDId, this.selectReponceTaskIDId)

    })
  }


  selectFreq = false;
  selectCustom = false;
  unitOfMeasure = false;


  selectUnit = false;
  selectFreqdata(data) {
    this.selectFreq = !this.selectFreq
    this.pmTemplateForm.patchValue({ frequency: data })
  }
  selectCustomData(data) {
    this.pmTemplateForm.patchValue({ frequency: data })
  }


  selectFreqUnitdata(data) {
    this.unitOfMeasure = !this.unitOfMeasure
    this.pmTemplateForm.patchValue({ frequencyUnit: data })
  }


  // getFrequencyName() {
  //   if (this.pmTemplateForm.value.frequency == null) return 'Set a frequency'
  //   else if (this.pmTemplateForm.value.frequency == 'Custom') return 'Every  '+ (this.pmTemplateForm.value.frequencyNumber ? this.pmTemplateForm.value.frequencyNumber : '') + " " + (this.pmTemplateForm.value.frequencyUnit ? this.pmTemplateForm.value.frequencyUnit : '')
  //   else return this.pmTemplateForm.value.frequency
  // }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.selectUnit = false
      this.selectFreq = false
      this.responceType = null
      this.responcetypePopup = null
    }
  }

  filterLocation(ev, i) {
    // console.log(ev)

    this.statusListresponceType = this.statusListresponceTypeFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.formChecklistArr.at(i).value.responceSearch.toLowerCase())
      }
    }
    )

  }



  getAssetName(id) {
    if (this.assetList) {
      let idx = this.assetList.findIndex(x => x.id == id)

      if (idx != -1) return this.assetList[idx].assetName
    }
  }

  getPropertyName(id) {
    let idx = this.propertyList.findIndex(x => x.id == id)

    if (idx != -1) return this.propertyList[idx].outletName
  }

  removeChecklist(i) {
    // console.log(i)
    this.formChecklistArr.removeAt(i)
  }

  getValidation() {
    if (this.userArry.length == 0) {
      return false;
    }
    if (this.assetId) this.pmTemplateForm.patchValue({ propertyId: this.propertyId })
    // if(!this.assetId && !this.pmTemplateForm.value.assetId){
    // return false;
    // }
    return this.pmTemplateForm.valid && !this.validateDate(this.pmTemplateForm.value.startDate, this.pmTemplateForm.value.endDate)
  }


  validateDate(satrtDate, endDate) {
    if(this.pmTemplateForm.value.isEndless == true){
      if (satrtDate) {
        return false
      }else{
        return true
      }
    }else{
      if (satrtDate && endDate) {
        if (endDate < satrtDate) return true
        else return false
      }
    }
  }
}
