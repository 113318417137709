import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
import html2canvas from 'html2canvas';
// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;



@Component({
  selector: 'app-dashboard-survey-graph',
  templateUrl: './dashboard-survey-graph.component.html',
  styleUrls: ['./dashboard-survey-graph.component.css']
})
export class DashboardSurveyGraphComponent implements OnInit {
  selectedFiler = { department: null, name: null, property: null, startDate: null, endDate: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.showFilter = false
      this.propertyFiletr = false;
      // this.statsuFilter = false;
      // this.typeFilter = false;
      // // this.showAddMenu = false;
      // this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.typeFilter = false;
      // this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }
  closeAll() {
    this.propertyFiletr = false;
    this.departmentFilter = false;
    // this.typeFilter = false;
    // this.businessFilter = false;
  }

  departmentList = [{ id: 2, name: 'Single-Question' }, { id: 1, name: 'Multi-Question' }]
  getDepartmentList() {
    this.departmentList = [{ id: 2, name: 'Single-Question' }, { id: 1, name: 'Multi-Question' }]
    // this.apiService.getCall('asset-managements/index/'+this.apiService.buisnessId, {}).subscribe((result: any[]) => {
    //   // console.log("Response Listing :",result);
    //   if(result['success'] == true){
    //     this.departmentList = result['data']['data'];
    //     this.filterdepartmentList = result['data']; 
    //   }
    // });
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.getAsset()
    // this.simpleBarchart()
    this.getsurveyEntiroesWithMostNegative()
    this.gettop5SurveyEntries()
    this.getDepartmentList()
  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { department: null, name: null, property: null, startDate: null, endDate: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }


    this.ngOnInit()
  }
  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }

    })
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  // propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }



  SurveyName = ''
  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }
  businessFeatureAttendance = false
  // ratingValue = [
  //   ['Period', 'Rating 1', 'Rating 2', 'Rating 3', 'Rating 4',
  //     'Rating 5', { role: 'annotation' }],
  //   ['2010', 10, 24, 20, 32, 18],
  //   ['2020', 16, 22, 23, 30, 16],
  //   ['2030', 28, 19, 29, 30, 12]
  // ];
  ngOnInit(): void {
    var body = {}
    body = localStorage.getItem('body');
    var id = localStorage.getItem('id');
    this.SurveyName = localStorage.getItem('SurveyName');
    this.view(id, body);
  }

  surveyDashboard
  getAsset() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()
    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),

    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/feedbackDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.surveyDashboard = result;

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }
  /*getAddition(rate1, rate2, rate3, rate4, rate5) {
    let total = 0;
    total = total + parseInt(rate1);
    total = total + parseInt(rate2);
    total = total + parseInt(rate3);
    total = total + parseInt(rate4);
    total = total + parseInt(rate5);
    return total;
  } */

  top5SurveyEntries = []
  gettop5SurveyEntries() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()

    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),

    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/top5SurveyEntries', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.top5SurveyEntries = result['top5SurveyEntries'];

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }

  backClicked() {
    this.route.navigate(['/dashboard-survey'])
  }
  negativeSurveyEntries = []

  getsurveyEntiroesWithMostNegative() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()

    this.apiService.startLoader()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      type: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      "businessId": this.apiService.buisnessId,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),

    }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/negativeSurveyEntries', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.negativeSurveyEntries = result['negativeSurveyEntries'];

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }

  openListnegativeSurveyEntries(id) {
    this.storedData.dashBoardIndex = { headers: ['Question', 'Survey Type', 'Average Score'], api: 'Feedbacks/negativeSurveyEntries', tableName: 'Bottom-5 Surveys', dataSource: ['survey_name', 'question', 'average'], apiObj: 'negativeSurveyEntries', rowCountParam: 'count' }
    this.route.navigate(['/dashboard-index/' + id])

  }

  openListtop5SurveyEntries(id) {
    this.storedData.dashBoardIndex = { headers: ['Question', 'Survey Type', 'Average Score'], api: 'Feedbacks/top5SurveyEntries', tableName: 'Top 5 Surveys', dataSource: ['survey_name', 'question', 'average'], apiObj: 'top5SurveyEntries', rowCountParam: 'count' }
    this.route.navigate(['/dashboard-index/' + id])

  }
  downloadReportButton() {
    this.loadMoreRating(1);
  }
  chart1
  chart2
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.exportAsPDF1();
  }
  exportAsPDF1() {
    var HTML_Width = $("#divIdCharts1").width();
    var HTML_Height = $("#divIdCharts1").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts1")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage1('img2', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage1(imageName: any, url: any) {
    this.chart2 = url;
    this.downloadReport();
  }
  downloadReport() {
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      businessId: this.apiService.buisnessId,
      "chart1": this.chart1,
      "chart2": this.chart2,
      "downloadData": this.dn,
      "feedbackSummeryLoadMore": this.feedbackSummeryLoadMore,
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('Feedbacks/dashboardSurveyGraphDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }

  dn = []
  feedbackSummery = []
  feedbackSummeryLoadMore = []
  rating = []
  outletName
  surveyName
  fromDate
  endDate
  businessName
  meanScore
  view(d, body) {
    body = JSON.parse(body);
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()
    // this.apiService.startLoader()
    // let body2 = {
    //   outletId: body.outletId,
    //   type: body.type,
    //   startDate: body.startDate,
    //   endDate: body.endDate,
    //   "roleId": this.apiService.roleId,
    //   "userId": this.apiService.userId,
    //   "businessId": this.apiService.buisnessId,
    //   "flag" :flag
    // }
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/getNewReportView/' + d, {}, body).subscribe((result: any[]) => {
      // console.log(result);

      this.ticketDisplayFlag = true
      this.displayFlag2 = true
      if (this.staticDashboardFlag == true) {
        this.dn = [{
          "success": true,
          "msg": "Data Found",
          "data": {
            "fromDate": result['data']['fromDate'],
            "endDate": result['data']['endDate'],
            "outletName": result['data']['outletName'],
            "businessName": result['data']['businessName'],
            "meanScore": result['data']['meanScore'],
            "surveyName": result['data']['surveyName'],
            "resultRatingQuestionLists": [
              {
                "question": "Does company provide good facilities?",
                "created": "2023-07-10 15:30:44",
                "question_group_id": "518",
                "sumQ": "63",
                "avgQ": "4.87",
                "avgQStar": 4.87,
                "rating1": 1,
                "rating2": 3,
                "rating3": 8,
                "rating4": 24,
                "rating5": 27
              }
            ],
            "ratingValue": [
              [
                "Period",
                "Rating 1",
                "Rating 2",
                "Rating 3",
                "Rating 4",
                "Rating 5"
              ],
              [
                "Jul 2023",
                1,
                3,
                8,
                24,
                27
              ]
            ],
            "feedbackSummery": [
              {
                "date": "Jul 2023",
                "avg": 5,
                "count": "6",
                "rating": {
                  "date": "Jul 2023",
                  "rating1": 1,
                  "rating2": 3,
                  "rating3": 8,
                  "rating4": 24,
                  "rating5": 27
                },
                "ratingCount": 63
              }
            ],
            "feedbackChart": [
              [
                "date",
                "avg"
              ],
              [
                "Jul 2023",
                63
              ]
            ],
            "rating": [
              {
                "date": "Jul 2023",
                "rating1": 1,
                "rating2": 3,
                "rating3": 8,
                "rating4": 24,
                "rating5": 27
              }
            ]
          }
        }];
        this.ratingBarchart([
          [
            "Period",
            "Rating 1",
            "Rating 2",
            "Rating 3",
            "Rating 4",
            "Rating 5"
          ],
          [
            "Jul 2023",
            1,
            3,
            8,
            24,
            27
          ]
        ]);
        this.feebackBarchart([
          [
            "date",
            "avg"
          ],
          [
            "Jul 2023",
            63
          ]
        ]);
        this.feedbackSummery = [
          {
            "question": "Does company provide good facilities?",
            "created": "2023-07-10 15:30:44",
            "question_group_id": "518",
            "sumQ": "63",
            "avgQ": "4.87",
            "avgQStar": 4.87,
            "rating1": 1,
            "rating2": 3,
            "rating3": 8,
            "rating4": 24,
            "rating5": 27
          }
        ];
        this.rating = [
          {
            "date": "Jul 2023",
            "rating1": 1,
            "rating2": 3,
            "rating3": 8,
            "rating4": 24,
            "rating5": 27
          }
        ];
      } else {
        this.dn = result['data'];
        this.ratingBarchart(result['data']['ratingValue']);
        this.feebackBarchart(result['data']['feedbackChart']);
        this.feedbackSummery = result['data']['resultRatingQuestionLists'];
        this.rating = result['data']['rating'];
      }
      this.outletName = result['data']['outletName'];
      this.surveyName = result['data']['surveyName'];
      this.fromDate = result['data']['fromDate'];
      this.endDate = result['data']['endDate'];
      this.businessName= result['data']['businessName'];
      this.meanScore= result['data']['meanScore'];
      // this.feedbackLine(result['data']['line']);
      this.apiService.stopLoader();
      //(<HTMLInputElement>document.getElementById('openSuccess')).click();
    })

  }
  loadMoreRating(flag) {
    var body = {}
    body = localStorage.getItem('body');
    var id = localStorage.getItem('id');
    this.SurveyName = localStorage.getItem('SurveyName');
    this.loadMoreRatingView(id, body, flag);
  }
  loadMoreRatingView(d, body, flag) {
    body = JSON.parse(body);
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    satrtDate.setMonth(satrtDate.getMonth() - 2)
    let endDate = new Date()
    this.apiService.startLoader()
    this.apiService.postCall('Feedbacks/getNewReportViewLoadMore/' + d, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      if (flag == 1) {
        this.feedbackSummeryLoadMore = result['data']['resultRatingQuestionLists'];
        this.exportAsPDF();
      } else {
        this.ticketDisplayFlag = true
        this.displayFlag2 = true
        this.feedbackSummery = result['data']['resultRatingQuestionLists'];
        (<HTMLInputElement>document.getElementById('loadMore')).style.display = "none";
        this.apiService.stopLoader();
      }
    })
  }

  thiscketChartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['date', 'No. of Responses'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#2EB8A1', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: 'Month' },
      vAxis: { title: 'No. of Responses' },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 700,
    height: 400,
    width_units: '%'
  };
  ratingChartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['date', 'avg'],
    options: {
      responsive: true,
      //borderRadius: 15,
      // colors: ['#2EB8A1', '#EF6B6B'],
      colors: ['#880808','#dc3912','#FF4500','#008000','#006400'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      //seriesType: 'bars',
      bar: { groupWidth: '75%' },
      //isStacked: true,
    },
    width: 1300,
    height: 400,
    width_units: '%'
  };

  ticketDisplayFlag = false
  feebackBarchart(data2) {

    data2.splice(0, 1);
    this.thiscketChartData = {
      type: 'ColumnChart',
      data: data2,
      chartColumns: ['date', 'No. of Responses'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#2EB8A1'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: 'Month' },
        vAxis: { title: 'No. of Responses' },
        seriesType: 'bars',
        series: { 2: { type: 'line' } }
      },
      width: 1300,
      height: 400,
      width_units: '%'
    };
    this.ticketDisplayFlag = false;
  }
  ratingBarchart(data2) {
    data2.splice(0, 1);
    this.ratingChartData = {
      type: 'ColumnChart',
      data: data2,
      chartColumns: ['Period', 'Rating 1', 'Rating 2', 'Rating 3', 'Rating 4', 'Rating 5'],
      options: {
        responsive: true,
        //borderRadius: 15,
        colors: ['#880808','#dc3912','#FF4500','#008000','#006400'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: 'Rating' },
        //seriesType: 'bars',
        bar: { groupWidth: '75%' },
        //isStacked: true,
      },
      width: 1300,
      height: 400,
      width_units: '%'
    };
    this.ticketDisplayFlag = false;
  }

  chartDataLine = {
    type: 'Line',
    data: [],
    chartColumns: ['date', 'count'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#2EB8A1', '#EF6B6B'],
      legend: { position: 'none' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };
  displayFlag2 = false
  feedbackLine(data2) {
    // console.log(data2)
    data2.splice(0, 1)
    this.chartDataLine = {
      type: 'Line',
      data: data2,
      chartColumns: ['date', 'avg'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#2EB8A1'],
        // legend: { position: 'bottom', alignment: 'center' },
        legend: { position: 'none' },
        hAxis: { title: 'No. of Responses.' },
        vAxis: { title: '' },
        series: { 2: { type: 'line' } }
      },
      width: 500,
      height: 700
    };
    this.displayFlag2 = false

  }







  simpleBarchart() {
    var data = [{ name: "Bob", value: 33 }, { name: "Robin", value: 12 }, { name: "Anne", value: 41 }, { name: "Mark", value: 16 }, { name: "Joe", value: 59 }, { name: "Eve", value: 38 }, { name: "Karen", value: 21 }, { name: "Kirsty", value: 25 }, { name: "Chris", value: 30 }, { name: "Lisa", value: 47 }, { name: "Tom", value: 5 }, { name: "Stacy", value: 20 }, { name: "Charles", value: 13 }, { name: "Mary", value: 29 },
    { name: "1Bob", value: 33 }, { name: "1Robin", value: 12 }, { name: "1Anne", value: 41 }, { name: "1Mark", value: 16 }, { name: "1Joe", value: 59 }, { name: "1Eve", value: 38 }, { name: "1Karen", value: 21 }, { name: "1Kirsty", value: 25 }, { name: "1Chris", value: 30 }, { name: "1Lisa", value: 47 }, { name: "1Tom", value: 5 }, { name: "1Stacy", value: 20 }, { name: "1Charles", value: 13 }, { name: "1Mary", value: 29 },
    { name: "2Bob", value: 33 }, { name: "2Robin", value: 12 }, { name: "2Anne", value: 41 }, { name: "2Mark", value: 16 }, { name: "2Joe", value: 59 }, { name: "2Eve", value: 38 }, { name: "2Karen", value: 21 }, { name: "2Kirsty", value: 25 }, { name: "2Chris", value: 30 }, { name: "2Lisa", value: 47 }, { name: "2Tom", value: 5 }, { name: "2Stacy", value: 20 }, { name: "2Charles", value: 13 }, { name: "2Mary", value: 29 },




    ]
    // set the dimensions and margins of the graph
    var margin = { top: 20, right: 20, bottom: 30, left: 40 },
      width = (960 * data.length) / 14,
      height = 500 - margin.top - margin.bottom;

    // set the ranges
    var x = d3.scaleBand()
      .range([0, width])
      .padding(0.1);

    var y = d3.scaleLinear()
      .range([height, 0]);




    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    var svgAxis = d3.select("#barChartYAxis").append("svg")
      .attr("width", 50)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");
    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));



    var svg = d3.select("#barChart").append("svg")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");

    // get the data


    // format the data

    // Scale the range of the data in the domains

    x.domain(data.map(function (d) { return d.name; 1 }));
    y.domain([0, d3.max(data, function (d) { return d.value; })]);

    // append the rectangles for the bar chart
    svg.selectAll(".bar")
      .data(data)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", function (d) {

        return x(d.name) + 15;

      })
      .attr("width", '20px')
      .attr("y", function (d) { return y(d.value); })
      .attr("height", function (d) { return height - y(d.value); });

    // add the x Axis
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      .call(d3.axisBottom(x));

    // add the y Axis
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));



    d3.selectAll("g.myYaxis g.tick")
      .append("line")
      .attr("class", "gridline")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width)
      .attr("y2", 0);

    d3.selectAll("g.myXaxis g.tick")
      .append("line")
      .attr("class", "gridline")
      .attr("x1", 0)
      .attr("y1", -height)
      .attr("x2", 0)
      .attr("y2", 0);

  }

}
