import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoredData } from '../common/services/storedData.service';
import {Location} from '@angular/common';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ApiService } from '../common/services/apiCalls.service';

@Component({
  selector: 'app-asset-qr-html',
  templateUrl: './asset-qr-html.component.html',
  styleUrls: ['./asset-qr-html.component.css']
})
export class AssetQrHtmlComponent implements OnInit {

  

  constructor(public _location: Location,public storedData:StoredData,public apiService: ApiService , public route  : ActivatedRoute,) { }

  data=[]
  logoDisplayConfirmationPermission = false;
  ngOnInit(): void {
    //this.getAll();
    this.storedData.selectedAssetsDetailsList = JSON.parse(localStorage.getItem("selectedAssetsDetailsList"));
    this.data = this.chunk(this.storedData.selectedAssetsDetailsList,this.storedData.selectedAssetsDetailsList.length);
    // console.log(this.data);
    // console.log(this.storedData.selectedAssetsDetailsList)
    document.body.style.overflow = 'hidden';
    this.route.queryParams
      
      .subscribe(params => {
        // console.log(params); // { order: "popular" }
        if(!this.storedData.qrData.name) this.storedData.qrData = {name:params.name,image : params.image, customerComplaintName:"" }
        // this.order = params.order;

        // // console.log(this.order); // popular
      }
    );
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 27 && businessFeatures[i]['permission_allow'] == 1) {
          this.logoDisplayConfirmationPermission = true;
        }
      }
    }
  }
  chunk(array, chunkSize) {
    let chunkCount = Math.ceil(array.length / chunkSize);
    let chunks = new Array(chunkCount);
    for(let i = 0, j = 0, k = chunkSize; i < chunkCount; ++i) {
        chunks[i] = array.slice(j, k);
        j = k;
        k += chunkSize;
    }
    return chunks;
}
  closeBytm=true

  getAll(){

    // console.log("its a call")
    // this.authService.loader= true;

    const body = {
      assetName: null,
      assetNameDp: null,
      assetTagNo: null,
      businessId: this.apiService.buisnessId,
      limit: 31,
      make: null,
      outletId: null,
      pageNo: 1,
      roleId: 2,
      sortBy: "assetId",
      sortType: "desc",
      status: null,
      userId: this.apiService.authId,
      warrantyExpirationEnd: null,
      warrantyExpirationStart: null,

    };
  
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      this.storedData.selectedAssetsDetailsList = result['data']['data']
      
    })
  }

  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  backClicked(){
    this._location.back()
  }
 
 
  exportAsPDF(div_id) {
    this.closeBytm=false
    window.print();
    return true
    // document.getElementById("pdfButton").style.opacity="0";
    // let data = document.getElementById(div_id);
    // // console.log(data.clientHeight)
    // html2canvas(data, {
    //   width: 2480,
    //   height: data.clientHeight+100
    // }).then((canvas) => {
    //   let imgWidth = 500;
    //     let pageHeight = 480;
    //     let imgHeight =
    //       ((canvas.height * imgWidth) / 2454)*1.24;
    //     var heightLeft = imgHeight;

    //     const imgData = canvas.toDataURL("image/png");

    //     const pdf = new jspdf({
    //       orientation: "p",
    //       unit: "mm",
    //       format: [400, 480],
    //     });

    //     let position = 10;
    //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;

    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //       heightLeft -= pageHeight;
    //     }
    //     pdf.save("Result.pdf");
    //     document.getElementById("pdfButton").style.opacity="1";
    //   });

    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    // mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + this.storedData.qrData.name  + '</h1>');
    mywindow.document.write(document.getElementById(div_id).innerHTML);
    // mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();

    return true;
  }
}
