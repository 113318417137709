import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-part-transfer',
  templateUrl: './part-transfer.component.html',
  styleUrls: ['./part-transfer.component.css']
}) 
export class PartTransferComponent implements OnInit {
  partList = [];
  userList = [];
  transferType = false
  partTransferForm = this.formBuilder.group({
    businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    propertyId: [null],
    partId: [null],
    partInHand: [null],
    quantity: [null, Validators.required],
    reason: [null],
    migrateTo:[null ,Validators.required],
    transferDate: [null, Validators.required],
    returnDate: [null, Validators.required],
    isNotify: [null],
    notifyUsers: [null],
    type: 1,
    number: [null],
    status: [null]
  });
  todayDate:any = new Date();
  tabSelected = 'step1'
  constructor(
    public apiService: ApiService,
    public formBuilder: FormBuilder,
    public router: Router,
    public _location: Location,
    private route: ActivatedRoute,
    public storedData: StoredData
  ) { }

  partsId
  partTId 
  buisnessId=0

  validateDate(satrtDate , endDate){
    // console.log(satrtDate , endDate)
if(satrtDate && endDate){
   if(endDate < satrtDate) return true
   else return false
  }
 }

 validateForm(){

  if(!this.transferType && !this.partTransferForm.value.isNotify ) return this.partTransferForm.valid && !this.validateDate(this.partTransferForm.value.transferDate,this.partTransferForm.value.returnDate) ;
  else if (!this.transferType && this.partTransferForm.value.isNotify ) return this.partTransferForm.valid && !this.validateDate(this.partTransferForm.value.dot,this.partTransferForm.value.dor) && (this.userArry[0]) ;

  // return !this.transferType && this.partTransferForm.valid && !this.validateDate(this.partTransferForm.value.transferDate,this.partTransferForm.value.returnDate)
 }

  validateQuantity(){
    if(!this.partTransferForm.value.quantity){
      return true
    }
    if(this.quantity && this.quantity < this.partTransferForm.value.quantity){
      return true
    }
  }
  validateQuantity2(){
    // console.log(this.partTransferForm.value.propertyId)
    if(!this.partTransferForm.value.propertyId){
      return true
    }else{
      return false
    }
  }
  changeOutletId(){
    if(this.transferType){
      this.partTransferForm.patchValue({propertyId:this.propertyId })
    }else{
      this.partTransferForm.patchValue({propertyId:null })
    }
    
  }

  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  submitButton=false; 
  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    document.body.style.overflow = 'hidden';
    this.route.queryParams
      .subscribe(params => {
        // console.log(params); // { orderby: "price" }
        if (params.partId) {
          this.partsId = params.partId;
          if (this.partsId) {
            this.partTransferForm.patchValue({
              partId: this.partsId
            })
            this.apiService.startLoader()
            this.selectPart1(this.partsId);
            this.transferType=true
            this.apiService.stopLoader()
          }
        } else if (params.partTId) {
          this.partTId = params.partTId;
          if (this.partTId) {
            this.getTransferPart(this.partTId);
          }
        }
      }
      );

    //this.partsId = this.router.url.split("/")[2]

    this.getPartList();
    setTimeout(() => { this.partTransferForm.patchValue({ propertyId: null,isNotify:false})}, 2000);
    
  }

  omit_special_char(event){   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   // console.log(k)
   return((k > 64 && k < 91 ) || (k > 96 && k < 123) || k == 8 || k == 32 || k ==48 || (k > 48 && k <= 57)); 
  }


  openDate(id){
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  propertyList = []
  permanentTransfer = [{ partid: null, partTagId: null, partProperty: null }]
  getPropertyList() {
    // if(this.apiService.roleId==2 || this.apiService.roleId==3){
    //   this.buisnessId=this.apiService.buisnessId
    // }
    // let userId=0;
    // if(this.apiService.roleId==3){
    //   userId =this.apiService.authId
    // }
    this.apiService.startLoader()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
      propertyId:0
    }
    this.apiService.postCall('outlets/outlet-list/' +this.buisnessId, {} , obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.propertyList = this.propertyList.filter(x=> x.id != this.propertyId);


      let obj ={
        "roleId":this.apiService.roleId,
        "userId":this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "outletId":this.propertyId,
      }
      this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {} , obj).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        this.userList = result['data'];
      })
    })



  }

  selectedAllLocation = null

  getTransferPart(id): void {
    this.apiService.startLoader()
    this.transferType = true
    this.apiService.getCall('part-transfers/view/' + id, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      let data = result['data'];
      // console.log(data);
      if (data) {
        let dateString = data.transferDate;
        let momentVariable = moment(dateString, 'DD-MM-YYYY');
        let stringvalue = momentVariable.format('MM/DD/YYYY');

        let momentVariable1 = moment(data.returnDate, 'DD-MM-YYYY');
        let stringvalue1 = momentVariable1.format('MM/DD/YYYY');

        this.quantity = data.partQuantity ;//- data.quantity ;

        this.autoCount = data.autoCount;

        this.selectPart1(data.partId);
        this.partTransferForm.patchValue({
          transferDate: new Date(stringvalue),
          partId: data.partId,
          number: data.number,
          quantity: data.quantity,
          reason: data.reason,
          returnDate: new Date(stringvalue1),
          isNotify: data.isNotify,
          businessId: data.businessId,
          authId: this.apiService.authId,
          notifyUsers: null,
          type: data.type,
          status: data.status,
          propertyId:data.outletId,
        })
        for (let d of data.notifyUsers) {
          this.selectedUsers.push(d.id)
          this.userArry.push(d);
        }
        this.buisnessId =data.businessId;
        this.getPropertyList();
      }

    })
  }
  isNotify = false
  isNotifyFunction(e) {
    if (e.target.checked) {
      this.isNotify = true
    } else {
      this.isNotify = false
      this.userArry = []
      this.selectedUsers = []
    }

  }
  setUserIds() {
    this.partTransferForm.patchValue({
      notifyUsers: this.userArry,
    })
  }
  getPartList(): void {
    this.apiService.startLoader()
    this.apiService.getCall('parts/partsList/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.partList = []
      // console.log("vaulesss", result);
      if (result['success'] == true) {
        this.partList = result['data'];
      }
    })

    
   
  }

  selectedUsers = []
  userArry = []
  removeUser(i) {
    this.userArry.splice(i, 1)
    this.selectedUsers.splice(i, 1)
  }
  selectUser() {
    this.selectedUsers.push(this.partTransferForm.value.notifyUsers.id)
    this.userArry.push(this.partTransferForm.value.notifyUsers)
    this.partTransferForm.patchValue({ notifyUsers: null })
  }


  transferId
  message

  getPartName(id) {
    let idx = this.partList.findIndex(x => x.id == id)
    // console.log()
    if(this.transferType){
      if(idx !=-1)return this.partList[idx].name+' '+ (this.autoCount == 0 ? '' : '-'+this.autoCount)  
    }
    if(idx !=-1)return this.partList[idx].name+' '+ (this.autoCount == 0 ? '' : '-'+this.autoCount)+' / '+this.partList[idx].quentityInHand
  }
  getPropertyName(id) {
    let idx = this.propertyList.findIndex(x => x.id == id) 
    if(idx !=-1)return this.propertyList[idx].outletName
    
  }

  getDisabledUser(id){
    if(this.userArry[0]){
      let idx = this.userArry.findIndex(x=> x.id == id)
      if(idx != -1) return true
      else return false
      }
  }
  saveTransfer(): void {

    // console.log(this.selectedUsers)
    // console.log(this.partTransferForm.status)
    this.partTransferForm.patchValue({ notifyUsers: this.selectedUsers })
    let formData = this.partTransferForm.value;
    // console.log("data"+ formData.status)
    if (this.partTId) {
      if (!this.transferType) {
        if(this.partTransferForm.value.returnDate && this.partTransferForm.value.transferDate > this.partTransferForm.value.returnDate){
          this.partTransferForm.patchValue({returnDate : null})
          this.message = "Expected Date of Return should be greater than Date of Transfer";
          (<HTMLInputElement>document.getElementById('openSuccess1')).click();
          this.tabSelected ='step1';
          // console.log("in");
          return;
        }
      }
      if (this.propertyId == formData['propertyId']) {
        this.message = "Part can't be transferred to same property";
        (<HTMLInputElement>document.getElementById('openSuccess1')).click();
        this.tabSelected ='step1';
        return;
      }
      this.apiService.startLoader()
      this.apiService.postCall('part-transfers/add' , {}, formData).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        if (result['success'] == true) {
          this.transferId = this.partTId;
          this.message = result["msg"];
          this.submitButton=true;
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }
      })
    } else {
      if (!this.transferType) {
        formData['type'] = 1;

        if(this.partTransferForm.value.returnDate && this.partTransferForm.value.transferDate > this.partTransferForm.value.returnDate){
          this.partTransferForm.patchValue({returnDate : null})
          this.message = "Expected Date of Return should be greater than Date of Transfer";
          (<HTMLInputElement>document.getElementById('openSuccess1')).click();
          this.tabSelected ='step1';
          // console.log("in");
          return;
        }
        
        this.apiService.startLoader()
        this.apiService.postCall('part-transfers/add', {}, formData).subscribe((result: any[]) => {
          this.apiService.stopLoader()

          if (result['success'] == true) {
            this.transferId = result['partTransferId'];
            this.message = result["msg"];
            this.submitButton=true;
            (<HTMLInputElement>document.getElementById('openSuccess')).click();
          }
        })
      }
      else {

        formData['type'] = 2
        if (this.propertyId == formData['propertyId']) {
          this.message = "Part can't be transferred to same property";
          (<HTMLInputElement>document.getElementById('openSuccess1')).click();
          this.tabSelected ='step1';
          return;
        }
        if (this.checkQuantity()) {
          this.apiService.startLoader()
          this.apiService.postCall('part-transfers/add', {}, formData).subscribe((result: any[]) => {
            this.apiService.stopLoader()

            if (result['success'] == true) {
              this.transferId = result['partTransferId'];
              this.message = result["msg"];
              this.submitButton=true;
              (<HTMLInputElement>document.getElementById('openSuccess')).click();
            }
          })
        }
      }
    }

  }
  backClicked() {
    // this.router.navigateByUrl('/part-transfer-view/'+this.transferId);
    this._location.back();
  }
  quantity = 0;
  autoCount=0
  selectPart(e) {
    this.apiService.startLoader()
    this.apiService.getCall('parts/showDetails/' + e.target.value, {}).subscribe((result: any[]) => {
      this.partTransferForm.patchValue({ number: result['part']['number'] })
      this.quantity = result['part']['quentityInHand'];
      this.autoCount = result['part']['autoCount'];
      this.propertyId = result['part']['outletId'];
      this.apiService.stopLoader()
    });
  }
  propertyId
  selectPart1(e) {
    this.apiService.startLoader()
    this.apiService.getCall('parts/showDetails/' + e, {}).subscribe((result: any[]) => {
      this.partTransferForm.patchValue({
        number: result['part']['number'],
        //propertyId: result['part']['outletId']
      })

      this.propertyId = result['part']['outletId'];
      this.quantity = result['part']['quentityInHand'];
      this.autoCount = result['part']['autoCount'];
      this.partTransferForm.patchValue({businessId: result['part']['businessId'],partInHand: result['part']['quentityInHand']});
      this.apiService.stopLoader()
      this.buisnessId = result['part']['businessId'];
      this.getPropertyList()
    });
  }

  checkQuantity() {
    if (this.partTransferForm.value.quantity <= this.quantity) {
      return true;
    } else {
      this.message = "Quantity should be less than " + this.quantity;
      (<HTMLInputElement>document.getElementById('openSuccess1')).click();
      this.partTransferForm.patchValue({ quantity: null })
      this.tabSelected = 'step1'
      return false;
    }
  }
  goBack(): void {
    this._location.back();
  }


  getValidation() {
    return (this.partTransferForm.valid)
  }

}
