import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { StoredData } from '../common/services/storedData.service';
@Component({
  selector: 'app-meter-trigger-list',
  templateUrl: './meter-trigger-list.component.html',
  styleUrls: ['./meter-trigger-list.component.css']
})
export class MeterTriggerListComponent implements OnInit {

  constructor(public _location: Location,public route: Router,public router:Router ,public apiService:ApiService,public storeData : StoredData) { }

  meterId
  addPermission=false
  editPermission=false
  ngOnInit(): void {

    this.meterId = this.router.url.split("/")[2]
    
    this.getTriggerList()
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==6 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Meter Trigger" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.addPermission=true
      }
      if(permission[i]['subModuleName'] == "Meter Trigger" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
    }
  }
  metersList=[]
  


  meterDetails


  triggerList
  getTriggerList(){
    this.apiService.startLoader()
    this.apiService.getCall('meter-triggers/meterTriggerList/'+this.meterId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.meterDetails = result['meter'];
      this.triggerList = result['triggerslist'];
    
    })
  }

  sortData

  // getAssetDetail(){
  //   this.apiService.startLoader()
  //   this.apiService.getCall('meters/view/'+this.meterId,{}  ).subscribe((result: any[])=>{
  //     // console.log(result);
  //     this.apiService.stopLoader()
  //     this.meterDetails = result['data'];
  //   // this.getUserList(this.meterDetails.outletId)
  //   })
  // }
  getTrigger(d){
    // options=["above", "below" , "equal"]

    if(d.whenReading == "above") return "Reading is > "+d.value+ " "+this.meterDetails?.unitName
    else if (d.whenReading == "below") return "Reading is < "+d.value+ " "+this.meterDetails?.unitName
    else if (d.whenReading == "equal") return "Reading is = "+d.value+ " "+this.meterDetails?.unitName
  }
  
  editTrigget(d){
    this.storeData.triggerData= d;
    this.route.navigate(['/meter-trigger-edit/'+this.meterId])
  }
}
