import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from '../../../common/services/apiCalls.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-permit-work-add',
  templateUrl: './permit-work-add.component.html',
  styleUrls: ['./permit-work-add.component.css']
})
export class PermitWorkAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  userDropdownSettings?: IDropdownSettings;
  userList = [];
  businessId = 0;
  propertyList = [];
  routeId;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  selectedAssets = []
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    this.selectedAssets[0] = []
    this.selectedAssets[1] = []
    this.selectedAssets[2] = []
    this.selectedAssets[3] = []


    this.tenantForm = this.fb.group({
      businessId: [null],
      outletId: [null, Validators.required],
      type: [null, Validators.required],
      name: [null, Validators.required],
      authId: [null],
      underTaking: [null, Validators.required],
      level1Users: [null],
      level2Users: [null],
      level3Users: [null],
      level4Users: [null],
    });
    // this.userDropdownSettings = {
    //   singleSelection: false,
    //   idField: 'id',
    //   textField: 'fullName',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 5,
    //   allowSearchFilter: true
    // };
    // this.dropdownSettings = {
    //   singleSelection: true,
    //   closeDropDownOnSelection: true,
    //   idField: 'id',
    //   textField: 'outletName',
    //   allowSearchFilter: true
    // };

    this.getPropertyList();
    // if (this.apiService.outletId) {
    //   this.onItemSelect({id: this.apiService.outletId});
    // }

  }

  getName(d, flag) {
    let idx = this.userList.find(x => x.id == d)
    // console.log(idx)
    return idx?.fullName
  }

  removeAssets(d, flag) {

    let idx1 = this.selectedAssets[flag].findIndex(x => x == d)
    this.selectedAssets[flag].splice(idx1, 1)
  }
  selectAssets(flag) {
    if (flag == 0) {
      this.selectedAssets[flag].push(this.tenantForm.value.level1Users)
      this.tenantForm.patchValue({ level1Users: null })
    } else if (flag == 1) {
      this.selectedAssets[flag].push(this.tenantForm.value.level2Users)
      this.tenantForm.patchValue({ level2Users: null })
    } else if (flag == 2) {
      this.selectedAssets[flag].push(this.tenantForm.value.level3Users)
      this.tenantForm.patchValue({ level3Users: null })
    } else if (flag == 3) {
      this.selectedAssets[flag].push(this.tenantForm.value.level4Users)
      this.tenantForm.patchValue({ level4Users: null })
    }
  }
  getdiasableAsset(d, flag) {
    // let idx = this.selectedAssets[flag].findIndex(x => x == d)
    // if (idx != -1) return true
    let id1 = this.selectedAssets[0].findIndex(x => x == d)
      let id2 = this.selectedAssets[1].findIndex(x => x == d)
      let id3 = this.selectedAssets[2].findIndex(x => x == d)
      let id4 = this.selectedAssets[3].findIndex(x => x == d)
      if (id1 != -1 || id2 != -1 || id3 != -1 || id4 != -1) return true
  }

  goBack(): void {
    this._location.back();
  }


  save(): void {

    this.tenantForm.value.level1Users = []
    this.tenantForm.value.level2Users = []
    this.tenantForm.value.level3Users = []
    this.tenantForm.value.level4Users = []

    if (!this.routeId) {
      this.businessId = 0;
      if (this.apiService.roleId == 1) {
        this.businessId = this.tenantForm.value.businessId ? this.tenantForm.value.businessId : 0;
      } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
        this.businessId = this.apiService.buisnessId;
      }
      this.tenantForm.value.businessId = this.businessId;
      this.tenantForm.value.authId = this.apiService.authId;
      this.tenantForm.value.type = this.apiService.userType;
      for (let i = 0; i < this.selectedAssets[0].length; i++) {
        this.tenantForm.value.level1Users.push(this.selectedAssets[0][i]);
      }
      for (let i = 0; i < this.selectedAssets[1].length; i++) {
        this.tenantForm.value.level2Users.push(this.selectedAssets[1][i]);
      }
      for (let i = 0; i < this.selectedAssets[2].length; i++) {
        this.tenantForm.value.level3Users.push(this.selectedAssets[2][i]);
      }
      for (let i = 0; i < this.selectedAssets[3].length; i++) {
        this.tenantForm.value.level4Users.push(this.selectedAssets[3][i]);
      }
      this.tenantForm.value.outletId = this.tenantForm.value.outletId;
      let formData = this.tenantForm.value;
      this.apiService.startLoader();
      this.apiService.postCall('permits/add', {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    } else {
      this.businessId = 0;
      if (this.apiService.roleId == 1) {
        this.businessId = this.tenantForm.value.businessId ? this.tenantForm.value.businessId : 0;
      } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
        this.businessId = this.apiService.buisnessId;
      }
      this.tenantForm.value.businessId = this.apiService.buisnessId;
      this.tenantForm.value.authId = this.apiService.authId;
      this.tenantForm.value.type = this.apiService.userType;
      for (let i = 0; i < this.selectedAssets[0].length; i++) {
        this.tenantForm.value.level1Users.push(this.selectedAssets[0][i]);
      }
      for (let i = 0; i < this.selectedAssets[1].length; i++) {
        this.tenantForm.value.level2Users.push(this.selectedAssets[1][i]);
      }
      for (let i = 0; i < this.selectedAssets[2].length; i++) {
        this.tenantForm.value.level3Users.push(this.selectedAssets[2][i]);
      }
      for (let i = 0; i < this.selectedAssets[3].length; i++) {
        this.tenantForm.value.level4Users.push(this.selectedAssets[3][i]);
      }
      this.tenantForm.value.outletId = this.tenantForm.value.outletId;
      let formData = this.tenantForm.value;
      this.apiService.startLoader();
      this.apiService.postCall('permits/edit/' + this.routeId, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    }


  }

  getValidation() {
    if (this.selectedAssets[0].length == 0) {
      return false;
    }
    return this.tenantForm.valid;
  }

  permitType = []
  getPropertyList() {
    this.apiService.startLoader();
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.authId,
      'businessId': this.apiService.buisnessId
    }
    ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();

      this.permitType = result['permitsTypes'];
      this.propertyList = result['data'];
      if (this.routeId) {
        this.getByIdDetails();

      }
      // this.groups = result['groups']

      // console.log(this.propertyList);
    });
  }

  getByIdDetails() {
    this.apiService.getCall('permits/showPermitService/' + this.routeId, {}).subscribe((result: any[]) => {
      if (result['data']) {
        this.onItemSelect({ id: result['data'].outletId }, result);

        this.tenantForm.patchValue({
          outletId: result['data'].outletId,
          name: result['data'].name,
          type: result['data'].type,
          underTaking: result['data'].underTaking,
        });
        for (let i = 0; i < result['data'].level1User.length; i++) {
          this.selectedAssets[0].push(parseInt(result['data'].level1User[i]));
        }
        for (let i = 0; i < result['data'].level2User.length; i++) {
          this.selectedAssets[1].push(parseInt(result['data'].level2User[i]));
        }
        for (let i = 0; i < result['data'].level3User.length; i++) {
          this.selectedAssets[2].push(parseInt(result['data'].level3User[i]));
        }
        for (let i = 0; i < result['data'].level4User.length; i++) {
          this.selectedAssets[3].push(parseInt(result['data'].level4User[i]));
        }
        //const find = this.propertyList.find(p => p.id == result['data'].outletId);
        //result['data'].outletId = [{id: find.id, outletName: find.outletName}];
      }

    });
  }
  onItemSelect2(t) {
    this.onItemSelect({ id: t.target.value }, []);
  }
  onItemSelect(item: any, results?: any) {
    this.apiService.startLoader();
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + item.id, {}, {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.authId,
      'businessId': this.apiService.buisnessId,
      'outletId': item.id,
      'onlyUsers': 1
    }
    ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();

      this.userList = result['data'];
      if (results) {
        // result['data'].level1Users = result['data'].level1User.split(',').map(dat => {
        //   const find = this.propertyList.find(p => p.id == dat);
        //   if (dat == find.id) {
        //     return {
        //       id: find.id,
        //       outletName: find.name
        //     };
        //   }
        // });
        // console.log(results['data']);
        /* if (results['data'].level1User) {
          var level1 =  results['data'].level1Users = results['data'].level1User.split(',').map(dat => {
             const find = this.userList.find(p => p.id == dat);
             if (dat == find.id) {
               return {
                 id: find.id,
                 fullName: find.fullName
               };
             }
           });
           this.tenantForm.patchValue({level1Users: level1});
         }
         if (results['data'].level2User) {
           var level2 =  results['data'].level2Users = results['data'].level2User.split(',').map(dat => {
             const find = this.userList.find(p => p.id == dat);
             if (dat == find.id) {
               return {
                 id: find.id,
                 fullName: find.fullName
               };
             }
           });
           this.tenantForm.patchValue({level2Users: level2});
         }
         if (results['data'].level3User) {
           var level3 =  results['data'].level3Users = results['data'].level3User.split(',').map(dat => {
             const find = this.userList.find(p => p.id == dat);
             if (dat == find.id) {
               return {
                 id: find.id,
                 fullName: find.fullName
               };
             }
           });
           this.tenantForm.patchValue({level3Users: level3});
         }
         if (results['data'].level4User) {
           var level4 =  results['data'].level4Users = results['data'].level4User.split(',').map(dat => {
             const find = this.userList.find(p => p.id == dat);
             if (dat == find.id) {
               return {
                 id: find.id,
                 fullName: find.fullName
               };
             }
           });
           this.tenantForm.patchValue({level4Users: level4});
         }*/
      }
      // this.groups = result['groups']
      // console.log(this.userList);
    });
    this.apiService.stopLoader();
  }

  backClicked() {
    this.router.navigate(['/permit-setting/work-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
