import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { DatePipe } from "@angular/common";
import html2canvas from 'html2canvas';

// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;

@Component({
  selector: 'app-dashboard-attendance',
  templateUrl: './dashboard-attendance.component.html',
  styleUrls: ['./dashboard-attendance.component.css']
})
export class DashboardAttendanceComponent implements OnInit {
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;


  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterPropertySearch: any;
  filterDepartmentSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];


  selectedFiler = { startDate: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      //this.showFilter = false
      this.propertyFiletr = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      // this.showAddMenu = false;
      this.businessFilter = false;
      this.propertyZone = false;
      this.isState = false;
      this.isCluster = false;
      this.isCityForFilter = false;
      this.isDepartmentFilter = false;
      this.isPropertyFilter = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
      this.propertyZone = false;
      this.isState = false;
      this.isCluster = false;
      this.isCityForFilter = false;
      this.isDepartmentFilter = false;
      this.isPropertyFilter = false
    }

  }
  chartDataLine = {
    type: 'Line',
    data: [],
    chartColumns: ['Date', 'Present'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2'],
      legend: { position: 'none' },
      hAxis: { title: 'No. Of Employees Present' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };

  closeAll() {
    this.propertyFiletr = false;
    this.statsuFilter = false;
    this.typeFilter = false;
    this.businessFilter = false;

    this.propertyZone = false;
    this.isState = false;
    this.isCluster = false;
    this.isCityForFilter = false;
    this.isDepartmentFilter = false;
    this.isPropertyFilter = false
  }
  downloadFlag = false;
  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.ngOnInit()


  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { startDate: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
  }
  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData, public datepipe: DatePipe) { }

  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    this.apiService.startLoader();
    setTimeout(() => {
      // console.log("in");
      //this.apiService.stopLoader();
    }, 2000);
    // this.screenWidth = window.innerWidth;
    // if (this.screenWidth <= 1000) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 550;
    // }
    // else if (this.screenWidth <= 1380) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 750;
    // }
    // else if (this.screenWidth <= 1450) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 900;
    // }
    // else if (this.screenWidth <= 1500) {
    //   this.marginLeft = 2;
    //   this.fixedIWdth = 950;
    // }
    // else if (this.screenWidth <= 1600) {
    //   this.marginLeft = 3;
    //   this.fixedIWdth = 1000;
    // }
    // else if (this.screenWidth <= 1700) {
    //   this.marginLeft = 4;
    //   this.fixedIWdth = 1050;
    // }
    // else if (this.screenWidth <= 1800) {
    //   this.marginLeft = 6;
    //   this.fixedIWdth = 1100;
    // }

    // else if (this.screenWidth <= 1920) {
    //   this.marginLeft = 8;
    //   this.fixedIWdth = 1150;
    // }

    // else if (this.screenWidth <= 2048) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1200;
    // }
    // else if (this.screenWidth <= 2100) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1300;
    // }
    // else if (this.screenWidth <= 2400) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1500;
    // }

    // else if (this.screenWidth <= 2800) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1900;
    // }


    // d3.selectAll("#groupedChartYAxis > *").remove();
    // d3.selectAll("#chart > *").remove();

    this.getAttendanceData()
    this.getLocationList()
    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.ZoneListData = []
          this.filteredZoneListData = null
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }

  displayFlag = true
  assetDashboard
  warrantyExpirationList = []
  getAttendanceData() {
    d3.selectAll("#groupedChartYAxis > *").remove();
    d3.selectAll("#chart > *").remove();
    this.apiService.startLoader()
    const date = new Date();
    //let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //firstDay.setDate(date.getDate()-7)
    //let satrtDate = new Date()
    let firstDay = new Date()
    let endDate = new Date()

    let body = {
      businessId: this.apiService.buisnessId,
      subUserId: null,
      authId: this.apiService.authId,
      roleId: this.apiService.roleId,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : '',
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[0]).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[1]).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.startLoader();
    this.apiService.postCall('attendances/attendanceDashboardReport/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);

      this.assetDashboard = result;
      this.apiService.startLoader();
      this.curveChart()
    })

  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.downloadReport();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  downloadReport() {
    const date = new Date();
    //let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //firstDay.setDate(date.getDate()-7)
    //let satrtDate = new Date()
    let firstDay = new Date()
    let endDate = new Date()

    let body = {
      businessId: this.apiService.buisnessId,
      subUserId: null,
      authId: this.apiService.authId,
      roleId: this.apiService.roleId,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : '',
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[0]).format('YYYY-MM-DD') : moment(firstDay).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[1]).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      "chart1": this.chart1
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('attendances/attendanceDashboardReportDownload/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }

  gettoFixedData(data) {
    // console.log(data)
    return data.toFixed(0)
  }


  getEndDate(data) {
    let value = data.replace(" days", "")
    return parseInt(value)
  }
  curveChart() {
    this.apiService.startLoader()
    // console.log(this.assetDashboard.graph)
    this.chartDataLine = {
      type: 'Line',
      data: this.assetDashboard.graph,
      chartColumns: ['Date', 'Present'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2'],
        legend: { position: 'none' },
        hAxis: { title: 'No. Of Employees Present' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 2: { type: 'line' } }
      },
      width: 1000,
      height: 400
    };
    this.displayFlag = false
    this.apiService.startLoader()
    this.apiService.stopLoader()
  }
  curveChart2() {

    let jsonToBeUsed = []
    for (var type in this.assetDashboard.graph) {
      let item = {};
      item['Date'] = this.assetDashboard.graph[type].Date;
      item['Present'] = this.assetDashboard.graph[type].Present;
      jsonToBeUsed.push(item);


    }
    // console.log(jsonToBeUsed)
    let data = jsonToBeUsed
    // console.log(data)
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;

    //  var parseDate = d3.time.format("%Y-%m-%d %X");
    var parseDate = d3.timeFormat("%Y-%m").parse;
    var area = d3.area()
      .curve(d3.curveBasis)
      .x(function (d) { return x(d.Date); })
      .y0(height)
      .y1(function (d) { return y(d.Present); });



    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    var svg = d3.select("#chart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");



    svg.append("text")
      .attr("class", "x label")
      .attr("text-anchor", "end")
      .attr("x", width - 50)
      .attr("y", height + 30)
    // .text("Time Chart");



    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")


    var svgAxis = d3.select("#groupedChartYAxis")
      .append("svg")
      .attr("width", 70)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + 60 + "," + margin.top + ")");

    svgAxis.append("text")
      .attr("class", "y_label cart-title")
      .attr("text-anchor", "end")
      .attr("x", -86)
      .attr("y", -46)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("No. Of Employees Present");

    ///////////////////////////////////////////////////////////
    // Initialise a X axis:
    var x = d3.scaleBand()
      .domain(data.map(function (d) { return d.Date; 1 }))
      .range([0, width])
      .padding(0.1);

    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return d.Present; })])
      .range([height, 0]);


    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .attr("class", "myXaxis");


    //   // Add Y axis

    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    svgAxis.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    /////////////////////////////////

    //////////////////////////////////////////////////////

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4.5)
      .attr("transform", "translate(80,0)")
      .attr("class", "lineTest")
      .attr("d", d3.line()
        .curve(d3.curveBasis)
        .x(function (d) { return x(d.Date) })
        .y(function (d) { return y(d.Present) })
      )

    svg.append("path")
      .data([data])
      .attr("class", "myArea")
      .attr("transform", "translate(80,0)")
      .attr("d", area)
    //  .on("mouseover", function(d) {

    var dots = svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("fill", "#33CCB2")
      .attr("transform", "translate(85,0)")
      .attr("cx", function (d) { return x(d.Date); })
      .attr("cy", function (d) { return y(d.Present); })
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html((d.Date) + "<br/>" + d.Present.toLocaleString())
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

  }

  locationList: any;
  getLocationList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      citieId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.ClientsListData = []
      this.locationList = result['data']
      this.filterpropertyList = result['data']
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();
      if (this.apiService.outletId) {
        let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      }
      // console.log(this.apiService.roleId)

    })
  }

  getPropertyName(id) {
    let idx = this.locationList.findIndex(x => x.id == id)
    if (idx != -1) return this.locationList[idx].outletName
  }


  propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }



  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 1000) {
      this.marginLeft = 0;
      this.fixedIWdth = 550;
    }
    else if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#groupedChartYAxis > *").remove();
    d3.selectAll("#chart > *").remove();
    this.curveChart()
  }

}
