import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-assets-details-notification-settings',
  templateUrl: './assets-details-notification-settings.component.html',
  styleUrls: ['./assets-details-notification-settings.component.css']
}) 
export class NotificationSettingsComponent implements OnInit {

  constructor(public route: Router,public router:Router ,public apiService:ApiService,public _location: Location,) { }
  assetId
  addPermission=false
  pmPermission=false;
  partsFeaturePermission = false;
  partsPermission = true;
  maintenanceFeaturePermission = false;
  ngOnInit(): void {
    this.assetId = this.router.url.split("/")[2]

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.addPermission=true
      }
      if(permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.pmPermission=true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true
    }
    
    this.getAssetDetail()
    // this.getNotifyList()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
      "statusNotIn":"5,8",
    }

    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
     
      this.statusList = result['statusList']

      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
      // idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      // this.statusList.splice(idx1,1)
     
    })
  }

  getNotifyList(){
    this.apiService.getCall('send-asset-notifications/assetNotificationList/' + this.assetId, {}, ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      if(result['data'][0].assetNotificationId){
        let periodTyepe = result['data'][0].dMY.replace("(","")
        periodTyepe = periodTyepe.replace(")","")
        // console.log(periodTyepe)
      this.warrantExpiration=[{id:result['data'][0].assetNotificationId , period : parseInt(result['data'][0].whenToNotify) ,periodType : periodTyepe , userId:null , selectList:[] , userSelected:[] , showAdd:true}]

      this.changePeriodTYpe(this.warrantExpiration[0])
      for(let i of result['data'][0].userId){
        let idx = this.userList.findIndex(x=> x.id == parseInt(i))
        if(idx != -1) this.warrantExpiration[0].userSelected.push(this.userList[idx]) 
      }
      }

      if(result['data'][1].assetNotificationId){
        let periodTyepe = result['data'][1].dMY.replace("(","")
        periodTyepe = periodTyepe.replace(")","")
        // console.log(periodTyepe)
      this.slaamcExpiration=[{id:result['data'][1].assetNotificationId , period : parseInt(result['data'][1].whenToNotify) ,periodType : periodTyepe , userId:null , selectList:[] , userSelected:[] , showAdd:true}]

      this.changePeriodTYpe(this.slaamcExpiration[0])
      for(let i of result['data'][1].userId){
        let idx = this.userList.findIndex(x=> x.id == parseInt(i))
        if(idx != -1) this.slaamcExpiration[0].userSelected.push(this.userList[idx]) 
      }
    }

    if(result['data'][2].assetNotificationId){
      // let periodTyepe = result['data'][2].dMY.replace("(","")
      // periodTyepe = periodTyepe.replace(")","")
      // // console.log(periodTyepe)
    this.locationChange=[{id:result['data'][2].assetNotificationId , period : parseInt(result['data'][2].whenToNotify) ,periodType : "" , userId:null , selectList:[] , userSelected:[] , showAdd:true}]

    this.changePeriodTYpe(this.locationChange[0])
    for(let i of result['data'][2].userId){
      let idx = this.userList.findIndex(x=> x.id == parseInt(i))
      if(idx != -1) this.locationChange[0].userSelected.push(this.userList[idx]) 
    }
  }

  
// this.slaamcExpiration.push({id:2 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true})
// this.locationChange.push({id:3 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true})
if(result['data'][3].assetNotificationId){
  // let periodTyepe = result['data'][3].dMY.replace("(","")
  // periodTyepe = periodTyepe.replace(")","")
  // // console.log(periodTyepe)
this.pmMissed=[{id:result['data'][3].assetNotificationId , period : parseInt(result['data'][3].whenToNotify) ,periodType : "" , userId:null , selectList:[] , userSelected:[] , showAdd:true}]

this.changePeriodTYpe(this.pmMissed[0])
for(let i of result['data'][3].userId){
  let idx = this.userList.findIndex(x=> x.id == parseInt(i))
  if(idx != -1) this.pmMissed[0].userSelected.push(this.userList[idx]) 
}
}


// this.pmMissed.push({id:4 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true})
    })
     
  }

  backClicked(){
    // this.route.navigate(['/asset-details/'+this.assetId])
  }

  removeUser(i,d){
    // let idx = d.userSelected.findIndex(x=> x == d.id)
// // console.log(d)
    d.userSelected.splice(i,1)
  }


  validate() {
    let valid = false;

    for (let i of this.warrantExpiration) {
      if (i.userSelected[0]) {
        if (i.period && i.periodType && i.userSelected[0]){
          valid = true
        }else{
         return valid=false
        }
      }
    }

    for (let i of this.slaamcExpiration) {
      let users = []

      if (i.userSelected[0]) {
        if (i.period && i.periodType && i.userSelected[0]) {valid = true}else{
          return valid=false
        }
      }
    }


    for (let i of this.locationChange) {
      let users = []

      if (i.userSelected[0]) {
        if (i.userSelected[0]) valid = true
      }
    }


    for (let i of this.pmMissed) {
      let users = []
      // 
      if (i.userSelected[0]) {
        if (i.userSelected[0]) valid = true
      }

    }

    return valid;

  }

  sendNotification(){


    let userObj=[]
    for(let i of this.warrantExpiration){
      let users=[]
      for(let j of i.userSelected) users.push(j.id)
      if(i.period && i.periodType && i.userSelected[0])userObj.push({assetNotificationId:i.id , userId:users , whenToNotify:i.period +"("+i.periodType+")" , })
    }

    for(let i of this.slaamcExpiration){
      let users=[]
      for(let j of i.userSelected) users.push(j.id)
      if(i.period && i.periodType && i.userSelected[0])userObj.push({assetNotificationId:i.id , userId:users , whenToNotify:i.period +"("+i.periodType+")" , })
    }

    for(let i of this.locationChange){
      let users=[]
      for(let j of i.userSelected) users.push(j.id)
      if( i.userSelected[0])userObj.push({assetNotificationId:i.id , userId:users , whenToNotify:"" , })
    }

    for(let i of this.pmMissed){
      let users=[]
      for(let j of i.userSelected) users.push(j.id)
      if( i.userSelected[0])userObj.push({assetNotificationId:i.id , userId:users , whenToNotify:"" , })
    }
    

      // console.log("its a call")
      // this.authService.loader= true;
    
      let body={
        assetId:this.assetId,
        authId:this.apiService.userId,
        notifyUser : userObj

      }
      this.apiService.startLoader()
      this.apiService.postCall('send-asset-notifications/addAssetNotifications',{} ,body ).subscribe((result: any[])=>{
        // console.log(result);
        this.apiService.stopLoader()
        // this.logsList = result['dataWithOutFile']
    
        if(result['success']){
        this.message = result["msg"];
  
    this.ngOnInit();
   
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }
        else{
          this.message = result["msg"];
  
    
   
          (<HTMLInputElement>document.getElementById('openError')).click();
        }
        // this.setPaginationArray(result)
      })
    }
    message

    
    changePeriodTYpe(d){
      d.selectList=[]
      // console.log(d)
      if(d.periodType == 'month'){
        for(let i =1 ; i<= 12 ;i ++)d.selectList.push(i);
      }else if(d.periodType == 'days'){
        for(let i =1 ; i<= 30 ;i ++)d.selectList.push(i);
      }else if(d.periodType == 'year'){
        for(let i =1 ; i<= 30 ;i ++)d.selectList.push(i);
      }else {
        d.selectList=[];
      }
    }

userList=[]
partsList=[]
notificationTYpeList=[]
    getUserList(){
      

        // console.log("its a call")
        // this.authService.loader= true;
      
        
        this.apiService.startLoader()
        
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.assetDetails.propertyId,
    }
        this.apiService.postCall('users/user-list',{} , obj  ).subscribe((result: any[])=>{
          // console.log(result);
          
          this.apiService.stopLoader()
          var jsonToBeUsed = [];

         
        // console.log(jsonToBeUsed)
        this.userList = result['data']
        this.getNotifyList()
      
          // this.setPaginationArray(result)
        })

         
        
        this.apiService.getCall('parts/parts-list/'+1,{}  ).subscribe((result: any[])=>{
          // console.log(result);
          
          var jsonToBeUsed = [];

          for (var type in result['data']) {
            let item = {};
            item['key'] = type;
            item['value'] = result['data'][type];
            jsonToBeUsed.push(item);
      
      
        }
      
        // console.log(jsonToBeUsed)
        this.partsList = jsonToBeUsed
          
      
          // this.setPaginationArray(result)
        })
      
      
            
        this.apiService.getCall('asset-notifications/index',{}  ).subscribe((result: any[])=>{
          // console.log(result);
          
          var jsonToBeUsed = [];

          for (var type in result['data']) {
            let item = {};
            item['key'] = type;
            item['value'] = result['data'][type];
            jsonToBeUsed.push(item);
      
      
        }
      
        // console.log(jsonToBeUsed)
        this.notificationTYpeList = jsonToBeUsed
          
      
          // this.setPaginationArray(result)
        })
      }

     
      // showAdd:boolean=true;
      selectUser(d){
        if(d.userSelected.indexOf(d.userId) == -1){
          d.showAdd=false;
          d.userSelected.push(d.userId)
      
          d.userId=null;

          // console.log(d)

          setTimeout(() => {
            // (<HTMLInputElement>document.getElementById('navigattohome')).click();
            d.showAdd=true;
        }, 100);
      }else{
        d.showAdd=false;
        d.userId=null;
        setTimeout(() => {
          // (<HTMLInputElement>document.getElementById('navigattohome')).click();
          d.showAdd=true;
      }, 100);
      }
          
      }
  
      
        warrantExpiration = [{id:1 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true}]
        slaamcExpiration = [{id:2 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true}]
        locationChange = [{id:3 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true}]
        pmMissed = [{id:4 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true}]

  
        addWarrantExpiration(){
          this.warrantExpiration.push({id:1 , period : null ,periodType : null , userId:null , selectList:[] , userSelected:[] , showAdd:true})
        }

        addslaamcExpiration(){
          this.slaamcExpiration.push({id:2 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true})
        }

        addlocationChange(){
          this.locationChange.push({id:3 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true})
        }
        addpmMissed(){
          this.pmMissed.push({id:4 , period : null ,periodType : null , userId:null ,selectList:[] , userSelected:[] , showAdd:true})
        }
      
    
        assetDetails
        getAssetDetail(){
          this.apiService.startLoader()
          this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
            // console.log(result);
            this.apiService.stopLoader()
            this.assetDetails = result['assetDetails'];
          
            this.getUserList()
          })

        }
      

        
  
  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFilesAsset =file
        
       
  
      }
  
      let testData:FormData = new FormData();
    
     
  // // console.log(obj)
  testData.append('image', this.uploadedFilesAsset);
   
    // status:this.assetDetailsForm.value.status,
    
  
  
  // // console.log(obj)
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
    // console.log(result);
    this.apiService.stopLoader()
    this.getAssetDetail()
    document.getElementById('dismissPopUp').click()
    
  })
  }
  
  
  changeStatus(){
    let testData:FormData = new FormData();
    
     
    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);
     
      testData.append("status", this.assetDetails.status);
      
    
    
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()
      
    })
  }
  statusList=[
    
   ]
}
