import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-part-reports',
  templateUrl: './part-reports.component.html',
  styleUrls: ['./part-reports.component.css']
})
export class PartReportsComponent implements OnInit {

  constructor(
    public router: Router,
    public _location: Location,
    public apiService: ApiService
  ) { }

  role:any;
  assetViewPermission = true;
  assetsFeaturePermission = false;
  partsFeaturePermission = false;
  partsPermission = true;
  ngOnInit(): void {
    this.role = this.apiService.role;
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
      this.partsFeaturePermission = true
    }
  } 
  navigateTo(rout:String){
    this.router.navigate([rout])
  }
  goBack():void{
    this._location.back();
  }

}
