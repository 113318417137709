import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-insurance-add',
  templateUrl: './insurance-add.component.html',
  styleUrls: ['./insurance-add.component.css']
})
export class InsuranceAddComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  insuranceForm: FormGroup

  conditionListing = [{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]

  insuranceProvider
  startDate
  endDate
  conditionIns
  fileName
  insuranceCost
  isActive
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {


    document.body.style.overflow = 'hidden';
    //this.type = this.route.url.split("/")[2]
    // this.insuranceProvider = this.insuranceProvider,
    //   this.startDate = this.startDate,
    //   this.endDate = this.endDate,
    //   this.conditionIns = this.conditionIns,
    //   this.fileName = this.fileName,
    //   this.insuranceCost = this.insuranceCost,
    //   this.isActive = this.isActive


    this.insuranceForm = this.fb.group({
      businessId: [null, Validators.required],
      insuranceProvider: [null, Validators.required],
      startDate: [null, Validators.required],
      type: [null, Validators.required],
      endDate: [null, Validators.required],
      conditionIns: [null],
      status: [null],
      assetId: [null],
      insuranceCost: [null, Validators.required],
      outletId: [null, Validators.required],
      fileName: [null],
      insuranceAssets: [null]
    });
    if (this.apiService.roleId == 1) {
      this.getBusinessList()
    } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      this.insuranceForm.patchValue({ businessId: this.apiService.buisnessId });
      this.getPropertyList();
    }
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  initImages() {
    return this.fb.group({
      fileName: ['']
    })
  }
  selectUnit = false;
  selectIsActivedata(data) {
    this.selectUnit = !this.selectUnit
    this.insuranceForm.patchValue({ status: data })
  }


  propertyList = []
  getPropertyList() {
    var busineId = 0;
    this.selectedAssets = []
    if (this.apiService.roleId == 1) {
      busineId = this.insuranceForm.value.businessId ? this.insuranceForm.value.businessId : 0
    } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      busineId = this.apiService.buisnessId
    }
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": busineId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + busineId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.propertyList = result['data']
    })
  }

  selectedAssets = []
  submitAsset = []

  omit_special_char(event) {
    // // console.log(event.charCode)
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  selectAssets() {

    // console.log(this.insuranceForm.value.assetId);
    this.selectedAssets.push(this.insuranceForm.value.assetId)
    this.submitAsset.push(this.insuranceForm.value.assetId.id)
    // console.log(this.selectedAssets);

    this.insuranceForm.patchValue({
      assetId: null
    })
  }
  removeAssets(d) {
    let idx = this.selectedAssets.findIndex(x => x == d)
    this.selectedAssets.splice(idx, 1)

    let idx1 = this.submitAsset.findIndex(x => x == d)
    this.submitAsset.splice(idx1, 1)
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  assetList = []
  getAssetList(t) {
    this.selectedAssets = []
    var outletId = t.target.value;
    var busineId = 0;
    if (this.apiService.roleId == 1) {
      busineId = this.insuranceForm.value.businessId ? this.insuranceForm.value.businessId : 0
    } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      busineId = this.apiService.buisnessId
    }

    this.apiService.startLoader()
    this.apiService.getCall('insurancePropertiesAssets/assetList/' + busineId + '/' + outletId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = result['data']
    })
  }


  submit() {
    // console.log(this.insuranceForm.value)
    /*let imagesArray:any =[]
    for(let d of this.attchments)imagesArray.push(d.file)
    // console.log(imagesArray)
    let obj = {
      businessId: this.apiService.buisnessId,
      isActive: this.insuranceForm.value.isActive,
      insuranceProvider: this.insuranceForm.value.insuranceProvider,
      startDate: moment(this.insuranceForm.value.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.insuranceForm.value.endDate).format('YYYY-MM-DD'),
      conditionIns: this.insuranceForm.value.conditionIns,
      fileName: imagesArray[0] ? imagesArray[0] : null,
      insuranceCost: this.insuranceForm.value.insuranceCost,
      status: this.insuranceForm.value.status,
      userTo: "",
      userToName: "",
      authId: this.apiService.authId,
      type: this.type,
      outletId:this.insuranceForm.value.outletId,
      insuranceAssets:this.submitAsset
    }*/
    if (this.insuranceForm.value.startDate && this.insuranceForm.value.startDate > this.insuranceForm.value.endDate) {
      this.insuranceForm.patchValue({ endDate: null })
      this.message = "End Date should be greater than Start date";
      (<HTMLInputElement>document.getElementById('openSuccess2Option')).click();
      // console.log("out");
      return;
    }


    let testData: FormData = new FormData();
    testData.append('businessId', this.insuranceForm.value.businessId);
    testData.append('status', this.insuranceForm.value.status);
    testData.append('type', this.insuranceForm.value.type);
    testData.append('insuranceProvider', this.insuranceForm.value.insuranceProvider);
    testData.append('startDate', moment(this.insuranceForm.value.startDate).format('YYYY-MM-DD'));
    testData.append('endDate', moment(this.insuranceForm.value.endDate).format('YYYY-MM-DD'));
    testData.append('conditionIns', this.insuranceForm.value.conditionIns);
    if (this.uploadedFiles) {
      testData.append('fileName', this.uploadedFiles);
    } else {
      testData.append('fileName', "");
    }
    testData.append('insuranceCost', this.insuranceForm.value.insuranceCost);
    testData.append('status', this.insuranceForm.value.status);
    testData.append('userTo', "");
    testData.append('userToName', "");
    testData.append('authId', this.apiService.authId);
    testData.append('outletId', this.insuranceForm.value.outletId);
    testData.append('insuranceAssets', JSON.stringify(this.submitAsset));

    // console.log(testData)
    this.apiService.startLoader()
    this.apiService.postCall('insurances/add', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }
  backClicked() {
    this.route.navigate(['/insurance-list'])
  }

  message
  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]

  attchments = []
  uploadedFiles
  fileSize: boolean
  fileChange(event) {
    this.fileSize = false
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log('size', file.size);
      if (file.size < 5269767) {
        // console.log(file)
        file = file
        this.uploadedFiles = file;
        this.fileName = file.name
        this.attchments.push({ file: file, fileName: file.name })
      }
      else {
        this.fileSize = true
      }
    }
  }
  removeImage() {
    this.fileName = null;
    this.attchments = [];
  }

  getValidation() {
    // // console.log(this.insuranceForm.valid)
    return (this.insuranceForm.valid && !this.validateDate(this.insuranceForm.value.startDate, this.insuranceForm.value.endDate))
  }

  validateDate(satrtDate, endDate) {
    // console.log(satrtDate , endDate)
    if (satrtDate && endDate) {
      if (endDate <= satrtDate) return true
      else return false
    }
  }


  getdiasableAsset(d) {
    let idx = this.selectedAssets.findIndex(x => x.id == d.id)

    if (idx != -1) return true
  }
}
