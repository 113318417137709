import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-survey-view',
  templateUrl: './employee-survey-view.component.html',
  styleUrls: ['./employee-survey-view.component.css']
})
export class EmployeeSurveyViewComponent implements OnInit {

  constructor(
    public _location: Location,
    public storedData: StoredData,
    public router: Router,
    private http: HttpClient,
    public route: Router,
    public apiService: ApiService,
  ) { }

  addPermission = false
  editPermission = false
  viewPermission = false
  viewResponsePermission = false
  printQRCodePermission = false
  authId = this.apiService.authId;
  surveyId
  message
  authIdFlag = false;
  ngOnInit(): void {
    this.surveyId = this.route.url.split("/")[2]
    this.getEmployeeDetail(this.surveyId);
    // const date = new Date();
    // date.setHours(date.getHours() + 5);
    // date.setMinutes(date.getMinutes() + 30);
    // let starDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    // starDate.setHours(starDate.getHours() + 5);
    // starDate.setMinutes(starDate.getMinutes() + 30);
    // this.range = [starDate , date];
    if (this.authId != 5747 || this.authId != 5710 || this.authId != 5749 || this.authId != 5708) {
      this.authIdFlag = true;
    } else {
      this.authIdFlag = false;
    }
  }
  clear() {
    this.searchText = "";
    this.range = "";
    this.startDate = "";
    this.endDate = "";
    this.getEmployeeDetail(this.surveyId);
  }

  employeeArray = [];
  searchText = "";
  startDate = "";
  endDate = "";
  getEmployeeDetail(id) {
    this.apiService.startLoader();
    if (this.range) {
      this.startDate = moment(this.range[0]).format('YYYY-MM-DD');
      this.endDate = moment(this.range[1]).format('YYYY-MM-DD');
    }
    console.log(this.range);
    let body = {
      searchText: this.searchText,
      startDate: this.startDate,
      endDate: this.endDate,
      download: ""
    }
    this.apiService.postCall('EmpSurveys/empSurveyDetail/' + id, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.employeeArray = result['surveyDetail']
      this.apiService.stopLoader()
    })
  }
  range: any;
  goToEmployeeSurvey() {
    this.route.navigate(['/employee-survey']);
  }
  detailSurvey(data) {
    this.route.navigate(['/employee-question-detail/' + data.emp_survey_id + '/' + data.employee_code + '/' + data.id]);
  }
  downloadReport() {
    this.apiService.startLoader();
    if (this.range) {
      this.startDate = moment(this.range[0]).format('YYYY-MM-DD');
      this.endDate = moment(this.range[1]).format('YYYY-MM-DD');
    }
    console.log(this.range);
    let body = {
      searchText: this.searchText,
      startDate: this.startDate,
      endDate: this.endDate,
      download: 1,
    }
    this.apiService.postCall('EmpSurveys/empSurveyDetail/' + this.surveyId, {}, body).subscribe((result: any[]) => {
      window.open(result['url'], "_blank");
      this.apiService.stopLoader()
    })
  }

  statusSelection: any;

  selectStatus(d) {

    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }
}
