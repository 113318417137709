import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-meter-add-trigger',
  templateUrl: './meter-add-trigger.component.html',
  styleUrls: ['./meter-add-trigger.component.css']
})
export class MeterAddTriggerComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  tiggerDetailsForm: FormGroup
  meterId
  ngOnInit(): void {
    document.body.style.overflowY = 'hidden';


    this.meterId = this.route.url.split("/")[2]
    this.getMeterDetail()

    this.tiggerDetailsForm = this.fb.group({
      property: [null, Validators.required],
      triggerValue: [null, Validators.required],
      triggetTitle: [null, Validators.required],
      triggerDescription: [null],
      frequency: [null],
      asset: [null],
      location: [null],
      priorityId: [1],
      // assignedto:this.fb.array([this.initChecklist()]),
      department: [null, Validators.required],
      option: [null, Validators.required],
    });
    this.getPropertyList()
    // this.getAssetList()

    if (this.route.url.split("/")[1] == 'meter-trigger-edit' && this.storedData.triggerData) {
      var option="";
      if(this.storedData.triggerData.whenReading=="above"){
        option ="Is Greater Than";
      }else if(this.storedData.triggerData.whenReading =="below"){
        option ="Is Less Than";
      }else if(this.storedData.triggerData.whenReading=="equal"){
        option ="Is Equal To";
      }
      this.tiggerDetailsForm.patchValue({

        property: this.storedData.triggerData.outletId,
        triggerValue: this.storedData.triggerData.value,
        triggetTitle: this.storedData.triggerData.ticketTitle,
        triggerDescription: this.storedData.triggerData.description,
        frequency: [null],
        // asset:this.storedData.triggerData.assetManagementId,
        location: this.storedData.triggerData.locationId,
        // assignedto:this.fb.array([this.initChecklist()]),
        department: this.storedData.triggerData.departmentId,
        option: option,
        priorityId: this.storedData.triggerData.priorityId
      })
      this.userArr = []
      if (this.storedData.triggerData.assigneUsers[0]) {
        for (let d of this.storedData.triggerData.assigneUsers) this.userArr.push({ user: d.id, department: null })
      }
      // console.log(this.userArr)
      // console.log(this.tiggerDetailsForm.value)
      this.getLocationList(); this.getUserList(); this.getAssetList()
    }
  }


  getDisabledUser(id){
    // console.log(this.userArr)
    if(this.userArr[0].user){
      let idx = this.userArr.findIndex(x=> x.user == id)
      if(idx != -1) return true
      else return false
      }
  }
  assetDetails() {

  }
  meterDetails: any;





  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }


  userArr = [{ user: null, department: null }]

  // get formChecklistArr() {
  //   return this.tiggerDetailsForm.get('checklist') as FormArray;
  // }




  // initChecklist(){
  //   return this.fb.group({
  //     user:[null],
  //     department:[null],

  //   });
  // }
  showAssigneeLimitFlag: boolean;

  addMoreUser(): void {

    if (this.userArr.length < 3) {
      this.userArr.push({ user: null, department: null })
    }
    else {
      this.showAssigneeLimitFlag = true;
    }
    // this.formChecklistArr.push(this.initChecklist());
  }

  getPriority(pId) {
    if (pId == 1) {
      return "High";
    } else if (pId == 2) {
      return "Medium";
    } else if (pId == 3) {
      return "Low";
    }

  }

  getMeterDetail() {
    this.apiService.startLoader()
    this.apiService.getCall('meters/view/' + this.meterId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.meterDetails = result['data'];
      this.tiggerDetailsForm.patchValue({ property: this.meterDetails.outletId });
      if (this.route.url.split("/")[1] != 'meter-trigger-edit') {
        this.getLocationList();
        this.getUserList();
        this.getAssetList()
      }
    })
  }
  //
  propertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.selectedFiler.property?this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.propertyList = result['data']
      this.departmentList = result['departments']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
      //   this.tiggerDetailsForm.patchValue({
      //     property :this.propertyList[idx].id 
      //   })


      // }
      // this.getLocationList();this.getUserList();this.getAssetList()



    })
  }

  locationList = []
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.tiggerDetailsForm.value.property ? this.tiggerDetailsForm.value.property : "",
    }
    // http://52.14.126.174/synccit/locations/location-list/:businessId/:outletId
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']


    })
  }



  assetList = []
  getAssetList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.tiggerDetailsForm.value.property ? this.tiggerDetailsForm.value.property : "",
      "statusNotIn":"5,8",
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']

    })
  }

  assignPriority(id) {
    this.tiggerDetailsForm.patchValue({


      priorityId: id
    })
  }

  priorityList = [{ id: 1, name: 'High' }, { id: 2, name: 'Medium' }, { id: 3, name: 'Low' }]

  userArchived = []
  userList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.tiggerDetailsForm.value.property ? this.tiggerDetailsForm.value.property : "",
    }
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.userList = result['data']

    })
  }
  message
  submit() {
    let assigneUserObj = []
    for (let d of this.userArr) {
      assigneUserObj.push({ "id": d.user, "name": this.getTextAssignedto(d.user) })
    }
    var option="";
    if(this.tiggerDetailsForm.value.option =="Is Greater Than"){
      option ="above";
    }else if(this.tiggerDetailsForm.value.option =="Is Less Than"){
      option ="below";
    }else if(this.tiggerDetailsForm.value.option =="Is Equal To"){
      option ="equal";
    }

    let obj = {
      meterId: parseInt(this.meterId),
      whenReadinge:option ,
      value: parseInt(this.tiggerDetailsForm.value.triggerValue),
      ticketTitle: this.tiggerDetailsForm.value.triggetTitle,
      description: this.tiggerDetailsForm.value.triggerDescription,
      outletId: this.tiggerDetailsForm.value.property,
      // assetManagementId:this.tiggerDetailsForm.value.asset,
      locationId: this.tiggerDetailsForm.value.location,
      userId: this.apiService.userId,
      // departmentId:this.tiggerDetailsForm.value.department,
      departmentId: this.tiggerDetailsForm.value.department,
      authId: this.apiService.userId,
      priorityId: this.tiggerDetailsForm.value.priorityId,
      "assigneUsers": assigneUserObj


    }
    // if( this.route.url.split("/")[1] == 'meter-trigger-edit' && this.storedData.triggerData){
    //   obj['triggerId']= this.storedData.triggerData.triggerId;
    // }

    let url

    if (this.route.url.split("/")[1] == 'meter-trigger-edit') url = "meter-triggers/edit/" + this.storedData.triggerData.triggerId
    else url = "meter-triggers/add"
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall(url, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()



      this.message = result["msg"];



      (<HTMLInputElement>document.getElementById('openSuccess')).click();

      //   setTimeout(() => {
      //     (<HTMLInputElement>document.getElementById('dismissOk')).click();

      // }, 1000);

    })
  }
  backClicked() {
    this.route.navigate(['/meter-trigger-list/' + this.meterId])
  }



  getTextProperty(data) {
    if (data && this.propertyList && this.propertyList[0]) return this.propertyList.find(x => x.id == data) ? this.propertyList.find(x => x.id == data).outletName : ''
  }
  getTextLocation(data) {

    if (data && this.locationList && this.locationList[0]) return this.locationList.find(x => x.id == data) ? this.locationList.find(x => x.id == data).name : ''
  }


  getTextAsset(data) {

    if (data && this.assetList && this.assetList[0]) return this.assetList.find(x => x.id == data) ? this.assetList.find(x => x.id == data).assetName : ''
  }


  getSelectedUsers(){
    var d="";
    for(var i=0; i <this.userArr.length;i++ ){
      if(i==0){
        d = this.getTextAssignedto(this.userArr[i].user)
      }else{
      d = d+", "+this.getTextAssignedto(this.userArr[i].user)
      }
    }
    return d
  }
  getTextAssignedto(data) {
    let idx = this.userList.findIndex(x => x.id == data)
    if (idx != -1 && data && this.userList[0]) return this.userList.find(x => x.id == data).fullName
  }

  getDepartment(data) {
    if (data && this.departmentList[0]) return this.departmentList.find(x => x.id == data).name
  }

  //options = ["above", "below", "equal"]
  options = ["Is Greater Than", "Is Less Than", "Is Equal To"]
  tabSelected = "step1"

  departmentList = []


  getValidation() {
    // console.log(this.tiggerDetailsForm.valid)
    return this.tiggerDetailsForm.valid && this.userArr[0] && this.userArr[0].user
  }

  removeUser(i) {
    this.userArr.splice(i, 1)
    this.showAssigneeLimitFlag = false;
  }
}


