import { Component, HostListener, OnInit } from '@angular/core';
// import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApiService } from '../common/services/apiCalls.service';
import { DateRange } from 'igniteui-angular';
import { StoredData } from '../common/services/storedData.service';
import { local } from 'd3';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public range: DateRange = { start: new Date(), end: new Date(new Date().setDate(new Date().getDate() + 5)) };
  constructor(private http: HttpClient, public route: Router,public router: Router, public apiService: ApiService, public storedData: StoredData) { }

  duplicatePermission = false
  transferPermission = false
  assetAddPermission = false
  assetBulkAddPermission = false
  assetViewPermission = true
  deactivatePermission = false
  changeStatusPermission = false

  listingtype

  getIds(ev) {
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property = ev.property
    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);

    // this.applyFilter();
  }
  ngOnInit() {
    if(this.router.url.split("/")[2]){
      this.tabSelected = this.router.url.split("/")[2];
      this.clear();
      this.setTabSelection();
    }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).assetList
    }
    if (localStorage.getItem("listingtype")) {
      this.listingtype = localStorage.getItem("listingtype");
      if (this.listingtype == "transferAssets") {
        this.tabSelected = 'migrate'
        this.selectedFiler.type = { id: 1, name: 'Temporary' }
      }
      localStorage.setItem("listingtype", undefined)
    }
    var homePagination = localStorage.getItem('homePagination');
    if (homePagination) {
      this.activePage = parseInt(homePagination);
    }
    this.apiService.stopLoader()
    this.getAssets()
    //this.getPropertyList()
    this.getTransitList()
    this.getBusinessList()
    // this.getLocationList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 3);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.assetAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Bulk Add" && permission[i]['actionStatus'] == 1) {
        this.assetBulkAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Duplicate" && permission[i]['actionStatus'] == 1) {
        this.duplicatePermission = true
      }
      if (permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Transfer" && permission[i]['actionStatus'] == 1) {
        this.transferPermission = true
      }
      if (permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1) {
        this.changeStatusPermission = true
      }
      if (permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Deactivate" && permission[i]['actionStatus'] == 1) {
        this.deactivatePermission = true
      }
    }

  }
  checkAdmin(outletId) {
    let idx1 = this.propertyList.findIndex(x => x.id == outletId);
    if (idx1 != -1) {
      return true;
    } else {
      return false;
    }
  }
  paginationArray = []
  activePage: any = 1
  pageSize = 10
  assetList: any;
  sortType = "desc"
  sortBy = 'assetId'

  search: any;
  serachPromise = null
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true
  }

  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;

  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ ticketList: 'openTicket', assetList: this.tabSelected, inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }));
    (<HTMLInputElement>document.getElementById('clearFilter')).click();
  }
  selectStatus(d) {
    // console.log(d)
    if (this.statusSelection != 'status' + d.id) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }
  searchAssetNAme() {
    clearTimeout(this.serachPromise);
    this.serachPromise = setTimeout(() => {
      this.activePage = 1;
      const body = {
        pageNo: this.activePage, limit: this.pageSize, sortType: this.sortType, sortBy: this.sortBy, assetName: this.search, "roleId": this.apiService.roleId,
        "userId": this.apiService.userId
      };
      this.apiService.startLoader()
      this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.assetList = []
        this.assetList = result['data']
        this.setPaginationArray(result)

      })
    }, 1000);
  }

  getColor(status) {
    if (status == 5 || status == 2 || status == 8) {
      return 'red';
    }
    return ''
  }
  filterStatus(data) {
    this.activePage = 1;
    const body = {
      pageNo: this.activePage, limit: this.pageSize, sortType: this.sortType, sortBy: this.sortBy, status: parseInt(data), "roleId": this.apiService.roleId,
      "userId": this.apiService.userId
    };
    if (this.search != '') body['assetName'] = this.search
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']['data']
      this.setPaginationArray(result)

    })
  }
  getFilterStatus() {
    if (this.applyFor == 1) {
      if (this.tabSelected == 'migrate') {
        return `(None Selected)`
      }
    } else if (this.applyFor == 2) {
      if (this.tabSelected == 'allasset') {
        return `(None Selected)`
      }
    }
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1

    // if(length == 4 || !this.applyFlag) return "(None Selected)"
    // else if(this.applyFlag) return `(${4-length} Filter Selected)`
    // console.log(this.selectedFiler)
    let length = 0;
    //if (this.selectedFiler.name != null && this.selectedFiler.name != "") length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.status != null) length = length + 1
    if (this.selectedFiler.assetName != null) length = length + 1
    // if (this.selectedFiler.assetTag != null && this.selectedFiler.assetTag != "") length = length + 1
    if (this.selectedFiler.assetMake != null && this.selectedFiler.assetMake != "") length = length + 1
    if (this.tabSelected == 'migrate' && this.selectedFiler.type != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  applyFor = 0
  applyFlag = false;
  applyFilter() {
    localStorage.removeItem('assetLocalFilter');
    localStorage.setItem("assetLocalFilter", null);
    localStorage.removeItem('localAssetTransfersFilter');
    localStorage.setItem("localAssetTransfersFilter", null);
    this.applyFlag = true;
    // console.log(this.selectedFiler)
    this.activePage = 1;
    this.activePageTransit = 1;
    if (this.tabSelected == 'allasset') {
      this.applyFor = 1
      this.getAssets();
    } else {
      this.applyFor = 2
      this.getTransitList();
    }
  }

  selectedFiler = { name: null, assetName: null, warrantExpiratioStart: null, property: null, status: null, business: null, type: null, assetTag: null, assetMake: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, }

  routeTOMigrate() {
    this.storedData.selectedTransferArray = this.selectedAssets;
    this.storedData.selectedTransferPermanentArray = this.selectedAssetsDetailsList;
    this.route.navigate(['/migrate-asset'])
  }

  navigateToDetails(d) {
    // console.log(d)
    if (d.assetId) this.route.navigate(['/asset-details/' + d.assetId])
    else this.route.navigate(['/asset-details/' + d.assetManagementId])
  }
  onDateSelect(ev) {
    // console.log(ev)
  }

  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pageSizeTransit = 10
  activePageTransit: any = 1
  paginationArrayTransit = []
  totalnoOfPagesTransit
  setPaginationArrayTransit(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeTransit)
    this.totalnoOfPagesTransit = noOfPAges;
    this.paginationArrayTransit = []
    this.activePageTransit = parseInt(this.activePageTransit)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayTransit.push(i)
    else if (4 + this.activePageTransit < this.totalnoOfPagesTransit) {
      for (let i = this.activePageTransit; i <= 4 + this.activePageTransit; i++)this.paginationArrayTransit.push(i)
    }
    else if (4 + this.activePageTransit >= 6) {

      let start = (4 + this.activePageTransit) - noOfPAges
      for (let i = this.activePageTransit - start; i <= noOfPAges; i++)this.paginationArrayTransit.push(i)
    }
    // console.log(this.paginationArrayTransit)
  }

  rowCount = 0
  qrAssetList = []
  getAll() {

    // console.log("its a call")
    // this.authService.loader= true;

    const body = {
      pageNo: 1,
      limit: this.rowCount,
      sortType: this.sortType,
      sortBy: this.sortBy,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetName: this.selectedFiler.name ? this.selectedFiler.name : null,
      assetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : null,
      assetTagNo: this.selectedFiler.assetTag ? this.selectedFiler.assetTag : null,
      make: this.selectedFiler.assetMake ? this.selectedFiler.assetMake : null,
      status: this.selectedFiler.status ? parseInt(this.selectedFiler.status.id) : null,
      warrantyExpirationStart: this.selectedFiler.warrantExpiratioStart ? this.selectedFiler.warrantExpiratioStart[0] : null,
      warrantyExpirationEnd: this.selectedFiler.warrantExpiratioStart ? this.selectedFiler.warrantExpiratioStart[1] : null,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
      frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",
      frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
    };

    // if(this.search != '')body['assetName'] = this.search
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/index/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.qrAssetList = []
      this.qrAssetList = result['data']['data']
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrAssetList.length; i++) {
        this.qrAssetList[i]['selected'] = false
      }

    })
  }

  AllSelectedCheck = false
  assetsForPrintQr = []
  AllSelected(event, asset) {
    if (event.target.checked && asset == "All") {
      this.AllSelectedCheck = true
      for (var i = 0; i < this.qrAssetList.length; i++) {
        this.qrAssetList[i]['selected'] = true
      }
      this.assetsForPrintQr = this.qrAssetList
    } else if (event.target.checked) {
      // console.log("in" + event.target.checked);
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrAssetList.length; i++) {
        if (this.qrAssetList[i]['assetId'] == asset.assetId) {
          this.qrAssetList[i]['selected'] = true
        }
      }
      this.assetsForPrintQr.push(asset);
    } else if (!event.target.checked && asset == "All") {
      this.assetsForPrintQr = []
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrAssetList.length; i++) {
        this.qrAssetList[i]['selected'] = false
      }
    } else if (!event.target.checked) {
      this.AllSelectedCheck = false
      this.assetsForPrintQr = this.assetsForPrintQr.filter(item => item.assetId != asset.assetId);
      for (var i = 0; i < this.qrAssetList.length; i++) {
        if (this.qrAssetList[i]['assetId'] == asset.assetId) {
          this.qrAssetList[i]['selected'] = false
        }
      }
    }
  }
  gotoPrint() {
    this.storedData.selectedAssetsDetailsList = this.assetsForPrintQr
    localStorage.setItem("selectedAssetsDetailsList", JSON.stringify(this.assetsForPrintQr));
    window.open('/asset-qr-html')
    //this.route.navigate(['/asset-qr-html'])
  }
  openQR(d) {
    this.storedData.qrData = { name: d.assetManagementName, image: d.QRCode,customerComplaintName:'' }
    this.route.navigate(['print-qr'])
  }
  getAssets(): void {
    let body = {}
    if (localStorage.getItem("assetLocalFilter") && localStorage.getItem("assetLocalFilter") != null && localStorage.getItem("assetLocalFilter") != 'null') {
      let assetLocalFilter = JSON.parse(localStorage.getItem("assetLocalFilter"));
      body = assetLocalFilter;
      if (assetLocalFilter.outletId && assetLocalFilter.outletId != null && assetLocalFilter.outletId != 'null') {
        this.selectedFiler.property = { id: assetLocalFilter.outletId, outletName: assetLocalFilter.frontOutletNameFilter }
      }
      if (assetLocalFilter.assetNameDp && assetLocalFilter.assetNameDp != null && assetLocalFilter.assetNameDp != 'null') {
        this.searchAssetNAme();
        this.selectedFiler.assetName = { id: assetLocalFilter.assetNameDp, assetName: assetLocalFilter.frontAssetNameDp }
      }
      this.selectedFiler.assetMake = assetLocalFilter.make
      if (assetLocalFilter.status && assetLocalFilter.status != null && assetLocalFilter.status != 'null') {
        this.selectedFiler.status = { id: assetLocalFilter.status, name: assetLocalFilter.frontStatusName }
      }
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        assetName: this.selectedFiler.name ? this.selectedFiler.name : null,
        assetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : null,
        assetTagNo: this.selectedFiler.assetTag ? this.selectedFiler.assetTag : null,
        make: this.selectedFiler.assetMake ? this.selectedFiler.assetMake : null,
        status: this.selectedFiler.status ? parseInt(this.selectedFiler.status.id) : null,
        warrantyExpirationStart: this.selectedFiler.warrantExpiratioStart ? this.selectedFiler.warrantExpiratioStart[0] : null,
        warrantyExpirationEnd: this.selectedFiler.warrantExpiratioStart ? this.selectedFiler.warrantExpiratioStart[1] : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",
        frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('assetLocalFilter', JSON.stringify(body));
    }
    // if(this.search != '')body['assetName'] = this.search
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/index/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']['data']
      this.statusListArray = result['statusList']
      this.rowCount = result['rowCount']
      // let idx = this.statusListArray.findIndex(x=>x .name== "In Migration")
      // this.statusListArray.splice(idx,1)
      // let idx1 = this.statusListArray.findIndex(x=>x .name== "In Transit")
      // this.statusListArray.splice(idx1,1)
      // idx1 = this.statusListArray.findIndex(x=>x .name== "Dormant")
      // this.statusListArray.splice(idx1,1)

      // this.statusList = result['statusList']

      this.setPaginationArray(result)
    })
  }
  clear() {
    localStorage.removeItem('assetLocalFilter');
    localStorage.setItem("assetLocalFilter", null);
    localStorage.removeItem('localAssetTransfersFilter');
    localStorage.setItem("localAssetTransfersFilter", null);
    this.applyFor = 0
    this.activePage = 1
    this.selectedFiler = { name: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, assetName: null, warrantExpiratioStart: null, property: null, status: null, business: null, type: null, assetTag: null, assetMake: null }

    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx]
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
        // this.getPropertyList()

      }
      else {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
      }
    }

    else {
      this.getPropertyList()
    }

    if (this.tabSelected == 'allasset') {
      this.getAssets();
    } else {
      this.getTransitList();
    }
  }
  // statusList=[]
  locationList = []
  filterlocationList = []
  getLocationList() {
    this.apiService.startLoader()
    let url
    if (this.apiService.roleId == '3') {
      url = 'outlets/outlet-list/' + this.apiService.buisnessId + '/' + this.apiService.userId
    }
    else if (this.apiService.roleId == '2' || this.apiService.roleId == '1') {
      url = 'outlets/outlet-list/' + this.apiService.buisnessId
    }
    else {
      url = 'outlets/outlet-list/' + this.apiService.buisnessId + '/' + this.apiService.userId
    }
    this.apiService.getCall(url, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      var jsonToBeUsed = [];

      //   for (var type in result['data']) {
      //     let item = {};
      //     item['key'] = type;
      //     item['value'] = result['data'][type];
      //     jsonToBeUsed.push(item);


      // }

      // console.log(jsonToBeUsed)
      this.locationList = result['data']
      this.filterlocationList = result['data']

      // console.log(jsonToBeUsed)
      // this.locationList = jsonToBeUsed
      // this.filterlocationList = jsonToBeUsed
      this.storedData.locationList = this.filterlocationList;

    })
  }

  pagination(d) {
    localStorage.removeItem('assetLocalFilter');
    localStorage.setItem("assetLocalFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.selectedAssets = []
      this.activePage = d
      localStorage.setItem('homePagination', d);
      // console.log(d)
      this.getAssets()
      window.scrollTo(0, 0);

    }
  }
  changePage(ev) {
    localStorage.removeItem('assetLocalFilter');
    localStorage.setItem("assetLocalFilter", null);
    // console.log(this.pageSize)
    this.selectedAssets = []
    // this.pageSize = d
    this.getAssets()
    window.scrollTo(0, 0);
  }

  changePageTransit(ev) {
    localStorage.removeItem('localAssetTransfersFilter');
    localStorage.setItem("localAssetTransfersFilter", null);
    this.getTransitList()
    window.scrollTo(0, 0);
  }

  paginationTransit(d) {
    localStorage.removeItem('localAssetTransfersFilter');
    localStorage.setItem("localAssetTransfersFilter", null);
    if (d != 0 && d <= this.totalnoOfPagesTransit && this.activePageTransit != d) {
      // this.selectedAssets=[]
      this.activePageTransit = d
      localStorage.setItem('homePagination', d);
      // console.log(d)
      this.getTransitList()
      window.scrollTo(0, 0);
    }
  }


  selectedAssets = []
  selectedAssetsDetailsList = []
  selectAllAssets(ev) {
    this.selectedAssets = []

    if (ev.target.checked) {
      this.assetList.map(x => (x.status != 5 && x.status != 8) ? x.selected = true : x.selected = false)
      for (let asset of this.assetList) {
        if (asset.selected) {
          this.selectedAssets.push(asset.assetId)
          this.selectedAssetsDetailsList.push(asset)
        }
      }
    }
    else {
      this.assetList.map(x => x.selected = false)
      this.selectedAssets = []
      this.selectedAssetsDetailsList = []
    }
    // console.log(this.selectedAssets)
  }
  selectAsset(ev, asset) {
    // console.log(ev.target.checked, asset)
    if (ev.target.checked) {
      this.selectedAssets.push(asset.assetId)
      this.selectedAssetsDetailsList.push(asset)
    }
    else {
      this.selectedAssets.splice(this.selectedAssets.findIndex(x => x == asset.assetId), 1)
      this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.assetId == asset.assetId), 1)
    }

    // console.log(this.selectedAssets)
  }

  showStatus(d) {
    let ele = document.getElementById("status" + d)
    if (ele.style.display == "none") ele.style.display = "block";
    else ele.style.display = "none";
  }

  sortData(data) {
    localStorage.removeItem('assetLocalFilter');
    localStorage.setItem("assetLocalFilter", null);
    localStorage.removeItem('localAssetTransfersFilter');
    localStorage.setItem("localAssetTransfersFilter", null);
    this.sortBy = data
    this.sortByTrnasit = data;
    if (this.sortBy == 'assetId') this.sortByTrnasit = 'id'
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    if (this.tabSelected == 'allasset') {
      this.getAssets();
    } else {
      this.getTransitList();
    }
  }

  changeAssetMultiple: any = null
  forAll = false
  event
  chnageStatus(status) {
    // console.log(status.target.options.selectedIndex, this.statusListArray[status.target.options.selectedIndex - 1])
    if (this.statusListArray[status.target.options.selectedIndex - 1].id == 5 || this.statusListArray[status.target.options.selectedIndex - 1].id == 8) {
      this.forAll = true;
      this.event = this.statusListArray[status.target.options.selectedIndex - 1].id;
      this.remark = null;
      (<HTMLInputElement>document.getElementById('statusRemarks')).click();
      return 1;
    }
    const body = { status: this.statusListArray[status.target.options.selectedIndex - 1].id, assetManagementIds: this.selectedAssets, authId: this.apiService.userId };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/change-status', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // console.log(result);
      this.changeAssetMultiple = null
      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('openSuccess')).click();

      // this.activePage = 1
      // this.pageSize = 10
      // this.sortType = "asc"
      // this.sortBy = 'outletName'
      this.selectedAssets = []


      this.getAssets()
    })
  }
  remarkSaveAll(status) {
    const body = { status: status, assetManagementIds: this.selectedAssets, authId: this.apiService.userId };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/change-status', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.changeAssetMultiple = null
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.selectedAssets = []
      this.getAssets()
    })
  }
  remarkCancelSingle(d) {
    d.status = d.preStatus;
    (<HTMLInputElement>document.getElementById('statusRemarks')).click();
  }
  remarkCancel() {
    this.changeAssetMultiple = null;
    (<HTMLInputElement>document.getElementById('statusRemarks')).click();
  }

  // deleteSelected(){
  //   const body ={ authId:1 };
  //   for(let assetId of this.selectedAssets){
  //   this.apiService.startLoader()
  //   this.apiService.postCall('pms/assetPm/'+assetId,{} , body ).subscribe((result: any[])=>{
  //     this.apiService.stopLoader()
  //   })
  // }
  // }
  message: any;
  dublicateSelected() {
    const body = { assetManagementIds: this.selectedAssets, authId: this.apiService.userId };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/duplicateAsset', {}, body).subscribe((result) => {
      this.apiService.stopLoader()
      // console.log(result)
      if (this.selectedAssets.length == 1) this.message = result["msg"];
      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      // this.activePage=1
      // this.pageSize = 10
      // this.sortType="asc"
      // this.sortBy = 'outletName'
      this.selectedAssets = []
      this.selectedAssetsDetailsList = []
      this.getAssets()
    })
  }

  deactiveSelected() {
    const body = { assetManagementIds: this.selectedAssets, authId: this.apiService.userId };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/deactivate', {}, body).subscribe((result) => {
      this.activePage = 1
      this.pageSize = 10

      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      // this.sortType="asc"
      // this.sortBy = 'outletName'
      this.selectedAssets = []
      this.apiService.stopLoader()
      this.getAssets()
    })
  }

  statusListArray = []

  statusList = [

    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
    { id: 3, name: 'Dormant' },


  ]
  typeList = [

    { id: 1, name: 'Temporary' },
    { id: 2, name: 'Permanent' },


  ]

  getStatus(id) {

    if (id) return this.statusList.find(x => x.id == id).name
  }
  removeStatusValue
  changeStatusSingle(d) {
    if (d.status == 5 || d.status == 8) {
      this.removeStatusValue = d;
      this.remark = null;
      this.forAll = false;
      (<HTMLInputElement>document.getElementById('statusRemarks')).click();
      return 1;
    }
    const body = { status: d.status, assetManagementIds: [d.assetId], authId: this.apiService.userId };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/change-status', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }

  tabSelected = 'allasset'
  filterByLocationId(d) {
    this.activePage = 1;
    const body = {
      pageNo: this.activePage, limit: this.pageSize, sortType: this.sortType, sortBy: this.sortBy, outletId: d.id,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId
    };
    if (this.search != '') body['assetName'] = this.search
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']['data'];

      this.setPaginationArray(result)
    })
  }


  migrateList = []
  filterLocationSearch: any
  filterLocation(ev) {
    // console.log(ev)

    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    }
    )

  }

  filterAssetSearch: any
  filterAsset(ev) {
    // console.log(ev)
    this.AssetList = this.filterAssetList.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase())
      }
    }
    )

  }

  // getLocationName(id) {
  //   return this.locationList.find(x => x.key == id).value
  // }

  sortByTrnasit = 'id';

  getTransitList() {
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    let body = {}
    if (localStorage.getItem("localAssetTransfersFilter") && localStorage.getItem("localAssetTransfersFilter") != null && localStorage.getItem("localAssetTransfersFilter") != 'null') {
      let localAssetTransfersFilter = JSON.parse(localStorage.getItem("localAssetTransfersFilter"));
      body = localAssetTransfersFilter;
      if (localAssetTransfersFilter.outletId && localAssetTransfersFilter.outletId != null && localAssetTransfersFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localAssetTransfersFilter.outletId, outletName: localAssetTransfersFilter.frontOutletNameFilter }
      }
      if (localAssetTransfersFilter.assetNameDp && localAssetTransfersFilter.assetNameDp != null && localAssetTransfersFilter.assetNameDp != 'null') {
        this.searchAssetNAme();
        this.selectedFiler.assetName = { id: localAssetTransfersFilter.assetNameDp, assetName: localAssetTransfersFilter.frontAssetNameDp }
      }
      this.selectedFiler.assetMake = localAssetTransfersFilter.make
      if (localAssetTransfersFilter.type && localAssetTransfersFilter.type != null && localAssetTransfersFilter.type != 'null') {
        this.selectedFiler.type = { id: localAssetTransfersFilter.type, name: localAssetTransfersFilter.frontTransferTypeName }
      }
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePageTransit, limit: this.pageSizeTransit, businessId: businessId,
        sortType: this.sortType,
        sortBy: this.sortByTrnasit,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        assetName: this.selectedFiler.name ? this.selectedFiler.name : null,
        assetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : null,
        assetTagNo: this.selectedFiler.assetTag ? this.selectedFiler.assetTag : null,
        make: this.selectedFiler.assetMake ? this.selectedFiler.assetMake : null,
        status: this.selectedFiler.status ? parseInt(this.selectedFiler.status.id) : null,
        warrantyExpirationStart: this.selectedFiler.warrantExpiratioStart ? this.selectedFiler.warrantExpiratioStart[0] : null,
        warrantyExpirationEnd: this.selectedFiler.warrantExpiratioStart ? this.selectedFiler.warrantExpiratioStart[1] : null,
        type: this.selectedFiler.type ? parseInt(this.selectedFiler.type.id) : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",
        frontTransferTypeName: this.selectedFiler.type ? this.selectedFiler.type.name : "",
        frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localAssetTransfersFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('asset-transfers/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.migrateList = [];
      this.migrateList = result['data']
      this.apiService.stopLoader()
      this.setPaginationArrayTransit(result)

    })
  }
  statusSelection
  changeStatusInitRe(status, d, type) {
    // console.log(d.statusCheck)
    if (status == 'markaslost') {
      this.lastId = d.id;
      (<HTMLInputElement>document.getElementById('statusRemarksss')).click();
      return 1;
    }
    this.statusSelection = undefined;
    if (status != 'ReInitialize') {
      const body = {
        authId: this.apiService.userId,
        id: d.id,
        type: type
      };
      if (status == 'markasretunr') body['status'] = 3
      else if (status == 'markaslost') body['status'] = 4
      else if (status == 'Approve') body['status'] = 1
      else if (status == 'Reject') body['status'] = 2
      else if (status == 'Recieved') body['status'] = 5

      this.apiService.startLoader()
      this.apiService.postCall('asset-transfers/statusChange', {}, body).subscribe((result: any[]) => {
        // console.log(result);

        this.apiService.stopLoader()
        // this.message = result["msg"];  
        if (status == 'markasretunr') this.message = "Asset(s) returned successfully";
        else if (status == 'markaslost') this.message = "Asset Marked as Lost";
        else if (status == 'Reject') this.message = "Asset transfer cancelled successfully";
        else if (status == 'Approve') this.message = "Asset successfully transferred";
        else if (status == 'Recieved') this.message = "Asset(s) moved successfully";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.getTransitList()
        this.getAssets()
      }
      )
    }

    else {

      // console.log(d)

      d.assetId = d.assetManagementId
      d.assetName = d.assetManagementName

      d.propertyId = d.outletId
      d.propertyName = d.outletName
      this.storedData.selectedTransferArray = [d];
      this.storedData.selectedTransferPermanentArray = [d];
      this.route.navigate(['/migrate-asset'])


      // const body ={ authId:1 ,id:d.id };

      // this.apiService.startLoader()
      // this.apiService.postCall('asset-transfers/reInitialize',{} , body ).subscribe((result: any[])=>{
      //   // console.log(result);

      //   this.apiService.stopLoader()
      //   this.message = result["msg"];  
      //   (<HTMLInputElement>document.getElementById('openSuccess')).click();
      //   this.getTransitList()
      // }
      // )
    }
  }

  saveLost(id) {
    const body = {
      authId: this.apiService.userId,
      id: id,
      type: 'temporary',
      status: 4,
      remark: this.remark
    };

    this.apiService.startLoader()
    this.apiService.postCall('asset-transfers/statusChange', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getTransitList()
      this.getAssets()
    }
    )
  }
  userList = []
  filterUserList = []
  filterUserSearch
  // propertFilter= false;
  filterUser(ev) {
    // console.log(ev)
    this.userList = this.filterUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }

  getUserList() {
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)


    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property.id,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.userList = result['data']

    })
  }






  propertyList = []
  AssetList = []
  filterpropertyList = []
  filterAssetList = []
  getPropertyList() {
    this.apiService.startLoader()
    let url
    if (this.apiService.roleId == '3') {
      url = 'outlets/outlet-list/' + this.apiService.buisnessId + '/' + this.apiService.userId
    }
    else if (this.apiService.roleId == '2' || this.apiService.roleId == '1') {
      url = 'outlets/outlet-list/' + this.apiService.buisnessId
    }
    else {
      url = 'outlets/outlet-list/' + this.apiService.buisnessId + '/' + this.apiService.userId
    }

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.tiggerDetailsForm.value.property?this.tiggerDetailsForm.value.property : "",
    }
    this.apiService.postCall(url, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

      this.AssetList = result['assetsList']
      this.filterAssetList = result['assetsList']
      // if(idx != -1){
      //  if(this.apiService.roleId == '3')  {

      //     this.selectedFiler.property = this.propertyList[idx] 
      //     // this.getPropertyList()

      //   }
      //   else{

      //     this.selectedFiler.property = this.propertyList[idx] 
      //   }
      // }
    })
  }
  remarkLost() {
    this.remark = null;
    this.lastId = null;
    (<HTMLInputElement>document.getElementById('statusRemarksss')).click();
  }
  lastId = null
  remark = null
  remarkSave(d) {
    const body = { status: d.status, assetManagementIds: [d.assetId], authId: this.apiService.userId, remark: this.remark };
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/change-status', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }
  filterPropertySearch
  propertFilter = false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      // if(idx != -1 ){
      // if(this.apiService.roleId == '2') {
      //   this.selectedFiler.business =  this.bussnissList[idx]
      //   this.getPropertyList()
      //   }
      //   else if(this.apiService.roleId == '3')  {
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()

      //   }
      //   else{
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()
      //   }
      // }
      // else {
      this.getPropertyList()
      // }
    })
  }

  showAddMenu = false;
  showFilter = false;

  jumpToPage = 1
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  jumpToPageTransit = 1

  assetNameFiletr = false
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    // if (btn.id == 'custom11') {
    //   // this.showFilter = false
    //   this.propertyFiletr = false;
    //   this.assetNameFiletr=false
    //   this.statsuFilter = false;
    //   this.typeFilter = false;
    //   this.showAddMenu = false;
    //   this.businessFilter = false;
    //   // this.selectFreq = false
    // }

    if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.assetNameFiletr = false
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
      // this.selectFreq = false
    }

    if (btn.id == 'changeBUton') {
    } else if (btn.id != 'changeBUton') {
      this.statusSelection = undefined
    }
  }
  closeAll() {
    this.propertyFiletr = false;
    this.assetNameFiletr = false;
    this.statsuFilter = false;
    this.typeFilter = false;
    this.businessFilter = false;
  }


  uploadedFiles
  fileName
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }
  getValidation() {
    if (!this.uploadedFiles) {
      return false;
    }
    return true;
  }
  errorMessages = []
  uploadWarranty() {
    let testData: FormData = new FormData();
    testData.append('file', this.uploadedFiles);
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    this.errorMessages = []

    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/bulkupload', {}, testData).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.clear()
      this.uploadedFiles = null
      this.fileName = null
    })
  }



}
