import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from '../../../common/services/apiCalls.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-permit-associated-add',
  templateUrl: './permit-associated-add.component.html',
  styleUrls: ['./permit-associated-add.component.css']
})
export class PermitAssociatedAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  workDropdownSettings?: IDropdownSettings;
  propertyList = [];
  workList = [];
  routeId;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  id
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    // this.id = this.route.url.split("/")[2]

    this.tenantForm = this.fb.group({
      outletId: [null, Validators.required],
      permitId: [null, Validators.required],
      name: [null, Validators.required],
      authId: [null],
    });
    this.getPropertyList();
    if(this.routeId){
        this.getDetailsById();
    }
  }

  goBack(): void {
    this.router.navigate(['/permit-setting/associated-list']);
  }


  save(): void {
    this.tenantForm.value.authId = this.apiService.authId;
    let formData = this.tenantForm.value;
    this.apiService.startLoader();
    var url = 'AssociatedPermits/add';
    if (this.routeId) {
      var url = 'AssociatedPermits/edit/'+this.routeId;
    }
    this.apiService.postCall(url, {}, formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });

  }

  getValidation() {
    return this.tenantForm.valid;
  }

  getPropertyList() {
    this.apiService.startLoader();
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {},{
        'roleId': this.apiService.roleId,
        'userId': this.apiService.authId,
        'businessId': this.apiService.buisnessId
      }
    ).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      this.propertyList = result['data'];
    });
  }

  onItemSelect(t){
    this.workList = [];
    this.apiService.startLoader();
    let body = {
      pageNo: null,
      limit: null,
      outletId: t.target.value,
      'authId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('permits/index', {}, body).subscribe((result: any[]) => {
      this.workList = result['data'];
      this.apiService.stopLoader();
    });
  }

  getDetailsById() {
    if (this.routeId) {
      this.apiService.startLoader();
      this.apiService.getCall('AssociatedPermits/view/' + this.routeId, {}).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.tenantForm.patchValue(result['data']);

        this.workList = [];
        this.apiService.startLoader();
        let body = {
          pageNo: null,
          limit: null,
          outletId: result['data']['outletId'],
          'authId': this.apiService.userId,
          'businessId': this.apiService.buisnessId,
        };
        this.apiService.postCall('permits/index', {}, body).subscribe((result: any[]) => {
          this.workList = result['data'];
          this.apiService.stopLoader();
        });
      });
    }
  }

  
  backClicked() {
    this.router.navigate(['/permit-setting/associated-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
