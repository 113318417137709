import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';

@Component({
  selector: 'setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  pmId;
  pmDetails;
  role:any;
  constructor(public router: Router,public _location: Location,public apiService: ApiService) { }

  propertyPermission=false
  checklistPermission=true
  insurancePermission=false
  businessPermission=false
  departMentPermission=false
  surveyPermission=false
  userTeamsPermission=true
  settingPermission=false

  businessInspectionPermission = false
  businessFeaturePermit=false
  businessFeatureFeedback=false
  businessFeatureTraining=false
  employeeDatabaseFeaturePermission = false
  documentTrackerFeaturePermissions = false
  ngOnInit(): void {
    this.role = parseInt(this.apiService.role);

    
    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if(businessPermission){
      for(var i=0;i<businessPermission.length;i++){
        if(businessPermission[i]['business_app_feature_id'] ==4 && businessPermission[i]['permission_allow'] ==  1){
          this.businessFeaturePermit=true
        }
        if(businessPermission[i]['business_app_feature_id'] ==5 && businessPermission[i]['permission_allow'] ==  1){
          this.businessFeatureFeedback=true
        }
        if(businessPermission[i]['business_app_feature_id'] ==8 && businessPermission[i]['permission_allow'] ==  1){
          this.businessFeatureTraining=true
        }
        if (businessPermission[i]['business_app_feature_id'] == 11 && businessPermission[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 30 && businessPermission[i]['permission_allow'] == 1) {
          this.documentTrackerFeaturePermissions = true
        }
        if (businessPermission[i]['business_app_feature_id'] == 33 && businessPermission[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
      if(this.apiService.role==1){
        this.businessInspectionPermission = true
        this.businessFeaturePermit=true
        this.businessFeatureFeedback=true
        this.businessFeatureTraining=true
        this.documentTrackerFeaturePermissions = true
      }
    }


    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Properties" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.propertyPermission=true
      }
      if(permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.checklistPermission=true
      }
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.insurancePermission=true
      }
      if(permission[i]['subModuleName'] == "Businesse" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.businessPermission=true
      }
      if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.departMentPermission=true
      }
      if(permission[i]['subModuleName'] == "Surveys" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.surveyPermission=true
      }
      if(permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.userTeamsPermission=true
      }
      if(permission[i]['subModuleName'] == "Settings" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.settingPermission=true
      }
    }
    // console.log(this.role == 1)
  } 
  navigateTo(rout){
    if(rout=="user-index"){
      localStorage.setItem("userIndexPagination",1+"");
    }else if(rout=="property-index"){
      localStorage.setItem("propertyIndexPagination",1+"");
    }else if(rout=="department-list"){
      localStorage.setItem("departmentListPagination",1+"");
    }else if(rout=="survey-index"){
      localStorage.setItem("surveyIndexPagination",1+"");
    }else if(rout=="insurance-list"){
      localStorage.setItem("insuranceListPagination",1+"");
    }else if(rout=="roles-index"){
      localStorage.setItem("rolesIndexPagination",1+"");
    }else if(rout=="business-index"){
      localStorage.setItem("businessIndexPagination",1+"");
    }
    this.router.navigate([rout])
  }
  goBack():void{
    this._location.back();
  }


  getRoles(){
    // console.log(this.apiService.roleId)
    return parseInt(this.apiService.role);
  }
}
