import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { compileComponentFromMetadata } from '@angular/compiler';
import { FormBuilder, Validators } from '@angular/forms';
import { DateRange } from 'igniteui-angular';
import * as moment from 'moment';
import { Console } from 'console';


@Component({
  selector: 'app-preventive-maintenace',
  templateUrl: './preventive-maintenace.component.html',
  styleUrls: ['./preventive-maintenace.component.css']
})
export class PreventiveMaintenaceComponent implements OnInit {
  public range: DateRange = { start: new Date(), end: new Date(new Date().setDate(new Date().getDate() + 5)) };
  constructor(public route: Router, public apiService: ApiService, public formBuilder: FormBuilder, private router: ActivatedRoute) { }
  assetId
  listBy = 'today'

  pageSize = 10
  selectedFiler = { name: null, dueDate: null, assignedTo: null, }

  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true
  }

  assetDetails
  setBudgetForm = this.formBuilder.group({
    budget: ['',
      [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.maxLength(8),
      ]
    ],
    budgetType: '',
    authId: this.apiService.authId
  });

  setBudgetPermission = false
  createTemplate = false
  viewPmTempaletPermission = false
  editPmTemplatePermission = false
  deleteTemplatePermission = false
  notifyAddPermission = false;
  partsFeaturePermission = false;
  partsPermission = true;
  ngOnInit(): void {
    this.assetId = this.route.url.split("/")
    this.assetId = this.assetId[2]
    // console.log('AssetId', this.assetId);
    if (localStorage.getItem("overdue")) {
      this.listBy = localStorage.getItem("overdue");
    }

    this.getPMsList()
    this.getPmTemplates()
    this.getAssetDetails()
    this.getBusinessList()
    // this.uploadFile()

    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
      // idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      // this.statusList.splice(idx1,1)
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 3);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Set Budget" && permission[i]['actionStatus'] == 1) {
        this.setBudgetPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Create Template" && permission[i]['actionStatus'] == 1) {
        this.createTemplate = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.editPmTemplatePermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.viewPmTempaletPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Delete" && permission[i]['actionStatus'] == 1) {
        this.deleteTemplatePermission = true
      }
      if (permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.notifyAddPermission = true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true
    }

  }
  assignedFilter = false;
  frequencyList = []
  responseType = []
  templateList = []
  pmList = []
  statusGroup = []
  propertyList = []
  refresh(): void {
    window.location.reload();
  }

  userList = []
  filterUserList = []
  filterUserSearch
  // propertFilter= false;
  filterUser(ev) {
    // console.log(ev)
    this.userList = this.filterUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }




  update() {
    this.getPMsList()
    this.getPmTemplates()
  }

  sortSelected = "name"
  sort = 'asc'
  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getPMsList()
  }


  getPMsList(): void {
    // this.listBy = status;
    let getListBody = {
      assetId: this.assetId,
      sortBy: this.sortSelected,
      sortType: this.sort,
      listType: this.listBy,
      pageNo: this.activePage,
      limit: this.pageSize,
      name: this.selectedFiler.name,
      assignedTo: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.id : null,
      // propertyId:this.selectedFiler.property ?this.selectedFiler.property.id : null,
      dueDate: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:MM:SS') : null,

    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-schedules/index', {}, getListBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.pmList = []
      this.pmList = result['data']

      if (this.listBy == 'finished') this.pmList.map(x => x.completedOn = x.completedOn.substring(0, 10))
      // console.log(this.pmList);
      this.setPaginationArray(result)
    })
  }

  getPmUList(): void {
    // this.listBy = status;
    let getListBody = {
      assetId: this.assetId,
      sortBy: this.sortSelected,
      sortType: this.sort,
      listType: this.listBy,
      pageNo: this.activePage,
      limit: this.pageSize,
      name: this.selectedFiler.name,
      assignedTo: this.selectedFiler.assignedTo ? this.selectedFiler.assignedTo.id : null,
      dueDate: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:MM:SS') : null,
      "roleId": this.apiService.roleId,
      "businessId": this.apiService.buisnessId,
      "userId": this.apiService.userId,

    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/index', {}, getListBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.pmList = []
      this.pmList = result['data']
      // console.log("Template : ", this.pmList);
      this.setPaginationArray(result)
    })
  }

  filterpropertyList
  getPmTemplates(): void {
    let templetBody = {
      assetId: this.assetId,
    }
    // this.apiService.startLoader()
    this.apiService.postCall('pm-templates/index', {}, templetBody).subscribe((result: any[]) => {
      // this.apiService.stopLoader()
      this.templateList = []
      this.frequencyList = []
      this.responseType = []
      this.statusGroup = []
      this.templateList = result['data']
      this.frequencyList = result['frequency']
      this.responseType = result['responseType']
      this.statusGroup = result['statusGroup']
      this.propertyList = result['propertyList']
      this.filterpropertyList = result['propertyList']

      // console.log("Template : ", this.templateList);
    })
  }

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    this.getPMsList()
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { name: null, dueDate: null, assignedTo: null, }
    this.getPMsList()
  }


  getAssetDetails(): void {
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/' + this.assetId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
      // console.log("Asset Details : ", this.templateList);


      // this.apiService.startLoader()

      let obj = {
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "outletId": result['assetDetails']['propertyId'] ? result['assetDetails']['propertyId'] : "",
      }

      this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + result['assetDetails']['propertyId'], {}, obj).subscribe((result: any[]) => {
        // this.apiService.stopLoader()

        this.userList = result['data'];
        this.filterUserList = result['data'];
        // console.log("List of Users", this.userList);

      })
    })
  }

  statusSelection
  selectStatus(d) {


    // console.log("++++++++++++++++++")
    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }
  editSchedule(d) {
    this.route.navigate(['/pm-templates/edit/' + d.id])
  }

  deleteTemplate(id): void {
    // var response = confirm("Are you sure you want to delete this Template?");

    let deleteBody = {
      authId: this.apiService.authId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('pm-templates/delete/' + id, {}, deleteBody).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      // if(result['success'])this.message ='Maintenance budget added successfully';
      this.message = result["msg"];
      this.getPmTemplates();

      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      // if(result['success'] == true){
      //   alert(result['msg']);
      //   this.getPmTemplates();
      // }else{
      //   alert(result['msg'])
      // }
    })

  }


  slecdtedTemplateForDelet: any
  message: any;
  updateBudget(): void {

    // let budgetForm = this.setBudgetForm.value;
    // var budgetType = 0;
    // if(budgetForm.budgetType == true){
    //   budgetType = 1
    // }
    // budgetForm.budgetType = budgetType;
    let body = {
      budget: this.setBudgetForm.value.budget,
      budgetType: this.setBudgetForm.value.budgetType ? 1 : 0,
      authId: this.apiService.authId
    }
    // // console.log('Budget Form', budgetForm);
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.assetId, {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // console.log(result["msg"])
     // if (result['success']) this.message = 'Budget set for '+ this.assetDetails.assetName+' successfully';
      if (result['success']) this.message = 'Maintenance budget added successfully';
      else this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getAssetDetails()
    })

  }


  get budget() { return this.setBudgetForm.get('budget') }

  paginationArray = []

  uploadFile() {
    let body = {
      pmName: 'Testing',
      outletId: 71,
      assetManagementId: 15,
      scheduleId: "2",
      businessId: 26,
      startDate: "2021-9-17 12:14:00",
      endDate: "2022-9-17 12:00:00",
      assignedTo: ["1"],
      checklist: [{ name: 'Test checkList', response: 1 }],

      authId: this.apiService.userId

    }

    // console.log(body)
    this.apiService.startLoader()
    this.apiService.postCall('pms/add', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

    })
  }

  // edit(){
  //   (<HTMLInputElement>document.getElementById('openSuccess')).click();
  // }

  activePage = 1
  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets=[]
      this.activePage = d
      // console.log(d)
      this.getPMsList()
    }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    // this.selectedAssets=[]
    // this.pageSize = d
    this.getPMsList()
  }



  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFilesAsset = file



    }

    let testData: FormData = new FormData();


    // // console.log(obj)
    testData.append('image', this.uploadedFilesAsset);

    // status:this.assetDetailsForm.value.status,



    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetails()
      document.getElementById('dismissPopUp').click()

    })
  }


  changeStatus() {
    let testData: FormData = new FormData();


    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);

    testData.append("status", this.assetDetails.status);



    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetails()
      document.getElementById('dismissPopUp').click()

    })
  }
  statusList = []

  filterSelect: boolean
  //  closeAll(){}

  applyFlag
  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.assignedTo != null) length = length + 1
    if (this.selectedFiler.dueDate != null) length = length + 1
    // if(this.selectedFiler.property != null) length = length+1
    // if(this.selectedFiler.business != null) length = length+1

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      //     this.assetFilter= false;
      // this.locationFilter= false;
      // this.businessFilter= false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
  }
  closeAll() {
    this.assignedFilter = false;
    // this.locationFilter= false;
    this.businessFilter = false;
    this.propertFilter = false;
  }


  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    // this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      // this.apiService.stopLoader()

      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']



    })
  }

  // getPropertyList(){
  //   this.apiService.startLoader()
  //   this.apiService.getCall('outlets/outlet-list/'+this.selectedFiler.business.id,{}  ).subscribe((result: any[])=>{
  //   // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
  //     // console.log(result);
  //     result['data']
  //     this.apiService.stopLoader()

  //   this.propertyList = result['data']
  //   this.filterpropertyList = result['data']



  // })



  // }



  filterPropertySearch
  // propertFilter= false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;

  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  getUserName(data) {
    let str = ''
    // console.log(data)
    if (typeof data != undefined && data != 'undefined') {
      for (let d of data) {
        str += (str != '') ? ', ' + d.userName : '' + d.userName
        // console.log(str)
      }
    }

    return str
  }

}

