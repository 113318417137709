import { Component,HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-business-admin-index',
  templateUrl: './business-admin-index.component.html',
  styleUrls: ['./business-admin-index.component.css']
})
export class BusinessAdminIndexComponent implements OnInit {

  businessId: any;

  constructor(
    public route: Router,
    public apiService:ApiService,
    public _location: Location,
    public fb:FormBuilder,
  ) { }
  showAddMenu = false;
// pagination Veriables
  paginationArray = [];
  activePage = 1;
  pageSize = 10;
  totalnoOfPages;
  saveData = false;
  message:any;
  pageSizeArchive = 10
  activePageArchive = 1
  paginationArrayArchive = []
  totalnoOfPagesArchive

// array list veriables
  activeList: any;
  archiveList: any;
  roleList:any;
  propertyList:any;
  businessList:any;

// Sorting variables
  sortType = "desc";
  sortBy = 'userId';
    
// Filter Object
  selectedFiler={userName:null,property:null , business: null,role:null}
  applyFlag=false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role:any;

  tabSelected='admin';
  statusSelection:any;

  userForm = this.fb.group({
    id:[null,Validators.required],
    fullName:[null , Validators.required],
    mobile:[null,[Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
  });

  ngOnInit(): void {
    this.businessId = this.route.url.split("/")[2];
    this.getUsers();
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.userName != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.role != null) length = length + 1

    if(length == 0 || !this.applyFlag) return "(None Selected)"
    else if(this.applyFlag) return `(${length} Filter Selected)`
  }

  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log("Pagination Active : ",this.paginationArray)
  }

  applyFilter(){
    this.applyFlag=true;
    this.activePage= 1;
    this.getUsers();
  }

  clear() {
    this.selectedFiler = {userName:null,property:null , business: null,role:null}
      this.getUsers();
    
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      this.getUsers();
    }
  }

  changePage(ev) {
    this.getUsers();
  }

  getUsers(): void {
      const body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
        role: this.tabSelected == 'admin'? 2: '',
      }

      this.apiService.startLoader()
      this.apiService.postCall('users/allUsers/'+this.businessId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.activeList = []
        this.activeList = result['data']['data'];
        this.roleList = result['roles'];
        // console.log("Roles : ",this.roleList);
        this.setPaginationArray(result)
      })
  }


  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    if(btn.id == "changBtn"){
    }else if(btn.id != "changBtn"){
      this.statusSelection =undefined
    }
    
  }

  changeStatus(id:any,status): void{
    this.apiService.startLoader()
    this.apiService.getCall('users/deactivateUser/'+id+'/'+status, {}).subscribe((result: any[]) => {
      this.saveData = result['success'];
      this.message = result['msg'];
      this.apiService.stopLoader();
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }

  closeAll() {
    this.propertyFilter = false;
    this.roleFilter = false;
    this.businessFilter = false;
  }

  selectStatus(d){
    // console.log(d);
    if('status'+d.id != this.statusSelection ) this.statusSelection = 'status'+d.id
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }

  getUserData(d:any){
    this.userForm = this.fb.group({
      id:[d.id,Validators.required],
      fullName:[d.fullName , Validators.required],
      mobile:[d.mobile,[Validators.required,Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
    });
  }

  editUser(){
    let formData = this.userForm.value;
    let userId = formData.id;
    this.apiService.startLoader();
    this.apiService.postCall('users/edit/'+userId, {},formData).subscribe((result: any[]) => {
      // console.log("Data Saved :",result);
      this.apiService.stopLoader();
      this.saveData = result['success'];
      this.message = result['msg'];
      if(result['success'] == false){
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        this.getUsers();
      }
      
    });
  }

  getValidation(){
    return (this.userForm.valid);
  }


  dropButton=false;
}
