import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from '../../../common/services/apiCalls.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-permit-tenant-add',
  templateUrl: './permit-tenant-add.component.html',
  styleUrls: ['./permit-tenant-add.component.css']
})
export class PermitTenantAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  message;
  propertyList = [];
  dropdownSettings?: IDropdownSettings;
  businessId;
  routeId;
  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    private route: ActivatedRoute,
    public fb: FormBuilder,
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });
    this.tenantForm = this.fb.group({
      businessId: [null],
      fullName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      password: [null, this.routeId ? null : Validators.required],
      cpassword: [null, this.routeId ? null : Validators.required],
      roleId: 6,
      outletId: [null, Validators.required],
      modifiedBy: [null],
      authId: this.apiService.authId
    });
    this.getPropertyList();
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
  }

 
  goBack() {
    this.router.navigate(['/permit-setting/tenant-list']);
  }

  save(): void {
    if(!this.routeId){
      this.apiService.startLoader();
      this.tenantForm.value.businessId = this.apiService.buisnessId;
      this.tenantForm.value.roleId = 6;
      this.tenantForm.value.modifiedBy = this.apiService.userId;
      // for (let i = 0; i < this.tenantForm.value.outletId.length; i++) {
      //   this.tenantForm.value.outletId[i] = this.tenantForm.value.outletId[i].id;
      // }
      let formData = this.tenantForm.value;
      this.apiService.postCall('users/add', {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    }else{
      this.apiService.startLoader();
      this.tenantForm.value.businessId = this.apiService.buisnessId;
      this.tenantForm.value.roleId = 6;
      this.tenantForm.value.modifiedBy = this.apiService.userId;
      // for (let i = 0; i < this.tenantForm.value.outletId.length; i++) {
      //   this.tenantForm.value.outletId[i] = this.tenantForm.value.outletId[i].id;
      // }
      let formData = this.tenantForm.value;
      this.apiService.postCall('users/edit/'+this.routeId, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        
      });
    }



  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  getPropertyList() {
    this.apiService.startLoader();
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {},{
        'roleId': this.apiService.roleId,
        'userId': this.apiService.authId,
        'businessId': this.businessId
      }
    ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      if(this.routeId){
        this.getByIdDetails();
      }
      this.propertyList = result['data'];
      // console.log(this.propertyList);
    });
  }
  getValidation() {
    if ((this.tenantForm.value.password != this.tenantForm.value.cpassword) && !this.routeId) {
      return false;
    }
    return (this.tenantForm.valid);
  }
  getByIdDetails() {
    this.apiService.startLoader();
    this.apiService.postCall('users/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      this.tenantForm.patchValue(result['data']);
    });
  }
  backClicked() {
    this.router.navigate(['/permit-setting/tenant-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
