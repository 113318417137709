import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-sub-user-edit',
  templateUrl: './sub-user-edit.component.html',
  styleUrls: ['./sub-user-edit.component.css']
})

export class SubUserEditComponent implements OnInit {

  constructor(
    public route: Router,
    public apiService:ApiService,
    public storedData:StoredData,
    public formBuilder:FormBuilder,
    public _location:Location,
  ) { }
  subUserId:any;

  userForm = this.formBuilder.group({
    fullName:[''],
    mobile:[''],
  });

  ngOnInit(): void {
    this.subUserId = this.route.url.split("/")[2];
    this.getSubUserDetails();
  }

  getSubUserDetails():void{
    this.apiService.getCall('/SubUsers/view/'+this.subUserId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      if(result['success'] == true){
       // console.log("User Data :",result['data']);
       let userDetails = result['data'];
       this.userForm = this.formBuilder.group({
        fullName:userDetails.full_name,
        mobile:userDetails.mobile,
      })
      }else{
        this.goBack();
      }
    });
  }

  message
  saveUser():void{
    let formData = this.userForm.value;
    // console.log("Form Data : ",formData);
    this.apiService.postCall('SubUsers/edit/'+this.subUserId, {},formData).subscribe((result: any[]) => {
      // console.log("Data Saved :",result);
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      if(result['success'] == true){
        this.goBack();
      }
    });
  }

  goBack():void{
    this._location.back();
  }
}
