import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
//import { OneSignal } from 'onesignal-ngx';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.css']
})
export class LoginComponentComponent implements OnInit {

  searchPromise2 = null;
  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { //public on:OneSignal
  }
  fullScreen: false;

  ngOnInit(): void {
    setTimeout(() => {
      if (localStorage.getItem("accessToken") && localStorage.getItem("accessToken") != null) {
        if (localStorage.getItem('businessDetailsApiService') && localStorage.getItem("businessDetailsApiService") != null) {
          this.route.navigate(['/dashboard-ceo'])
        } else {
          localStorage.clear();
        }
      }
    }, 1000);
    if (this.rememberMe) {
      if (localStorage.getItem("userCred")) {
        JSON.parse(localStorage.getItem("userCred"))
        this.username = JSON.parse(localStorage.getItem("userCred")).email
        this.password = JSON.parse(localStorage.getItem("userCred")).password
      }
    }
  }

  saveToken(obj) {
    this.apiService.postCall('users/saveOnesignalToken', {}, obj).subscribe((result: any[]) => { });
  }
  username: any;
  password: any;

  getBusinessList() {
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      result['data']
      this.route.navigate(['/home'])
    },
      error => {
        this.apiService.stopLoader()
      });
  }

  invalidCredFlag: boolean;
  assetViewPermission = true
  inspectionPermission = true
  ticketPermission = true
  partsPermission = true
  maintanencePermission = true
  dashboardPermission = true
  meterPermission = true
  reportsPermission = true
  msg = "Invalid Credentials"
  login() {
    let body = {
      email: this.username,
      password: this.password,
      from: 1
    }
    this.apiService.startLoader()
    this.apiService.loginAPi('users/login', {}, body).subscribe((result: any[]) => {
      // console.log(result)
      this.apiService.stopLoader()
      if (result['success']) {
        if (this.rememberMe) {
          localStorage.setItem("userCred", JSON.stringify(body))
        }
        localStorage.setItem("settings", result['setting'])
        this.apiService.token = result['data'].token
        this.apiService.buisnessId = result['user'].business_id
        this.apiService.userId = result['user'].id
        this.apiService.outletId = result['user'].outlet_id
        this.apiService.authId = result['user'].id,
          this.apiService.roleId = result['user'].role,
          this.apiService.userType = result['user'].user_type,
          this.apiService.userName = result['user'].full_name,
          this.apiService.buisnessLogo = result['user'].businessLogoPath,
          this.apiService.buisnessName = result['user'].buisnessName,
          this.apiService.permit_start_time = result['result'].businessDetails.permit_start_time,
          this.apiService.permit_end_time = result['result'].businessDetails.permit_end_time,
          localStorage.setItem('accessToken', JSON.stringify(result['data'].token))
        localStorage.setItem('businessFeatures', JSON.stringify(result['result']['businessPermission']))
        localStorage.setItem('userDetails', JSON.stringify(result['user']))
        localStorage.setItem('businessDetailsApiService', JSON.stringify(result['result'].businessDetails))
        localStorage.setItem('businessPermission', JSON.stringify(result['result']['businessPermission']));
        localStorage.setItem('currency', result['currency']);
        localStorage.setItem('zone', result['zone']);
        //var url = "/dashboard-checklist";
        var url = "/dashboard-ceo";
        if (result['permissions'] && result['permissions']['permissionData']) {
          localStorage.setItem('permissions', JSON.stringify(result['permissions']['permissionData']));
          let permission: any;
          permission = JSON.parse(localStorage.getItem('permissions'));
          // console.log(permission);
          for (var i = 0; i < permission.length; i++) {
            if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.assetViewPermission = true
            }
            if (permission[i]['permissionModuleId'] == 1 && permission[i]['subModuleName'] == "Inspection" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.inspectionPermission = true
            }
            if (permission[i]['permissionModuleId'] == 2 && permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.ticketPermission = true
            }
            if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.partsPermission = true
            }
            if (permission[i]['permissionModuleId'] == 4 && permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.maintanencePermission = true
            }
            if (permission[i]['permissionModuleId'] == 6 && permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.meterPermission = true
            }
            if (permission[i]['permissionModuleId'] == 7 && permission[i]['subModuleName'] == "Dashboard" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.dashboardPermission = true
            }
            if (permission[i]['permissionModuleId'] == 8 && permission[i]['subModuleName'] == "Reports" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
              this.reportsPermission = true
            }
          }
          if (this.apiService.roleId == 4) {
            url = "/ticket-main-list";
          }
          if (this.dashboardPermission) {
          } else if (this.reportsPermission) {
            url = "/reports";
          } else if (this.inspectionPermission) {
            url = "/inspection-list";
          } else if (this.ticketPermission) {
            url = "/ticket-main-list";
          } else if (this.assetViewPermission) {
            url = "/home";
          } else if (this.partsPermission) {
            url = "/parts-inventory";
          } else if (this.maintanencePermission) {
            url = "/pm-index";
          } else if (this.meterPermission) {
            url = "/meter-list";
          } else {
            url = "/setup";
          }
          if (this.apiService.roleId == 6) {
            url = "/permits-list";
          }
        }
        if (localStorage.getItem("onsignalToken")) {
          let obj = {
            "token": localStorage.getItem("onsignalToken"),
            "userId": this.apiService.userId
          }
          this.saveToken(obj);
        } else {
          var OneSignal = window['OneSignal'] || [];
          OneSignal.sendTag("tech", "test").then(() => {
            var userId = this.apiService.userId;
            var t = this;
            OneSignal.getUserId(function (ids) {
              let obj = {
                "token": ids,
                "userId": userId
              }
              t.saveToken(obj);
              t.route.navigate([url])
            });
          });
        }
        this.route.navigate([url])
      }
      else {
        if (result['msg']) {
          this.msg = result['msg']
        }
        this.invalidCredFlag = true;
      }
    },
      error => {
        this.apiService.stopLoader()
        this.invalidCredFlag = true;
      });


  }


  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(regularExpression.test(String(email).toLowerCase()))
    return regularExpression.test(String(email).toLowerCase());
  }
  errorFlag: boolean
  sendEmail() {
    this.errorFlag = true
    if (this.validateEmail(this.emailAddress)) this.emailReport()
    else {
      // console.log("invalid email")
    }
  }

  message
  emailAddress: any;
  // errorFlag:boolean;
  // message:any;
  emailReport() {
    let obj = {
      email: this.emailAddress
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/forgotPasswordEmail', {}, obj).subscribe((result: any[]) => {
      console.log(result);
      // window.open(result['data'], "_blank");
      this.errorFlag = false
      this.emailAddress = undefined
      this.apiService.stopLoader();
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('cancelEmailPopup')).click();

      //this.message = "Reset Password Email send sccessfully";
      (<HTMLInputElement>document.getElementById('validationButton')).click();
    })
  }

  rememberMe: boolean = true;
  onSomeAction(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  @HostListener('click', ['$event.target'])
  // onKey(event: any) {

  // }

  onKey(event: KeyboardEvent) { // with type info
    ;
    // this.values += (event.target as HTMLInputElement).value + ' | ';
  }
}
