import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from '../../common/services/apiCalls.service';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-permit-add',
  templateUrl: './permit-add.component.html',
  styleUrls: ['./permit-add.component.css'],
})
export class PermitAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  userDropdownSettings?: IDropdownSettings;
  userList = [];
  businessId = 0;
  propertyList = [];
  permitList = [];
  associtatedPermitList = [];
  routeId;
  fileName;
  uploadedFiles;
  questionActive = {};
  questionList = [];
  selectedQuestionList = [];
  signatureFile;
  signatureFileUrl;
  signatureBoolean = false;
  todayDate: any = new Date();
  todayDateTime: any = new Date();
  undertakingText = ''
  @ViewChild('canvas') public canvas: ElementRef;
  private cx: CanvasRenderingContext2D;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const date = new Date();
    document.body.style.overflow = 'hidden';
    this.tenantForm = this.fb.group({
      buildingName: [null],
      outletId: [null, [Validators.required]],
      permitNo: [null, [Validators.required]],
      date: [date, [Validators.required]],
      tenant: [null, [Validators.required]],
      location: [null, [Validators.required]],
      requestorDesignation: [null, [Validators.required]],
      vendorId: [null],
      noOfStaff: [null, [Validators.required]],
      contactNo: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      nameOfSupervisor: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      permitId: [null, [Validators.required]],
      description: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      associated: [null, [Validators.required]],
      questions: [null],
      bRestored: [null],
      location_2: [null],
      requirementDate: [null],
      requirementTime: [null],
      amountOfSpaceRequired: [null],
      descItemTobeStored: [null],
      shutdownAt: [null],
      extraStartDate: [null],
      extraStartDate2: [null],
      extraStartTime: [null],
      extraStartTime2: [null],
      undertaking: [null],
      signatures: [null],
      imageUrl: [null],
      authId: [null],

    });
    if (this.apiService.roleId == 6) {
      this.tenantForm.patchValue({ tenant: this.apiService.authId, outletId: this.apiService.outletId })
      this.onItemSelect2(this.apiService.outletId);
    }
    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'outletName',
      allowSearchFilter: true
    };
    this.userDropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };

    this.getPropertyList();
    // if (this.apiService.outletId) {
    //   this.onItemSelect({id: this.apiService.outletId});
    // }
    // this.drawSignature();


  }

  ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    // set the width and height
    // canvasEl.width = this.width;
    // canvasEl.height = this.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }

  goBack(): void {
    this._location.back();
  }


  save(): void {

    let idx = this.propertyList.find(x => x.id == this.tenantForm.value.outletId);
    var bulName = idx?.outletName;
    let startDateTime = new Date(this.tenantForm.value.startDate)
    startDateTime.setHours(new Date(this.tenantForm.value.startTime).getHours())
    startDateTime.setMinutes(new Date(this.tenantForm.value.startTime).getMinutes())
    startDateTime.setSeconds(new Date(this.tenantForm.value.startTime).getSeconds())
    // console.log(startDateTime)

    let endDateTime = new Date(this.tenantForm.value.endDate)
    endDateTime.setHours(new Date(this.tenantForm.value.endTime).getHours())
    endDateTime.setMinutes(new Date(this.tenantForm.value.endTime).getMinutes())
    endDateTime.setSeconds(new Date(this.tenantForm.value.endTime).getSeconds())

    let startDateTimeCheck = new Date(this.tenantForm.value.startDate)
    startDateTimeCheck.setHours(new Date(this.tenantForm.value.startTime).getHours())
    startDateTimeCheck.setMinutes(new Date(this.tenantForm.value.startTime).getMinutes())

    let endDateTimeCheck = new Date(this.tenantForm.value.endDate)
    endDateTimeCheck.setHours(new Date(this.tenantForm.value.endTime).getHours())
    endDateTimeCheck.setMinutes(new Date(this.tenantForm.value.endTime).getMinutes())
    if (startDateTimeCheck >= endDateTimeCheck) {
      alert('Please select end time greater than start time');
    } else {
      if (!this.routeId) {
        var form = new FormData();
        form.append('buildingName', bulName);
        form.append('outletId', this.tenantForm.value.outletId ? this.tenantForm.value.outletId : '');
        form.append('permitNo', this.tenantForm.value.permitNo);
        form.append('date', this.tenantForm.value.date);
        form.append('tenant', this.tenantForm.value.tenant ? this.tenantForm.value.tenant : '');
        form.append('location', this.tenantForm.value.location);
        form.append('requestorDesignation', this.tenantForm.value.requestorDesignation);
        form.append('vendorId', this.tenantForm.value.vendorId);
        form.append('noOfStaff', this.tenantForm.value.noOfStaff);
        form.append('contactNo', this.tenantForm.value.contactNo);
        form.append('nameOfSupervisor', this.tenantForm.value.nameOfSupervisor);
        form.append('email', this.tenantForm.value.email);
        form.append('permitId', this.tenantForm.value.permitId ? this.tenantForm.value.permitId : '');
        if (this.tenantForm.value.description) {
          form.append('description', this.tenantForm.value.description);
        } else {
          form.append('description', '');
        }
        form.append('startDate', moment(startDateTime).format('YYYY-MM-DD HH:mm:ss'));
        form.append('endDate', moment(endDateTime).format('YYYY-MM-DD HH:mm:ss'));
        form.append('associated', this.tenantForm.value.associated);
        for (let i = 0; i < this.selectedQuestionList.length; i++) {
          form.append('questions[' + this.selectedQuestionList[i].questionId + '][' + this.selectedQuestionList[i].subQuestionId + ']', this.selectedQuestionList[i].status);
        }
        if (this.uploadedFiles && this.uploadedFiles != '' && this.uploadedFiles != 'undefined') {
          // for (let i = 0; i < this.uploadedFiles.length; i++) {
          //   form.append('imageUrl[' + i + ']', this.uploadedFiles[i]);
          // }
          form.append('imageUrl[0]', this.uploadedFiles);
        }
        if (this.signatureFileUrl) {
          form.append('signatures', this.signatureFileUrl);
        }
        form.append('undertaking', this.undertakingText);
        form.append('authId', this.apiService.authId);
        this.apiService.startLoader();
        this.apiService.postCall('PermitRequests/add', {}, form).subscribe((result: any[]) => {
          // console.log(result);
          this.apiService.stopLoader();
          // this.saveData = result['success'];
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          if (result['success']) {
            this.backClicked();
          }
        }, error => {
          this.apiService.stopLoader();
        });
      } else {
        var form = new FormData();
        form.append('buildingName', bulName);
        form.append('outletId', this.tenantForm.value.outletId);
        form.append('permitNo', this.tenantForm.value.permitNo);
        form.append('date', this.tenantForm.value.date);
        form.append('tenant', this.tenantForm.value.tenant);
        form.append('location', this.tenantForm.value.location);
        form.append('requestorDesignation', this.tenantForm.value.requestorDesignation);
        form.append('vendorId', this.tenantForm.value.vendorId);
        form.append('noOfStaff', this.tenantForm.value.noOfStaff);
        form.append('contactNo', this.tenantForm.value.contactNo);
        form.append('nameOfSupervisor', this.tenantForm.value.nameOfSupervisor);
        form.append('email', this.tenantForm.value.email);
        form.append('permitId', this.tenantForm.value.permitId);
        form.append('description', this.tenantForm.value.description);
        // form.append('startDate', this.tenantForm.value.startDate);
        // form.append('endDate', this.tenantForm.value.endDate);
        form.append('startDate', moment(startDateTime).format('YYYY-MM-DD HH:mm:ss'));
        form.append('endDate', moment(endDateTime).format('YYYY-MM-DD HH:mm:ss'));
        form.append('associated', this.tenantForm.value.associated);
        form.append('questions', this.tenantForm.value.questions);
        form.append('signatures', this.tenantForm.value.signatures);
        form.append('imageUrl', this.tenantForm.value.imageUrl);
        form.append('authId', this.apiService.authId);
        this.apiService.startLoader();
        this.apiService.postCall('/PermitRequests/edit/' + this.routeId, {}, form).subscribe((result: any[]) => {
          // console.log(result);
          this.apiService.stopLoader();
          // this.saveData = result['success'];
          this.message = result['msg'];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          if (result['success']) {
            this.backClicked();
          }
        }, error => {
          this.apiService.stopLoader();
        });
      }
    }

  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  getValidation() {
    // console.log(this.tenantForm)
    return this.tenantForm.valid;
  }

  onClick(item, i?, j?) {
    var array = [];
    array.push({ item: item, boolean: i });
    // this.tenantForm.value.question.push()
    // this.tenantForm.value.question.push()
    Object.keys(this.questionActive).forEach(h => {
      // console.log('000', h);
      this.questionActive[h] = false;
    });
    this.questionActive[item] = true;
    // console.log(item, this.questionActive[item + i]);
  }

  getPropertyList() {
    this.apiService.startLoader();
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.authId,
      'businessId': this.apiService.buisnessId
    }
    ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      if (this.routeId) {
        this.getByIdDetails();

      }
      // this.groups = result['groups']

      // console.log(this.propertyList);
    });
  }

  getByIdDetails() {
    this.apiService.startLoader();
    this.apiService.postCall('PermitRequests/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {
      this.onItemSelect2({ id: result['data'].outletId });
      const find = this.propertyList.find(p => p.id == result['data'].outletId);
      result['data'].outletId = [{ id: find.id, outletName: find.outletName }];
      this.apiService.stopLoader();
      this.tenantForm.patchValue(result['data']);

    });
  }
  setDateTimeFunction() {
    if (moment(this.tenantForm.value.startDate).format('YYYY-MM-DD') == moment(this.todayDate).format('YYYY-MM-DD')) {
      this.todayDateTime = new Date();
    } else {
      this.todayDateTime = this.tenantForm.value.startDate;
    }
  }
  getDoneSignature() {
    const imageURL = this.canvas.nativeElement.toDataURL();
    // Convert canvas image to Base64
    this.signatureFile = this.dataURItoBlob(imageURL);
    this.signatureFileUrl = imageURL;
    this.signatureBoolean = true;
  }
  clearSignature() {
    this.cx.clearRect(0, 0, 300, 100);

  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number, y: number },
    currentPos: { x: number, y: number }
  ) {
    // incase the context is not set
    if (!this.cx) {
      return;
    }

    // start our drawing path
    this.cx.beginPath();

    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from

      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);

      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  }

  onItemSelect(event) {
    var t = event.target.value
    this.onItemSelect2(t);
  }
  onItemSelect2(id) {
    this.apiService.startLoader();
    this.apiService.getCall('permits/applicationFormFilter/' + id).subscribe((result: any[]) => {

      this.apiService.stopLoader();
      this.tenantForm.patchValue({ permitNo: result['permit_no'] });
      this.userList = result['tenants'];
      /* var mapTenants = Object.entries(result['tenants']);
       if (mapTenants) {
         this.userList = mapTenants.map(dat => {
           return {
             id: dat[0],
             name: dat[1]
           };
         });
       }*/
      this.permitList = result['permits'];
      /*var mapPermitss = Object.entries(result['permits']);
      if (mapPermitss) {
        this.permitList = mapPermitss.map(dat => {
          return {
            id: dat[0],
            name: dat[1]
          };
        });
      }*/
    });
  }

  onItemSelectPermit(item: any, results?: any) {
    item = item.target.value
    this.apiService.startLoader();
    this.apiService.getCall('permits/applicationFormFilter/' + this.tenantForm.value.outletId + '/' + item)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.associtatedPermitList = result['associated_permits'];
        this.undertakingText = result['selected_permits']['under_taking'];
        /*var mapPermitss = Object.entries(result['associated_permits']);
        if (mapPermitss) {
          this.associtatedPermitList = mapPermitss.map(dat => {
            return {
              id: dat[0],
              name: dat[1]
            };
          });
        }*/
      });
  }

  onItemSelectAssociatedPermit(item: any, results?: any) {
    this.apiService.startLoader();
    item = item.target.value
    this.apiService.getCall('permits/checkpoints/' + this.tenantForm.value.permitId + '/' + item)
      .subscribe((result: any[]) => {
        this.apiService.stopLoader();
        this.questionList = result['questions'];
        if (this.questionList.length) {
          for (let i = 0; i < this.questionList.length; i++) {
            for (let j = 0; j < this.questionList[i].sub_questions.length; j++) {
              if (this.questionList[i].sub_questions[j].id) {
                this.selectedQuestionList.push({ questionId: this.questionList[i].id, subQuestionId: this.questionList[i].sub_questions[j].id, status: -1 });
              }
            }
          }
        }
      });
  }

  passSubQuestion(questionId, subQuestionId, status, event?) {
    console.log(this.selectedQuestionList);
    if (this.selectedQuestionList.length == 0) {
      this.selectedQuestionList.push({ questionId: questionId, subQuestionId: subQuestionId, status: status });
    } else {
      var checkBoolean = this.selectedQuestionList.some(value => {
        return ((value.subQuestionId == subQuestionId) && (value.questionId == questionId));
      });
      if (!checkBoolean) {
        this.selectedQuestionList.push({ questionId: questionId, subQuestionId: subQuestionId, status: status });
      } else {
        for (let i = 0; i < this.selectedQuestionList.length; i++) {
          if ((this.selectedQuestionList[i].questionId == questionId) && (this.selectedQuestionList[i].subQuestionId == subQuestionId)) {
            this.selectedQuestionList[i].status = status;
          }
        }
      }
    }
    // console.log(this.selectedQuestionList);
  }

  backClicked() {
    this.router.navigate(['/permits-list']);
  }

  fileChange(event) {


    // console.log(event);

    if (event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/jpeg') {
      var span = document.getElementById('images');
      var img = document.createElement('img');
      img.src = URL.createObjectURL(event.target.files[0]);
      img.id = 'firstId';
      if (document.getElementById('firstId')) {
        document.getElementById('firstId').remove();
      }
      span.appendChild(img);

      // span.removeChild(img);
      img.style.height = '88px';
      // console.log(event);


      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file);
        file = file;
        this.uploadedFiles = file;
        this.fileName = file.name;
      }
    } else {

      this.message = 'File with format ' + event.target.files[0].type + ' not acceptable, please choose images only';


      (<HTMLInputElement>document.getElementById('openError')).click();
    }
  }

  removeImage() {
    this.fileName = null;
    this.uploadedFiles = [];
    if (document.getElementById('firstId')) {
      document.getElementById('firstId').remove();
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
