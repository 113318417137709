import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-pm-view-new',
  templateUrl: './pm-view-new.component.html',
  styleUrls: ['./pm-view-new.component.css']
})
export class PmViewNewComponent implements OnInit {
  pmId;
  pmDetails;
  isTeamplate = false
  searchPromise2 = null;
  constructor(public router: Router, public _location: Location, public apiService: ApiService) {
    router.events.subscribe((val) => {
      clearTimeout(this.searchPromise2);
      this.searchPromise2 = setTimeout(() => {
        this.ngOnInit()
      }, 500)
    });
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  emailPermission = false
  downloadPermission = false
  raiseTicketPermission = false
  ngOnInit(): void {
    // document.body.style.overflow = 'hidden';

    this.pmId = this.router.url.split("/")[2];

    // console.log(this.router.url.split("/")[1])
    if (this.router.url.split("/")[1] == "pm-view-template-new" || this.router.url.split("/")[1] == "pm-upcoming-view-new") this.isTeamplate = true;
    this.getPmDetails();

    let permission: any;
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 4);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Raised Tickets" && permission[i]['actionStatus'] == 1) {
        this.raiseTicketPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Send Email" && permission[i]['actionStatus'] == 1) {
        this.emailPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "Export" && permission[i]['actionStatus'] == 1) {
        this.downloadPermission = true
      }
    }
  }
  checklistArray: any;

  additionalDetails : any;

  removeTimeZone(date) {
    date = date.replace('+05:30', '')
    date = date.replace('T', ' ')
    return date
  }

  tmpsrc = ''
  openImage(src) {
    this.tmpsrc = src;
  }

  getOnlyDate(data) {
    if (data != "") return data.substring(0, 10)
    else return "-"
  }
  serviceTeams: any;
  additionalCostsPmsData: any;
  additionalPartsPmsData: any;
  getPmDetails(): void {
    // this.apiService.startLoader()
    // this.apiService.getCall('pm-schedules/pmCheckDaily/'+this.pmId, {}).subscribe((result: any[]) => {
    //   this.apiService.stopLoader()

    //     // console.log(result);
    //     this.pmDetails = result['data'];


    // })

    if (this.pmId) {
      if (this.isTeamplate) {
        this.apiService.startLoader()
        this.apiService.getCall('pm-templates/view/' + this.pmId, {}).subscribe((result: any[]) => {
          this.apiService.stopLoader()

          // console.log(result);
          this.additionalDetails = result['data']
          if (result['data'].additionalCostsPmsData) {
            this.additionalCostsPmsData = result['data'].additionalCostsPmsData;
          }
          if (result['data'].additionalPartsPmsData) {
            this.additionalPartsPmsData = result['data'].additionalPartsPmsData;
          }
          this.serviceTeams = result['data']['serviceTeams']
          // for (let d of result['data']['checklists']){

          //   // if(d.responseType == 0){
          //   // let myArray=  d.response.split('/')
          //   // // console.log(myArray)
          //   // d.buttons= myArray
          //   // }
          // }

          this.checklistArray = result['data']['checklists']
          // console.log(this.checklistArray)
        })
      }
      else {
        this.apiService.startLoader()
        this.apiService.getCall('pm-schedules/view/' + this.pmId, {}).subscribe((result: any[]) => {


          // console.log(result);
          this.additionalDetails = result['data']
          this.assignedTo = this.additionalDetails['assignedTo']
          if (result['data'].additionalCostsPmsData) {
            this.additionalCostsPmsData = result['data'].additionalCostsPmsData;
          }
          if (result['data'].additionalPartsPmsData) {
            this.additionalPartsPmsData = result['data'].additionalPartsPmsData;
          }
          // this.costDetails = 
          // for (let d of result['data']['checklists']){

          //   // if(d.responseType == 0){
          //   // let myArray=  d.response.split('/')
          //   // // console.log(myArray)
          //   // d.buttons= myArray
          //   // }
          // }

          this.checklistArray = result['data']['checklists']
          // console.log(this.checklistArray)
          this.apiService.stopLoader()
        })
      }
    }


  }
  assignedTo : any;
  getUserNames(flag) {
    var str = "";

    if (this.assignedTo.length > 0) {
      for (var i = 0; i < this.assignedTo.length; i++) {
        if (i == 0) {
          str = this.assignedTo[i].userName;
        } else {
          str = str + ", " + this.assignedTo[i].userName;
        }
      }
      if (flag == 1) {
        str = str.length <= 15 ? str : str.substring(0, 10) + '...'
        return str;
      }
    } else {
      for (var i = 0; i < this.serviceTeams.length; i++) {
        if (i == 0) {
          str = this.serviceTeams[i].userName;
        } else {
          str = str + ", " + this.serviceTeams[i].userName;
        }
      }
      if (flag == 1) {
        str = str.length <= 15 ? str : str.substring(0, 10) + '...'
        return str;
      }
    }
    return str;
  }
  getTotalAdditionalCost() {
    let sum = 0
    if (this.additionalDetails['additionalCostsPmsData']) {
      for (let d of this.additionalDetails['additionalCostsPmsData']) sum = sum + d.costs
    }
    return sum
  }


  getTotaPartsCost() {
    let sum = 0
    if (this.additionalDetails['additionalPartsPmsData']) {
      for (let d of this.additionalDetails['additionalPartsPmsData']) sum = sum + d.quantity
    }

    return sum
  }

  getTotaPartsCostPrice() {
    let sum = 0
    if (this.additionalDetails['additionalPartsPmsData']) {
      for (let d of this.additionalDetails['additionalPartsPmsData']) sum = sum + d.costs
    }

    return sum
  }

  costDetails

  partdDetails
  goBack(): void {
    document.body.style.overflowY = 'scroll';
    if (this.isTeamplate) {
      //this.router.navigate(['pm-templates'])
      this._location.back();
    } else {
      //this.router.navigate(['pm-index'])
      this._location.back();
    }
  }

  getSelected(i, j) {
    // console.log(parseInt(i) == j)
    return parseInt(i) == j
  }
  getSelected2(response, statuses) {
    // console.log(statuses,response)
    if (statuses) {
      for (var i = 0; i < statuses.length; i++) {
        if (statuses[i].id == response) {
          return statuses[i].name;
        }
      }
    }
    return "";
  }

  raiseTicket(d) {
    this.router.navigate(['/pm-ticket-raise-ticket/' + this.pmId + '/' + d.id])
  }

  viewTicket(d) {
    this.router.navigate(['/ticket-view/' + d.ticketId])
  }

  getUsers(data) {
    let str = ''
    for (let d of data) str = (str != '' ? ',' : '') + d.userName

    return str
  }

  exportAsPDF(div_id) {

    let obj = {
      reportId: [this.pmId],
      // email:this.emailAddress   
    }
    this.apiService.startLoader()
    this.apiService.uploadCall('PmSchedules/downloadPdf', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      // console.log(result);
      window.open(result['data'], "_blank");
    })



    // let data = document.getElementById(div_id);
    // html2canvas(data, {
    //   width: 2380,
    //   height: data.clientHeight
    // }).then((canvas) => {
    //   let imgWidth = 500;
    //     let pageHeight = 480;
    //     let imgHeight =
    //       ((canvas.height * imgWidth) / 2454)*1.24;
    //     var heightLeft = imgHeight;

    //     const imgData = canvas.toDataURL("image/png");

    //     const pdf = new jspdf({
    //       orientation: "p",
    //       unit: "mm",
    //       format: [400, 480],
    //     });

    //     let position = 0;
    //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;

    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //       heightLeft -= pageHeight;
    //     }
    //     pdf.save("Result.pdf");
    //   });


  }

  downloadDiv: boolean;

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.downloadDiv = false

    }
  }

  email(div_id) {

    let data = document.getElementById(div_id);
    html2canvas(data, {
      width: 2380,
      height: data.clientHeight
    }).then((canvas) => {
      let imgWidth = 500;
      let pageHeight = 480;
      let imgHeight =
        ((canvas.height * imgWidth) / 2454) * 1.24;
      var heightLeft = imgHeight;

      const imgData = canvas.toDataURL("image/png");

      const pdf: any = new jspdf({
        orientation: "p",
        unit: "mm",
        format: [400, 480],
      });

      let position = 0;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      // pdf.save("Result.pdf");
      // console.log(pdf.canvas.pdf)
      // let uploadedFiles
      // if(pdf.length > 0) {
      //   let file: File = pdf[0];
      //   // console.log(file)
      //   uploadedFiles =file
      //   // this.fileName = file.name


      // }
      // // console.log(atob(decodeURIComponent(imgData)))


      // let testData:FormData = new FormData();
      // testData.append("userName","prakash")
      // testData.append("email","akshay.andlab@gmail.com")
      // testData.append("subject","Regarding pdf")
      // testData.append("pdf", canvas.toDataURL().replace(/.*,/, '')
      // )

      //   let obj={
      //     "userName":"prakash",
      // "email":"akshay.andlab@gmail.com",
      // "subject":"Regarding pdf",
      // "pdf":pdf,

      //   }
      // // console.log(JSON.stringify(obj))
      // this.apiService.startLoader()
      // this.apiService.postCall('email/sendEmailReport', {},testData).subscribe((result: any[]) => {
      //   this.apiService.stopLoader();

      //   // this.message = result["msg"];
      //     (<HTMLInputElement>document.getElementById('openSuccess11')).click();
      //   // if(result['success'] = true){
      //   //   alert(result['msg']);
      //   //   this.router.navigate(['/pm-templates'])
      //   // }else{
      //   //   alert(result['msg']);
      //   // }
      // });
    });
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(regularExpression.test(String(email).toLowerCase()))
    return regularExpression.test(String(email).toLowerCase());
  }
  errorFlag: boolean
  sendEmail() {
    this.errorFlag = true
    this.emailAddress = $('#emailAddressPmData').val();
    if (this.validateEmail(this.emailAddress)) this.emailReport()
    else {
      // console.log("invalid email")
    }
  }


  emailAddress: any;
  // errorFlag:boolean;
  message: any;
  emailReport() {
    let obj = {
      reportId: [this.pmId],
      email: this.emailAddress
    }
    this.apiService.startLoader()
    this.apiService.uploadCall('PmSchedules/downloadPdf', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      // window.open(result['data'], "_blank");
      this.errorFlag = false
      this.emailAddress = undefined
      $('#emailAddressPmData').val('');
      this.apiService.stopLoader();
      (<HTMLInputElement>document.getElementById('cancelEmailPopup')).click();

      this.message = "Maintenance report mailed successfully";
      (<HTMLInputElement>document.getElementById('validationButton')).click();
    })
  }


}
