import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-logs-files-listing',
  templateUrl: './logs-files-listing.component.html',
  styleUrls: ['./logs-files-listing.component.css']
})
export class LogsFilesListingComponent implements OnInit {

  assetId: string;
  temporaryForm: FormGroup

  constructor(private fb: FormBuilder,public _location: Location,public route: Router,public router:Router ,public apiService:ApiService,private http: HttpClient) { }
  paginationArray=[1,2,3]
  fileAddPermission=false
  fileDeletePermission=false
  notifyAddPermission=false
  pmPermission=false;
  partsFeaturePermission = false;
  partsPermission = true;
  maintenanceFeaturePermission = false;
  ngOnInit(): void {
    this.assetId = this.router.url.split("/")[2]
    // console.log(this.assetId)
    this.getPMsList()
    this.getLogsList()
    this.getAssetDetail()
    this.getUserList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 3);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Logs" && permission[i]['action'] == "File Add" && permission[i]['actionStatus'] == 1) {
        this.fileAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Logs" && permission[i]['action'] == "File Delete" && permission[i]['actionStatus'] == 1) {
        this.fileDeletePermission = true
      }
      if (permission[i]['subModuleName'] == "Notify" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.notifyAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Preventive Maintenance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.pmPermission = true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.partsFeaturePermission = true
    }
    this.temporaryForm = this.fb.group({
      file_upload: [null]
    });
  }
  tabSelected = 'Active Logs'

  selectedFiler = { entry: null, fileName: null, fileType: null, dueDate: null, uploadedBy: null }
  logsList = []
  filesList = []

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    this.getLogsList()
    this.getPMsList()

  }

  deletLog: any;


  userFilter = false;
  userList
  getUserList(): void {
    this.apiService.startLoader()
    this.apiService.startLoader()


    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.userList = result['data'];
      this.filterUserList = result['data'];
      // console.log("List of Users",this.userList);

    })

  }
  clear() {
    this.applyFlag = false;
    this.selectedFiler = { entry: null, fileName: null, fileType: null, dueDate: null, uploadedBy: null }
    this.getLogsList()
    this.getPMsList()
  }
  sortSelected: any = 'created_at';
  sortType = 'desc'
  sortData(d) {
    this.sortSelected = d
    if (this.sortType == 'asc') this.sortType = 'desc';
    else this.sortType = 'asc'
    if (this.tabSelected != 'Files') this.getLogsList()
    else this.getPMsList()
  }
  deleteFile() {
    this.apiService.startLoader()
    let obj = {
      'authId': this.apiService.authId
    }
    this.apiService.postCall('logs/delete/' + this.deletLog.logId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.getPMsList()
    })
  }


  getLogsList() {
    // console.log("its a call")
    // this.authService.loader= true;
    let getListBody = {
      // assetId:this.assetId,
      sortby: this.sortSelected + " " + this.sortType,
      pageNo: this.activePage,
      limit: this.pageSize,
      entry: this.selectedFiler.entry,
      userName: this.selectedFiler.uploadedBy,
      createdAt: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD') : null,
      type: this.selectedFiler.fileType ? this.selectedFiler.fileType.id : null
    }

    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/assetLogsListing/' + this.assetId, {}, getListBody).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.logsList = result['assetLogsListing']

      this.logTypeList = result['logType']
      this.filterpropertyList = result['logType']
      this.setPaginationArray(result)
    })
  }

  logTypeList = []

  getLogText(id) {
    return this.logTypeList.find(x => x.id == id).name
  }

  filterpropertyList = []
  filterPropertySearch
  // propertFilter= false;
  filterProperty(ev) {
    // console.log(ev)
    this.logTypeList = this.filterpropertyList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }


  filterUserList = []
  filterUserSearch
  // propertFilter= false;
  filterUser(ev) {
    // console.log(ev)
    this.userList = this.filterUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }


  getPMsList(): void {

    let getListBody = {
      // assetId:this.assetId,
      sortby: this.sortSelected + " " + this.sortType,
      pageNo: this.activePageFile,
      limit: this.pageSizeFile,
      userName: this.selectedFiler.uploadedBy,
      fileName: this.selectedFiler.fileName,
      createdAt: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD') : null,
    }

    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/asseFilestLogs/' + this.assetId, {}, getListBody).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.filesList = result['fileList']

      this.setPaginationArrayFile(result)
    })
  }

  message



  uploadFile() {

    let testData: FormData = new FormData();
    testData.append('files', this.uploadedFiles);
    testData.append('entry', this.fileName);
    testData.append('assetManagementId', this.assetId);
    testData.append('authId', this.apiService.userId);
    testData.append('type', '1');

    // console.log(testData)
    this.apiService.startLoader()
    this.apiService.uploadCall('logs/add-file-entry', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = "File Uploaded Successfully";

      this.uploadedFiles = undefined;
      this.fileName = undefined;


      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getLogsList()
      this.getPMsList()
    })
  }


  fileName: any;
  uploadedFiles;
  loading = false;

  public files;

  // public dropped(files) {
  //   let fileList: FileList = event.target.files;
  //   if(fileList.length > 0) {
  //       let file: File = fileList[0];
  //       // console.log(file)
  //       this.uploadedFiles =file
  //   this.fileName = this.temporaryForm.value.file_upload.fileEntry.name

  //   // console.log(this.fileName)
  //   }
  // }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }


  goToLink(url: string) {
    window.open(url, "_blank");
  }

  assetDetails
  getAssetDetail() {
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/' + this.assetId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];

    })

    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)
      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      //   this.statusList.splice(idx1,1)
      //   idx1 = this.statusList.findIndex(x=>x .name== "Dormant")
      //   this.statusList.splice(idx1,1)
    })
  }




  fileChange(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }
  goBack() { }


  uploadedFilesAsset

  fileChangeAsset(event) {
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFilesAsset = file



    }

    let testData: FormData = new FormData();


    // // console.log(obj)
    testData.append('image', this.uploadedFilesAsset);

    // status:this.assetDetailsForm.value.status,



    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()

    })
  }


  changeStatus(){
    let testData:FormData = new FormData();
    
     
    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);
     
      testData.append("status", this.assetDetails.status);
      
    
    
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/'+this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getAssetDetail()
      document.getElementById('dismissPopUp').click()
      
    })
  }
  statusList=[]
  
  
   activePage=1
   totalnoOfPages
   pageSize=10
   setPaginationArray(result){
     // this.totalrowCount = result['rowCount']
     let noOfPAges = Math.ceil(result['logCounts'] /this.pageSize)
     this.totalnoOfPages= noOfPAges;
     this.paginationArray=[]
  
     if(noOfPAges <=5)for(let i =1 ; i<= noOfPAges ; i++)this.paginationArray.push(i)
     else if(4+this.activePage < this.totalnoOfPages) {
       for(let i =this.activePage ; i<= 4+this.activePage ; i++)this.paginationArray.push(i)
     }
     else if(4+this.activePage >= 6){
  
       let start = (4+this.activePage)- noOfPAges 
       for(let i =this.activePage-start ; i<= noOfPAges ; i++)this.paginationArray.push(i)
     }
     // console.log(this.paginationArray)
   }
  
  
   activePageFile=1
   totalnoOfPagesFile
   pageSizeFile=10
   paginationArrayFile=[]
   setPaginationArrayFile(result){
     // this.totalrowCount = result['rowCount']
     let noOfPAges = Math.ceil(result['filesCounts'] /this.pageSizeFile)
     this.totalnoOfPagesFile= noOfPAges;
     this.paginationArrayFile=[]
  
     if(noOfPAges <=5)for(let i =1 ; i<= noOfPAges ; i++)this.paginationArrayFile.push(i)
     else if(4+this.activePageFile < this.totalnoOfPagesFile) {
       for(let i =this.activePageFile ; i<= 4+this.activePageFile ; i++)this.paginationArrayFile.push(i)
     }
     else if(4+this.activePageFile >= 6){
  
       let start = (4+this.activePageFile)- noOfPAges 
       for(let i =this.activePageFile-start ; i<= noOfPAges ; i++)this.paginationArrayFile.push(i)
     }
     // console.log(this.paginationArrayFile)
   }
  
  
   pagination(d){
     // console.log(d)
     if(d != 0 && d <= this.totalnoOfPages && this.activePage != d){
     // this.selectedAssets=[]
     this.activePage= d
     // console.log(d)
     this.getLogsList()
     }
   }
   changePage(ev){
     // console.log(this.pageSize)
     // this.selectedAssets=[]
     // this.pageSize = d
     this.getLogsList()
   }
  
  
   paginationFile(d){
    // console.log(d)
    if(d != 0 && d <= this.totalnoOfPagesFile && this.activePageFile != d){
    // this.selectedAssets=[]
    this.activePageFile= d
    // console.log(d)
    this.getPMsList()
    }
  }
  changePageFile(ev){
    // console.log(this.pageSize)
    // this.selectedAssets=[]
    // this.pageSize = d
    this.getPMsList()
  }
  filterSelect: boolean
  //  closeAll(){}
  
   applyFlag
  //  {entry:null , fileName:null ,  fileType:null ,dueDate:null , uploadedBy:null }
   getFilterStatus(){
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length=0;
    if(this.tabSelected == 'Active Logs' && this.selectedFiler.entry != null) length = length+1
    if(this.tabSelected == 'Active Logs' && this.selectedFiler.fileType != null) length = length+1
    if(this.tabSelected == 'Files' && this.selectedFiler.fileName!= null) length = length+1
    // if(this.selectedFiler.fileName != null) length = length+1
    if(this.selectedFiler.dueDate != null) length = length+1
    if(this.selectedFiler.uploadedBy != null) length = length+1
    
    // for(let d of this.selectedFiler)
    // // console.log(length)
    if(length == 0 || !this.applyFlag) return "(None Selected)"
    else if(this.applyFlag) return `(${length} Filter Selected)`
  }
  
  propertFilter=false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if(btn.id != 'custom') {
      this.filterSelect= false
  //     this.assetFilter= false;
  // this.locationFilter= false;
  // this.businessFilter= false;
  this.propertFilter= false;
  this.userFilter = false;
      // this.selectFreq = false
    }
  }
  closeAll(){
  // this.assetFilter= false;
  // this.locationFilter= false;
  // this.businessFilter= false;
  this.propertFilter= false;
  this.userFilter = false;
  }  





}



