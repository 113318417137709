import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/common/services/apiCalls.service";
import * as moment from 'moment';

@Component({
  selector: "app-attendance-list",
  templateUrl: "./attendance-list.component.html",
  styleUrls: ["./attendance-list.component.css"],
})
export class AttendanceListComponent implements OnInit {
  userDetails: any;
  showAttendanceDropdownData = false;
  showsiteDropdownData = false;
  uploadedFileForPunchInOut: any;
  fileName: any;
  isMultiAttenance = false;

  absentTypesValue = null;

  onOffSiteFlagForPunchIn = false;
  onOffSiteFlagForPunchOut = false;

  descriptionForPunchOut: string = "";
  descriptionForPunchIn: string = "";

  punchInOutAbsenceSingleData: any | null;
  punchInOutAbsenceMultipleData: any[] = [];

  propertyList: any[] = [];
  attendanceListData: any[] = [];
  copyattendanceListData: any[] = [];
  relieverListData: any[] = [];
  attendanceReportListData: any;
  sundayData: any[] = [];
  AttendanceDatareport: any;
  reportKeys: any[] = [];
  relieverCollectionData: any[] = [];

  totalEmployee: number;
  presentEmployee: number;
  absentEmployee: number;
  unmark: number;
  selectedSite: string = "All";
  selectedSiteId: number | null;
  absenteesPercentage: string;

  /* --------------------------------------- pagination and sortingx -------------------------------------- */
  sortType = "desc";
  sortBy = "id";

  activePage: any = 1;
  pageSize: number = 10;
  totalnoOfPages: any;
  jumpToOpen: any;
  paginationArray: any[] = [];

  filterSelect = false;
  filterSelectForAttendanceReport = false;
  selectedFiler = {
    Clients: null,
    Zone: null,
    state: null,
    cluster: null,
    city: null,
    propertyType: null,
    property: null,
    department: null,
    fullName: null,
    loginFlagFilter: null,
    startDate: null,
    endDate: null,
  };
  applyFlag;

  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isDepartmentFilter1 = false;
  isPropertyFilter1 = false;
  isCityForFilter1 = false;
  isCluster1 = false;
  isState1 = false;
  propertyZone1 = false;
  propertyClients1 = false;
  isState = false;

  filterClientsSearch: any;
  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterPropertySearch: any;
  filterDepartmentSearch: any;
  filterStateSearch: any;

  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];

  writeEmail: any;
  grapDurationReportValue: string;

  @ViewChild("punchInModal") punchInModal: ElementRef;

  constructor(
    public route: Router,
    public apiService: ApiService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // console.log(this.userDetails, "this.userDetails");
    this.getPropertyList();
    this.getAttendanceList("");

    this.getZoneListData();
    //this.getAttendanceReport();
    //this.getStateListData();
    //this.getClusterListData();
    //this.getCityListData();
  }
  img1 = null
  img2 = null
  goTab(url) {
    this.img1 = null
    this.img2 = null
    var url1 = []
    url1 = url.split(",")
    // console.log(url1)
    if (url1[0]) {
      this.img1 = url1[0]
    }
    if (url1[1]) {
      this.img2 = url1[1]
    }
    ($("#showImages") as any).modal("show");
  }
  goTabOpen(url) {
    window.open(url, "_blank");
  }
  downloadReport() {
    this.apiService.startLoader();

    let req = {
      businessId: this.apiService.buisnessId,
      outletId: this.selectedSite === "All" ? "" : this.selectedSiteId,
      sortBy: this.sortBy,
      authId: this.apiService.authId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      fullName: this.selectedFiler.fullName ? this.selectedFiler.fullName : "",
      loginFlagFilter: this.selectedFiler.loginFlagFilter ? this.selectedFiler.loginFlagFilter : "",
      //startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[0]).format('YYYY-MM-DD') : "",
      //endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[1]).format('YYYY-MM-DD') : "",
      myDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
      //myDate: this.datepipe.transform(new Date(), "yyyy-MM-dd"),
      //myDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
    };
    this.apiService.postCall("attendances/attendanceReportDownload", {}, req).subscribe((result: any) => {
      // console.log(result);
      this.apiService.stopLoader();
      if (result['success'] == true) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = result['url'];
        a.download = result['datePeriod'] + ".csv";
        a.click();
        document.body.removeChild(a);
      }
    });
  }
  /* ----------------------------------------- attendance list api ---------------------------------------- */
  getAttendanceList(propertyId?: any) {
    this.apiService.startLoader();
    let req = {
      businessId: this.apiService.buisnessId,
      limit: this.pageSize,
      pageNo: this.activePage,
      propertyId: propertyId,
      loginFlag: "",
      sortBy: this.sortBy,
      sortType: this.sortType,
      role: this.apiService.role,
      authId: this.apiService.authId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      fullName: this.selectedFiler.fullName ? this.selectedFiler.fullName : "",
      loginFlagFilter: this.selectedFiler.loginFlagFilter ? this.selectedFiler.loginFlagFilter : "",
      startDate: this.selectedFiler.startDate ? this.datepipe.transform(new Date(this.selectedFiler.startDate[0]), "dd-MM-yyyy") : "",
      endDate: this.selectedFiler.startDate ? this.datepipe.transform(new Date(this.selectedFiler.startDate[1]), "dd-MM-yyyy") : "",
    };
    this.apiService
      .postCall("attendances/index", {}, req)
      .subscribe((result: any) => {
        // console.log(result);
        if (result.msg === "No Data Available") {
          // console.log(result.msg);
          this.totalEmployee = 0;
          this.presentEmployee = 0;
          this.absentEmployee = 0;
          this.unmark = 0;
          this.absenteesPercentage = "0%";
          this.attendanceListData = [];
          this.apiService.stopLoader();
        } else {
          this.totalEmployee = result.totalEmployee;
          this.presentEmployee = result.presentEmployee;
          this.absentEmployee = result.absentEmployee;
          this.unmark = result.unmarkEmp;
          this.absenteesPercentage = result.todaysAbsenteesPercentage;

          this.attendanceListData = result.data;
          if (this.attendanceListData != null && this.attendanceListData.length > 0) {
            this.copyattendanceListData = this.attendanceListData.slice();
          }
          this.setPaginationArray(result);

          this.apiService.stopLoader();
        }
      });
  }

  /* ---------------------------------------- attendace report api ---------------------------------------- */
  datePeriod = 0
  totalEmployee1 = 0
  getAttendanceReport() {
    this.apiService.startLoader();

    let req = {
      businessId: this.apiService.buisnessId,
      outletId: this.selectedSite === "All" ? "" : this.selectedSiteId,
      sortBy: this.sortBy,
      authId: this.apiService.authId,
      myDate: this.datepipe.transform(new Date(), "yyyy-MM-dd"),
    };
    this.apiService.postCall("attendances/attendanceReport", {}, req).subscribe((result: any) => {
      this.attendanceReportListData = result;
      // console.log(this.attendanceReportListData);

      this.sundayData = result.sunday;
      this.AttendanceDatareport = result.report;
      this.reportKeys = Object.keys(this.AttendanceDatareport);
      this.datePeriod = result.datePeriod;
      this.totalEmployee1 = result.totalEmployee;
      this.reportKeys.forEach((i: any) => {
        // console.log(this.AttendanceDatareport[i])
        if (this.AttendanceDatareport[i]["status"] === "A") {
          this.AttendanceDatareport[i]["className"] = "absent";
        } else if (this.AttendanceDatareport[i]["status"] === "SL") {
          this.AttendanceDatareport[i]["className"] = "leave";
        } else if (this.AttendanceDatareport[i]["status"] === "PL") {
          this.AttendanceDatareport[i]["className"] = "leave";
        } else if (this.AttendanceDatareport[i]["status"] === "CL") {
          this.AttendanceDatareport[i]["className"] = "leave";
        } else {
          //this.AttendanceDatareport[i]["status"]="";
          //// console.log(i,this.AttendanceDatareport[i]["status"])
          this.AttendanceDatareport[i]["className"] = "leave";
        }
      });

      // console.log(this.sundayData);
      // console.log(this.AttendanceDatareport, "AttendanceDatareport");

      this.apiService.stopLoader();
    });
  }

  /* ----------------------------------------- property list api ---------------------------------------- */
  getPropertyList() {
    this.apiService.startLoader();
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      citieId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
    };
    this.apiService
      .postCall("outlets/outlet-list/" + this.apiService.buisnessId, {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.propertyList = []
        this.departmentListData = []
        this.ClientsListData = []
        if (result["data"]) {
          this.propertyList = result["data"];
        }
        if (result["departments"]) {
          this.departmentListData = result["departments"];
        }
        this.filtereddepartmentListData = this.departmentListData.slice();
        this.propertyListData = this.propertyList.slice();
        this.filteredpropertyListData = this.propertyList.slice();
        if (result["clients"]) {
          this.ClientsListData = result["clients"];
        }
        this.filteredClientsListData = this.ClientsListData.slice();
        this.apiService.stopLoader();
      });
  }

  filterReliver = []
  openReliever() {
    this.apiService.startLoader();
    this.relieverListData = [];
    this.filterReliver = [];
    let req = {
      businessId: this.apiService.buisnessId,
      limit: 0,
      pageNo: this.activePage,
      loginFlag: "",
      sortBy: this.sortBy,
      sortType: this.sortType,
      propertyId: "",
      role: this.apiService.role,
      authId: this.apiService.authId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      fullName: this.selectedFiler.fullName ? this.selectedFiler.fullName : "",
      loginFlagFilter: this.selectedFiler.loginFlagFilter ? this.selectedFiler.loginFlagFilter : "",
      startDate: this.selectedFiler.startDate ? this.datepipe.transform(new Date(this.selectedFiler.startDate[0]), "dd-MM-yyyy") : "",
      endDate: this.selectedFiler.startDate ? this.datepipe.transform(new Date(this.selectedFiler.startDate[1]), "dd-MM-yyyy") : "",
    };
    this.apiService
      .postCall("attendances/index", {}, req)
      .subscribe((result: any) => {
        if (result.success) {
          this.relieverListData = result.data;
          this.filterReliver = result.data;
          this.apiService.stopLoader();
        }
      });
  }

  filterReliverSearch = ""
  filterReliverFunction(event: any) {
    if (this.filterReliverSearch) {
      this.relieverListData = this.filterReliver.filter((item: any) => {
        if (item.subUserName != null) {
          return item.subUserName.toLowerCase().includes(this.filterReliverSearch.toLowerCase());
        }
      });
    } else {
      this.relieverListData = this.filterReliver;
    }
  }

  openAttendanceReportModal() {
    this.getAttendanceReport();
    ($("#attendanceReport") as any).modal("show");
  }


  selectForReliever(event: any, item: any) {
    // console.log(event.target.checked);
    // console.log(item);
    if (event.target.checked) {
      for (var i = 0; i < this.relieverListData.length; i++) {
        if (item.id == this.relieverListData[i].id) {
          this.relieverListData[i].selected = true;
        }
      }
    } else {
      for (var i = 0; i < this.relieverListData.length; i++) {
        if (item.id == this.relieverListData[i].id) {
          this.relieverListData[i].selected = false;
        }
      }
    }
    /*if (event.target.checked) {
      this.relieverCollectionData.push({
        userRelieverName: item.relieverName,
        userId: item.subUserId,
        businessId: this.apiService.buisnessId,
      });
    } else {
      this.relieverCollectionData.splice(
        this.relieverCollectionData.findIndex((x) => x == item.subUserId),
        1
      );
    }*/
    // console.log(this.relieverCollectionData);
  }

  selectedAllReliver(event) {
    if (event.target.checked) {
      for (var i = 0; i < this.relieverListData.length; i++) {
        this.relieverListData[i].selected = true;
      }
    } else {
      for (var i = 0; i < this.relieverListData.length; i++) {
        this.relieverListData[i].selected = false;
      }
    }
  }

  saveReliever() {
    this.apiService.startLoader();

    for (var i = 0; i < this.relieverListData.length; i++) {
      if (this.relieverListData[i].selected == true) {
        this.relieverCollectionData.push({
          userRelieverName: this.relieverListData[i].relieverName,
          attendanceId: this.relieverListData[i].id,
          userId: this.relieverListData[i].subUserId,
          absentTypes: this.relieverListData[i].absentTypes,
          businessId: this.apiService.buisnessId,
        });

        this.punchInOutAbsenceMultipleData.push({
          id: this.relieverListData[i].id,
        });

      }
    }
    // console.log(this.relieverCollectionData)
    let req = {
      userReliever: this.relieverCollectionData,
      authId: this.apiService.authId
    };
    this.apiService.postCall("UserRelievers/add", {}, req).subscribe((result: any) => {
      // console.log(result);
      for (var i = 0; i < this.relieverListData.length; i++) {
        if (this.relieverListData[i].selected == true) {
          if (this.relieverListData[i].absentTypes == '') {
            this.updateAbscentData();
          }
        }
      }

      this.absentTypesValue = "Absent";
      this.isMultiAttenance = true
      this.relieverCollectionData = []
      //this.getAttendanceList();
      this.apiService.stopLoader();
    });
  }

  openPunchInModal(attend: any) {
    this.removeAttachment();
    var punchIn = document.getElementById("customPunchIn");
    this.punchInOutAbsenceSingleData = attend;

    if (attend.InTime && attend.loginFlag === 0) {
      // console.log("You Cant Open Modal");
    } else {
      ($("#punchInModal") as any).modal("show");
    }
  }

  openPunchOutModal(attend: any) {
    this.removeAttachment();
    var punchOut = document.getElementById("customPunchOut");
    this.punchInOutAbsenceSingleData = attend;
    if (attend.outTime) {
      // console.log("You Cant Open Modal");
    } else {
      if (!attend.InTime && attend.loginFlag === 3) {
        // console.log("You Cant Open Modal");
      } else {
        ($("#punchOutModal") as any).modal("show");
      }
    }
  }

  openModalForAbsence(event: any, attend: any) {
    // console.log(event.target.checked);
    // console.log(attend);
    this.punchInOutAbsenceSingleData = attend;
    var absence = document.getElementById("absenceModalOpen");
    this.absentTypesValue = null
    if (event.target.checked) {
      ($("#absenceModalID") as any).modal("show");
    }
  }
  openShareReportModal() {
    ($("#staticBackdrop") as any).modal("show");
  }

  getSelectPropertyData(data: any) {
    // console.log(data);
    this.selectedSite = data.outletName;
    this.selectedSiteId = data.id;
    this.getAttendanceList(data.id);

    this.showsiteDropdownData = !this.showsiteDropdownData;
  }

  updatePunchInData() {
    // console.log(this.onOffSiteFlagForPunchIn);
    var testData: FormData = new FormData();

    if (this.isMultiAttenance) {
      testData.append(
        "id",
        JSON.stringify(
          this.punchInOutAbsenceMultipleData.map((i: any) => i?.id)
        )
      );
      testData.append(
        "propertyId",
        this.selectedSite === "All" ? "" : this.selectedSiteId.toString()
      );
      testData.append("authId", this.apiService.authId);
      testData.append("fromManager", "1");
      testData.append("businessId", this.apiService.buisnessId);
      testData.append("attendFlag", "1");
      testData.append("loginType", "1");
      testData.append("shipmentId", "0");
      testData.append("latitude", "");
      testData.append("longitude", "");
      testData.append("absentTypes", "");
      if (this.descriptionForPunchIn)
        testData.append("description", this.descriptionForPunchIn);
      if (this.uploadedFileForPunchInOut)
        testData.append("attachment", this.uploadedFileForPunchInOut);
      testData.append(
        "inoutsideFlag",
        this.onOffSiteFlagForPunchIn ? "1" : "0"
      );

      this.apiService
        .postCall("attendances/markeUser", {}, testData)
        .subscribe((result: any) => {
          // console.log(result);

          this.punchInOutAbsenceSingleData = null;
          this.selectedSite = "All";
          this.isMultiAttenance = false;
          this.showAttendanceDropdownData = false;
          this.getAttendanceList(this.selectedSiteId);
          this.apiService.stopLoader();
        });
    } else {
      if (this.punchInOutAbsenceSingleData)
        testData.append(
          "subUserId",
          this.punchInOutAbsenceSingleData.subUserId
        );
      if (this.punchInOutAbsenceSingleData)
        testData.append(
          "propertyId",
          this.punchInOutAbsenceSingleData.propertyId
        );
      testData.append("authId", this.apiService.authId);
      testData.append("fromManager", "1");
      testData.append("businessId", this.apiService.buisnessId);
      testData.append("attendFlag", "1");
      testData.append("loginType", "1");
      if (this.punchInOutAbsenceSingleData)
        testData.append(
          "shipmentId",
          this.punchInOutAbsenceSingleData.shipmentId
        );
      testData.append("latitude", "");
      testData.append("longitude", "");
      testData.append("absentTypes", "");
      if (this.descriptionForPunchIn)
        testData.append("description", this.descriptionForPunchIn);
      if (this.uploadedFileForPunchInOut)
        testData.append("attachment", this.uploadedFileForPunchInOut);
      testData.append(
        "inoutsideFlag",
        this.onOffSiteFlagForPunchIn ? "1" : "0"
      );
      if (this.punchInOutAbsenceSingleData)
        testData.append("id", this.punchInOutAbsenceSingleData.id);

      this.apiService
        .postCall("attendances/takeAttendance", {}, testData)
        .subscribe((result: any) => {
          // console.log(result);

          this.punchInOutAbsenceSingleData = null;
          this.selectedSite = "All";
          this.getAttendanceList(this.selectedSiteId);
          this.apiService.stopLoader();
        });
    }

    ($("#punchInModal") as any).modal("hide");
  }

  updatePunchOutData() {
    // console.log(this.onOffSiteFlagForPunchOut);

    this.apiService.startLoader();

    var testData: FormData = new FormData();

    if (this.isMultiAttenance) {
      testData.append(
        "id",
        JSON.stringify(
          this.punchInOutAbsenceMultipleData.map((i: any) => i?.id)
        )
      );
      testData.append(
        "propertyId",
        this.selectedSite === "All" ? "" : this.selectedSiteId.toString()
      );
      testData.append("authId", this.apiService.authId);
      testData.append("fromManager", "1");
      testData.append("businessId", this.apiService.buisnessId);
      testData.append("attendFlag", "2");
      testData.append("loginType", "1");
      testData.append("shipmentId", "0");
      testData.append("latitude", "");
      testData.append("longitude", "");
      testData.append("absentTypes", "");
      if (this.descriptionForPunchOut)
        testData.append("description", this.descriptionForPunchOut);
      if (this.uploadedFileForPunchInOut)
        testData.append("attachment", this.uploadedFileForPunchInOut);
      testData.append(
        "inoutsideFlag",
        this.onOffSiteFlagForPunchOut ? "1" : "0"
      );

      this.apiService
        .postCall("attendances/takeAttendance", {}, testData)
        .subscribe((result: any) => {
          // console.log(result);

          this.punchInOutAbsenceSingleData = null;
          this.selectedSite = "All";
          this.isMultiAttenance = false;
          this.showAttendanceDropdownData = false;
          this.getAttendanceList(this.selectedSiteId);
          this.apiService.stopLoader();
        });
    } else {
      if (this.punchInOutAbsenceSingleData)
        testData.append(
          "subUserId",
          this.punchInOutAbsenceSingleData.subUserId
        );
      if (this.punchInOutAbsenceSingleData)
        testData.append(
          "propertyId",
          this.punchInOutAbsenceSingleData.propertyId
        );
      testData.append("authId", this.apiService.authId);
      testData.append("fromManager", "1");
      testData.append("businessId", this.apiService.buisnessId);
      testData.append("attendFlag", "2");
      testData.append("loginType", "1");
      if (this.punchInOutAbsenceSingleData)
        testData.append(
          "shipmentId",
          this.punchInOutAbsenceSingleData.shipmentId
        );
      testData.append("latitude", "");
      testData.append("longitude", "");
      testData.append("absentTypes", "");
      if (this.descriptionForPunchOut)
        testData.append("description", this.descriptionForPunchOut);
      if (this.uploadedFileForPunchInOut)
        testData.append("attachment", this.uploadedFileForPunchInOut);
      testData.append(
        "inoutsideFlag",
        this.onOffSiteFlagForPunchOut ? "1" : "0"
      );
      if (this.punchInOutAbsenceSingleData)
        testData.append("id", this.punchInOutAbsenceSingleData.id);

      this.apiService
        .postCall("attendances/takeAttendance", {}, testData)
        .subscribe((result: any) => {
          // console.log(result);

          this.punchInOutAbsenceSingleData = null;
          this.selectedSite = "All";
          this.getAttendanceList(this.selectedSiteId);
          this.apiService.stopLoader();
        });
    }

    ($("#punchOutModal") as any).modal("hide");
  }

  updateAbscentData() {
    // console.log(this.absentTypesValue);
    this.apiService.startLoader();

    var testData: FormData = new FormData();

    if (this.isMultiAttenance) {
      testData.append("authId", this.apiService.authId);
      testData.append("fromManager", "1");
      testData.append("id", JSON.stringify(this.punchInOutAbsenceMultipleData.map((i: any) => i?.id)));
      testData.append("propertyId", this.selectedSite === "All" ? "" : this.selectedSiteId.toString());
      testData.append("businessId", this.apiService.buisnessId);
      testData.append("attendFlag", this.absentTypesValue === "Absent" ? "" : "0");
      testData.append("loginType", "1");
      testData.append("shipmentId", "0");
      testData.append("latitude", "");
      testData.append("longitude", "");
      testData.append("absentTypes", this.absentTypesValue === "Absent" ? "" : this.absentTypesValue);
      testData.append("description", "");
      testData.append("attachment", "");
      testData.append("inoutsideFlag", "");

      this.apiService
        .postCall("attendances/markeUser", {}, testData)
        .subscribe((result: any) => {
          // console.log(result);

          this.punchInOutAbsenceSingleData = null;
          this.selectedSite = "All";
          this.isMultiAttenance = false;
          this.getAttendanceList(this.selectedSiteId);
          this.showAttendanceDropdownData = false;
          this.apiService.stopLoader();
        });
    } else {
      let req = {
        authId: this.apiService.authId,
        fromManager: 1,
        subUserId: this.punchInOutAbsenceSingleData ? this.punchInOutAbsenceSingleData.subUserId : "",
        propertyId: this.punchInOutAbsenceSingleData ? this.punchInOutAbsenceSingleData.propertyId : "",
        businessId: this.apiService.buisnessId,
        attendFlag: this.absentTypesValue === "Absent" ? 0 : 0,
        loginType: 1,
        shipmentId: this.punchInOutAbsenceSingleData ? this.punchInOutAbsenceSingleData.shipmentId : "",
        latitude: "",
        longitude: "",
        absentTypes: this.absentTypesValue === "Absent" ? "" : parseInt(this.absentTypesValue),
        description: "",
        attachment: "",
        inoutsideFlag: "",
        id: this.punchInOutAbsenceSingleData ? this.punchInOutAbsenceSingleData.id : "",
      };

      this.apiService
        .postCall("attendances/takeAttendance", {}, req)
        .subscribe((result: any) => {
          // console.log(result);

          this.punchInOutAbsenceSingleData = null;
          this.selectedSite = "All";
          this.getAttendanceList(this.selectedSiteId);
          this.apiService.stopLoader();
        });
    }
    ($("#absenceModalID") as any).modal("hide");
  }

  changeOnOffSiteForPunchOut() {
    this.onOffSiteFlagForPunchOut = !this.onOffSiteFlagForPunchOut;
  }
  changeOnOffSiteForPunchIn() {
    this.onOffSiteFlagForPunchIn = !this.onOffSiteFlagForPunchIn;
  }
  fileChange(event: any) {
    // console.log(event.target.files[0].type);

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file);
      this.uploadedFileForPunchInOut = file;
      this.fileName = file.name;
      // console.log(this.uploadedFileForPunchInOut);
      // console.log(this.fileName);
    }
  }
  removeAttachment() {
    this.uploadedFileForPunchInOut = null;
    this.fileName = null;
  }
  clickImage() {
    $("#uploadImage").click();
  }
  selectedAllUser(event: any) {
    if (event.target.checked) {
      this.selectAll = true
      for (var i = 0; i < this.attendanceListData.length; i++) {
        this.attendanceListData[i].selected = true
      }
      this.punchInOutAbsenceMultipleData = this.attendanceListData;
    } else {
      this.selectAll = false
      this.punchInOutAbsenceMultipleData = [];
      for (var i = 0; i < this.attendanceListData.length; i++) {
        this.attendanceListData[i].selected = false
      }
    }
    // console.log(this.punchInOutAbsenceMultipleData);
  }

  selectAll = false
  selectedOneByOneUser(event: any, item: any) {
    if (event.target.checked) {
      item.selected = true
      this.punchInOutAbsenceMultipleData.push({
        id: item.id,
      });
    } else {
      this.selectAll = false
      item.selected = false
      this.punchInOutAbsenceMultipleData.splice(
        this.punchInOutAbsenceMultipleData.findIndex((x) => x == item.id),
        1
      );
    }
    // console.log(this.punchInOutAbsenceMultipleData.map((i: any) => i.id));
  }
  doMultipleAttendance(item: string) {
    this.showAttendanceDropdownData = false;
    this.isMultiAttenance = true;
    // console.log(this.showAttendanceDropdownData);

    this.absentTypesValue = null
    if (!this.showAttendanceDropdownData) {
      if (item === "Absent") {
        ($("#absenceModalID") as any).modal("show");
      } else if (item === "Punch In") {
        ($("#punchInModal") as any).modal("show");
      } else {
        ($("#punchOutModal") as any).modal("show");
      }
    }
  }

  /* ----------------------------------------------- sorting ---------------------------------------------- */
  sortData(data: any) {
    // console.log(data);
    this.sortBy = data;

    if (this.sortType == "asc") {
      this.sortType = "desc";
    } else {
      this.sortType = "asc";
    }
    // console.log(this.sortType);
    // this.getAttendanceList(
    //   this.selectedSite === "All" ? "" : this.selectedSiteId
    // );
    this.applyFilter();
  }

  openAttendanceMarkDropdown() {
    this.showAttendanceDropdownData = !this.showAttendanceDropdownData;
  }

  /* --------------------------------------------- pagination --------------------------------------------- */

  changePage(event: any) {
    this.pageSize = Number(event.target.value);
    // console.log(this.pageSize);
    this.applyFilter();
    // this.getAttendanceList(
    //   this.selectedSite === "All" ? "" : this.selectedSiteId
    // );
  }

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      this.applyFilter();
      // this.getAttendanceList(
      //   this.selectedSite === "All" ? "" : this.selectedSiteId
      // );
    }
  }

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) array.push(i);

    return array;
  }

  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result["rowCount"] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5)
      for (let i = 1; i <= noOfPAges; i++) this.paginationArray.push(i);
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)
        this.paginationArray.push(i);
    } else if (4 + this.activePage >= 6) {
      let start = 4 + this.activePage - noOfPAges;
      for (let i = this.activePage - start; i <= noOfPAges; i++)
        this.paginationArray.push(i);
    }
    // console.log(this.paginationArray);
  }

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.state != null) length = length + 1;
    if (this.selectedFiler.Clients != null) length = length + 1;
    if (this.selectedFiler.Zone != null) length = length + 1;
    if (this.selectedFiler.cluster != null) length = length + 1;
    if (this.selectedFiler.city != null) length = length + 1;
    if (this.selectedFiler.property != null) length = length + 1;
    if (this.selectedFiler.propertyType != null) length = length + 1;
    if (this.selectedFiler.fullName != null) length = length + 1;
    if (this.selectedFiler.loginFlagFilter != null) length = length + 1;
    if (this.selectedFiler.department != null) length = length + 1;
    if (this.selectedFiler.startDate != null) length = length + 1;
    // if (this.selectedFiler.endDate != null) length = length + 1;

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0) return "(None Selected)";
    else return `(${length} Filter Selected)`;
  }

  openFilterSection() {
    this.filterSelect = !this.filterSelect;
  }
  openFilterSectionForreport() {
    this.filterSelectForAttendanceReport =
      !this.filterSelectForAttendanceReport;
  }
  getColor(st) {
    if (st == 'A') {
      return '#EB4646';
    } else if (st == 'PL' || st == 'CL' || st == 'SL') {
      return '#FFA033';
    }
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;

    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
  filterPropertyType(event: any) { }
  filterProperty(event: any) {
    this.propertyListData = this.filteredpropertyListData.filter(
      (item: any) => {
        if (item.outletName != null) {
          return item.outletName
            .toLowerCase()
            .includes(this.filterPropertySearch.toLowerCase());
        }
      }
    );
  }
  filterDepartment(event: any) {
    this.departmentListData = this.filtereddepartmentListData.filter(
      (item: any) => {
        if (item.name != null) {
          return item.name
            .toLowerCase()
            .includes(this.filterDepartmentSearch.toLowerCase());
        }
      }
    );
  }

  closeAll() {
    this.propertyZone = false;
    this.isState = false;
    this.isCluster = false;
    this.isCityForFilter = false;
    this.isDepartmentFilter = false;
    this.isPropertyFilter = false
  }

  closeModel() {
    this.selectedFiler = {
      Clients: null,
      Zone: null,
      state: null,
      cluster: null,
      city: null,
      propertyType: null,
      property: null,
      loginFlagFilter: null,
      fullName: null,
      department: null,
      startDate: null,
      endDate: null,
    };
  }
  clearFilter() {
    this.selectedFiler = {
      Clients: null,
      Zone: null,
      state: null,
      cluster: null,
      city: null,
      propertyType: null,
      property: null,
      loginFlagFilter: null,
      fullName: null,
      department: null,
      startDate: null,
      endDate: null,
    };
    this.propertyList = [];
    this.filteredpropertyListData = []
    this.clusterListData = [];
    this.cityListData = []
    this.stateListData = []
    this.getAttendanceList();
    this.applyFilterForAttendanceReport();
  }

  getClients() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.ClientsListData = []
      //this.locationList = result['data'] 
      //this.filterpropertyList = result['data']
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

      // let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // // console.log(this.apiService.roleId)

    })
  }

  applyFilter() {
    this.apiService.startLoader();

    let req = {
      businessId: this.apiService.buisnessId,
      limit: this.pageSize,
      pageNo: this.activePage,
      loginFlag: "",
      sortBy: this.sortBy,
      sortType: this.sortType,
      role: this.apiService.role,
      authId: this.apiService.authId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      fullName: this.selectedFiler.fullName ? this.selectedFiler.fullName : "",
      loginFlagFilter: this.selectedFiler.loginFlagFilter ? this.selectedFiler.loginFlagFilter : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[0]).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[1]).format('YYYY-MM-DD') : "",
    };

    // console.log(req);
    this.closeAll();

    this.apiService.postCall("attendances/index", {}, req).subscribe((result: any) => {
      // console.log(result);
      if (result.msg === "No Data Available") {
        // console.log(result.msg);
        this.totalEmployee = 0;
        this.presentEmployee = 0;
        this.absentEmployee = 0;
        this.unmark = 0;
        this.absenteesPercentage = "0%";
        this.attendanceListData = [];
        this.apiService.stopLoader();
      } else {
        this.totalEmployee = result.totalEmployee;
        this.presentEmployee = result.presentEmployee;
        this.absentEmployee = result.absentEmployee;
        this.unmark = result.unmarkEmp;
        this.absenteesPercentage = result.todaysAbsenteesPercentage;

        this.attendanceListData = result.data;
        if (this.attendanceListData != null && this.attendanceListData.length > 0) {
          this.copyattendanceListData = this.attendanceListData.slice();
        }
        this.setPaginationArray(result);

        this.apiService.stopLoader();
      }
    });
  }
  downloadReportButton() {
    this.apiService.startLoader();
    let req = {
      businessId: this.apiService.buisnessId,
      limit: this.pageSize,
      pageNo: this.activePage,
      loginFlag: "",
      sortBy: this.sortBy,
      sortType: this.sortType,
      role: this.apiService.role,
      authId: this.apiService.authId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      fullName: this.selectedFiler.fullName ? this.selectedFiler.fullName : "",
      loginFlagFilter: this.selectedFiler.loginFlagFilter ? this.selectedFiler.loginFlagFilter : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[0]).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[1]).format('YYYY-MM-DD') : "",
      download: 1
    };
    this.closeAll();
    this.apiService.postCall("attendances/downloadTodayAttedences", {}, req).subscribe((result: any) => {
      this.apiService.stopLoader();
      if (result['success'] == true) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = result['url'];
        a.download = result['datePeriod'] + ".csv";
        a.click();
        document.body.removeChild(a);
      }
    });
  }
  message
  attendanceReportSend() {
    this.apiService.startLoader();

    var todayDate = new Date();
    var startDate = "";
    var endDate = "";

    switch (this.grapDurationReportValue) {
      case "Daily":
        startDate = this.datepipe.transform(todayDate, "dd-MM-yyyy");
        endDate = this.datepipe.transform(todayDate, "dd-MM-yyyy");
        break;
      case "Weekly":
        var weekFirstDate = todayDate.getDate() - todayDate.getDay() + 1;
        startDate = this.datepipe.transform(
          todayDate.setDate(weekFirstDate),
          "dd-MM-yyyy"
        );
        endDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");

        break;
      case "Fortnightly":
        if (todayDate.getDate() <= 15) {
          startDate = this.datepipe.transform(
            todayDate.setDate(1),
            "dd-MM-yyyy"
          );
          endDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");
        } else {
          var lastDateofMonth = new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() + 1,
            0
          );
          startDate = this.datepipe.transform(
            todayDate.setDate(16),
            "dd-MM-yyyy"
          );
          endDate = this.datepipe.transform(lastDateofMonth, "dd-MM-yyyy");
        }
        break;
      case "Monthly":
        var firstDateOfMonth = new Date(
          todayDate.getFullYear(),
          todayDate.getMonth(),
          1
        );
        startDate = this.datepipe.transform(firstDateOfMonth, "dd-MM-yyyy");
        endDate = this.datepipe.transform(todayDate, "dd-MM-yyyy");

        break;

      default:
        break;
    }

    var ids = []
    for (var i = 0; i < this.punchInOutAbsenceMultipleData.length; i++) {
      ids.push(this.punchInOutAbsenceMultipleData[i].id)
    }
    let req = {
      businessId: this.apiService.buisnessId,
      outletId: this.selectedSite === "All" ? "" : this.selectedSiteId,
      authId: this.apiService.authId,
      selectedIds: ids,
      startDate: startDate,
      endDate: endDate,
      email: this.writeEmail,
      myDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : this.datepipe.transform(new Date(), "yyyy-MM-dd"),
    };
    this.apiService
      .postCall("attendances/attendanceReportSend", {}, req)
      .subscribe((result: any) => {
        // console.log(result);
        ($("#staticBackdrop") as any).modal("hide");
        this.writeEmail = "";
        this.message = "Mail Sent Successfully.";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.grapDurationReportValue = "";
        this.apiService.stopLoader();
      });
  }
  grapReportDurationValue(data: string) {
    this.grapDurationReportValue = data;
  }


  /* ------------------------------------- apply filter for attendance  report------------------------------------ */

  applyFilterForAttendanceReport() {
    this.apiService.startLoader();
    let req = {
      businessId: this.apiService.buisnessId,
      // outletId: this.selectedSite === "All" ? "" : this.selectedSiteId,
      sortBy: this.sortBy,
      authId: this.apiService.authId,
      pageNo: this.activePage,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      deparmentId: this.selectedFiler.department ? this.selectedFiler.department.id : "",
      fullName: this.selectedFiler.fullName ? this.selectedFiler.fullName : "",
      loginFlagFilter: this.selectedFiler.loginFlagFilter ? this.selectedFiler.loginFlagFilter : "",
      //startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[0]).format('YYYY-MM-DD') : "",
      //endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate[1]).format('YYYY-MM-DD') : "",
      myDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
    };
    this.filterSelectForAttendanceReport =
      !this.filterSelectForAttendanceReport;

    this.apiService
      .postCall("attendances/attendanceReport", {}, req)
      .subscribe((result: any) => {
        if (result.success) {
          this.attendanceReportListData = result;
          // console.log(this.attendanceReportListData);
          this.sundayData = result.sunday;
          this.AttendanceDatareport = result.report;
          this.totalEmployee1 = result.totalEmployee;
          this.datePeriod = result.datePeriod;
          this.reportKeys = Object.keys(this.AttendanceDatareport);
          // console.log(this.sundayData);
          this.closeAll();

          this.apiService.stopLoader();
        } else {
          this.attendanceReportListData = [];
          // console.log(this.attendanceReportListData);
          this.sundayData = [];
          this.AttendanceDatareport = [];
          this.totalEmployee1 = 0;
          this.datePeriod = result.datePeriod;
          this.reportKeys = Object.keys(this.AttendanceDatareport);
          // console.log(this.sundayData);
          this.closeAll();
          this.apiService.stopLoader();
        }
      });
  }

  getDisableStatusIn(attend) {
    if (attend.loginFlag == 0 || attend.loginFlag == 1 || attend.loginFlag == null) {
      return true;
    }
    if (attend.loginFlag == 2) {
      return true;
    }
    return false;
  }

  getDisableStatusOut(attend) {
    if (attend.loginFlag == 0 || attend.loginFlag == 2 || attend.loginFlag == 3 || attend.loginFlag == null) {
      return true;
    }
    return false;
  }

  getDisableStatusAbsent(attend) {
    if (attend.loginFlag == 0) {
      return true;
    }
    if (attend.loginFlag === 1 || attend.loginFlag === 2) {
      return true;
    }
    return false;
  }

  getDisableStatusName(attend) {
    if (attend.loginFlag == 0 && attend.absentTypesName != "-") {
      return attend.absentTypesName;
    } else {
      if (!attend.status) {
        return "Absent"
      } else {
        return attend.status;
      }
    }
  }

  filterSelect1 = false
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      // this.filterSelect= false

      this.propertyZone = false;
      this.isState = false;
      this.isCluster = false;
      this.isCityForFilter = false;
      this.isDepartmentFilter = false;
      this.isPropertyFilter = false

    } else if (btn.id != 'custom') {
      this.filterSelect = false
      this.filterSelect1 = false
      this.propertyZone = false;
      this.isState = false;
      this.isCluster = false;
      this.isCityForFilter = false;
      this.isDepartmentFilter = false;
      this.isPropertyFilter = false
      this.showsiteDropdownData = false;

    }
    if (btn.id != "custom1") {
      this.showAttendanceDropdownData = false
      $(".showAttendanceDropdownData").css("display", "none");
    }
  }

  getEmail() {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !regularExpression.test(String(this.writeEmail).toLowerCase());
  }
  openTop(id) {
    (<HTMLInputElement>document.getElementById(id)).click();
  }
}
