import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-business-edit',
  templateUrl: './business-edit.component.html',
  styleUrls: ['./business-edit.component.css']
})
export class BusinessEditComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService: ApiService,
    public storedData: StoredData,
  ) { }
  businessForm: FormGroup
  saveData = false;
  message: any;
  businessId: any;
  todayDate: any = new Date();
  curencyList = [];
  regionList = [];
  ngOnInit(): void {
    this.businessId = this.route.url.split("/")[2];
    document.body.style.overflow = 'hidden';
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    this.businessForm = this.fb.group({
      name: [null, Validators.required],
      isSingleProperty: [null, Validators.required],
      maxUsers: [null, Validators.required],
      maxProperties: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      city: [null, Validators.required],
      address: [null, Validators.required],
      description: [null],
      latLogStatus: [0],
      logo: [null],
      permitStartTime: [null],
      permitEndTime: [null],
      permitMobileNo: [null],
      permitUserId: [null],
      region_id: [null],
      currency_id: [null],
    });
    //this.getDetails();
    this.getBusinessSettings();
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  getValidation() {
    return this.businessForm.valid;
  }

  isSingleProperty
  logo
  permitStartTime1
  permitEndTime1
  getDetails(): void {
    this.apiService.startLoader()
    this.apiService.getCall('/businesses/view/' + this.businessId, {},).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['success'];
      this.message = result['msg'];
      if (result['success'] == 'true') {
        var data = result['data'];
        if (data.logo) {
          this.logo = data.logo;
        }
        if (data.isSingleProperty == 1) {
          this.isSingleProperty = true
        }
        if (result['data'].permitStartTime) {
          this.permitStartTime1 = new Date();
          this.permitStartTime1.setHours(result['data'].permitStartTime.split(':')[0])
          this.permitStartTime1.setMinutes(result['data'].permitStartTime.split(':')[1])
          this.permitStartTime1.setSeconds(result['data'].permitStartTime.split(':')[2])
        }
        if (result['data'].permitEndTime) {
          this.permitEndTime1 = new Date();
          this.permitEndTime1.setHours(result['data'].permitEndTime.split(':')[0])
          this.permitEndTime1.setMinutes(result['data'].permitEndTime.split(':')[1])
          this.permitEndTime1.setSeconds(result['data'].permitEndTime.split(':')[2])
        }
        this.businessForm = this.fb.group({
          name: data.name,
          isSingleProperty: data.isSingleProperty == 1 ? true : false,
          maxUsers: data.maxUsers,
          maxProperties: data.maxProperties,
          startDate: data.startDate,
          endDate: data.endDate,
          city: data.city,
          address: data.address,
          description: data.description,
          latLogStatus: data.latLogStatus,
          logo: null,
          permitStartTime: this.permitStartTime1,
          permitEndTime: this.permitEndTime1,
          permitMobileNo: data.permitMobileNo,
          permitUserId: data.permitUserId,
          region_id: this.old_region_id,
          currency_id: this.old_currency_id,
        });
      }
    });
  }

  submitBusinessForm(): void {
    let formData = this.businessForm.value;

    let testData: FormData = new FormData();
    if (this.uploadedFiles) {
      testData.append('logo', this.uploadedFiles);
    } else {
      testData.append('logo', "");
    }
    testData.append('name', this.businessForm.value.name);
    testData.append('maxUsers', this.businessForm.value.maxUsers);
    testData.append('city', this.businessForm.value.city);
    testData.append('address', this.businessForm.value.address);
    testData.append('description', this.businessForm.value.description);
    testData.append('latLogStatus', this.businessForm.value.latLogStatus);
    testData.append('userId', this.apiService.authId);
    if (formData.isSingleProperty == true) {
      testData.append('isSingleProperty', 1 + "");
      testData.append('maxProperties', 1 + "");
    } else {
      testData.append('isSingleProperty', 0 + "");
      testData.append('maxProperties', this.businessForm.value.maxProperties);
    }
    testData.append('startDate', moment(this.businessForm.value.startDate).format('YYYY-MM-DD'));
    testData.append('endDate', moment(this.businessForm.value.endDate).format('YYYY-MM-DD'));
    testData.append('permitStartTime', this.businessForm.value.permitStartTime ? moment(this.businessForm.value.permitStartTime).format('HH:mm:ss') : '');
    testData.append('permitEndTime', this.businessForm.value.permitEndTime ? moment(this.businessForm.value.permitEndTime).format('HH:mm:ss') : '');
    testData.append('permitMobileNo', this.businessForm.value.permitMobileNo);
    testData.append('permitUserId', this.businessForm.value.permitUserId);
    testData.append('region', this.businessForm.value.region_id);
    testData.append('cureency', this.businessForm.value.currency_id);
    /*if(formData.isSingleProperty == true){
      formData.isSingleProperty = 1;
    }else{
      formData.isSingleProperty = 0;
    }
    formData.startDate = moment(this.businessForm.value.startDate).format('YYYY-MM-DD'),
    formData.endDate = moment(this.businessForm.value.endDate).format('YYYY-MM-DD'),*/
    // console.log("FormData : ",formData);
    this.apiService.startLoader()
    this.apiService.postCall('businesses/edit/' + this.businessId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.saveData = result['success'];
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }
  goToIndex() {
    this.route.navigate(['/business-index']);
  }
  attchments = []
  uploadedFiles
  fileName
  fileSize: boolean
  fileChange(event) {
    this.fileSize = false
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log('size', file.size);
      if (file.size < 5269767) {
        // console.log(file)
        file = file
        this.uploadedFiles = file;
        this.fileName = file.name
        this.attchments.push({ file: file, fileName: file.name })
      }
      else {
        this.fileSize = true
      }
    }
  }
  removeImage() {
    this.fileName = null;
    this.attchments = [];
  }
  old_region_id
  old_currency_id
  getBusinessSettings() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessSettings/' + this.businessId, {}).subscribe((result: any[]) => {
      if (result['success']) {
        //this.data = result['data'];
        this.old_region_id = result['data'].region_id;
        this.old_currency_id = result['data'].currency_id;
        // this.businessForm = this.fb.group({
        //   region_id: this.data.region_id,
        //   currency_id: this.data.currency_id,
        // });
      }
      this.apiService.stopLoader();
      this.getCurencys();
      this.getDetails();
    })
  }
  getCurencys() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getRegionCurrencys', {}).subscribe((result: any[]) => {

      if (result['success']) {
        this.regionList = result['data']['regions'];
        this.curencyList = result['data']['currency'];
      }
      this.apiService.stopLoader()
    })
  }
}
