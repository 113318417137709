import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from '../common/services/apiCalls.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-inspection-monthly-data',
  templateUrl: './inspection-monthly-data.component.html',
  styleUrls: ['./inspection-monthly-data.component.css']
})
export class inspectionMonthlyDataComponent implements OnInit {

  constructor(public route: Router,public _location: Location, public apiService: ApiService,) { }

  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;
  propertyFiletr = false;
  dataDownloadSuccessfullyFlag = false;

  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];


  sendEmailPermission = false
  downloadPdfPermission = false
  viewReportPermission = true
  scheduleViewPermission = false
  checklistViewPermission = false
  todayDate: any;
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  completionStatus = [{ id: 1, name: 'Completed' }, { id: 0, name: 'Incompleted' }];
  formatPdfExcelList = [{ id: 1, name: 'Excel' }, { id: 2, name: 'PDF' }];
  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    //this.getPropertyList()
    this.getClients()
    //this.getInspectionMainList();
    this.getDepartmentList();
    this.getUserList()
    this.getZoneListData();
    this.getBusinessList()
    //checkPermission
  }
  filterSelect: boolean;
  getClients() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.ClientsListData = []
      //this.locationList = result['data'] 
      //this.filterpropertyList = result['data']
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

      // let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // // console.log(this.apiService.roleId)

    })
  }

  getSelectdEmail() {
    return 2;
  }
  selectedAssets = []
  // pageSize=10;
  // pageNo=1;
  tabSelected = ''
  inspectionList
  inspectionListPrevious

  sortSelected: any;
  sort: any;

  getInspectionMainList() {
    this.apiService.startLoader();
    this.dataDownloadSuccessfullyFlag = false;
    // this.selectedFiler={name:null , property:null  , department:null, user:null , dateRange:null , }
    this.inspectionListPrevious = []
    let body = {}
    if (this.selectedFiler.email != null && this.selectedFiler.email != '') {
      body = {
        sortType: this.sort,
        sortBy: this.sortSelected,
        pageNo: this.activePagePrev,
        limit: this.pageSizePrev,
        name: this.selectedFiler.name,
        email: this.selectedFiler.email,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
        from: this.selectedFiler.startDate ? this.selectedFiler.startDate : null,
        to: this.selectedFiler.ensDate ? this.selectedFiler.ensDate : null,
        floorPlanId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
        //  bussinessId:this.selectedFiler.business?this.selectedFiler.business.id:null,
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        //  bussinessId:this.selectedFiler.business?this.selectedFiler.business.id:null,
        userId: this.apiService.userId,
        "roleId": this.apiService.roleId,

        "businessId": this.apiService.buisnessId,

        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontLocationNameFilter: this.selectedFiler.location ? this.selectedFiler.location.name : "",
        frontUserNameFilter: this.selectedFiler.user ? this.selectedFiler.user.fullName : "",
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
        completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
        formatPdfExcel: this.selectedFiler.formatPdfExcel ? this.selectedFiler.formatPdfExcel.id : 1,
      }
      this.apiService.postCall('inspections/montlyDataExportExcel', {}, body).subscribe((result: any[]) => {
        this.apiService.stopLoader();
        if (result['success'] == true) {
          // let a = document.createElement("a");
          // document.body.appendChild(a);
          // a.href = result['data'];
          // a.download = "inspection_report.csv";
          // a.click();
          // document.body.removeChild(a);
          this.dataDownloadSuccessfullyFlag = true;
        }
      }
      )
    } else {
      this.apiService.stopLoader();
      alert('Please enter email id');
    }
  }
  download = false

  applyFilter() {
    this.selectedPrevInspection = [];
    this.selectedTodayInspection = [];
    this.applyFlag = true;
    this.getInspectionMainList()
  }
  selectedFiler = { email: null, name: null, property: null, user: null, department: null, location: null, startDate: null, ensDate: null, dateRange: null, business: null, Clients: null, Zone: null, state: null, cluster: null, city: null, completionStatus: null,formatPdfExcel:null }
  clear() {
    this.selectedFiler = { email: null, name: null, property: null, user: null, department: null, location: null, startDate: null, ensDate: null, dateRange: null, business: null, Clients: null, Zone: null, state: null, cluster: null, city: null, completionStatus: null,formatPdfExcel:null }
    this.propertyList = [];
    this.filterpropertyList = []
    this.filterdepartmentList = []
    this.clusterListData = [];
    this.cityListData = [];
    this.stateListData = [];
    this.selectedPrevInspection = [];
    this.selectedTodayInspection = [];
    //this.getPropertyList()
    this.getInspectionMainList()
  }
  filterdepartmentList = []
  filterDepartmentSearch
  filterdepartment(ev) {
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }
  filterPropertySearch
  filterProperty(ev) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // if(idx != -1){
      // if(this.apiService.roleId == '3')  {

      //    this.selectedFiler.property = this.propertyList[idx] 
      //    this.getUserList()

      //  }
      //  else{

      //    this.selectedFiler.property = this.propertyList[idx] 
      //    this.getUserList()
      //  }
      // }
      this.getUserList()
      //   if (result["clients"]) {
      //     this.ClientsListData = result["clients"];
      //   }
      //   this.filteredClientsListData = this.ClientsListData.slice();
    })
  }

  filterUserSearch
  filterUsers(ev) {
    this.userList = this.filetrUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  userList
  filetrUserList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.userList = result['data']
      this.filetrUserList = this.userList;
    })
  }
  departmentList
  filterDepartmentList = []
  getDepartmentList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('types/pluckDepartments/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }
  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    this.locationList = []
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('locations/locationList/' + this.apiService.buisnessId + "/" + this.selectedFiler.property.id, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      this.locationList = result['data'];
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();
    })
  }

  // departmentList=[{"departmentId": 1, "departmentName": "Operations,",}, {"departmentId": 108, "departmentName": "Security",}, {"departmentId": 106, "departmentName": "Cleaning",},]
  // filterDepartmentList =[{"departmentId": 1, "departmentName": "Operations,",}, {"departmentId": 108, "departmentName": "Security",}, {"departmentId": 106, "departmentName": "Cleaning",},]

  filterDepartmetsearch;
  filterDepartmet(ev) {
    this.departmentList = this.filterDepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmetsearch.toLowerCase())
      }
    })
  }

  filterLocationSearch
  filterLocation(ev) {
    this.locationList = this.filterDepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })
  }

  deactiveSelected() { }
  dublicateSelected() { }


  // selectAllAssets
  chnageStatus
  message
  applyFlag

  totalnoOfPagesPrev
  activePagePrev: any = 1
  pageSizePrev = 10;
  paginationArrayPrev = []

  propertFilter = false;
  locationFilter = false
  businessFilter = false;
  departmentFilter = false
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      // console.log('in');
      this.filterSelect = false
      this.assetFilter = false;
      this.assignedFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      this.locationFilter = false
      this.departmentFilter = false
      this.completionStatusFilter = false;
      this.formatPdfExcelFilter = false;
      // this.selectFreq = false
    } else {

    }
  }
  assignedFilter = false;
  assetFilter = false;
  completionStatusFilter = false;
  formatPdfExcelFilter = false;
  closeAll() {
    this.assignedFilter = false;
    this.assetFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
    this.departmentFilter = false;
    this.completionStatusFilter = false;
    this.formatPdfExcelFilter = false;
  }

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.email != null && this.selectedFiler.email != "") length = length + 1
    if (this.selectedFiler.name != null && this.selectedFiler.name != "") length = length + 1
    if (this.selectedFiler.department != null && this.selectedFiler.department) length = length + 1
    if (this.selectedFiler.location != null && this.selectedFiler.location) length = length + 1
    if (this.selectedFiler.user != null && this.selectedFiler.user) length = length + 1
    if (this.selectedFiler.property != null && this.selectedFiler.property != "") length = length + 1
    if (this.selectedFiler.business != null && this.selectedFiler.business != "") length = length + 1
    if (this.tabSelected == 'prevReports' && this.selectedFiler.dateRange != null && this.selectedFiler.dateRange != "") length = length + 1
    if (this.tabSelected == 'prevReports' && this.selectedFiler.startDate != null && this.selectedFiler.startDate != "") length = length + 1
    if (this.tabSelected == 'prevReports' && this.selectedFiler.ensDate != null && this.selectedFiler.ensDate != "") length = length + 1
    if (this.selectedFiler.Clients != null) length = length + 1;
    if (this.selectedFiler.Zone != null) length = length + 1;
    if (this.selectedFiler.city != null) length = length + 1;
    if (this.selectedFiler.cluster != null) length = length + 1;
    if (this.selectedFiler.state != null) length = length + 1;
    if (this.selectedFiler.completionStatus != null) length = length + 1;
    if (this.selectedFiler.formatPdfExcel != null) length = length + 1;
    // for(let d of this.selectedFiler)
    // // console.log(this.selectedFiler);
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  filterBusinessSearch
  // businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      // let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      // if(this.apiService.roleId == '2') {
      //   this.selectedFiler.business =  this.bussnissList[idx]
      //   this.getPropertyList()
      //   }
      //   else if(this.apiService.roleId == '3')  {
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()

      //   }
      //   else{
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()
      //   }

      this.getPropertyList()
    })
  }


  jumpToPagePre = 1
  jumpToPage = 1
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }



  selectedTodayInspection = []
  selectAllInspection: boolean
  selectAllAssets(ev) {
    this.selectedTodayInspection = []

    this.selectAllInspection = ev.target.checked;
    if (ev.target.checked) {
      // this.selectAllInspection = true;
      this.inspectionList.map(x => x.selected = true)
      for (let department of this.inspectionList) {
        this.selectedTodayInspection.push(department.id)
        // this.selectedTodayInspectionDetailsList.push(asset)
      }
    }
    else {

      this.inspectionList.map(x => x.selected = false)
      this.selectedTodayInspection = []
      // this.selectedTodayInspectionDetailsList = []
    }
    // console.log(this.selectedTodayInspection)
  }

  selectAsset(ev, department) {
    if (ev.target.checked) {
      this.selectedTodayInspection.push(department.id)
      // this.selectedTodayInspectionDetailsList.push(asset)
    }
    else {
      this.selectedTodayInspection.splice(this.selectedTodayInspection.findIndex(x => x == department.id), 1)
      // this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.assetId == asset.assetId), 1)
    }

    if (this.selectedTodayInspection.length == this.inspectionList.length) this.selectAllInspection = true;
    else this.selectAllInspection = false;
  }

  selectedPrevInspection = []
  selectAllInspectionPrev: boolean
  selectAllAssetsPrev(ev) {
    this.selectedPrevInspection = []
    this.selectAllInspectionPrev = ev.target.checked;
    if (ev.target.checked) {

      this.inspectionListPrevious.map(x => x.selected = true)
      for (let department of this.inspectionListPrevious) {
        this.selectedPrevInspection.push(department.id)
        // this.selectedPrevInspectionDetailsList.push(asset)
      }
    }
    else {
      this.inspectionListPrevious.map(x => x.selected = false)
      this.selectedPrevInspection = []
      // this.selectedPrevInspectionDetailsList = []
    }
    // console.log(this.selectedPrevInspection)
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;

    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
  goBack():void{
    this._location.back();
  }
}





[{ "key": "name", "value": "", "description": "" }, { "key": "isActive", "value": "", "description": "" }, { "key": "outletId", "value": "", "description": "" }, { "key": "departmentId", "value": "", "description": "" }, { "key": "floorPlanId", "value": "", "description": "" }, { "key": "from", "value": "", "description": "" }, { "key": "to", "value": "", "description": "" }]