import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-inspection-schedule-view',
  templateUrl: './inspection-schedule-view.component.html',
  styleUrls: ['./inspection-schedule-view.component.css']
})
export class InspectionScheduleViewComponent implements OnInit {

  constructor(public _location: Location,public route: Router,public router:Router ,private fb: FormBuilder,public apiService:ApiService,public storedData:StoredData ,) { }

  scheduleId

  editPermission=false
  printQrPermission=false

  ngOnInit(): void {
    this.scheduleId = this.router.url.split("/")[2]
    this.getInspectionData()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==1 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
      if(permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "Print QR Code" && permission[i]['actionStatus'] == 1){
        this.printQrPermission=true
      }
    }
  }

scheduleDetails:any;
  getInspectionData(){
    this.apiService.getCall('schedules/view/'+this.scheduleId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.scheduleDetails= result['data']
  
  // this.locationList = result['data']
     
    })
  }

  backClicked() {
    this.route.navigate(['/inspection-schedule-list'])
  }

  getFrequency(d){

    // // console.log(d)
    if(d.scheduleType == 1 ) return "Repeat Once"
    if(d.scheduleType == 2 ) return "Repeat every day"
    if(d.scheduleType == 3 ) return "Repeat every week"
    if(d.scheduleType == 4 ) return "Repeat every month"
    if(d.scheduleType == 6 ){
      
    let str= ""
    
    if(d.customType == 1) return "Repeat in every "+d.repeatDays+ " Days"
    else if(d.customType == 2) return "Repeat in every "+d.repeatDays+ " Week"
    else if(d.customType == 3) return "Repeat in every "+d.repeatDays+ " Month"
    else if(d.customType == 4) return "Repeat in every "+d.repeatDays+ " Year"
    }
  }

  navigateToedit(){
    this.route.navigate(['/inspection-schedule-edit/'+this.scheduleId])
  }

  openQR(){
    this.storedData.qrData={name:this.scheduleDetails.inspectionName,image : this.scheduleDetails.qrCode, customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }
}
