import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { StoredData } from '../services/storedData.service';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ApiService } from '../services/apiCalls.service';

@Component({
  selector: 'app-qr-html',
  templateUrl: './qr-permit-html.component.html',
  styleUrls: ['./qr-permit-html.component.css']
})
export class QrPermitHtmlComponent implements OnInit {

  constructor(public _location: Location, public storedData: StoredData, public route: ActivatedRoute, public apiService: ApiService) { }

  showClose = true;
  logoDisplayConfirmationPermission = false;
  ngOnInit(): void {
    // console.log(this.storedData.qrData)
    document.body.style.overflow = 'hidden';
    this.route.queryParams

      .subscribe(params => {
        // console.log(params); // { order: "popular" }
        if (!this.storedData.qrData.name) {
          this.showClose = false
          this.storedData.qrData = { name: params.name, image: params.image, customerComplaintName: params.customerComplaintName }
        }
        // this.order = params.order;

        // // console.log(this.order); // popular
      }
      );
      let businessFeatures = [];
      businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
      if (businessFeatures != null) {
        for (var i = 0; i < businessFeatures.length; i++) {
          if (businessFeatures[i]['business_app_feature_id'] == 27 && businessFeatures[i]['permission_allow'] == 1) {
            this.logoDisplayConfirmationPermission = true;
          }
        }
      }
  }
  customerComplaintName
  backClicked() {
    this._location.back()
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  printDiv(divName) {
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // // document.body.innerHTML = printContents;

    // window.print();

    // window.close();
    // document.body.innerHTML = originalContents;
    let fontSize = ((16 / 100) * this.slider)
    let fontSizeHead = ((40 / 100) * this.slider)
    let buisnessLogo = this.apiService.buisnessLogo
    var mywindow = window.open('', 'PRINT', `height=400,width=600`);

    // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    // mywindow.document.write('</head><body >');
    mywindow.document.write("<div style='border: 1px solid #ccc; text-align: center; width: fit-content; margin: auto;  padding: 8%; border-radius: 25px;'>");
    mywindow.document.write(`<p  style='font-size:${fontSizeHead}px'>` + this.storedData.qrData.name + `</p>`);
    if (this.storedData.qrData.customerComplaintName) {
      mywindow.document.write(`<p  style='font-size:${fontSizeHead}px'>` + this.storedData.qrData.customerComplaintName + `</p>`);
    }
    mywindow.document.write('<br>');
    mywindow.document.write(document.getElementById(divName).innerHTML);
    // mywindow.document.write(`<p id="textPoweredBy" style='font-size:${fontSize}px'>Powered by</p>`);
    // mywindow.document.write('<br>');
    mywindow.document.write(`<img src='${buisnessLogo}' style="width: 100px;height: 50px;">`);
    // mywindow.document.write('<img  src="./assets/images/icons/logo.svg" style="width: 50px;height: 50px;">');
    mywindow.document.write('<br>');
    mywindow.document.write(`<p id="textPoweredByLogo" style='font-size:${fontSize}px;float: right;color: #33ccb2;'>Powered by Proptor</p>`);

    mywindow.document.write("</div>");
    // mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
    // var originalContents = JSON.stringify(document.body.innerHTML);

    //   var printContents = document.getElementById(divName).innerHTML;


    //   document.body.innerHTML = printContents;

    //   window.print();
    //   window.close();
    //   document.body.innerHTML = JSON.parse(originalContents);


  }


  @HostListener("click", ["$event"])
  public onClick(event: any): void {

  }

  slider = 100
  changeSlider(ev) {
    // console.log(this.slider)
    let obj = (<HTMLInputElement>document.getElementById('printableAreaLogo'))

    obj.style.width = ((180 / 100) * this.slider) + "px"
    obj.style.height = ((180 / 100) * this.slider) + "px"
    let obj11 = (<HTMLInputElement>document.getElementById('printableArea'))

    obj11.style.width = ((180 / 100) * this.slider) + "px"
    obj11.style.height = ((180 / 100) * this.slider) + "px"

    let obj1 = (<HTMLInputElement>document.getElementById('gridBox'))

    obj1.style.width = ((600 / 100) * this.slider) + "px"

    let obj2 = (<HTMLInputElement>document.getElementById('textPoweredBy'))

    obj2.style.fontSize = ((16 / 100) * this.slider) + "px"

    let obj3 = (<HTMLInputElement>document.getElementById('textPoweredByLogo'))

    obj3.style.fontSize = ((16 / 100) * this.slider) + "px"

    let objImg = (<HTMLInputElement>document.getElementById('poweredLogo'))

    objImg.style.width = ((50 / 100) * this.slider) + "px"
    objImg.style.height = ((50 / 100) * this.slider) + "px"

    let objImgPrint = (<HTMLInputElement>document.getElementById('headerText'))

    // objImgPrint.style.padding=((8/100)*this.slider)+"px "+ ((60/100)*this.slider)+"px" 
    objImgPrint.style.fontSize = ((20 / 100) * this.slider) + "px"

    // let objImgPrintIcon = (<HTMLInputElement>document.getElementById('printIcon'))

    // objImgPrintIcon.style.width=(this.slider)+"%" 


    // obj.style.height=((180/100)*this.slider)+"px"
  }



  exportAsPDF(div_id) {

    setTimeout(() => {



      // document.getElementById("pdfButton1").style.opacity="0";

      let data = document.getElementById(div_id);
      // console.log(data.clientHeight)
      html2canvas(data, {
        // logging: true, 
        allowTaint: true,
        // letterRendering: 1, 
        useCORS: true,
        width: 2480,
        height: data.clientHeight + 100

      }).then((canvas) => {
        let imgWidth = 500;
        let pageHeight = 480;
        let imgHeight =
          ((canvas.height * imgWidth) / 2454) * 1.24;
        var heightLeft = imgHeight;

        const imgData = canvas.toDataURL("image/png");

        const pdf = new jspdf({
          orientation: "p",
          unit: "mm",
          format: [400, 480],
        });

        let position = 10;
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("Result.pdf");
        // document.getElementById("pdfButton1").style.opacity="1";
      });

    }, 1000);
  }
}
