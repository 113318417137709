import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-trainings-list',
  templateUrl: './trainings-list.component.html',
  styleUrls: ['./trainings-list.component.css']
})
export class TrainingsListComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }

  addMeterPermission = false
  viewMeterPermission = true

  getIds(ev){
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property=ev.property
    if(ev.flag==1){
      this.clear()
    }else if(ev.flag==0){
      this.applyFilter()
    }
  
    // console.log(ev);
    //this.applyFilter();
  }
  ngOnInit(): void {

    var tarinningPagination = localStorage.getItem('tarinningPagination');
    if (tarinningPagination) {
      this.activePage = parseInt(tarinningPagination);
    }
    this.getTrainingsList()
    this.getBusinessList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.addMeterPermission = true
      }
    }
  }

  // let obj ={
  //   "roleId":this.apiService.roleId,
  //   "userId":this.apiService.userId,
  //   "businessId": this.apiService.buisnessId,
  //   "outletId":this.tiggerDetailsForm.value.property?this.tiggerDetailsForm.value.property : "",
  // }

  getTrainingsList() {
    this.apiService.startLoader()
    // this.applyFlag=false;
    this.trainingList = []
    let body = {}
    if (localStorage.getItem("traningFilterLocal") && localStorage.getItem("traningFilterLocal") != null && localStorage.getItem("traningFilterLocal") != 'null') {
      let traningFilterLocal = JSON.parse(localStorage.getItem("traningFilterLocal"));
      body = traningFilterLocal;
      if (traningFilterLocal.propertyId && traningFilterLocal.propertyId != null && traningFilterLocal.propertyId != 'null') {
        this.selectedFiler.property = { id: traningFilterLocal.propertyId, outletName: traningFilterLocal.frontOutletNameFilter }
      }
      this.selectedFiler.title = traningFilterLocal.title
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        title: this.selectedFiler.title,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        // businessId :this.selectedFiler.business ?this.selectedFiler.business.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,

        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        zoneId:this.selectedFiler.zoneId,
        cluserId:this.selectedFiler.cluserId ,
        stateId :this.selectedFiler.stateId,
        cityId:this.selectedFiler.cityId,
        clientId:this.selectedFiler.clientId,
      }
      localStorage.setItem('traningFilterLocal', JSON.stringify(body));
    }
    this.apiService.postCall('trainings/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result)
      this.trainingList = result['data']

      this.apiService.stopLoader()

    })
  }

  clear() {
    localStorage.removeItem('traningFilterLocal');
    localStorage.setItem("traningFilterLocal", null);
    this.applyFlag = false;
    this.selectedFiler = { title: null, property: null, business: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null, }
    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx]
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
        // this.getPropertyList()

      }
      else {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
      }
    }

    this.getTrainingsList()
  }
  paginationArray = []

  pageSize = 10;
  activePage: any = 1;
  message
  traning_id
  totalnoOfPages
  changePage(ev) {
    localStorage.removeItem('traningFilterLocal');
    localStorage.setItem("traningFilterLocal", null);
    // localStorage.removeItem('traningFilterLocal');
    // localStorage.setItem("traningFilterLocal", null);
    this.selectedAssets = []
    // this.pageSize = d
    this.getTrainingsList()
    window.scrollTo(0, 0);
  }
  setDeleteTraining(id) {
    this.traning_id = id;
  }
  deleteTraining() {
    this.apiService.startLoader()
    this.apiService.postCall('trainings/delete/' + this.traning_id).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      if (result['success'] == true) {
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess1')).click();
        this.getTrainingsList();
      }
    })
  }
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    // console.log(noOfPAges <= 5)
    // console.log(4 + this.activePage < this.totalnoOfPages)
    // console.log(4 + this.activePage >= 6)

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)

    // if (this.activePage) {
    //   this.pagination(this.activePage);
    // }
  }
  trainingList = []
  selectedAssets = []


  getCustomName(d) {
    return 'Every ' + d.frequency_no + " " + d.frequency_unit
  }
  pagination(d) {
    localStorage.removeItem('traningFilterLocal');
    localStorage.setItem("traningFilterLocal", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('tarinningPagination', d);
      this.getTrainingsList()
    }
  }

  sortSelected: any = null
  sort: any = null

  sortData(data) {
    localStorage.removeItem('traningFilterLocal');
    localStorage.setItem("traningFilterLocal", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getTrainingsList()
  }


  selectedFiler = { title: null, property: null, business: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null, }

  locationList = []
  filterlocationList = []
  getLocationList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']
      this.filterlocationList = result['data']

      this.storedData.locationList = this.filterlocationList;

    })


  }

  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      if (idx != -1) {
        if (this.apiService.roleId == '2') {
          this.selectedFiler.business = this.bussnissList[idx]
          this.getPropertyList()
        }
        else if (this.apiService.roleId == '3') {
          this.selectedFiler.business = this.bussnissList[idx]
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
          this.getPropertyList()

        }
        else {
          this.selectedFiler.business = this.bussnissList[idx]
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
          this.getPropertyList()
        }

      }
      else {
        this.getMainPropertyList()
      }

    })
  }


  filterLocationSearch: any
  filterLocation(ev) {
    // console.log(ev)
    this.locationList = this.filterlocationList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })

  }
  filterPropertySearch
  propertFilter = false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }


  applyFlag = false;
  applyFilter() {
    localStorage.removeItem('traningFilterLocal');
    localStorage.setItem("traningFilterLocal", null);
    this.applyFlag = true;
    this.activePage = 1;
    let body = {}
    if (localStorage.getItem("traningFilterLocal") && localStorage.getItem("traningFilterLocal") != null && localStorage.getItem("traningFilterLocal") != 'null') {
      let traningFilterLocal = JSON.parse(localStorage.getItem("traningFilterLocal"));
      body = traningFilterLocal;
      if (traningFilterLocal.propertyId && traningFilterLocal.propertyId != null && traningFilterLocal.propertyId != 'null') {
        this.selectedFiler.property = { id: traningFilterLocal.propertyId, outletName: traningFilterLocal.frontOutletNameFilter }
      }
      this.selectedFiler.title = traningFilterLocal.title
    } else {
      body = {
        activePage: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        title: this.selectedFiler.title,
        propertyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        // businessId :this.selectedFiler.business ?this.selectedFiler.business.id : null,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        zoneId:this.selectedFiler.zoneId,
        cluserId:this.selectedFiler.cluserId ,
        stateId :this.selectedFiler.stateId,
        cityId:this.selectedFiler.cityId,
        clientId:this.selectedFiler.clientId,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
      }
      localStorage.setItem('traningFilterLocal', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('trainings/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result)
      this.trainingList = result['data']

      this.apiService.stopLoader()

    })
  }
  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.title != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1
    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  // businessFilter= false;


  navigateToDetails(d) {
    //this.route.navigate(['/TrainingUsers/index/'+d.id+'/'+this.apiService.userId])
  }
  goToDetails(id) {
    this.route.navigate(['/training-details/' + id.id])
  }
  deactiveSelected() { }
  dublicateSelected() { }
  filterSelect = false;
  assetFilter = false;
  locationFilter = false;


  bussnissList = []
  bussnissListFilter = []
  propertyList = []
  filterpropertyList = []

  getPropertyList() {
    this.apiService.startLoader()
    // console.log(this.selectedFiler.business)
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)



    })



  }

  jumpToPage
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }
  getMainPropertyList() {
    this.apiService.startLoader()
    // console.log(this.selectedFiler.business)
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    // this.apiService.getCall('outlets/outlet-list/'+this.selectedFiler.business.id,{}  ).subscribe((result: any[])=>{
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  getMianPropertyList() {
    this.apiService.startLoader()
    // console.log(this.selectedFiler.business)
    this.apiService.getCall('outlets/outlet-list/' + this.selectedFiler.business.id, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)



    })



  }

  callGetOutside() {
    // console.log("d")
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.assetFilter = false;
      this.locationFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
  }
  closeAll() {
    this.assetFilter = false;
    this.locationFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
  }


}
