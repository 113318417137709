import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../../common/services/apiCalls.service';
import { StoredData } from '../../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-permit-associated-list',
  templateUrl: './permit-associated-list.component.html',
  styleUrls: ['./permit-associated-list.component.css']
})
export class PermitAssociatedListComponent implements OnInit {
  tabSelected = 'active';
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  statusSelection;
  addPermission = false;
  editPermission = false;
  message;
  constructor(public route: Router, public router: Router, public apiService: ApiService, public storedData: StoredData) {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    // for(var i=0;i<permission.length;i++){
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
    //     this.addPermission=true
    //   }
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
    //     this.editPermission=true
    //   }
    //
    // }
  }

  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ permitList: this.tabSelected, assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }))
  }
  ngOnInit(): void {

    // var meterListPagination = localStorage.getItem('meterListPagination');
    // if (meterListPagination) {
    //   this.activePage = parseInt(meterListPagination);
    // }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).permitList
      let tabselection = JSON.parse(localStorage.getItem("tabselection")).permitList
      if (tabselection == undefined) {
        this.tabSelected = 'active';
      }
    }
    if (this.router.url.split("/")[3]) {
      this.tabSelected = this.router.url.split("/")[3];
      if (this.router.url.split("/")[3] == 'active') {
        this.getList(1);
        this.setTabSelection();
      }
      else if (this.router.url.split("/")[3] == 'archived') {
        this.getList(0);
        this.setTabSelection();
      }
    } else {
      if (this.tabSelected == 'active') {
        this.getList(1);
      }
      else if (this.tabSelected == 'archived') {
        this.getList(0);
      }
    }
    this.getBusinessList();
    // this.getpropertyList()
    // this.getMainLocationList();
    // this.getMianAssetList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }
  // let obj ={
  //   "roleId":this.apiService.roleId,
  //   "userId":this.apiService.userId,
  //   "businessId": this.apiService.buisnessId,
  //   "outletId":this.tiggerDetailsForm.value.property?this.tiggerDetailsForm.value.property : "",
  // }
  getList(isActive: any = 1) {
    this.apiService.startLoader();
    // this.applyFlag=false;

    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,
      id: this.selectedFiler.id,
      isActive: Number(isActive),
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      "roleId": this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('AssociatedPermits/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];

      this.apiService.stopLoader();

    });
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { name: null, id: null, assetName: null, location: null, property: null, business: null };
    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId);
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId);

    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx];
        this.getPropertyList();
      } else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx];
        // this.selectedFiler.property = this.propertyList[idx1]
        // this.getPropertyList()

      } else {
        this.selectedFiler.business = this.bussnissList[idx];
        // this.selectedFiler.property = this.propertyList[idx1]
      }
    }

    this.getList(this.tabSelected == 'active' ? 1 : 0);
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1;

  totalnoOfPages;

  changePage(ev) {
    // console.log(this.pageSize);
    this.selectedAssets = [];
    // this.pageSize = d
    this.getList();
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }

  workList = [];
  selectedAssets = [];


  getCustomName(d) {
    return 'Every ' + d.frequency_no + ' ' + d.frequency_unit;
  }

  pagination(d) {
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('meterListPagination', d);
      this.getList();
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList();
  }


  selectedFiler = { name: null, id: null, assetName: null, location: null, property: null, business: null };

  locationList = [];
  filterlocationList = [];


  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }

    // console.log(this.statusSelection);

    // if($(".stama"+d).css('display') == 'none'){
    //   $(".stama"+d).css("display","block");
    // }else{
    //   $(".stama"+d).css("display","none");
    // }
  }

  getLocationList() {
    this.apiService.startLoader();

    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'outletId': this.selectedFiler.property ? this.selectedFiler.property.id : '',
    };
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();


      this.locationList = result['data'];
      this.filterlocationList = result['data'];

      this.storedData.locationList = this.filterlocationList;

    });


  }

  getMainLocationList() {
    this.apiService.startLoader();
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'outletId': this.selectedFiler.property ? this.selectedFiler.property.id : '',
    };
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();


      this.locationList = result['data'];
      this.filterlocationList = result['data'];

      this.storedData.locationList = this.filterlocationList;

    });


  }

  getBusinessList() {
    this.apiService.startLoader();
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.bussnissList = result['data'];
      this.bussnissListFilter = result['data'];
      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId);

      if (idx != -1) {
        if (this.apiService.roleId == '2') {
          this.selectedFiler.business = this.bussnissList[idx];
          this.getPropertyList();
        } else if (this.apiService.roleId == '3') {
          this.selectedFiler.business = this.bussnissList[idx];
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId)
          this.getPropertyList();

        } else {
          this.selectedFiler.business = this.bussnissList[idx];
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId)
          this.getPropertyList();
        }

      } else {
        this.getMainPropertyList();
      }

    });
  }


  filterLocationSearch: any;

  filterLocation(ev) {
    // console.log(ev);
    this.locationList = this.filterlocationList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase());
      }
    });

  }

  filterPropertySearch;
  propertFilter = false;

  filterProperty(ev) {
    // console.log(ev);
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase());
      }
    });
  }

  filterBusinessSearch;
  businessFilter = false;

  filterBuisness(ev) {
    // console.log(ev);
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase());
      }
    });
  }


  applyFlag = false;

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    let body = {
      activePage: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
      assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.id : null,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      // businessId :this.selectedFiler.business ?this.selectedFiler.business.id : null,

    };
    this.apiService.startLoader();
    this.apiService.postCall('AssociatedPermits/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];

      this.apiService.stopLoader();

    });
  }

  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.assetName != null) {
      length = length + 1;
    }
    // if(this.selectedFiler.business != null) length = length+1
    if (this.selectedFiler.location != null) {
      length = length + 1;
    }
    if (this.selectedFiler.name != null) {
      length = length + 1;
    }
    if (this.selectedFiler.property != null) {
      length = length + 1;
    }
    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) {
      return '(None Selected)';
    } else if (this.applyFlag) {
      return `(${length} Filter Selected)`;
    }
  }


  assetList = [];
  assetListFilter = [];

  getAssetList() {
    this.apiService.startLoader();
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'outletId': this.selectedFiler.property ? this.selectedFiler.property.id : '',
      'statusNotIn': '5,8',
    };
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.assetList = [];
      this.assetList = result['data'];
      this.assetListFilter = result['data'];
    });
  }

  getMianAssetList() {
    this.apiService.startLoader();

    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'outletId': this.selectedFiler.property ? this.selectedFiler.property.id : '',
      'statusNotIn': '5,8',
    };
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.assetList = [];
      this.assetList = result['data'];
      this.assetListFilter = result['data'];
    });
  }

  filterAssetSearch;

  // businessFilter= false;
  filterAsset(ev) {
    // console.log(ev);
    this.assetList = this.assetListFilter.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase());
      }
    });
  }


  navigateToDetails(d) {
    this.route.navigate(['/meter-detail/' + d.meterId]);
  }

  deactiveSelected() {
  }

  dublicateSelected() {
  }

  selectAllAssets;

  filterSelect = false;
  assetFilter = false;
  locationFilter = false;


  bussnissList = [];
  bussnissListFilter = [];
  propertyList = [];
  filterpropertyList = [];

  getPropertyList() {
    this.apiService.startLoader();
    // console.log(this.selectedFiler.business);
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId);


      //  this.selectedFiler.property = this.propertyList[idx]
      this.getLocationList();
      this.getAssetList();


    });


  }
  goBack() {
    this.route.navigate(['/permit-setting']);
  }
  changeIsActive(id, isActive?) {
    this.apiService.startLoader();
    let obj = {
      'isActive': Number(isActive),
      'authId': this.apiService.userId,
    };
    this.apiService.postCall('AssociatedPermits/edit/' + id, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      this.getList(this.tabSelected == 'active' ? 1 : 0);
      this.message = result['msg'];
      this.statusSelection = undefined;
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.apiService.stopLoader();

    });
  }

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }

  getMainPropertyList() {
    this.apiService.startLoader();
    // console.log(this.selectedFiler.business);
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      'outletId': this.selectedFiler.property ? this.selectedFiler.property.id : '',
    };
    // this.apiService.getCall('outlets/outlet-list/'+this.selectedFiler.business.id,{}  ).subscribe((result: any[])=>{
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];
    });
  }

  getMianPropertyList() {
    this.apiService.startLoader();
    // console.log(this.selectedFiler.business);
    this.apiService.getCall('outlets/outlet-list/' + this.selectedFiler.business.id, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId);

      if (idx != -1) {
        if (this.apiService.roleId != '3' && this.apiService.roleId != '2' && this.apiService.roleId != '1') {

          this.selectedFiler.property = this.propertyList[idx];
          this.getLocationList();
          this.getAssetList();


        }
        //  else{

        //    this.selectedFiler.property = this.propertyList[idx]
        //    this.getLocationList()
        //    this.getAssetList()
        //  }
      }


    });


  }

  callGetOutside() {
    // console.log('d');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;
      this.assetFilter = false;
      this.locationFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
    if (btn.id != 'changeButton') {
      this.statusSelection = undefined;
    }
  }

  closeAll() {
    this.assetFilter = false;
    this.locationFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }


  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };

    pdfMake.createPdf(documentDefinition).download();

  }

}
