import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ApiService} from '../../../common/services/apiCalls.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-shipments-add',
  templateUrl: './shipments-add.component.html',
  styleUrls: ['./shipments-add.component.css']
})
export class ShipmentsAddComponent implements OnInit {
  shide = false;
  hide = false;
  form: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  businessId = 0;
  statusList = [{id: 0, name: 'Deactive'}, {id: 1, name: 'Active'}];
  routeId;
  hours = [];
  minutes = [];
  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'hh:mm:ss',
    defaultOpen: true
  };

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {

    for (let i = 0; i < 24; i++) {
      this.hours.push(i < 10 ? '0' + i : i);
    }
    for (let i = 0; i < 60; i++) {
      this.minutes.push(i < 10 ? '0' + i : i);
    }
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.form = this.fb.group({
      businessId: [null],
      name: [null, Validators.required],
      startTime: [null],
      endTime: [null],
      authId: [null],
      hoursStart: [null],
      minutesStart: [null],
      hoursEnd: [null],
      minutesEnd: [null],
      isActive: [1],
    });
    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'name',
      allowSearchFilter: true
    };


    this.getDetailsById();

    // if (this.apiService.outletId) {
    //   this.onItemSelect({id: this.apiService.outletId});
    // }

  }

  goBack(): void {
    this._location.back();
  }


  save(): void {

    if (!this.routeId) {
      this.form.value.authId = this.apiService.authId;
      this.form.value.businessId = this.apiService.buisnessId;
      this.form.value.startTime = this.form.value.hoursStart + ':' + this.form.value.minutesStart;
      this.form.value.endTime = this.form.value.hoursEnd + ':' + this.form.value.minutesEnd;

      let formData = this.form.value;
      this.apiService.startLoader();
      this.apiService.postCall('Shipments/add', {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    } else {
      this.form.value.authId = this.apiService.authId;
      this.form.value.businessId = this.apiService.buisnessId;
      this.form.value.isActive = this.form.value.isActive;
      this.form.value.startTime = this.form.value.hoursStart + ':' + this.form.value.minutesStart;
      this.form.value.endTime = this.form.value.hoursEnd + ':' + this.form.value.minutesEnd;
      let formData = this.form.value;
      this.apiService.startLoader();
      this.apiService.postCall('Shipments/edit/' + this.routeId, {}, formData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
    }


  }

  getValidation() {
    return this.form.valid;
  }

  getDetailsById() {
    if (this.routeId) {
      this.apiService.postCall('Shipments/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {

        this.apiService.stopLoader();
        this.form.patchValue(result['data']);
        var findStatus = this.statusList.filter(dat => dat.id == result['data'].status);
        var start = result['data'].start_time.split(':');
        var startHr = start[0];
        var startMin = start[1];
        // console.log(findStatus);
        var end = result['data'].end_time.split(':');
        var endHr = end[0];
        var endtMin = end[1];
        this.form.patchValue({name: result['data'].name, isActive: result['data'].isActive, hoursStart: startHr, hoursEnd: endHr, minutesStart: startMin, minutesEnd:  endtMin});
      });
    }
  }

  backClicked() {
    this.router.navigate(['/shifts/shipment-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
