import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../../common/services/apiCalls.service';
import { StoredData } from '../../../common/services/storedData.service';
import { Location } from '@angular/common';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-employee-db-list',
  templateUrl: './employee-db-list.component.html',
  styleUrls: ['./employee-db-list.component.css']
})
export class EmployeeDbListComponent implements OnInit {
  tabSelected = 'active';
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  statusSelection;
  addPermission = false;
  editPermission = false;
  message: any;
  type = null
  clientList = [];
  filterClientList = [];
  stateList = [];
  filterStateList = [];
  siteList = [];
  filterSiteList = [];
  clientFilter = false;
  siteFilter = false;
  stateFilter = false;
  filterClientSearch;
  filterStateSearch;
  filterSiteSearch;
  showAddMenu = false;
  exeedMsg
  uploadedFiles
  fileName

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData, public _location: Location) {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // for(var i=0;i<permission.length;i++){
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
    //     this.addPermission=true
    //   }
    //   if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
    //     this.editPermission=true
    //   }
    //
    // }
  }

  
  getIds(ev){
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property=ev.property
    if(ev.flag==1){
      this.clear()
    }else if(ev.flag==0){
      this.applyFilter()
    }
  
    // console.log(ev);
    //if(ev.flag==0){
     // this.applyFilter();
    //}else if(ev.flag==1){

    //   this.downloadReport();
    // }
    
  }
  ngOnInit(): void {

    var meterListPagination = localStorage.getItem('meterListPagination');
    if (meterListPagination) {
      this.activePage = parseInt(meterListPagination);
    }


    this.getList();


    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }

  // let obj ={
  //   "roleId":this.apiService.roleId,
  //   "userId":this.apiService.userId,
  //   "businessId": this.apiService.buisnessId,
  //   "outletId":this.tiggerDetailsForm.value.property?this.tiggerDetailsForm.value.property : "",
  // }

  getList(isActive: any = 1) {
    this.apiService.startLoader();
    // this.applyFlag=false;

    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      id: this.selectedFiler.id,
      fullName: this.selectedFiler.fullName,
      clientName: this.selectedFiler.clientName,
      currentAddState: this.selectedFiler.currentAddState,
      currentSitePosting: this.selectedFiler.currentSitePosting,
      joiningDate: this.selectedFiler.joiningDate,
      leavingDate: this.selectedFiler.leavingDate,
      aadharCardNo: this.selectedFiler.aadharCardNo,
      employeeNo: this.selectedFiler.employeeNo,
      ESICNumber: this.selectedFiler.ESICNumber,
      startDate: this.selectedFiler.startDate,
      endDate: this.selectedFiler.endDate,
      status: isActive,
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('Employies/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.allList = result['data'];
      this.apiService.stopLoader();

    });
    this.getClientList();
    this.getSiteList();
    this.getStateList();
  }

  getClientList() {
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('Clients/getClientsPluck', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.clientList = result['data'];
      this.filterClientList = result['data'];
      this.apiService.stopLoader();

    });
  }

  getStateList() {
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('/States/getStatesPluck', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.stateList = result['data'];
      this.filterStateList = result['data'];
      this.apiService.stopLoader();

    });
  }

  getSiteList() {
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      zoneId:this.selectedFiler.zoneId,
      cluserId:this.selectedFiler.cluserId ,
      stateId :this.selectedFiler.stateId,
      cityId:this.selectedFiler.cityId,
      clientId:this.selectedFiler.clientId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.siteList = result['data'];
      this.filterSiteList = result['data'];
      this.apiService.stopLoader();

    });
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = {
      id: '',
      fullName: '',
      clientName: '',
      currentAddState: '',
      currentSitePosting: '',
      joiningDate: '',
      leavingDate: '',
      aadharCardNo: '',
      employeeNo: '',
      ESICNumber: '',
      startDate: '',
      endDate: '',
     status: '',
     zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null,property:null,
    };
    this.getList();
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1;

  totalnoOfPages;

  changePage(ev) {
    // this.pageSize = d
    this.getList();
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }

  allList = [];


  pagination(d) {
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('meterListPagination', d);
      this.getList();
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList();
  }

  selectedFiler = {
    id: '',
    fullName: '',
    clientName: null,
    currentAddState: null,
    currentSitePosting: null,
    joiningDate: '',
    leavingDate: '',
    aadharCardNo: '',
    employeeNo: '',
    ESICNumber: '',
    startDate: '',
    endDate: '',
    status: '',
    zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null,property:null
  };


  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }

    // console.log(this.statusSelection);

    // if($(".stama"+d).css('display') == 'none'){
    //   $(".stama"+d).css("display","block");
    // }else{
    //   $(".stama"+d).css("display","none");
    // }
  }


  applyFlag = false;

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    let body = {
      activePage: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      id: this.selectedFiler.id,
      fullName: this.selectedFiler.fullName,
      clientName: this.selectedFiler.clientName ? this.selectedFiler.clientName?.id : '',
      currentAddState: this.selectedFiler.currentAddState ? this.selectedFiler.currentAddState?.id : '',
      currentSitePosting: this.selectedFiler.currentSitePosting ? this.selectedFiler.currentSitePosting?.id : '',
      joiningDate: this.selectedFiler.joiningDate,
      leavingDate: this.selectedFiler.leavingDate,
      aadharCardNo: this.selectedFiler.aadharCardNo,
      employeeNo: this.selectedFiler.employeeNo,
      ESICNumber: this.selectedFiler.ESICNumber,
      startDate: this.selectedFiler.startDate,
      endDate: this.selectedFiler.endDate,
      status: this.selectedFiler.status,
      zoneId:this.selectedFiler.zoneId,
      cluserId:this.selectedFiler.cluserId ,
      stateId :this.selectedFiler.stateId,
      cityId:this.selectedFiler.cityId,
      clientId:this.selectedFiler.clientId,
      property : this.selectedFiler.property? this.selectedFiler.property.id : "",
    };
    this.apiService.startLoader();
    this.apiService.postCall('Employies/index/', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.allList = result['data'];
      this.apiService.stopLoader();

    });
  }

  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.fullName != null && this.selectedFiler.fullName !='') {
      length = length + 1;
    }
    if (this.selectedFiler.clientName != null && this.selectedFiler.clientName != '') {
      length = length + 1;
    }
    if (this.selectedFiler.currentAddState != null && this.selectedFiler.currentAddState != '') {
      length = length + 1;
    }
    if (this.selectedFiler.currentSitePosting != null && this.selectedFiler.currentSitePosting != '') {
      length = length + 1;
    }
    if (this.selectedFiler.leavingDate != null && this.selectedFiler.leavingDate != '') {
      length = length + 1;
    }
    if (this.selectedFiler.aadharCardNo != null && this.selectedFiler.aadharCardNo != '') {
      length = length + 1;
    }
    if (this.selectedFiler.employeeNo != null && this.selectedFiler.employeeNo != '') {
      length = length + 1;
    }
    if (this.selectedFiler.ESICNumber != null && this.selectedFiler.ESICNumber != '') {
      length = length + 1;
    }
    if (this.selectedFiler.startDate != null && this.selectedFiler.startDate != '') {
      length = length + 1;
    }
    if (this.selectedFiler.endDate != null && this.selectedFiler.endDate != '') {
      length = length + 1;
    }
    if (this.selectedFiler.status != null && this.selectedFiler.status != '') {
      length = length + 1;
    }
    if (this.selectedFiler.clientId != null) length = length + 1;
    if (this.selectedFiler.zoneId != null) length = length + 1;
    if (this.selectedFiler.cityId != null) length = length + 1;
    if (this.selectedFiler.cluserId != null) length = length + 1;
    if (this.selectedFiler.stateId != null) length = length + 1;

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) {
      return '(None Selected)';
    } else if (this.applyFlag) {
      return `(${length} Filter Selected)`;
    }
  }

  filterClient(ev: any) {
    this.clientList = this.filterClientList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterClientSearch.toLowerCase());
      }
    });
  }

  filterState(ev: any) {
    this.stateList = this.filterStateList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterStateSearch.toLowerCase());
      }
    });
  }

  filterSite(ev: any) {
    this.siteList = this.filterSiteList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterSiteSearch.toLowerCase());
      }
    });
  }


  goBack(): void {
    this.route.navigate(['/setup']);
  }

  navigateToDetails(d) {
    this.route.navigate(['/employee/designation-edit/' + d.id]);
  }

  deactiveSelected() {
  }

  dublicateSelected() {
  }


  filterSelect = false;

  changeIsActive(id,isActive): void{
    this.apiService.startLoader();
    // let obj = {
    //   'isActive': Number(isActive),
    //   'id': id,
    // };
    this.apiService.postCall('Employies/changeStatus/'+id+'/'+Number(isActive), {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      if(Number(isActive) == 1){
        this.getList('0');
      }else{
        this.getList('1');
      }
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.apiService.stopLoader();

    });
  }

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }


  callGetOutside() {
    // console.log('d');
  }
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.showAddMenu = false;
    }
    else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.showAddMenu = false;
    }
    if (btn.id == 'changeButton') {
    } else if (btn.id != 'changeButton') {
      this.statusSelection = undefined
    }
  }

  closeAll() {


  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }


  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };

    pdfMake.createPdf(documentDefinition).download();

  }

  downloadReport(): void {
    this.apiService.startLoader();
    let body = {
      id: this.selectedFiler.id,
      fullName: this.selectedFiler.fullName,
      clientName: this.selectedFiler.clientName ? this.selectedFiler.clientName.id : '',
      currentAddState: this.selectedFiler.currentAddState ? this.selectedFiler.currentAddState.id : '',
      currentSitePosting: this.selectedFiler.currentSitePosting ? this.selectedFiler.currentSitePosting.id : '',
      joiningDate: this.selectedFiler.joiningDate,
      leavingDate: this.selectedFiler.leavingDate,
      aadharCardNo: this.selectedFiler.aadharCardNo,
      employeeNo: this.selectedFiler.employeeNo,
      ESICNumber: this.selectedFiler.ESICNumber,
      startDate: this.selectedFiler.startDate,
      endDate: this.selectedFiler.endDate,
      status: this.selectedFiler.status,
      download: 1,
      // number: this.selectedFiler.number,
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      zoneId:this.selectedFiler.zoneId,
        cluserId:this.selectedFiler.cluserId ,
        stateId :this.selectedFiler.stateId,
        cityId:this.selectedFiler.cityId,
        clientId:this.selectedFiler.clientId,
        property : this.selectedFiler.property? this.selectedFiler.property.id : "",
    };
    this.apiService.postCall('Employies/downloadAllDataExcel', {}, body).subscribe((result: any[]) => {
      if (result['success'] == true) {
        // window.open(result['data']);
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', result['url']);

        link.setAttribute('download', `employedb.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader();
    });
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name
    }
  }
  changeType(e) {
    if (e.target.value == "0: null") {
      this.type = null;
    } else {
      this.type = e.target.value
    }
    this.uploadedFiles = null
    this.fileName = null
  }
  getValidation() {
    if (!this.uploadedFiles) {
      return false;
    }
    return true;
  }
  errorMessages = []
  uploadWarranty() {
    let testData: FormData = new FormData();
    this.errorMessages = []
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    testData.append('file', this.uploadedFiles);
    this.apiService.startLoader()
    this.apiService.postCall('Employies/uploadExcel', {}, testData).subscribe((result: any[]) => {
      this.getList();
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.apiService.stopLoader()
      this.uploadedFiles = null
      this.fileName = null
    });
  }

}
