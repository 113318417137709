import { Component,HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {

  constructor(
    public route: Router,
    public apiService:ApiService,
    public storedData:StoredData,
    public _location: Location,
  ) { }

// pagination Veriables
  paginationArray = [];
  activePage = 1;
  pageSize = 10;
  totalnoOfPages;

// array list veriables
  userList: any;

// Sorting variables
  sortType = "desc";
  sortBy = 'userId';
    
// Filter Object
  selectedFiler={userName:null,property:null , business: null,role:null}
  applyFlag=false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role:any;
  message:any;
  
  userId:any;

  ngOnInit(): void {
    this.userId = this.route.url.split("/")[2];
    this.getUserSubscriptions();
  }
  getUserSubscriptions(): void {
      const body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
        role: this.selectedFiler.role ? this.selectedFiler.role.id : null,
      };
      this.apiService.startLoader()
      this.apiService.postCall('/subscriptions/index/'+this.userId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.userList = []
        this.userList = result['data'];
        this.setPaginationArray(this.userList);
      })
  }

  
  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage= this.activePage
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  jumpToParked
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)

    return array
  }
  applyFilter(){
    this.applyFlag=true;
    this.activePage= 1;
    this.getUserSubscriptions();
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      this.getUserSubscriptions();
    }
  }
  changePage(ev) {
    this.getUserSubscriptions();
  }

  subscribePlan(): void {
      this.apiService.startLoader()
      this.apiService.getCall('/subscriptions/add/'+this.userId, {}).subscribe((result: any[]) => {
        this.apiService.stopLoader();
        this.message = result['msg'];
        if(result['success'] == true){
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }else{
          (<HTMLInputElement>document.getElementById('openError')).click();
        }
       
      })
  }


  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
    }
    
  }

}
