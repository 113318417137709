import { Component,HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-sub-user-index',
  templateUrl: './sub-user-index.component.html',
  styleUrls: ['./sub-user-index.component.css']
})
export class SubUserIndexComponent implements OnInit {

  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  constructor(
    public route: Router,
    public apiService:ApiService,
    public storedData:StoredData,
    public _location: Location,
    public formBuilder:FormBuilder,
  ) { }


  subuserForm = this.formBuilder.group({
    fullName: [null,[Validators.required]],
    mobile: [null,[Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
    designa: [null,[Validators.required]],
  });


// pagination Veriables
  paginationArray = [];
  activePage:any = 1;
  pageSize = 10;
  totalnoOfPages;
  erroMsg
  userId:any;
// array list veriables
  activeList: any;

// Sorting variables
  sortType = "desc";
  sortBy = 'userId';
    
// Filter Object
  selectedFiler={userName:null,property:null , business: null,role:null}
  applyFlag=false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role;

// Tab Selectio veriable
  tabSelected='';
  statusSelection:any;

  ngOnInit(): void {
    this.role = this.apiService.roleId;
    this.userId = this.route.url.split("/")[2]; 
    this.getSubUsers();
    this.getUserDetail()
  }

  userDetails:any

  getUserDetail(){
    this.apiService.startLoader()
    this.apiService.getCall('users/view/'+this.userId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      // this.activeList = []
      this.userDetails = result['data'];

      // console.log(this.userDetails)
      // this.setPaginationArray(result)
    })
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.userName != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.role != null) length = length + 1

    if(length == 0 || !this.applyFlag) return "(None Selected)"
    else if(this.applyFlag) return `(${length} Filter Selected)`
  }

  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage= parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  jumpToParked
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)

    return array
  }
  applyFilter(){
    this.applyFlag=true;
    this.activePage= 1;
    this.getSubUsers();
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      this.tabSelected = '';
      this.getSubUsers();
    }
  }

  changePage(ev) {
    this.getSubUsers();
  }

  getSubUsers(): void {
    const body = {
      pageNo: this.activePage,
      limit: this.pageSize,
    };
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('SubUsers/index/'+this.userId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.activeList = []
      this.activeList = result['data'];
      this.setPaginationArray(result)
    })
    
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
    }
  }

  changeStatus(id,status): void{
    this.statusSelection = undefined;
    this.apiService.startLoader()
    this.apiService.getCall('SubUsers/deactivateSubUser/'+id+'/'+status, {}).subscribe((result: any[]) => {
      // console.log(result);
      // alert(result['msg']);
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getSubUsers();
    })
  }

  closeAll() {
    this.propertyFilter = false;
    this.roleFilter = false;
    this.businessFilter = false;
  }

  selectStatus(d){
    // console.log(d);
    if('status'+d.subUserId != this.statusSelection ) this.statusSelection = 'status'+d.subUserId
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }

  message
  selectedUser
  addSubUser(){
    // console.log(this.userDetails.businessId)
    let obj={
      "fullName":this.subuserForm.value.fullName,
"businessId":this.userDetails.businessId,
"outletId":this.userDetails.outletId,
"designa":this.subuserForm.value.designa,
"mobile":this.subuserForm.value.mobile,
"parentId":this.userId,

    }
    this.apiService.startLoader();
    this.apiService.postCall('SubUsers/add', {},obj).subscribe((result: any[]) => {

      if(result['success']){
      this.message =  "Sub-user(s) added successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getSubUsers()
      }
    })
  }


  getValidation(){
    return  this.subuserForm.valid
  }

}
