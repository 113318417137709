import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
import html2canvas from 'html2canvas';
// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;

@Component({
  selector: 'app-dashboard-asset',
  templateUrl: './dashboard-asset.component.html',
  styleUrls: ['./dashboard-asset.component.css']
})
export class DashboardAssetComponent implements OnInit {
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;
  departmentFilter = false;

  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];

  selectedFiler = { department: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      //this.showFilter = false
      this.propertyFiletr = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      // this.showAddMenu = false;
      this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }
  chartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ["Date", 'Budget Allocated Cost', 'Budget Used'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: 'Value ('+ this.apiService.convertCureency() + ')' },
      seriesType: 'bars',
      series: { 2: { type: 'bars' } }
    },
    width: 1000,
    height: 400
  };

  chartDataLine = {
    type: 'AreaChart',
    data: [],
    chartColumns: ['Month', 'Cost'],
    options : { 
      isStacked:true,
      hAxis: {
         title: 'Month',
         titleTextStyle: {color: '#000000'}
      },
      vAxis:{
        title: 'Cost',
        titleTextStyle: {color: '#000000'}
      },
      colors: ['#27AE60'],
   },
    // options: {
    //   responsive: true,
    //   borderRadius: 15,
    //   colors: ['#33CCB2', '#EF6B6B'],
    //   legend: { position: 'none' },
    //   hAxis: { title: '' },
    //   vAxis: { title: '' },
    //   seriesType: 'bars',
    //   series: { 2: { type: 'line' } }
    // },
    width: 1000,
    height: 400
  };
  staticDashboardstaticDashboardChangeValue = this.storedData.staticDashboardFlag;
  staticDashboardChange() {
    if (this.staticDashboardstaticDashboardChangeValue == false || this.staticDashboardstaticDashboardChangeValue == undefined) {
      this.staticDashboardFlag = true;
      this.storedData.staticDashboardFlag = true;
      this.ngOnInit();
    } else {
      this.staticDashboardFlag = false;
      this.storedData.staticDashboardFlag = false;
      this.ngOnInit();
    }
  }
  closeAll() {
    this.propertyFiletr = false;
    this.statsuFilter = false;
    this.typeFilter = false;
    this.businessFilter = false;
  }
  downloadFlag = false;
  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.ngOnInit()


  }
  openList(type) {
    localStorage.setItem("listingtype", type);
    this.route.navigate(['/home'])
  }
  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { department: null, property: null, Clients: null, Zone: null, state: null, cluster: null, city: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
  }

  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }

  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    // this.screenWidth= window.innerWidth;
    // if (this.screenWidth <= 1000) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 550;
    // }
    // else if (this.screenWidth <= 1380) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 750;
    // }
    // else if (this.screenWidth <= 1450) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 900;
    // }
    // else if (this.screenWidth <= 1500) {
    //   this.marginLeft = 2;
    //   this.fixedIWdth = 950;
    // }
    // else if (this.screenWidth <= 1600) {
    //   this.marginLeft = 3;
    //   this.fixedIWdth = 1000;
    // }
    // else if (this.screenWidth <= 1700) {
    //   this.marginLeft = 4;
    //   this.fixedIWdth = 1050;
    // }
    // else if (this.screenWidth <= 1800) {
    //   this.marginLeft = 6;
    //   this.fixedIWdth = 1100;
    // }

    // else if (this.screenWidth <= 1920) {
    //   this.marginLeft = 8;
    //   this.fixedIWdth = 1150;
    // }

    // else if (this.screenWidth <= 2048) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1200;
    // }
    // else if (this.screenWidth <= 2100) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1300;
    // }
    // else if (this.screenWidth <= 2400) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1500;
    // }

    // else if (this.screenWidth <= 2800) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1900;
    // }


    // d3.selectAll("#groupedChartYAxis > *").remove();
    // d3.selectAll("#chart > *").remove();

    // d3.selectAll("#groupedChartYAxis2 > *").remove();
    // d3.selectAll("#chart2 > *").remove();

    this.getAsset()

    this.getLocationList()
    this.getDepartmentList()
    this.getWarrantyExpirationList()
    this.getAssetWithverLessLife()
    this.getAssetswithMaxBraekDown()
    this.getassetMaiantanance()

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
      }
      if (this.apiService.role == 1) {
        this.businessFeatureAttendance = true
      }
    }


    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);

  }
  assetDashboard
  warrantyExpirationList = []
  getAsset() {
    let body = {
      businessId: this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      userId: this.apiService.authId,
      roleId: this.apiService.roleId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,
    }
    this.apiService.startLoader()
    this.apiService.postCall('AssetManagements/assetDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.assetDashboard = {
          "success": true,
          "msg": "success.",
          "assetTotalValues": "50,00,000",
          "totalAsset": "250",
          "totalAssetActive": "32",
          "totalAssetInActive": "2",
          "totalAssetTemparary": "5",
          "assetMttr": "185 Hrs",
          "assetMtbf": "2,25,750 Hrs",
          "downtimeAvailability": "375 Hrs",
          "assetAmcOverdue": "3",
          "assetWarrantyExp": [
            {
              "id": 208,
              "asset_name": "DG Air Washer No. 1",
              "make": "EDGTECH",
              "spec_rating": "14400 CFM",
              "sr_no": "1",
              "location_id": 8879,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 5992,
              "business_id": 26,
              "manufacturer": null,
              "model": "DAW N",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454395_qeunk_208.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:46:09+05:30",
              "modified_at": "2022-11-26T09:19:55+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221637fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Washer",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Siemens",
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "Aarkays Air Equipment Pvt Ltd",
              "warraanty_contact_no": "7125445450",
              "warraanty_alternate_no": null,
              "warraanty_email": "aarkaysair@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-05-01",
              "amc_cron_date": "2023-05-16"
            },
            {
              "id": 209,
              "asset_name": "Chiller Auxilary Panel",
              "make": "ADLEC",
              "spec_rating": "1000 AMP",
              "sr_no": "2",
              "location_id": 8881,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 5992,
              "business_id": 26,
              "manufacturer": null,
              "model": "CAP",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454396_hzjym_209.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:46:09+05:30",
              "modified_at": "2022-11-26T09:19:56+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221635fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Panel",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Aircon Controls",
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "Aircon Controls",
              "warraanty_contact_no": "7745774588",
              "warraanty_alternate_no": null,
              "warraanty_email": "airconelectronics@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-05-30",
              "amc_cron_date": "2023-05-16"
            },
            {
              "id": 210,
              "asset_name": "Condenser Pump No.1",
              "make": "CE(XYLEM)",
              "spec_rating": "60HP/45KW",
              "sr_no": "1217M80017267",
              "location_id": 8880,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 7150,
              "business_id": 26,
              "manufacturer": null,
              "model": "CPN 1",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454397_fusyo_210.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:44:29+05:30",
              "modified_at": "2022-11-26T09:19:57+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221658fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Pump",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "",
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "Shakti Pumps",
              "warraanty_contact_no": "7784577845",
              "warraanty_alternate_no": "7654688431",
              "warraanty_email": "shakti@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-05-01",
              "amc_cron_date": "2023-03-16"
            },
            {
              "id": 211,
              "asset_name": "Air Blower No. 2 (Pump)",
              "make": "KIRLOSKER",
              "spec_rating": "5.5 KW",
              "sr_no": "U8WMCH00976",
              "location_id": 8880,
              "purchase_date": "2020-07-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 7150,
              "business_id": 26,
              "manufacturer": null,
              "model": "ABN 2 P",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669454398_vwlui_211.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 5992,
              "is_deleted": 0,
              "created_at": "2022-12-22T11:44:29+05:30",
              "modified_at": "2022-11-26T09:19:58+05:30",
              "created_by": 318,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/261120221637fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "Pump",
              "conditions": "New",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "",
              "budget": null,
              "budget_type": 0,
              "parent_id": 210,
              "warraanty_vendor_name": "Ram Sons Pump",
              "warraanty_contact_no": "7558456985",
              "warraanty_alternate_no": "8946598645",
              "warraanty_email": "ramsons@gmail.com",
              "warraanty_start_date": "2020-07-01",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-05-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 0,
              "warraanty_cron_date": "2023-05-01",
              "amc_cron_date": "2023-07-16"
            },
            {
              "id": 20,
              "asset_name": "CHILLER PLANT 2",
              "make": "DAIKIN",
              "spec_rating": "550TR",
              "sr_no": "6700102",
              "location_id": 4848,
              "purchase_date": "2019-07-15T00:07:00+05:30",
              "plasce_in_service": "2019-07-15T00:07:00+05:30",
              "vendor_id": null,
              "outlet_id": 71,
              "business_id": 26,
              "manufacturer": null,
              "model": "C3612 B2RB418A",
              "specification": "spec",
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658670049_gcfre_20.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 0,
              "is_deleted": 0,
              "created_at": "2022-12-30T07:02:34+05:30",
              "modified_at": "2022-07-24T13:40:49+05:30",
              "created_by": 323,
              "modified_by": 318,
              "amc_provider_name": null,
              "remarks": "3",
              "renewal_date": null,
              "warranty_expiration": null,
              "image": null,
              "sla_expiry_date": null,
              "assigned_customer": null,
              "status": 1,
              "is_used": 0,
              "category_name": "HVAC",
              "conditions": "New",
              "ownership": "WMG",
              "asset_tag_no": null,
              "vendor_name": null,
              "budget": null,
              "budget_type": 0,
              "parent_id": 19,
              "warraanty_vendor_name": "abc",
              "warraanty_contact_no": "6666666666",
              "warraanty_alternate_no": "6666666626",
              "warraanty_email": "abc@gmail.com",
              "warraanty_start_date": "2022-12-30",
              "warraanty_sheduled_services": "50",
              "warraanty_attach": "",
              "warraanty_end_date": "2023-04-29",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 750,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": "2023-04-19",
              "amc_cron_date": null
            },
            {
              "id": 21,
              "asset_name": "HRU",
              "make": "EDGTECH",
              "spec_rating": "8200CFM",
              "sr_no": "K1805889/K1007501",
              "location_id": 4848,
              "purchase_date": "2020-01-10T00:01:00+05:30",
              "plasce_in_service": "2020-01-10T00:01:00+05:30",
              "vendor_id": null,
              "outlet_id": 5994,
              "business_id": 26,
              "manufacturer": null,
              "model": "ETV100HRU",
              "specification": "spec",
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658671234_lxvzn_21.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 71,
              "is_deleted": 0,
              "created_at": "2023-05-04T11:18:23+05:30",
              "modified_at": "2022-07-24T14:00:34+05:30",
              "created_by": 323,
              "modified_by": 6553,
              "amc_provider_name": null,
              "remarks": "3",
              "renewal_date": null,
              "warranty_expiration": null,
              "image": null,
              "sla_expiry_date": null,
              "assigned_customer": null,
              "status": 1,
              "is_used": 1,
              "category_name": "HVAC",
              "conditions": "New",
              "ownership": "WMG",
              "asset_tag_no": null,
              "vendor_name": null,
              "budget": null,
              "budget_type": 0,
              "parent_id": 0,
              "warraanty_vendor_name": "ssss",
              "warraanty_contact_no": "6666666666",
              "warraanty_alternate_no": null,
              "warraanty_email": "dsd@gmia.com",
              "warraanty_start_date": "2022-12-30",
              "warraanty_sheduled_services": "5",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-03-31",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 0,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 323,
              "warraanty_cron_date": null,
              "amc_cron_date": null
            },
            {
              "id": 1,
              "asset_name": "Milling Machine",
              "make": "Mahindra",
              "spec_rating": "2250KVA",
              "sr_no": "566",
              "location_id": 106,
              "purchase_date": "2021-01-01T00:00:00+05:30",
              "plasce_in_service": "2021-01-10T00:00:00+05:30",
              "vendor_id": null,
              "outlet_id": 71,
              "business_id": 26,
              "manufacturer": null,
              "model": "Mahindra 5.3 k ",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_zyvqo_1.png",
              "is_operational": 1,
              "is_active": 1,
              "migrated_to": 0,
              "is_deleted": 0,
              "created_at": "2022-12-01T10:40:01+05:30",
              "modified_at": "2022-05-24T11:43:46+05:30",
              "created_by": 2125,
              "modified_by": 5654,
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01T05:30:00+05:30",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
              "sla_expiry_date": "1970-01-01T00:00:00+05:30",
              "assigned_customer": null,
              "status": 1,
              "is_used": 0,
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Zenn Machines",
              "budget": "250",
              "budget_type": 0,
              "parent_id": 5,
              "warraanty_vendor_name": "VJ Machines Ltd.",
              "warraanty_contact_no": "8954612645",
              "warraanty_alternate_no": "7895663541",
              "warraanty_email": "vj@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": "",
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": 0,
              "maintemce_cost": 2244,
              "badget_type": 0,
              "budget_monthly_cost": 0,
              "budget_annual_cost": 0,
              "budget_notify": 0,
              "updated_by": 2125,
              "warraanty_cron_date": null,
              "amc_cron_date": null
            }
          ],
          "maxNoOfBreakdown": [
            {
              "id": "5",
              "asset_name": "Generator No. 2",
              "AssetManagementBreakdowns": "2"
            },
            {
              "id": "209",
              "asset_name": "Chiller Plant No. 1",
              "AssetManagementBreakdowns": "1"
            },
            {
              "id": "211",
              "asset_name": "AHU Motor No. 5",
              "AssetManagementBreakdowns": "1"
            }
          ],
          "assetWithVerryLessFull": [
            {
              "id": "2",
              "asset_name": "Generator No. 2",
              "usefullLifeYear": "2",
              "end_date": "2024-6-30"
            },
            {
              "id": "16",
              "asset_name": "HVAC Plant",
              "usefullLifeYear": "3",
              "end_date": "2024-12-31"
            },
            {
              "id": "186",
              "asset_name": "Transformer No. 1",
              "usefullLifeYear": "1",
              "end_date": "2024-12-31"
            },
            {
              "id": "4",
              "asset_name": "Chiller Plant No. 1",
              "usefullLifeYear": "3",
              "end_date": "2025-6-30"
            },
            {
              "id": "1",
              "asset_name": "AHU Motor No. 5",
              "usefullLifeYear": "3",
              "end_date": "2025-09-30"
            }
          ],
          "assetHighestMaintenceCosts": [
            {
              "asset_management_id": "6",
              "quantity": "1",
              "price": "80",
              "id": "6",
              "asset_name": "Shaper Machine ",
              "make": "Zacko",
              "spec_rating": "2251KVA",
              "sr_no": "23",
              "location_id": "107",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "Zacko - 56",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653486936_jkrcu_6.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-10-13 05:40:01",
              "modified_at": "2022-05-24 11:43:49",
              "created_by": "318",
              "modified_by": "318",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221429fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "NY Ltd",
              "budget": null,
              "budget_type": "0",
              "parent_id": "0",
              "warraanty_vendor_name": "Zacko",
              "warraanty_contact_no": "7897864795",
              "warraanty_alternate_no": "8998798771",
              "warraanty_email": "zacko@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "5130",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "318",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "5130"
            },
            {
              "asset_management_id": "3",
              "quantity": "4",
              "price": "300",
              "id": "3",
              "asset_name": "Tool & Cutter Machine",
              "make": "JK",
              "spec_rating": "2250KVA",
              "sr_no": "14236",
              "location_id": "2339",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "JK G89",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392627_twils_3.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-09-21 15:00:01",
              "modified_at": "2022-05-24 11:43:47",
              "created_by": "323",
              "modified_by": "318",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221427fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "HDA Machines",
              "budget": "750",
              "budget_type": "0",
              "parent_id": "0",
              "warraanty_vendor_name": "Sai Machines",
              "warraanty_contact_no": "9564325632",
              "warraanty_alternate_no": "8954612645",
              "warraanty_email": "sai@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "2920",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "323",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "3420"
            },
            {
              "asset_management_id": "19",
              "quantity": "1",
              "price": "100",
              "id": "19",
              "asset_name": "CHILLER PLANT 1",
              "make": "DAIKIN",
              "spec_rating": "550 TR",
              "sr_no": "6700101",
              "location_id": "4848",
              "purchase_date": "2019-07-20 00:07:00",
              "plasce_in_service": "2019-07-20 00:07:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "C3612 BLYY 2",
              "specification": "spec",
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658668545_ozybk_19.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "5994",
              "is_deleted": "0",
              "created_at": "2023-05-04 11:18:23",
              "modified_at": "2022-07-24 13:15:45",
              "created_by": "323",
              "modified_by": "6553",
              "amc_provider_name": null,
              "remarks": "3",
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": null,
              "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/181020221255fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "1",
              "category_name": "HVAC",
              "conditions": "New",
              "ownership": "WMG",
              "asset_tag_no": null,
              "vendor_name": "KE Ltd.",
              "budget": "2000",
              "budget_type": "0",
              "parent_id": "20",
              "warraanty_vendor_name": "Daikin Ltd.",
              "warraanty_contact_no": "9598989898",
              "warraanty_alternate_no": "7988979798",
              "warraanty_email": "daikin@gmail.com",
              "warraanty_start_date": "2022-08-01",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": null,
              "warraanty_end_date": "2022-10-31",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "2771.11",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "323",
              "warraanty_cron_date": "2022-10-30",
              "amc_cron_date": "2022-10-28",
              "finalcost": "3311.11"
            },
            {
              "asset_management_id": "1",
              "quantity": "2",
              "price": "244",
              "id": "1",
              "asset_name": "Milling Machine",
              "make": "Mahindra",
              "spec_rating": "2250KVA",
              "sr_no": "566",
              "location_id": "106",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "Mahindra 5.3 k ",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_zyvqo_1.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-12-01 10:40:01",
              "modified_at": "2022-05-24 11:43:46",
              "created_by": "2125",
              "modified_by": "5654",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Zenn Machines",
              "budget": "250",
              "budget_type": "0",
              "parent_id": "5",
              "warraanty_vendor_name": "VJ Machines Ltd.",
              "warraanty_contact_no": "8954612645",
              "warraanty_alternate_no": "7895663541",
              "warraanty_email": "vj@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": "",
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "2244",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "2125",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "2244"
            },
            {
              "asset_management_id": "2",
              "quantity": "1",
              "price": "80",
              "id": "2",
              "asset_name": "Radial drill machine",
              "make": "Winzz",
              "spec_rating": "2251KVA",
              "sr_no": "78",
              "location_id": "107",
              "purchase_date": "2023-01-01 00:00:00",
              "plasce_in_service": "2023-05-05 00:00:00",
              "vendor_id": null,
              "outlet_id": "5994",
              "business_id": "26",
              "manufacturer": null,
              "model": "Winzz 6.56 ",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_fhnoi_2.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2023-05-04 07:30:01",
              "modified_at": "2022-05-24 11:43:46",
              "created_by": "318",
              "modified_by": "6553",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "Winzz Vehicle Ltd",
              "budget": "500",
              "budget_type": "0",
              "parent_id": "1",
              "warraanty_vendor_name": "Lakhani Enterprises",
              "warraanty_contact_no": "7895663541",
              "warraanty_alternate_no": "9564325632",
              "warraanty_email": "le@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "1",
              "warraanty_attach": "",
              "warraanty_end_date": "2024-01-31",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "1130",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "0",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "1130"
            },
            {
              "asset_management_id": "5",
              "quantity": "5",
              "price": "80",
              "id": "5",
              "asset_name": "Shaper Machine ",
              "make": "Zacko",
              "spec_rating": "2251KVA",
              "sr_no": "23",
              "location_id": "107",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "71",
              "business_id": "26",
              "manufacturer": null,
              "model": "Zacko - 56",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392629_rotma_5.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "0",
              "is_deleted": "0",
              "created_at": "2022-12-01 10:10:50",
              "modified_at": "2022-05-24 11:43:49",
              "created_by": "318",
              "modified_by": "5654",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": null,
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "0",
              "category_name": "Machine",
              "conditions": "Used",
              "ownership": "Self-owned",
              "asset_tag_no": "",
              "vendor_name": "NY Ltd",
              "budget": null,
              "budget_type": "0",
              "parent_id": "1",
              "warraanty_vendor_name": "Zacko",
              "warraanty_contact_no": "7897864795",
              "warraanty_alternate_no": "8998798771",
              "warraanty_email": "zacko@gmail.com",
              "warraanty_start_date": "2021-01-10",
              "warraanty_sheduled_services": "2",
              "warraanty_attach": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/2605202214414.jpg",
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "800",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "318",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "160"
            },
            {
              "asset_management_id": "28",
              "quantity": "10",
              "price": "75",
              "id": "28",
              "asset_name": "Cleaning Machine",
              "make": "XYZ Ltd.",
              "spec_rating": "2250KVA",
              "sr_no": "6458564",
              "location_id": "106",
              "purchase_date": "2021-01-01 00:00:00",
              "plasce_in_service": "2021-01-10 00:00:00",
              "vendor_id": null,
              "outlet_id": "5992",
              "business_id": "26",
              "manufacturer": null,
              "model": "Zen 2.5",
              "specification": null,
              "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1661856147_fqvrh_28.png",
              "is_operational": "1",
              "is_active": "1",
              "migrated_to": "5992",
              "is_deleted": "0",
              "created_at": "2022-12-22 11:46:09",
              "modified_at": "2022-08-30 10:42:27",
              "created_by": "323",
              "modified_by": "318",
              "amc_provider_name": null,
              "remarks": null,
              "renewal_date": "1970-01-01 05:30:00",
              "warranty_expiration": "",
              "image": null,
              "sla_expiry_date": "1970-01-01 00:00:00",
              "assigned_customer": null,
              "status": "1",
              "is_used": "1",
              "category_name": "Cleaner",
              "conditions": "New",
              "ownership": "Shubham",
              "asset_tag_no": "",
              "vendor_name": "ABC Ltd",
              "budget": null,
              "budget_type": "0",
              "parent_id": "0",
              "warraanty_vendor_name": "ABC Ltd",
              "warraanty_contact_no": "6666666666",
              "warraanty_alternate_no": "5555555555",
              "warraanty_email": "pritesh555@aquil.net",
              "warraanty_start_date": "2021-01-15",
              "warraanty_sheduled_services": "3",
              "warraanty_attach": null,
              "warraanty_end_date": "2023-01-15",
              "warraanty_renewal_date": null,
              "down_time": "0",
              "maintemce_cost": "1250",
              "badget_type": "0",
              "budget_monthly_cost": "0",
              "budget_annual_cost": "0",
              "budget_notify": "0",
              "updated_by": "323",
              "warraanty_cron_date": null,
              "amc_cron_date": null,
              "finalcost": "125"
            }
          ],
          "maintenceCostArrayGraph": [
            [
              "Jan 23",
              10000
            ],
            [
              "Feb 23",
              12000
            ],
            [
              "Mar 23",
              3000
            ],
            [
              "Apr 23",
              4500
            ],
            [
              "May 23",
              7500
            ],
            [
              "Jun 23",
              18000
            ]
          ],
          "assetBudgetArrayGraph": [
            [
              "Jan 23",
              20000,
              10000
            ],
            [
              "Feb 2023",
              15000,
              12000
            ],
            [
              "Mar 2023",
              5000,
              3000
            ],
            [
              "Apr 2023",
              5000,
              4500
            ],
            [
              "May 2023",
              10000,
              7500
            ],
            [
              "Jun 2023",
              20000,
              18000
            ]
          ],
          "userId": 6553
        }
      } else {
        this.assetDashboard = result;
      }
      //this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      this.curveChart()
      this.linecurveChart()
    })





  }
  chart1
  chart2
  downloadReport() {

    let body = {
      businessId: this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      userId: this.apiService.authId,
      roleId: this.apiService.roleId,
      "chart1": this.chart1,
      "chart2": this.chart2,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,
      assetDashboard:this.assetDashboard,
      warrantyExpirationList:this.warrantyExpirationList,
      assetWithverLessLife:this.assetWithverLessLife,
      maxBearkDown:this.maxBearkDown,
      assetMaiantanance:this.assetMaiantanance,
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('AssetManagements/dashboardAssetsDownload/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.exportAsPDF1();
  }
  exportAsPDF1() {
    var HTML_Width = $("#divIdCharts1").width();
    var HTML_Height = $("#divIdCharts1").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts1")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage1('img2', imgData);
      // console.log(imgData);
    });
  };
  saveGraphImage1(imageName: any, url: any) {
    this.chart2 = url;
    this.downloadReport();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  getWarrantyExpirationList() {
    let body = {

      // businessId:this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      limit: 10,
      pageNo: 1,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,

    }

    this.apiService.postCall('AssetManagements/assetWarrantyList', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.warrantyExpirationList = [
          {
            "id": 263,
            "asset_name": "Generator No. 2",
            "make": "Samsung",
            "spec_rating": "1 Ton",
            "sr_no": "6456",
            "location_id": 106,
            "purchase_date": "2022-08-01T00:08:00+05:30",
            "plasce_in_service": "2022-08-22T00:08:00+05:30",
            "vendor_id": null,
            "outlet_id": 5992,
            "business_id": 26,
            "manufacturer": null,
            "model": "Samsung 1 Ton",
            "specification": "spec",
            "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1672062128_rgbsu_263.png",
            "is_operational": 1,
            "is_active": 1,
            "migrated_to": 71,
            "is_deleted": 0,
            "created_at": "2023-05-04T11:17:23+05:30",
            "modified_at": "2022-08-30T10:11:33+05:30",
            "created_by": 318,
            "modified_by": 6553,
            "amc_provider_name": null,
            "remarks": "3",
            "renewal_date": "1970-01-01T05:30:00+05:30",
            "warranty_expiration": null,
            "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/231220221141fileName.jpeg",
            "sla_expiry_date": "1970-01-01T05:30:00+05:30",
            "assigned_customer": null,
            "status": 1,
            "is_used": 1,
            "category_name": "Appliances",
            "conditions": "New",
            "ownership": "SMCL",
            "asset_tag_no": null,
            "vendor_name": "Lakhani Electronics",
            "budget": "30000",
            "budget_type": 0,
            "parent_id": 0,
            "warraanty_vendor_name": "KE Ltd.",
            "warraanty_contact_no": "9964984897",
            "warraanty_alternate_no": "8989898969",
            "warraanty_email": "keltd@gmail.com",
            "warraanty_start_date": "01-08-2022",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": "",
            "warraanty_end_date": "31-12-2023",
            "warraanty_renewal_date": null,
            "down_time": 0,
            "maintemce_cost": 0,
            "badget_type": 0,
            "budget_monthly_cost": 0,
            "budget_annual_cost": 0,
            "budget_notify": 0,
            "updated_by": 318,
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "userToNotify": "",
            "outletId": 5992,
            "outletName": "Site NGP",
            "location": "Ground Floor"
          },
          {
            "id": 27,
            "asset_name": "HVAC Plant",
            "make": "Samsung",
            "spec_rating": "1 Ton",
            "sr_no": "6456",
            "location_id": 106,
            "purchase_date": "2022-08-01T00:08:00+05:30",
            "plasce_in_service": "2022-08-22T00:08:00+05:30",
            "vendor_id": null,
            "outlet_id": 5992,
            "business_id": 26,
            "manufacturer": null,
            "model": "Samsung 1 Ton",
            "specification": "spec",
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1661854293_remqp_27.png",
            "is_operational": 1,
            "is_active": 1,
            "migrated_to": 0,
            "is_deleted": 0,
            "created_at": "2022-12-30T08:35:25+05:30",
            "modified_at": "2022-08-30T10:11:33+05:30",
            "created_by": 323,
            "modified_by": 318,
            "amc_provider_name": null,
            "remarks": "3",
            "renewal_date": null,
            "warranty_expiration": null,
            "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/231220221141fileName.jpeg",
            "sla_expiry_date": null,
            "assigned_customer": null,
            "status": 5,
            "is_used": 0,
            "category_name": "Appliances",
            "conditions": "New",
            "ownership": "SMCL",
            "asset_tag_no": null,
            "vendor_name": "Lakhani Electronics",
            "budget": null,
            "budget_type": 0,
            "parent_id": 263,
            "warraanty_vendor_name": "KE Ltd.",
            "warraanty_contact_no": "9964984897",
            "warraanty_alternate_no": "8989898969",
            "warraanty_email": "keltd@gmail.com",
            "warraanty_start_date": "01-08-2022",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "30-06-2024",
            "warraanty_renewal_date": null,
            "down_time": 0,
            "maintemce_cost": 122,
            "badget_type": 0,
            "budget_monthly_cost": 0,
            "budget_annual_cost": 0,
            "budget_notify": 0,
            "updated_by": 323,
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "userToNotify": "",
            "outletId": 5992,
            "outletName": "Site NGP",
            "location": "Ground Floor"
          },
          {
            "id": 186,
            "asset_name": "Transformer No. 1",
            "make": "Zebronic",
            "spec_rating": "2555 /-",
            "sr_no": "25498565",
            "location_id": 8876,
            "purchase_date": "2022-11-15T00:11:00+05:30",
            "plasce_in_service": "2023-11-01T00:11:00+05:30",
            "vendor_id": null,
            "outlet_id": 7147,
            "business_id": 26,
            "manufacturer": null,
            "model": "214A-525",
            "specification": "spec",
            "QR_code": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/1669113877_atzbr_186.png",
            "is_operational": 1,
            "is_active": 1,
            "migrated_to": 0,
            "is_deleted": 0,
            "created_at": "2023-05-23T10:03:09+05:30",
            "modified_at": "2022-11-22T10:44:37+05:30",
            "created_by": 6456,
            "modified_by": 318,
            "amc_provider_name": null,
            "remarks": "3",
            "renewal_date": null,
            "warranty_expiration": null,
            "image": "https://proptors-live.s3.amazonaws.com/proptor/assets/2022/assets/031220221609fileName.jpeg",
            "sla_expiry_date": null,
            "assigned_customer": null,
            "status": 1,
            "is_used": 0,
            "category_name": "Technical",
            "conditions": "New",
            "ownership": "Viki Dongre",
            "asset_tag_no": null,
            "vendor_name": "Electronic",
            "budget": null,
            "budget_type": 0,
            "parent_id": 264,
            "warraanty_vendor_name": "Zebronic Pvt Ltd",
            "warraanty_contact_no": "1245363585",
            "warraanty_alternate_no": "5555555555",
            "warraanty_email": "zebronic@gmail.com",
            "warraanty_start_date": "01-01-2023",
            "warraanty_sheduled_services": "12",
            "warraanty_attach": null,
            "warraanty_end_date": "30-09-2024",
            "warraanty_renewal_date": null,
            "down_time": 0,
            "maintemce_cost": 100,
            "badget_type": 0,
            "budget_monthly_cost": 0,
            "budget_annual_cost": 0,
            "budget_notify": 0,
            "updated_by": 0,
            "warraanty_cron_date": "2023-12-01",
            "amc_cron_date": null,
            "userToNotify": "Sachin",
            "outletId": 7147,
            "outletName": "Manik Cinemas ",
            "location": "Near Dipak Square Tilak Road Akola"
          },
          {
            "id": 2,
            "asset_name": "Chiller Plant No. 1",
            "make": "Winzz",
            "spec_rating": "2251KVA",
            "sr_no": "78",
            "location_id": 107,
            "purchase_date": "2023-01-01T00:00:00+05:30",
            "plasce_in_service": "2023-05-05T00:00:00+05:30",
            "vendor_id": null,
            "outlet_id": 5994,
            "business_id": 26,
            "manufacturer": null,
            "model": "Winzz 6.56 ",
            "specification": null,
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_fhnoi_2.png",
            "is_operational": 1,
            "is_active": 1,
            "migrated_to": 0,
            "is_deleted": 0,
            "created_at": "2023-05-04T07:30:01+05:30",
            "modified_at": "2022-05-24T11:43:46+05:30",
            "created_by": 318,
            "modified_by": 6553,
            "amc_provider_name": null,
            "remarks": null,
            "renewal_date": "1970-01-01T05:30:00+05:30",
            "warranty_expiration": "",
            "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
            "sla_expiry_date": "1970-01-01T00:00:00+05:30",
            "assigned_customer": null,
            "status": 1,
            "is_used": 0,
            "category_name": "Machine",
            "conditions": "Used",
            "ownership": "Self-owned",
            "asset_tag_no": "",
            "vendor_name": "Winzz Vehicle Ltd",
            "budget": "500",
            "budget_type": 0,
            "parent_id": 1,
            "warraanty_vendor_name": "Lakhani Enterprises",
            "warraanty_contact_no": "7895663541",
            "warraanty_alternate_no": "9564325632",
            "warraanty_email": "le@gmail.com",
            "warraanty_start_date": "10-01-2021",
            "warraanty_sheduled_services": "1",
            "warraanty_attach": "",
            "warraanty_end_date": "31-12-2024",
            "warraanty_renewal_date": null,
            "down_time": 0,
            "maintemce_cost": 1130,
            "badget_type": 0,
            "budget_monthly_cost": 0,
            "budget_annual_cost": 0,
            "budget_notify": 0,
            "updated_by": 0,
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "userToNotify": "",
            "outletId": 5994,
            "outletName": "Express Avenue Mall",
            "location": "First Floor"
          },
          {
            "id": 2,
            "asset_name": "AHU Motor No. 5",
            "make": "Winzz",
            "spec_rating": "2251KVA",
            "sr_no": "78",
            "location_id": 107,
            "purchase_date": "2023-01-01T00:00:00+05:30",
            "plasce_in_service": "2023-05-05T00:00:00+05:30",
            "vendor_id": null,
            "outlet_id": 5994,
            "business_id": 26,
            "manufacturer": null,
            "model": "Winzz 6.56 ",
            "specification": null,
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392626_fhnoi_2.png",
            "is_operational": 1,
            "is_active": 1,
            "migrated_to": 0,
            "is_deleted": 0,
            "created_at": "2023-05-04T07:30:01+05:30",
            "modified_at": "2022-05-24T11:43:46+05:30",
            "created_by": 318,
            "modified_by": 6553,
            "amc_provider_name": null,
            "remarks": null,
            "renewal_date": "1970-01-01T05:30:00+05:30",
            "warranty_expiration": "",
            "image": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/230820221426fileName.jpeg",
            "sla_expiry_date": "1970-01-01T00:00:00+05:30",
            "assigned_customer": null,
            "status": 1,
            "is_used": 0,
            "category_name": "Machine",
            "conditions": "Used",
            "ownership": "Self-owned",
            "asset_tag_no": "",
            "vendor_name": "Winzz Vehicle Ltd",
            "budget": "500",
            "budget_type": 0,
            "parent_id": 1,
            "warraanty_vendor_name": "Lakhani Enterprises",
            "warraanty_contact_no": "7895663541",
            "warraanty_alternate_no": "9564325632",
            "warraanty_email": "le@gmail.com",
            "warraanty_start_date": "10-01-2021",
            "warraanty_sheduled_services": "1",
            "warraanty_attach": "",
            "warraanty_end_date": "30-06-2025",
            "warraanty_renewal_date": null,
            "down_time": 0,
            "maintemce_cost": 1130,
            "badget_type": 0,
            "budget_monthly_cost": 0,
            "budget_annual_cost": 0,
            "budget_notify": 0,
            "updated_by": 0,
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "userToNotify": "",
            "outletId": 5994,
            "outletName": "Express Avenue Mall",
            "location": "First Floor"
          }
        ]
      } else {
        this.warrantyExpirationList = result['assetWarrantyExp'];
      }
      // this.assetDashboard = result;

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.curveChart()
      // this.linecurveChart()
    })

  }

  assetWithverLessLife = []
  getAssetWithverLessLife() {
    let body = {

      // businessId:this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      limit: 10,
      pageNo: 1,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,
    }

    this.apiService.postCall('AssetManagements/assetWithVerryLessFull', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.assetWithverLessLife = [
          {
            "id": "2",
            "asset_name": "Generator No. 2",
            "usefullLifeYear": "2",
            "end_date": "-190 days",
            "outlet_name": "Express Avenue Mall",
            "locationName": "First Floor",
            "life_end_date": "30-06-2024",
            "plasce_in_service": "0 years"
          },
          {
            "id": "16",
            "asset_name": "HVAC Plant",
            "usefullLifeYear": "3",
            "end_date": "-86 days",
            "outlet_name": "Site - A.",
            "locationName": "Common Area",
            "life_end_date": "31-12-2024",
            "plasce_in_service": "3 years"
          },
          {
            "id": "186",
            "asset_name": "Transformer No. 1",
            "usefullLifeYear": "1",
            "end_date": "127 days",
            "outlet_name": "Manik Cinemas ",
            "locationName": "Near Dipak Square Tilak Road Akola",
            "life_end_date": "31-12-2024",
            "plasce_in_service": "0 years"
          },
          {
            "id": "4",
            "asset_name": "Chiller Plant No. 1",
            "usefullLifeYear": "3",
            "end_date": "174 days",
            "outlet_name": "Site - A.",
            "locationName": "Ground Floor",
            "life_end_date": "30-06-2025",
            "plasce_in_service": "2 years"
          },
          {
            "id": "1",
            "asset_name": "AHU Motor No. 5",
            "usefullLifeYear": "3",
            "end_date": "174 days",
            "outlet_name": "Site - A.",
            "locationName": "Ground Floor",
            "life_end_date": "30-09-2025",
            "plasce_in_service": "2 years"
          }
        ]
      } else {
        this.assetWithverLessLife = result['assetWithVerryLessFull'];
      }
      // this.assetDashboard = result;

      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.curveChart()
      // this.linecurveChart()
    })
  }

  maxBearkDown = []
  getAssetswithMaxBraekDown() {
    let body = {

      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // businessId:this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      limit: 10,
      pageNo: 1,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,

    }

    this.apiService.postCall('AssetManagements/maxBreakdownList', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.maxBearkDown = [
          {
            "id": "209",
            "asset_name": "Generator No. 2",
            "sr_no": "2",
            "make": "ADLEC",
            "spec_rating": "1000 AMP",
            "AssetManagementBreakdowns": "2",
            "outlet_name": "Site NGP",
            "locationName": "Second Floor",
            "status": "2",
            "downtime": "507"
          },
          {
            "id": "19",
            "asset_name": "Chiller Plant No. 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "spec_rating": "550 TR",
            "AssetManagementBreakdowns": "1",
            "outlet_name": "Site - A.",
            "locationName": "Common Area",
            "status": "2",
            "downtime": "94"
          },
          {
            "id": "264",
            "asset_name": "AHU Motor No. 5",
            "sr_no": "a",
            "make": "",
            "spec_rating": "a",
            "AssetManagementBreakdowns": "1",
            "outlet_name": "Manik Cinemas ",
            "locationName": "Near Dipak Square Tilak Road Akola",
            "status": "2",
            "downtime": "23"
          }
        ]
      } else {
        this.maxBearkDown = result['maxNoOfBreakdown'];
      }
    })
  }

  assetMaiantanance = []
  getassetMaiantanance() {
    let body = {

      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // businessId:this.apiService.buisnessId,
      proprtyId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      limit: 10,
      pageNo: 1,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,

    }

    this.apiService.postCall('AssetManagements/assetHighestMaintenceCosts', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.assetMaiantanance = [
          {
            "asset_management_id": "6",
            "quantity": "1",
            "price": "80",
            "id": "107",
            "asset_name": "Generator No. 2",
            "make": "Zacko",
            "spec_rating": "2251KVA",
            "sr_no": "23",
            "location_id": "107",
            "purchase_date": "2021-01-01 00:00:00",
            "plasce_in_service": "2021-01-10 00:00:00",
            "vendor_id": null,
            "outlet_id": "71",
            "business_id": "26",
            "manufacturer": null,
            "model": "Zacko - 56",
            "specification": null,
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653486936_jkrcu_6.png",
            "is_operational": "1",
            "is_active": "1",
            "migrated_to": "0",
            "is_deleted": "0",
            "created_at": "2022-10-13 05:40:01",
            "modified_at": "2022-05-24 11:43:49",
            "created_by": "1",
            "modified_by": "318",
            "amc_provider_name": null,
            "remarks": null,
            "renewal_date": "1970-01-01 05:30:00",
            "warranty_expiration": "",
            "image": "https://proptors-live.s3.amazonaws.com/enrich-salons---test-account/enrich---four-bungalows/2019/floorplans/Jw4TSUI1548750750New.png",
            "sla_expiry_date": "1970-01-01 00:00:00",
            "assigned_customer": null,
            "status": "1",
            "is_used": "0",
            "category_name": "Machine",
            "conditions": "Used",
            "ownership": "Self-owned",
            "asset_tag_no": "",
            "vendor_name": "NY Ltd",
            "budget": null,
            "budget_type": "0",
            "parent_id": "0",
            "warraanty_vendor_name": "Zacko",
            "warraanty_contact_no": "7897864795",
            "warraanty_alternate_no": "8998798771",
            "warraanty_email": "zacko@gmail.com",
            "warraanty_start_date": "2021-01-10",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "2023-01-15",
            "warraanty_renewal_date": null,
            "down_time": "0",
            "maintemce_cost": "29,500",
            "badget_type": "0",
            "budget_monthly_cost": "0",
            "budget_annual_cost": "0",
            "budget_notify": "0",
            "updated_by": "318",
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "outlet_name": "Site - A.",
            "outlet_code": "DM1",
            "site_code": null,
            "location": "",
            "latitude": "20.7025544360567",
            "longitude": "76.99185782698785",
            "city": "",
            "zone_id": "27",
            "state_id": "92",
            "citie_id": "1072",
            "cluster_id": "56",
            "district_id": "307",
            "client_id": "43",
            "site_type": null,
            "site_group": null,
            "area": "",
            "qr_code": "1571380368_71.png",
            "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
            "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
            "default_users": "4431",
            "default_users_internal_complaints": null,
            "default_users_near_miss": null,
            "default_department": "86",
            "is_ad_hoc": "0",
            "created": "2018-09-10 18:43:16",
            "modified": "2022-05-22 19:35:46",
            "name": "First Floor",
            "qr_image": "https://proptors-live.s3.amazonaws.com/synccit/property_barcode/2022/property_barcode/1653388666_cizwm_107.png",
            "finalcost": 29500,
            "locationName": "First Floor",
            "amccost": 0,
            "additionalCost": 0,
            "partcost": 0
          },
          {
            "asset_management_id": "3",
            "quantity": "4",
            "price": "300",
            "id": "2339",
            "asset_name": "Chiller Plant No. 1",
            "make": "JK",
            "spec_rating": "2250KVA",
            "sr_no": "14236",
            "location_id": "2339",
            "purchase_date": "2021-01-01 00:00:00",
            "plasce_in_service": "2021-01-10 00:00:00",
            "vendor_id": null,
            "outlet_id": "71",
            "business_id": "26",
            "manufacturer": null,
            "model": "JK G89",
            "specification": null,
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1653392627_twils_3.png",
            "is_operational": "1",
            "is_active": "1",
            "migrated_to": "0",
            "is_deleted": "0",
            "created_at": "2022-09-21 15:00:01",
            "modified_at": "2022-05-24 11:43:47",
            "created_by": "1",
            "modified_by": "318",
            "amc_provider_name": null,
            "remarks": null,
            "renewal_date": "1970-01-01 05:30:00",
            "warranty_expiration": "",
            "image": "https://proptors-live.s3.amazonaws.com/demo-account/site---a/2020/floorplans/gGbm3xy1599892265New.png",
            "sla_expiry_date": "1970-01-01 00:00:00",
            "assigned_customer": null,
            "status": "1",
            "is_used": "0",
            "category_name": "Machine",
            "conditions": "Used",
            "ownership": "Self-owned",
            "asset_tag_no": "",
            "vendor_name": "HDA Machines",
            "budget": "750",
            "budget_type": "0",
            "parent_id": "0",
            "warraanty_vendor_name": "Sai Machines",
            "warraanty_contact_no": "9564325632",
            "warraanty_alternate_no": "8954612645",
            "warraanty_email": "sai@gmail.com",
            "warraanty_start_date": "2021-01-10",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "2023-01-15",
            "warraanty_renewal_date": null,
            "down_time": "0",
            "maintemce_cost": "3420",
            "badget_type": "0",
            "budget_monthly_cost": "0",
            "budget_annual_cost": "0",
            "budget_notify": "0",
            "updated_by": "323",
            "warraanty_cron_date": null,
            "amc_cron_date": null,
            "outlet_name": "Site - A.",
            "outlet_code": "DM1",
            "site_code": null,
            "location": "",
            "latitude": "20.7025544360567",
            "longitude": "76.99185782698785",
            "city": "",
            "zone_id": "27",
            "state_id": "92",
            "citie_id": "1072",
            "cluster_id": "56",
            "district_id": "307",
            "client_id": "43",
            "site_type": null,
            "site_group": null,
            "area": "",
            "qr_code": "1571380368_71.png",
            "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
            "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
            "default_users": "4431",
            "default_users_internal_complaints": null,
            "default_users_near_miss": null,
            "default_department": "86",
            "is_ad_hoc": "0",
            "created": "2020-09-12 11:56:20",
            "modified": "2022-05-22 19:36:54",
            "name": "Second Floor",
            "qr_image": "https://proptors-live.s3.amazonaws.com/synccit/property_barcode/2022/property_barcode/1653390312_uinvz_2339.png",
            "finalcost": 18000,
            "locationName": "Second Floor",
            "amccost": 0,
            "additionalCost": 0,
            "partcost": 0
          },
          {
            "asset_management_id": "19",
            "quantity": "1",
            "price": "100",
            "id": "4848",
            "asset_name": "AHU Motor No. 5",
            "make": "DAIKIN",
            "spec_rating": "550 TR",
            "sr_no": "6700101",
            "location_id": "4848",
            "purchase_date": "2019-07-20 00:07:00",
            "plasce_in_service": "2019-07-20 00:07:00",
            "vendor_id": null,
            "outlet_id": "71",
            "business_id": "26",
            "manufacturer": null,
            "model": "C3612 BLYY 2",
            "specification": "spec",
            "QR_code": "https://proptors-live.s3.amazonaws.com/synccit/assets/2022/assets/1658668545_ozybk_19.png",
            "is_operational": "1",
            "is_active": "1",
            "migrated_to": "5994",
            "is_deleted": "0",
            "created_at": "2023-05-04 11:18:23",
            "modified_at": "2022-07-24 13:15:45",
            "created_by": "1",
            "modified_by": "6553",
            "amc_provider_name": null,
            "remarks": "3",
            "renewal_date": "1970-01-01 05:30:00",
            "warranty_expiration": null,
            "image": "https://proptors-live.s3.amazonaws.com/enrich-salons---test-account/enrich---four-bungalows/2019/floorplans/Jw4TSUI1548750750New.png",
            "sla_expiry_date": "1970-01-01 00:00:00",
            "assigned_customer": null,
            "status": "1",
            "is_used": "1",
            "category_name": "HVAC",
            "conditions": "New",
            "ownership": "WMG",
            "asset_tag_no": null,
            "vendor_name": "KE Ltd.",
            "budget": "2000",
            "budget_type": "0",
            "parent_id": "20",
            "warraanty_vendor_name": "Daikin Ltd.",
            "warraanty_contact_no": "9598989898",
            "warraanty_alternate_no": "7988979798",
            "warraanty_email": "daikin@gmail.com",
            "warraanty_start_date": "2022-08-01",
            "warraanty_sheduled_services": "2",
            "warraanty_attach": null,
            "warraanty_end_date": "2022-10-31",
            "warraanty_renewal_date": null,
            "down_time": "0",
            "maintemce_cost": "3311.11",
            "badget_type": "0",
            "budget_monthly_cost": "0",
            "budget_annual_cost": "0",
            "budget_notify": "0",
            "updated_by": "323",
            "warraanty_cron_date": "2022-10-30",
            "amc_cron_date": "2022-10-28",
            "outlet_name": "Site - A.",
            "outlet_code": "DM1",
            "site_code": null,
            "location": "",
            "latitude": "20.7025544360567",
            "longitude": "76.99185782698785",
            "city": "",
            "zone_id": "27",
            "state_id": "92",
            "citie_id": "1072",
            "cluster_id": "56",
            "district_id": "307",
            "client_id": "43",
            "site_type": null,
            "site_group": null,
            "area": "",
            "qr_code": "1571380368_71.png",
            "qr_code_emp_voice": "https://proptors-dev.s3.amazonaws.com/proptor/property_barcode/2022/property_barcode/1664456238_cvjxk_71.png",
            "permit_qr_code": "https://proptors-live.s3.amazonaws.com/proptor/property_barcode/2023/property_barcode/1681305625_bqkhs_71.png",
            "default_users": "4431",
            "default_users_internal_complaints": null,
            "default_users_near_miss": null,
            "default_department": "86",
            "is_ad_hoc": "0",
            "created": "2021-07-24 16:27:27",
            "modified": "2022-05-22 19:38:05",
            "name": "Common Area",
            "qr_image": "https://proptors-live.s3.amazonaws.com/synccit/property_barcode/2022/property_barcode/1653392208_vsgwh_4848.png",
            "finalcost": 7500,
            "locationName": "Common Area",
            "amccost": 0,
            "additionalCost": 0,
            "partcost": 0
          }
        ]
      } else {
        this.assetMaiantanance = result['assetHighestMaintenceCosts'];
      }
    })
  }

  gettoFixedData(data) {
    // console.log(data)
    return data.toFixed(0)
  }


  getEndDate(data) {
    let value = data.replace(" days", "")
    return parseInt(value)
  }
  displayFlag2 = true
  curveChart() {

    this.chartDataLine = {
      type: 'AreaChart',
      data: this.assetDashboard.maintenceCostArrayGraph,
      chartColumns: ['Month', 'Cost'],
      // options: {
      //   responsive: true,
      //   borderRadius: 15,
      //   colors: ['#33CCB2'],
      //   // legend: { position: 'bottom', alignment: 'center' },
      //   legend: { position: 'none' },
      //   hAxis: { title: '' },
      //   vAxis: { title: '' },
      //   seriesType: 'bars',
      //   series: { 2: { type: 'line' } }
      // },
      options : { 
        isStacked:true,
        hAxis: {
           title: 'Month',
           titleTextStyle: {color: '#000000'}
        },
        vAxis:{
          title: 'Cost',
          titleTextStyle: {color: '#000000'}
        },
        colors: ['#27AE60'],
     },
      width: 1000,
      height: 400
    };
    this.displayFlag2 = false

  }

  curveChart2() {
    let jsonToBeUsed = []
    for (var type in this.assetDashboard.maintenceCostArrayGraph) {
      let item = {};
      item['Month'] = this.assetDashboard.maintenceCostArrayGraph[type].Month;
      item['cost'] = this.assetDashboard.maintenceCostArrayGraph[type].cost;
      jsonToBeUsed.push(item);


    }
    // console.log(jsonToBeUsed)
    let data = jsonToBeUsed
    // console.log(data)
    // let data1= [ {name:"Bob",value:23}, {name:"Robin",value:15}, {name:"Anne",value:31}, {name:"Mark",value:26}, {name:"Joe",value:17}, {name:"Eve",value:38}, {name:"Karen",value:42}, {name:"Kirsty",value:22}, {name:"Chris",value:35}, {name:"Lisa",value:33}, {name:"Tom",value:9}, {name:"Stacy",value:12}, {name:"Charles",value:17}, {name:"Mary",value:25}, ]
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;

    //  var parseDate = d3.time.format("%Y-%m-%d %X");
    var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;
    var area = d3.area()
      .curve(d3.curveBasis)
      .x(function (d) { return x(d.Month); })
      .y0(height)
      .y1(function (d) { return y(d.cost); });



    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    var svg = d3.select("#chart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");



    svg.append("text")
      .attr("class", "x label")
      .attr("text-anchor", "end")
      .attr("x", width - 50)
      .attr("y", height + 30)
    // .text("Time Chart");



    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")


    var svgAxis = d3.select("#groupedChartYAxis")
      .append("svg")
      .attr("width", 70)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + 60 + "," + margin.top + ")");

    svgAxis.append("text")
      .attr("class", "y_label cart-title")
      .attr("text-anchor", "end")
      .attr("x", -86)
      .attr("y", -46)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
    // .text("Cost in INR");

    ///////////////////////////////////////////////////////////
    // Initialise a X axis:
    var x = d3.scaleBand()
      .domain(data.map(function (d) { return d.Month; 1 }))
      .range([0, width])
      .padding(0.1);

    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return d.cost; })])
      .range([height, 0]);


    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .attr("class", "myXaxis");


    //   // Add Y axis

    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    svgAxis.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    /////////////////////////////////

    //////////////////////////////////////////////////////

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4.5)
      .attr("transform", "translate(80,0)")
      .attr("class", "lineTest")
      .attr("d", d3.line()
        .curve(d3.curveBasis)
        .x(function (d) { return x(d.Month) })
        .y(function (d) { return y(d.cost) })
      )

    svg.append("path")
      .data([data])
      .attr("class", "myArea")
      .attr("transform", "translate(80,0)")
      .attr("d", area)
    //  .on("mouseover", function(d) {

    var dots = svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("fill", "#33CCB2")
      .attr("transform", "translate(85,0)")
      .attr("cx", function (d) { return x(d.Month); })
      .attr("cy", function (d) { return y(d.cost); })
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html((d.Month) + "<br/>" + d.cost.toLocaleString())
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // })




    //    d3.selectAll("g.myYaxis g.tick")
    //  .append("line")
    //  .attr("class", "gridline")
    //  .attr("x1", 0)
    //  .attr("y1", 0)
    //  .attr("x2", width)
    //  .attr("y2", 0);

    //  d3.selectAll("g.myXaxis g.tick")
    //  .append("line")
    //  .attr("class", "gridline")
    //  .attr("x1", 0)
    //  .attr("y1", -height)
    //  .attr("x2", 0)
    //  .attr("y2", 0);

  }

  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : null,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : null,
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : null,
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : null,
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : null,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']
      this.filterpropertyList = result['data']
if(this.apiService.outletId){
  let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
}
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })
  }

  getPropertyName(id) {
    let idx = this.locationList.findIndex(x => x.id == id)
    if (idx != -1) return this.locationList[idx].outletName
  }


  filterPropertySearch
  propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  departmentList
  filterdepartmentList = []
  getDepartmentList() {
    this.apiService.getCall('asset-managements/index/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data']['data'];
        this.filterdepartmentList = result['data']['data'];
      }
    });
  }

  filterDepartmentSearch
  // propertFilter = false;
  // filterdepartmentList=[]
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }




  displayFlag = true
  linecurveChart() {
    // console.log(this.assetDashboard.assetBudgetArrayGraph)
    this.chartData = {
      type: 'ColumnChart',
      data: this.assetDashboard.assetBudgetArrayGraph,
      chartColumns: ["Date", 'Budget Allocated Cost', 'Budget Used'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#1F7A6B', '#6FCF97'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: 'Value ('+ this.apiService.convertCureency() + ')' },
        seriesType: 'bars',
        series: { 2: { type: 'bars' } }
      },
      width: 1000,
      height: 400
    };
    this.displayFlag = false

  }

  //NotUsed
  linecurveChart2() {
    let array = []
    for (var type in this.assetDashboard.assetBudgetArrayGraph) {
      if (this.assetDashboard.assetBudgetArrayGraph[type].Month) {
        let item = {};
        item['Month'] = this.assetDashboard.assetBudgetArrayGraph[type].Month;
        item['budgetAllocatCost'] = parseInt(this.assetDashboard.assetBudgetArrayGraph[type].budgetAllocatCost);
        item['budgetUsed'] = parseInt(this.assetDashboard.assetBudgetArrayGraph[type].budgetUsed);
        array.push(item);


      }

    }
    // let result = {"success":true,"msg":"Data Found","data":{"ticketsByDepartment":[["","Open","Closed","Parked","Not Valid"],["Housekeeping1",65,7,5,1],["Housekeeping",12,0,0,0],["Operations",0,0,0,0],["Engineering",0,0,0,0],["Security",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["RnM",0,0,0,0],["Soft Services",0,0,0,0],["F & B",0,0,0,0],["Engineering",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["Housekeeping",0,0,0,0],["Housekeeping",0,0,0,0],["Engineering",0,0,0,0],["Security",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Technical",0,0,0,0],["Maintenance",0,0,0,0],["IT",0,0,0,0],["FM",0,0,0,0],["IT",0,0,0,0],["Marketing",0,0,0,0],["Electrical",0,0,0,0],["HVAC",0,0,0,0],["Plumbing",0,0,0,0],["Civil",0,0,0,0],["Mechanical",0,0,0,0],["Fire & Safety",0,0,0,0],["Emergency",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["ff",0,0,0,0],["Engineering",0,0,0,0],["MEP",0,0,0,0],["Purchase",0,0,0,0],["Management",0,0,0,0],["Engineering",0,0,0,0],["Housekeeping",0,0,0,0],["Operations",0,0,0,0],["Security",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["General",5,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Technical",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["Housekeeping",0,0,0,0],["Security",0,0,0,0],["test1",1,0,0,0],["payment",0,0,0,0],["Event Management",7,0,0,0],["Gaming",1,0,0,0],["naaa",0,0,0,0],["naa",0,0,0,0],["house hold",1,0,0,0],["field ",0,0,0,0],["jyjyc",0,0,0,0],["game tester ",0,0,0,0],["IT Department",2,0,0,0],["Housekeeping",0,0,0,0],["SSDWDESDDDDWD",0,0,0,0],["Keeping",0,0,0,0],["Technical support",0,0,0,0],["Technical ",0,0,0,0]]}}
    let max: number = 0
    let data = []
    // console.log(array)
    for (let i = 0; i < array.length; i++) {
      // console.log()
      data.push({ 'group': array[i].Month, 'Open': array[i].budgetAllocatCost.toFixed(0), 'Closed': array[i].budgetUsed.toFixed(0) })

      if (max < array[i].budgetAllocatCost) max = array[i].budgetAllocatCost
      if (max < array[i].budgetUsed) max = array[i].budgetUsed


    }

    // console.log(data)

    var margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;


    // append the svg object to the body of the page
    var svgAxis = d3.select("#groupedChartYAxis2")
      .append("svg")
      .attr("width", 80)
      .attr("height", 500)
      .append("g")
      .attr("transform",
        "translate(" + 70 + "," + margin.top + ")");
    // append the svg object to the body of the page
    var svg = d3.select("#chart2")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", 500)
      .append("g")
      .attr("transform",
        "translate(" + -5 + "," + margin.top + ")");

    // Parse the Data



    // List of subgroups = header of the csv files = soil condition here
    var subgroups = ['Open', 'Closed']

    var groups = []
    for (let i of data) groups.push(i.group)
    // List of groups = species here = value of the first column called group -> I show them on the X axis



    // console.log('subgroups', subgroups)
    // console.log('groups', groups)

    if (data.length < 10) {
      for (let i = data.length; i <= 8; i++) {
        let obj = {}
        let str = " "
        for (let j = 0; j < i; j++) str = str + " "
        obj['group'] = str
        for (let d of subgroups) obj[d] = 0

        // console.log(obj)
        data.push(obj)
        groups.push(str)
      }
    }
    // console.log(data)

    // Add X axis
    var x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2])
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      .call(d3.axisBottom(x).tickSize(0))

      .selectAll("text")
      .style("text-anchor", "end")

      .attr("transform", "rotate(-65)");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, (max + (max * 0.1))])
      .range([height, 0]);
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));

    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));


    // Another scale for subgroup position?
    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, 80])
      .padding([0.05])

    // color palette = one color per subgroup
    var color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(['#1F7A6B', '#6fcf97', '#F2994A', '#D6D6D6'])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
      .enter().append("rect")
      .attr("x", function (d) { return xSubgroup(d.key); })
      .attr("y", function (d) { return y(d.value); })
      .attr("width", 19.5)
      .attr("height", function (d) { return height - y(d.value); })
      .attr("fill", function (d) { return color(d.key); });


    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")

      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key) {
          // // console.log(key , d[key])
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("text")
      .text(function (d) {
        if (d.value != 0) return d.value;
      })
      .attr("x", function (d) {
        let data = (xSubgroup(d.key) + 5)
        return data;
      })
      .attr("y", function (d) {
        return y(d.value) - 5;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("fill", function (d) { return color(d.key); })
      .attr("text-anchor", "middle");

    // d3.selectAll("g.myYaxis g.tick")
    // .append("line")
    // .attr("class", "gridline")
    // .attr("x1", 0)
    // .attr("y1", 0)
    // .attr("x2", width)
    // .attr("y2", 0);

    // d3.selectAll("g.myXaxis g.tick")
    // .append("line")
    // .attr("class", "gridline")
    // .attr("x1", 0)
    // .attr("y1", -height)
    // .attr("x2", 0)
    // .attr("y2", 0);



    //       let data = []
    //       let data1 = []
    //       for (var type in this.assetDashboard.assetBudgetArrayGraph) {
    //         if(this.assetDashboard.assetBudgetArrayGraph[type].Month){
    //           let item = {};
    //           item['name'] = this.assetDashboard.assetBudgetArrayGraph[type].Month;
    //           item['value'] = this.assetDashboard.assetBudgetArrayGraph[type].budgetUsed;
    //           data.push(item);

    //           let item1={}

    //           item1['name'] = this.assetDashboard.assetBudgetArrayGraph[type].Month;
    //           item1['value'] = this.assetDashboard.assetBudgetArrayGraph[type].budgetAllocatCost;
    //           data1.push(item);
    //         }

    //       }


    //       var div = d3.select("body").append("div")
    // .attr("class", "tooltip")
    // .style("opacity", 0);

    //       // console.log(data , data1)
    //       // let data=  [ {name:"Bob",value:33}, {name:"Robin",value:12}, {name:"Anne",value:41}, {name:"Mark",value:16}, {name:"Joe",value:59}, {name:"Eve",value:38}, {name:"Karen",value:21}, {name:"Kirsty",value:25}, {name:"Chris",value:30}, {name:"Lisa",value:47}, {name:"Tom",value:5}, {name:"Stacy",value:20}, {name:"Charles",value:13}, {name:"Mary",value:29}, ]
    //       // let data1= [ {name:"Bob",value:23}, {name:"Robin",value:15}, {name:"Anne",value:31}, {name:"Mark",value:26}, {name:"Joe",value:17}, {name:"Eve",value:38}, {name:"Karen",value:42}, {name:"Kirsty",value:22}, {name:"Chris",value:35}, {name:"Lisa",value:33}, {name:"Tom",value:9}, {name:"Stacy",value:12}, {name:"Charles",value:17}, {name:"Mary",value:25}, ]
    //             var margin = {top: 10, right: 30, bottom: 30, left: 50},
    //             width =this.fixedIWdth,
    //             height = 400 - margin.top - margin.bottom;

    //            //  var parseDate = d3.time.format("%Y-%m-%d %X");
    //            var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;


    //            var svgAxis = d3.select("#groupedChartYAxis2")
    //            .append("svg")
    //              .attr("width", 60 )
    //              .attr("height", height + margin.top + margin.bottom)
    //            .append("g")
    //              .attr("transform",
    //                    "translate(" + 50 + "," + margin.top + ")");




    //             var svg = d3.select("#chart2")
    //            .append("svg")
    //              .attr("width", width + margin.left + margin.right)
    //              .attr("height", height + margin.top + margin.bottom)
    //            .append("g")
    //              .attr("transform",
    //                    "translate(" + -10 + "," + margin.top + ")");



    //         svg.append("text")
    //         .attr("class", "x label")
    //         .attr("text-anchor", "end")
    //         .attr("x", width -50 )
    //         .attr("y", height + 30)
    //         // .text("Time Chart");


    //         svg.append("text")
    //         .attr("class", "y label")
    //         .attr("text-anchor", "end")
    //         .attr("x", -86)
    //         .attr("y", -46)
    //         .attr("dy", ".75em")
    //         .attr("transform", "rotate(-90)")
    //         .text("Litres");
    //         svg.append("g")
    //         .attr("transform", "translate(0," + height + ")")
    //         .attr("class","myXaxis")



    //         ///////////////////////////////////////////////////////////
    //          // Initialise a X axis:
    //          var x = d3.scaleBand()
    //          .domain(data.map(function(d) { return d.name;1 }))
    //          .range([0, width])
    //          .padding(0.1);

    //    var y = d3.scaleLinear()
    //    .domain([0, d3.max(data, function(d) { return d.value; })])
    //          .range([height, 0]);


    //            svg.append("g")
    //              .attr("transform", "translate(0," + height + ")")
    //              .call(d3.axisBottom(x))
    //          .attr("class","myXaxis");

    //          //   // Add Y axis

    //            svg.append("g")
    //              .call(d3.axisLeft(y))
    //              .attr("class","myYaxis");

    //              svgAxis.append("g")
    //              .call(d3.axisLeft(y))
    //              .attr("class","myYaxis");

    //         /////////////////////////////////

    //         //////////////////////////////////////////////////////

    //            // Add the line
    //            svg.append("path")
    //              .datum(data)
    //              .attr("fill", "none")
    //              .attr("stroke", "steelblue")
    //              .attr("stroke-width", 4.5)
    //              .attr("class","lineTestGreen")
    //              .attr("transform", "translate(80,0)")

    //              .attr("d", d3.line()
    //              .curve(d3.curveBasis)
    //                .x(function(d) { return x(d.name) })
    //                .y(function(d) { return y(d.value) })
    //                )
    //                var dots=  svg.selectAll("dot")
    //              .data(data)
    //            .enter().append("circle")
    //              .attr("r", 5)
    //              .attr("fill", "#5D5FEF")
    //              .attr("transform", "translate(80,0)")
    //              .attr("cx", function(d) { return x(d.name); })
    //              .attr("cy", function(d) { return y(d.value); })
    //              .on("mouseover", function(d) {
    //                div.transition()
    //                  .duration(200)
    //                  .style("opacity", .9);
    //                div.html((d.name) + "<br/>" + d.value)
    //                  .style("left", (d3.event.pageX) + "px")
    //                  .style("top", (d3.event.pageY - 28) + "px");
    //                })
    //              .on("mouseout", function(d) {
    //                div.transition()
    //                  .duration(500)
    //                  .style("opacity", 0);
    //                });

    //             svg.append("path")
    //              .datum(data1)
    //              .attr("fill", "none")
    //              .attr("stroke", "#6FCF97")
    //              .attr("stroke-width", 4.5)
    //              .attr("class","lineTest")
    //              .attr("transform", "translate(80,0)")

    //              .attr("d", d3.line()

    //                .x(function(d) { return x(d.name) })
    //                .y(function(d) { return y(d.value) })
    //                )
    //                var dots=  svg.selectAll("dot")
    //              .data(data)
    //            .enter().append("circle")
    //              .attr("r", 5)
    //              .attr("fill", "#5D5FEF")
    //              .attr("transform", "translate(80,0)")
    //              .attr("cx", function(d) { return x(d.name); })
    //              .attr("cy", function(d) { return y(d.value); })
    //              .on("mouseover", function(d) {
    //                div.transition()
    //                  .duration(200)
    //                  .style("opacity", .9);
    //                div.html((d.name) + "<br/>" + d.value)
    //                  .style("left", (d3.event.pageX) + "px")
    //                  .style("top", (d3.event.pageY - 28) + "px");
    //                })
    //              .on("mouseout", function(d) {
    //                div.transition()
    //                  .duration(500)
    //                  .style("opacity", 0);
    //                });


    //    d3.selectAll("g.myYaxis g.tick")
    //  .append("line")
    //  .attr("class", "gridline")
    //  .attr("x1", 0)
    //  .attr("y1", 0)
    //  .attr("x2", width)
    //  .attr("y2", 0);

    //  d3.selectAll("g.myXaxis g.tick")
    //  .append("line")
    //  .attr("class", "gridline")
    //  .attr("x1", 0)
    //  .attr("y1", -height)
    //  .attr("x2", 0)
    //  .attr("y2", 0);

  }



  openListWarranty(id) {
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Asset ID', 'Property', 'Location', 'Expiry Date', 'Notify'], api: 'AssetManagements/assetWarrantyList', tableName: 'Warranty Status', dataSource: ['asset_name', 'id', 'outletName', 'location', 'warraanty_end_date', 'userToNotify'], apiObj: 'assetWarrantyExp', rowCountParam: 'assetWarrantyCount' }
    this.route.navigate(['/dashboard-index/' + id])

  }

  openListMaxBraekdoen(id) {
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Asset ID', 'Property', 'Location', 'Count', 'Total Downtime'], api: 'AssetManagements/maxBreakdownList', tableName: 'Breakdown Statistics', dataSource: ['asset_name', 'id', 'outlet_name', 'locationName', 'AssetManagementBreakdowns', 'downtime'], apiObj: 'maxNoOfBreakdown', rowCountParam: 'maxBreakdownCounts' }
    this.route.navigate(['/dashboard-index/' + id])

  }

  openListassetMaiantanance(id) {
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Asset ID', 'Property', 'Location', 'Total Cost', 'Parts Cost', 'AMC Cost', 'Additional Costs'], api: 'AssetManagements/assetHighestMaintenceCosts', tableName: 'Maintenance Cost Incurred', dataSource: ['asset_name', 'id', 'outlet_name', 'locationName', 'finalcost', 'partcost', 'amccost', 'additionalCost'], apiObj: 'assetHighestMaintenceCosts', rowCountParam: 'count' }
    this.route.navigate(['/dashboard-index/' + id])

  }

  openListassetWithverLessLife(id) {
    this.storedData.dashBoardIndex = { headers: ['Asset Name', 'Asset ID', 'Property', 'Location', 'End Date', 'Remaining Service Period'], api: 'AssetManagements/assetWithVerryLessFull', tableName: 'Useful Life Availability', dataSource: ['asset_name', 'id', 'outlet_name', 'locationName', 'life_end_date', 'plasce_in_service'], apiObj: 'assetWithVerryLessFull', rowCountParam: 'count' }
    this.route.navigate(['/dashboard-index/' + id])

  }

  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 1000) {
      this.marginLeft = 0;
      this.fixedIWdth = 550;
    }
    else if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#groupedChartYAxis > *").remove();
    d3.selectAll("#chart > *").remove();

    d3.selectAll("#groupedChartYAxis2 > *").remove();
    d3.selectAll("#chart2 > *").remove();

    this.curveChart()
    this.linecurveChart()
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.ZoneListData = []
          this.filteredZoneListData = null;
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }


}
