import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-insurance-files',
  templateUrl: './insurance-files.component.html',
  styleUrls: ['./insurance-files.component.css']
})
export class InsuranceFilesComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }
  insuranceId: any;
  insuranceDetails: any
  insuranceFiles: any
  type: any
  fileName
  addFilePermission=false
  removeFiles=false
  saveNotify=false
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {

    //this.type = this.route.url.split("/")[2]
    this.insuranceId = this.route.url.split("/")[2]
    this.apiService.startLoader()
    this.apiService.getCall('insurances/view/' + this.insuranceId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.insuranceDetails = result['data']
      this.apiService.stopLoader();
      this.getInsurenceFileList();
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Add Files" && permission[i]['actionStatus'] == 1){
        this.addFilePermission=true
      }
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Remove File" && permission[i]['actionStatus'] == 1){
        this.removeFiles=true
      }
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Notification Setting" && permission[i]['actionStatus'] == 1){
        this.saveNotify=true
      }
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  selectedFiler = { business: null, property: null, date: null, insuranceProvider: null }
  clear() {
    this.selectedFiler = { business: null, property: null, date: null, insuranceProvider: null }
    this.filterSelect = false;
    this.getInsurenceFileList();
  }


  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }

  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.selectedFiler.business.id, {}).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  deleteFile(id) {
    this.apiService.startLoader()
    this.apiService.postCall('insuranceFiles/delete/' + id, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.getInsurenceFileList();
      this.apiService.stopLoader()
    })
  }


  uploadedFiles;
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name
    }
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  getFileName(name) {
    // console.log(name.split("/"))
    if (name){ 
      //name = name.split("/")[4]
      if(name.split("/")[4]){
        return name.split("/")[4];
      }
      return name.split("/")[3];
    }
  }
  uploadFile() {
    if(!this.uploadedFiles){
      return;
    }
    let testData: FormData = new FormData();
    testData.append('insuranceId', this.insuranceId);
    testData.append('authId', this.apiService.userId);
    testData.append('fileName', this.uploadedFiles);

    // console.log(testData)
    this.apiService.startLoader()
    this.apiService.uploadCall('insuranceFiles/add', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      this.uploadedFiles = [];
      this.fileName = '';
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getInsurenceFileList();
    })
  }

  getInsurenceFileList() {
    this.apiService.startLoader();
    var busineId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      busineId = this.selectedFiler.business.id
    }
    let body = {
      pageNo: this.pageNo,
      limit: this.pageSize,
      type: this.type,
      sortBy: this.sortSelected,
      sortType: this.sort,
      businessId: busineId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('insuranceFiles/index/' + this.insuranceId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.insuranceFiles=[]
      if (result['success']) {
        this.insuranceFiles = result['data']
      }
      this.apiService.stopLoader()
      this.setPaginationArray(result)
      

    })
  }
  message = '';


  paginationArray = []

  pageSize = 10;
  pageNo: any = 1

  activePage:any=1
  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      // console.log("Active Page :", d)
      this.getInsurenceFileList()
    }
  }
  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)
    return array
  }
  totalnoOfPages;
  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }


  sortSelected: any = 'insuranceId'
  sort: any = 'desc'

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getInsurenceFileList()
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.pageNo = 1;
    this.getInsurenceFileList();
  }
  getDate(d)  {
    let pattern = 'YYYY-MM-DDTHH:mm:ss'
    return moment(d ,pattern).utc(false)
  }
  getFilterStatus() {
    // console.log(JSON.stringify(this.selectedFiler));
    let length = 0;
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.date != null) length = length + 1
    if (this.selectedFiler.insuranceProvider != null) length = length + 1

    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`

    if (length == 1 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${1 - length} Filter Selected)`
  }

  navigateToDetails(d) {
    this.route.navigate(['/insurance-details/' + this.type + '/' + d.insuranceId])
  }
  assetList = []

  openDropdown(d) {
    if ($(".stama" + d).css('display') == 'none') {
      $(".stama" + d).css("display", "block");
    } else {
      $(".stama" + d).css("display", "none");
    }
  }

  deactiveSelected() { }
  dublicateSelected() { }
  selectAllAssets

  filterSelect = false;
}
