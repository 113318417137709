import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css']
})
export class DepartmentListComponent implements OnInit {

  constructor(public route: Router,public router: Router, public apiService: ApiService, public storedData: StoredData) { }

  addPermission=false
  editPermission=false
  changeStatusPermission=false
  ngOnInit(): void {
    var departmentListPagination = localStorage.getItem('departmentListPagination');
    if(departmentListPagination){
      this.activePage = parseInt(departmentListPagination);
    }
    if(this.router.url.split("/")[2]){
      this.tabSelected = this.router.url.split("/")[2];
      this.jumpToPage=1;
      this.selectAll=false;
      this.getDepartmentList();
    }else{
      this.getDepartmentList();
    }
    //this.getDepartmentListArchived()
    this.getBusinessList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.addPermission=true
      }
      if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
      if(permission[i]['subModuleName'] == "Departments" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
    }
  }

  businessId=this.apiService.buisnessId
  
  selectedFiler = { name: null,businessId:null,status:null }
  sortSelected: any = 'id'
  sort: any = 'desc'

  getDepartmentList() {
    
    this.selectedDepartment =[]
    this.apiService.startLoader()
    if(this.apiService.roleId==1){
      if(this.selectedFiler.businessId){
        this.businessId =this.selectedFiler.businessId.id;
      }else{
        this.businessId =0;
      }
      
    }else if(this.apiService.roleId==2){
      this.businessId =this.apiService.buisnessId;
    }

    let isActive;
    if(this.tabSelected=='active'){
      isActive=1;
    }else{
      isActive=0;
    }
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      name: this.selectedFiler.name,
      isActive:isActive,
      // businessId:this.businessId,
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.departmentList=[]
    this.apiService.postCall('types/index/' + this.businessId, {}, body).subscribe((result: any[]) => {
      this.departmentList = result['data']
      this.setPaginationArray(result)
      this.apiService.stopLoader()
      // console.log(this.departmentList);
    })
  }
  statusList=[{id:1,name:"Active"},{id:0,name:"Deactive"}]
  locationFilter=false
  statusFilter=false
  tabSelected= 'active'
  message='';
  changeStatus(id,flag) {
    if(flag){
      flag=1;
    }else{
      flag=0;
    }
    let obj = {
      id:id,
      isActive:flag,
      authId:this.apiService.authId
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('types/changeStatus', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.getDepartmentList();
      this.selectAll=false
      if(flag ==0)this.message = "Department deactivated successfully";
      else this.message = "Department activated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })

  }


  paginationArray = []

  pageSize = 10;
  pageSizeArchived = 10;
  pageNo: any = 1
  
  departmentList = []
  selectedAssets = []


  activePage:any=1
  totalnoOfPages;
  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  goBack(){
    this.route.navigate(['/setup'])
  }

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('departmentListPagination',d);
      // console.log("Active Page :", d)
      this.getDepartmentList()
    }
  }

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getDepartmentList()
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.getDepartmentList()
  }

  clear(){
    this.selectedFiler = { name: null,businessId:null,status:null }
    this.locationFilter=false
    this.getDepartmentList()
  }

  
  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)
    return array
  }
  getFilterStatus() {
    //// console.log(JSON.stringify(this.selectedFiler))
    let length = JSON.stringify(this.selectedFiler).split(null).length - 1

    if (length == 3 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${3 - length} Filter Selected)`
  }

  closeAll(){

  }
  assetList = []
  statusSelection:any;
  openDropdown(d){
    if('status'+d != this.statusSelection ){
      this.statusSelection = 'status'+d
    }else{
      this.statusSelection = undefined
    }

   // console.log(this.statusSelection)

    // if($(".stama"+d).css('display') == 'none'){
    //   $(".stama"+d).css("display","block");
    // }else{
    //   $(".stama"+d).css("display","none");
    // }
  }

  deactiveSelected() {
    for(let i=0;i<this.selectedDepartment.length;i++){
      let obj = {
        id:this.selectedDepartment[i],
        //isArchive:1,
        isActive:0,
        authId:this.apiService.authId
      }
      // console.log(obj)
      this.apiService.startLoader()
      this.apiService.postCall('types/changeStatus', {}, obj).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.selectAll=false
        this.message = "Department deactivated successfully";
        if(this.message && this.selectedDepartment.length-1 == i){
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          this.getDepartmentList();
        }
      })
    }
   }
  dublicateSelected() { 
    for(let i=0;i<this.selectedDepartment.length;i++){
      let obj = {
        id:this.selectedDepartment[i],
        //isArchive:0,
        isActive:1,
        authId:this.apiService.authId
      }
      // console.log(obj)
      this.apiService.startLoader()
      this.apiService.postCall('types/changeStatus', {}, obj).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.selectAll=false
        this.message = "Department activated successfully";
        if(this.message && this.selectedDepartment.length-1 == i){
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          this.getDepartmentList();
        }
      })
    }
  }

  selectAll=false
  selectedDepartment=[]
  selectAllAssets(ev){
    this.selectedDepartment = []

    if (ev.target.checked) {
      this.selectAll=true
      this.departmentList.map(x => x.selected = true)
      for (let department of this.departmentList) {
        this.selectedDepartment.push(department.id)
        // this.selectedDepartmentDetailsList.push(asset)
      }
    }
    else {
      this.selectAll=false
      this.departmentList.map(x => x.selected = false)
      this.selectedDepartment = []
      // this.selectedDepartmentDetailsList = []
    }
    // console.log(this.selectedDepartment)
    
  }

  selectAsset(ev,department){
    this.selectAll=false
    if (ev.target.checked) {
      this.selectedDepartment.push(department.id)
    }
    else {
      this.selectedDepartment.splice(this.selectedDepartment.findIndex(x => x == department.id), 1)
    }
  }
  changePage() {
    this.getDepartmentList()
  }
  filterSelect = false;

  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  showFilter=false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.showFilter = false
      this.locationFilter=false
    }
    if(btn.id =="buttonClose"){
    }else if(btn.id !="buttonClose"){
      this.statusSelection = undefined
    }
    
  }
}
