import { Component,HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-ticket-view-new',
  templateUrl: './ticket-view-new.component.html',
  styleUrls: ['./ticket-view-new.component.css']
})
export class TicketViewNewComponent implements OnInit {
  addTicektForm: FormGroup;
  searchPromise2 =null;
  constructor(public _location: Location,public route: Router,public router:Router ,private fb: FormBuilder,public apiService:ApiService,public storedData:StoredData ,) { 
  
  
    router.events.subscribe((val) => {
      clearTimeout(this.searchPromise2);
      this.searchPromise2 = setTimeout(() => {
        this.ngOnInit()
      } ,500)
     });
    
  }
  
  ticketId
  editPermissions=false
  forwardPermissions=false
  showAddMenu=false
  ngOnInit(): void {
  
    this.addTicektForm = this.fb.group({
      forwardoutletName:[null],
      forwardDepartment:[null],
      forwarUser:[null],
      
      forwardDescription:[],
      forwardPriority:[],
      forwarStatus:[]
    })
    this.ticketId = this.router.url.split("/")[2]
    this.getTicektDetails()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==2 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "Update" && permission[i]['actionStatus'] == 1){
        this.editPermissions=true
      }
      if(permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "Forward Ticket" && permission[i]['actionStatus'] == 1){
        this.forwardPermissions=true
      }
    }
  }

  getDisabledUser(id){
    // console.log(this.forwardUserList)
    if(this.forwardUserList[0] ){
      let idx = this.forwardUserList.findIndex(x=> x.id == id)
      if(idx != -1) return true
      else return false
      }
  }
ticketDetails:any
assetList=[]
statusList=[]
propertyList=[]
departmentList=[]
ticketHistory=[]
getOtherDetails(){

  
  this.apiService.postCall('asset-managements/index/'+this.apiService.buisnessId,{} , {pageNo:1, limit:200} ).subscribe((result: any[])=>{
    // console.log(result);
    
    // this.assetList=[]
    this.assetList= result['data']['data'];
    // console.log(this.assetList)
    this.statusList = result["statusList"];

  })

  
  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    // "outletId":outletId,
  }
 
  this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} ,obj  ).subscribe((result: any[])=>{
    this.propertyList = result['data']
    // console.log(result)
    this.departmentList = result['departments']
  })

  
  let obj1 ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    "outletId":this.ticketDetails.outletId,
  }
 
  this.apiService.postCall('users/user-list/'+this.apiService.buisnessId,{}, obj1  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    
  // // console.log(jsonToBeUsed)
 this.userList = result['data']
},
error =>{
this.apiService.stopLoader()
});
}

assignList=[]
selectUser2(event){
  this.assignList.push(event.target.value)
}
getName(id){
  var ids = this.userList.filter(item => item.id==id);
  if(ids.length>0){
    return ids[0]?.fullName
  }
}
removeUser2(d){
  let idx = this.assignList.findIndex(x=> x == d)
  this.assignList.splice(idx,1)
}

partdDetails=[]
costDetails=[]
editAddPermission =true
  getTicektDetails(){
    if(this.ticketId){
    this.apiService.startLoader()
    this.apiService.getCall('tickets/view/'+this.ticketId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.ticketDetails=result['data']
      if(this.ticketDetails.status == 'Closed'){
        //this.editAddPermission =false
      }
      this.partdDetails = result['additionalPartsTicketsData']
      //this.partdDetails.map(x=>x.costs =0)
      this.costDetails = result['additionalCostsTicketsData']
      if(this.ticketDetails.assetManagementId !=0){
        this.getAssetDetails(this.ticketDetails.assetManagementId)
      }
      this.ticketDetails.departmentId= parseInt(this.ticketDetails.departmentId)
      // console.log(this.ticketDetails.attach2 == null)
      if(this.ticketDetails.attach1 == null) this.ticketDetails.attach1 =  undefined
      if(this.ticketDetails.attach2 == null) this.ticketDetails.attach2  = undefined
      if(this.ticketDetails.attach3 == null) this.ticketDetails.attach3  = undefined

      // console.log(this.ticketDetails)
      this.ticketHistory=result['ticketHistory']
      this.addTicektForm.patchValue({
        forwardoutletName : this.ticketDetails.outletId,
        forwardDepartment : this.ticketDetails.departmentId,
        
        forwardDescription:this.ticketDetails.description,
        forwardPriority : this.ticketDetails.priorityId,
        forwarStatus: this.ticketDetails.status
      })
      if(this.ticketDetails.status=="Closed"){
        this.ticketDetails.status="Resolved"
      }
      this.getOtherDetails() 
      var d=[]
      this.assignList=[]
      d = this.ticketDetails.userId.split(',');
      // console.log(d)
      for(var j=0;j<d.length;j++){
        if(d[j]){
          this.assignList.push(d[j]);
        }
      }

  })
}
}
getTotalAdditionalCost(){
  let sum =0
  for(let d of this.costDetails) sum = sum + d.costs

  return sum
}


getTotaPartsCost(){
  let sum =0
  for(let d of this.partdDetails) sum = sum + d.costs

  return sum
}

getTotaPartsCostQuantity(){
  let sum =0
  for(let d of this.partdDetails) sum = sum + d.quantity

  return sum
}


viewPartDetails(d){
  this.route.navigate(['/parts-details/'+d.partId])
}
changeStatus(status){
  this.ticketDetails.status=status;
  this.update();
}
userList=[]
message:any;
update(){
  let body={
    // outletId:this.ticketDetails.outletId,

departmentId:this.ticketDetails.departmentId,
businessId:this.apiService.buisnessId,
// userId:this.ticketDetails.userId,
// subject:this.ticketDetails.subject,
// description:this.ticketDetails.description,
status:this.ticketDetails.status,
priorityId:this.ticketDetails.priorityId,
// attach1:this.ticketDetails.attach1,

assetManagementId:this.ticketDetails.assetManagementId,
updatedBy:this.apiService.userId,

  }
  // console.log(body)
  this.apiService.startLoader()
  this.apiService.postCall('tickets/edit/'+this.ticketId,{}, body  ).subscribe((result: any[])=>{
    this.propertyList = result['data']
    this.apiService.stopLoader()
    // console.log(result)
    this.message = result["msg"];
     
    this.getTicektDetails();
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
     
  })
}
getAssetName(id){
  if(id)return this.assetList.find(x=> x.assetId == id).assetName
  else return ""
}

assetDetails
getAssetDetails(id){
  this.apiService.getCall('asset-managements/view/'+id,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    this.apiService.stopLoader()
    this.assetDetails = result['assetDetails'];
    // this.asseAmcDetails = result['amcDetails'];
  })
}


forwardUserList=[]
selectUser(){
  this.forwardUserList.push(this.addTicektForm.value.forwarUser)

  this.addTicektForm.patchValue({forwarUser : null})
}
removeUser(d){
  let idx = this.forwardUserList.findIndex(x=> x == d)

  this.forwardUserList.splice(idx,1)
}

getFileName(name){
  if(name) return name.split("/")[6];
 }
 getPng(data){
  //  // console.log(data.search('.png') || data.search('.jpg'))
   if(data){
  let idx  = data.search('.jpg') 
  let idx1 = data.search('.png')

  if(idx != -1 || idx1 != -1) return 1
   }
  else return -1
 } 

forwardTicket(){
  this.fileName = null
  this.fileName2 =null;
  this.fileName3 =null;
  this.vedioUploadFileName=null
  let userArry:any=[]
  for(let d of this.forwardUserList)userArry.push(d.id)


// console.log(this.apiService)
  let testData:FormData = new FormData();
  testData.append('ticketId',this.ticketId); 
  testData.append('forwardTo',userArry);
  testData.append('priorityId',this.addTicektForm.value.forwardPriority); 
  testData.append('forwardDepartment',this.addTicektForm.value.forwardDepartment); 
  testData.append('messageType',"3");
  testData.append('createdBy',this.apiService.userId);
  testData.append('statusId',this.ticketDetails.status);
  testData.append("forwardTo",userArry),
  testData.append("ccEmail","prakash.b@aquil.net"),
  testData.append("bccEmail","prakash.b@aquil.net"),
  testData.append("message",this.addTicektForm.value.forwardDescription);
  
  if(this.uploadedFiles)testData.append('attach1', this.uploadedFiles);
    if(this.uploadedFiles2)testData.append('attach2', this.uploadedFiles2);
    if(this.uploadedFiles3)testData.append('attach3', this.uploadedFiles3);
    // if(this.vedioUploadFile)testData.append('video', this.vedioUploadFile);
  
  testData.append('assetManagementId',this.ticketDetails.assetManagementId);
  
  if(this.vedioUploadFile){
    this.apiService.startLoader()
    let testData1:FormData = new FormData();
    testData1.append('file',this.vedioUploadFile);
    testData1.append('filename',this.vedioUploadFileName);

  this.apiService.postCall('tickets_videos/index.php/saveUploadedFile',{}, testData1 ).subscribe((result: any[])=>{
    if(result['success']){
      let d=result['success'];
      testData.append("video",d.video);

  this.apiService.startLoader()
  this.apiService.postCall('tickets/ticketReply',{}, testData  ).subscribe((result: any[])=>{
    this.propertyList = result['data']
    this.apiService.stopLoader()
    // console.log(result)
    this.uploadedFiles= undefined;
    this.message = result["msg"];
    
       
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
    this.getTicektDetails()
  })

}
})
  }
  else{


    this.apiService.startLoader()
    this.apiService.postCall('tickets/ticketReply',{}, testData  ).subscribe((result: any[])=>{
      this.propertyList = result['data']
      this.apiService.stopLoader()
      // console.log(result)
      this.uploadedFiles= undefined;
      this.message = result["msg"];
      
         
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getTicektDetails()
    })
  }
}

goToLink(url: string){
  window.open(url, "_blank");
}
videoFlag=false
fileChangeWarranty(event){
  if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "application/pdf" || event.target.files[0].type == "video/mp4" || event.target.files[0].type == "video/quicktime" || event.target.files[0].type == "video/x-ms-wmv"  ){
  let fileList: FileList = event.target.files;
      if(fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles =file
          this.fileName = file.name
         
  
        }
      }
      else{

        this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose jpg/video" ;
       
         
        (<HTMLInputElement>document.getElementById('openError')).click();
      }

}

uploadWarranty(){
  let testData:FormData = new FormData();
  if(this.videoFlag){
    this.apiService.startLoader()
    testData.append('file',this.uploadedFiles);
    testData.append('filename',this.fileName);
    this.apiService.postCall('tickets_videos/index.php/saveUploadedFile',{}, testData ).subscribe((result: any[])=>{
      let testData:FormData = new FormData();
      if(result['success']){
        // console.log("in");
        let d=result['success'];
        testData.append('video',d.video);
        this.apiService.startLoader()
        this.apiService.postCall('tickets/edit/'+this.ticketId,{}, testData ).subscribe((result: any[])=>{
          this.propertyList = result['data']
          this.apiService.stopLoader()
          // console.log(result)
          this.message = result["msg"];
            
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
          this.getTicektDetails()
          
        })
      }else{
        this.apiService.stopLoader()
      }
      });
    }else{

      if(this.ticketDetails.attach1 == null)testData.append('attach1',this.uploadedFiles );
      else if(this.ticketDetails.attach2 == null)testData.append('attach2',this.uploadedFiles );
      else if(this.ticketDetails.attach3 == null)testData.append('attach3',this.uploadedFiles );
      
      this.apiService.startLoader()
      this.apiService.postCall('tickets/edit/'+this.ticketId,{}, testData ).subscribe((result: any[])=>{
        this.propertyList = result['data']
        this.apiService.stopLoader()
        // console.log(result)
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.getTicektDetails()
      })
    }
}
ticketMessage=""
replyTicket(){
  let testData:FormData = new FormData();
  testData.append('ticketId',this.ticketId); 
  testData.append('messageType',"1");
  testData.append('createdBy',this.apiService.userId); 
  testData.append('statusId',this.ticketDetails.status); 
  testData.append('forwardTo',this.ticketDetails.userId);
  testData.append('ccEmail',"prakash.b@aquil.net");
  testData.append('bccEmail',"prakash.b@aquil.net");
  testData.append('message',this.ticketMessage);
  if(this.uploadedFiles)testData.append('attach1', this.uploadedFiles);
  if(this.uploadedFiles2)testData.append('attach2', this.uploadedFiles2);
  if(this.uploadedFiles3)testData.append('attach3', this.uploadedFiles3);
  // if(this.vedioUploadFile)testData.append('video', this.vedioUploadFile);
  
  testData.append('assetManagementId',this.ticketDetails.assetManagementId);


  this.apiService.startLoader()
  this.apiService.postCall('tickets/ticketReply',{}, testData  ).subscribe((result: any[])=>{
    this.propertyList = result['data']
    this.apiService.stopLoader()
    // console.log(result)
    this.ticketMessage ="";

    this.message = result["msg"];
    this.uploadedFiles=undefined;
    this.uploadedFiles2=undefined;
    this.uploadedFiles3=undefined;
    this.fileName=undefined;
    this.fileName2=undefined;
    this.fileName3=undefined;
    this.vedioUploadFileName=undefined;
    if(document.getElementById('firstId')){
      document.getElementById('firstId').remove();
    }
    if(document.getElementById('firstId2')){
      document.getElementById('firstId2').remove();
    }
    if(document.getElementById('firstId3')){
      document.getElementById('firstId3').remove();
    }
    
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
    this.getTicektDetails()
  })

}

// uploadedFiles:any;
//   fileName:any
//   fileChange(event) {
//     let fileList: FileList = event.target.files;
//     if(fileList.length > 0) {
//         let file: File = fileList[0];
//         // console.log(file)
//         this.uploadedFiles =file
//         this.fileName = file.name
       

//       }
// }



uploadedFiles:any;
uploadedFiles2:any;
uploadedFiles3:any;
fileName:any
fileName2:any;
fileName3:any;

vedioUploadFile:any;
vedioUploadFileName:any
fileChangeVedio(event){
  // console.log(event.target.files[0].type  )
  if( event.target.files[0].type == "video/mp4" || event.target.files[0].type == "video/quicktime" || event.target.files[0].type == "video/x-ms-wmv"  ){
    let fileList: FileList = event.target.files;
    if(fileList.length > 0 ) {
        let file: File = fileList[0];
        // console.log(file)
        this.vedioUploadFile =file
        this.vedioUploadFileName = file.name

        
       

      }
  }
  else{

    this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose Vedio" ;
   
     
    (<HTMLInputElement>document.getElementById('openError')).click();
  }
}
fileChange(event) {
  // console.log(event.target.files[0].type)
  
  // .png, .jpg, .jpeg ,  .mp4, .mov, .wmv,.pdf 
  if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg"  ){
   var field =( <HTMLInputElement>document.getElementById('before_image'));


// var image =<HTMLInputElement> document.getElementById('before_image');
// image.src = URL.createObjectURL(event.target.files[0]);
// console.log(this.fileName)
if(!this.fileName){
  var span = document.getElementById('images');
if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg"){
var img = document.createElement('img');
  img.src =  URL.createObjectURL(event.target.files[0]);
  img.id =  "firstId";
  if(document.getElementById('firstId')){
    document.getElementById('firstId').remove();
  }
  span.appendChild(img);
  
  // span.removeChild(img);
  img.style.height="88px"
  // console.log(event)
}
  let fileList: FileList = event.target.files;
  if(fileList.length > 0 ) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles =file
      this.fileName = file.name

      
     

    }
  }
  else if(!this.fileName2){
    var span = document.getElementById('images2');
    if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg"){
      var img = document.createElement('img');
          img.src =  URL.createObjectURL(event.target.files[0]);
          img.id =  "firstId2";
          if(document.getElementById('firstId2')){
            document.getElementById('firstId2').remove();
          }
          span.appendChild(img);
          
          // span.removeChild(img);
          img.style.height="88px"
          // console.log(event)
        }
          let fileList: FileList = event.target.files;
          if(fileList.length > 0 ) {
              let file: File = fileList[0];
              // console.log(file)
              this.uploadedFiles2 =file
              this.fileName2 = file.name
      
              
             
      
            }
  }
  else if(!this.fileName3){
    var span = document.getElementById('images3');
    if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg"){
      var img = document.createElement('img');
          img.src =  URL.createObjectURL(event.target.files[0]);
          img.id =  "firstId3";
          if(document.getElementById('firstId3')){
            document.getElementById('firstId3').remove();
          }
          span.appendChild(img);
          
          // span.removeChild(img);
          img.style.height="88px"
          // console.log(event)
        }
          let fileList: FileList = event.target.files;
          if(fileList.length > 0 ) {
              let file: File = fileList[0];
              // console.log(file)
              this.uploadedFiles3 =file
              this.fileName3 = file.name
      
              
             
      
            }
  }
}
  else{

    this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose jpg" ;
   
     
    (<HTMLInputElement>document.getElementById('openError')).click();
  }
}

removeFile1(){

  if(document.getElementById('firstId')){
    document.getElementById('firstId').remove();
  }
  this.uploadedFiles =undefined
  this.fileName = undefined
}

removeFile2(){

  if(document.getElementById('firstId2')){
    document.getElementById('firstId2').remove();
  }
  this.uploadedFiles2 =undefined
        this.fileName2 = undefined
}
removeFile3(){

  if(document.getElementById('firstId3')){
    document.getElementById('firstId3').remove();
  }
  this.uploadedFiles3 =undefined
        this.fileName3 = undefined
}

removeVedio(){
  this.vedioUploadFile =undefined
  this.vedioUploadFileName = null
}
upload(){

  let testData:FormData = new FormData();

  testData.append('outletId',this.ticketDetails.outletId);
  testData.append('departmentId',this.ticketDetails.departmentId);
  testData.append('businessId',this.apiService.buisnessId);
  testData.append('userId',this.ticketDetails.userId);
  testData.append('subject',this.ticketDetails.subject);
  testData.append('description',this.ticketDetails.description);
  testData.append('status',this.ticketDetails.status);
  testData.append('priorityId', this.addTicektForm.value.priorityId);
  testData.append('priorityId',this.ticketDetails.priorityId);
  testData.append('attach1',this.ticketDetails.attach1? this.ticketDetails.attach1 :this.uploadedFiles );
  // testData.append('attach2',this.ticketDetails.attach2? this.ticketDetails.attach2 :this.uploadedFiles );
  // testData.append('attach3',this.ticketDetails.attach3? this.ticketDetails.attach3 :this.uploadedFiles );
  testData.append('assetManagementId',this.ticketDetails.assetManagementId);
  testData.append('updatedBy',this.apiService.userId);
  
  

  // // console.log(body)
  this.apiService.startLoader()
  this.apiService.postCall('tickets/edit/'+this.ticketId,{}, testData  ).subscribe((result: any[])=>{
    this.propertyList = result['data']
    this.apiService.stopLoader()
    // console.log(result)
    this.message = result["msg"];
     
       
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
     this.getTicektDetails()
  })
}

assignPriority(id){
  this.addTicektForm.patchValue({
    forwardPriority : id
  })
  
}

validate(){
  let valid = true
if(!this.forwardUserList[0]) valid = false;

// // console.log(valid)
  return valid
}
priorityList=[{id:1 , name:'High'} , {id:2 , name:'Medium'} , {id:3 , name:'Low'}] 
// getValidation(){
//   // console.log(this.uploadedFiles)
//   return this.addTicektForm.valid 
// }

removeTimeZone(date){
  date = date.replace('+05:30','')
  date = date.replace('T',' ')
  return date
}

getNewDate(){
  return new Date()
}

@HostListener('click', ['$event.target'])
onClick(btn) {
  // console.log('button', btn.id, 'number of clicks:');
  if (btn.id != 'custom') {
    this.showAddMenu = false
  }
}

}


// assignPriority(id){
//   this.addTicektForm.patchValue({
//     priorityId : id,
//   })
// }

// const priorityList=[{id:1 , name:'High'} , {id:2 , name:'Medium'} , {id:3 , name:'Low'}] 

// }






