import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-department-add-new',
  templateUrl: './department-add-new.component.html',
  styleUrls: ['./department-add-new.component.css']
})
export class DepartmentAddNewComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  departmentForm: FormGroup

  name
  description
  isActive=1;

  ngOnInit(): void {
    this.getBusinessList();
    this.name = this.name,
    this.description = this.description,
    this.isActive = 1,//this.isActive
    document.body.style.overflow = 'hidden';
      this.departmentForm = this.fb.group({
        name:[null , Validators.required],
        description:[null],
        isActive:[null],
        businessId:[null, Validators.required],
      });
      if(this.apiService.roleId==2){
        this.departmentForm.patchValue({businessId:this.apiService.buisnessId})
      }
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  selectUnit=false;
  selectIsActivedata(data) {
    this.isActive = data
    this.selectUnit = !this.selectUnit
    this.departmentForm.patchValue({unit:data})
  }

  submit() {
    // console.log(this.departmentForm.value)
    let businessId=0;
    if(this.apiService.roleId==1){
      businessId=this.departmentForm.value.businessId
    }else if(this.apiService.roleId==2){
      businessId= this.apiService.buisnessId;
    }
    let obj = {
      name: this.departmentForm.value.name,
      description: this.departmentForm.value.description,
      businessId: businessId,
      isActive: 1,//this.departmentForm.value.isActive,
      authId: this.apiService.authId
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('types/add', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if(result["success"] == true){
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess1')).click();
      }
    })

  }
  backClicked() {
    this.route.navigate(['/department-list'])
  }

  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  message
  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]


  getValidation() {
    return this.departmentForm.valid
  }
}
