import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.css']
})
export class TemplateViewComponent implements OnInit {
  propertyList: any = [];
  departmentList: any = [];

  constructor(public apiService: ApiService, public _location: Location, public router: Router) { }

  tabSelected='tab2'
  tempId
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.tempId = this.router.url.split("/")[2];
    this.getTemplateCategoryList();
    this.getMultichoiceresponcetypeLis();
    if(this.tempId){
      this.getTemplateIndex();
    }
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  //If Edit
  editTemplate
  subTemplates
  getTemplateIndex(){
    this.apiService.startLoader()
    let body = {
      pageNo: 1,
      limit: 1,
      tempId:this.tempId
    }
    this.apiService.postCall('inspection-templates/getTemplateIndex', {}, body).subscribe((result: any[]) => {
      if(result['data'][0]){
        this.editTemplate = result['data'][0]
        this.templateName = this.editTemplate.templateName;
        this.templateCategoryId=this.editTemplate.templateCategoryId;
        this.department = this.editTemplate.departmentId;//{id:this.editTemplate.departmentId,name:this.editTemplate.departmentName};
        // console.log(this.department)
        if(this.editTemplate.subTemplets){
          this.sections=[]
          this.subTemplates = this.editTemplate.subTemplets;

          
          let unique = this.subTemplates.map(item => item.categoryName)
          .filter((value, index, self) => self.indexOf(value) === index)

          for(let uni=0;uni<unique.length;uni++){
            this.sections.push({ sectionName: unique[uni], task: [{ id:null,parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null }] })
            for(let i=0;i<this.subTemplates.length;i++){
              if(unique[uni] == this.subTemplates[i].categoryName){

                // let respo ="";
                // let responseType=0;
                // let responceGroup=null;

                // if(this.subTemplates[i].isSignature == 1){
                //   respo = 'Digital Signature';
                //   responseType=1;
                // }else if(this.subTemplates[i].isSignature == 3){
                //   respo = 'Text Field';
                //   responseType=3;
                // }else{
                //   respo ='Multiple Choice';
                //   responseType=2;
                //   responceGroup=this.subTemplates[i].statusId;
                // }
                //task.push({parking : d2.name , part:null , isDescription:d2.description?true : false , description:d2.description ,responseType: parseInt(d2.isSignature) , response: d2.isSignature == "1" ? 'Digital Signature' :d2.isSignature == "3" ? 'Text Field': d.statusName ,responseSeelcted:'Mutiple Choice', responceGroup : d2.statusId, searchResponceType:null ,buttons:myArray }) this.subTemplates[i].statusName
                let myArray
                if(this.subTemplates[i].statusName) {
                  myArray=  this.subTemplates[i].statusName.split('/')
                }
                this.sections[uni].task.push({id:this.subTemplates[i].id, parking: this.subTemplates[i].name, part: null, isDescription: this.subTemplates[i].description ? true : false, description: this.subTemplates[i].description, responseType: parseInt(this.subTemplates[i].isSignature), response: this.subTemplates[i].isSignature == "1" ? 'Digital Signature' :this.subTemplates[i].isSignature == "3" ? 'Text Field': 'Mutiple Choice' , responseSeelcted:'Mutiple Choice', responceGroup: this.subTemplates[i].statusId, searchResponceType: null })
              }
            }
            this.sections[uni].task.splice(0, 1)
          }
        }
        // console.log(this.sections);
      }
      this.apiService.stopLoader()
    })
  }


getDepartMentName(id){
  let name="";
  for(let i=0;i<this.departmentList.length;i++){
    if (this.departmentList[i].id == id) {
      name= this.departmentList[i].name;
    }
  }
  return name;
}
getCategoryName(id){
  let name="";
  for(let i=0;i<this.templetCategoryList.length;i++){
    if (this.templetCategoryList[i].id == id) {
      name= this.templetCategoryList[i].categoryName;
    }
  }
  return name;
}

checkDescription($event,i,j){

}



  templateName;
  department = null;
  property = null;
  templateCategoryId = null;
  sections = [{ sectionName: null, task: [{ id:null, parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null }] }]
  

  templetCategoryList = []
  getTemplateCategoryList() {
    this.apiService.startLoader()
    this.apiService.getCall('template-categories/index/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      if (result['success'] == true) {
        this.templetCategoryList = result['templateCategoryList'];
        this.departmentList = result['departments']
      }
    })
  }

  backClicked() {
    this._location.back()
  }

  addBNewSection() {
    this.sections.push({ sectionName: null, task: [{ id:null,parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null }] })
  }

  addNewTask(i) {
    this.sections[i].task.push({ id:null,parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null })
  }

  removeTask(i, j) {
    this.sections[i].task.splice(j, 1)
  }

  removeSection(i) {
    this.sections.splice(i, 1)
  }


  responceType
  responcetypePopup
  openResponceTypePopup(name) {


    if (this.responcetypePopup == name) {
      this.responceType = null;
      this.responcetypePopup = null;
    }
    else this.responcetypePopup = name
    // console.log(this.responcetypePopup, name)
  }
  getFreqtext(d) {
    // // console.log(d)
    if (d) {
      if (d.scheduleFrequency != "Custom") return d.scheduleFrequency
      else return d.frequencyNo + " " + d.frequencyUnit
    }
  }

  selectUnit: boolean;
  selectFreq: boolean;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.selectUnit = false
      this.selectFreq = false
      this.responceType = null
      this.responcetypePopup = null
    }
    // console.log(this.responcetypePopup,)
  }

  openResponceType(name) {
    // console.log(this.responceType, name)
    if (this.responceType == name) this.responceType = null
    else this.responceType = name
    // var modal = document.getElementById(name);

    // if(modal.style.display == 'none')modal.style.display = "block";
    // else modal.style.display = "none";
  }
  selectResponceTypr(i, j, data) {
    // console.log(i, data)
    // responseType: 0,
    //   response: data.name,
    //   responceGroup : data.id
    this.sections[i].task[j].response = data.name
    this.sections[i].task[j].responseType = 2
    this.sections[i].task[j].responceGroup = data.id

    let myArray = this.sections[i].task[j].response.split('/')
    // console.log(myArray)
    this.sections[i].task[j]['buttons'] = myArray
    // // console.log(this.pmTemplateForm.value)
  }
  selectResponse(data, i, j) {
    // console.log("Data :", data);
    this.sections[i].task[j].response = data
    this.sections[i].task[j].responseType = data == 'Text Field' ? 3 : 1,
      this.sections[i].task[j].responceGroup = null

  }

  statusListresponceType = []
  statusListresponceTypeFilter = []

  onItemSelect(item: any) {
    // console.log(item);

  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  getMultichoiceresponcetypeLis() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;

    })
  }


  searchResponceType: any;

  filterLocation(ev, i, j) {
    // console.log(ev)

    this.statusListresponceType = this.statusListresponceTypeFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.sections[i].task[j].searchResponceType.toLowerCase())
      }
    }
    )

  }

  message
  saveChecklist() {
    // console.log(this.department)
    let sectionObj = []
    for (let d of this.sections) {
      let task = []

      for (let d2 of d.task){
        let des =d2.description;
        if(!d2.isDescription){
          des=""
        }
        task.push({ id:d2.id,itemName: d2.parking, description: des, statusId: d2.responceGroup, isSignature: d2.response == 'Digital Signature' ? 1 : d2.response == 'Text Field' ? 3 : 2 })
      }
      sectionObj.push({ sectionName: d.sectionName, item: task })
    }
    let body = {
      templateName: this.templateName,
      departmentId:this.department,
      businessId: this.apiService.buisnessId,
      templateCategoryId: this.templateCategoryId,
      section: sectionObj,

    }
    let url = 'inspection-templates/add';  //ForAdd
    if(this.tempId){ //For Edit
      url = 'inspection-templates/edit/'+this.tempId;
    }
    // console.log(body);
    this.apiService.startLoader()
    this.apiService.postCall(url, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    }
    )
  }
}

