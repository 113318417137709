import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-warranty',
  templateUrl: './edit-warranty.component.html',
  styleUrls: ['./edit-warranty.component.css']
})
export class EditWarrantyComponent implements OnInit {
  @Input() assetDetails: any;
  @Input() amcType:any;
  @Input() type: any;
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  constructor(public router:Router ,private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService, ) { }
  assetDetailsForm = this.fb.group({
    vendorName:[null , Validators.required],
    contactNo:[null  ,Validators.required] ,
    alternetNo:[null],
    email:[null , Validators.required],
    amcType:1,
    startDate:[null , Validators.required],
    endDate:[null , Validators.required],
    RenewalDate:[null],
    scheduledService:[null],
    amcId:[null]
  });
  assetId:any;

  ngOnInit(): void {

    // console.log(this.assetDetails)
    // console.log(this.router.url.split("/")[2])
    this.assetId = this.router.url.split("/")[2]
  
  }

  scheduleService=["1","2","3","4"]
  // amcType=[1]

  ngOnChanges() {
    // console.log(this.type)
    // console.log(this.assetDetails);
    if (this.assetDetails) {
      this.assetDetailsForm.patchValue({
        assetId: this.assetDetails.assetId,
        vendorName:this.assetDetails.warraantyVendorName,
      contactNo:this.assetDetails.warraantyContactNo,
      alternetNo:this.assetDetails.warraantyAlternateNo,
      email:this.assetDetails.warraantyEmail,
      // amcType:this.assetDetails.amcType,
      startDate:new Date(this.assetDetails.warraantyStartDateEdit?this.assetDetails.warraantyStartDateEdit:''),
      endDate:new Date(this.assetDetails.warraantyEndDateEdit?this.assetDetails.warraantyEndDateEdit:''),
      RenewalDate:new Date(this.assetDetails.warraantyRenewalDateEdit?this.assetDetails.warraantyRenewalDateEdit:''),
      scheduledService:this.assetDetails.warraantySheduledServices,
        // amcId : this.assetDetails.id,
      })
    }

    // console.log(this.assetDetailsForm.value)
  }
  message

  submit(){

    // console.log(this.assetDetailsForm);
    let amcObj={
      
      warraantyVendorName:this.assetDetailsForm.value.vendorName,
      warraantyContactNo:parseInt(this.assetDetailsForm.value.contactNo),
      warraantyAlternateNo:parseInt(this.assetDetailsForm.value.alternetNo),
      warraantyEmail:this.assetDetailsForm.value.email,
      // amcType:this.assetDetailsForm.value.amcType,
      warraantyStartDate:moment(this.assetDetailsForm.value.startDate).format('YYYY-MM-DD'),
      warraantyEndDate:moment(this.assetDetailsForm.value.endDate).format('YYYY-MM-DD'),
      // warraantyRenewalDate:moment(this.assetDetailsForm.value.RenewalDate).format('YYYY-MM-DD'),
      warraantySheduledServices:this.assetDetailsForm.value.scheduledService,
      authId:this.apiService.userId,
      

    

  }

 
  this.apiService.startLoader()
  this.apiService.postCall('asset-managements/editWarrantyDetails/'+this.assetDetails.assetId, {}, amcObj).subscribe((result: any[]) => {
    // console.log(result);
    
  this.apiService.stopLoader()
  document.getElementById('dismissPopUp223').click()
  this.updateData.emit()
  })


  }
  public noOfService = [/[0-9]/, /\d/, /\d/, /\d/]
  public contactmask= [ /[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

  getValidation(){
    // // console.log(this.assetDetailsForm.value)
    return (this.assetDetailsForm.valid && !this.validateDate(this.assetDetailsForm.value.startDate,this.assetDetailsForm.value.endDate) && this.validateEmail(this.assetDetailsForm.value.email)
    &&   !this.getMobileValidation(this.assetDetailsForm.value.contactNo)
    &&   !this.getMobileValidation(this.assetDetailsForm.value.alternetNo)
    )
  }

  getMobileValidation(data){
    if(data){
      // // console.log(data)
    return data.search('_') != -1
    }
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }


  numberOnlyWarranty(){
    var str = new String(this.assetDetailsForm.value.scheduledService);
    // console.log(str.length)
    if(str.length>4) str = str.substring(0,4)

    this.assetDetailsForm.patchValue({
      scheduledService : str
    })
  }

  validateDate(satrtDate , endDate){
    // // console.log(satrtDate , endDate)
if(satrtDate && endDate){
   if(endDate <= satrtDate) return true
   else return false
  }
} 
}
