import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-inspection-schedule-add',
  templateUrl: './inspection-schedule-add.component.html',
  styleUrls: ['./inspection-schedule-add.component.css']
})
export class InspectionScheduleAddComponent implements OnInit {

  constructor(public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  scheduleAdd: FormGroup
  tselection = true;
  lockChecklistFlag=false;
  lockMin = 0;
  todayDate: any = new Date();

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  step = 1
  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    document.body.style.overflow = 'hidden';

    this.scheduleAdd = this.fb.group({
      property: [null, Validators.required],
      name: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null],
      frequency: [null, Validators.required],
      user: [null],
      isReminder: false,
      qrAllow: false,
      inspection: [null, Validators.required],
      randomMandetoryPhoto: false,
      frequencyNumber: [null],
      frequencyUnit: [null],
      assignee: [null],
      reminderMeasure: [null],
      reminderTime: [null],
      groupName: [null],
      isEndless: false,
      weekFlag: false,
      isMultiple: false,
      location: [null, Validators.required],
      multipleTimes: this.fb.array([]),
      multipleTimesHoliday: this.fb.array([]),
      saturdayWeekFlag: false,
      sundayWeekFlag: false,
      checklistStartTime: [null],
      checklistEndTime: [null],
      checklistScheduleUserId: [null],
      checklistLockFlag: [null],
      // inspection:[null],
      // isReminder:[null],
    });

    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 29 && businessFeatures[i]['permission_allow'] == 1) {
          this.lockChecklistFlag = true;
          this.lockMin = businessFeatures[i]['lock_min'];
        }
      }
    }
    // this.getInspectionMainList()
    this.getPropertyList()
    // this.getUserList()

    this.scheduleId = this.route.url.split("/")[2]
    if (this.scheduleId) this.getInspectionData()
  }
  scheduleId

  removeChecklists = []
  removeChecklistsHoliday = []
  removeAm() {
    // console.log(this.scheduleAdd.value.reminderTime)
    // console.log(this.scheduleAdd.value.reminderTime.replace("am", ""))
    if (this.scheduleAdd.value.reminderTime) {
      this.scheduleAdd.value.reminderTime = this.scheduleAdd.value.reminderTime.replace("am", "");
      this.scheduleAdd.value.reminderTime = this.scheduleAdd.value.reminderTime.replace("pm", "");
    }
  }
  removeAmcheCklistStartTime() {
    if (this.scheduleAdd.value.checklistStartTime) {
      this.scheduleAdd.value.checklistStartTime = this.scheduleAdd.value.checklistStartTime.replace("am", "");
      this.scheduleAdd.value.checklistStartTime = this.scheduleAdd.value.checklistStartTime.replace("pm", "");
    }
  }
  removeAmcheChecklistEndTime() {
    if (this.scheduleAdd.value.checklistEndTime) {
      this.scheduleAdd.value.checklistEndTime = this.scheduleAdd.value.checklistEndTime.replace("am", "");
      this.scheduleAdd.value.checklistEndTime = this.scheduleAdd.value.checklistEndTime.replace("pm", "");
    }
  }
  removeAm1(index) {
    if (this.scheduleAdd.value.multipleTimes[index]) {
      this.scheduleAdd.value.multipleTimes[index]['name'] = this.scheduleAdd.value.multipleTimes[index]['name'].replace("am", "");
      this.scheduleAdd.value.multipleTimes[index]['name'] = this.scheduleAdd.value.multipleTimes[index]['name'].replace("pm", "");
    }
  }
  removeAm1Holiday(index) {
    if (this.scheduleAdd.value.multipleTimesHoliday[index]) {
      this.scheduleAdd.value.multipleTimesHoliday[index]['name'] = this.scheduleAdd.value.multipleTimesHoliday[index]['name'];
      this.scheduleAdd.value.multipleTimeHoliday[index]['name'] = this.scheduleAdd.value.multipleTimesHoliday[index]['name'];
    }
  }

  scheduleDetails: any;
  checklistStartTime1
  checklistEndTime1
  getInspectionData() {
    this.apiService.getCall('schedules/view/' + this.scheduleId, {}).subscribe((result: any[]) => {
      // console.log(result);
      let reminder = new Date();
      if (result['data'].reminderTime) {
        reminder.setHours(result['data'].reminderTime.split(':')[0])
        reminder.setMinutes(result['data'].reminderTime.split(':')[1])
        reminder.setSeconds(result['data'].reminderTime.split(':')[2])
      }
      if (result['data'].checklistStartTime) {
        this.checklistStartTime1 = new Date();
        this.checklistStartTime1.setHours(result['data'].checklistStartTime.split(':')[0])
        this.checklistStartTime1.setMinutes(result['data'].checklistStartTime.split(':')[1])
        this.checklistStartTime1.setSeconds(result['data'].checklistStartTime.split(':')[2])
      }
      if (result['data'].checklistEndTime) {
        this.checklistEndTime1 = new Date();
        this.checklistEndTime1.setHours(result['data'].checklistEndTime.split(':')[0])
        this.checklistEndTime1.setMinutes(result['data'].checklistEndTime.split(':')[1])
        this.checklistEndTime1.setSeconds(result['data'].checklistEndTime.split(':')[2])
      }
      this.scheduleDetails = result['data'];
      this.scheduleAdd.patchValue({
        property: result['data'].outletId,
        name: result['data'].scheduleName,
        startDate: result['data'].startDate ? new Date(result['data'].startDate) : null,
        endDate: result['data'].endDate ? new Date(result['data'].endDate) : null,
        frequency: [null],
        isEndless: result['data'].isInfinite == "No" || result['data'].isInfinite == 0 ? false : true,
        isReminder: result['data'].isReminder == "No" ? false : true,
        weekFlag: result['data'].weekFlag == "0" ? false : true,
        saturdayWeekFlag: result['data'].saturdayWeekFlag == "0" ? false : true,
        sundayWeekFlag: result['data'].sundayWeekFlag == "0" ? false : true,
        qrAllow: result['data'].qrAllow == "No" ? 0 : 1,
        inspection: JSON.stringify(result['data'].inspectionId),
        location: result['data'].floorPlanId,
        frequencyNumber: result['data'].repeatDays,
        reminderMeasure: result['data'].remMeasure,
        randomMandetoryPhoto: result['data'].randomPhoto,
        checklistStartTime: this.checklistStartTime1,
        checklistEndTime: this.checklistEndTime1,
        checklistScheduleUserIdArry: parseInt(result['data'].checklistScheduleUserId),
        checklistLockFlag: result['data'].checklistLockFlag,
        assignee: parseInt(result['data'].assignTo),
        reminderTime: reminder,
        groupName: result['data'].groupName,
        isMultiple: result['data'].isMultiple == "No" ? false : true
      })
      this.getUserListWithArray(result);
      this.getLocationList();
      this.setCustonType(result);
      this.setSchdeuleType(result);
      this.getInspectionMainList();
      this.selectReminderMeasure();
      // this.locationList = result['data']
      if (result['data'].isMultipleTimes != null) {
        if (result['data'].isMultipleTimes[0]) {
          for (let d of result['data'].isMultipleTimes) this.addDefaultChecklist(d)
        }
      }
      if (result['data'].isMultipleTimesHoliday != null) {
        if (result['data'].isMultipleTimesHoliday[0]) {
          for (let d of result['data'].isMultipleTimesHoliday) this.addDefaultChecklistHoliday(d)
        }
      }
      // console.log(this.scheduleAdd.value)

    })
  }
  getUserListWithArray(result1) {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.scheduleAdd.value.property,
      "onlyUsers": 1
    }

    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.scheduleAdd.value.property, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.userList = result['data'];
      this.filetrUserList = this.userList;
      this.userListNotify = result['data'];
      if (result1['data'].assignTo) {
        const myArray = result1['data'].assignTo.split(",");
        // console.log(myArray)
        for (let d of myArray) {
          let idx = this.userList.findIndex(x => x.id == d)
          if (idx != -1) this.userArry.push(this.userList[idx])
        }
      }
      if (result1['data'].checklistScheduleUserId) {
        const myArray1 = result1['data'].checklistScheduleUserId.split(",");
        for (let d of myArray1) {
          let idx = this.userListNotify.findIndex(x => x.id == d)
          if (idx != -1) this.checklistScheduleUserIdArry.push(this.userListNotify[idx])
        }
      }
    })
  }



  setCustonType(result) {
    if (result['data'].customType == 1) this.scheduleAdd.patchValue({ frequencyUnit: 'Day' })
    else if (result['data'].customType == 2) this.scheduleAdd.patchValue({ frequencyUnit: 'Week' })
    else if (result['data'].customType == 3) this.scheduleAdd.patchValue({ frequencyUnit: 'Month' })
    else if (result['data'].customType == 4) this.scheduleAdd.patchValue({ frequencyUnit: 'Year' })
    else this.scheduleAdd.patchValue({ frequencyUnit: null })
  }
  setSchdeuleType(result) {
    // console.log(result['data'].scheduleType)
    if (result['data'].scheduleType == 1) this.scheduleAdd.patchValue({ frequency: 'Once' })
    else if (result['data'].scheduleType == 2) this.scheduleAdd.patchValue({ frequency: 'Daily' })
    else if (result['data'].scheduleType == 3) this.scheduleAdd.patchValue({ frequency: 'Weekly' })
    else if (result['data'].scheduleType == 4) this.scheduleAdd.patchValue({ frequency: 'Monthly' })
    else if (result['data'].scheduleType == 6) this.scheduleAdd.patchValue({ frequency: 'Custom' })
    else if (result['data'].scheduleType == 7) this.scheduleAdd.patchValue({ frequency: 'Quarterly' })
    else this.scheduleAdd.patchValue({ frequency: null })
  }



  timeList = []
  selectReminderMeasure() {
    this.timeList = []
    if (this.scheduleAdd.value.reminderMeasure == 'Minutes') {
      for (let i = 0; i <= 59; i++) this.timeList.push(i)
    }
    else if (this.scheduleAdd.value.reminderMeasure == 'Hours') {
      for (let i = 0; i <= 23; i++) this.timeList.push(i)
    }
    else if (this.scheduleAdd.value.reminderMeasure == 'Days') {
      for (let i = 0; i <= 31; i++) this.timeList.push(i)
    }
  }

  userArry = []
  removeUser(i) {
    this.userArry.splice(i, 1)
  }
  removeUserNotifyUser(i) {
    this.checklistScheduleUserIdArry.splice(i, 1)
  }
  showAssigneeLimitFlag = false
  selectUser() {
    if (this.userArry.length < 32) {
      this.userArry.push(this.scheduleAdd.value.assignee)
      this.scheduleAdd.patchValue({ assignee: null })
      this.showAssigneeLimitFlag = false
    } else {
      this.showAssigneeLimitFlag = true
      this.scheduleAdd.patchValue({ assignee: null })
    }
  }
  checklistScheduleUserIdArry = []
  selectUserNotify() {
    if (this.checklistScheduleUserIdArry.length < 32) {
      this.checklistScheduleUserIdArry.push(this.scheduleAdd.value.checklistScheduleUserId)
      this.scheduleAdd.patchValue({ checklistScheduleUserId: null })
    } else {
      this.scheduleAdd.patchValue({ checklistScheduleUserId: null })
    }
  }
  getDisabledUser(d) {
    if (this.userArry[0]) {
      // console.log(d, this.userArry)
      let idx = this.userArry.findIndex(x => x.id == d.id)
      if (idx != -1) return true
      else return false
    }
    else return false
  }
  getDisabledUserNotify(d) {
    if (this.checklistScheduleUserIdArry[0]) {
      let idx = this.checklistScheduleUserIdArry.findIndex(x => x.id == d.id)
      if (idx != -1) return true
      else return false
    }
    else return false
  }

  userList
  userListNotify
  filetrUserList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.scheduleAdd.value.property,
      "onlyUsers": 1
    }
    this.userArry = []
    this.checklistScheduleUserIdArry = [];
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.scheduleAdd.value.property, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.userListNotify = result['data']
      this.userList = result['data']
      this.filetrUserList = this.userList

    })
  }
  get formChecklistArr() {
    return this.scheduleAdd.get('multipleTimes') as FormArray;
  }
  get formChecklistArrHoliday() {
    return this.scheduleAdd.get('multipleTimesHoliday') as FormArray;
  }
  removeChecklist(i) {
    // console.log(this.formChecklistArr)
    if (this.formChecklistArr.at(i).get('id')) {
      this.removeChecklists.push(this.formChecklistArr.at(i).get('id')['value']);
    }
    //if(i != 0)
    this.formChecklistArr.removeAt(i)
  }
  removeChecklistHoliday(i) {
    // console.log(this.formChecklistArr)
    if (this.formChecklistArrHoliday.at(i).get('id')) {
      this.removeChecklistsHoliday.push(this.formChecklistArrHoliday.at(i).get('id')['value']);
    }
    //if(i != 0)
    this.formChecklistArrHoliday.removeAt(i)
  }
  initChecklist(data: any | null) {
    if (data != undefined) {
      // console.log(data.name);
      return this.fb.group({
        name: [data['name'], Validators.required]
      });
    } else {
      return this.fb.group({
        checklistId: [null],
        name: [null, Validators.required]
      });
    }
  }
  initChecklistHoliday(data: any | null) {
    if (data != undefined) {
      // console.log(data.name);
      return this.fb.group({
        name: [data['name'], Validators.required]
      });
    } else {
      return this.fb.group({
        checklistId: [null],
        name: [null, Validators.required]
      });
    }
  }
  initChecklistDefalut(d) {
    let scheduleTime = new Date()
    if (d.name) {
      scheduleTime.setHours(d.name.split(':')[0])
      scheduleTime.setMinutes(d.name.split(':')[1])
      scheduleTime.setSeconds(d.name.split(':')[2])
    }
    return this.fb.group({
      name: scheduleTime
    });
  }
  initChecklistDefalutHoliday(d) {
    if (d.name) {
      let scheduleTimeHoliday = new Date(d.name);
      return this.fb.group({
        name: scheduleTimeHoliday
      });
    }
  }
  addMoreChecklistHoliday(data?): void {
    this.formChecklistArrHoliday.push(this.initChecklistHoliday(data));
  }
  addMoreChecklist(data?): void {
    this.formChecklistArr.push(this.initChecklist(data));
  }
  addDefaultChecklistHoliday(d): void {
    this.formChecklistArrHoliday.push(this.initChecklistDefalutHoliday(d));
    console.log(this.formChecklistArrHoliday);
  }
  addDefaultChecklist(d): void {
    this.formChecklistArr.push(this.initChecklistDefalut(d));
  }
  propertyList = []
  filetrPropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.propertyList = result['data']

      this.filetrPropertyList = this.propertyList

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // if(idx != -1){
      // if(this.apiService.roleId == '3')  {

      //   this.scheduleAdd.patchValue({
      //     property : this.propertyList[idx].id
      //   })
      //   //  this.selectedFiler.
      //    // this.getPropertyList()
      //    this.getUserList() ; this.getLocationList() ;this.getInspectionMainList()
      //  }
      //  else{

      //   this.scheduleAdd.patchValue({
      //     property : this.propertyList[idx].id
      //   })
      //   //  this.selectedFiler.
      //    // this.getPropertyList()
      //    this.getUserList() ; this.getLocationList() ;this.getInspectionMainList()
      //  }
      // }
    })
  }
  getOutletName() {
    if (this.propertyList) {
      let idx = this.propertyList.find(x => x.id == this.scheduleAdd.value?.property);
      return idx?.outletName;
    }
  }
  getInspectionName() {
    if (this.inspectionList) {
      let idx = this.inspectionList.find(x => x.id == this.scheduleAdd.value?.inspection);
      return idx?.inspectionName;
    }
  }
  getLocation() {
    if (this.locationList) {
      let idx = this.locationList.find(x => x.id == this.scheduleAdd.value?.location);
      return idx?.name;
    }
  }
  getTime() {
    return moment(this.scheduleAdd.value?.reminderTime).format('HH:mm:ss');
  }
  inspectionList
  getInspectionMainList() {
    this.apiService.startLoader()

    this.apiService.postCall('schedules/getOutletInspectionsChecklist/' + this.scheduleAdd.value.property, {}, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      var jsonToBeUsed = [];

      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['inspectionName'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      // console.log(jsonToBeUsed)

      this.inspectionList = jsonToBeUsed
      // this.setPaginationArray(result)
      this.apiService.stopLoader()
    }
    )

  }


  selectFreq = false;
  selectCustom = false;
  unitOfMeasure = false;


  locationList = []
  getLocationList() {
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/locations/location-list/:businessId/:outletId
    this.apiService.getCall('locations/location-list/' + this.apiService.buisnessId + '/' + this.scheduleAdd.value.property, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']


    })
  }


  getFrequencyName() {
    if (this.scheduleAdd.value.frequency == null) return 'Set a Frequency'
    else if (this.scheduleAdd.value.frequency == 'Custom') return 'Open For ' + (this.scheduleAdd.value.frequencyNumber ? this.scheduleAdd.value.frequencyNumber : '') + " " + (this.scheduleAdd.value.frequencyUnit ? this.scheduleAdd.value.frequencyUnit : '')
    else return this.scheduleAdd.value.frequency
  }




  selectUnit = false;
  selectFreqdata(data) {
    this.selectFreq = !this.selectFreq
    this.scheduleAdd.patchValue({ frequency: data })
  }
  selectCustomData(data) {
    this.scheduleAdd.patchValue({ frequency: data })
  }


  selectFreqUnitdata(data) {
    this.unitOfMeasure = !this.unitOfMeasure
    this.scheduleAdd.patchValue({ frequencyUnit: data })
  }


  // getFrequencyName() {
  //   if (this.scheduleAdd.value.frequency == null) return 'Set a frequency'
  //   else if (this.scheduleAdd.value.frequency == 'Custom') return 'Every  '+ (this.scheduleAdd.value.frequencyNumber ? this.scheduleAdd.value.frequencyNumber : '') + " " + (this.scheduleAdd.value.frequencyUnit ? this.scheduleAdd.value.frequencyUnit : '')
  //   else return this.scheduleAdd.value.frequency
  // }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.selectUnit = false
      this.selectFreq = false
      // this.responceType = null
      // this.responcetypePopup = null
    }
  }

  validate() {
    if (!this.validateDate(this.scheduleAdd.value.startDate, this.scheduleAdd.value.endDate)) this.submit()

  }

  submit() {

    let assegen = []
    let checklistScheduleUserIdArryId = []
    let isMultipleTimess = []
    let isMultipleTimessHoliday = []
    for (let d of this.userArry) assegen.push(d.id)
    for (let d of this.checklistScheduleUserIdArry) checklistScheduleUserIdArryId.push(d.id)
    for (let d of this.scheduleAdd.value.multipleTimes) { isMultipleTimess.push({ name: d.name ? moment(d.name).format('HH:mm:ss') : "" }) }
    for (let d of this.scheduleAdd.value.multipleTimesHoliday) { isMultipleTimessHoliday.push({ name: d.name ? moment(d.name).format('YYYY-MM-DD') : "" }) }
    let body = {
      outletId: this.scheduleAdd.value.property,
      inspectionId: this.scheduleAdd.value.inspection,
      floorPlanId: this.scheduleAdd.value.location,
      userId: this.apiService.userId,
      scheduleName: this.scheduleAdd.value.name,
      inFinite: this.scheduleAdd.value.isEndless ? 1 : 0,
      weekFlag: this.scheduleAdd.value.weekFlag ? 1 : 0,
      saturdayWeekFlag: this.scheduleAdd.value.saturdayWeekFlag ? 1 : 0,
      sundayWeekFlag: this.scheduleAdd.value.sundayWeekFlag ? 1 : 0,
      continueDays: 1,
      date: this.scheduleAdd.value.startDate,
      statusId: 98,
      isReminder: this.scheduleAdd.value.isReminder ? 1 : 0,
      qrAllow: this.scheduleAdd.value.qrAllow ? 1 : 0,
      displayInTime: 0,

      groupName: this.scheduleAdd.value.groupName,
      isMultiple: this.scheduleAdd.value.isMultiple ? 1 : 0,
      repeatDays: this.scheduleAdd.value.frequencyNumber,
      startDate: this.scheduleAdd.value.startDate ? moment(this.scheduleAdd.value.startDate).format('YYYY-MM-DD') : "",
      endDate: !this.scheduleAdd.value.isEndless ? moment(this.scheduleAdd.value.endDate).format('YYYY-MM-DD') : "",
      reminderTime: this.scheduleAdd.value.reminderTime ? moment(this.scheduleAdd.value.reminderTime).format('HH:mm:ss') : "",
      remMeasure: this.scheduleAdd.value.reminderMeasure,
      assignTo: assegen,//[this.scheduleAdd.value.assignee],
      randomPhoto: this.scheduleAdd.value.randomMandetoryPhoto ? 1 : 0,
      scheduleType: this.getSchdeuleType(),
      customType: this.getCustonType(), //days -1, week -2,month -3, year-4 ,
      removeChecklist: this.removeChecklists,
      isMultipleTimes: isMultipleTimess,
      isMultipleTimessHoliday: isMultipleTimessHoliday,
      checklistStartTime: this.scheduleAdd.value.checklistStartTime ? moment(this.scheduleAdd.value.checklistStartTime).format('HH:mm:ss') : "",
      checklistEndTime: this.scheduleAdd.value.checklistEndTime ? moment(this.scheduleAdd.value.checklistEndTime).format('HH:mm:ss') : "",
      checklistScheduleUserId: checklistScheduleUserIdArryId,
      checklistLockFlag: this.scheduleAdd.value.checklistLockFlag ? 1 : 0,
    }
    // console.log(body)
    this.apiService.startLoader()


    if (!this.scheduleId) {
      this.apiService.postCall('schedules/add', {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()

        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      )
    }
    else {

      this.apiService.postCall('schedules/edit/' + this.scheduleId, {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()

        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      )





    }



  }

  getCustonType() {
    if (this.scheduleAdd.value.frequencyUnit == 'Day') return 1
    else if (this.scheduleAdd.value.frequencyUnit == 'Week') return 2
    else if (this.scheduleAdd.value.frequencyUnit == 'Month') return 3
    else if (this.scheduleAdd.value.frequencyUnit == 'Year') return 4
  }
  getSchdeuleType() {
    if (this.scheduleAdd.value.frequency == 'Once') return 1
    else if (this.scheduleAdd.value.frequency == 'Daily') return 2
    else if (this.scheduleAdd.value.frequency == 'Weekly') return 3
    else if (this.scheduleAdd.value.frequency == 'Monthly') return 4
    else if (this.scheduleAdd.value.frequency == 'Custom') return 6
    else if (this.scheduleAdd.value.frequency == 'Quarterly') return 7
  }
  backClicked() {
    this.route.navigate(['/inspection-schedule-list'])
  }

  message: any;
  frquencyList = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }, { id: "5" }, { id: "6" }, { id: "7" }]

  validateDate(satrtDate, endDate) {
    // console.log(satrtDate && endDate);
    if (satrtDate && endDate) {
      if (endDate < satrtDate) return true
      else return false
    }
    else return false

  }

  validateFor() {
    if (this.userArry.length == 0) {
      return false
    }
    // console.log(this.scheduleAdd)
    if (!this.scheduleAdd.value.isEndless && !this.scheduleAdd.value.isReminder) {
      return this.scheduleAdd.value.property &&
        this.scheduleAdd.value.name &&
        this.scheduleAdd.value.startDate &&
        this.scheduleAdd.value.frequency &&
        this.scheduleAdd.value.inspection &&
        //this.scheduleAdd.value.assignee &&
        this.scheduleAdd.value.location &&
        this.scheduleAdd.value.endDate && !this.validateDate(this.scheduleAdd.value.startDate, this.scheduleAdd.value.endDate)
    }
    else if (!this.scheduleAdd.value.isEndless && this.scheduleAdd.value.isReminder) {
      return this.scheduleAdd.value.property &&
        this.scheduleAdd.value.name &&
        this.scheduleAdd.value.startDate &&
        this.scheduleAdd.value.frequency &&
        this.scheduleAdd.value.inspection &&
        //this.scheduleAdd.value.assignee &&
        this.scheduleAdd.value.location && this.scheduleAdd.value.endDate && !this.validateDate(this.scheduleAdd.value.startDate, this.scheduleAdd.value.endDate) && this.scheduleAdd.value.reminderTime
    } else if (this.scheduleAdd.value.isReminder && this.scheduleAdd.value.isEndless) {
      return this.scheduleAdd.value.property &&
        this.scheduleAdd.value.name &&
        this.scheduleAdd.value.startDate &&
        this.scheduleAdd.value.frequency &&
        this.scheduleAdd.value.inspection &&
        //this.scheduleAdd.value.assignee &&
        this.scheduleAdd.value.location && this.scheduleAdd.value.reminderTime
    } else {
      return this.scheduleAdd.valid

    }
  }









}
