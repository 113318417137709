import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import { StoredData } from '../common/services/storedData.service';
@Component({
  selector: 'app-location-checklists',
  templateUrl: './location-checklist-add.component.html',
  styleUrls: ['./location-checklist-add.component.css']
})
export class LocationChecklistsComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public formBuilder: FormBuilder,
    public storedData: StoredData
  ) { }

  selectedUsers = [];
  roleId: any
  userList = [];
  dropdownSettings: IDropdownSettings;



  tabSelected = "step1"

  backClicked() {
    this._location.back();
  }

  changetabSelected() {
    this.tabSelected = "step2"
    // console.log(this.tabSelected)
  }

  id
  outletId
  locationId
  locationChecklistForm: FormGroup;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    this.locationChecklistForm = this.formBuilder.group({
      businessId: [null, Validators.required],
      authId: this.apiService.authId,
      locationId: [null, Validators.required],
      outletId: [null, Validators.required],
      inspectionId: [null],
    })
    this.locationChecklistForm.patchValue({ businessId: this.apiService.buisnessId, authId: this.apiService.authId })
    this.outletId = this.router.url.split("/")[2];
    this.locationId = this.router.url.split("/")[3];
    if (this.outletId && this.locationId) {
      this.locationChecklistForm.patchValue({ locationId: this.locationId, outletId: this.outletId })
      this.getInspectionMainList();
    }

    this.getPreselected();
  }

  getPreselected() {
    this.apiService.startLoader()
    this.apiService.postCall('LocationChecklists/index/' + this.locationId, {}, {}).subscribe((result: any[]) => {
      if (result['success']) {
        for (var i = 0; i < result['data'].length; i++) {
          this.userArry.push(result['data'][i].inspectionId);
        }
      }
      // console.log(this.userArry)
      this.apiService.stopLoader();
    });
    this.apiService.stopLoader();
  }

  inspectionList = []
  getInspectionMainList() {
    this.apiService.startLoader()
    this.apiService.postCall('schedules/getOutletInspectionsChecklist/' + this.outletId, {}, {}).subscribe((result: any[]) => {
      for (var type in result['data']) {
        this.inspectionList.push({ id: type, name: result['data'][type] })
      }
      this.apiService.stopLoader();
    });
    this.apiService.stopLoader();
  }

  userArry = []
  removeUser(i) {
    this.userArry.splice(i, 1)
  }
  selectUser() {
    if (this.userArry.indexOf(this.locationChecklistForm.value.inspectionId) == -1) {
      this.userArry.push(this.locationChecklistForm.value.inspectionId)
      this.locationChecklistForm.patchValue({ inspectionId: null })
    } else {
      this.locationChecklistForm.patchValue({ inspectionId: null })
    }
  }


  message
  saveProperty() {
    let formData = this.locationChecklistForm.value;
    formData.inspectionId = this.userArry;
    // console.log("formData : ",formData);
    var url = "LocationChecklists/edit/" + this.locationId;

    this.apiService.startLoader()
    this.apiService.postCall(url, {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }

  getValidation() {
    // console.log(this.userArry,this.locationChecklistForm.value)
    if (this.userArry.length == 0) {
      return false
    }
    return this.locationChecklistForm.valid
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  next() {
    $("#next").click();
  }
  getUserName(id) {
    for (let i = 0; i < this.inspectionList.length; i++) {
      if (id == this.inspectionList[i].id) {
        return this.inspectionList[i].name;
      }
    }
  }

  goBack(): void {
    this._location.back();
  }

}
