import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
// import { SelectComponent ,OffClickDirective} from 'ng2-select';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public formBuilder: FormBuilder,
  ) { }
  saveData = false;
  message: any;
  businesId = null;
  businessList: any;
  selectedPropertys: any
  role: any
  selectedDepartments: any
  propertyList = [];
  roleList = [];
  userTypeList = []
  departmentList = [];
  departmentArray = [];
  errorFlag: boolean;
  shide
  hide
  userForm
  dropdownSettings: IDropdownSettings;
  dropdownSettings2: [];
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  public passwordMask = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/
  ngOnInit(): void {
    if (this.apiService.buisnessId == 71) {
      this.userForm = this.formBuilder.group({
        businessId: this.apiService.buisnessId,
        modifiedBy: this.apiService.authId,
        outletId: [null],
        options: [null],
        fullName: [null, [Validators.required, Validators.maxLength(40)]],
        email: [null, [Validators.email]],
        empCompanyCode: [null, [Validators.required]],
        mobile: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        password: [null, [Validators.required]],
        cpassword: [null, [Validators.required]],
        designation: [null, [Validators.maxLength(80)]],
        shipmentId: 0,
        roleId: [null, [Validators.required]],
        subUsers: this.formBuilder.array([this.initSubUsers()]),
        userType: [2], //,[Validators.required]
        userTypeMobile: [1], //,[Validators.required]
      })
    } else {
      this.userForm = this.formBuilder.group({
        businessId: this.apiService.buisnessId,
        modifiedBy: this.apiService.authId,
        outletId: [null],
        options: [null],
        fullName: [null, [Validators.required, Validators.maxLength(40)]],
        email: [null, [Validators.required, Validators.email]],
        empCompanyCode: [null],
        mobile: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        password: [null, [Validators.required]],
        cpassword: [null, [Validators.required]],
        designation: [null, [Validators.maxLength(80)]],
        shipmentId: 0,
        roleId: [null, [Validators.required]],
        subUsers: this.formBuilder.array([this.initSubUsers()]),
        userType: [2], //,[Validators.required]
        userTypeMobile: [1], //,[Validators.required]
      })
    }
    document.body.style.overflow = 'hidden';
    this.dropdownSettings = {
      singleSelection: false,
      "defaultOpen": false,
      idField: 'id',
      textField: 'outletName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      "enableCheckAll": true,
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };

    // this.dropdownSettings2 = {
    //   singleSelection: false,
    //   idField: 'id',
    //   textField: 'name',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 5,
    //   allowSearchFilter: false
    // }
    this.role = this.apiService.roleId;

    this.getBusinessList();
    this.getpropertyList();
    if (this.role == 2 || this.role == 3) {
      this.businesId = this.apiService.buisnessId;
    }
    this.resetForm();
    this.getShift();
  }

  private value: any = {};
  private _disabledV: string = '0';
  private disabled: boolean = false;
  private items: Array<string> = ['Amsterdam', 'Antwerp', 'Athens', 'Barcelona',
    'Berlin', 'Birmingham', 'Bradford', 'Bremen', 'Brussels', 'Bucharest',
    'Budapest', 'Cologne', 'Copenhagen', 'Dortmund', 'Dresden', 'Dublin',
    'Düsseldorf', 'Essen', 'Frankfurt', 'Genoa', 'Glasgow', 'Gothenburg',
    'Hamburg', 'Hannover', 'Helsinki', 'Kraków', 'Leeds', 'Leipzig', 'Lisbon',
    'London', 'Madrid', 'Manchester', 'Marseille', 'Milan', 'Munich', 'Málaga',
    'Naples', 'Palermo', 'Paris', 'Poznań', 'Prague', 'Riga', 'Rome',
    'Rotterdam', 'Seville', 'Sheffield', 'Sofia', 'Stockholm', 'Stuttgart',
    'The Hague', 'Turin', 'Valencia', 'Vienna', 'Vilnius', 'Warsaw', 'Wrocław',
    'Zagreb', 'Zaragoza', 'Łódź'];

  private get disabledV(): string {
    return this._disabledV;
  }

  private set disabledV(value: string) {
    this._disabledV = value;
    this.disabled = this._disabledV === '1';
  }

  private selected(value: any) {
    // console.log('Selected value is: ', value);
  }

  private removed(value: any) {
    // console.log('Removed value is: ', value);
  }

  private typed(value: any) {
    // console.log('New search input: ', value);
  }

  private refreshValue(value: any) {
    this.value = value;
  }
  getDisbaled(id, userArry) {
    let idx = userArry.findIndex(x => x == id)
    if (idx != -1) return true
  }
  onBlur() {
    this.userForm.patchValue({
      fullName: this.userForm.value.fullName != null ? this.userForm.value.fullName.trim() == "" ? null : this.userForm.value.fullName.trim() : null,
      email: this.userForm.value.email != null ? this.userForm.value.email.trim() == "" ? null : this.userForm.value.email.trim() : null,
      empCompanyCode: this.userForm.value.empCompanyCode != null ? this.userForm.value.empCompanyCode.trim() == "" ? null : this.userForm.value.empCompanyCode.trim() : null,
      mobile: this.userForm.value.mobile != null ? this.userForm.value.mobile.trim() == "" ? null : this.userForm.value.mobile.trim() : null,
      password: this.userForm.value.password != null ? this.userForm.value.password.trim() == "" ? null : this.userForm.value.password.trim() : null,
      cpassword: this.userForm.value.cpassword != null ? this.userForm.value.cpassword.trim() == "" ? null : this.userForm.value.cpassword.trim() : null,
    })
  }
  shiftMent = []
  getShift() {
    this.userArry = []
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "isActive": 1,
      "businessId": this.apiService.buisnessId,
      "page_no": 1,
      "limit": 100
    }
    // console.log(formData);
    this.apiService.startLoader()
    this.apiService.postCall('shipments/index', {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      this.shiftMent = result['data'];

    });
  }

  goBack(): void {
    this._location.back();
  }

  resetForm(): void {
    this.userArry = []
    this.departmentArray = []
    this.userForm.setControl('subUsers', this.formBuilder.array([this.initSubUsers()]));
    this.userForm.reset();
  }

  userTypeListMobile = []
  getuserType(e) {
    var type = 0;
    if (e.target.value == 3 || e.target.value == 2) {
      type = 2;
    } else if (e.target.value == 5) {
      type = 1;
    } else {
      this.userTypeList = []
    }
    this.userArry = []
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "isActive": 1,
      // "type":type,
      "businessId": this.userForm.value.businessId ? this.userForm.value.businessId : this.apiService.buisnessId,
    }
    // console.log(formData);
    this.apiService.startLoader()
    this.apiService.postCall('roles/index', {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      var da = result['data'];
      this.userTypeList = da.filter(item => item.type == 2);
      this.userTypeListMobile = da.filter(item => item.type == 1);

    });
  }

  getpropertyList(): void {
    this.userArry = []
    this.departmentArray = []
    this.propertyList = []
    let formData = {
      // businessId:this.apiService.role==1?this.businesId:this.apiService.buisnessId,
      // userId:this.apiService.role > 2?this.apiService.authId:'',
      // roleId:this.apiService.role > 2?this.apiService.role:'',
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.userForm.value.businessId ? this.userForm.value.businessId : this.apiService.buisnessId,
    }
    // console.log(formData);
    this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, formData).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.propertyList = result['data'];
      }
      if (this.apiService.roleId == 3) {
        result['roleList'].splice(0, 1)
        result['roleList'].splice(0, 1)
      } else if (this.apiService.roleId == 2) {
        result['roleList'].splice(0, 1)
      }
      this.roleList = result['roleList'];
      this.departmentList = result['departments'];
    });
  }

  getBusinessList(): void {
    // this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      // this.apiService.stopLoader()
      this.businessList = []
      this.businessList = result['data'];
    })
  }

  getDepartmentList() {
    let propertyId = [];
    for (let p of this.selectedPropertys) {
      propertyId.push(p.id);
    }
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.userForm.value.businessId ? this.userForm.value.businessId : this.apiService.buisnessId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.propertyList = result['data'];

      }
      if (this.apiService.roleId == 3) {
        result['roleList'].splice(0, 1)
        result['roleList'].splice(0, 1)
      } else if (this.apiService.roleId == 2) {
        result['roleList'].splice(0, 1)
      }
      this.roleList = result['roleList'];
      // console.log(this.roleList);
      this.departmentList = result['departments'];

    });
    // console.log("Properts",propertyId);
  }

  initSubUsers() {
    return this.formBuilder.group({
      fullName: [null, [Validators.maxLength(40)]],
      mobile: [null, [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      designa: [null, [Validators.maxLength(40)]],
    });
  }

  get formSubUsers() {
    return this.userForm.get('subUsers') as FormArray;
  }

  addMoreSubUser(): void {
    this.formSubUsers.push(this.initSubUsers());
  }
  removeMoreSubUser(i): void {
    this.formSubUsers.removeAt(i);
  }

  onItemSelect(item: any) {
    if (this.userArry.indexOf(item.id) == -1) {
      this.userArry.push(item.id)
      // this.userForm.patchValue({ outletId: null })
    } else {
      // this.userForm.patchValue({ outletId: null })
    }
  }
  onItemDeSelect(item: any) {
    //console.log('onItemDeSelect', item);
    let idx = this.userArry.findIndex(x => x == item.id)
    this.userArry.splice(idx, 1)
  }

  onSelectAll(items: any) {
    for (let i = 0; i < items.length; i++) {
      this.userArry.push(items[i].id);
    }
  }
  onUnSelectAll() {
    this.userArry = []
  }

  onDepartmentSelect(item: any) {
    // console.log(item);
  }

  onDepartmentSelectAll(items: any) {
    // console.log(items);
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  validPassword = false
  getValidation() {
    this.validPassword = false;
    if (this.userForm.value.password && !this.passwordMask.test(this.userForm.value.password)) {
      this.validPassword = true;
      return false;
    }
    if (this.userForm.value.roleId == 2) {
      this.userForm.patchValue({ userTypeMobile: 1 });
    } else if (this.userForm.value.roleId == 5) {
      this.userForm.patchValue({ userType: 2 });
    }
    if (this.userForm.value.roleId != 2 && this.userForm.value.roleId != 4 && this.userForm.value.roleId != 5 && this.userArry.length == 0) {
      return false;
    }
    if (this.userArry.length == 0 && this.userForm.value.roleId != 2) { //outlets
      return false;
    }
    if (this.departmentArray.length == 0 && this.userForm.value.roleId == 3) {
      return false;
    } else if (this.userArry.length == 0 && this.userForm.value.roleId == 4) {
      return false;
    } else if (this.departmentArray.length == 0 && this.userForm.value.roleId == 5) {
      return false;
    }
    if (this.userForm.value.password != this.userForm.value.cpassword) {
      return false;
    }
    else {
      return (this.userForm.valid);
    }

  }

  saveUser(): void {
    let formData = this.userForm.value;
    // let propertyId=[];
    // for(let p of this.selectedPropertys){
    //   propertyId.push(p.id);
    // }
    // let departmentId=[];
    // for(let d of this.selectedDepartments){
    //   departmentId.push(d.id);
    // }
    let outlet = [];
    for (let i = 0; i < this.userArry.length; i++) {
      outlet.push(this.userArry[i]);
    }
    let dept = [];
    for (let i = 0; i < this.departmentArray.length; i++) {
      dept.push(this.departmentArray[i]);
    }
    formData.outletId = outlet;
    formData.options = dept;
    formData.roleId = parseInt(formData.roleId);
    // console.log("Form Data : ",formData);
    this.apiService.startLoader();
    if (this.role == 2 || this.role == 3) {
      formData.businessId = this.apiService.buisnessId;
    }
    this.apiService.postCall('users/add', {}, formData).subscribe((result: any[]) => {
      // console.log("Data Saved :",result);
      this.apiService.stopLoader();
      if (result['success']) {
        this.saveData = result['success'];
        this.message = "User created successfully";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      else {
        this.saveData = result['success'];
        if (result['msg']['email']) {
          this.message = result['msg']['email'] ? "Email is already taken" : "Something went wrong";
        } else {
          this.message = result['msg'] ? result['msg'] : "Something went wrong";
        }
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
    });
  }

  getOutletName(id: any) {
    for (let i = 0; i < this.propertyList.length; i++) {
      if (id == this.propertyList[i].id) {
        return this.propertyList[i].outletName;
      }
    }
  }
  userArry = []
  removeOutlet(i: any) {
    this.userArry.splice(i, 1)
  }
  selectOutlet(d: any) {
    if (this.userArry.indexOf(this.userForm.value.outletId) == -1) {
      this.userArry.push(this.userForm.value.outletId)
      this.userForm.patchValue({ outletId: null })
    } else {
      this.userForm.patchValue({ outletId: null })
    }
  }

  getDepartmentName(id: any) {
    for (let i = 0; i < this.departmentList.length; i++) {
      if (id == this.departmentList[i].id) {
        return this.departmentList[i].name;
      }
    }
  }
  selectDepartments(d: any) {
    if (this.departmentArray.indexOf(this.userForm.value.options) == -1) {
      this.departmentArray.push(this.userForm.value.options)
      this.userForm.patchValue({ options: null })
    } else {
      this.userForm.patchValue({ options: null })
    }
  }
  removeDepartment(i: any) {
    this.departmentArray.splice(i, 1);
  }
}
