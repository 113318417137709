import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-inspection-schedule-list',
  templateUrl: './inspection-schedule-list.component.html',
  styleUrls: ['./inspection-schedule-list.component.css']
})
export class InspectionScheduleListComponent implements OnInit {

  scheduleList: any;

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }

  duplicatePermission = false
  printQrPermission = false
  scheduleAddPermission = false
  scheduleViewPermission = false
  changeStatusPermission = false
  ngOnInit(): void {

    var schedulePage = localStorage.getItem('schedulePage');
    if (schedulePage) {
      this.activePage = parseInt(schedulePage);
    }

    this.getInspectionScheduleList()
    this.getBusinessList()
    this.getLocationList()
    this.getUserListGeneric()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 1);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "Duplicate" && permission[i]['actionStatus'] == 1) {
        this.duplicatePermission = true
      }
      if (permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "Print QR Code" && permission[i]['actionStatus'] == 1) {
        this.printQrPermission = true
      }
      if (permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.scheduleAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.scheduleViewPermission = true
      }
      if (permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1) {
        this.changeStatusPermission = true
      }
    }
  }
  frequencyLists = [{ id: 1, name: 'Once' }, { id: 2, name: 'Daily' },{id:3,name:'Weekly'},{id:4,name:'Monthly'},{id:5,name:'Quarterly'},{id:6,name:'Custom'}];
  departmentList = []
  filterdepartmentList = []
  filterDepartmentSearch
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }

  navigateToDublicate() {
    this.storedData.selectedScheduleList = this.selectedAssetsDetailsList
    // console.log(this.storedData.selectedScheduleList)
    this.route.navigate(['/inspection-schedule-dublicate'])
  }

  sortSelected = 'id';
  sort = 'desc'

  sortData(data) {
    localStorage.removeItem("inspectionScheduleListFilter");
    localStorage.setItem("inspectionScheduleListFilter", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getInspectionScheduleList()
  }
  deactiveSelected() {
    this.apiService.startLoader()
    let body = {
      scheduleId: this.selectedAssets,
      isActive: 0,
    }
    this.apiService.postCall('schedules/deactivateSchedules', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      // this.scheduleList= result['data']
      this.apiService.stopLoader();
      this.message = "Inspection schedule deactivated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.selectedAssets = [];
      this.getInspectionScheduleList()
    })
  }

  deactivateSelectedItem(d) {
    this.apiService.startLoader()
    this.statusSelection = null;
    let body = {
      scheduleId: [d.id],
      isActive: 0,
    }
    this.apiService.postCall('schedules/deactivateSchedules', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      // this.scheduleList= result['data']
      this.apiService.stopLoader();
      this.message = "Schedule deactivated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getInspectionScheduleList();
    })
  }
  activateSelected(d) {
    this.apiService.startLoader()
    this.statusSelection = null;
    let body = {
      scheduleId: [d.id],
      isActive: 1,
    }
    this.apiService.postCall('schedules/deactivateSchedules', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      // this.scheduleList= result['data']
      this.apiService.stopLoader();
      this.message = "Schedule activated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getInspectionScheduleList();
    })
  }
  qrSchedulesList = [];
  getInspectionScheduleList() {
    this.apiService.startLoader()
    let body = {}
    if (localStorage.getItem("inspectionScheduleListFilter") && localStorage.getItem("inspectionScheduleListFilter") != null && localStorage.getItem("inspectionScheduleListFilter") != 'null') {
      let inspectionScheduleListFilter = JSON.parse(localStorage.getItem("inspectionScheduleListFilter"));
      // console.log(inspectionScheduleListFilter);
      body = inspectionScheduleListFilter;
      if (inspectionScheduleListFilter.outletId && inspectionScheduleListFilter.outletId != null && inspectionScheduleListFilter.outletId != 'null') {
        this.selectedFiler.property = { id: inspectionScheduleListFilter.outletId, outletName: inspectionScheduleListFilter.frontOutletNameFilter }
        this.getLocationList();
        this.getUserList();
      }
      if (inspectionScheduleListFilter.userIdFilter && inspectionScheduleListFilter.userIdFilter != null && inspectionScheduleListFilter.userIdFilter != 'null') {
        this.selectedFiler.user = { id: inspectionScheduleListFilter.userIdFilter, fullName: inspectionScheduleListFilter.frontUserNameFilter }
      }
      this.selectedFiler.inspectionName = inspectionScheduleListFilter.inspectionName
      this.selectedFiler.scheduleName = inspectionScheduleListFilter.scheduleName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sort,
        sortBy: this.sortSelected,
        inspectionName: this.selectedFiler.inspectionName,
        scheduleName: this.selectedFiler.scheduleName,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        floorPlanId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontLocationNameFilter: this.selectedFiler.location ? this.selectedFiler.location.name : "",
        frontUserNameFilter: this.selectedFiler.user ? this.selectedFiler.user.fullName : "",
        frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      }
      localStorage.setItem('inspectionScheduleListFilter', JSON.stringify(body));
    }
    this.apiService.postCall('schedules/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.scheduleList = result['data'];
      this.qrSchedulesList = result['data'];
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrSchedulesList.length; i++) {
        this.qrSchedulesList[i]['selected'] = false
      }
      this.apiService.stopLoader()
      var jsonToBeUsed = [];
      this.setPaginationArray(result)
    })
  }
  AllSelectedCheck = false
  schedulesForPrintQr = []
  AllSelected(event, asset) {
    if (event.target.checked && asset == "All") {
      this.AllSelectedCheck = true
      for (var i = 0; i < this.qrSchedulesList.length; i++) {
        this.qrSchedulesList[i]['selected'] = true
      }
      this.schedulesForPrintQr = this.qrSchedulesList
    } else if (event.target.checked) {
      // console.log("in" + event.target.checked);
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrSchedulesList.length; i++) {
        if (this.qrSchedulesList[i]['id'] == asset.id) {
          this.qrSchedulesList[i]['selected'] = true
        }
      }
      this.schedulesForPrintQr.push(asset);
    } else if (!event.target.checked && asset == "All") {
      this.schedulesForPrintQr = []
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrSchedulesList.length; i++) {
        this.qrSchedulesList[i]['selected'] = false
      }
    } else if (!event.target.checked) {
      this.AllSelectedCheck = false
      this.schedulesForPrintQr = this.schedulesForPrintQr.filter(item => item.id != asset.id);
      for (var i = 0; i < this.qrSchedulesList.length; i++) {
        if (this.qrSchedulesList[i]['id'] == asset.id) {
          this.qrSchedulesList[i]['selected'] = false
        }
      }
    }
  }
  gotoPrint() {
    this.storedData.selectedAssetsDetailsList = this.schedulesForPrintQr
    localStorage.setItem("selectedSchedulesList", JSON.stringify(this.schedulesForPrintQr));
    window.open('/schedules-qr-html')
    //this.route.navigate(['/asset-qr-html'])
  }
  // gotoPrint() {
  //   this.storedData.selectedAssetsDetailsList = this.selectedAssetsDetailsList
  //   this.route.navigate(['/print-multiple-qr'])
  // }
  openQR(d) {
    this.storedData.qrData = { name: d.assetManagementName, image: d.QRCode,customerComplaintName:'' }
    this.route.navigate(['print-qr'])
  }
  statusSelection

  editSchedule(d) {
    this.route.navigate(['/inspection-schedule-view/' + d.id])
  }
  selectStatus(d) {
    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }


  totalnoOfPages
  activePage: any = 1
  pageSize = 10;
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  paginationArray = []


  pagination(d) {
    localStorage.removeItem("inspectionScheduleListFilter");
    localStorage.setItem("inspectionScheduleListFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.selectedAssets = []
      this.activePage = d
      // console.log(d)
      localStorage.setItem('schedulePage', d)
      this.getInspectionScheduleList()
    }
  }
  changePage(ev) {
    localStorage.removeItem("inspectionScheduleListFilter");
    localStorage.setItem("inspectionScheduleListFilter", null);
    // console.log(this.pageSize)
    this.selectedAssets = []
    // this.pageSize = d
    this.getInspectionScheduleList()
  }


  dublicateSelected() { }

  // changePage
  // pageSize

  chnageStatus
  message


  applyFilter() {
    localStorage.removeItem("inspectionScheduleListFilter");
    localStorage.setItem("inspectionScheduleListFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    this.getInspectionScheduleList()
    // this.getInspectionMainListPrev()
  }

  clear() {
    localStorage.removeItem("inspectionScheduleListFilter");
    localStorage.setItem("inspectionScheduleListFilter", null);
    this.selectedFiler = { scheduleName: null, inspectionName: null, location: null, property: null, user: null, business: null,frequency:null }
    this.getPropertyList()
    this.getInspectionScheduleList()
    // this.getInspectionMainListPrev()
  }

  selectALLFlag: boolean;
  selectedAssets = []
  selectedAssetsDetailsList = []
  selectAllAssets(ev) {
    this.selectedAssets = []
    if (ev.target.checked) {
      this.selectALLFlag = true;
      this.scheduleList.map(x => x.selected = true)
      for (let asset of this.scheduleList) {
        this.selectedAssets.push(asset.id)
        this.schedulesForPrintQr.push(asset);
        this.selectedAssetsDetailsList.push(asset)
      }
    }
    else {
      this.selectALLFlag = false;
      this.scheduleList.map(x => x.selected = false)
      this.selectedAssets = []
      this.selectedAssetsDetailsList = []
      this.schedulesForPrintQr = []
    }
    // console.log(this.selectedAssets)
  }
  selectAsset(ev, asset) {
    // console.log(ev.target.checked, asset)
    if (ev.target.checked) {
      this.selectedAssets.push(asset.id)
      this.selectedAssetsDetailsList.push(asset)
      this.schedulesForPrintQr.push(asset);
    }
    else {
      this.selectedAssets.splice(this.selectedAssets.findIndex(x => x == asset.id), 1)
      this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.id == asset.id), 1)
      this.schedulesForPrintQr.splice(this.selectedAssets.findIndex(x => x.id == asset.id), 1)
    }
    // console.log(this.selectedAssets)
  }


  selectedFiler = { scheduleName: null, inspectionName: null, location: null, property: null, user: null, business: null,frequency:null }
  propertFilter = false;
  businessFilter = false;
  filterSelect
  locationFilter = false
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.departmentFilter = false;
      this.assignedFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      this.locationFilter = false;
      this.frequencyFilter = false;
    }
    if (btn.id != 'changeBtn') {
      this.statusSelection = undefined
    }
  }
  assignedFilter = false;
  assetFilter = false;
  departmentFilter = false
  frequencyFilter = false;
  closeAll() {
    this.assignedFilter = false;
    this.departmentFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
    this.frequencyFilter = false;
  }


  applyFlag: boolean;

  getFilterStatus() {
    // console.log(this.selectedFiler)
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.scheduleName != null && this.selectedFiler.scheduleName != "") length = length + 1
    // if(this.selectedFiler.assignedTo!= null) length = length+1
    if (this.selectedFiler.user != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    // if(this.selectedFiler.business != null) length = length+1
    if (this.selectedFiler.inspectionName != null && this.selectedFiler.inspectionName != "") length = length + 1

    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  filterBusinessSearch
  // businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      // if(idx != -1 ){
      // if(this.apiService.roleId == '2') {
      //   this.selectedFiler.business =  this.bussnissList[idx]
      //   this.getPropertyList()
      //   }
      //   else if(this.apiService.roleId == '3')  {
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()

      //   }
      //   else{
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()
      //   }
      // }

      this.getPropertyList()
    })
  }
  filterPropertySearch
  filterProperty(ev) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      this.departmentList = result['departments']
      this.filterdepartmentList = result['departments']

      this.getUserList()
    })
  }

  jumpToPage = 1
  getArray(d) {

    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  filterUserSearch
  filterUsers(ev) {
    this.userList = this.filetrUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  userList
  filetrUserList
  getUserList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.startLoader()
    this.apiService.getCall('users/user-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.userList = result['data']
      this.filetrUserList = this.userList
    })
  }

  getUserListGeneric() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.userList = result['data']
      this.filetrUserList = this.userList
    })
  }
  locationList = []
  filterDepartmentList = []
  getLocationList() {
    this.apiService.startLoader()
    this.locationList = []
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    if(this.selectedFiler.property){
      this.apiService.postCall('locations/locationList/' + this.apiService.buisnessId + "/" + this.selectedFiler.property.id, {}, obj).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        this.locationList = result['data']
        this.filterDepartmentList = result['data']
      })
    }else{
      this.apiService.postCall('locations/locationList/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
        this.apiService.stopLoader()
        this.locationList = result['data']
        this.filterDepartmentList = result['data']
      })
    }
  }


  filterLocationSearch
  filterLocation(ev) {
    this.locationList = this.filterDepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })
  }

  getLocationName(id) {
    let idx = this.locationList.findIndex(x => x.id == id)
    if (idx != -1) return this.locationList[idx].name
  }

  getFrequency(d) {
    if (d.scheduleType == 1) return "Once"
    if (d.scheduleType == 2) return "Every day"
    if (d.scheduleType == 3) return "Every week"
    if (d.scheduleType == 4) return "Every month"
    if (d.scheduleType == 7) return "Every quarterly"
    if (d.scheduleType == 6) {
      let str = ""
      if (d.customType == 1) return "Every " + d.repeatDays + " Days"
      else if (d.customType == 2) return "Every " + d.repeatDays + " Week"
      else if (d.customType == 3) return "Every " + d.repeatDays + " Month"
      else if (d.customType == 4) return "Every " + d.repeatDays + " Year"
    }
  }

  // selectedTodayInspection=[]
  //   selectAllInspection:boolean
  //   selectAllAssets(ev){
  //     this.selectedTodayInspection = []

  //     this.selectAllInspection = ev.target.checked;
  //     if (ev.target.checked) {
  //       // this.selectAllInspection = true;
  //       this.scheduleList.map(x => x.selected = true)
  //       for (let department of this.scheduleList) {
  //         this.selectedTodayInspection.push(department.id)
  //         // this.selectedTodayInspectionDetailsList.push(asset)
  //       }
  //     }
  //     else {

  //       this.scheduleList.map(x => x.selected = false)
  //       this.selectedTodayInspection = []
  //       // this.selectedTodayInspectionDetailsList = []
  //     }
  //     // console.log(this.selectedTodayInspection)
  //   }

  //   selectAsset(ev,department){
  //     if (ev.target.checked) {
  //       this.selectedTodayInspection.push(department.id)
  //       // this.selectedTodayInspectionDetailsList.push(asset)
  //     }
  //     else {
  //       this.selectedTodayInspection.splice(this.selectedTodayInspection.findIndex(x => x == department.id), 1)
  //       // this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.assetId == asset.assetId), 1)
  //     }

  //     if(this.selectedTodayInspection.length == this.scheduleList.length) this.selectAllInspection = true;
  //     else this.selectAllInspection = false;
  //   }
}
