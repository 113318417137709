import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
declare var d3: any;

@Component({
  selector: 'app-trainings-details',
  templateUrl: './trainings-details.component.html',
  styleUrls: ['./trainings-details.component.css']
})
export class TrainingsDetailsComponent implements OnInit {
  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public formBuilder: FormBuilder,

  ) { }

  propertyList = []
  departmentList = []
  userList = []
  trainingForm = this.formBuilder.group({
    businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    outletId: [null, Validators.required],
    title: [null, Validators.required],
    userId: [null],
    file: [null],
    materialType: [null,],
    departmentId: [null, Validators.required],
  });
  downloadFlag = false
  businessId = 0;
  outletId = 0
  message
  trainingId
  userId
  ngOnInit(): void {

    this.trainingId = this.router.url.split("/")[2]
    this.userId = this.router.url.split("/")[3]
    this.getTrainingDetails(this.trainingId);
    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);
  }

  avgCompletionRate = 0
  completedtraining = []
  completionRate = 0
  viewershipPercentage = 0
  trainingUsers = []
  training = {}
  pieChartData = []
  rowCount=0;
  getTrainingDetails(id) {
    d3.selectAll("#donutchart").html('');

    this.apiService.startLoader()
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortBy,
      sortType: this.sortType,
      outlet_id: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      search_text: this.selectedFiler.name ? this.selectedFiler.name: null,

    }
    var url= 'TrainingUsers/index/' + id + '/' + this.apiService.authId;
    if(this.userId){
      url = 'TrainingUsers/viewMobile/'+ id + '/' + this.userId
    }
    this.apiService.postCall(url, {},body).subscribe((result: any[]) => {

      this.training = result['training']
      this.avgCompletionRate = result['avgCompletionRate']
      this.completedtraining = result['completedtraining']
      this.completionRate = result['completionRate']
      this.trainingUsers = result['trainingUsers']
      this.pieChartData = result['piechart']
      this.viewershipPercentage = result['viewershipPercentage']
      // console.log(result)
      this.rowCount= result['rowCount']
      this.propertyList = result['outlets']
      this.filterpropertyList =result['outlets']

      this.getPieChart()
      this.apiService.stopLoader();
      this.setPaginationArray(result)
    })

  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  exportAsPDF() {
    var HTML_Width = $("#donutchart1").width();
    var HTML_Height = $("#donutchart1").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#donutchart1")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.downloadReport();
  }
  downloadReport() {
    this.apiService.startLoader()
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortBy,
      sortType: this.sortType,
      outlet_id: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      search_text: this.selectedFiler.name ? this.selectedFiler.name: null,
      "chart1": this.chart1,
    }
    var url= 'Trainings/trainingReportDownload/' + this.trainingId + '/' + this.apiService.authId;
    if(this.userId){
      url = 'Trainings/trainingReportDownload/'+ this.trainingId + '/' + this.userId
    }
    this.apiService.postCall(url, {},body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  getMaterialType(typeId) {
    if (typeId == 4) {
      return "Image";
    } else if (typeId == 1) {
      return "Video";
    } else if (typeId == 2) {
      return "Audio";
    } else if (typeId == 3) {
      return "PDF";
    }
  }

  getUserName(d) {
    return d.sub_user['full_name'] + " (" + d.user['full_name'] + ")";
  }

  chartData = {
    type: 'Pie',
    data: [],
    chartColumns: [],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      is3D: true,
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };

  getPieChart2() {
    this.pieChartData.splice(0,1)
    this.chartData = {
      type: 'Pie',
      data: this.pieChartData,
      chartColumns: ['Progress','Progress','Progress'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#ee9595', '#eead35','#32ab32'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: '' },
        vAxis: { title: '' },
        seriesType: 'bars',
        is3D: true,
        series: { 2: { type: 'line' } }
      },
      width: 1000,
      height: 400
    };
  }
  getPieChart() {
    let obj = this.pieChartData

    var legendRectSize = 18;
    var legendSpacing = 4;

    var data = [parseInt(obj[1][1]), parseInt(obj[2][1]), parseInt(obj[3][1])];

    // console.log(data)
    var sum = 0
    for (let d of data) sum = sum + d

    var width = 640,
      height = 280,
      radius = Math.min(width, height) / 2;

    var color = d3.scaleOrdinal()
      .range(["#ee9595", "#eead35", "#32ab32"]);

    var arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(0);

    var labelArc = d3.arc()
      .outerRadius(radius - 40)
      .innerRadius(radius - 40);

    var pie = d3.pie()
      .sort(null)
      .value(function (d) { return d; });

    // NEW

    var svg = d3.select("#donutchart").append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + 180 + "," + height / 2 + ")")



    var g = svg.selectAll(".arc")
      .data(pie(data))
      .enter().append("g")
      .attr("class", "arc");

    g.append("path")
      .attr("d", arc)
      .style("fill", function (d) { return color(d.data); })
      .on("mouseover", function () { return tooltip.style("visibility", "visible"); })
      .on("mousemove", function (d) {
        // console.log(d, sum)
        return tooltip.style("color", ":red").style("top", "120px").style("left", (d3.event.pageX - 200) + "px").text(((d.data / sum) * 100).toFixed(2) + '%');
      })
      .on("mouseout", function () { return tooltip.style("visibility", "hidden"); });

    var path = svg.selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d, i) {
        return color(d.data.label);
      })


    var tooltip = d3.select("#donutchart")
      .append("div")
      .style("position", "absolute")
      .style("visibility", "hidden");

  }


  selectedFiler = { name: null, property: null }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1

    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }


  
  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    this.getTrainingDetails(this.trainingId);
    //this.applyFilter();
  }

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      this.getTrainingDetails(this.trainingId);
    }
  }
  jumpToOpen: any;
  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) array.push(i);

    return array;
  }

  applyFlag = false;
  sortType="desc"
  activePage = 1
  sortBy="id"
  pageSize = 10
  paginationArray=[]
  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    let body = {
      activePage: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortBy,
      sortType: this.sortType,
      outlet_id: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      search_text: this.selectedFiler.name ? this.selectedFiler.name: null,

    }
    this.apiService.startLoader()
    this.apiService.postCall('TrainingUsers/index/' + this.trainingId + '/' + this.apiService.authId, {}, body).subscribe((result: any[]) => {

      //this.training = result['training']
      //this.avgCompletionRate = result['avgCompletionRate']
      //this.completedtraining = result['completedtraining']
      //this.completionRate = result['completionRate']
      this.trainingUsers = result['trainingUsers']
      //this.pieChartData = result['piechart']
      //this.viewershipPercentage = result['viewershipPercentage']


      // console.log(result)
      //this.getPieChart()
      this.setPaginationArray(result)
      this.apiService.stopLoader()
    })
  }
  changePage(event: any) {
    this.pageSize = Number(event.target.value);
    // console.log(this.pageSize);
    this.applyFilter();
  }

  filterPropertySearch
  filterpropertyList=[]
  filterProperty(ev){
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      } } )
  }

  getOutLetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
      // console.log(result)
      this.departmentList = result['departments']
      this.filterpropertyList =result['data']
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)
      if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {
        this.trainingForm.patchValue({
          outletId: this.propertyList[idx].id
        })

      }

    })
  }

  filterSelect = false;
  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
    }
  }
  closeAll() {
    this.propertFilter = false;
  }

  clear(){
    this.applyFlag= false;
    this.selectedFiler={name:null  , property:null }
    this.applyFilter();
  }

  totalnoOfPages
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }







  
  goBack(): void {
    this._location.back();
  }
  groupList: []
  getPropertyList(businessId, userId) {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId, 
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.filterpropertyList =result['data']
      this.apiService.stopLoader()
      this.propertyList = [];
      this.propertyList = result['data'];
      this.groupList = result['groups'];
    })
  }


  
  openDate(id) {
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  backClicked() {
    this.router.navigate(['/training-list'])
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
