import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.css']
})
export class InsuranceDetailsComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData) { }

  insuranceId: any;
  insuranceDetails: any
  type:any
  editPermission=false
  saveNotify=false
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {
    this.apiService.startLoader()
    //this.type = this.router.url.split("/")[2]
    this.insuranceId = this.router.url.split("/")[2]
    this.apiService.getCall('insurances/view/'+this.insuranceId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.insuranceDetails = result['data']
      this.apiService.stopLoader()
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Notification Setting" && permission[i]['actionStatus'] == 1){
        this.saveNotify=true
      }
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]
  conditionListing =[{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]
  

  goToLink(url: string) {
    window.open(url, "_blank");
  }
  getFileName(name) {
    if (name.fileName){
      // console.log(name.fileName.split("/"))
      return name.fileName.split("/")[name.fileName.split("/").length -1];
    } 
  }
  checkFile(name){
    if (name.fileName){
      return true;
    } else{
      return false;
    }
  }

  getFileNameWarranty(name) {
    if (name && name.split("/")[6]) return name.split("/")[6];
  }
  amcType: any;

  getAmcText(id) {
    if (this.amcType && id) return this.amcType.find(x => x.id == id).name
  }
  removeFile(id){
    this.apiService.startLoader()
    this.apiService.getCall('insurances/deleteFile/' + id, {}).subscribe((result: any[]) => {
      // console.log(result);
      if(result['success']){
        this.message= "File Remove Successfully";
        (<HTMLInputElement>document.getElementById('openSuccess1')).click();
      }
      this.ngOnInit();
      this.apiService.stopLoader()
    })
  }

  userList
  getUserList(): void {
    this.apiService.startLoader()
    this.apiService.startLoader()
    this.apiService.getCall('users/user-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.userList = result['data'];
      // console.log("List of Users", this.userList);

    })

  }

  getUserName(id) {
    let idx = this.userList.findIndex(x => x.id == id)

    if (idx != -1) return this.userList[idx].fullName
    else return ''
  }
  showLoader = true
  updateData() {
    this.ngOnInit()
  }

  getStatus(id) {

    if (id) {
      let idx = this.statusList.findIndex(x => x.id == id)
      if (idx != -1) return this.statusList[idx].name
      else return "Lost"

    }
  }

  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    this.apiService.getCall('outlets/outlet-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      var jsonToBeUsed = [];




      // console.log(jsonToBeUsed)
      this.locationList = result['data']


      this.storedData.locationList = this.locationList;

    })
  }



  uploadedFiles: any
  fileName: any;

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }

  // upload() {


  //   let testData: FormData = new FormData();
  //   testData.append("file", this.uploadedFiles)
  //   this.apiService.startLoader()
  //   this.apiService.uploadCall('amcs/edit/' + this.asseAmcDetails.id, {}, testData).subscribe((result: any[]) => {
  //     // console.log(result);
  //     this.apiService.stopLoader()

  //     this.message = result["msg"];
  //     this.uploadedFiles = undefined
  //     this.fileName = undefined

  //     // (<HTMLInputElement>document.getElementById('openSuccess')).click();

  //   })
  // }

  uploadedFilesAsset

  // fileChangeAsset(event) {
  //   // console.log(event)
  //   let fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFilesAsset = file



  //   }

  //   let testData: FormData = new FormData();


  //   // // console.log(obj)
  //   testData.append('image', this.uploadedFilesAsset);
  //   testData.append("assetName", this.assetDetails.assetName);
  //   testData.append("make", this.assetDetails.make);
  //   testData.append("specRating", this.assetDetails.specRating);
  //   testData.append("srNo", this.assetDetails.srNo);
  //   testData.append("locationId", this.assetDetails.locationId);
  //   testData.append("purchaseDate", this.assetDetails.purchaseDate);
  //   testData.append("placeInService", this.assetDetails.placeInService);
  //   testData.append("propertyId", this.assetDetails.propertyId);
  //   testData.append("businessId", this.assetDetails.businessId);
  //   testData.append("model", this.assetDetails.model);
  //   testData.append("specification", this.assetDetails.specification);
  //   testData.append("isOperational", this.assetDetails.isOperational);
  //   testData.append("renewalDate", this.assetDetails.renewalDate);
  //   testData.append("warrantyExpiration", this.assetDetails.warrantyExpiration);
  //   testData.append("authId", "1");
  //   testData.append("vendorName", this.assetDetails.vendorName);
  //   testData.append("categoryName", this.assetDetails.categoryName);
  //   testData.append("conditions", this.assetDetails.conditions);
  //   testData.append("slaExpiryDate", this.assetDetails.slaExpiryDate);
  //   testData.append("remark", this.assetDetails.remark);
  //   testData.append("ownership", this.assetDetails.ownership);
  //   testData.append("assetTagNo", this.assetDetails.assetTagNo);
  //   testData.append("status", this.assetDetails.status);
  //   // status:this.assetDetailsForm.value.status,



  //   // // console.log(obj)
  //   this.apiService.startLoader()
  //   this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
  //     // console.log(result);
  //     this.apiService.stopLoader()
  //     this.getAsset()
  //     document.getElementById('dismissPopUp').click()

  //   })
  // }

  fileChangeWarranty(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }

  message
  changeStatus() {
    let testData: FormData = new FormData();


    // // console.log(obj)
    // testData.append('image', this.uploadedFilesAsset);


    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/' + this.insuranceDetails.assetId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      document.getElementById('dismissPopUp').click()

    })
  }
  closeAll() {
    (<HTMLInputElement>document.getElementById('closePopUp')).click();
  }

}

