import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {Location} from '@angular/common';
import { StoredData } from '../common/services/storedData.service';
@Component({
  selector: 'app-property-add',
  templateUrl: './property-add.component.html',
  styleUrls: ['./property-add.component.css']
})
export class PropertyAddComponent implements OnInit {

  constructor(
    public apiService:ApiService,
    public _location:Location,
    public router:Router,
    public formBuilder:FormBuilder,
    public storedData:StoredData
  ) { }

  selectedUsers=[];
  roleId:any
  userList = [];
  departmentList = [];
  dropdownSettings: IDropdownSettings;

  

  tabSelected="step1"

  backClicked() {
    this._location.back();
  }

  changetabSelected(){
    this.tabSelected="step2"
    // console.log(this.tabSelected)
  }
    

  id
  propertyForm: FormGroup;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };

    this.propertyForm = this.formBuilder.group({
      businessId: [null,Validators.required],
      authId: this.apiService.authId,
      outletCode: [null,Validators.required],
      outletName:[null,Validators.required],
      city:[null],
      defaultUsers:[null],
      latitude:[null],
      longitude:[null],
      siteCode:[null,Validators.required],
      // zone:[null],
      defaultDepartment:[null],
      locations:this.formBuilder.array([this.initLocation()]),
      removeLocation:null,
      
      zoneId:[null, Validators.required],
      stateId:[null, Validators.required],
      districtId:[null, Validators.required],
      siteType:[null, Validators.required],
      clientId:[null, Validators.required],
      citieId:[null, Validators.required],
      clusterId:[null, Validators.required],
      country:[null],
      isAdHoc:true
  
  
    })

    this.id = this.router.url.split("/")[2];
    if(this.apiService.roleId==1){
      this.getBusinessList();
    }else if(this.apiService.roleId==2){
      this.propertyForm.patchValue({businessId:this.apiService.buisnessId});
      this.getUserList(this.apiService.buisnessId);
      this.getDepartmentList(this.apiService.buisnessId);
    }else if(this.apiService.roleId==3){
      this.getUserList(this.apiService.buisnessId);
      this.propertyForm.patchValue({businessId:this.apiService.buisnessId});
      this.getDepartmentList(this.apiService.buisnessId);
    }
    this.getZoneList();
    this.getSiteTypes();
    this.getClientList();
    if(this.id){
      this.getPropertyDetail();
    }
  }

  changeBusiness(){
    if(this.propertyForm.value.businessId){
      this.userArry=[]
      this.getUserList(this.propertyForm.value.businessId);
      this.getDepartmentList(this.propertyForm.value.businessId);
    }
  }
  onBlur(){
    this.propertyForm.patchValue({
      outletName: this.propertyForm.value.outletName!= null ? this.propertyForm.value.outletName.trim()==""? null:this.propertyForm.value.outletName.trim():null,
      country :this.propertyForm.value.country!=null ? this.propertyForm.value.country.trim()==""? null:this.propertyForm.value.country.trim():null,
      city :this.propertyForm.value.city!=null ? this.propertyForm.value.city.trim()==""? null:this.propertyForm.value.city.trim():null,
    })
    var f=0;
    var array=[];
    for(var i=0;i<this.propertyForm.value.locations.length;i++){
      if(this.propertyForm.value.locations[i].locationName != null && this.propertyForm.value.locations[i].locationName !=''){//} &&  this.propertyForm.value.locations[i].locationName !=' ' && this.propertyForm.value.locations[i].locationName !='  '){
        f=1;
      }
      this.propertyForm.value.locations[i].locationName = this.propertyForm.value.locations[i].locationName.trim();
      array.push(this.propertyForm.value.locations[i].locationName);
    }
  }
  //editFlow
  selectedDepartment:any
  getPropertyDetail(){
    if(this.apiService.roleId==2){
      this.propertyForm.patchValue({businessId:this.apiService.buisnessId});
      this.getUserList(this.apiService.buisnessId);
      this.getDepartmentList(this.apiService.buisnessId);
    }else if(this.apiService.roleId==3){
      this.getUserList(this.apiService.buisnessId);
      this.propertyForm.patchValue({businessId:this.apiService.buisnessId});
      this.getDepartmentList(this.apiService.buisnessId);
    }

    this.apiService.getCall('outlets/view/'+this.id, {}).subscribe((result: any[]) => {
      if(result['success'] == true){
          let propertyDetails = result['data'];
          if(this.apiService.roleId==1){
            this.getUserList(propertyDetails.businessId);
            this.getDepartmentList(propertyDetails.businessId);
          }
          this.propertyForm = this.formBuilder.group({
            businessId: propertyDetails.businessId,
            authId: this.apiService.authId,
            outletCode: propertyDetails.outletCode,
            outletName:propertyDetails.outletName,
            city:propertyDetails.city,
            defaultUsers:[],
            latitude:propertyDetails.latitude,
            longitude:propertyDetails.longitude,
            siteCode:propertyDetails.siteCode,
            defaultDepartment:Number(propertyDetails.defaultDepartment),
            locations:this.formBuilder.array([]),
            removeLocation:null,
            country : propertyDetails.country,
            // zone : propertyDetails.zone,

            zoneId:propertyDetails.zoneId,
            stateId:propertyDetails.stateId,
            districtId:propertyDetails.districtId,
            siteType:propertyDetails.siteType,
            clientId:propertyDetails.clientId,
            citieId:propertyDetails.citieId,
            clusterId:propertyDetails.clusterId,
            isAdHoc: propertyDetails.isAdHoc == "0" ? false : true,
          })
          this.zoneId=propertyDetails.zoneId,
          this.stateId=propertyDetails.stateId,
          this.districtId=propertyDetails.districtId,
          this.clusterId=propertyDetails.clusterId,

          
          this.getStateList();
          this.getclusterList();
          this.getDistrictList();

          this.getSiteTypes();
          this.getClientList();
          this.getCityList();

          this.userArry=propertyDetails.defaultUsers;
          for(let d of propertyDetails['location']){
            this.addMoreLocations(d);  
          }
          // console.log(this.propertyForm.value)

      }
    });
  }
  addMoreLocations(data?): void {
    this.formLocation.push(this.initLocations(data));
  }
  initLocations(data:any | null){
    if(data != undefined && data != null){
      return this.formBuilder.group({
        id:data.id,
        locationName: data.name,
        qr_image: data.qr_image
      });
    }else{
      return this.formBuilder.group({
        id:[null],
        locationName: [null],
        qr_image: [null]
      });
    }
  }
  viewQr(i){
    // console.log(this.formLocation.value[i])
    if(this.formLocation.value[i].qr_image){
      this.storedData.qrData={name:this.formLocation.value[i].locationName,image : this.formLocation.value[i].qr_image, customerComplaintName:"" };
      this.router.navigate(['print-qr'])
    }
  }
  showQR(i){
    // console.log(this.formLocation.value[i])
    if(this.formLocation.value[i].qr_image){
      return true
    }
    return false
  }
  getDepartment(id){
    for(let i=0;i<this.departmentList.length;i++){
        if(id == this.departmentList[i].id){
          return this.departmentList[i].name;
        }
    }
  }
  getBusiness(id){
    for(let i=0;i<this.bussnissList.length;i++){
        if(id == this.bussnissList[i].id){
          return this.bussnissList[i].name;
        }
    }
  }
  getUserName(id){
    for(let i=0;i<this.userList.length;i++){
        if(id == this.userList[i].id){
          return this.userList[i].fullName;
        }
    }
  }
  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  goBack():void{
    this._location.back();
  }

  initLocation(){
    return this.formBuilder.group({
      locationName: [null]
    });
  }

  get formLocation() {
    return this.propertyForm.get('locations') as FormArray;
  }

  addMoreLocation(): void {
    this.formLocation.push(this.initLocation());
  }
  removeLocation=[]
  removeMoreLocation(i): void {
    if(this.formLocation.value[i].id){
      this.removeLocation.push(this.formLocation.value[i].id);
    }
    // console.log(this.formLocation.value[i].id)
    this.formLocation.removeAt(i);
  }
  getUserList(businessId){
    this.apiService.getCall('users/user-list/'+businessId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      if(result['success'] == 'true'){
        this.userList = result['data'];
      }else{
        this.userList=[]
      }
    });
  }

  getDepartmentList(businessId){
    this.apiService.getCall('types/pluckDepartments/'+businessId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      if(result['success'] == true){
        this.departmentList = result['data'];
      }else{
        this.departmentList = []
      }
    });
  }

  userArry=[]
  removeUser(i){
    this.userArry.splice(i,1) 
  }
  selectUser(){
    if(this.userArry.indexOf(this.propertyForm.value.defaultUsers) == -1){
      this.userArry.push(this.propertyForm.value.defaultUsers)
      this.propertyForm.patchValue({defaultUsers : null})
    }else{
      this.propertyForm.patchValue({defaultUsers : null})
    }
  }

  
  message
  saveProperty(){
    let formData = this.propertyForm.value;
    if(this.apiService.roleId==1){
      formData.businessId=this.propertyForm.value.businessId; 
      formData.defaultDepartment=this.propertyForm.value.defaultDepartment;
    }
    formData.isAdHoc=this.propertyForm.value.isAdHoc ? 1 : 0;
    let user=[];
    for(let i=0;i<this.userArry.length;i++){
      user.push(this.userArry[i]);
    }
    formData.defaultUsers=user; 
    // console.log("formData : ",formData);
    var url ="outlets/add";
    if(this.id){
      formData.removeLocation = this.removeLocation
      url = "outlets/edit/"+this.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall(url, {},formData).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // console.log("Response Listing :",result);
      this.message = result['msg'];
      if(result['success'] == false){
        
      }else{
        if(this.id)this.message = "Property details updated successfully";
        else this.message = "Property added successfully";
      }
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }

  getValidation(){
    // console.log(this.propertyForm)
    var f=0;
    // console.log(this.propertyForm.value.locations)
    for(var i=0;i<this.propertyForm.value.locations.length;i++){
      if(this.propertyForm.value.locations[i].locationName != null && this.propertyForm.value.locations[i].locationName !=''){
        f=1;
      }
      if(this.propertyForm.value.country == "null" || this.propertyForm.value.country == ""){
        //return false
      }
    }
    if(f==0){
      return false
    }

    return this.propertyForm.valid 
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }


  getZoneList() {
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.zoneList=[]
    this.apiService.postCall('zones/getZonesPluck', {}, body).subscribe((result: any[]) => {
      if(result['success']){
      this.zoneList = result['data'];
      }
      this.apiService.stopLoader();

    });
  }

  getZoneName(zId){
    var name = "";
    for(var i=0;i<this.zoneList.length;i++){
      if(this.zoneList[i].id ==zId){
        name=this.zoneList[i].name
      }
    }
   return name;
  }
  getStateList() {
    // console.log(this.propertyForm);
    this.stateList=[]
    this.clusterList=[]
    this.districtList=[]
    this.cityList=[]

    if(this.zoneId > 0){
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
      zoneId: this.zoneId,
    };
    this.apiService.postCall('states/getStatesPluck', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      if(result['success']){
      this.stateList = result['data'];
      }
      if(this.zoneId>0 && this.stateId>0){
        this.getclusterList();
      }
      this.apiService.stopLoader();

    });
  }
  }
  getStaetName(zId){
    var name = "";
    for(var i=0;i<this.stateList.length;i++){
      if(this.stateList[i].id ==zId){
        name=this.stateList[i].name
      }
    }
   return name;
  }

  stateList=[]
  clusterList=[]
  zoneList=[]
  districtList=[]

  zoneId=null
  stateId=null
  districtId=null
  clusterId=null
  getclusterList() {
    this.clusterList=[]
    this.districtList=[]
    this.cityList=[]
    if(this.zoneId >0 && this.stateId >0){
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.zoneId,
        stateId: this.stateId
      };
      this.apiService.postCall('clusters/getClustersPluck', {}, body).subscribe((result: any[]) => {
        if(result['success']){
        this.clusterList = result['data'];
        }
        this.apiService.stopLoader();
      });
    }
  }
  getClusterName(zId){
    var name = "";
    for(var i=0;i<this.clusterList.length;i++){
      if(this.clusterList[i].id==zId){
        name=this.clusterList[i].name
      }
    }
   return name;
  }

  getDistrictList() {
    this.districtList=[]
    this.cityList=[]
    if(this.zoneId>0 && this.clusterId >0 && this.stateId>0){
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.zoneId,
        stateId: this.stateId,
        clusterId: this.clusterId
      };
      this.apiService.postCall('districts/getDistrictsPluck', {}, body).subscribe((result: any[]) => {
        if(result['success']){
        this.districtList = result['data'];
        }
        this.apiService.stopLoader();
      });
    }
  }
  getDistrictName(zId){
    var name = "";
    for(var i=0;i<this.districtList.length;i++){
      if(this.districtList[i].id ==zId){
        name=this.districtList[i].name
      }
    }
   return name;
  }

  cityList=[]
  getCityList() {
    if(this.zoneId>0 && this.stateId>0 && this.clusterId>0 && this.districtId>0){
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.zoneId,
        stateId: this.stateId,
        clusterId: this.clusterId,
        districtId: this.districtId
      };
      this.apiService.postCall('cities/getCitiesPluck', {}, body).subscribe((result: any[]) => {
        if(result['success']){
        this.cityList = result['data'];
        }
        this.apiService.stopLoader();
      });
    }
  }
  getCituName(zId){
    var name = "";
    for(var i=0;i<this.cityList.length;i++){
      if(this.cityList[i].id ==zId){
        name=this.cityList[i].name
      }
    }
   return name;
  }

  clientList=[]
  getClientList() {
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId
      };
      this.apiService.postCall('clients/getClientsPluck', {}, body).subscribe((result: any[]) => {
        if(result['success']){
        this.clientList = result['data'];
        }
        this.apiService.stopLoader();
      });
  }
  getClientName(zId){
    var name = "";
    for(var i=0;i<this.clientList.length;i++){
      if(this.clientList[i].id ==zId){
        name=this.clientList[i].name
      }
    }
   return name;
  }

  siteList=[]
  getSiteTypes() {
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId
      };
      this.apiService.postCall('siteTypes/getSiteTypesPluck', {}, body).subscribe((result: any[]) => {
        if(result['success']){
        this.siteList = result['data'];
        }
        this.apiService.stopLoader();
      });
  }
  getSiteNmae(zId){
    var name = "";
    for(var i=0;i<this.siteList.length;i++){
      if(this.siteList[i].id ==zId){
        name=this.siteList[i].name
      }
    }
   return name;
  }

  next(){
    $("#next").click();
  }
}
