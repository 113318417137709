import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {ApiService} from '../../common/services/apiCalls.service';
import {StoredData} from '../../common/services/storedData.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-permit-view',
  templateUrl: './permit-view.component.html',
  styleUrls: ['./permit-view.component.css']
})
export class PermitViewComponent implements OnInit {
  tabSelected = 'active';
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  statusSelection;
  addPermission = false;
  editPermission = false;
  message;
  tenantList = [];
  urlSafe: SafeResourceUrl;
  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData,public sanitizer: DomSanitizer) {
    
  }


  ngOnInit(): void {
    this.apiService.startLoader();
    var url = localStorage.getItem("PermitUrl");
    // console.log(url)
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  
  getData(url){
    this.apiService.startLoader();
    this.apiService.getCall(url, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.urlSafe= result;
      setTimeout( function(){ this.apiService.startLoader() }, 20000);

    });

  }

  getList(isActive: any = 1) {

    this.apiService.startLoader();
    // this.applyFlag=false;

    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      status: Number(isActive),
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      startDate: '',
      endDate: '',
      searchText: '',
      permitNo: '',
      tenant: '',
    };
    this.apiService.postCall('PermitRequests/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];

      this.apiService.stopLoader();

    });
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = {name: null, permitNo: null, startDate: null,  endDate: null, tenant: null,  property: null, business: null};
    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId);
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId);

    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx];
        this.getPropertyList();
      } else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx];
        // this.selectedFiler.property = this.propertyList[idx1]
        // this.getPropertyList()

      } else {
        this.selectedFiler.business = this.bussnissList[idx];
        // this.selectedFiler.property = this.propertyList[idx1]
      }
    }

    this.getList();
  }

  paginationArray = [];

  pageSize = 10;
  activePage: any = 1;

  totalnoOfPages;

  changePage(ev) {
    // console.log(this.pageSize);
    this.selectedAssets = [];
    // this.pageSize = d
    this.getList();
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage);
    if (noOfPAges <= 5) {
      for (let i = 1; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++) {
        this.paginationArray.push(i);
      }
    } else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges;
      // console.log(start);
      for (let i = this.activePage - start; i <= noOfPAges; i++) {
        this.paginationArray.push(i);
      }
    }
    // console.log(this.paginationArray);
  }

  workList = [];
  selectedAssets = [];


  getCustomName(d) {
    return 'Every ' + d.frequency_no + ' ' + d.frequency_unit;
  }

  pagination(d) {
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('meterListPagination', d);
      this.getList();
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList();
  }


  selectedFiler = {name: null, startDate: null, endDate: null, property: null, permitNo: null, tenant: null, business: null};

  locationList = [];
  filterlocationList = [];


  selectStatus(d) {
    if ('status' + d != this.statusSelection) {
      this.statusSelection = 'status' + d;
    } else {
      this.statusSelection = undefined;
    }

    // console.log(this.statusSelection);

    // if($(".stama"+d).css('display') == 'none'){
    //   $(".stama"+d).css("display","block");
    // }else{
    //   $(".stama"+d).css("display","none");
    // }
  }

  changeIsActive(id, isActive?) {
    this.apiService.startLoader();
    let obj = {
      'isActive': Number(isActive),
      'authId': this.apiService.userId,
    };
    this.apiService.postCall('PermitRequests/edit/' + id, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      this.getList();
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.apiService.stopLoader();

    });
  }

  getBusinessList() {
    this.apiService.startLoader();
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
// this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.bussnissList = result['data'];
      this.bussnissListFilter = result['data'];
      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId);

      if (idx != -1) {
        if (this.apiService.roleId == '2') {
          this.selectedFiler.business = this.bussnissList[idx];
          this.getPropertyList();
        } else if (this.apiService.roleId == '3') {
          this.selectedFiler.business = this.bussnissList[idx];
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId)
          this.getPropertyList();

        } else {
          this.selectedFiler.business = this.bussnissList[idx];
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId)
          this.getPropertyList();
        }

      } else {
        // this.getMainPropertyList();
      }

    });
  }




  filterPropertySearch;
  propertFilter = false;

  filterTenantSearch;
  tenantFilter = false;
  filterProperty(ev) {
    // console.log(ev);
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase());
      }
    });
  }

  filterTenant(ev) {
    // console.log(ev);
    this.tenantList = this.filterTenantList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterTenantSearch.toLowerCase());
      }
    });
  }

  filterBusinessSearch;
  businessFilter = false;

  filterBuisness(ev) {
    // console.log(ev);
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase());
      }
    });
  }


  applyFlag = false;

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    let body = {
      activePage: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      name: this.selectedFiler.name,
      permitNo: this.selectedFiler.permitNo,
      endDate: this.selectedFiler.endDate,
      startDate: this.selectedFiler.startDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      tenant: this.selectedFiler.tenant ? this.selectedFiler.tenant.id : null,
    };
    this.apiService.startLoader();
    this.apiService.postCall('PermitRequests/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];

      this.apiService.stopLoader();

    });
  }

  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.startDate != null) {
      length = length + 1;
    }
    // if(this.selectedFiler.business != null) length = length+1
    if (this.selectedFiler.endDate != null) {
      length = length + 1;
    }
    if (this.selectedFiler.name != null) {
      length = length + 1;
    }
    if (this.selectedFiler.property != null) {
      length = length + 1;
    }
    if (this.selectedFiler.permitNo != null) {
      length = length + 1;
    }
    if (this.selectedFiler.tenant != null) {
      length = length + 1;
    }
    if (length == 0 || !this.applyFlag) {
      return '(None Selected)';
    } else if (this.applyFlag) {
      return `(${length} Filter Selected)`;
    }
  }


  navigateToDetails(id) {
    this.route.navigate(['/permit-setting/work-edit/' + id]);
  }

  deactiveSelected() {

  }

  dublicateSelected() {
  }

  selectAllAssets;

  filterSelect = false;


  bussnissList = [];
  bussnissListFilter = [];
  propertyList = [];
  filterpropertyList = [];
  filterTenantList = [];

  getPropertyList() {
    this.apiService.startLoader();
    // console.log(this.selectedFiler.business);
    let obj = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data'];
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId);


      //  this.selectedFiler.property = this.propertyList[idx]
    });


  }

  getTenantList(): void {
    this.tabSelected = 'active';
    const body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,

    };
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader();
    this.apiService.postCall('users/userActive/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.tenantList = result['data'];
      this.filterTenantList = result['data'];
      this.apiService.stopLoader();
    });

  }

  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }

  callGetOutside() {
    // console.log('d');
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
  }

  closeAll() {
    this.businessFilter = false;
    this.propertFilter = false;
  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }


  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = {content: html};

    pdfMake.createPdf(documentDefinition).download();

  }

}
