import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-meter-add-new',
  templateUrl: './meter-add-new.component.html',
  styleUrls: ['./meter-add-new.component.css']
})
export class MeterAddNewComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  meterDetailsForm: FormGroup

  unitList = []

  unitListFilter
  assetViewPermission = true;
  assetsFeaturePermission = false;

  userAssign = [{ userId: null }]
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';


    this.meterDetailsForm = this.fb.group({
      property: [null, Validators.required],
      meterName: [null, Validators.required],
      location: [null, Validators.required],
      asset: [null],
      frequency: [null, Validators.required],
      frequencyNumber: [null],
      frequencyUnit: [null],
      unit: [null, Validators.required],
      frequencyCustom: [null],
      meterId: [null],
      filterUnit: [null],

    });

    // this.getAssetList()
    this.getPropertyList();
    this.getUnitList();
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
    let permission: any;
    permission = JSON.parse(localStorage.getItem('permissions'));
    // console.log(permission);
    if (permission) {
      for (var i = 0; i < permission.length; i++) {
        if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
          this.assetViewPermission = true
        }
      }
    }
  }
  getUnitList() {
    this.apiService.getCall('units/unitList', {}).subscribe((result: any[]) => {
      this.unitList = result['data']
      this.unitListFilter = result['data']
    })
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  getFrequencyName() {
    if (this.meterDetailsForm.value.frequency == null) return 'Select'
    else if (this.meterDetailsForm.value.frequency == 'Custom') return 'Every ' + (this.meterDetailsForm.value.frequencyNumber ? this.meterDetailsForm.value.frequencyNumber : '') + " " + (this.meterDetailsForm.value.frequencyUnit ? this.meterDetailsForm.value.frequencyUnit : '')
    else return this.meterDetailsForm.value.frequency
  }

  selectUnitdataCustom() {
    this.selectUnit = !this.selectUnit
    this.meterDetailsForm.patchValue({
      unit: { id: this.meterDetailsForm.value.frequencyCustom, name: this.meterDetailsForm.value.frequencyCustom }
    })

    // )}
  }

  showAssigneeLimitFlag: boolean
  addMoreUser(): void {
    // this.formUserArr.push(this.initUser());
    if (this.userAssign.length < 5) {
      this.userAssign.push({ userId: null })
    }
    else {
      // this.userAssign.push({userId : null})
      this.showAssigneeLimitFlag = true;
    }


  }

  removeUser(idx) {
    this.userAssign.splice(idx, 1)
    this.showAssigneeLimitFlag = false
  }
  selectFreq = false;
  selectUnit = false;
  selectFreqdata(data) {
    this.selectFreq = !this.selectFreq
    this.meterDetailsForm.patchValue({ frequency: data })
  }
  selectCustomData(data) {
    this.meterDetailsForm.patchValue({ frequency: data })
  }
  selectUnitdata(data) {
    this.selectUnit = !this.selectUnit
    this.meterDetailsForm.patchValue({ unit: data })
  }

  selectFreqUnitdata(data) {
    this.unitOfMeasure = !this.unitOfMeasure
    this.meterDetailsForm.patchValue({ frequencyUnit: data })
  }
  submit() {
    // console.log(this.meterDetailsForm.value)
    let userAssignList = []
    for (let d of this.userAssign) { userAssignList.push(d.userId) }
    let obj = {

      meterName: this.meterDetailsForm.value.meterName,
      unitId: this.meterDetailsForm.value.unit.id,
      businessId: this.apiService.buisnessId,
      frequency: this.meterDetailsForm.value.frequency,

      frequencyUnit: this.meterDetailsForm.value.frequencyUnit,

      frequencyNo: this.meterDetailsForm.value.frequencyNumber,
      assetManagementId: this.meterDetailsForm.value.asset,
      outletId: this.meterDetailsForm.value.property,
      locationId: this.meterDetailsForm.value.location,
      userIds: userAssignList.toString(),
      // userNames:"hp s,pk",
      authId: this.apiService.userId
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('meters/add', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()


      if (result['success']) {
        this.message = result["msg"];



        (<HTMLInputElement>document.getElementById('openSuccess')).click();


        //   setTimeout(() => {
        //     (<HTMLInputElement>document.getElementById('dismissOk')).click();

        // }, 1000);
      }
      else {
        this.message = result["msg"];



        (<HTMLInputElement>document.getElementById('openError')).click();
      }
    })

  }
  backClicked() {
    this.route.navigate(['/meter-list'])
  }

  message
  statusList = [
    { id: 0, name: 'Deactive' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Dormant' },
    { id: 3, name: 'Migrated' },
    { id: 4, name: 'In Progress' },
    { id: 5, name: 'In Transit' },
  ]


  propertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.propertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
      //   this.meterDetailsForm.patchValue({
      //     property :this.propertyList[idx].id 
      //   })

      // this.getLocationList();this.getUserList(); this.getAssetList()
      // }



    })
  }

  locationList = []
  getLocationList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.meterDetailsForm.value.property ? this.meterDetailsForm.value.property : "",
    }
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/locations/location-list/:businessId/:outletId
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']



    })
  }



  assetList = []
  getAssetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.meterDetailsForm.value.property ? this.meterDetailsForm.value.property : "",
      "statusNotIn": "5,8",
    }
    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']

    })
  }

  userList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.meterDetailsForm.value.property ? this.meterDetailsForm.value.property : "",
    }
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.userList = result['data']

    })
  }


  frquencyList = [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }, { id: "5" }, { id: "6" }, { id: "7" }]
  tabSelected = 'step1'



  getTextUnit(data) {
    if (data) return this.unitList.find(x => x.unitId == data).unitName
  }
  getTextProperty(data) {
    if (data && this.propertyList && this.propertyList[0]) return this.propertyList.find(x => x.id == data) ? this.propertyList.find(x => x.id == data).outletName : ""
  }
  getTextLocation(data) {

    if (data && this.locationList && this.locationList[0]) return this.locationList.find(x => x.id == data) ? this.locationList.find(x => x.id == data).name : ""
  }


  getTextAsset(data) {

    if (data && this.assetList && this.assetList[0]) return this.assetList.find(x => x.id == data) ? this.assetList.find(x => x.id == data).assetName : ""
  }


  getTextAssignedto(data) {
    if (data && this.userList && this.userList[0]) return this.userList.find(x => x.id == data) ? this.userList.find(x => x.id == data).fullName : ""
  }


  filterLocation(ev) {
    // console.log(ev)

    this.unitList = this.unitListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.meterDetailsForm.value.filterUnit.toLowerCase())
      }
    }
    )

  }

  selectCustom = false
  unitOfMeasure = false;

  getValidation() {
    // console.log(this.meterDetailsForm.valid)
    return this.meterDetailsForm.valid && this.userAssign[0].userId
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.selectUnit = false
      this.selectFreq = false
    }
  }




  getDisabledUser(id) {
    // console.log(this.userAssign)
    if (this.userAssign[0].userId) {
      let idx = this.userAssign.findIndex(x => x.userId == id)
      if (idx != -1) return true
      else return false
    }
  }
}
