
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { HashLocationStrategy, Location } from '@angular/common';
import html2canvas from 'html2canvas';
// import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
declare var d3: any;

@Component({
  selector: 'app-dashboard-pm',
  templateUrl: './dashboard-pm.component.html',
  styleUrls: ['./dashboard-pm.component.css']
})
export class DashboardPmComponent implements OnInit {
  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;


  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];

  selectedFiler = { department: null, property: null, startDate: null, endDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null }
  propertyFiletr = false;
  statsuFilter = false;
  warrantyFilter = false;
  typeFilter = false;
  showFilter = false;
  businessFilter = false;
  departmentFilter = false;
  downloadFlag = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      // this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.statsuFilter = false;
      // this.typeFilter = false;
      // // this.showAddMenu = false;
      // this.businessFilter = false;
      // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFiletr = false;
      this.departmentFilter = false;
      // this.typeFilter = false;
      // this.businessFilter = false;
      // this.showAddMenu = false;
      // this.selectFreq = false
    }

  }

  chartData = {
    type: 'ColumnChart',
    data: [],
    chartColumns: ['Days', 'Compliant', 'Non Compliant'],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'bottom', alignment: 'center' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 1000,
    height: 400
  };

  closeAll() {
    this.propertyFiletr = false;
    this.departmentFilter = false;
    // this.typeFilter = false;
    // this.businessFilter = false;
  }

  departmentList
  getDepartmentList() {
    this.apiService.getCall('asset-managements/index/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data']['data'];
        this.filterdepartmentList = result['data']['data'];
      }
    });
  }

  goOnPmList(id) {
    localStorage.setItem("overdue", 'overdue');
    this.route.navigate(['/pm-list/' + id])
  }
  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;

    this.ngOnInit()
  }

  // sortData(data) {
  //   this.sortBy = data
  //   if (this.sortType == 'asc') this.sortType = 'desc'
  //   else this.sortType = 'asc'

  //   if(this.tabSelected == 'openTicket'){
  //     this.getOpenList();
  //   }
  //   else  if(this.tabSelected == 'parkedTicket'){
  //     this.getParkedTicekt();
  //   }
  //   else  if(this.tabSelected == 'closedTicket'){
  //     this.getClosedTicekt();
  //   }
  // }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { department: null, property: null, startDate: null, endDate: null, Clients: null, Zone: null, state: null, cluster: null, city: null }

    let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
    // console.log(this.apiService.roleId)

    if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

      this.selectedFiler.property = this.locationList[idx]

    }

    this.ngOnInit()
  }
  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      this.locationList = result['data']
      this.filterpropertyList = result['data']
      if (this.apiService.outletId) {
        let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      }
      // console.log(this.apiService.roleId)

      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){

      //   this.selectedFiler.property =this.locationList[idx]

      // }

      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })
  }

  filterPropertySearch
  // propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.locationList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterDepartmentSearch
  // propertFilter = false;
  filterdepartmentList = []
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }








  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData,) { }

  most_noncomplainPMList = [{ asset_name: "Transformer no. 1", non_comp: '5' },
  { asset_name: "Transformer no. 1", non_comp: '5' },
  { asset_name: "Transformer no. 1", non_comp: '5' },
  { asset_name: "Transformer no. 1", non_comp: '5' },
  { asset_name: "Transformer no. 1", non_comp: '5' },
  { asset_name: "Transformer no. 1", non_comp: '5' },
  { asset_name: "Transformer no. 1", non_comp: '5' },
  ]

  complainUsersList = [{ name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  { name: "Ojas Tripathi", property: "Site-A", checklist_count: "31-12-2021" },
  ]

  businessInspectionPermission = false
  businessFeaturePermit = false
  businessFeatureAttendance = false
  businessFeatureTraining = false
  businessFeatureasset = false
  businessFeaturmaintanence = false
  businesspartsPermission = false
  dashboardFeaturePermission = false
  assetsFeaturePermission = false
  partsFeaturePermission = false
  meterFeaturePermission = false
  maintenanceFeaturePermission = false
  adHockChecklistFeaturePermission = false
  qrcklistFeaturePermission = false
  ticketsFeaturePermission = false
  attendanceFeaturePermission = false
  permitFeaturePermission = false
  feedbackFeaturePermission = false
  trainingFeaturePermission = false
  employeeDatabaseFeaturePermission = false
  staticDashboardFlag = this.storedData.staticDashboardFlag;
  ngOnInit(): void {
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 1 && businessFeatures[i]['permission_allow'] == 1) {
          this.attendanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 2 && businessFeatures[i]['permission_allow'] == 1) {
          this.adHockChecklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 3 && businessFeatures[i]['permission_allow'] == 1) {
          this.qrcklistFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 4 && businessFeatures[i]['permission_allow'] == 1) {
          this.permitFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 5 && businessFeatures[i]['permission_allow'] == 1) {
          this.feedbackFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 6 && businessFeatures[i]['permission_allow'] == 1) {
          this.dashboardFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 8 && businessFeatures[i]['permission_allow'] == 1) {
          this.trainingFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 11 && businessFeatures[i]['permission_allow'] == 1) {
          this.employeeDatabaseFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 15 && businessFeatures[i]['permission_allow'] == 1) {
          this.meterFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 17 && businessFeatures[i]['permission_allow'] == 1) {
          this.maintenanceFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 18 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 33 && businessFeatures[i]['permission_allow'] == 1) {
          this.businessInspectionPermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.businessInspectionPermission = true
      this.businessFeaturePermit = true
      this.businessFeatureAttendance = true
      this.businessFeatureTraining = true
      this.businessFeatureasset = true
      this.businessFeaturmaintanence = true
      this.businesspartsPermission = true
      this.dashboardFeaturePermission = true
      this.assetsFeaturePermission = true
      this.partsFeaturePermission = true
      this.meterFeaturePermission = true
      this.maintenanceFeaturePermission = true
      this.adHockChecklistFeaturePermission = true
      this.qrcklistFeaturePermission = true
      this.ticketsFeaturePermission = true
      this.attendanceFeaturePermission = true
      this.permitFeaturePermission = true
      this.feedbackFeaturePermission = true
      this.trainingFeaturePermission = true
      this.employeeDatabaseFeaturePermission = true
    }
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    if (!this.selectedFiler.startDate && !this.selectedFiler.endDate) {
      this.selectedFiler.startDate = moment(satrtDate).format('YYYY-MM-DD');
      this.selectedFiler.endDate = moment(endDate).format('YYYY-MM-DD');
    }

    this.getFigur();
    // d3.selectAll("#groupedChartYAxis11 > *").remove();
    // d3.selectAll("#groupedChart11 > *").remove();
    this.getAsset()
    this.highestComplains()
    this.nonComplains()

    this.getLocationList()
    this.getDepartmentList()

    let businessPermission = [];
    businessPermission = JSON.parse(localStorage.getItem('businessPermission'));
    if (businessPermission) {
      for (var i = 0; i < businessPermission.length; i++) {
        if (businessPermission[i]['business_app_feature_id'] == 1 && businessPermission[i]['permission_allow'] == 1) {
          this.businessFeatureAttendance = true
        }
      }
      if (this.apiService.role == 1) {
        this.businessFeatureAttendance = true
      }
    }

    // this.screenWidth= window.innerWidth;

    // if (this.screenWidth <= 1380) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 750;
    // }
    // else if (this.screenWidth <= 1450) {
    //   this.marginLeft = 0;
    //   this.fixedIWdth = 900;
    // }
    // else if (this.screenWidth <= 1500) {
    //   this.marginLeft = 2;
    //   this.fixedIWdth = 950;
    // }
    // else if (this.screenWidth <= 1600) {
    //   this.marginLeft = 3;
    //   this.fixedIWdth = 1000;
    // }
    // else if (this.screenWidth <= 1700) {
    //   this.marginLeft = 4;
    //   this.fixedIWdth = 1050;
    // }
    // else if (this.screenWidth <= 1800) {
    //   this.marginLeft = 6;
    //   this.fixedIWdth = 1100;
    // }

    // else if (this.screenWidth <= 1920) {
    //   this.marginLeft = 8;
    //   this.fixedIWdth = 1150;
    // }

    // else if (this.screenWidth <= 2048) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1200;
    // }
    // else if (this.screenWidth <= 2100) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1300;
    // }
    // else if (this.screenWidth <= 2400) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1500;
    // }
    // else if (this.screenWidth <= 2800) {
    //   this.marginLeft = 9;
    //   this.fixedIWdth = 1900;
    // }
    setTimeout(() => {
      this.downloadFlag = true;
    }, 1000);
  }
  onSelect(event) {
    const row = event['selection'][0].row;
    const column = event['selection'][0].column;
    const year = this.pmDashboard['pmComplianceNonCompliantGraph'][row][0];
    let selectedItem;
    var flag = 1;
    if (column === 1) {
      selectedItem = "completed";
      flag = 1;
    }
    if (column === 2) {
      selectedItem = "inCompleted";
      flag = 2;
    }
    this.departMentalAnalyasis(flag, year)
  }

  departMentalAnalyasis(flag, grouo) {
    var apiValue = "Pms/pmDashboard/" + this.apiService.buisnessId;
    var tableName1 = 'Compliance Status'
    var obj1 = {
      "assetId": null,
      "date": moment(grouo).format('YYYY-MM-DD'),
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "detailsFlag": 1,
      "type": flag == "complete" ? 1 : 2 //1Completeed, 2=noneCompl
    }

    this.storedData.graphIndex = {
      headers: ['Pm Name', 'Asset Name', 'Outlet Name', 'Conditions', 'Model', 'Make', 'Category Name'],
      obj: obj1,
      api: apiValue,
      tableName: tableName1,
      dataSource: ['name', 'asset_name', 'outlet_name', 'conditions', 'model', 'make', 'category_name'],
      apiObj: 'data',
      rowCountParam: 'rowCount'
    }
    localStorage.setItem("graphIndex", JSON.stringify(this.storedData.graphIndex));
    this.route.navigate(['/graph-list'])

  }
  staticDashboardstaticDashboardChangeValue = this.storedData.staticDashboardFlag;
  staticDashboardChange() {
    if (this.staticDashboardstaticDashboardChangeValue == false || this.staticDashboardstaticDashboardChangeValue == undefined) {
      this.staticDashboardFlag = true;
      this.storedData.staticDashboardFlag = true;
      this.ngOnInit();
    } else {
      this.staticDashboardFlag = false;
      this.storedData.staticDashboardFlag = false;
      this.ngOnInit();
    }
  }
  pmDashboardCount
  getFigur() {
    let endDate = new Date()
    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      userId: this.apiService.userId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.startLoader()
    this.apiService.postCall('Pms/pmDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.pmDashboardCount = {
          "success": true,
          "msg": "success.",
          "totalPmChecklistsScheduled": "390",
          "totalCompletePmChecklistsScheduled": 320,
          "totalPendingPmChecklistsScheduled": "70",
          "pmComplianceNonCompliantGraph": [
            [
              "Days",
              "Compliant",
              "Non Compliant"
            ],
            [
              "01 Jun 2023",
              13,
              2
            ],
            [
              "02 Jun 2023",
              12,
              3
            ],
            [
              "03 Jun 2023",
              14,
              1
            ],
            [
              "04 Jun 2023",
              10,
              5
            ],
            [
              "05 Jun 2023",
              11,
              4
            ],
            [
              "06 Jun 2023",
              9,
              6
            ],
            [
              "07 Jun 2023",
              0,
              0
            ],
            [
              "08 Jun 2023",
              14,
              1
            ],
            [
              "09 Jun 2023",
              9,
              6
            ],
            [
              "10 Jun 2023",
              12,
              3
            ],
            [
              "11 Jun 2023",
              15,
              0
            ],
            [
              "12 Jun 2023",
              13,
              2
            ],
            [
              "13 Jun 2023",
              11,
              4
            ],
            [
              "14 Jun 2023",
              0,
              0
            ],
            [
              "15 Jun 2023",
              11,
              4
            ],
            [
              "16 Jun 2023",
              15,
              0
            ],
            [
              "17 Jun 2023",
              14,
              1
            ],
            [
              "18 Jun 2023",
              10,
              5
            ],
            [
              "19 Jun 2023",
              13,
              2
            ],
            [
              "20 Jun 2023",
              11,
              4
            ],
            [
              "21 Jun 2023",
              0,
              0
            ],
            [
              "22 Jun 2023",
              15,
              0
            ],
            [
              "23 Jun 2023",
              9,
              6
            ],
            [
              "24 Jun 2023",
              12,
              3
            ],
            [
              "25 Jun 2023",
              14,
              1
            ],
            [
              "26 Jun 2023",
              12,
              3
            ],
            [
              "27 Jun 2023",
              13,
              2
            ],
            [
              "28 Jun 2023",
              0,
              0
            ],
            [
              "29 Jun 2023",
              15,
              0
            ],
            [
              "30 Jun 2023",
              13,
              2
            ]
          ],
          "assetWithNonCompliant": [
            {
              "id": "186",
              "asset_name": "Asset -1111",
              "pmTemplateId": "31"
            },
            {
              "id": "6",
              "asset_name": "Shaper Machine ",
              "pmTemplateId": "9"
            },
            {
              "id": "264",
              "asset_name": "abcnnnnnnnkkkkkkkkkkkkkk",
              "pmTemplateId": "6"
            },
            {
              "id": "15",
              "asset_name": "DG NO 4",
              "pmTemplateId": "3"
            },
            {
              "id": "2",
              "asset_name": "Radial drill machine",
              "pmTemplateId": "2"
            }
          ],
          "userWithHighestCompliance": [],
          "data": [],
          "rowCount": 0
        }
      } else {
        this.pmDashboardCount = result;
      }
    })
  }

  pmDashboard
  getAsset() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      userId: this.apiService.userId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.startLoader()
    this.apiService.postCall('Pms/pmDashboard/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.pmDashboard = {
          "success": true,
          "msg": "success.",
          "totalPmChecklistsScheduled": "390",
          "totalCompletePmChecklistsScheduled": 320,
          "totalPendingPmChecklistsScheduled": "70",
          "pmComplianceNonCompliantGraph": [
            [
              "Days",
              "Compliant",
              "Non Compliant"
            ],
            [
              "01 Jun 2023",
              13,
              2
            ],
            [
              "02 Jun 2023",
              12,
              3
            ],
            [
              "03 Jun 2023",
              14,
              1
            ],
            [
              "04 Jun 2023",
              10,
              5
            ],
            [
              "05 Jun 2023",
              11,
              4
            ],
            [
              "06 Jun 2023",
              9,
              6
            ],
            [
              "07 Jun 2023",
              0,
              0
            ],
            [
              "08 Jun 2023",
              14,
              1
            ],
            [
              "09 Jun 2023",
              9,
              6
            ],
            [
              "10 Jun 2023",
              12,
              3
            ],
            [
              "11 Jun 2023",
              15,
              0
            ],
            [
              "12 Jun 2023",
              13,
              2
            ],
            [
              "13 Jun 2023",
              11,
              4
            ],
            [
              "14 Jun 2023",
              0,
              0
            ],
            [
              "15 Jun 2023",
              11,
              4
            ],
            [
              "16 Jun 2023",
              15,
              0
            ],
            [
              "17 Jun 2023",
              14,
              1
            ],
            [
              "18 Jun 2023",
              10,
              5
            ],
            [
              "19 Jun 2023",
              13,
              2
            ],
            [
              "20 Jun 2023",
              11,
              4
            ],
            [
              "21 Jun 2023",
              0,
              0
            ],
            [
              "22 Jun 2023",
              15,
              0
            ],
            [
              "23 Jun 2023",
              9,
              6
            ],
            [
              "24 Jun 2023",
              12,
              3
            ],
            [
              "25 Jun 2023",
              14,
              1
            ],
            [
              "26 Jun 2023",
              12,
              3
            ],
            [
              "27 Jun 2023",
              13,
              2
            ],
            [
              "28 Jun 2023",
              0,
              0
            ],
            [
              "29 Jun 2023",
              15,
              0
            ],
            [
              "30 Jun 2023",
              13,
              2
            ]
          ],
          "assetWithNonCompliant": [
            {
              "id": "186",
              "asset_name": "Asset -1111",
              "pmTemplateId": "31"
            },
            {
              "id": "6",
              "asset_name": "Shaper Machine ",
              "pmTemplateId": "9"
            },
            {
              "id": "264",
              "asset_name": "abcnnnnnnnkkkkkkkkkkkkkk",
              "pmTemplateId": "6"
            },
            {
              "id": "15",
              "asset_name": "DG NO 4",
              "pmTemplateId": "3"
            },
            {
              "id": "2",
              "asset_name": "Radial drill machine",
              "pmTemplateId": "2"
            }
          ],
          "userWithHighestCompliance": [],
          "data": [],
          "rowCount": 0
        }
      } else {
        this.pmDashboard = result;
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      this.groupedBarchart()
    })
  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.downloadReport();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  downloadReport() {

    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()

    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      userId: this.apiService.userId,
      "roleId": this.apiService.roleId,
      "businessId": this.apiService.buisnessId,
      "chart1": this.chart1,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      pmDashboardCount: this.pmDashboardCount,
      nonComplainPms: this.nonComplainPms,
      highestComplainPms: this.highestComplainPms,
    }
    this.apiService.startLoader()
    this.apiService.graphPostCall('pms/dashboardMaintenanceDownload', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        const link = document.createElement('a');
        link.setAttribute('href', result['data']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.apiService.stopLoader();
      }
    })
  }
  nonComplainPms = []

  nonComplains() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()


    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.startLoader()
    this.apiService.postCall('pms/nonCompliancePms', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.nonComplainPms = [
          {
            "id": "186",
            "asset_name": "Generator No. 2",
            "sr_no": "25498565",
            "make": "Zebronic",
            "pmTemplateId": "3",
            "pm_template_id": "30",
            "outlet_name": "Manik Cinemas ",
            "locationName": "Near Dipak Square Tilak Road Akola",
            "totalComplete": 2,
            "totalMiss": 3,
            "totalPm": 182,
            "frequency": "Daily"
          },
          {
            "id": "209",
            "asset_name": "HVAC Plant",
            "sr_no": "2",
            "make": "ADLEC",
            "pmTemplateId": "2",
            "pm_template_id": "32",
            "outlet_name": "Site NGP",
            "locationName": "Second Floor",
            "totalComplete": 0,
            "totalMiss": 2,
            "totalPm": 121,
            "frequency": "Daily"
          },
          {
            "id": "211",
            "asset_name": "Transformer No. 1",
            "sr_no": "U8WMCH00976",
            "make": "KIRLOSKER",
            "pmTemplateId": "2",
            "pm_template_id": "34",
            "outlet_name": "DLF Mall",
            "locationName": "First Floor",
            "totalComplete": 1,
            "totalMiss": 2,
            "totalPm": 121,
            "frequency": "Daily"
          },
          {
            "id": "208",
            "asset_name": "Chiller Plant No. 1",
            "sr_no": "1",
            "make": "EDGTECH",
            "pmTemplateId": "1",
            "pm_template_id": "33",
            "outlet_name": "Site NGP",
            "locationName": "Ground Floor",
            "totalComplete": 0,
            "totalMiss": 1,
            "totalPm": 90,
            "frequency": "Daily"
          },
          {
            "id": "210",
            "asset_name": "AHU Motor No. 5",
            "sr_no": "1217M80017267",
            "make": "CE(XYLEM)",
            "pmTemplateId": "1",
            "pm_template_id": "31",
            "outlet_name": "DLF Mall",
            "locationName": "First Floor",
            "totalComplete": 0,
            "totalMiss": 1,
            "totalPm": 90,
            "frequency": "Daily"
          }
        ]
      } else {
        this.nonComplainPms = result['assetWithNonCompliant'];
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }

  highestComplainPms = []

  highestComplains() {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()


    let body = {
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : "",
      assetId: this.selectedFiler.department ? this.selectedFiler.department.assetId : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'),
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",

    }
    this.apiService.startLoader()
    this.apiService.postCall('pms/userWithHighestCompliance', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (this.staticDashboardFlag == true) {
        this.highestComplainPms = [
          {
            "id": "19",
            "asset_name": "Engg. Supervisor Team",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Engg. Supervisor Team",
            "userId": "4431",
            "outlet_name": "Site - A",
            "pmTemplateId": "30",
            "sub_user_id": "7125",
            "totalAssign": 30,
            "percentage": "2 %"
          },
          {
            "id": "211",
            "asset_name": "Air Blower No. 2 (Pump)",
            "sr_no": "U8WMCH00976",
            "make": "KIRLOSKER",
            "full_name": "Nick D'Souza	",
            "userId": "323",
            "outlet_name": "Site - C",
            "pmTemplateId": "26",
            "sub_user_id": "68",
            "totalAssign": 26,
            "percentage": "0 %"
          },
          {
            "id": "19",
            "asset_name": "CHILLER PLANT 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Viraj Gandhi",
            "userId": "5654",
            "outlet_name": "Site - B",
            "pmTemplateId": "22",
            "sub_user_id": "10202",
            "totalAssign": 22,
            "percentage": "1 %"
          },
          {
            "id": "19",
            "asset_name": "CHILLER PLANT 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Sam Patil",
            "userId": "5654",
            "outlet_name": "Site - A",
            "pmTemplateId": "19",
            "sub_user_id": "10202",
            "totalAssign": 19,
            "percentage": "1 %"
          },
          {
            "id": "19",
            "asset_name": "CHILLER PLANT 1",
            "sr_no": "6700101",
            "make": "DAIKIN",
            "full_name": "Operations Team",
            "userId": "5654",
            "outlet_name": "Site - B",
            "pmTemplateId": "17",
            "sub_user_id": "10202",
            "totalAssign": 17,
            "percentage": "1 %"
          }
        ]
      } else {
        this.highestComplainPms = result['userWithHighestCompliance'];
      }
      // this.assetDashboard.assetTotalValues = parseInt(this.assetDashboard.assetTotalValues).toFixed(3)
      // this.groupedBarchart()
    })
  }
  displayFlag = true
  groupedBarchart() {

    this.pmDashboard['pmComplianceNonCompliantGraph'].splice(0, 1)
    this.chartData = {
      type: 'ColumnChart',
      data: this.pmDashboard['pmComplianceNonCompliantGraph'],
      chartColumns: ['Days', 'Compliant', 'Non Compliant'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'bottom', alignment: 'center' },
        hAxis: { title: 'Time Period' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 2: { type: 'line' } }
      },
      width: 1000,
      height: 400
    };
    this.displayFlag = false

  }

  groupedBarchart2() {
    let data = []

    let max: number = 0
    for (let i = 1; i < this.pmDashboard['pmComplianceNonCompliantGraph'].length; i++) {
      data.push({ 'group': this.pmDashboard['pmComplianceNonCompliantGraph'][i][0], 'complete': this.pmDashboard['pmComplianceNonCompliantGraph'][i][1], 'pending': this.pmDashboard['pmComplianceNonCompliantGraph'][i][2] })

      if (max < this.pmDashboard['pmComplianceNonCompliantGraph'][i][1]) max = parseInt(this.pmDashboard['pmComplianceNonCompliantGraph'][i][1])
      if (max < this.pmDashboard['pmComplianceNonCompliantGraph'][i][2]) max = parseInt(this.pmDashboard['pmComplianceNonCompliantGraph'][i][2])
    }

    max = max + 5
    // console.log("max" + max)

    var margin = { top: 10, right: 30, bottom: 20, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;


    // append the svg object to the body of the page
    var svgAxis = d3.select("#groupedChartYAxis11")
      .append("svg")
      .attr("width", 50)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + 40 + "," + margin.top + ")");
    // append the svg object to the body of the page
    var svg = d3.select("#groupedChart11")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 50)
      .append("g")
      .attr("transform",
        "translate(" + -10 + "," + margin.top + ")");

    // Parse the Data



    // List of subgroups = header of the csv files = soil condition here
    var subgroups = ['complete', 'pending']

    var groups = []
    for (let i of data) groups.push(i.group)
    // List of groups = species here = value of the first column called group -> I show them on the X axis



    // console.log('subgroups', subgroups)
    // console.log('groups', groups)
    // console.log(data)
    // Add X axis
    var x = d3.scaleBand()
      .domain(groups)
      .range([0, width])
      .padding([0.2])
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .style("text-anchor", "end")

      .attr("transform", "rotate(-65)");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, max])
      .range([height, 0]);
    svg.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));

    svgAxis.append("g")
      .attr("class", "myYaxis")
      .call(d3.axisLeft(y));


    // Another scale for subgroup position?
    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    // color palette = one color per subgroup
    var color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(['#33CCB2', '#EF6B6B'])

    // Show the bars
    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
      .enter().append("rect")
      .attr("x", function (d) { return xSubgroup(d.key); })
      .attr("y", function (d) { return y(d.value); })
      .on('click', function (s) {
        // console.log(s)
        this.departMentalAnalyasis(s.key, s.group);
      }.bind(this))
      .attr("width", 15)
      .attr("height", function (d) { return height - y(d.value); })
      .attr("fill", function (d) { return color(d.key); })
      .attr("rx", "3");

    svg.append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) { return "translate(" + x(d.group) + ",0)"; })
      .selectAll("rect")
      .data(function (d) {
        return subgroups.map(function (key) {
          // // console.log(key , d[key])
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("text")
      .text(function (d) {
        if (d.value != 0) return d.value;
      })
      .attr("x", function (d) {
        let data = (xSubgroup(d.key) + 5)
        return data;
      })
      .attr("y", function (d) {
        return y(d.value) - 5;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "10px")
      .attr("fill", function (d) { return color(d.key); })
      .attr("text-anchor", "middle");


    // d3.selectAll("g.myYaxis g.tick")
    // .append("line")
    // .attr("class", "gridline")
    // .attr("x1", 0)
    // .attr("y1", 0)
    // .attr("x2", width)
    // .attr("y2", 0);

    // d3.selectAll("g.myXaxis g.tick")
    // .append("line")
    // .attr("class", "gridline")
    // .attr("x1", 0)
    // .attr("y1", -height)
    // .attr("x2", 0)
    // .attr("y2", 0);


  }

  openListnonComplainsPms(id) {

    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    this.storedData.dashBoardIndex = {
      headers: ['Asset Name', 'Frequency', 'Property', 'Location', 'Completed', 'Overdue', 'Total'], api: 'pms/nonCompliancePms', tableName: 'PM Non Compliance Status', dataSource: ['asset_name', 'frequency', 'outlet_name', 'locationName', 'totalComplete', 'totalMiss', 'totalPm'], apiObj: 'assetWithNonCompliant', rowCountParam: 'count',

    }
    this.storedData.dashBoardIndex['apiObjParam'] = { startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'), }
    this.route.navigate(['/dashboard-index/' + id])

  }

  openListhighestPMComplaints(id) {
    const date = new Date();
    let satrtDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let endDate = new Date()
    this.storedData.dashBoardIndex = { headers: ['Name', 'Property', 'Assigned', 'Completed', '% Compliance'], api: 'pms/userWithHighestCompliance', tableName: 'Most Compliant Associates/Teams', dataSource: ['full_name', 'outlet_name', 'totalAssign', 'pmTemplateId', 'percentage'], apiObj: 'userWithHighestCompliance', rowCountParam: 'count' }

    this.storedData.dashBoardIndex['apiObjParam'] = { startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : moment(satrtDate).format('YYYY-MM-DD'), endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'), }
    this.route.navigate(['/dashboard-index/' + id])

  }


  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;

    if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#groupedChartYAxis11 > *").remove();
    d3.selectAll("#groupedChart11 > *").remove();

    this.groupedBarchart()
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.ZoneListData = []
          this.filteredZoneListData = null;
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;
    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }

}
