import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-index',
  templateUrl: './user-index.component.html',
  styleUrls: ['./user-index.component.css']
})
export class UserIndexComponent implements OnInit {

  constructor(
    public route: Router,
    public router: Router,
    public apiService: ApiService,
    public storedData: StoredData
  ) { }
  showAddMenu = false;
  // pagination Veriables
  paginationArray = [];
  activePage: any = 1;
  pageSize = 10;
  totalnoOfPages;
  message: any;
  saveData = false;
  pageSizeArchive = 10
  activePageArchive: any = 1
  paginationArrayArchive = []
  totalnoOfPagesArchive

  // array list veriables
  activeList: any;
  archiveList: any;
  roleList: any;
  propertyList: any;
  businessList: any;
  propertyFiletr = false;
  // Sorting variables
  sortType = "desc";
  sortBy = 'id';

  // Filter Object
  selectedFiler = { userName: null, property: null, business: null, role: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, }
  applyFlag = false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role: any;

  // Tab Selectio veriable
  tabSelected = '';
  statusSelection: any;

  addUserPermission = false
  addBulkUserPermission = false
  editPermission = false
  resetPasswordPermission = false
  changeStatusPermission = false
  showSubUserPermission = false

  getIds(ev) {
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property = ev.property

    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);
    // if(ev.flag==0){
    //  this.applyFilter();
    // }else if(ev.flag==1){
    //   this.downloadReportAllUsers();
    // }else if(ev.flag==2){
    // this.downloadReport();
    // }
  }

  ngOnInit(): void {
    // document.body.style.overflow = 'hidden';
    document.body.style.overflowY = 'scroll';

    var userIndexPagination = localStorage.getItem('userIndexPagination');
    if (userIndexPagination) {
      this.activePage = parseInt(userIndexPagination);
    }
    if (this.router.url.split("/")[2]) {
      this.tabSelected = this.router.url.split("/")[2];
      this.activePage = 1;
      this.jumpToPage = 1;
      this.clear();
      if (this.router.url.split("/")[2] == 'active') {
        this.getActiveUsers();
      } else {
        this.getArchiveUsers();
      }

    } else {
      this.getActiveUsers();
    }
    this.role = this.apiService.roleId;
    this.getPropertyList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.addUserPermission = true
      }
      if (permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "Bulk Add" && permission[i]['actionStatus'] == 1) {
        this.addBulkUserPermission = true
      }
      if (permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "Reset Password" && permission[i]['actionStatus'] == 1) {
        this.resetPasswordPermission = true
      }
      if (permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.editPermission = true
      }
      if (permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1) {
        this.changeStatusPermission = true
      }
      if (permission[i]['subModuleName'] == "Users & Teams" && permission[i]['action'] == "Show SubUser" && permission[i]['actionStatus'] == 1) {
        this.showSubUserPermission = true
      }
    }
  }
  goBack() {
    this.route.navigate(['/setup'])
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  sortData(data) {
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.userName != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.role != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1;
    if (this.selectedFiler.zoneId != null) length = length + 1;
    if (this.selectedFiler.cityId != null) length = length + 1;
    if (this.selectedFiler.cluserId != null) length = length + 1;
    if (this.selectedFiler.stateId != null) length = length + 1;

    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  setPaginationArrayArchive(result: any) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeArchive)
    this.totalnoOfPagesArchive = noOfPAges;
    this.paginationArrayArchive = []

    this.activePageArchive = parseInt(this.activePageArchive)

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayArchive.push(i)
    else if (4 + this.activePageArchive < this.totalnoOfPagesArchive) {
      for (let i = this.activePageArchive; i <= 4 + this.activePageArchive; i++)this.paginationArrayArchive.push(i)
    }
    else if (4 + this.activePageArchive >= 6) {

      let start = (4 + this.activePageArchive) - noOfPAges
      for (let i = this.activePageArchive - start; i <= noOfPAges; i++)this.paginationArrayArchive.push(i)
    }
    // console.log("Pagination Archive : ", this.paginationArrayArchive)
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    // console.log(noOfPAges)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    } else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }


  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  jumpToParkedN

  applyFilter() {
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }
  jumpToPage

  clear() {
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    this.jumpToPage = 1
    this.selectedFiler = { userName: null, property: null, business: null, role: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null }
    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
    (<HTMLInputElement>document.getElementById('clearFilter')).click();
  }

  pagination(d) {
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('userIndexPagination', d);
      if (this.tabSelected == 'active') {
        this.getActiveUsers();
      } else {
        this.activePageArchive = d
        this.getArchiveUsers();
      }
    }
  }
  paginationArchive(d) {
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePageArchive != d) {
      this.activePageArchive = d
      localStorage.setItem('userIndexPagination', d);
      if (this.tabSelected == 'active') {
        this.getActiveUsers();
      } else {
        this.getArchiveUsers();
      }
    }
  }

  downloadReport() {
    let body = {}
    if (localStorage.getItem("localUserFilter") && localStorage.getItem("localUserFilter") != null && localStorage.getItem("localUserFilter") != 'null') {
      let localUserFilter = JSON.parse(localStorage.getItem("localUserFilter"));
      body = localUserFilter;
      if (localUserFilter.outletId && localUserFilter.outletId != null && localUserFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localUserFilter.outletId, outletName: localUserFilter.frontOutletNameFilter }
      }
      if (localUserFilter.role && localUserFilter.role != null && localUserFilter.role != 'null') {
        this.selectedFiler.role = { id: localUserFilter.role, name: localUserFilter.frontRoleNameFilter }
      }
      this.selectedFiler.userName = localUserFilter.fullName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
        role: this.selectedFiler.role ? this.selectedFiler.role.id : null,
        // userId:this.apiService.roleId > 2 ? this.apiService.authId : '',
        // roleId:this.apiService.roleId > 2 ? this.apiService.roleId : '',
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRoleNameFilter: this.selectedFiler.role ? this.selectedFiler.role.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localUserFilter', JSON.stringify(body));
    }
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/exportSubUsers/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      const link = document.createElement('a');
      link.setAttribute('href', result['url']);
      link.setAttribute('download', `subUsers.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.apiService.stopLoader();
    })
  }


  downloadReportAllUsers() {
    let body = {}
    if (localStorage.getItem("localUserFilter") && localStorage.getItem("localUserFilter") != null && localStorage.getItem("localUserFilter") != 'null') {
      let localUserFilter = JSON.parse(localStorage.getItem("localUserFilter"));
      body = localUserFilter;
      if (localUserFilter.outletId && localUserFilter.outletId != null && localUserFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localUserFilter.outletId, outletName: localUserFilter.frontOutletNameFilter }
      }
      if (localUserFilter.role && localUserFilter.role != null && localUserFilter.role != 'null') {
        this.selectedFiler.role = { id: localUserFilter.role, name: localUserFilter.frontRoleNameFilter }
      }
      this.selectedFiler.userName = localUserFilter.fullName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
        role: this.selectedFiler.role ? this.selectedFiler.role.id : null,
        // userId:this.apiService.roleId > 2 ? this.apiService.authId : '',
        // roleId:this.apiService.roleId > 2 ? this.apiService.roleId : '',
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRoleNameFilter: this.selectedFiler.role ? this.selectedFiler.role.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localUserFilter', JSON.stringify(body));
    }
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/exportUsers/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      const link = document.createElement('a');
      link.setAttribute('href', result['url']);
      link.setAttribute('download', `users.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.apiService.stopLoader();
    })
  }

  changePage(ev) {
    localStorage.removeItem('localUserFilter');
    localStorage.setItem("localUserFilter", null);
    localStorage.removeItem('localUserArchiveFilter');
    localStorage.setItem("localUserArchiveFilter", null);
    if (this.tabSelected == 'active') {
      this.getActiveUsers();
    } else {
      this.getArchiveUsers();
    }
  }

  getBusinessList(): void {
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      this.businessList = []
      this.businessList = result['data'];
    })
  }

  getPropertyList(): void {
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
    }
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
      this.apiService.startLoader()
    }
    this.apiService.postCall('outlets/outlet-list/' + businessId + '/' + this.apiService.authId, {}, formData).subscribe((result: any[]) => {
      // console.log(result);
      if (this.selectedFiler.business) {
        this.apiService.stopLoader()
      }
      this.propertyList = []
      this.propertyList = result['data'];
      this.filterpropertyList = result['data'];
    })
  }

  filterpropertyList = []
  outletAddLimit = false
  exeedMsg = ""
  getActiveUsers(): void {
    this.tabSelected = 'active';
    let body = {}
    if (localStorage.getItem("localUserFilter") && localStorage.getItem("localUserFilter") != null && localStorage.getItem("localUserFilter") != 'null') {
      let localUserFilter = JSON.parse(localStorage.getItem("localUserFilter"));
      body = localUserFilter;
      if (localUserFilter.outletId && localUserFilter.outletId != null && localUserFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localUserFilter.outletId, outletName: localUserFilter.frontOutletNameFilter }
      }
      if (localUserFilter.role && localUserFilter.role != null && localUserFilter.role != 'null') {
        this.selectedFiler.role = { id: localUserFilter.role, name: localUserFilter.frontRoleNameFilter }
      }
      this.selectedFiler.userName = localUserFilter.fullName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
        role: this.selectedFiler.role ? this.selectedFiler.role.id : null,
        // userId:this.apiService.roleId > 2 ? this.apiService.authId : '',
        // roleId:this.apiService.roleId > 2 ? this.apiService.roleId : '',
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRoleNameFilter: this.selectedFiler.role ? this.selectedFiler.role.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localUserFilter', JSON.stringify(body));
    }
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/user-active/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.activeList = []
      this.activeList = result['data']['data'];
      this.outletAddLimit = result['outletAddLimit']
      if (this.outletAddLimit == false) {
        this.exeedMsg = result['exeedMsg'];
      }
      if (this.apiService.roleId == 3) {
        result['roles'].splice(0, 1)
        result['roles'].splice(0, 1)
      } else if (this.apiService.roleId == 2) {
        result['roles'].splice(1, 1)
      }

      result['roles'].splice(0, 1)
      this.roleList = result['roles'];
      // console.log("Roles : ", this.roleList);
      this.setPaginationArray(result)
    })

  }

  filterPropertySearch
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }



  getArchiveUsers(): void {
    this.tabSelected = 'archived';
    let body = {}
    if (localStorage.getItem("localUserArchiveFilter") && localStorage.getItem("localUserArchiveFilter") != null && localStorage.getItem("localUserArchiveFilter") != 'null') {
      let localUserArchiveFilter = JSON.parse(localStorage.getItem("localUserArchiveFilter"));
      body = localUserArchiveFilter;
      if (localUserArchiveFilter.outletId && localUserArchiveFilter.outletId != null && localUserArchiveFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localUserArchiveFilter.outletId, outletName: localUserArchiveFilter.frontOutletNameFilter }
      }
      if (localUserArchiveFilter.role && localUserArchiveFilter.role != null && localUserArchiveFilter.role != 'null') {
        this.selectedFiler.role = { id: localUserArchiveFilter.role, name: localUserArchiveFilter.frontRoleNameFilter }
      }
      this.selectedFiler.userName = localUserArchiveFilter.fullName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        fullName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
        role: this.selectedFiler.role ? this.selectedFiler.role.id : '',

        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRoleNameFilter: this.selectedFiler.role ? this.selectedFiler.role.name : "",
      };
      localStorage.setItem('localUserArchiveFilter', JSON.stringify(body));
    }
    var businessId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      businessId = this.selectedFiler.business.id;
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/user-archive/' + businessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.archiveList = []
      this.archiveList = result['data']['data'];
      this.setPaginationArrayArchive(result)
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    if (btn.id == 'changeButton') {
    } else if (btn.id != 'changeButton') {
      this.statusSelection = undefined
    }

  }

  changeStatus(id: any, status): void {

    this.apiService.startLoader()
    this.apiService.getCall('users/deactivateUser/' + id + '/' + status, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      this.saveData = result['success'];
      this.message = result['msg'];
      if (this.tabSelected == 'active') {
        // this.tabSelected = '';
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        // this.getActiveUsers();
      } else {
        // this.tabSelected = '';
        (<HTMLInputElement>document.getElementById('openSuccessArchive')).click();
        // this.getArchiveUsers();
      }
    })
  }

  closeAll() {
    this.propertyFilter = false;
    this.roleFilter = false;
    this.businessFilter = false;
  }

  selectStatus(d) {
    // console.log(d);
    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }


  dropButton = false;
  uploadedFiles
  fileName
  type = null
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name
    }
  }
  changeType(e) {
    if (e.target.value == "0: null") {
      this.type = null;
    } else {
      this.type = e.target.value
    }
    this.uploadedFiles = null
    this.fileName = null
  }
  getValidation() {
    // console.log(this.uploadedFiles)
    // console.log(this.type);
    // console.log(this.apiService.roleId != 1 && this.type == 2);
    if (!this.uploadedFiles) {
      return false;
    }
    if (this.type == null) {
      return false;
    }
    if (this.apiService.roleId != 1 && this.type == 2) {
      this.message = "You dont have access to upload Business Admin";
      (<HTMLInputElement>document.getElementById('openSuccessArchive')).click();
      return false;
    }
    return true;
  }
  errorMessages = []
  uploadWarranty() {
    let testData: FormData = new FormData();
    this.errorMessages = []
    testData.append('file', this.uploadedFiles);
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    testData.append('type', this.type);
    this.apiService.startLoader()
    this.apiService.postCall('users/bulkupload', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.getActiveUsers();
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.apiService.stopLoader()
      this.uploadedFiles = null
      this.fileName = null
    });
  }

}
