import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ticket-status-report',
  templateUrl: './ticket-status-report.component.html',
  styleUrls: ['./ticket-status-report.component.css']
})
export class TicketStatusReportComponent implements OnInit {

  constructor(
    public route: Router, 
    public apiService: ApiService, 
    public storedData: StoredData,
    public _location: Location,
  ) { }
  
  selectedFiler = { priority: null,department:null, userName: null, raisedBy: null, property: null,startDate:null,endDate:null,number:null,status:null,origin:null,subject:null,location:null }
  originList = [{ id: "3", name: "Ad-Hoc Tickets" }, { id: "10", name: "Meter Tickets" }, { id: "8", name: "Asset Tickets" }, { id: "9", name: "PmChecklist" }, { id: "3", name: "Checklist Tickets" }, { id: "0", name: "Customer Ticket" }]
  fromDate:Date = new Date();
  toDate:Date = new Date();
  filterLocationSearch
  sortSelected: any = null;
  sort: any = null;

  reportList = [];
  
  raisedByList = [];
  filterRaisedByList = [];
  RaisedByFilter = false;
  filterRaisedBySearch: any
  departmentFilter = false;
  statusFilter =false;
  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];
  filterdepartmentList = [];
  
  applyFlag = false;
  
  priorityList = [{id:1 , name:'High'} , {id:2 , name:'Medium'} , {id:3 , name:'Low'}];
  statusList = [{id:1 , name:'Open'} , {id:2 , name:'Closed'} , {id:3 , name:'Parked'}, {id:8 , name:'NotValid'}];
  priorityListFilter = [];
  priorityFilter = false;
  priorityFilterSearch:any;

  filterSelect = false;

  assetList = []
  assetListFilter = []
  assetFilter = false;
  filterAssetSearch:any
  
  numberFilter = false;

  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch:any

  ngOnInit(): void {
    //this.fromDate.setDate(this.fromDate.getDate() - 7);
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.applyFlag = true;
    this.getReportListing();
    this.getPropertyList();
    this.getDepartmentList();
  }

  openTop(id){
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  getReportListing() {
    this.apiService.startLoader()
    let body = {
      businessId:this.apiService.buisnessId,
      subject:this.selectedFiler.subject ? this.selectedFiler.subject : null,
      ticketId:this.selectedFiler.number ? this.selectedFiler.number : null,
      dueDateFrom: this.selectedFiler.startDate ? this.selectedFiler.startDate : null,
      dueDateTo: this.selectedFiler.endDate ? this.selectedFiler.endDate : null,
      raisedBy: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.id :null,
      priorityId:this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      userName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      status:this.selectedFiler.status ? this.selectedFiler.status.id : null,
      originId:this.selectedFiler.origin ? this.selectedFiler.origin.id:null,
      download: 0,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('tickets/getTicketStatusReports', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data']
      this.apiService.stopLoader()
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { priority: null, department:null,userName: null, raisedBy: null, property: null,startDate:null,number:null,status:null,origin:null,subject:null,endDate:null,location:null }
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getReportListing();
    this.filterdepartmentList = [];
  }

  sortData(data:any) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getReportListing()
  }

  getUserList() {
    this.apiService.startLoader()
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.selectedFiler.property?this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.raisedByList = result['data']
      this.filterRaisedByList = result['data']
      this.storedData.raisedByList = this.filterRaisedByList;
    })
  }
 
  filterUser(ev:any) {
    this.raisedByList = this.filterRaisedByList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterRaisedBySearch.toLowerCase())
      }
    })
  }

  applyFilter() {
    this.applyFlag = true;
    this.getReportListing();
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.priority != null) length = length + 1
    if (this.selectedFiler.subject != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.department != null) length = length + 1
    if (this.selectedFiler.userName != null) length = length + 1
    if (this.selectedFiler.raisedBy != null) length = length + 1
    if (this.selectedFiler.number != null) length = length + 1
    if (this.selectedFiler.origin != null) length = length + 1
    if (this.selectedFiler.status != null) length = length + 1
    if(this.selectedFiler.endDate != null) length = length + 1
    if(this.selectedFiler.status != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  
  filterPriority(ev:any){
    this.priorityList = this.priorityListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.priorityFilterSearch.toLowerCase())
      }
    })
  }
  filterDepartmentSearch
  departmentList
  filterDepartmentList = []
  getDepartmentList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('types/pluckDepartments/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }
  filterdepartment(ev) {
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }

  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      this.getUserList()
    })
  }

  filterProperty(ev:any) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.assetFilter = false;
      this.RaisedByFilter = false;
      this.departmentFilter = false;
      this.propertFilter = false;
      this.priorityFilter = false;
      this.statusFilter = false;
    }
  }
  closeAll() {
    this.assetFilter = false;
    this.RaisedByFilter = false;
    this.propertFilter = false;
    this.departmentFilter = false;
    this.priorityFilter = false;
    this.statusFilter =false;
  }

  downloadReport():void{
    this.apiService.startLoader()
    let body = {
      businessId:this.apiService.buisnessId,
      subject:this.selectedFiler.subject ? this.selectedFiler.subject : null,
      ticketId:this.selectedFiler.number ? this.selectedFiler.number : null,
      dueDateFrom: this.selectedFiler.startDate ? this.selectedFiler.startDate : null,
      dueDateTo: this.selectedFiler.endDate ? this.selectedFiler.endDate : null,
      raisedBy: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.id :null,
      priorityId:this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      userName: this.selectedFiler.userName ? this.selectedFiler.userName : null,
      departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      status:this.selectedFiler.status ? this.selectedFiler.status.id : null,
      originId:this.selectedFiler.origin ? this.selectedFiler.origin.id:null,
      download: 1,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('tickets/getTicketStatusReports', {}, body).subscribe((result: any[]) => {
      if(result['success'] == true){
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }

}