import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from '../../common/services/apiCalls.service';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lost-and-found-found-article-add',
  templateUrl: './found-article-add.component.html',
  styleUrls: ['./found-article-add.component.css'],
})
export class FoundArticleAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  handOverProcessForm: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  businessId = this.apiService.buisnessId;
  routeId;
  detailsArticleFound;
  nameDepositingPerson;
  areaArticleFound;
  claimedDate;
  claimedTime;
  nameAddressClaimant;
  articleInSecurity;
  articleInOperation;
  todayDate: any = new Date();
  remark = ''
  finderIn
  articleOutSecurity = ''
  articleOutOperation
  articleOutClaimant = ''
  nameFinder = ''
  dept = ''
  datePersonal
  timePersonal
  descriptionArticle
  signFinder
  nameInfoReceiver
  signInfoReceiver
  nameSecurityCharge
  signSecurityCharge
  userId
  signatureFile;
  signatureFileUrl;
  signatureBoolean = false;
  @ViewChild('canvas') public canvas: ElementRef;
  private cx: CanvasRenderingContext2D;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    // set the width and height
    // canvasEl.width = this.width;
    // canvasEl.height = this.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }
  ngOnInit(): void {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const date = new Date();
    document.body.style.overflow = 'hidden';
    this.tenantForm = this.fb.group({
      claimedDate: [date, [Validators.required]],
      claimedTime: [null, [Validators.required]],
      detailsArticleFound: [null, [Validators.required]],
      nameDepositingPerson: [null, [Validators.required]],
      areaArticleFound: [null, [Validators.required]],
      //nameAddressClaimant: [null, [Validators.required]],
      articleInSecurity: [null],
      articleInOperation: [null],
      businessId: [null],
      userId: [null],
      articleInHandhoverFlag: [0],
    });
    this.handOverProcessForm = this.fb.group({
      date: [date, [Validators.required]],
      time: [null, [Validators.required]],
      detailsArticleLost: [null, [Validators.required]],
      personFillingComplaint: [null, [Validators.required]],
      locationArticleLost: [null, [Validators.required]],
      addressComplaint: [null, [Validators.required]],
      mobileNumber: [null, [Validators.required]],
      contactNumber: [null],
      remark: [null],
      handoverNameOfReceiver: [null],
      handoverContactNumber: [null],
      handoverIdProof: [null],
      handoverIdProofValue: [null],
      handoverIdProofValueAttach: [null],
      handoverAddressOfReceiver: [null],
      handoverSignature: [null],
    });
    this.getUserList();
  }
  fileName
  uploadedFiles
  msg = null
  fileChange(event) {
    this.msg = null
    var succ = false
    // console.log(event.target.files[0].type)
    var ext = event.target.files[0].name.split('.').pop();
    if (ext == "jpg" || ext == "gif" || ext == "jpeg" || ext == "png") {
      var span = document.getElementById('images');
      var img = document.createElement('img');
      img.src = URL.createObjectURL(event.target.files[0]);
      img.id = "firstId";
      if (document.getElementById('firstId')) {
        document.getElementById('firstId').remove();
      }
      span.appendChild(img);
      // span.removeChild(img);
      img.style.height = "88px"
      // console.log(event)
      succ = true;
    } else {
      this.msg = "Please select Image format only";
    }
    if (succ) {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        file = file
        this.uploadedFiles = file;
        this.fileName = file.name
      }
    }
  }
  removeImage() {
    this.fileName = null;
    this.uploadedFiles = [];
    if (document.getElementById('firstId')) {
      document.getElementById('firstId').remove();
    }
  }
  handoverSignatureUrl
  foundArticleDetails: any;
  getTicektDetails() {
    if (this.routeId) {
      this.apiService.startLoader()
      this.apiService.getCall('LostFounds/view/' + this.routeId, {}).subscribe((result: any) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.foundArticleDetails = result['data'];
        let reminder = new Date()
        if (this.foundArticleDetails.claimedTime) {
          reminder.setHours(this.foundArticleDetails.claimedTime.split(':')[0])
          reminder.setMinutes(this.foundArticleDetails.claimedTime.split(':')[1])
          reminder.setSeconds(0)
        }
        let articleFoundFlags = 0;
        if (this.foundArticleDetails.lostArticleComplaintData.articleFoundFlag) {
          if (this.foundArticleDetails.lostArticleComplaintData.articleFoundFlag == 'Yes') {
            articleFoundFlags = 1;
            this.handoverSignatureUrl = this.foundArticleDetails.lostArticleComplaintData.handoverSignature;
          }
        }
        this.tenantForm.patchValue({
          businessId: this.businessId,
          claimedDate: this.foundArticleDetails.claimedDate ? new Date(this.foundArticleDetails.claimedDateEdit) : null,
          claimedTime: reminder,
          detailsArticleFound: this.foundArticleDetails.detailsArticleFound,
          nameDepositingPerson: this.foundArticleDetails.nameDepositingPerson,
          areaArticleFound: this.foundArticleDetails.areaArticleFound,
          articleInSecurity: this.foundArticleDetails.articleInSecurity,
          articleInOperation: this.foundArticleDetails.articleInOperation,
          articleInHandhoverFlag: articleFoundFlags,
        });
        let reminderTime = new Date()
        if (this.foundArticleDetails.lostArticleComplaintData.time) {
          reminderTime.setHours(this.foundArticleDetails.lostArticleComplaintData.time.split(':')[0])
          reminderTime.setMinutes(this.foundArticleDetails.lostArticleComplaintData.time.split(':')[1])
          reminderTime.setSeconds(0)
        }
        if (this.foundArticleDetails.lostArticleComplaintData.handoverIdProofValueAttach) {
          this.fileName = this.foundArticleDetails.lostArticleComplaintData.handoverIdProofValueAttach;
        }
        if (this.foundArticleDetails.lostArticleComplaintData.articleFoundFlag) {
          this.handOverProcessForm.patchValue({
            articleInHandhoverFlag: this.foundArticleDetails.lostArticleComplaintData.articleFoundFlag,
            date: this.foundArticleDetails.lostArticleComplaintData.dateEdit,
            time: reminderTime,
            personFillingComplaint: this.foundArticleDetails.lostArticleComplaintData.personFillingComplaint,
            detailsArticleLost: this.foundArticleDetails.lostArticleComplaintData.detailsArticleLost,
            locationArticleLost: this.foundArticleDetails.lostArticleComplaintData.locationArticleLost,
            addressComplaint: this.foundArticleDetails.lostArticleComplaintData.addressComplaint,
            mobileNumber: this.foundArticleDetails.lostArticleComplaintData.mobileNumber,
            contactNumber: this.foundArticleDetails.lostArticleComplaintData.contactNumber,
            remark: this.foundArticleDetails.lostArticleComplaintData.remark,
            handoverNameOfReceiver: this.foundArticleDetails.lostArticleComplaintData.handoverNameOfReceiver,
            handoverContactNumber: this.foundArticleDetails.lostArticleComplaintData.handoverContactNumber,
            handoverIdProof: this.foundArticleDetails.lostArticleComplaintData.handoverIdProof,
            handoverIdProofValue: this.foundArticleDetails.lostArticleComplaintData.handoverIdProofValue,
            handoverAddressOfReceiver: this.foundArticleDetails.lostArticleComplaintData.handoverAddressOfReceiver,
            handoverSignature: this.foundArticleDetails.lostArticleComplaintData.handoverSignature,
          });
        }
      });
    }
  }
  userListSecurity = [];
  userListOperation = [];
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId
    }
    this.apiService.postCall('users/user-list-roles/' + this.apiService.buisnessId + '/' + 0, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.userListSecurity = result['data']['userListSecurity'];
      this.userListOperation = result['data']['userListOperation'];
    });
    this.getTicektDetails();
  }
  goBack(): void {
    this._location.back();
  }
  getDoneSignature() {
    const imageURL = this.canvas.nativeElement.toDataURL();
    // Convert canvas image to Base64
    this.signatureFile = this.dataURItoBlob(imageURL);
    this.signatureFileUrl = imageURL;
    this.signatureBoolean = true;
  }
  clearSignature() {
    this.cx.clearRect(0, 0, 300, 100);

  }
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number, y: number },
    currentPos: { x: number, y: number }
  ) {
    // incase the context is not set
    if (!this.cx) {
      return;
    }

    // start our drawing path
    this.cx.beginPath();

    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from

      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);

      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  }
  save(): void {
    let claimedTime = new Date(this.tenantForm.value.claimedTime)
    claimedTime.setHours(new Date(this.tenantForm.value.time).getHours())
    claimedTime.setMinutes(new Date(this.tenantForm.value.time).getMinutes())
    claimedTime.setSeconds(new Date(this.tenantForm.value.time).getSeconds())
    if (!this.routeId) {
      var form = new FormData();
      form.append('claimedDate', moment(this.tenantForm.value.claimedDate).format('YYYY-MM-DD'));
      form.append('claimedTime', moment(this.tenantForm.value.claimedTime).format('HH:mm:ss'));
      form.append('detailsArticleFound', this.tenantForm.value.detailsArticleFound);
      form.append('nameDepositingPerson', this.tenantForm.value.nameDepositingPerson);
      form.append('areaArticleFound', this.tenantForm.value.areaArticleFound ? this.tenantForm.value.areaArticleFound : '');
      //form.append('nameAddressClaimant', this.tenantForm.value.nameAddressClaimant);
      form.append('articleInSecurity', this.tenantForm.value.articleInSecurity);
      form.append('articleInOperation', this.tenantForm.value.articleInOperation);
      form.append('businessId', this.businessId);
      form.append('userId', this.apiService.authId);
      form.append('articleInHandhoverFlag', this.tenantForm.value.articleInHandhoverFlag);
      form.append('date', moment(this.tenantForm.value.date).format('YYYY-MM-DD'));
      form.append('time', moment(this.handOverProcessForm.value.time).format('HH:mm:ss'));
      form.append('personFillingComplaint', this.handOverProcessForm.value.personFillingComplaint);
      form.append('detailsArticleLost', this.handOverProcessForm.value.detailsArticleLost);
      form.append('locationArticleLost', this.handOverProcessForm.value.locationArticleLost);
      form.append('addressComplaint', this.handOverProcessForm.value.addressComplaint);
      form.append('mobileNumber', this.handOverProcessForm.value.mobileNumber);
      form.append('contactNumber', this.handOverProcessForm.value.contactNumber);
      form.append('remark', this.handOverProcessForm.value.remark);
      form.append('handoverNameOfReceiver', this.handOverProcessForm.value.handoverNameOfReceiver);
      form.append('handoverContactNumber', this.handOverProcessForm.value.handoverContactNumber);
      form.append('handoverIdProof', this.handOverProcessForm.value.handoverIdProof);
      form.append('handoverIdProofValue', this.handOverProcessForm.value.handoverIdProofValue);
      if (this.uploadedFiles) {
        form.append('handoverIdProofValueAttach', this.uploadedFiles ? this.uploadedFiles : '');
      } else {
        form.append('handoverIdProofValueAttach', '');
      }
      form.append('handoverAddressOfReceiver', this.handOverProcessForm.value.handoverAddressOfReceiver);
      form.append('handoverSignature', this.signatureFileUrl ? this.signatureFileUrl : '');
      this.apiService.startLoader();
      this.apiService.postCall('LostFounds/add', {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        if (result['success']) {
          this.backClicked();
        }
      }, error => {
        this.apiService.stopLoader();
      });
    } else {
      var form = new FormData();
      form.append('claimedDate', moment(this.tenantForm.value.claimedDate).format('YYYY-MM-DD'));
      form.append('claimedTime', moment(this.tenantForm.value.claimedTime).format('HH:mm:ss'));
      form.append('detailsArticleFound', this.tenantForm.value.detailsArticleFound);
      form.append('nameDepositingPerson', this.tenantForm.value.nameDepositingPerson);
      form.append('areaArticleFound', this.tenantForm.value.areaArticleFound ? this.tenantForm.value.areaArticleFound : '');
      //form.append('nameAddressClaimant', this.tenantForm.value.nameAddressClaimant);
      form.append('articleInSecurity', this.tenantForm.value.articleInSecurity);
      form.append('articleInOperation', this.tenantForm.value.articleInOperation);
      form.append('businessId', this.businessId);
      form.append('userId', this.apiService.authId);
      //form.append('articleInHandhoverFlag', this.tenantForm.value.articleInHandhoverFlag);
      form.append('articleInHandhoverFlag', this.tenantForm.value.articleInHandhoverFlag);
      form.append('date', moment(this.tenantForm.value.date).format('YYYY-MM-DD'));
      form.append('time', moment(this.handOverProcessForm.value.time).format('HH:mm:ss'));
      form.append('personFillingComplaint', this.handOverProcessForm.value.personFillingComplaint);
      form.append('detailsArticleLost', this.handOverProcessForm.value.detailsArticleLost);
      form.append('locationArticleLost', this.handOverProcessForm.value.locationArticleLost);
      form.append('addressComplaint', this.handOverProcessForm.value.addressComplaint);
      form.append('mobileNumber', this.handOverProcessForm.value.mobileNumber);
      form.append('contactNumber', this.handOverProcessForm.value.contactNumber);
      form.append('remark', this.handOverProcessForm.value.remark);
      form.append('handoverNameOfReceiver', this.handOverProcessForm.value.handoverNameOfReceiver);
      form.append('handoverContactNumber', this.handOverProcessForm.value.handoverContactNumber);
      form.append('handoverIdProof', this.handOverProcessForm.value.handoverIdProof);
      form.append('handoverIdProofValue', this.handOverProcessForm.value.handoverIdProofValue);
      if (this.uploadedFiles) {
        form.append('handoverIdProofValueAttach', this.uploadedFiles ? this.uploadedFiles : '');
      } else {
        form.append('handoverIdProofValueAttach', '');
      }
      form.append('handoverAddressOfReceiver', this.handOverProcessForm.value.handoverAddressOfReceiver);
      form.append('handoverSignature', this.signatureFileUrl ? this.signatureFileUrl : '');
      this.apiService.startLoader();
      this.apiService.postCall('/LostFounds/edit/' + this.routeId, {}, form).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        // this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        if (result['success']) {
          this.backClicked();
        }
      }, error => {
        this.apiService.stopLoader();
      });
    }
  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  getValidation() {
    // console.log(this.tenantForm)
    if (this.tenantForm.value.articleInHandhoverFlag == 1) {
      return this.tenantForm.valid && this.handOverProcessForm.valid;
    } else {
      return this.tenantForm.valid;
    }
  }

  backClicked() {
    this.router.navigate(['/found-article-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
