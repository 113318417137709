import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/apiCalls.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public router : Router , public apiService:ApiService ,) { }
  userId:any;
  notiFcount=0
  tokenFlag=false
  ngOnInit() {
    this.userId = this.apiService.authId;
    interval(5*60*1000).subscribe(x => {
      if(this.apiService.userId){
        this.getNotification();
      }
    });
    if(localStorage.getItem('accessToken')){
      this.tokenFlag = true
    }
  }

  goToDetails(d){
    if(d.notificationType==1){
      this.router.navigate(['ticket-view/'+d.ticketId])
    }else if(d.notificationType==2){
      this.router.navigate(['ticket-view/'+d.ticketId])
    }else if(d.notificationType==3){
      this.router.navigate(['asset-details/'+d.assetId])
    }else if(d.notificationType==4){
      this.router.navigate(['parts-details/'+d.partId])
    }else if(d.notificationType==5){
      this.router.navigate(['pm-view/'+d.pmId])
    }
  }
  showAddMenu:boolean

  notification=[]
  getNotification(){
    let obj={
      "userId":this.apiService.userId,
      pageNo:1,
      limit:100,
      //seen:0
      
    }
    // this.apiService.startLoader()
    // this.apiService.getCall('notifications/getNotifications/'+this.apiService.userId+'/1', {}).subscribe((result: any[]) => {
      this.apiService.postCall('Notifications/index', {},obj).subscribe((result: any[]) => {
      // console.log(result);
      // this.apiService.stopLoader()
      if(result['success']){
      this.notification = result['data'];
      var d=[]
      d = this.notification.filter(item => item.seen==0 );
      this.notiFcount=d.length
      }else{
        this.notification = [];
        this.notiFcount=0
      }
    })
  }
  updateNotification(){
    let obj={
      "userId":this.apiService.userId
    }
    // this.apiService.startLoader()
    this.apiService.getCall('notifications/updateSeen/'+this.apiService.userId, {}).subscribe((result: any[]) => {
      this.notiFcount=0
    })
  }
  
  logout(){
    let obj = localStorage.getItem("userCred")
    localStorage.clear();
    localStorage.setItem("userCred" , obj);
    this.apiService.token=undefined;
    this.router.navigate(['/login-view']);
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if(btn.id != 'customLogout' && btn.id != "customLogoutBox") {
      this.showAddMenu = false;
    }
  }
}
