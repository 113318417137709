import { Component, EventEmitter, OnInit, Input,Output } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder,Validators } from '@angular/forms';

@Component({
  selector: 'app-update-quantity',
  templateUrl: './update-quantity.component.html',
  styleUrls: ['./update-quantity.component.css']
})
export class UpdateQuantityComponent implements OnInit {
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();
  constructor(public router: Router, public apiService: ApiService, public formBuilder:FormBuilder) { }

  quantityForm = this.formBuilder.group({
    orderPoId: [null,Validators.required],
    quantityReceived:[null,Validators.required],
    quantityOrdered: [null, Validators.required],
    price:[null,Validators.required],
    type: [null],
    lostDiscard: [null],
    partialId:null
  });

  partsId: any;
  locationId: any = null;
  quantityHistoryList = []
  quantityHistoryList2=[]
  locationList=[]
  updateType=false
  partsDetails
  ngOnInit(): void {
    this.partsId = this.router.url.split("/")[2]
    this.apiService.getCall('parts/showDetails/' + this.partsId + "/" + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.partsDetails = result['part']
    })

    this.getQuantityHistory()
  }

  getValidation() {
    if(this.quantityForm.value.partialId == null || this.quantityForm.value.partialId == "null" || this.quantityForm.value.partialId == ""){
      return false;
    }
    return (this.quantityForm.valid )
  }
  getValidation2(){
    if(!this.quantityForm.value.lostDiscard || !this.quantityForm.value.type){
      return false;
    }
    return true;
  }
  quantityHistoryListTwo
  getQuantityHistory() {
    this.apiService.getCall('parts/getQuantityHistory/' + this.partsId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.quantityHistoryList = result['data']
      this.quantityHistoryListTwo = result['data']
      this.quantityHistoryList2 = this.quantityHistoryListTwo.filter(item => item.historyType ==2 );
      //this.quantityHistoryList = this.quantityHistoryList.filter(item => item.historyType ==1 );

    })


  }

  orderPoId(){
    (<HTMLInputElement>document.getElementById('orderPoId')).click();
  }
  message
  erroMsg
  update() {
    let obj={}

    if(this.updateType == false){
      this.erroMsg=""
      if(this.quantityForm.value.quantityReceived > this.quantityForm.value.quantityOrdered){
        this.erroMsg = "Received quantity should not greater than order quantity";
        this.quantityForm.patchValue({quantityReceived:null});
        return false;
      }
      
      obj = {
        partsId: this.partsId,
        orderPoId: this.quantityForm.value.orderPoId,
        quantityReceived: this.quantityForm.value.quantityReceived,
        quantityOrdered: this.quantityForm.value.quantityOrdered,
        partialId: this.quantityForm.value.partialId,
        authId: this.apiService.authId,
        price: this.quantityForm.value.price,
        historyType: 1,
        newEntry:2
      }
    }else{
      this.erroMsg=""
      if(this.quantityForm.value.lostDiscard > this.partsDetails.quentityInHand){
        this.erroMsg = "Lost or Discard should not greater than In hand quantity of part";
        this.quantityForm.patchValue({lostDiscard:null});
        return false;
      }
      if(!this.quantityForm.value.lostDiscard){
        return false;
      }
      obj = {
        partsId: this.partsId,
        orderPoId: null,
        quantityReceived: null,
        quantityOrdered: null,
        partialId: null,
        price:null,
        authId: this.apiService.authId,
        historyType: 2,
        type:this.quantityForm.value.type,
        lostDiscardQuantity:this.quantityForm.value.lostDiscard,
        newEntry:2
      }

    }
    this.apiService.startLoader()
    
    this.apiService.postCall('parts/saveQuantityHistory', {}, obj).subscribe((result: any[]) => {
      
      this.locationId = null;
      this.apiService.stopLoader();
      this.message = result['msg'];
      this.quantityForm.patchValue({orderPoId: null, quantityReceived:null, quantityOrdered: null, partialId: null, type: null,lostDiscard:null,price:null});
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      (<HTMLInputElement>document.getElementById('duplicate121')).click();
      this.getQuantityHistory()
      this.updateData.emit()
    })
  }

  statusList=[{id:1,name:"Partial"},{id:2,name:"Complete"}]
  ngOnDestroy() {
    // console.log('foo destroy')

  }

  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

}
