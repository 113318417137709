import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';

@Component({
  selector: 'app-edit-asset',
  templateUrl: './edit-asset.component.html',
  styleUrls: ['./edit-asset.component.css']
})
export class EditAssetComponent implements OnInit {
  @Input() assetDetails: any;
  @Input() statusList:any;
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDataError :EventEmitter<any> = new EventEmitter<any>();
  // @Input() propertyList:any;
  @Input() userList:any;
  constructor(private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService, ) { }
  // assetDetailsForm: FormGroup
  propertyList=[]
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false,
    dateType: 'start'
  }

  assetDetailsForm = this.fb.group({
    assetId: [null],
    assetName: [null],
    make: [null],
    specRating: [null],
    srNo: [null],
    locationId: [null],
    purchaseDate: [new Date()],
    placeInService: [new Date()],
    propertyId: [null],
    businessId: [null],
    model: [null],
    specification: [null],
    isOperational: [null],
    renewalDate: [null],
    warrantyExpiration: [new Date()],
    authId: [null],
    vendorName: [null],
    categoryName: [null],
    conditions: [null],
    slaExpiryDate: [new Date()],
    remark: [null],
    ownership: [null],
    assetTagNo: [null],
    status: [null]
  });
  changeStatusPermission=false
  viewEditLocationPermission=false
  propertyFlag
  ngOnInit(): void {

    // console.log(this.assetDetails)
   
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==3 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
      if(permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View Edit Location" && permission[i]['actionStatus'] == 1){
        this.viewEditLocationPermission=true
      }
    }

this.getPropertyList()
    
  }


  validateDate(satrtDate , endDate){
    //  // console.log(satrtDate , endDate)
if(satrtDate && endDate){
    if(endDate < satrtDate) return true
    else return false
   }
  }


  submit() {
    // console.log(this.assetDetailsForm.value)

    let obj = {
      assetName: this.assetDetailsForm.value.assetName,
      make: this.assetDetailsForm.value.make,
      specRating: this.assetDetailsForm.value.specRating,
      srNo: this.assetDetailsForm.value.srNo,
      locationId: this.assetDetailsForm.value.locationId,
      purchaseDate: this.assetDetailsForm.value.purchaseDate,
      placeInService: this.assetDetailsForm.value.placeInService,
      propertyId: this.assetDetailsForm.value.propertyId,
      businessId: this.assetDetailsForm.value.businessId,
      model: this.assetDetailsForm.value.model,
      specification: this.assetDetailsForm.value.specification,
      isOperational: this.assetDetailsForm.value.isOperational,
      renewalDate: this.assetDetailsForm.value.renewalDate,
      warrantyExpiration: this.assetDetailsForm.value.warrantyExpiration?this.assetDetailsForm.value.warrantyExpiration:null,
      authId: this.apiService.userId,
      vendorName: this.assetDetailsForm.value.vendorName,
      categoryName: this.assetDetailsForm.value.categoryName,
      conditions: this.assetDetailsForm.value.conditions,
      slaExpiryDate: this.assetDetailsForm.value.slaExpiryDate,
      remark: this.assetDetailsForm.value.remark,
      ownership: this.assetDetailsForm.value.ownership,
      assetTagNo: this.assetDetailsForm.value.assetTagNo,
      status:this.assetDetailsForm.value.status,

    }
// console.log(obj)
this.apiService.startLoader()
    this.apiService.postCall('asset-managements/edit/'+this.assetDetailsForm.value.assetId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if(result['success']){
      this.updateData.emit()
      document.getElementById('dismissPopUp').click()
      }
      else{
        this.updateDataError.emit(result['msg'])
        document.getElementById('dismissPopUp').click()
        this.message=result['msg']
        
        (<HTMLInputElement>document.getElementById('openError1111123')).click();
      }
    })
  
}

message
// statusList = [
 
//   { id: 1, name: 'Active' },
//   { id: 2, name: 'Inactive' },
// ]
conditionListing =[{ "id": "Used", "name": "Used" }, { "id": "New", "name": "New" }]

ngOnChanges() {
  // console.log(this.assetDetails);
  if (this.assetDetails) {
    this.assetDetailsForm.patchValue({
      assetId: this.assetDetails.assetId,
      assetName: this.assetDetails.assetName,
      make: this.assetDetails.make,
      specRating: this.assetDetails.specRating,
      srNo: this.assetDetails.srNo,
      locationId: this.assetDetails.locationId,
      purchaseDate: this.assetDetails.purchaseDateEdit != null ? new Date(this.assetDetails.purchaseDateEdit) : '',
      placeInService: this.assetDetails.plasceInServiceEdit != null ? new Date(this.assetDetails.plasceInServiceEdit) : '',
      propertyId: this.assetDetails.propertyId,
      businessId: this.assetDetails.businessId,
      model: this.assetDetails.model,
      specification: this.assetDetails.specification,
      isOperational: this.assetDetails.isOperational,
      renewalDate: this.assetDetails.renewalDateEdit != null ? new Date(this.assetDetails.renewalDateEdit) : '',
      warrantyExpiration: this.assetDetails.renewalDateEdit != null ? new Date(this.assetDetails.warrantyExpiration) :'',
      authId: this.assetDetails.authId,
      vendorName: this.assetDetails.vendorName,
      categoryName: this.assetDetails.categoryName,
      conditions: this.assetDetails.conditions,
      slaExpiryDate: this.assetDetails.renewalDateEdit != null ? new Date(this.assetDetails.slaExpiryDateEdit) : '',
      remark: this.assetDetails.remark,
      ownership: this.assetDetails.ownership,
      assetTagNo: this.assetDetails.assetTagNo,
      status: this.assetDetails.status,
    })

    // console.log(this.assetDetailsForm)
    this.gertLocations()
  }
}


locationList=[]
gertLocations(){

  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    // "outletId":this.assetDetailsForm.value.propertyId?this.assetDetailsForm.value.propertyId.id : "",
  }
 
  this.apiService.postCall('locations/location-list/'+this.apiService.buisnessId,{}  , obj).subscribe((result: any[])=>{
    // console.log(result);

//     var jsonToBeUsed = [];
  
//   for (var type in result['data']) {
//     let item = {};
//     item['key'] = type;
//     item['value'] = result['data'][type];
//     jsonToBeUsed.push(item);
    
   
// }

// // console.log(jsonToBeUsed)
this.locationList = result['data']
   
  })
}

public contactmask= [ /[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

getPropertyList(){
  this.apiService.startLoader()

  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    // "outletId":this.assetDetailsForm.value.propertyId?this.assetDetailsForm.value.propertyId.id : "",
  }
 
  this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj ).subscribe((result: any[])=>{
    // console.log(result);
    this.apiService.stopLoader()
    result['data']
  
  this.propertyList = result['data']


  // this.storedData.locationList = this.locationList;

})
}

getValidation(){
  //  // console.log(this.addAmc && this.amcDetailsForm.value.contactNo.search('_') == -1)
  return (!this.validateDate(this.assetDetailsForm.value.purchaseDate,this.assetDetailsForm.value.placeInService) && !this.validateDate(this.assetDetailsForm.value.purchaseDate,this.assetDetailsForm.value.warrantyExpiration)
  //  && !(this.addAmc && this.amcDetailsForm.value.contactNo.search('_') != -1)
  //  && (this.addAmc && this.amcDetailsForm.value.alternetNo.search('_') == -1) && (this.addWarrant && this.warrantyDetailsForm.value.contactNo.search('_') == -1) && (this.addWarrant && this.warrantyDetailsForm.value.alternetNo.search('_') == -1)
   
   )
}

// validateDate(satrtDate , endDate){
//   //  // console.log(satrtDate , endDate)
// if(satrtDate && endDate){
//   if(endDate <= satrtDate) return true
//   else return false
//  }
// }

}
