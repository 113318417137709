import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/services/apiCalls.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css']
})
export class TicketListComponent implements OnInit {


  constructor(public _location: Location, public route: Router, public router: Router, public apiService: ApiService,) { }
  filterLocationSearch
  filterLocation(ev) { }
  // applyFilter(){}
  getAssets() { }
  selectAllAssets(ev) { }
  // changePage(rv){}
  locationList = []
  dublicateSelected() { }
  deactiveSelected() { }
  statusList = []
  pageSize = 10
  'applyFlag': boolean;
  message
  buisnessId = this.apiService.buisnessId;

  viewPermission = true
  createPermission = false
  esclationAddPermission = false
  propertyFiletr = false;
  ticketClossingConfirmationPermission = false
  setTabSelection() {
    localStorage.setItem("tabselection", JSON.stringify({ ticketList: this.tabSelected, assetList: 'allasset', inspectionList: 'todaysReport', partsList: 'allparts', maintananceList: 'today' }));
    (<HTMLInputElement>document.getElementById('clearFilter')).click();
  }

  getIds(ev) {
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property = ev.property
    ev.flag == 1;
    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);
    //this.applyFilter();
  }


  ngOnInit(): void {
    if (this.router.url.split("/")[2]) {
      this.tabSelected = this.router.url.split("/")[2];
      this.clear();
      this.setTabSelection();
    }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).ticketList
    }
    var ticketMainListPagination = localStorage.getItem('ticketMainListPagination');
    if (ticketMainListPagination) {
      this.activePage = parseInt(ticketMainListPagination);
    }
    if (this.tabSelected == 'openTicket') {
      this.getOpenList();
    }
    else if (this.tabSelected == 'parkedTicket') {
      this.getParkedTicekt();
    }
    else if (this.tabSelected == 'closedTicket') {
      this.getClosedTicekt();
    }
    else if (this.tabSelected == 'notValid') {
      this.getnotValidTicket();
    }
    // this.getOpenList()
    // this.cl()
    // this.getClosedTicekt()
    // this.getParkedTicekt()
    this.getOtherDetails()
    //this.getnotValidTicket()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 2);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.createPermission = true
      }
      if (permission[i]['subModuleName'] == "Ticket" && permission[i]['action'] == "Escalation Setup" && permission[i]['actionStatus'] == 1) {
        this.esclationAddPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 25 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketClossingConfirmationPermission = true;
        }
      }
    }
    if (this.apiService.role == 1) {
      this.ticketClossingConfirmationPermission = true;
    }
  }

  selectedFiler = { subject: null, ticketId: null, dueDate: null, business: null, property: null, priority: null, raisedBy: null, assignTo: null, departmentId: null, origin: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null }
  openTicket = []
  cloasedTicket = []
  parkedTickets = []
  notValid = []
  paginationArray = []
  // selectedFiler={name:null , warrantExpiratioStart:null}
  tabSelected: any = 'openTicket'
  sortBy = 'tickedId'
  sortType = 'desc'
  activePage: any = 1
  //  changePage(ev){
  //   // console.log(this.pageSize)
  //   // this.selectedAssets=[]
  //   // this.pageSize = d
  //   this.getOpenTicketList()
  // }

  propertyList = []
  departmentList = []
  userList = []
  bussnissListFilter = []
  bussnissList = []
  propertyListForIndex = []
  // departmentList=[]
  getOtherDetails() {
    // this.apiService.postCall('asset-managements/index/'+this.apiService.buisnessId,{} , {pageNo:1, limit:200} ).subscribe((result: any[])=>{
    //   // console.log(result);

    //   // this.assetList=[]
    //   this.assetList= result['data']['data'];
    //   // console.log(this.assetList)
    //   this.statusList = result["statusList"];

    // })

    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
      // if(idx != -1 ){
      // if(this.apiService.roleId == '2') {
      //   this.selectedFiler.business =  this.bussnissList[idx]
      //   this.getPropertyList()
      //   }
      //   else if(this.apiService.roleId == '3')  {
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()

      //   }
      //   else{
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()
      //   }
      // }

      this.getPropertyList()
    })


    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.propertyList = result['data']
      this.propertyListForIndex = result['data']
      // console.log(result)
      this.departmentList = result['departments']
      this.departmentListFilter = result['departments']
    })


    let obj1 = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj1).subscribe((result: any[]) => {
      // console.log(result);
      result['data']

      // // console.log(jsonToBeUsed)
      this.userList = result['data']
      this.filterUserList = result['data']
    },
      error => {
        this.apiService.stopLoader()
      });
  }

  getUserFilyter() {
    let obj1 = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.startLoader()
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj1).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      // // console.log(jsonToBeUsed)
      this.userList = result['data']
      this.filterUserList = result['data']
    },
      error => {
        this.apiService.stopLoader()
      });
  }

  departmentListFilter
  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.ticketId != null) length = length + 1
    if (this.selectedFiler.subject != null) length = length + 1
    if (this.selectedFiler.dueDate != null) length = length + 1
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.priority != null) length = length + 1
    if (this.selectedFiler.raisedBy != null) length = length + 1
    if (this.selectedFiler.assignTo != null) length = length + 1
    if (this.selectedFiler.origin != null) length = length + 1
    if (this.selectedFiler.departmentId != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1



    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }


  getPropertyList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      this.departmentList = result['departments']
      this.departmentListFilter = result['departments']
      // let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
      // if(idx != -1){
      // if(this.apiService.roleId == '3')  {

      //    this.selectedFiler.property = this.propertyList[idx] 
      //    // this.getPropertyList()

      //  }
      //  else{

      //    this.selectedFiler.property = this.propertyList[idx] 
      //  }
      // }
    })
  }


  openTop(id) {
    (<HTMLInputElement>document.getElementById(id)).click();
  }

  getOpenList() {
    if (this.selectedFiler.dueDate) {
      this.selectedFiler.dueDate[0] = moment(this.selectedFiler.dueDate[0]).format('YYYY-MM-DD HH:mm:ss');
      this.selectedFiler.dueDate[1] = moment(this.selectedFiler.dueDate[1]).format('YYYY-MM-DD HH:mm:ss')
    }
    let body = {}
    if (localStorage.getItem("openTicketFilter") && localStorage.getItem("openTicketFilter") != null && localStorage.getItem("openTicketFilter") != 'null') {
      let openTicketFilter = JSON.parse(localStorage.getItem("openTicketFilter"));
      body = openTicketFilter;
      if (openTicketFilter.outletId && openTicketFilter.outletId != null && openTicketFilter.outletId != 'null') {
        this.selectedFiler.property = { id: openTicketFilter.outletId, outletName: openTicketFilter.frontOutletNameFilter }
        this.getUserFilyter();
      }
      if (openTicketFilter.departmentId && openTicketFilter.departmentId != null && openTicketFilter.departmentId != 'null') {
        this.selectedFiler.departmentId = { id: openTicketFilter.departmentId, name: openTicketFilter.frontDeparmentNameFilter }
      }
      if (openTicketFilter.userIdFilter && openTicketFilter.userIdFilter != null && openTicketFilter.userIdFilter != 'null') {
        this.selectedFiler.assignTo = { id: openTicketFilter.userIdFilter, fullName: openTicketFilter.frontUserNameFilter }
      }
      if (openTicketFilter.raisedBy && openTicketFilter.raisedBy != null && openTicketFilter.raisedBy != 'null') {
        this.selectedFiler.raisedBy = { id: openTicketFilter.raisedBy, fullName: openTicketFilter.frontRaisedUserNameFilter }
      }
      if (openTicketFilter.priority && openTicketFilter.priority != null && openTicketFilter.priority != 'null') {
        this.selectedFiler.priority = { id: openTicketFilter.priority, name: openTicketFilter.frontPriorityNameFilter }
      }
      if (openTicketFilter.origin && openTicketFilter.origin != null && openTicketFilter.origin != 'null') {
        this.selectedFiler.origin = { id: openTicketFilter.origin, name: openTicketFilter.frontOriginNameFilter }
      }
      this.selectedFiler.subject = openTicketFilter.subject
      this.selectedFiler.dueDate = openTicketFilter.dueDate
      this.selectedFiler.ticketId = openTicketFilter.ticketId
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage, limit: this.pageSize,
        businessId: this.apiService.buisnessId,
        // assetManagementId:this.assetId,
        subject: this.selectedFiler.subject,
        ticketId: this.selectedFiler.ticketId,
        dueDate: this.selectedFiler.dueDate,
        sortType: this.sortType,
        sortBy: this.sortBy,
        raisedBy: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.id : null,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        priorityId: this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
        userIdFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.id : null,
        origin: this.selectedFiler.origin ? this.selectedFiler.origin.id : null,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,

        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontDeparmentNameFilter: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.name : "",
        frontUserNameFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.fullName : "",
        frontRaisedUserNameFilter: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.fullName : "",
        frontOriginNameFilter: this.selectedFiler.origin ? this.selectedFiler.origin.name : "",
        frontPriorityNameFilter: this.selectedFiler.priority ? this.selectedFiler.priority.name : "",

        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
        internalCustomerFlag: 1,
      }
      localStorage.setItem('openTicketFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('tickets/openTickets', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.openTicket = result["data"]


      // result=10
      this.setPaginationArray(result)
      // console.log("Result is " + result)
    })
  }


  closedTicket = []

  getClosedTicekt() {

    // console.log("its a call")
    // this.authService.loader= true;
    let body = {}
    if (localStorage.getItem("closeTicketFilter") && localStorage.getItem("closeTicketFilter") != null && localStorage.getItem("closeTicketFilter") != 'null') {
      let closeTicketFilter = JSON.parse(localStorage.getItem("closeTicketFilter"));
      body = closeTicketFilter;
      if (closeTicketFilter.outletId && closeTicketFilter.outletId != null && closeTicketFilter.outletId != 'null') {
        this.selectedFiler.property = { id: closeTicketFilter.outletId, outletName: closeTicketFilter.frontOutletNameFilter }
        this.getUserFilyter();
      }
      if (closeTicketFilter.departmentId && closeTicketFilter.departmentId != null && closeTicketFilter.departmentId != 'null') {
        this.selectedFiler.departmentId = { id: closeTicketFilter.departmentId, name: closeTicketFilter.frontDeparmentNameFilter }
      }
      if (closeTicketFilter.userIdFilter && closeTicketFilter.userIdFilter != null && closeTicketFilter.userIdFilter != 'null') {
        this.selectedFiler.assignTo = { id: closeTicketFilter.userIdFilter, fullName: closeTicketFilter.frontUserNameFilter }
      }
      if (closeTicketFilter.raisedBy && closeTicketFilter.raisedBy != null && closeTicketFilter.raisedBy != 'null') {
        this.selectedFiler.raisedBy = { id: closeTicketFilter.raisedBy, fullName: closeTicketFilter.frontRaisedUserNameFilter }
      }
      if (closeTicketFilter.priority && closeTicketFilter.priority != null && closeTicketFilter.priority != 'null') {
        this.selectedFiler.priority = { id: closeTicketFilter.priority, name: closeTicketFilter.frontPriorityNameFilter }
      }
      if (closeTicketFilter.origin && closeTicketFilter.origin != null && closeTicketFilter.origin != 'null') {
        this.selectedFiler.origin = { id: closeTicketFilter.origin, name: closeTicketFilter.frontOriginNameFilter }
      }
      this.selectedFiler.subject = closeTicketFilter.subject
      this.selectedFiler.dueDate = closeTicketFilter.dueDate
      this.selectedFiler.ticketId = closeTicketFilter.ticketId
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePageClosed, limit: this.pageSizeClosed,
        businessId: this.apiService.buisnessId,
        // assetManagementId:this.assetId,
        subject: this.selectedFiler.subject,
        ticketId: this.selectedFiler.ticketId,
        dueDate: this.selectedFiler.dueDate,
        sortType: this.sortType,
        sortBy: this.sortBy,
        raisedBy: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.id : null,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        priorityId: this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
        userIdFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.id : null,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.id : null,
        origin: this.selectedFiler.origin ? this.selectedFiler.origin.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontDeparmentNameFilter: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.name : "",
        frontUserNameFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.fullName : "",
        frontRaisedUserNameFilter: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.fullName : "",
        frontOriginNameFilter: this.selectedFiler.origin ? this.selectedFiler.origin.name : "",
        frontPriorityNameFilter: this.selectedFiler.priority ? this.selectedFiler.priority.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
        internalCustomerFlag: 1,
      }
      localStorage.setItem('closeTicketFilter', JSON.stringify(body));
    }

    this.apiService.startLoader()
    this.apiService.postCall('tickets/closeTickets', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.closedTicket = result["data"]


      this.setPaginationArrayClosed(result)
    })

  }

  parkedTicket = []
  getParkedTicekt() {
    let body = {}
    if (localStorage.getItem("parkedTicketFilter") && localStorage.getItem("parkedTicketFilter") != null && localStorage.getItem("parkedTicketFilter") != 'null') {
      let parkedTicketFilter = JSON.parse(localStorage.getItem("parkedTicketFilter"));
      body = parkedTicketFilter;
      if (parkedTicketFilter.outletId && parkedTicketFilter.outletId != null && parkedTicketFilter.outletId != 'null') {
        this.selectedFiler.property = { id: parkedTicketFilter.outletId, outletName: parkedTicketFilter.frontOutletNameFilter }
        this.getUserFilyter();
      }
      if (parkedTicketFilter.departmentId && parkedTicketFilter.departmentId != null && parkedTicketFilter.departmentId != 'null') {
        this.selectedFiler.departmentId = { id: parkedTicketFilter.departmentId, name: parkedTicketFilter.frontDeparmentNameFilter }
      }
      if (parkedTicketFilter.userIdFilter && parkedTicketFilter.userIdFilter != null && parkedTicketFilter.userIdFilter != 'null') {
        this.selectedFiler.assignTo = { id: parkedTicketFilter.userIdFilter, fullName: parkedTicketFilter.frontUserNameFilter }
      }
      if (parkedTicketFilter.raisedBy && parkedTicketFilter.raisedBy != null && parkedTicketFilter.raisedBy != 'null') {
        this.selectedFiler.raisedBy = { id: parkedTicketFilter.raisedBy, fullName: parkedTicketFilter.frontRaisedUserNameFilter }
      }
      if (parkedTicketFilter.priority && parkedTicketFilter.priority != null && parkedTicketFilter.priority != 'null') {
        this.selectedFiler.priority = { id: parkedTicketFilter.priority, name: parkedTicketFilter.frontPriorityNameFilter }
      }
      if (parkedTicketFilter.origin && parkedTicketFilter.origin != null && parkedTicketFilter.origin != 'null') {
        this.selectedFiler.origin = { id: parkedTicketFilter.origin, name: parkedTicketFilter.frontOriginNameFilter }
      }
      this.selectedFiler.subject = parkedTicketFilter.subject
      this.selectedFiler.dueDate = parkedTicketFilter.dueDate
      this.selectedFiler.ticketId = parkedTicketFilter.ticketId
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePageParked, limit: this.pageSizeParked,
        businessId: this.apiService.buisnessId,
        // assetManagementId:this.assetId,
        subject: this.selectedFiler.subject,
        ticketId: this.selectedFiler.ticketId,
        dueDate: this.selectedFiler.dueDate,
        sortType: this.sortType,
        sortBy: this.sortBy,
        raisedBy: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.id : null,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        priorityId: this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
        userIdFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.id : null,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.id : null,
        origin: this.selectedFiler.origin ? this.selectedFiler.origin.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontDeparmentNameFilter: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.name : "",
        frontUserNameFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.fullName : "",
        frontRaisedUserNameFilter: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.fullName : "",
        frontOriginNameFilter: this.selectedFiler.origin ? this.selectedFiler.origin.name : "",
        frontPriorityNameFilter: this.selectedFiler.priority ? this.selectedFiler.priority.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
        internalCustomerFlag: 1,
      }
      localStorage.setItem('parkedTicketFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('tickets/parkedTickets', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.parkedTicket = result["data"]


      this.setPaginationArrayParked(result)
    });
  }

  notVadlid = []
  getnotValidTicket() {
    let body = {}
    if (localStorage.getItem("notValidTicketFilter") && localStorage.getItem("notValidTicketFilter") != null && localStorage.getItem("notValidTicketFilter") != 'null') {
      let notValidTicketFilter = JSON.parse(localStorage.getItem("notValidTicketFilter"));
      body = notValidTicketFilter;
      if (notValidTicketFilter.outletId && notValidTicketFilter.outletId != null && notValidTicketFilter.outletId != 'null') {
        this.selectedFiler.property = { id: notValidTicketFilter.outletId, outletName: notValidTicketFilter.frontOutletNameFilter }
        this.getUserFilyter();
      }
      if (notValidTicketFilter.departmentId && notValidTicketFilter.departmentId != null && notValidTicketFilter.departmentId != 'null') {
        this.selectedFiler.departmentId = { id: notValidTicketFilter.departmentId, name: notValidTicketFilter.frontDeparmentNameFilter }
      }
      if (notValidTicketFilter.userIdFilter && notValidTicketFilter.userIdFilter != null && notValidTicketFilter.userIdFilter != 'null') {
        this.selectedFiler.assignTo = { id: notValidTicketFilter.userIdFilter, fullName: notValidTicketFilter.frontUserNameFilter }
      }
      if (notValidTicketFilter.raisedBy && notValidTicketFilter.raisedBy != null && notValidTicketFilter.raisedBy != 'null') {
        this.selectedFiler.raisedBy = { id: notValidTicketFilter.raisedBy, fullName: notValidTicketFilter.frontRaisedUserNameFilter }
      }
      if (notValidTicketFilter.priority && notValidTicketFilter.priority != null && notValidTicketFilter.priority != 'null') {
        this.selectedFiler.priority = { id: notValidTicketFilter.priority, name: notValidTicketFilter.frontPriorityNameFilter }
      }
      if (notValidTicketFilter.origin && notValidTicketFilter.origin != null && notValidTicketFilter.origin != 'null') {
        this.selectedFiler.origin = { id: notValidTicketFilter.origin, name: notValidTicketFilter.frontOriginNameFilter }
      }
      this.selectedFiler.subject = notValidTicketFilter.subject
      this.selectedFiler.dueDate = notValidTicketFilter.dueDate
      this.selectedFiler.ticketId = notValidTicketFilter.ticketId
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePagenotValid, limit: this.pageSizenotValid,
        businessId: this.apiService.buisnessId,
        // assetManagementId:this.assetId,
        subject: this.selectedFiler.subject,
        ticketId: this.selectedFiler.ticketId,
        dueDate: this.selectedFiler.dueDate,
        sortType: this.sortType,
        sortBy: this.sortBy,
        raisedBy: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.id : null,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        priorityId: this.selectedFiler.priority ? this.selectedFiler.priority.id : null,
        userIdFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.id : null,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.id : null,
        origin: this.selectedFiler.origin ? this.selectedFiler.origin.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontDeparmentNameFilter: this.selectedFiler.departmentId ? this.selectedFiler.departmentId.name : "",
        frontUserNameFilter: this.selectedFiler.assignTo ? this.selectedFiler.assignTo.fullName : "",
        frontRaisedUserNameFilter: this.selectedFiler.raisedBy ? this.selectedFiler.raisedBy.fullName : "",
        frontOriginNameFilter: this.selectedFiler.origin ? this.selectedFiler.origin.name : "",
        frontPriorityNameFilter: this.selectedFiler.priority ? this.selectedFiler.priority.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
        internalCustomerFlag: 1,
      }
      localStorage.setItem('notValidTicketFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('tickets/notValidTickets', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.notValid = result["data"]


      this.setPaginationArraynotValid(result)
    });
  }

  jumpToOpen = 1
  jumpToClose = 1
  jumpToParked = 1
  jumpTonotValid = 1

  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }


  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }



  totalnoOfPagesClosed
  pageSizeClosed = 10
  activePageClosed: any = 1
  paginationArrayClosed = []
  setPaginationArrayClosed(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeClosed)
    this.totalnoOfPagesClosed = noOfPAges;
    this.paginationArrayClosed = []
    this.activePageClosed = parseInt(this.activePageClosed)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayClosed.push(i)
    else if (4 + this.activePageClosed < this.totalnoOfPagesClosed) {
      for (let i = this.activePageClosed; i <= 4 + this.activePageClosed; i++)this.paginationArrayClosed.push(i)
    }
    else if (4 + this.activePageClosed >= 6) {

      let start = (4 + this.activePageClosed) - noOfPAges
      for (let i = this.activePageClosed - start; i <= noOfPAges; i++)this.paginationArrayClosed.push(i)
    }
    // console.log(this.paginationArrayClosed)
  }


  totalnoOfPagesParked
  pageSizeParked = 10
  activePageParked: any = 1
  paginationArrayParked = []
  setPaginationArrayParked(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizeParked)
    this.totalnoOfPagesParked = noOfPAges;
    this.paginationArrayParked = []
    this.activePageParked = parseInt(this.activePageParked)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayParked.push(i)
    else if (4 + this.activePageParked < this.totalnoOfPagesParked) {
      for (let i = this.activePageParked; i <= 4 + this.activePageParked; i++)this.paginationArrayParked.push(i)
    }
    else if (4 + this.activePageParked >= 6) {

      let start = (4 + this.activePageParked) - noOfPAges
      for (let i = this.activePageParked - start; i <= noOfPAges; i++)this.paginationArrayParked.push(i)
    }
    // console.log(this.paginationArrayParked)
  }

  totalnoOfPagesnotValid
  pageSizenotValid = 10
  activePagenotValid: any = 1
  paginationArraynotValid = []
  setPaginationArraynotValid(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizenotValid)
    this.totalnoOfPagesnotValid = noOfPAges;
    this.paginationArraynotValid = []
    this.activePagenotValid = parseInt(this.activePagenotValid)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArraynotValid.push(i)
    else if (4 + this.activePagenotValid < this.totalnoOfPagesnotValid) {
      for (let i = this.activePagenotValid; i <= 4 + this.activePagenotValid; i++)this.paginationArraynotValid.push(i)
    }
    else if (4 + this.activePagenotValid >= 6) {

      let start = (4 + this.activePagenotValid) - noOfPAges
      for (let i = this.activePagenotValid - start; i <= noOfPAges; i++)this.paginationArraynotValid.push(i)
    }
    // console.log(this.paginationArraynotValid)
  }

  pagination(d) {
    localStorage.removeItem('openTicketFilter');
    localStorage.setItem("openTicketFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets = []
      this.activePage = d
      localStorage.setItem('ticketMainListPagination', d);
      // console.log(d)
      this.getOpenList()
    }
  }

  paginationOPen(d) {
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    if (d != 0 && d <= this.totalnoOfPagesnotValid && this.activePagenotValid != d) {
      // this.selectedAssets = []
      this.activePagenotValid = d
      localStorage.setItem('ticketMainListPagination', d);
      // console.log(d)
      this.getnotValidTicket()
    }
  }
  changePage(ev) {
    localStorage.removeItem('openTicketFilter');
    localStorage.setItem("openTicketFilter", null);
    // this.selectedAssets = []
    // this.pageSize = d
    this.getOpenList()
  }

  changePageOPen(ev) {
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    // console.log(this.pageSize)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getnotValidTicket()
  }


  paginationParked(d) {
    localStorage.removeItem('parkedTicketFilter');
    localStorage.setItem("parkedTicketFilter", null);
    if (d != 0 && d <= this.totalnoOfPagesParked && this.activePageParked != d) {
      // this.selectedAssets = []
      this.activePageParked = d
      localStorage.setItem('ticketMainListPagination', d);
      // console.log(d)
      this.getParkedTicekt()
    }
  }
  changePageParked(ev) {
    localStorage.removeItem('parkedTicketFilter');
    localStorage.setItem("parkedTicketFilter", null);
    // this.selectedAssets = []
    // this.pageSize = d
    this.getParkedTicekt()
  }


  paginationClosed(d) {
    localStorage.removeItem('closeTicketFilter');
    localStorage.setItem("closeTicketFilter", null);
    if (d != 0 && d <= this.totalnoOfPagesClosed && this.activePageClosed != d) {
      // this.selectedAssets = []
      this.activePageClosed = d
      localStorage.setItem('ticketMainListPagination', d);
      // console.log(d)
      this.getClosedTicekt()
    }
  }
  changePageClosed(ev) {
    localStorage.removeItem('closeTicketFilter');
    localStorage.setItem("closeTicketFilter", null);
    // this.selectedAssets = []
    // this.pageSize = d
    this.getClosedTicekt()
  }
  paginationnotValid(d) {
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPagesnotValid && this.activePagenotValid != d) {
      // this.selectedAssets = []
      this.activePagenotValid = d
      localStorage.setItem('ticketMainListPagination', d);
      // console.log(d)
      this.getnotValidTicket()
    }
  }
  changePagenotValid(ev) {
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    // console.log(this.pageSizenotValid)
    // this.selectedAssets = []
    // this.pageSize = d
    this.getnotValidTicket()
  }

  getSerialNoopen(i) {
    return this.pageSize * (this.activePage - 1) + i
  }
  getSerialNoclosed(i) {
    return this.pageSizeClosed * (this.activePageClosed - 1) + i
  }
  getSerialNoparked(i) {
    return this.pageSizeParked * (this.activePageParked - 1) + i
  }

  getSerialNonotValid(i) {
    return this.pageSizenotValid * (this.activePagenotValid - 1) + i
  }

  applyFilter() {
    localStorage.removeItem('openTicketFilter');
    localStorage.setItem("openTicketFilter", null);
    localStorage.removeItem('closeTicketFilter');
    localStorage.setItem("closeTicketFilter", null);
    localStorage.removeItem('parkedTicketFilter');
    localStorage.setItem("parkedTicketFilter", null);
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    this.applyFlag = true;
    // this.activePage= 1;
    // this.getPMsList()

    this.getParkedTicekt()
    this.getOpenList()
    this.getClosedTicekt()
    this.getnotValidTicket()
  }

  sortData(data) {
    localStorage.removeItem('openTicketFilter');
    localStorage.setItem("openTicketFilter", null);
    localStorage.removeItem('closeTicketFilter');
    localStorage.setItem("closeTicketFilter", null);
    localStorage.removeItem('parkedTicketFilter');
    localStorage.setItem("parkedTicketFilter", null);
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    if (this.tabSelected == 'openTicket') {
      this.getOpenList();
    }
    else if (this.tabSelected == 'parkedTicket') {
      this.getParkedTicekt();
    }
    else if (this.tabSelected == 'closedTicket') {
      this.getClosedTicekt();
    }
    else if (this.tabSelected == 'notValid') {
      this.getnotValidTicket();
    }
  }

  clear() {
    localStorage.removeItem('openTicketFilter');
    localStorage.setItem("openTicketFilter", null);
    localStorage.removeItem('closeTicketFilter');
    localStorage.setItem("closeTicketFilter", null);
    localStorage.removeItem('parkedTicketFilter');
    localStorage.setItem("parkedTicketFilter", null);
    localStorage.removeItem('notValidTicketFilter');
    localStorage.setItem("notValidTicketFilter", null);
    this.applyFlag = false;
    this.selectedFiler = { subject: null, ticketId: null, dueDate: null, business: null, property: null, priority: null, raisedBy: null, assignTo: null, departmentId: null, origin: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, }

    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx]
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
        // this.getPropertyList()

      }
      else {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
      }

    }
    this.activePage = 1;
    this.getParkedTicekt()
    this.getOpenList()
    this.getClosedTicekt()
    this.getnotValidTicket()
  }

  /*if ($val['qr_type'] == 2) {
    $queryResponse->setOrigin('QR Issue');
  } else if ($val['qr_type'] == 1 && $val['ticket_type'] == 2) {
    $queryResponse->setOrigin('Ad-Hoc Issue');
  } else if ($val['ticket_type'] == 4) {
    $queryResponse->setOrigin('DLF Issue');
  } else if ($val['meter_id'] > 0 || $val['meter_trigger_id'] > 0) {
    $queryResponse->setOrigin('Meter Issue');
  } else if ($val['ticket_type'] == 8) {
    $queryResponse->setOrigin('Asset Issue');
  } else if ($val['ticket_type'] == 9) {
    $queryResponse->setOrigin('PmChecklist');
  } else if ($val['ticket_type'] == 10) {
    $queryResponse->setOrigin('Meter Issue');
  } else {
    $queryResponse->setOrigin('Checklist Issue');
  }*/
  originList = [{ id: "3", name: "Ad-Hoc Tickets" }, { id: "10", name: "Meter Tickets" }, { id: "8", name: "Asset Tickets" }, { id: "9", name: "PmChecklist" }, { id: "3", name: "Checklist Tickets" }]



  priorityFilter: boolean = false;
  originFilter: boolean = false;
  departmentFilyter: boolean = false;
  getPriorityName(id) {
    let idx = this.priorityList.findIndex(x => x.id == id)

    if (idx != -1) return this.priorityList[idx].name
    else return 'Low'
  }

  getOutletName(id) {
    let idx = this.propertyListForIndex.findIndex(x => x.id == id)
    if (idx != -1) return this.propertyListForIndex[idx].outletName
  }
  priorityList = [{ id: 1, name: 'High' }, { id: 2, name: 'Medium' }, { id: 3, name: 'Low' }]
  filterSelect: boolean


  filterPropertySearch
  propertFilter = false;
  filterpropertyList = []
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }



  filterDepartmentSearch
  filterDepartment(ev) {
    // console.log(ev)
    this.departmentList = this.departmentListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  filterUserList = []
  filterUserSearch
  // propertFilter= false;
  filterUser(ev) {
    // console.log(ev)
    this.userList = this.filterUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }


  userFilter
  assigneFilter
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.userFilter = false;
      // this.locationFilter= false;
      this.businessFilter = false;
      this.propertFilter = false;
      this.priorityFilter = false;
      this.assigneFilter = false;
      this.departmentFilyter = false
    }
  }

  closeAll() {
    this.businessFilter = false;
    this.propertFilter = false;
    this.priorityFilter = false;
    this.userFilter = false;
    this.assigneFilter = false;
    this.departmentFilyter = false
  }

}

