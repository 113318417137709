import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-inspection-checklist-list',
  templateUrl: './inspection-checklist-list.component.html',
  styleUrls: ['./inspection-checklist-list.component.css']
})
export class InspectionChecklistListComponent implements OnInit {
  checkList: any;

  constructor(public _location: Location, public route: Router, public apiService: ApiService, public storedData: StoredData) { }


  duplicatePermission = false
  checklistAddPermission = false
  checklistViewPermission = false
  deactivatePermission = false
  ngOnInit(): void {

    var checklistPage = localStorage.getItem('checklistPage');
    if (checklistPage) {
      this.activePage = parseInt(checklistPage);
    }

    this.getInspectionChecklist()
    this.getBusinessList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 1);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Duplicate" && permission[i]['actionStatus'] == 1) {
        this.duplicatePermission = true
      }
      if (permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.checklistAddPermission = true
      }
      if (permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.checklistViewPermission = true
      }
      if (permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Deactivate" && permission[i]['actionStatus'] == 1) {
        this.deactivatePermission = true
      }
    }

  }



  sortData(data) {
    localStorage.removeItem("inspectionChecklistFilter");
    localStorage.setItem("inspectionChecklistFilter", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getInspectionChecklist()
  }



  activateSelected(d) {
    this.apiService.startLoader()
    this.statusSelection = null;
    let body = {
      // scheduleId:[d.id],
      isActive: 1,



    }
    this.apiService.postCall('inspections/edit/' + d.id, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      // this.scheduleList= result['data']
      this.apiService.stopLoader()
      this.message = "Checklist activated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getInspectionChecklist()
    })
  }
  message: any;
  deactivateSelectedItem(d) {
    this.apiService.startLoader()
    this.statusSelection = null;
    let body = {
      // scheduleId:[d.id],
      isActive: 0,
    }
    this.apiService.postCall('inspections/edit/' + d.id, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      // this.scheduleList= result['data']
      this.apiService.stopLoader();
      this.message = "Inspection checklist deactivated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getInspectionChecklist()
    })
  }
  applyFlag: boolean
  applyFilter() {
    localStorage.removeItem("inspectionChecklistFilter");
    localStorage.setItem("inspectionChecklistFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    this.getInspectionChecklist()
    // this.getInspectionMainListPrev()
  }

  statusSelection

  selectStatus(d) {

    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }


  clear() {
    localStorage.removeItem("inspectionChecklistFilter");
    localStorage.setItem("inspectionChecklistFilter", null);
    this.selectedFiler = { name: null, property: null, department: null, business: null }
    this.getInspectionChecklist()
    // this.getInspectionMainListPrev()

  }

  departmentFilter
  getFilterStatus() {

    let length = 0;
    if (this.selectedFiler.name != null && this.selectedFiler.name != "") length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.department != null) length = length + 1
    // console.log(this.selectedFiler)
    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }



  selectedFiler = { name: null, property: null, department: null, business: null }

  sortSelected = 'id';
  sort = 'desc'
  getInspectionChecklist() {
    this.apiService.startLoader()
    let body = {}
    if (localStorage.getItem("inspectionChecklistFilter") && localStorage.getItem("inspectionChecklistFilter") != null && localStorage.getItem("inspectionChecklistFilter") != 'null') {
      let inspectionChecklistFilter = JSON.parse(localStorage.getItem("inspectionChecklistFilter"));
      // console.log(inspectionChecklistFilter);
      body = inspectionChecklistFilter;
      if (inspectionChecklistFilter.outletId && inspectionChecklistFilter.outletId != null && inspectionChecklistFilter.outletId != 'null') {
        this.selectedFiler.property = { id: inspectionChecklistFilter.outletId, outletName: inspectionChecklistFilter.frontOutletNameFilter }
      }
      if (inspectionChecklistFilter.departmentId && inspectionChecklistFilter.departmentId != null && inspectionChecklistFilter.departmentId != 'null') {
        this.selectedFiler.department = { id: inspectionChecklistFilter.departmentId, name: inspectionChecklistFilter.frontDepaermentNameFilter }
      }
      this.selectedFiler.name = inspectionChecklistFilter.name
      this.applyFlag = true;
    } else {
      body = {
        sortType: this.sort,
        sortBy: this.sortSelected,
        pageNo: this.activePage,
        limit: this.pageSize,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
        name: this.selectedFiler.name,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "isActive": 1,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontDepaermentNameFilter: this.selectedFiler.department ? this.selectedFiler.department.name : "",
      }
      localStorage.setItem('inspectionChecklistFilter', JSON.stringify(body));
    }
    this.apiService.postCall('inspections/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.checkList = result['data']
      this.apiService.stopLoader()
      var jsonToBeUsed = [];
      this.setPaginationArray(result)
    })





  }

  jumpToPage = 1
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }

  totalnoOfPages
  activePage: any = 1
  pageSize = 10;
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  paginationArray = []

  pagination(d) {
    localStorage.removeItem("inspectionChecklistFilter");
    localStorage.setItem("inspectionChecklistFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.selectedAssets = []
      this.activePage = d
      // console.log(d)
      localStorage.setItem('checklistPage', d);
      this.getInspectionChecklist()
    }
  }
  changePage(ev) {
    localStorage.removeItem("inspectionChecklistFilter");
    localStorage.setItem("inspectionChecklistFilter", null);
    // console.log(this.pageSize)
    this.selectedAssets = []
    // this.pageSize = d
    this.getInspectionChecklist()
  }

  navigateToDublicate() {
    this.storedData.selectedCheckList = this.selectedAssetsDetailsList
    this.route.navigate(['/inspection-dublicate'])
  }

  selectedAssets = []
  selectedAssetsDetailsList = []
  selectAllAssets(ev) {
    this.selectedAssets = []
    if (ev.target.checked) {
      this.checkList.map(x => x.selected = true)
      for (let asset of this.checkList) {
        this.selectedAssets.push(asset.id)
        this.selectedAssetsDetailsList.push(asset)
      }
    }
    else {
      this.checkList.map(x => x.selected = false)
      this.selectedAssets = []
      this.selectedAssetsDetailsList = []
    }
    // console.log(this.selectedAssets)
  }
  selectAsset(ev, asset) {
    // console.log(ev.target.checked, asset)
    if (ev.target.checked) {
      this.selectedAssets.push(asset.id)
      this.selectedAssetsDetailsList.push(asset)
    }
    else {
      this.selectedAssets.splice(this.selectedAssets.findIndex(x => x == asset.id), 1)
      this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.id == asset.id), 1)
    }

    // console.log(this.selectedAssets)
  }

  // paginationArray=[]
  deactiveSelected() { 
    this.apiService.startLoader()
    let body = {
      inspectionIds: this.selectedAssets,
      isActive: 0,
    }
    this.apiService.postCall('inspections/changeToUnArchive', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      // this.scheduleList= result['data']
      this.apiService.stopLoader();
      this.message = "Inspection checklist deactivated successfully";
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.selectedAssets = [];
      this.getInspectionChecklist();
    })
  }
  dublicateSelected() { }
  // changePage
  // pageSize

  chnageStatus
  // message 
  departmentList

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
      // if(idx != -1 ){
      //   if(this.apiService.roleId == '2') {
      //     this.selectedFiler.business =  this.bussnissList[idx]
      //     this.getPropertyList()
      //     }
      //     else if(this.apiService.roleId == '3')  {
      //       // this.selectedFiler.business = this.bussnissList[idx] 
      //       // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //       this.getPropertyList()

      //     }
      //     else{
      //       // this.selectedFiler.business = this.bussnissList[idx] 
      //       // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //       this.getPropertyList()
      //     }
      //   }
      this.getPropertyList()
    })
  }

  filterDeptSearch
  filterDepartmentSearch() {
    this.departmentList = this.filterDepartment.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDeptSearch.toLowerCase())
      }
    })
  }

  filterDepartment
  filterPropertySearch
  filterProperty(ev) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/', {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      this.departmentList = result['departments']
      this.filterDepartment = result['departments']
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      if (idx != -1) {
        if (this.apiService.roleId == '3') {

          //  this.selectedFiler.property = this.propertyList[idx] 
          //  this.getUserList()

        }
        else {

          //  this.selectedFiler.property = this.propertyList[idx] 
        }
      }
    })
  }

  filterSelect: boolean
  businessFilter: boolean
  propertFilter: boolean
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      // this.assetFilter= false;
      this.assignedFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
    if (btn.id == 'changeButton') {
    } else if (btn.id != 'changeButton') {
      this.statusSelection = undefined;
    }
  }
  assignedFilter = false;
  assetFilter = false;
  closeAll() {
    this.assignedFilter = false;
    // this.assetFilter= false;
    this.businessFilter = false;
    this.propertFilter = false;
  }



}
