import { Component, OnInit,HostListener } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { StoredData } from '../common/services/storedData.service';
@Component({
  selector: 'app-property-locations',
  templateUrl: './property-locations.component.html',
  styleUrls: ['./property-locations.component.css']
})
export class PropertyLocationsComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public router: Router,
    public storedData:StoredData
  ) { }

  id:any
  locationList:any;
  activePage :any= 1;
  pageSize = 50;
  selectedFiler = { code: null, property:null, status:null }
  paginationArray = [];
  statusSelection:any;
  applyFlag=false;
  sortType='desc';
  sortBy='id';
  filterSelect = false;
  statusList = [
    { id:'0',name:'Deactive'},
    {id:'1',name:'Active'}
  ]
  sortSelected
  ngOnInit(): void {
    this.id = this.router.url.split("/")[2];
    this.getLocations();
    this.getDetailse()
  }
  propertyDetailse
  getDetailse(){
    this.apiService.startLoader()
    this.apiService.getCall('outlets/view/'+this.id, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.propertyDetailse = result['data'];
      }
    })
  }
  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      // console.log("Active Page :", d)
      this.getLocations()
    }
  }
  viewQr(d){
    this.storedData.qrData={name:d.name,image : d.qr_image, customerComplaintName:"" };
      this.router.navigate(['print-qr'])
  }
  viewLocationChecklist(d){
    this.router.navigateByUrl('/location-checklist-add/'+this.id+'/'+d.id);
  }
  goBack(){
    this.router.navigateByUrl('/property-details/'+this.id);
  }
  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)
    return array
  }

 totalnoOfPages;
  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'
    //this.getLocations();
  }
  getLocations():void{
    let body = {
      limit:this.pageSize,
      pageNo:this.activePage,
      sortBy:this.sortBy,
      sortType:this.sortType,
      outletId:this.id,
    };
    // console.log("Body :",body);
    this.apiService.startLoader()
    this.apiService.postCall('locations/locationList', {},body).subscribe((result: any[]) => {
      // console.log("Location :",result);
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.locationList = result['data'];
        // this.setPaginationArray(result)
      }
    })
  }

}
