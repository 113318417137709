import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';

@Component({
  selector: 'app-master-property',
  templateUrl: './master-property.component.html'
})
export class MasterPropertyComponent implements OnInit {
  tabSelected = 'zones';
  dropdownShow = false;
  constructor(private route: ActivatedRoute,public route2: Router) {

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
          if(params){
            if(params.filter){
              this.tabSelected = params.filter;
            }
          }else{
            this.tabSelected = 'zones';
          }
        });
  }

  go(){
    if(this.tabSelected == 'zones'){
      this.route2.navigate(['/master-property/zone-add']);
    }else if(this.tabSelected == 'states'){
      this.route2.navigate(['/master-property/states-add']);
    }else if(this.tabSelected == 'clusters'){
      this.route2.navigate(['/master-property/cluster-add']);
    }else if(this.tabSelected == 'districts'){
      this.route2.navigate(['/master-property/districts-add']);
    }else if(this.tabSelected == 'cities'){
      this.route2.navigate(['/master-property/cities-add']);
    }else if(this.tabSelected == 'sites'){
      this.route2.navigate(['/master-property/sites-add']);
    }else if(this.tabSelected == 'clients'){
      this.route2.navigate(['/master-property/client-add']);
    }
  }
  getName(){
    if(this.tabSelected == 'zones'){
      return "Add Zone";
    }else if(this.tabSelected == 'states'){
      return "Add State";
    }else if(this.tabSelected == 'clusters'){
      return "Add Cluster";
    }else if(this.tabSelected == 'districts'){
      return "Add District";
    }else if(this.tabSelected == 'cities'){
      return "Add City";
    }else if(this.tabSelected == 'sites'){
      return "Add Site Type";
    }else if(this.tabSelected == 'clients'){
      return "Add Clients";
    }
  }
}
