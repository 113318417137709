import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-consolidated-report',
  templateUrl: './consolidated-report.component.html',
  styleUrls: ['./consolidated-report.component.css']
})
export class ConsolidatedReportComponent implements OnInit {

  constructor(
    public route: Router,
    public apiService: ApiService,
    public storedData: StoredData,
    public _location: Location,
  ) { }

  selectedFiler = { schedule_name: null, outletId: null, endDate: null, startDate: null, userId: null, userIdFilter: null }
  fromDate: Date = new Date();
  toDate: Date = new Date();

  reportList = [];

  applyFlag = false;

  userList = [];
  filterUserList = []
  userFilter = false;
  userFilterSearch: any;

  filterSelect = false;

  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch: any

  ngOnInit(): void {
    // this.fromDate.setDate(this.fromDate.getDate() - 2);
    // this.toDate.setDate(this.toDate.getDate());
    // this.selectedFiler.startDate = this.fromDate;
    // this.selectedFiler.endDate = this.toDate;
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.applyFlag = true;
    this.getReport();
    this.getPropertyList();
  }
  openTop(id) {
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  getReport() {
    this.apiService.startLoader()
    let body = {
      userIdFilter: this.selectedFiler.userIdFilter ? this.selectedFiler.userIdFilter.id : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : this.fromDate,
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : this.toDate,
      outletId: this.selectedFiler.outletId ? this.selectedFiler.outletId.id : null,
      download: 0,
      schedule_name: this.selectedFiler.schedule_name,
      businessId: this.apiService.buisnessId,
      userId: this.apiService.authId,
    }
    this.apiService.postCall('TodaysChecklists/checklistsConsolidatedReport', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data']
      this.apiService.stopLoader()
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { schedule_name: null, outletId: null, endDate: null, startDate: null, userId: null, userIdFilter: null }
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getReport()
  }

  applyFilter() {
    this.applyFlag = true;
    this.getReport();
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.schedule_name != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.outletId != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.userIdFilter != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.outletId ? this.selectedFiler.outletId.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.userList = result['data']
      this.filterUserList = result['data']
      this.storedData.raisedByList = this.filterUserList;
    })
  }

  filterUser(ev: any) {
    this.userList = this.filterUserList.filter((item) => {
      if (item != undefined) {
        if (item.fullName != null) {
          return item.fullName.toLowerCase().includes(this.userFilterSearch.toLowerCase())
        }
      }
    })
  }
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      this.getUserList()
    })
  }

  filterProperty(ev: any) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  tmpsrc=''
  openImage(src){
    this.tmpsrc = src;
  }
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
    }
  }
  closeAll() {
    this.propertFilter = false;
  }

  downloadReport(): void {
    this.apiService.startLoader()
    let body = {
      userIdFilter: this.selectedFiler.userIdFilter ? this.selectedFiler.userIdFilter.id : null,
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : '',
      endDate: this.selectedFiler.endDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : '',
      outletId: this.selectedFiler.outletId ? this.selectedFiler.outletId.id : null,
      schedule_name: this.selectedFiler.schedule_name,
      businessId: this.apiService.buisnessId,
      userId: this.apiService.authId,
      download: 1,
    }
    this.apiService.postCall('TodaysChecklists/checklistsConsolidatedReport', {}, body).subscribe((result: any[]) => {
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `consolidated-report.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }
}
