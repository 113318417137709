import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-survey-dublicate',
  templateUrl: './survey-dublicate.component.html',
  styleUrls: ['./survey-dublicate.component.css']
})
export class SurveyDublicateComponent implements OnInit {

  constructor(public router: Router, public _location: Location, public storedData: StoredData, public apiService: ApiService) { }

  selectedSurvey = []
  type: any;
  message = ""
  dropdownSettings: IDropdownSettings;
  dropdownSettingsSchedules: IDropdownSettings;
  ngOnInit(): void {
    this.type = this.router.url.split("/")[1]
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'outletName',
      enableCheckAll: false,
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.dropdownSettingsSchedules = {
      singleSelection: true,
      idField: 'id',
      textField: 'outletName',
      enableCheckAll: false,
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.selectedSurvey = this.storedData.selectedSurveys;
    if (this.selectedSurvey) {
      this.selectedSurvey.map(d => d.selectedOutletArray = [])
      this.selectedSurvey.map(d => d.copyAll = false)
      this.selectedSurvey.map(d => d.selectingFlag = true)
    }
    this.getOutLetList()
  }

  removeUserPropertyt(d, d2) {
    let idx = d.selectedPropertyArray.findIndex(x => x.id == d2.id)
    if (idx != -1) d.selectedPropertyArray.splice(idx, 1)
  }
  propertyList = []
  getOutLetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
    })
  }

  onItemSelect(item: any, d) {
    d.selectingFlag = true;
    if (d.selectedOutletArray.indexOf(item) == -1) {
      d.selectedOutletArray.push(item)
      //console.log(d.selectedOutletArray);
    }
  }
  onItemDeSelect(item: any, d) {
    let idx = d.selectedOutletArray.findIndex(x => x.id == item.id)
    d.selectedOutletArray.splice(idx, 1)
    if (d.selectedOutletArray.length == 0) {
      d.selectingFlag = false;
    }
  }
  saveDublicateSurvey() {

    if (this.type == 'survey-dublicate') {
      let body = []
      for (let d of this.selectedSurvey) {
        let outletArry = []
        for (let d2 of d.selectedOutletArray) outletArry.push(d2.id)
        if (d.copyAll) {
          outletArry = []
          for (let d2 of this.propertyList) outletArry.push(d2.id)
        } else {
          if (outletArry.length == 0) {
            return false
          }
        }
        body.push({ "surveyId": d.id, "surveyName": d.surveyName, "outletId": outletArry,authId:this.apiService.authId })
      }

      this.apiService.startLoader()
      // console.log(body)

      this.apiService.postCall('feedbackQuestions/saveDuplicated', {}, body).subscribe((result: any[]) => {
        this.apiService.stopLoader()

        // console.log(result)
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();

      })
    }
  }

  getVlaidation() {
    if (this.type == 'survey-dublicate') {
      if (this.selectedSurvey) {
        let idx3 = JSON.stringify(this.selectedSurvey).search('"selectedOutletArray":[]')
        for (let d of this.selectedSurvey) {
          let outletArry = []
          for (let d2 of d.selectedOutletArray) outletArry.push(d2.id)
          if (outletArry.length == 0 && !d.copyAll) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    }
  }

  goBack(): void {
    this._location.back();
  }


}
