import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-migrate-asset',
  templateUrl: './migrate-asset.component.html',
  styleUrls: ['./migrate-asset.component.css']
})
export class MigrateAssetComponent implements OnInit {

  selectedAllLocation = null;
  constructor(public router: Router, public _location: Location, private fb: FormBuilder, public apiService: ApiService, public storedData: StoredData,) { }

  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false,
    dateType: 'start'
  }
  temporaryForm: FormGroup
  todayDate: any = new Date();

  tempTrnsferInfo: FormGroup
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  ngOnInit() {
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    document.body.style.overflow = 'hidden';
    this.getLocationList()
    $.getScript('../../assets/js/custom.js');

    //  console.log(this.storedData.selectedTransferArray,
    //   this.storedData.selectedTransferPermanentArray)

    this.storedData.selectedTransferPermanentArray.map(x => x.propertyNameNew = null)

    this.temporaryForm = this.fb.group({
      dot: [null, Validators.required],
      dor: [null, Validators.required],
      reason: [null, Validators.required],
      userNotify: [false],
      userSelected: [null]

    });

    this.tempTrnsferInfo = this.fb.group({
      dot: [null],
      dor: [null],
      reason: [],
      userNotify: [false],
      userSelected: []

    });
    this.getUSerList()
  }
  dot: any = new Date();
  transferType = false;
  dor: any = new Date();;
  backClicked() {
    this._location.back();
  }
  Mywindow
  openQr(d) {
    this.storedData.qrData = { name: d.assetName, image: d.QRCode, customerComplaintName:"" }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`print-qr`], { queryParams: { name: d.assetName, image: d.QRCode } })
    );

    window.open(url, '_blank');
    // this.Mywindow.close
  }
  userList = []

  getUSerList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.apiService.outletId,
    }

    this.apiService.startLoader()
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.userList = result['data']
    },
      error => {
        this.apiService.stopLoader()
      });

  }


  userArry = []
  removeUser(i) {
    this.userArry.splice(i, 1)
  }
  selectUser(d) {

    this.userArry.push(this.temporaryForm.value.userSelected)
    this.temporaryForm.patchValue({ userSelected: null })
  }



  transfer() {
    // console.log(this.transferType)
    let teamArray = []
    for (let i of this.userArry) teamArray.push(i.id)


    if (!this.transferType) {
      // console.log(this.temporaryForm.value, this.storedData.selectedTransferPermanentArray, this.transferType)
      let assetobj = []
      for (let d of this.storedData.selectedTransferPermanentArray) {
        assetobj.push({ assetManagementId: d.assetId, outletId: d.propertyId })

      }

      let obj = {
        type: 1,
        transferDate: this.temporaryForm.value.dot,
        returnDate: this.temporaryForm.value.dor,
        isNotify: this.temporaryForm.value.userNotify ? 1 : 0,
        notifyUsers: teamArray[0] ? teamArray : null,
        authId: this.apiService.userId,
        reason: this.temporaryForm.value.reason,
        transferArray: assetobj
      }


      // console.log(obj)
      this.apiService.startLoader()
      this.apiService.postCall('asset-transfers/add', {}, obj).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()

        this.message = result["msg"];



        (<HTMLInputElement>document.getElementById('openSuccess')).click();


      })
    }
    else {
      // console.log(this.temporaryForm.value, this.storedData.selectedTransferPermanentArray, this.transferType)
      let assetobj = []
      for (let d of this.storedData.selectedTransferPermanentArray) {
        assetobj.push({ assetManagementId: d.assetId, outletId: d.propertyId })
      }


      let obj = {
        type: 2,
        transferDate: this.temporaryForm.value.dot,
        returnDate: this.temporaryForm.value.dor,
        isNotify: this.temporaryForm.value.userNotify ? 1 : 0,
        notifyUsers: teamArray[0] ? teamArray : null,
        authId: this.apiService.userId,
        reason: "",
        transferArray: assetobj
      }


      // console.log(obj)
      this.apiService.startLoader()
      this.apiService.postCall('asset-transfers/add', {}, obj).subscribe((result: any[]) => {
        // console.log(result);
        this.message = result["msg"];


        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.apiService.stopLoader()
      })
    }
  }
  message: any;
  getNotifyUserName(id) {
    if (id) {
      let idx = this.userList.findIndex(x => x.id == id)
      if (idx != -1) return this.userList[idx].fullName
    }
  }

  tabSelected = 'step1'
  goToNext() {
    this.tabSelected = 'step2'
  }
  openPopup() {
    (<HTMLInputElement>document.getElementById('openSuccess')).click();
  }

  getLocationName(id) {
    if (id) return this.storedData.locationList.find(x => x.key == id).value
  }

  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId":this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetailsForm.value.propertyId?this.assetDetailsForm.value.propertyId.id : "",
      propertyId:0
    }

    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      result['data']

      var jsonToBeUsed = [];
      // console.log(jsonToBeUsed)
      this.locationList = result['data']
      this.storedData.locationList = this.locationList;

    })
  }

  getDisabledUser(id){
    if(this.userArry[0]){
      let idx = this.userArry.findIndex(x=> x.id == id)
      if(idx != -1) return true
      else return false
      }
  }

  selelctOutlet(d) {

    let idx = this.storedData.locationList.findIndex(x => x.outletName == d.propertyNameNew)
    d.propertyNameNew = this.storedData.locationList[idx].outletName
    d.propertyId = this.storedData.locationList[idx].id

    //this.storedData.selectedTransferPermanentArray.map(x => x.propertyNameNew = this.storedData.locationList[idx].outletName)
    //this.storedData.selectedTransferPermanentArray.map(x => x.propertyId = this.storedData.locationList[idx].id)
    // this.allLocation
  }


  selelctOutletAll(dl) {
    this.storedData.selectedTransferPermanentArray.map(x => x.propertyNameNew = dl.outletName)
    this.storedData.selectedTransferPermanentArray.map(x => x.propertyId = dl.id)

    this.allLocation = dl.selelctOutletAll

    // console.log(this.storedData.selectedTransferPermanentArray)
  }

  navigateToDetails() {

  }


  getValid() {

    if (!this.transferType && !this.temporaryForm.value.userNotify) return this.temporaryForm.valid && !this.validateDate(this.temporaryForm.value.dot, this.temporaryForm.value.dor);
    else if (!this.transferType && this.temporaryForm.value.userNotify) return this.temporaryForm.valid && !this.validateDate(this.temporaryForm.value.dot, this.temporaryForm.value.dor) && (this.userArry[0]);
    else if (this.transferType) {

      // console.log(JSON.stringify(this.storedData.selectedTransferPermanentArray).search('"propertyNameNew":null') != -1)
      let flag = (JSON.stringify(this.storedData.selectedTransferPermanentArray).search('"propertyNameNew":null') != -1)
      // console.log(flag)
      return !flag
    }
    else return true
  }


  allLocation = 'Select'


  validateDate(satrtDate, endDate) {
    // console.log(satrtDate, endDate)
    if (satrtDate && endDate) {
      if (endDate < satrtDate) return true
      else return false
    }
  }



  openQR(d) {
    this.storedData.qrData = { name: d.assetName, image: d.QRCode, customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }

}
