import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../common/services/apiCalls.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { StoredData } from '../../common/services/storedData.service';
import * as moment from 'moment';
@Component({
  selector: 'app-parts-logs',
  templateUrl: './parts-logs.component.html',
  styleUrls: ['./parts-logs.component.css']
})
export class PartsLogsComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData) { }

  partsId: any;
  partsDetails: any
  partsStatus= []
  partsVendorName= ''
  assetList: []
  tabSelected = 'Active Logs'
  selectedFiler = { entry: null, fileName: null, fileType: null, dueDate: null, uploadedBy: null }
  logsList = []
  filesList = []


  addFilePermission=false
  deleteFilePermission=false
  saveNotify=false

  ngOnInit(): void {
    this.apiService.startLoader()
    this.partsId = this.router.url.split("/")[2]
    this.apiService.getCall('parts/showDetails/' + this.partsId + "/" + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.partsDetails = result['part']
      this.partsStatus = result['statusList']
      let idx2 = this.partsStatus.findIndex(x=>x.name== "Breakdown")
      this.partsStatus.splice(idx2,1)
      
      // console.log(this.partsDetails.vendors);
      if (this.partsDetails.vendors && this.partsDetails.vendors.length > 0) {
        let vendors = this.partsDetails.vendors;
        if(this.partsDetails.vendors.length > 1)this.partsVendorName = "Multiple"
        else this.partsVendorName = this.partsDetails.vendors[0].name
      }
      this.apiService.stopLoader()
    })

    this.getPartLogs();
    this.getUserList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==5 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Add Log File" && permission[i]['actionStatus'] == 1){
        this.addFilePermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Delete Log File" && permission[i]['actionStatus'] == 1){
        this.deleteFilePermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Update Notify" && permission[i]['actionStatus'] == 1){
        this.saveNotify=true
      }
    }
  }

  getAssetDetail(){
    this.ngOnInit()
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }


  applyFlag = false
  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    this.getPartLogs()
    //this.getPMsList()

  }

  sortSelected: any = 'createdAt';
  sortType = 'desc'
  sortData(d) {
    this.sortSelected = d
    if (this.sortType == 'asc') this.sortType = 'desc';
    else this.sortType = 'asc'
    this.getPartLogs()
  }

  resultForPagi=[]
  totalnoOfPages
  logTypeList = []
  filterpropertyList = []
  getPartLogs() {
    this.apiService.startLoader()
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      partId: this.partsId,
      sortBy: this.sortSelected,
      sortType: this.sortType,
      entry: this.selectedFiler.entry,
      date: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:mm:ss') : null, 
      type: this.selectedFiler.fileType ? this.selectedFiler.fileType.id : null,
      createdBy: this.selectedFiler.uploadedBy ? this.selectedFiler.uploadedBy.id : null,
      fileName: this.selectedFiler.fileName,
      created_at: this.selectedFiler.dueDate ? moment(this.selectedFiler.dueDate).format('YYYY-MM-DD HH:mm:ss') : null, 
    };
    this.partsId = this.router.url.split("/")[2]
    this.apiService.postCall('parts/partLogs', {}, body).subscribe((result: any[]) => {
      this.logsList = result['dataWithOutFile']
      this.filesList = result['fileList']
      this.logTypeList = result['logType']
      this.filterpropertyList = result['logType']
      this.setPaginationArray(result);
      this.apiService.stopLoader()
      this.resultForPagi=result;
    })
  }
  paginationArray = []
  setPaginationArray(result) {
    let noOfPAges = 0;
    if (this.tabSelected == 'Active Logs') {
      noOfPAges = Math.ceil(result['logCounts'] / this.pageSize)
    } else {
      noOfPAges = Math.ceil(result['logFileCounts'] / this.pageSize)
    }
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    // console.log(this.paginationArray)
  }


  activePage = 1
  pageSize = 10
  pagination(data) {
    if (data != 0 && data <= this.paginationArray.length)
      if (data != this.activePage) {
        this.activePage = data
        this.getPartLogs()
      }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    this.getPartLogs();

  }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.entry != null) length = length + 1
    if (this.selectedFiler.fileType != null) length = length + 1
    if (this.selectedFiler.fileName != null) length = length + 1
    if (this.selectedFiler.dueDate != null) length = length + 1

    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  getLogText(id) {
    return this.logTypeList.find(x => x.id == id).name
  }

  filterUserSearch
  userFilterProperty(ev){
    // console.log(ev)
    this.userList = this.userList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  filterPropertySearch
  filterProperty(ev) {
    // console.log(ev)
    this.logTypeList = this.filterpropertyList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterUserList = []
  userFilter = false;
  userList
  getUserList(): void {
    this.apiService.startLoader()
    this.apiService.startLoader()
    this.apiService.getCall('users/user-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.userList = result['data'];
      this.filterUserList = result['data'];
      // console.log("List of Users", this.userList);

    })

  }
  filterSelect
  clear() {
    this.filterSelect = false;
    this.applyFlag = false;
    this.selectedFiler = { entry: null, fileName: null, fileType: null, dueDate: null, uploadedBy: null }
    this.getPartLogs()
  }


  uploadedFilesImage: any
  fileName: any;
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFilesImage = file
      this.fileName = file.name
    }
    let testData: FormData = new FormData();

    testData.append('image', this.uploadedFilesImage);
    testData.append("partId", this.partsDetails.partId);
    testData.append("authId", this.apiService.authId);
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.ngOnInit();
      this.fileName='';
      document.getElementById('dismissPopUp').click()

    })

  }

  message
  changeStatus() {
    let testData: FormData = new FormData();
    testData.append("status", this.partsDetails.status);
    testData.append("authId", this.apiService.authId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/changeStatus/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      document.getElementById('dismissPopUp').click()

    })
  }

  uploadedFiles
  uploadFile() {

    let testData: FormData = new FormData();
    testData.append('files', this.uploadedFiles);
    testData.append('entry', this.fileName);
    testData.append('partId', this.partsId);
    testData.append('authId', this.apiService.authId);
    testData.append('type', '4');

    // console.log(testData)
    this.apiService.startLoader()
    this.apiService.uploadCall('logs/add-file-entry', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = "File Uploaded Successfully";
      this.uploadedFiles = undefined;
      this.fileName = undefined;
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.getPartLogs()
    })
  }
  fileChangeLog(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFiles =file
        this.fileName = file.name
      }
}

deletLog:any;
deleteFile(){
  this.apiService.startLoader()
  let obj={
    'authId':this.apiService.authId
  }
  this.apiService.postCall('logs/delete/'+this.deletLog.logId,{} ,obj ).subscribe((result: any[])=>{
    // console.log(result);
    this.getPartLogs()
  })
}



  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      //     this.assetFilter= false;
      // this.locationFilter= false;
      // this.businessFilter= false;
      this.propertFilter = false;
      this.userFilter = false;
      // this.selectFreq = false
    }
  }
  closeAll() {
    // this.assetFilter= false;
    // this.locationFilter= false;
    // this.businessFilter= false;
    this.propertFilter = false;
    this.userFilter = false;
  }








}

