import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
// import { Input } from 'hammerjs';

@Component({
  selector: 'app-associate-part',
  templateUrl: './associate-part.component.html',
  styleUrls: ['./associate-part.component.css']
})
export class AssociatePartComponent implements OnInit {
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  @Input() status:any
  constructor(public router:Router ,public apiService: ApiService) { }

  assetId:any;
  locationId:any=null;
  locationHistoryList=[]
  locationList=[]
  ngOnInit(): void {
    this.assetId = this.router.url.split("/")[2]
    // this.assetId = 2;
    this.gertLocationHistory()
    // this.gertLocations()
    this.getAsset()
  }

  gertLocations(){
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
   
    this.apiService.postCall('locations/location-list/'+this.apiService.buisnessId,{} , obj ).subscribe((result: any[])=>{
      // console.log(result);

  //     var jsonToBeUsed = [];
    
  //   for (var type in result['data']) {
  //     let item = {};
  //     item['key'] = type;
  //     item['value'] = result['data'][type];
  //     jsonToBeUsed.push(item);
      
     
  // }

  // // console.log(jsonToBeUsed)
  this.locationList = result['data']
     
    })
  }

  assetDetails
  getAsset(){
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
      
  
     this.gertLocations()
     this.apiService.stopLoader()
    })
  }

  gertLocationHistory(){
    this.apiService.getCall('location-historys/index/'+this.assetId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.locationHistoryList=result['data']
    })

    
  }

  message;
  update(){
    let obj ={
      assetManagementId:this.assetId,
      locationId: parseInt(this.locationId),
      authId:this.apiService.userId
      }
this.apiService.startLoader()
// console.log(this.apiService.loader)
       this.apiService.postCall('asset-managements/editLocation', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.locationId=null;
      this.apiService.stopLoader();
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess1111'));
      (<HTMLInputElement>document.getElementById('dismissPopUp'));
      // document.getElementById('dismissPopUp')
this.gertLocationHistory()
this.updateData.emit()
this.apiService.stopLoader();
    })
  }


  ngOnDestroy() {
    // console.log('foo destroy')
    this.apiService.stopLoader()
  }
}
