import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-meters-list',
  templateUrl: './meters-list.component.html',
  styleUrls: ['./meters-list.component.css']
})
export class MetersListComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }

  addMeterPermission = false
  viewMeterPermission = true

  getIds(ev){
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property=ev.property
    if(ev.flag==1){
      this.clear()
    }else if(ev.flag==0){
      this.applyFilter()
    }
  
    // console.log(ev);
   
    
  }
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {

    var meterListPagination = localStorage.getItem('meterListPagination');
    if (meterListPagination) {
      this.activePage = parseInt(meterListPagination);
    }


    this.getMetersList()
    this.getBusinessList()
    // this.getpropertyList()
    this.getMainLocationList()
    this.getMianAssetList()

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Meter" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.addMeterPermission = true
      }
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  // let obj ={
  //   "roleId":this.apiService.roleId,
  //   "userId":this.apiService.userId,
  //   "businessId": this.apiService.buisnessId,
  //   "outletId":this.tiggerDetailsForm.value.property?this.tiggerDetailsForm.value.property : "",
  // }

  getMetersList() {
    this.apiService.startLoader()
    // this.applyFlag=false;
    let body = {}
    if (localStorage.getItem("localMeterFilter") && localStorage.getItem("localMeterFilter") != null && localStorage.getItem("localMeterFilter") != 'null') {
      let localMeterFilter = JSON.parse(localStorage.getItem("localMeterFilter"));
      body = localMeterFilter;
      if (localMeterFilter.outletId && localMeterFilter.outletId != null && localMeterFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localMeterFilter.outletId, outletName: localMeterFilter.frontOutletNameFilter }
      }
      if (localMeterFilter.assetId && localMeterFilter.assetId != null && localMeterFilter.assetId != 'null') {
        this.getAssetList();
        this.selectedFiler.assetName = { id: localMeterFilter.assetId, assetName: localMeterFilter.frontAssetNameDp }
      }
      this.selectedFiler.meterName = localMeterFilter.meterName
      if (localMeterFilter.locationId && localMeterFilter.locationId != null && localMeterFilter.locationId != 'null') {
        this.getLocationList();
        this.selectedFiler.location = { id: localMeterFilter.locationId, name: localMeterFilter.frontLocationNameFilter }
      }
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        meterName: this.selectedFiler.meterName,
        locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
        assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.id : null,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        // businessId :this.selectedFiler.business ?this.selectedFiler.business.id : null,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontLocationNameFilter: this.selectedFiler.location ? this.selectedFiler.location.name : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",

        zoneId:this.selectedFiler.zoneId,
        cluserId:this.selectedFiler.cluserId ,
        stateId :this.selectedFiler.stateId,
        cityId:this.selectedFiler.cityId,
        clientId:this.selectedFiler.clientId,
      }
      localStorage.setItem('localMeterFilter', JSON.stringify(body));
    }
    this.apiService.postCall('meters/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result)
      this.metersList = result['data']

      this.apiService.stopLoader()

    })
  }

  clear() {
    localStorage.removeItem('localMeterFilter');
    localStorage.setItem("localMeterFilter", null);
    localStorage.removeItem('meterListPagination');
    localStorage.setItem("meterListPagination", null);
    this.applyFlag = false;
    this.selectedFiler = { meterName: null, zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null,assetName: null, location: null, property: null, business: null }
    let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

    this.getMainLocationList()
    this.getMianAssetList()
    if (idx != -1 && idx1 != -1) {
      if (this.apiService.roleId == '2') {
        this.selectedFiler.business = this.bussnissList[idx]
        this.getPropertyList()
      }
      else if (this.apiService.roleId == '3') {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
        // this.getPropertyList()

      }
      else {
        this.selectedFiler.business = this.bussnissList[idx]
        // this.selectedFiler.property = this.propertyList[idx1] 
      }
    }

    this.getMetersList()
  }
  paginationArray = []

  pageSize = 10;
  activePage: any = 1

  totalnoOfPages
  changePage(ev) {
    localStorage.removeItem('localMeterFilter');
    localStorage.setItem("localMeterFilter", null);
    // console.log(this.pageSize)
    this.selectedAssets = []
    // this.pageSize = d
    this.getMetersList()
    window.scrollTo(0, 0);
  }
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    // console.log(noOfPAges <= 5)
    // console.log(4 + this.activePage < this.totalnoOfPages)
    // console.log(4 + this.activePage >= 6)

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  metersList = []
  selectedAssets = []


  getCustomName(d) {
    return 'Every ' + d.frequency_no + " " + d.frequency_unit
  }
  pagination(d) {
    localStorage.removeItem('localMeterFilter');
    localStorage.setItem("localMeterFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('meterListPagination', d);
      this.getMetersList()
    }
  }

  sortSelected: any = null
  sort: any = null

  sortData(data) {
    localStorage.removeItem('localMeterFilter');
    localStorage.setItem("localMeterFilter", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getMetersList()
  }


  selectedFiler = { meterName: null, assetName: null, location: null, property: null, business: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null, }

  locationList = []
  filterlocationList = []
  getLocationList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']
      this.filterlocationList = result['data']

      this.storedData.locationList = this.filterlocationList;

    })


  }

  getMainLocationList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']
      this.filterlocationList = result['data']

      this.storedData.locationList = this.filterlocationList;

    })


  }

  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
      let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      if (idx != -1) {
        if (this.apiService.roleId == '2') {
          this.selectedFiler.business = this.bussnissList[idx]
          this.getPropertyList()
        }
        else if (this.apiService.roleId == '3') {
          this.selectedFiler.business = this.bussnissList[idx]
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
          this.getPropertyList()

        }
        else {
          this.selectedFiler.business = this.bussnissList[idx]
          // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
          this.getPropertyList()
        }

      }
      else {
        this.getMainPropertyList()
      }

    })
  }


  filterLocationSearch: any
  filterLocation(ev) {
    // console.log(ev)
    this.locationList = this.filterlocationList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })

  }
  filterPropertySearch
  propertFilter = false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  filterBusinessSearch
  businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }


  applyFlag = false;
  applyFilter() {
    localStorage.removeItem('localMeterFilter');
    localStorage.setItem("localMeterFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    let body = {}
    if (localStorage.getItem("localMeterFilter") && localStorage.getItem("localMeterFilter") != null && localStorage.getItem("localMeterFilter") != 'null') {
      let localMeterFilter = JSON.parse(localStorage.getItem("localMeterFilter"));
      body = localMeterFilter;
      if (localMeterFilter.outletId && localMeterFilter.outletId != null && localMeterFilter.outletId != 'null') {
        this.selectedFiler.property = { id: localMeterFilter.outletId, outletName: localMeterFilter.frontOutletNameFilter }
      }
      if (localMeterFilter.assetId && localMeterFilter.assetId != null && localMeterFilter.assetId != 'null') {
        this.getAssetList();
        this.selectedFiler.assetName = { id: localMeterFilter.assetId, assetName: localMeterFilter.frontAssetNameDp }
      }
      this.selectedFiler.meterName = localMeterFilter.meterName
      if (localMeterFilter.locationId && localMeterFilter.locationId != null && localMeterFilter.locationId != 'null') {
        this.getLocationList();
        this.selectedFiler.location = { id: localMeterFilter.locationId, name: localMeterFilter.frontLocationNameFilter }
      }
      this.applyFlag = true;
    } else {
      body = {
        activePage: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        meterName: this.selectedFiler.meterName,
        locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
        assetId: this.selectedFiler.assetName ? this.selectedFiler.assetName.id : null,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        // businessId :this.selectedFiler.business ?this.selectedFiler.business.id : null,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontLocationNameFilter: this.selectedFiler.location ? this.selectedFiler.location.name : "",
        frontAssetNameDp: this.selectedFiler.assetName ? this.selectedFiler.assetName.assetName : "",
        zoneId:this.selectedFiler.zoneId,
        cluserId:this.selectedFiler.cluserId ,
        stateId :this.selectedFiler.stateId,
        cityId:this.selectedFiler.cityId,
        clientId:this.selectedFiler.clientId,
      }
      localStorage.setItem('localMeterFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('meters/index/' + this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.setPaginationArray(result)
      this.metersList = result['data']

      this.apiService.stopLoader()

    })
  }
  // sortBy:readingDue/unitName/propertyName/locationName/assetName/lastReading/frequency/meter_name
  // sortType:asc

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.assetName != null) length = length + 1
    if (this.selectedFiler.location != null) length = length + 1
    if (this.selectedFiler.meterName != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1
    // for(let d of this.selectedFiler)
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }


  assetList = []
  assetListFilter = []
  getAssetList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "statusNotIn": "5,8",
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']
      this.assetListFilter = result['data']
    })
  }

  getMianAssetList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      "statusNotIn": "5,8",
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList = []
      this.assetList = result['data']
      this.assetListFilter = result['data']
    })
  }

  filterAssetSearch
  // businessFilter= false;
  filterAsset(ev) {
    // console.log(ev)
    this.assetList = this.assetListFilter.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase())
      }
    })
  }


  navigateToDetails(d) {
    this.route.navigate(['/meter-detail/' + d.meterId])
  }

  deactiveSelected() { }
  dublicateSelected() { }
  selectAllAssets

  filterSelect = false;
  assetFilter = false;
  locationFilter = false;


  bussnissList = []
  bussnissListFilter = []
  propertyList = []
  filterpropertyList = []

  getPropertyList() {
    this.apiService.startLoader()
    // console.log(this.selectedFiler.business)
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      zoneId:this.selectedFiler.zoneId,
      cluserId:this.selectedFiler.cluserId ,
      stateId :this.selectedFiler.stateId,
      cityId:this.selectedFiler.cityId,
      clientId:this.selectedFiler.clientId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)




      //  this.selectedFiler.property = this.propertyList[idx] 
      this.getLocationList()
      this.getAssetList()



    })



  }

  jumpToPage
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }
  getMainPropertyList() {
    this.apiService.startLoader()
    // console.log(this.selectedFiler.business)
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
      zoneId:this.selectedFiler.zoneId,
      cluserId:this.selectedFiler.cluserId ,
      stateId :this.selectedFiler.stateId,
      cityId:this.selectedFiler.cityId,
      clientId:this.selectedFiler.clientId,
    }
    // this.apiService.getCall('outlets/outlet-list/'+this.selectedFiler.business.id,{}  ).subscribe((result: any[])=>{
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  getMianPropertyList() {
    this.apiService.startLoader()
    // console.log(this.selectedFiler.business)
    this.apiService.getCall('outlets/outlet-list/' + this.selectedFiler.business.id, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

      if (idx != -1) {
        if (this.apiService.roleId != '3' && this.apiService.roleId != '2' && this.apiService.roleId != '1') {

          this.selectedFiler.property = this.propertyList[idx]
          this.getLocationList()
          this.getAssetList()


        }
        //  else{

        //    this.selectedFiler.property = this.propertyList[idx] 
        //    this.getLocationList()
        //    this.getAssetList()
        //  }
      }



    })



  }

  callGetOutside() {
    // console.log("d")
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.assetFilter = false;
      this.locationFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      // this.selectFreq = false
    }
  }
  closeAll() {
    this.assetFilter = false;
    this.locationFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
  }


  exportAsPDF(div_id) {
    let data = document.getElementById(div_id);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }



  public downloadAsPDF() {

    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };

    pdfMake.createPdf(documentDefinition).download();

  }

  getValidation() {
    if (!this.uploadedFiles) {
      return false;
    }
    return true;
  }

  dropdownShow = false;
  uploadedFiles
  fileName
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }

  message = ""
  errorMessages = []
  uploadWarranty() {

    let testData: FormData = new FormData();
    if (!this.uploadedFiles) {
      return;
    }
    this.errorMessages = []
    testData.append('file', this.uploadedFiles);
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    this.apiService.startLoader()
    this.apiService.postCall('meters/bulkupload', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.uploadedFiles = null
      this.fileName = null
      this.clear()
    })
  }

}
