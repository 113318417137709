import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-users',
  templateUrl: './property-users.component.html',
  styleUrls: ['./property-users.component.css']
})
export class PropertyUsersComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public router: Router,
  ) { }


  id:any
  userList:any;
  activePage :any= 1;
  pageSize = 10;
  selectedFiler = { code: null, property:null, status:null }
  paginationArray = [];
  statusSelection:any;
  applyFlag=false;
  sortType='desc';
  sortBy='id';
  filterSelect = false;
  statusList = [
    { id:'0',name:'Deactive'},
    {id:'1',name:'Active'}
  ]
  sortSelected
  ngOnInit(): void {
    this.id = this.router.url.split("/")[2];
    this.getUsers();
    this.getDetailse();
  }
  propertyDetailse
  getDetailse(){
    this.apiService.startLoader()
    this.apiService.getCall('outlets/view/'+this.id, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :",result);
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.propertyDetailse = result['data'];
      }
    })
  }
  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      // console.log("Active Page :", d)
      this.getUsers()
    }
  }

  totalnoOfPages;
  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  
  changePage(ev) {
    this.getUsers()
  }

  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'
    this.getUsers();
  }
  getUsers():void{
    let body = {
      limit:this.pageSize,
      pageNo:this.activePage,
      sortBy:this.sortBy,
      sortType:this.sortType,
    };
    // console.log("Body :",body);
    this.apiService.startLoader()
    this.apiService.postCall('users/propertyUsers/'+this.id, {},body).subscribe((result: any[]) => {
      // console.log("Location :",result);
      this.apiService.stopLoader()
      if(result['success'] == true){
        this.userList = result['data'];
        this.setPaginationArray(result)
      }
    })
  }
  goBack(){
    this.router.navigateByUrl('/property-locations/'+this.id);
  }
}
