import { Component,HostListener, OnInit } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';
import { Router } from '@angular/router';
import { active } from 'd3';
@Component({
  selector: 'app-roles-index',
  templateUrl: './roles-index.component.html',
  styleUrls: ['./roles-index.component.css']
})
export class RolesIndexComponent implements OnInit {

  constructor(
    public route: Router,
    public apiService:ApiService,
    public storedData:StoredData
  ) { }
  showAddMenu=false;
// pagination Veriables
  paginationArray = [];
  activePage = 1;
  pageSize = 10;
  totalnoOfPages:any;
  
  pageSizeArchive = 10
  activePageArchive = 1
  paginationArrayArchive = []
  totalnoOfPagesArchive:any;
  saveData = false;
  message:any;
// array list veriables
  roleList: any;
  sort
// Sorting variables
  sortType = "desc";
  sortBy = 'id';
    
// Filter Object
  selectedFiler={name:null,city:null }
  applyFlag=false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role:any;

  tabSelected='active'; 
  statusSelection:any;

  ngOnInit(): void {
    this.role = this.apiService.role;
    
    var rolesIndexPagination = localStorage.getItem('rolesIndexPagination');
    if(rolesIndexPagination){
      this.activePage = parseInt(rolesIndexPagination);
    }

    this.getRoleList();
  }
  sortData(data){
      this.sortBy = data
      if(this.sortType == 'asc')this.sortType = 'desc'
      else this.sortType = 'asc'
  
      this.getRoleList()
   }
   getOrigionalType(type){
    if(type==1){
      return "Business Admin";
    }else if(type==2){
      return "Property Admin";
    }else{
      return "User";
    }
   }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.city != null) length = length + 1

    if(length == 0 || !this.applyFlag) return "(None Selected)"
    else if(this.applyFlag) return `(${length} Filter Selected)`
  }
  goBack(){
    this.route.navigate(['/setup']);
  }
  addRoles(){
    this.route.navigate(['/roles-add']);
  }

  setPaginationArray(result){
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log("Pagination Active : ",this.paginationArray)
  }

  applyFilter(){
    this.applyFlag=true;
    this.activePage= 1;
    this.getRoleList();
  }

  clear() {
    this.jumpToPage=1
    this.activePage=1
    this.selectedFiler = { name:null,city:null }
    this.getRoleList();
  }

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('rolesIndexPagination',d);
      // console.log("Active Page :", d)
      this.getRoleList()
    }
  }
  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)
    return array
  }
  changePage(ev) {
    this.getRoleList();
  }

  getRoleList(): void {
      const body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortBy:this.sortBy,
        sortType:this.sortType,
        "roleId":this.apiService.roleId,
        "userId":this.apiService.userId,
        "businessId": this.apiService.buisnessId,
      };
      this.apiService.startLoader()
      this.apiService.postCall('roles/index', {}, body).subscribe((result: any[]) => {
        // console.log(result);
        this.roleList = result['data'];
        this.apiService.stopLoader()
        this.setPaginationArray(result)
      })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    if(btn.id=="changeBtn"){
    }else if(btn.id!="changeBtn"){
      this.statusSelection =undefined
    }
    
  }

  changeStatus(id:any,status): void{
    let formData = {
      isActive : status,
      id : id,
    }
    this.apiService.startLoader()
    this.apiService.postCall('roles/changeStatus', {},formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.tabSelected = '';
      this.saveData = result['success'];
      this.getRoleList();
      if(this.saveData == true){
        this.message = 'Status Updated successfully.';
      } 
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }

  closeAll() {
    this.propertyFilter = false;
    this.roleFilter = false;
    this.businessFilter = false;
  }

  selectStatus(d){
    // console.log(d);
    if('status'+d.id != this.statusSelection ) this.statusSelection = 'status'+d.id
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }


  dropButton=false;
}
