import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-inspection-dublicate',
  templateUrl: './inspection-dublicate.component.html',
  styleUrls: ['./inspection-dublicate.component.css']
})
export class InspectionDublicateComponent implements OnInit {

  constructor(public router: Router, public _location: Location, public storedData: StoredData, public apiService: ApiService) { }

  checklist = []
  scheduleList = []
  type: any;
  message = ""
  dropdownSettings: IDropdownSettings;
  dropdownSettingsSchedules: IDropdownSettings;
  dropdownSettingsSchedulesUsers: IDropdownSettings;
  ngOnInit(): void {
    this.type = this.router.url.split("/")[1]
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'outletName',
      enableCheckAll: false,
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.dropdownSettingsSchedules = {
      singleSelection: true,
      idField: 'id',
      textField: 'outletName',
      enableCheckAll: false,
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.dropdownSettingsSchedulesUsers = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      enableCheckAll: false,
      //selectAllText: 'Select All',
      //unSelectAllText: 'UnSelect All',
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };
    // console.log(this.storedData.selectedCheckList)
    this.checklist = this.storedData.selectedCheckList;
    // console.log(this.checklist);
    this.scheduleList = this.storedData.selectedScheduleList;
    if (this.scheduleList) {
      this.scheduleList.map(x => x.selectedPropertyArray = [])
      this.scheduleList.map(x => x.propertySelected = null)
      this.scheduleList.map(d => d.floorPlanId = null)
      this.scheduleList.map(d => d.copyAll = false)
      this.scheduleList.map(d => d.selectingFlag = true)
      this.scheduleList.map(d => d.selectedScheduleUsertArray = [])
    }
    if (this.checklist) {
      this.checklist.map(d => d.selectedUsertArray = [])
      this.checklist.map(d => d.userSelected = null)
      this.checklist.map(d => d.departmentId = null)
      this.checklist.map(d => d.copyAll = false)
      this.checklist.map(d => d.selectingFlag = true)

    }

    this.getOutLetList()
  }



  getSelected(index, id) {
    //// console.log(this.scheduleList[index],id)
    let idx = this.scheduleList[index].selectedPropertyArray.findIndex(x => x.id == id)
    if (idx != -1) { return true } else { return false }
  }
  getSelected2(index, id) {
    //// console.log(this.checklist[index],id)
    let idx = this.checklist[index].selectedUsertArray.findIndex(x => x.id == id)
    if (idx != -1) { return true } else { return false }
  }

  removeUser(d, d2) {

    let idx = d.selectedUsertArray.findIndex(x => x.id == d2.id)
    if (idx != -1) d.selectedUsertArray.splice(idx, 1)

  }
  removeUserPropertyt(d, d2) {

    let idx = d.selectedPropertyArray.findIndex(x => x.id == d2.id)
    if (idx != -1) d.selectedPropertyArray.splice(idx, 1)
  }

  departmentList = []
  propertyList = []
  getOutLetList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
      // console.log(result)
      this.departmentList = result['departments']
    })

    let obj1 = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.apiService.outletId,
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.apiService.outletId, {}, obj1).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      // // console.log(jsonToBeUsed)
      this.userList = result['data']
    },
      error => {
        this.apiService.stopLoader()
      });
  }
  userList = []
  getUserListWithArray(result1, id,index) {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": result1,
      "onlyUsers": 1
    }

    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + result1, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.userList[id+'-'+index] = result['data']
    })
  }
  locationList = []
  getLocationList(propertyId, id,index) {
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/locations/location-list/:businessId/:outletId
    this.apiService.getCall('locations/location-list/' + this.apiService.buisnessId + '/' + propertyId, {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.locationList[id+'-'+index] = result['data']
    })
  }

  selectUser(d) {
    d.selectingFlag = false;

    d.selectedUsertArray.push(d.userSelected)
    d.userSelected = null

    setTimeout(() => {
      d.selectingFlag = true;

    }, 100);
  }
  // selectUserSchedule(d) {
  //   console.log(d);
  //   console.log(d.userId);
  //   d.selectedScheduleUsertArray.push(d.userId)
  // }
  // removeUserSchedule(d, d2) {
  //   let idx = d.selectedScheduleUsertArray.findIndex(x => x.id == d2.id)
  //   if (idx != -1) d.selectedScheduleUsertArray.splice(idx, 1)
  // }
  onItemSelect(item: any, d) {
    d.selectingFlag = true;
    if (d.selectedUsertArray.indexOf(item) == -1) {
      d.selectedUsertArray.push(item)
      //console.log(d.selectedUsertArray);
    }
  }
  onItemSelectSchedule(item: any, d,index) {
    d.propertySelected = item.id;
    this.getLocationList(item.id, d.id,index);
    this.getUserListWithArray(item.id, d.id,index);
    this.selecProperty1(d);
  }
  onItemDeSelectSchedule(item: any, d,index) {
    d.propertySelected = null;
  }
  // onItemSelectScheduleUser(item: any, d,index) {
  //   console.log(this.scheduleList);
  //   console.log(index);
  //   console.log(this.scheduleList[index]);
  //   if (this.scheduleList[index].selectedScheduleUsertArray.indexOf(item.id) == -1) {
  //     this.scheduleList[index].selectedScheduleUsertArray.push(item.id)
  //     console.log(this.scheduleList[index].selectedScheduleUsertArray);
  //   }
  // }
  // onItemDeSelectScheduleUser(item: any, d,index) {
  //   let idx = this.scheduleList[index].selectedScheduleUsertArray.findIndex(x => x.id == item.id)
  //   this.scheduleList[index].selectedScheduleUsertArray.splice(idx, 1)
  // }
  onItemDeSelect(item: any, d) {
    //console.log('onItemDeSelect', item);
    let idx = d.selectedUsertArray.findIndex(x => x.id == item.id)
    d.selectedUsertArray.splice(idx, 1)
    if (d.selectedUsertArray.length == 0) {
      d.selectingFlag = false;
    }
  }

  selecProperty(d) {
    d.selectingFlag = false;
    d.selectedPropertyArray.push(d.propertySelected)
    d.propertySelected = null
    // console.log(d)

    setTimeout(() => {
      d.selectingFlag = true;

    }, 100);

  }
  selecProperty1(d) {
    d.selectingFlag = false;
    d.selectedPropertyArray.push(d.propertySelected)
    //d.propertySelected= null
    // console.log(d)

    setTimeout(() => {
      d.selectingFlag = true;

    }, 100);

  }


  saveDublicateChecklist() {

    if (this.type == 'inspection-dublicate') {
      let body = []
      for (let d of this.checklist) {
        let outletArry = []
        for (let d2 of d.selectedUsertArray) outletArry.push(d2.id)
        if (d.copyAll) {
          outletArry = []
          for (let d2 of this.propertyList) outletArry.push(d2.id)
        } else {
          if (outletArry.length == 0) {
            return false
          }
        }
        body.push({ "inspectionId": d.id, "name": d.name, "outletId": outletArry, "userId": d.userId, "floorPlanId": d.floorPlanId })

      }

      this.apiService.startLoader()
      // console.log(body)

      this.apiService.postCall('inspections/saveDuplicated', {}, body).subscribe((result: any[]) => {
        this.apiService.stopLoader()

        // console.log(result)
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();

      })
    }

    else {

      let body = []
      for (let d of this.scheduleList) {
        // let outletArry=[]
        let outletArry = []
        if(d.propertySelected != null){
          outletArry.push(d.propertySelected)
        }
        if (d.copyAll) {
          outletArry = []
          for (let d2 of this.propertyList) outletArry.push(d2.id)
        } else {
          if (outletArry.length == 0) {
            alert('Please Select All Fields')
            return false
          }
        }
        let userIdArry = [];
        if(d.selectedScheduleUsertArray.length == 0){
          alert('Please Select All Fields')
          return false
        }else{
          for (let d1 of d.selectedScheduleUsertArray) userIdArry.push(d1.id);
        }
        body.push({ "scheduleId": d.id, "scheduleName": d.scheduleName, "outletId": outletArry, "floorPlanId": d.floorPlanId, "userId": userIdArry })

      }

      // console.log(body)

      this.apiService.startLoader()
      this.apiService.postCall('schedules/saveDuplicated', {}, body).subscribe((result: any[]) => {
        this.apiService.stopLoader()

        // console.log(result)
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();

      })
    }
  }
  AddMoreNew(d) {
    this.scheduleList.push({ id: d.id, inspectionId: d.inspectionId, inspectionName: d.inspectionName, scheduleName: d.scheduleName, scheduleType: d.scheduleType, assignTo: '', assignToName: '', continueDays: d.continueDays, copyAll: d.copyAll, created: d.created, customType: d.customType, displayInTime: d.displayInTime, endDate: d.endDate, endTime: d.endTime, floorPlanId: d.floorPlanId, groupName: d.groupName, isActive: d.isActive, isAdhoc: d.isAdhoc, isInfinite: d.isInfinite, isLogo: d.isLogo, isMultiple: d.isMultiple, isMultipleTimes: d.isMultipleTimes, isReminder: d.isReminder, modified: d.modified, outletId: d.outletId, outletName: d.outletName, propertySelected: d.propertySelected, qrAllow: d.qrAllow, qrAllowGroupFlag: d.qrAllowGroupFlag, qrCode: d.qrCode, randomPhoto: d.randomPhoto, remMeasure: d.remMeasure, reminderDate: d.reminderDate, reminderTime: d.reminderTime, repeatDays: d.repeatDays, selected: d.selected, selectedPropertyArray: d.selectedPropertyArray, selectedScheduleUsertArray: [], selectingFlag: d.selectingFlag, startDate: d.startDate, startTime: d.startTime, userId: '', userName: '', weekFlag: d.weekFlag })
  }

  getVlaidation() {
    //// console.log(this.type,this.checklist)
    if (this.type == 'inspection-dublicate') {
      if (this.checklist) {
        let idx1 = JSON.stringify(this.checklist).search('"departmentId":null')
        let idx3 = JSON.stringify(this.checklist).search('"selectedUsertArray":[]')
        for (let d of this.checklist) {
          let outletArry = []
          for (let d2 of d.selectedUsertArray) outletArry.push(d2.id)
          if (outletArry.length == 0 && !d.copyAll) {
            return true
          }

        }
        if (idx1 != -1) return true
        else return false
      } else {
        return true
      }
    } else {
      if (this.scheduleList) {
        let idx = JSON.stringify(this.scheduleList).search('"floorPlanId":null')
        let idx1 = JSON.stringify(this.scheduleList).search('"departmentId":null')
        for (let d of this.scheduleList) {
          // console.log(d.propertySelected);
          let outletArry = []
          outletArry.push(d.propertySelected)
          if (outletArry.length == 0 && !d.copyAll) {
            return true
          }
        }
        if (idx != -1) return true
        else return false
      } else {
        return true
      }
    }
  }

  goBack(): void {
    this._location.back();
  }


}
