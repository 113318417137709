import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
declare var require: any;

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';

const htmlToPdfmake = require('html-to-pdfmake');
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lost-and-found-lost-articles',
  templateUrl: './found-article-index-list.component.html',
  styleUrls: ['./found-article-index-list.component.css']
})
export class FoundArticleIndexListComponent implements OnInit {
  tabSelected = 'fondArticles';
  // addPermission = false;
  // editPermission = false;
  todaysDate = new Date();
  constructor(public route: Router, public apiService: ApiService, public router: Router, public storedData: StoredData) {
    let permission = [];
    // permission = JSON.parse(localStorage.getItem('permissions'));
    // permission = permission.filter(item => item['permissionModuleId'] == 9);
  }
  FoundArticleComplaintsFilter = false;
  ngOnInit(): void {

    var foundArticlePagination = localStorage.getItem('foundArticlePagination');
    if (foundArticlePagination) {
      this.activePage = parseInt(foundArticlePagination);
    } else {
      this.activePage = 1
    }


    this.getList();

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {

    }
  }
  foundArticleNoList = [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }];
  foundArticleNoFilter: boolean
  requestList = []
  getList() {
    this.apiService.startLoader();
    this.workList = []
    let body = {}
    if (localStorage.getItem("FoundArticleComplaintsFilter") && localStorage.getItem("FoundArticleComplaintsFilter") != null && localStorage.getItem("FoundArticleComplaintsFilter") != 'null') {
      let FoundArticleComplaintsFilter = JSON.parse(localStorage.getItem("FoundArticleComplaintsFilter"));
      body = FoundArticleComplaintsFilter;
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortBy: this.sortSelected,
        sortType: this.sort,
        userId: this.apiService.userId,
        businessId: this.apiService.buisnessId,
        articleFoundFlag: this.selectedFiler.articleFoundFlag ? this.selectedFiler.articleFoundFlag.name : ""
      };
      localStorage.setItem('FoundArticleComplaintsFilter', JSON.stringify(body));
    }
    this.apiService.postCall('LostFounds/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];
      this.apiService.stopLoader();

    });
  }
  downloadReport(): void {
    this.apiService.startLoader();
    let body = {
      detailsArticleFound: this.selectedFiler.detailsArticleFound ? this.selectedFiler.detailsArticleFound : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : "",
      //foundArticleNo: this.selectedFiler.foundArticleNo.name,
      articleFoundFlag: this.selectedFiler.articleFoundFlag ? this.selectedFiler.articleFoundFlag.name : "",
      limit: this.pageSize,
      pageNo: this.activePage,
      sortBy: this.sortSelected,
      sortType: this.sort,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      download: 1
    };
    this.apiService.postCall('LostFounds/index', {}, body).subscribe((result: any[]) => {
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `FoundArticleLists.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader();
    });
  }
  clear() {
    localStorage.removeItem('FoundArticleComplaintsFilter');
    localStorage.setItem("FoundArticleComplaintsFilter", null);
    localStorage.removeItem('foundArticlePagination');
    localStorage.setItem("foundArticlePagination", null);
    this.activePage = 1
    this.pageSize = 10
    this.sortSelected = 'id'
    this.sort = 'desc'
    this.applyFlag = false;
    this.selectedFiler = { detailsArticleFound: null, startDate: null, endDate: null, businessId: null, articleFoundFlag: { id: 'No', name: 'No' } };
    this.getList();
  }

  paginationArray = [];
  message
  pageSize = 10;
  activePage: any = 1

  totalnoOfPages;

  changePage(ev) {
    localStorage.removeItem('FoundArticleComplaintsFilter');
    localStorage.setItem("FoundArticleComplaintsFilter", null);
    this.selectedAssets = [];
    // this.pageSize = d
    this.getList();
    window.scrollTo(0, 0);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize);
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = [];
    // console.log(noOfPAges <= 5);
    // console.log(4 + this.activePage < this.totalnoOfPages);
    // console.log(4 + this.activePage >= 6);

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray);
  }

  workList = [];
  selectedAssets = [];


  getCustomName(d) {
    return 'Every ' + d.frequency_no + ' ' + d.frequency_unit;
  }

  pagination(d) {
    localStorage.removeItem('FoundArticleComplaintsFilter');
    localStorage.setItem("FoundArticleComplaintsFilter", null);
    // console.log(d);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d;
      localStorage.setItem('foundArticlePagination', d);
      this.getList();
    }
  }

  sortSelected: any = null;
  sort: any = null;

  sortData(data) {
    localStorage.removeItem('FoundArticleComplaintsFilter');
    localStorage.setItem("FoundArticleComplaintsFilter", null);
    this.sortSelected = data;
    if (this.sort == 'asc') {
      this.sort = 'desc';
    } else {
      this.sort = 'asc';
    }

    this.getList();
  }


  selectedFiler = { detailsArticleFound: null, startDate: null, endDate: null, businessId: null, articleFoundFlag: { id: 'No', name: 'No' } };



  applyFlag = false;
  applyFilter() {
    localStorage.removeItem('FoundArticleComplaintsFilter');
    localStorage.setItem("FoundArticleComplaintsFilter", null);
    this.workList = []
    this.applyFlag = true;
    this.activePage = 1;
    let body = {}
    body = {
      detailsArticleFound: this.selectedFiler.detailsArticleFound ? this.selectedFiler.detailsArticleFound : "",
      startDate: this.selectedFiler.startDate ? moment(this.selectedFiler.startDate).format('YYYY-MM-DD') : "",
      endDate: this.selectedFiler.startDate ? moment(this.selectedFiler.endDate).format('YYYY-MM-DD') : "",
      //foundArticleNo: this.selectedFiler.foundArticleNo.name,
      articleFoundFlag: this.selectedFiler.articleFoundFlag ? this.selectedFiler.articleFoundFlag.name : "",
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
    };
    this.apiService.startLoader();
    this.apiService.postCall('LostFounds/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.setPaginationArray(result);
      this.workList = result['data'];
      this.apiService.stopLoader();

    });
  }


  getFilterStatus() {
    let length = 0;
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  jumpToPage;

  getArray(d) {
    let array = [];
    for (let i = 1; i <= d; i++) {
      array.push(i);
    }

    return array;
  }
  callGetOutside() {
    // console.log('d');
  }
  filterSelect = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false;
      this.FoundArticleComplaintsFilter = false;
    }
  }

  closeAll() {
    // this.businessFilter = false;
    this.FoundArticleComplaintsFilter = false;
  }

}
