import { Component,HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-survey',
  templateUrl: './employee-survey.component.html',
  styleUrls: ['./employee-survey.component.css']
})
export class EmployeeSurveyComponent implements OnInit {

  constructor(
    public _location: Location,
    public storedData: StoredData,
    public router: Router,
    private http: HttpClient,
    public route: Router,
    public apiService: ApiService,
  ) { }

  tabSelected = 'survey';
  baseUrl = window.location.origin;
  paginationArray = [];
  activePage: any = 1;
  pageSize = 10;
  showFilter = false;

  sortType = 'desc';
  // sortBy = 'id';

  search: any;
  selectedFiler = { buisness: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null,property: null, survayName: null, type: null, status: null ,startDate : null,ensDate : null}

  survayArray = [];
  // propertyList = [];
  locationList = [];
  surveyId
  message
  typeList = [{ id: 0, name: 'Single-Question' }, { id: 1, name: 'Multi-Question' },]

  addPermission = false
  editPermission = false
  viewPermission = false
  viewResponsePermission = false
  printQRCodePermission = false
getIds(ev){
  this.selectedFiler.zoneId = ev.zoneId
  this.selectedFiler.cluserId = ev.clusterId
  this.selectedFiler.stateId = ev.stateId
  this.selectedFiler.cityId = ev.cityId
  this.selectedFiler.clientId = ev.clientId
  this.selectedFiler.property=ev.property
  if(ev.flag==1){
    this.clear()
  }else if(ev.flag==0){
    this.applyFilter()
  }

  // console.log(ev);
}
  ngOnInit(): void {
    this.getSurveyList();
  }

  copyToClipboard(element) {
    // console.log(element);
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(element.barcode).select();
    document.execCommand("copy");
    $temp.remove();
    this.storedData.qrData = { name: element.name, image: element.barcode,customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }
  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    let length = JSON.stringify(this.selectedFiler).split(null).length - 1

    if (length == 12 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${12 - length} Filter Selected)`
  }


  // activePage=1
  totalnoOfPages
  //  pageSize=10
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    // console.log(noOfPAges, this.totalnoOfPages)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  changePage() {
    this.getSurveyList();
  }
  sortSelected: any = 'id';
  sortData(d) {
    // localStorage.removeItem('localServyFilter');
    // localStorage.setItem("localServyFilter", null);
    // this.sortSelected = d
    // if (this.sortType == 'asc') this.sortType = 'desc';
    // else this.sortType = 'asc'
    // this.getSurveyList()
  }
  pagination(d) {
    localStorage.removeItem('localServyFilter');
    localStorage.setItem("localServyFilter", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      // this.selectedAssets=[]
      this.activePage = d
      localStorage.setItem('surveyIndexPagination', d);
      // console.log(d)
      this.getSurveyList()
    }
  }


  filterSurvayLst = []
  searchText = ""
  checkexist() {
    this.survayArray = this.filterSurvayLst.filter((item) => {
      item.outletName = item.outletName != null ? item.outletName : '';
      item.feedbackLocationName = item.feedbackLocationName != null ? item.feedbackLocationName : '';
      item.surveyName = item.surveyName != null ? item.surveyName : '';
      return item.outletName.toLowerCase().includes(this.searchText.toLowerCase()) || item.feedbackLocationName.toLowerCase().includes(this.searchText.toLowerCase()) || item.surveyName.toLowerCase().includes(this.searchText.toLowerCase())
    })
  }

  getSurveyList() {
    let body = {}
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortSelected,
        businessId : this.apiService.buisnessId
    }
    this.apiService.startLoader()
    this.apiService.postCall('EmpSurveys/index/'+this.apiService.buisnessId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.survayArray = result["data"];
      this.setPaginationArray(result);
      this.apiService.stopLoader()
    })
   
  }

  clear() {
    this.jumpToParked = 1
    this.activePage = 1
    this.selectedFiler = { buisness: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null, property: null, survayName: null, type: null, status: null,startDate : null,ensDate : null }
    this.getSurveyList()
  }

  applyFlag = false;
  applyFilter() {
    localStorage.removeItem('localServyFilter');
    localStorage.setItem("localServyFilter", null);
    this.applyFlag = true;
    // console.log(this.selectedFiler)
    this.activePage = 1;
    this.getSurveyList()
    // this.activePageTransit = 1;
    // if(this.tabSelected == 'allasset'){
    //   this.getAssets();
    // }else{
    //   this.getTransitList();
    // }
  }

  statusSelection: any;

  selectStatus(d) {

    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }

  getFeedbackList(d) {
    // if(this.tabSelected != 'feedback'){
    //   this.tabSelected == 'feedback';
    // }
    this.route.navigate(['/feedback-index/' + d.id]);
  }
  goToAddSurvey() {
    this.route.navigate(['/survey-index']);
  }

  goToEmployeeSurveyAdd(){
    this.route.navigate(['/employee-survey-add']);
  }
  viewSurvey(data) {
    this.route.navigate(['/employee-survey-view/'+data.id]);
  }
  editSurvey(data) {
    // console.log(data.name);
    localStorage.setItem("empSurveyName",data.name);
    this.route.navigate(['/employee-survey-edit/'+data.id]);
  }
  gotToFeedback(url) {
    let id = encodeURIComponent(url);
    // console.log("URL Encoded :", id);

    window.open('/customer-feedback/' + id, '_blank');

    // this.route.navigateByUrl('/customer-feedback/'+id);
  }

  gotToFeedbackView(url) {

    let id = encodeURIComponent(url);
    // console.log("URL Encoded :", id);

    this.route.navigateByUrl('/customer-feedback-view/' + id);
  }

  // selectedFiler={}

  businessFilter
  showAddMenu: boolean;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.showFilter = false
      this.propertFilter = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.showAddMenu = false;
      this.businessFilter = false;
      // // this.selectFreq = false
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertFilter = false;
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
      // this.selectFreq = false
    }
    if (btn.id == 'changeBUton') {
    } else if (btn.id != 'changeBUton') {
      this.statusSelection = undefined
    }
  }
  typeFilter = false;
  statsuFilter = false;
  closeAll() {
    this.propertFilter = false;
    // this.statsuFilter = false;
    this.typeFilter = false;
    this.businessFilter = false;
  }

  filterBusinessSearch
  // businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    //this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      //this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }


  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    let businessId = 0;
    let userId = 0;
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.buisness ? this.selectedFiler.buisness.id : 0;
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId
      userId = this.apiService.userId
    }

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": businessId,
      zoneId:this.selectedFiler.zoneId,
      cluserId:this.selectedFiler.cluserId ,
      stateId :this.selectedFiler.stateId,
      cityId:this.selectedFiler.cityId,
      clientId:this.selectedFiler.clientId,
    }

    //this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + businessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      //this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  filterPropertySearch
  propertFilter = false;
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  getUrl(url) {
    return url.substring(0, 25) + "...";
  }
  openQR(d) {
    this.storedData.qrData = { name: d.surveyName, image: d.qrCode, customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }


  jumpToParked
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }
}
