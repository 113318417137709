import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-recived-inventory-expense',
  templateUrl: './recived-inventory-expense.component.html',
  styleUrls: ['./recived-inventory-expense.component.css']
})
export class RecivedInventoryExpenseComponent implements OnInit {

  constructor(
    public route: Router, 
    public apiService: ApiService, 
    public storedData: StoredData,
    public _location: Location,
  ) { }
  
  selectedFiler = { partId: null,partName:null, assets: null,department:null, location: null, property: null,endDate:null,startDate:null,number:null }
  fromDate:Date = new Date();
  toDate:Date = new Date();
  ngOnInit(): void {
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.applyFlag = true;
    this.getStockListing();
    this.getPropertyList();
  }
  goBack(){
    this.route.navigate(['/reports'])
  }
  getStockListing() {
    this.apiService.startLoader()

    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      partId: this.selectedFiler.partId ? this.selectedFiler.partId : null,
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate :this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
      assetId: this.selectedFiler.assets ? this.selectedFiler.assets.id : null,
      typeId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
      download: 0,
      number: this.selectedFiler.number,
      partName:this.selectedFiler.partName,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('parts/getExpenses', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.metersList = result['data']
      this.apiService.stopLoader()

    })
  }
  departmentList=[]
  filterdepartmentList=[]
  
  filterDepartmentSearch
  filterdepartment(ev) {
    // console.log(ev)
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { partId: null,partName:null,assets: null, location: null,department:null, property: null,endDate:this.toDate,startDate:this.fromDate,number:null }
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)

    this.getStockListing()
  }
  paginationArray = []

  pageSize = 10;
  activePage: any = 1

  totalnoOfPages
  changePage(ev) {
    // console.log(this.pageSize)
    this.selectedAssets = []
    this.getStockListing()
    window.scrollTo(0, 0);
  }
  metersList = []
  selectedAssets = []

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      this.getStockListing()
    }
  }

  sortSelected: any = null
  sort: any = null

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getStockListing()
  }


  locationList = []
  filterlocationList = []

  filterLocationSearch: any

  filterPropertySearch
  propertFilter = false;

  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    this.getStockListing();
  }

  getFilterStatus() {
   
    let length = 0;
    if (this.selectedFiler.partId != null) length = length + 1
    if (this.selectedFiler.location != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.assets != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.number != null) length = length + 1
    if (this.selectedFiler.partName != null) length = length + 1
    if (this.selectedFiler.department != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  assetList = []
  assetListFilter = []

  filterAssetSearch
  filterAsset(ev) {
    // console.log(ev)
    this.assetList = this.assetListFilter.filter((item) => {
      if (item.assetName != null) {
        return item.assetName.toLowerCase().includes(this.filterAssetSearch.toLowerCase())
      }
    })
  }

  selectAllAssets

  filterSelect = false;
  numberFilter = false;

  propertyList = []
  filterpropertyList = []

  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      this.departmentList = result['departments'];
      this.filterdepartmentList = result['departments'];

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    })
  }

  departmentFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
      this.departmentFilter=false
    }
  }
  closeAll() {
    this.propertFilter = false;
    this.departmentFilter=false
  }
  openDate(id){
    (<HTMLInputElement>document.getElementById(id)).click();
  }
 downloadReport():void{
    this.apiService.startLoader()

    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortBy: this.sortSelected,
      sortType: this.sort,
      partId: this.selectedFiler.partId ? this.selectedFiler.partId : null,
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate :this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      locationId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
      assetId: this.selectedFiler.assets ? this.selectedFiler.assets.id : null,
      download: 1,
      number: this.selectedFiler.number,
      partName: this.selectedFiler.partName,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
      typeId: this.selectedFiler.department ? this.selectedFiler.department.id : null
    }
    this.apiService.postCall('parts/getExpenses', {}, body).subscribe((result: any[]) => {
      // console.log("Download Report",result);
      if(result['success'] == true){
        const link = document.createElement('a');
        // link.setAttribute('target', '_blank');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()

    })
 }

}
