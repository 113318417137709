import { Component, HostListener, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { StoredData } from '../services/storedData.service';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { ApiService } from '../services/apiCalls.service';

@Component({
  selector: 'app-qr-html',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  constructor(public _location: Location,public storedData:StoredData , public route  : ActivatedRoute,public apiService: ApiService,public root: Router) { }

  showClose=true
  ngOnInit(): void {
    // console.log(this.storedData.qrDataEmp)
    document.body.style.overflow = 'hidden';
    this.route.queryParams
      
      .subscribe(params => {
        // console.log(params); // { order: "popular" }
        if(!this.storedData.qrDataEmp.name){
          this.showClose=false
          this.storedData.qrDataEmp = {name:params.name,image : params.image,qRCodeEmpVoice:params.qRCodeEmpVoice,qRCodeEmpVoiceWhistleblowerPolicy:params.qRCodeEmpVoiceWhistleblowerPolicy }
        }
        // this.order = params.order;

        // // console.log(this.order); // popular
      }
    );
  
  }
  openQR(outletName, qRCode,customerComplaintName) {
    this.storedData.qrData = { name: outletName, image: qRCode, customerComplaintName: customerComplaintName }
    this.root.navigate(['print-qr'])
  }
  backClicked(){
    this._location.back()
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  printDiv(divName) {
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // // document.body.innerHTML = printContents;

    // window.print();

    // window.close();
    // document.body.innerHTML = originalContents;
    let fontSize = ((16/100)*this.slider)
    let fontSizeHead = ((40/100)*this.slider)
    let buisnessLogo = this.apiService.buisnessLogo
    var mywindow = window.open('', 'PRINT', `height=400,width=600`);

    // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    // mywindow.document.write('</head><body >');
    mywindow.document.write("<div style='border: 1px solid #ccc; text-align: center; width: fit-content; margin: auto;  padding: 8%; border-radius: 25px;'>");
    mywindow.document.write(`<p  style='font-size:${fontSizeHead}px'>`+this.storedData.qrDataEmp.name+`</p>`);
    mywindow.document.write('<br>');
    mywindow.document.write(document.getElementById(divName).innerHTML);
    // mywindow.document.write(`<p id="textPoweredBy" style='font-size:${fontSize}px'>Powered by</p>`);
    // mywindow.document.write('<br>');
    mywindow.document.write(`<img src='${buisnessLogo}' style="width: 100px;height: 50px;">`);
    // mywindow.document.write('<img  src="./assets/images/icons/logo.svg" style="width: 50px;height: 50px;">');
    mywindow.document.write('<br>');
    mywindow.document.write(`<p id="textPoweredByLogo" style='font-size:${fontSize}px;float: right;color: #33ccb2;'>Powered by Proptor</p>`);

    mywindow.document.write("</div>");
    // mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
    // var originalContents = JSON.stringify(document.body.innerHTML);
    
    //   var printContents = document.getElementById(divName).innerHTML;
      
 
    //   document.body.innerHTML = printContents;
 
    //   window.print();
    //   window.close();
    //   document.body.innerHTML = JSON.parse(originalContents);
 

}


@HostListener("click", ["$event"])
public onClick(event: any): void
{

}

slider=100
changeSlider(ev){
  // console.log(this.slider)
  let obj = (<HTMLInputElement>document.getElementById('printableAreaLogo'))

  obj.style.width=((180/100)*this.slider)+"px"
  obj.style.height=((180/100)*this.slider)+"px"
  let obj11 = (<HTMLInputElement>document.getElementById('printableArea'))

  obj11.style.width=((180/100)*this.slider)+"px"
  obj11.style.height=((180/100)*this.slider)+"px"

  let obj1 = (<HTMLInputElement>document.getElementById('gridBox'))

  obj1.style.width=((600/100)*this.slider)+"px"

  let obj2 = (<HTMLInputElement>document.getElementById('textPoweredBy'))

  obj2.style.fontSize=((16/100)*this.slider)+"px"

  let obj3 = (<HTMLInputElement>document.getElementById('textPoweredByLogo'))

  obj3.style.fontSize=((16/100)*this.slider)+"px"

  let objImg = (<HTMLInputElement>document.getElementById('poweredLogo'))

  objImg.style.width=((50/100)*this.slider)+"px"
  objImg.style.height=((50/100)*this.slider)+"px"

  let objImgPrint = (<HTMLInputElement>document.getElementById('headerText'))

  // objImgPrint.style.padding=((8/100)*this.slider)+"px "+ ((60/100)*this.slider)+"px" 
  objImgPrint.style.fontSize=((20/100)*this.slider)+"px"
  
  // let objImgPrintIcon = (<HTMLInputElement>document.getElementById('printIcon'))

  // objImgPrintIcon.style.width=(this.slider)+"%" 
  
  
  // obj.style.height=((180/100)*this.slider)+"px"
}



exportAsPDF(div_id)
{

  setTimeout(() => {
    

  
  // document.getElementById("pdfButton1").style.opacity="0";
  
  let data = document.getElementById(div_id);
  // console.log(data.clientHeight)
  html2canvas(data, {
    // logging: true, 
    allowTaint: true,
    // letterRendering: 1, 
    useCORS: true, 
    width: 2480,
    height: data.clientHeight+100
    
  }).then((canvas) => {
    let imgWidth = 500;
      let pageHeight = 480;
      let imgHeight =
        ((canvas.height * imgWidth) / 2454)*1.24;
      var heightLeft = imgHeight;

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jspdf({
        orientation: "p",
        unit: "mm",
        format: [400, 480],
      });

      let position = 10;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("Result.pdf");
      // document.getElementById("pdfButton1").style.opacity="1";
    });

}, 1000);
}
}
