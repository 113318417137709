import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-asset-vendor-list',
  templateUrl: './asset-vendor-list.component.html',
  styleUrls: ['./asset-vendor-list.component.css']
})
export class AssetVendorListComponent implements OnInit {

  constructor(
    public route: Router, 
    public apiService: ApiService, 
    public storedData: StoredData,
    public _location: Location,
  ) { }
  
  selectedFiler = { part: null, assets: null,property: null,endDate:null,startDate:null,number:null,vendorName:null }
  fromDate:Date = new Date();
  toDate:Date = new Date();
  
  sortSelected: any = null;
  sort: any = null;

  reportList = [];
  
  locationList = [];
  filterlocationList = [];
  locationFilter = false;
  filterLocationSearch: any
  
  applyFlag = false;
  
  partList = [];
  partListFilter = [];
  partFilter = false;
  partFilterSearch:any;

  filterSelect = false;
  assetNameFileter = false;
  numberFilter = false;

  propertyList = []
  filterpropertyList = []
  propertFilter = false;
  filterPropertySearch:any

  ngOnInit(): void {
    this.fromDate.setDate(this.fromDate.getDate() - 7);
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    this.getReportListing();
    this.getPropertyList();
  }

  getReportListing() {
    this.apiService.startLoader()
    let body = {
      // sortBy: this.sortSelected,
      // sortType: this.sort,
      // partId: this.selectedFiler.part ? this.selectedFiler.part.id : null,
      // startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      // endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate :this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetName: this.selectedFiler.assets ? this.selectedFiler.assets : null,
      download: 0,
      assetTagNo: this.selectedFiler.number,
      vendorName:this.selectedFiler.vendorName,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('AssetManagements/getVendorList', {}, body).subscribe((result: any[]) => {
      this.reportList = result['data']
      this.apiService.stopLoader()
    })
  }

  clear() {
    this.applyFlag = false;
    this.selectedFiler = { part: null, assets: null, property: null,endDate:this.toDate,startDate:this.fromDate,number:null, vendorName:null}
    let idx1 = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    this.getReportListing()
  }

  sortData(data:any) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'
    this.getReportListing()
  }

  applyFilter() {
    this.applyFlag = true;
    this.getReportListing();
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.part != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.assets != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.number != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  

  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
    })
  }

  filterProperty(ev:any) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (btn.id != 'custom') {
      this.filterSelect = false
      this.propertFilter = false;
    }
  }
  closeAll() {
    this.propertFilter = false;
  }

  downloadReport():void{
    this.apiService.startLoader()
    let body = {
      // startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      // endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate :this.toDate,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      assetName: this.selectedFiler.assets ? this.selectedFiler.assets : null,
      download: 1,
      assetTagNo: this.selectedFiler.number,
      vendorName:this.selectedFiler.vendorName,
      businessId:this.apiService.buisnessId,
      authId:this.apiService.authId,
    }
    this.apiService.postCall('AssetManagements/getVendorList', {}, body).subscribe((result: any[]) => {
      if(result['success'] == true){
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }

}
