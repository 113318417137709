import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { active } from 'd3';
@Component({
  selector: 'app-business-index',
  templateUrl: './business-index.component.html',
  styleUrls: ['./business-index.component.css']
})
export class BusinessIndexComponent implements OnInit {

  constructor(
    public route: Router,
    public router: Router,
    public apiService: ApiService,
    public storedData: StoredData
  ) { }
  showAddMenu = false;
  // pagination Veriables
  paginationArray = [];
  activePage = 1;
  pageSize = 10;
  totalnoOfPages: any;

  pageSizeArchive = 10
  activePageArchive = 1
  paginationArrayArchive = []
  totalnoOfPagesArchive: any;
  saveData = false;
  message: any;
  // array list veriables
  businessList: any;

  // Sorting variables
  sortType = "desc";
  sortBy = 'id';

  // Filter Object
  selectedFiler = { name: null, city: null }
  applyFlag = false;
  showFilter = false;
  roleFilter = false;
  propertyFilter = false;
  businessFilter = false;
  role: any;

  tabSelected = 'active';
  statusSelection: any;

  ngOnInit(): void {
    this.role = this.apiService.role;

    var businessIndexPagination = localStorage.getItem('businessIndexPagination');
    if (businessIndexPagination) {
      this.activePage = parseInt(businessIndexPagination);
    }
    if (this.router.url.split("/")[2]) {
      this.tabSelected = this.router.url.split("/")[2];
      this.clear();
      this.getBusinessList();
    } else {
      this.getBusinessList();
    }
  }
  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'

    this.getBusinessList()
  }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.city != null) length = length + 1

    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }
  goBack() {
    this.route.navigate(['/setup']);
  }
  addBusiness() {
    this.route.navigate(['/business-add']);
  }
  deleteBusiness() {
    this.route.navigate(['/business-delete']);
  }

  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log("Pagination Active : ",this.paginationArray)
  }

  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    this.getBusinessList();
  }

  clear() {
    this.jumpToPage = 1
    this.activePage = 1
    this.selectedFiler = { name: null, city: null }
    this.getBusinessList();
  }

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('businessIndexPagination', d);
      // console.log("Active Page :", d)
      this.getBusinessList()
    }
  }
  jumpToPage
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)
    return array
  }
  changePage(ev) {
    this.getBusinessList();
  }

  getBusinessList(): void {
    var status = 1;
    tabSelected: active;
    // console.log("Tab selected"+this.tabSelected)
    if (this.tabSelected == 'active') {
      status = 1;
      this.tabSelected = 'active'

    } else {
      status = 0;
      this.tabSelected = 'archived'
    }
    const body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      city: this.selectedFiler.city ? this.selectedFiler.city : null,
      isActive: status,
      sortBy: this.sortBy,
      sortType: this.sortType,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    };
    this.apiService.startLoader()
    this.apiService.postCall('businesses/index', {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.businessList = result['data'];
      this.apiService.stopLoader()
      this.setPaginationArray(result)
    })
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.propertyFilter = false;
      this.roleFilter = false;
      this.businessFilter = false;
      this.showAddMenu = false;
    }
    if (btn.id == "changeBtn") {
    } else if (btn.id != "changeBtn") {
      this.statusSelection = undefined
    }

  }

  selectedBuisness

  changeStatus(id: any, status): void {
    let formData = {
      isActive: status,
    }
    this.apiService.startLoader()
    this.apiService.postCall('businesses/edit/' + id, {}, formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.tabSelected = '';
      this.saveData = result['success'];
      if (this.saveData == true) {
        this.message = 'Status Updated successfully.';
      }
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })
  }

  closeAll() {
    this.propertyFilter = false;
    this.roleFilter = false;
    this.businessFilter = false;
  }

  selectStatus(d) {
    // console.log(d);
    if ('status' + d.businessesId != this.statusSelection) this.statusSelection = 'status' + d.businessesId
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }


  dropButton = false;
}
