import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';

@Component({
  selector: 'app-add-new-asset',
  templateUrl: './add-new-asset.component.html',
  styleUrls: ['./add-new-asset.component.css']
})
export class AddNewAssetComponent implements OnInit {
  assetDetailsForm: FormGroup
  constructor(private fb: FormBuilder,public apiService: ApiService,) { }

  ngOnInit(): void {
    this.assetDetailsForm = this.fb.group({
      assetId: [null],
      assetName: [null],
      make: [null],
      specRating: [null],
      srNo: [null],
      locationId: [null],
      purchaseDate: [new Date()],
      placeInService: [new Date()],
      propertyId: [null],
      businessId: [null],
      model: [null],
      specification: [null],
      isOperational: [null],
      renewalDate: [null],
      warrantyExpiration: [new Date()],
      authId: [null],
      vendorName: [null],
      categoryName: [null],
      conditions: [null],
      slaExpiryDate: [new Date()],
      remark: [null],
      ownership: [null],
      assetTagNo: [null],
      status: [null]
    });
  }

  



  save(){
    let obj = {
      // assetName: this.assetDetailsForm.value.assetName,
      // make: this.assetDetailsForm.value.make,
      // specRating: this.assetDetailsForm.value.specRating,
      // srNo: this.assetDetailsForm.value.srNo,
      // locationId: this.assetDetailsForm.value.locationId,
      // purchaseDate: this.assetDetailsForm.value.purchaseDate,
      // placeInService: this.assetDetailsForm.value.placeInService,
      // propertyId: this.assetDetailsForm.value.propertyId,
      // businessId: this.assetDetailsForm.value.businessId,
      // model: this.assetDetailsForm.value.model,
      // specification: this.assetDetailsForm.value.specification,
      // isOperational: this.assetDetailsForm.value.isOperational,
      // renewalDate: this.assetDetailsForm.value.renewalDate,
      // warrantyExpiration: this.assetDetailsForm.value.warrantyExpiration,
      // authId: this.assetDetailsForm.value.authId,
      // vendorName: this.assetDetailsForm.value.vendorName,
      // categoryName: this.assetDetailsForm.value.categoryName,
      // conditions: this.assetDetailsForm.value.conditions,
      // slaExpiryDate: this.assetDetailsForm.value.slaExpiryDate,
      // remark: this.assetDetailsForm.value.remark,
      // ownership: this.assetDetailsForm.value.ownership,
      // assetTagNo: this.assetDetailsForm.value.assetTagNo,

      assetName:"Air Conditions",
make:12,
specRating:4,
srNo:1,
locationId:256,
purchaseDate:"2021-07-19 02:00:00",
placeInService:"2021-07-19 06:47:00",
propertyId:516,
businessId:41,
model:"abc",
specification:"xyz",
isOperational:1,
renewalDate:"2021-12-19 06:47:00",
warrantyExpiration:"2021-12-19 06:47:00",
authId:this.apiService.userId,
vendorName:"Ashutosh",
categoryName:"Air purifire",
conditions:"winter",
slaExpiryDate:"2021-12-4",
remark:3,
ownership:"shirish",
assetTagNo:654321,


      

    }

     this.apiService.postCall('asset-managements/add', {}, obj).subscribe((result: any[]) => {
      // console.log(result);

    })
  }
}
