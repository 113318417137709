// import { ResearchPreviewComponent } from './privateAsset/research-preview/research-preview.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
// import { DiscriptionComponent } from './privateAsset/discription/discription.component';
//  import { AssetDetailsComponent } from '';

import { HomeComponent } from './home/home.component';
import { EmployeeSurveyComponent } from './employee-survey/employee-survey.component';
import { EmployeeSurveyViewComponent } from './employee-survey-view/employee-survey-view.component';
import { PartsInventoryComponent } from './parts/parts-inventory/parts-inventory.component';
import { MigrateAssetComponent } from './migrate-asset/migrate-asset.component';
import { PartsConsumeComponent } from './part-consume/part-consume.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { ViewDestinationLocationComponent } from './view-destination-location/view-destination-location.component';
import { EditAssetComponent } from './edit-asset/edit-asset.component';
import { AmcSupportHistoryComponent } from './amc-support-history/amc-support-history.component';
import { AmcSupportAddNewComponent } from './amc-support-add-new/amc-support-add-new.component';
import { PreventiveMaintenaceComponent } from './preventive-maintenace/preventive-maintenace.component';
import { AssetsDetailsPMCreateTemplateComponent } from './assets-details-pm-create-template/assets-details-pm-create-template.component';
import { AssetsDetailsDepreciationComponent } from './assets-details-depreciation/assets-details-depreciation.component';
import { NotificationSettingsComponent } from './assets-details-notification-settings/assets-details-notification-settings.component';
// import { NotificationSettingsComponent } from './assets-details-notification-settings/assets-details-notification-settings.component';

import { TicketsListingComponent } from './tickets-listing/tickets-listing.component';
import { LogsFilesListingComponent } from './logs-files-listing/logs-files-listing.component';

import { ChildAssetNewComponent } from './child-asset-new/child-asset-new.component';
import { ReportComponent } from './report/report.component';
import { MetersListComponent } from './meters-list/meters-list.component';
import { MeterDetailsComponent } from './meter-details/meter-details.component';
import { MeterAddNewComponent } from './meter-add-new/meter-add-new.component';
import { MeterTriggerListComponent } from './meter-trigger-list/meter-trigger-list.component';
import { MeterAddTriggerComponent } from './meter-add-trigger/meter-add-trigger.component';
import { MeterHistoryComponent } from './meter-history/meter-history.component';
import { InspectionListComponent } from './inspection-list/inspection-list.component';
import { InspectionChecklistListComponent } from './inspection-checklist-list/inspection-checklist-list.component';
import { InspectionScheduleListComponent } from './inspection-schedule-list/inspection-schedule-list.component';
import { InspectionScheduleAddComponent } from './inspection-schedule-add/inspection-schedule-add.component';
import { AssetPartsListComponent } from './asset-parts-list/asset-parts-list.component';
import { AssetPartsAddComponent } from './asset-parts-add/asset-parts-add.component';
import { TicketListComponent } from './tickets/ticket-list/ticket-list.component';
import { TicketListCustomerComponent } from './tickets/ticket-list-customer/ticket-list-customer.component';
import { IndividualViewReportComponent } from './individual-view-report/individual-view-report.component';
import { IndividualViewReportNewComponent } from './individual-view-report-new/individual-view-report-new.component';
import { PmTemplatesComponent } from './pm-templates/pm-templates.component';
import { PmTemplatesEditComponent } from './pm-templates-edit/pm-templates-edit.component';
import { PmViewComponent } from './pm-view/pm-view.component';
import { PmViewNewComponent } from './pm-view-new/pm-view-new.component';
import { PmIndexComponent } from './pm-index/pm-index.component';
import { PartTransferComponent } from './part-transfer/part-transfer.component';
import { PartTransferViewComponent } from './part-transfer-view/part-transfer-view.component';
import { PartsAddComponent } from './parts/parts-add/parts-add.component';

import { RaiseTicketComponent } from './raise-ticket/raise-ticket.component';
import { TicketViewComponent } from './tickets/ticket-view/ticket-view.component';
import { TicketViewNewComponent } from './tickets/ticket-view-new/ticket-view-new.component';

import { TicketEscalationComponent } from './tickets/ticket-escalation/ticket-escalation.component';
import { InspectionScheduleViewComponent } from './inspection-schedule-view/inspection-schedule-view.component';
import { InspectionChecklistCreateComponent } from './inspection-checklist-create/inspection-checklist-create.component';
import { InspectionChecklistViewComponent } from './inspection-checklist-view/inspection-checklist-view.component';
import { InspectionDublicateComponent } from './inspection-dublicate/inspection-dublicate.component';
import { SurveyDublicateComponent } from './survey-dublicate/survey-dublicate.component';
import { QrHtmlComponent } from './common/qr-html/qr-html.component';
import { QrPermitHtmlComponent } from './common/qr-permit-html/qr-permit-html.component';
import { QrCodeComponent } from './common/qr-code/qr-code.component';
import { SurveyIndexComponent } from './survey-index/survey-index.component';
import { SurveyAddComponent } from './survey-add/survey-add.component';
import { FeedbackIndexComponent } from './feedback-index/feedback-index.component';
import { FeedbackAddComponent } from './feedback-add/feedback-add.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { DepartmentListComponent } from './department-list/department-list.component';
import { DepartmentAddNewComponent } from './department-add-new/department-add-new.component';
import { DepartmentEditComponent } from './department-edit/department-edit.component';

import { SetupComponent } from './setup/setup.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { EmployeeSurveyAddComponent } from './employee-survey-add/employee-survey-add.component';
import { InsuranceListComponent } from './insurance-list/insurance-list.component';
import { InsuranceAddComponent } from './insurance-add/insurance-add.component';
import { InsuranceDetailsComponent } from './insurance-details/insurance-details.component';
import { EditInsuranceComponent } from './edit-insurance/edit-insurance.component';
import { InsuranceNotificationSettingComponent } from './insurance-notification-setting/insurance-notification-setting.component';
import { AssetCoveredComponent } from './asset-covered/asset-covered.component';
import { UserIndexComponent } from './user-index/user-index.component';
import { UserAddComponent } from './user-add/user-add.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { PropertyIndexComponent } from './property-index/property-index.component';
import { PropertyAddComponent } from './property-add/property-add.component';
import { LocationChecklistsComponent } from './location-checklists/location-checklist-add.component';
import { ChecklistIndexComponent } from './checklist-index/checklist-index.component';
import { InsuranceFilesComponent } from './insurance-files/insurance-files.component';
import { SubUserIndexComponent } from './sub-user-index/sub-user-index.component';
import { SubUserEditComponent } from './sub-user-edit/sub-user-edit.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { PropertyLocationsComponent } from './property-locations/property-locations.component';
import { PropertyUsersComponent } from './property-users/property-users.component';
import { TemplateLibraryComponent } from './template-library/template-library.component';
import { PartsDetailsComponent } from './parts/parts-details/parts-details.component';
import { PartsVendorsComponent } from './parts/parts-vendors/parts-vendors.component';
import { PartsAssetsComponent } from './parts/parts-assets/parts-assets.component';
import { PartsLogsComponent } from './parts/parts-logs/parts-logs.component';
import { PartsReportsComponent } from './parts/parts-reports/parts-reports.component';
import { PartsNotifyComponent } from './parts/parts-notify/parts-notify.component';
import { DashboardChecklistComponent } from './dashboard-checklist/dashboard-checklist.component';
import { DashboardChecklistPercentageComponent } from './dashboard-checklist-percentage/dashboard-checklist-percentage.component';
import { DashboardCeoComponent } from './dashboard-ceo/dashboard-ceo.component';
import { D3PlotsComponent } from './d3-plots/d3-plots.component';
import { TemplateCreateComponent } from './templates/template-create/template-create.component';
import { TemplateIndexComponent } from './templates/template-index/template-index.component';
import { DashboardTicketComponent } from './dashboard-ticket/dashboard-ticket.component';
import { AddVendorComponent } from './parts/add-vendor/add-vendor.component';
import { TemplateViewComponent } from './templates/template-view/template-view.component';
import { DashboardAssetComponent } from './dashboard-asset/dashboard-asset.component';
import { DashboardPartsComponent } from './dashboard-parts/dashboard-parts.component';
import { DashboardSurveyComponent } from './dashboard-survey/dashboard-survey.component';
import { DashboardPmComponent } from './dashboard-pm/dashboard-pm.component';
import { DashboardIndexComponent } from './dashboard-index/dashboard-index.component';
import { DashboardChecklistIndexComponent } from './dashboard-checklist-index/dashboard-checklist-index.component';
import { PartsDashboardIndexComponent } from './parts-dashboard-index/parts-dashboard-index.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { BusinessIndexComponent } from './business-index/business-index.component';
import { BusinessAddComponent } from './business-add/business-add.component';
import { BusinessDeleteComponent } from './business-delete/business-delete.component';
import { BusinessAdminAddComponent } from './business-admin-add/business-admin-add.component';
import { BusinessFeaturesComponent } from './business-features/business-features.component';
import { BusinessEditComponent } from './business-edit/business-edit.component';
import { BusinessAdminIndexComponent } from './business-admin-index/business-admin-index.component';
import { BusinessPropertyComponent } from './business-property/business-property.component';
import { ReportsComponent } from './reports/reports.component';
import { AutomatedListComponent } from './automated-list/automated-list.component';
import { StockStatementsComponent } from './stock-statements/stock-statements.component';
import { RecivedInventoryExpenseComponent } from './recived-inventory-expense/recived-inventory-expense.component';
import { QrMultipleHtmlComponent } from './qr-multiple-html/qr-multiple-html.component';
import { TransferReportComponent } from './transfer-report/transfer-report.component';
import { AssetExpenseReportComponent } from './asset-expense-report/asset-expense-report.component';
import { AssetDueReturnComponent } from './asset-due-return/asset-due-return.component';
import { AssetDiscardSummaryComponent } from './asset-discard-summary/asset-discard-summary.component';
import { AssetTransferReportsComponent } from './asset-transfer-reports/asset-transfer-reports.component';
import { InventoryMovingComponent } from './inventory-moving/inventory-moving.component';
import { VendorListReportComponent } from './vendor-list-report/vendor-list-report.component';
import { AssetVendorListComponent } from './asset-vendor-list/asset-vendor-list.component';
import { PartVendorListComponent } from './part-vendor-list/part-vendor-list.component';
import { TicketStatusReportComponent } from './ticket-status-report/ticket-status-report.component';
import { SetupSettingsComponent } from './setup-settings/setup-settings.component';
import { RolesIndexComponent } from './roles/roles-index/roles-index.component';
import { RolesAddComponent } from './roles/roles-add/roles-add.component';
import { RolesViewPermissionComponent } from './roles/view-permissions/view-permissions.component';
import { QrGenerateTicketComponent } from './qr-generate-ticket/qr-generate-ticket.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WarrantySupportHistoryComponent } from './warranty-support-history/warranty-support-history.component';
import { WarrantySupportHistoryAddComponent } from './warranty-support-history-add/warranty-support-history-add.component';
import { TrainingsListComponent } from './trainings-list/trainings-list.component';
import { TrainingsAddComponent } from './trainings-add/trainings-add.component';
import { TrainingsAssignUserComponent } from './trainings-assign-user/trainings-assign-user.component';
import { GraphListComponent } from './graph-list/graph-list.component';
import { CheckListScoreGraphListComponent } from './checklist-score-graph-list/checklist-score-graph-list.component';
import { ChecklistScheduleScoreListComponent } from './checklist-schedule-score-list/checklist-schedule-score-list.component';
import { ChecklistSectionScoreListComponent } from './checklist-section-score-list/checklist-section-score-list.component';
import { AssetQrHtmlComponent } from './asset-qr-html/asset-qr-html.component';
import { PermitListComponent } from './permit-setting/permit-list.component';
import { PermitTenantAddComponent } from './permit-setting/permit-tenant/permit-tenant-add/permit-tenant-add.component';
import { PermitWorkAddComponent } from './permit-setting/permit-work/permit-work-add/permit-work-add.component';
import { PermitWorkListComponent } from './permit-setting/permit-work/permit-work-list/permit-work-list.component';
import { PermitAssociatedListComponent } from './permit-setting/permit-associated/permit-associated-list/permit-associated-list.component';
import { PermitAssociatedAddComponent } from './permit-setting/permit-associated/permit-associated-add/permit-associated-add.component';
import {
  PermitQuestionCheckpointListComponent
} from './permit-setting/permit-question-checkpoint/permit-question-checkpoint-list/permit-question-checkpoint-list.component';
import {
  PermitQuestionCheckpointAddComponent
} from './permit-setting/permit-question-checkpoint/permit-question-checkpoint-add/permit-question-checkpoint-add.component';
import { PermitTenantListComponent } from './permit-setting/permit-tenant/permit-tenant-list/permit-tenant-list.component';
import { MasterPropertyComponent } from './master-property/master-property.component';
import { PropertyZoneAddComponent } from './master-property/property-zone/property-zone-add/property-zone-add.component';

import { PropertyStatesAddComponent } from './master-property/property-states/property-states-add/property-states-add.component';
import { PropertyStatesListComponent } from './master-property/property-states/property-states-list/property-states-list.component';
import { PropertyClientAddComponent } from './master-property/property-client/property-client-add/property-client-add.component';
import { PropertyClusterAddComponent } from './master-property/property-cluster/property-cluster-add/property-cluster-add.component';

import { PropertyDistrictsAddComponent } from './master-property/property-districts/property-districts-add/property-districts-add.component';
import { PropertyCitiesAddComponent } from './master-property/property-cities/property-cities-add/property-cities-add.component';
import { PropertySitesAddComponent } from './master-property/property-sites/property-sites-add/property-sites-add.component';
import { AttendanceListComponent } from './attendance-management-master/attendance-list/attendance-list.component';
import { TrainingsDetailsComponent } from './trainings-details/trainings-details.component';
import { ShipmentsAddComponent } from './shifts/shipments/shipments-add/shipments-add.component';
import { ShipmentsListComponent } from './shifts/shipments/shipments-list/shipments-list.component';
import { PermitAddComponent } from './permits/permit-add/permit-add.component';
import { PermitViewComponent } from './permits/permit-view/permit-view.component';
import { PermitIndexListComponent } from './permits/permit-list/permit-index-list.component';
import { DashboardAttendanceComponent } from './dashboard-attendance/dashboard-attendance.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { EmployeeDesignationAddComponent } from './employee/employee-designation/employee-designation-add/employee-designation-add.component';
import {
  EmployeeDesignationListComponent
} from './employee/employee-designation/employee-designation-list/employee-designation-list.component';
import { EmployeeDepartmentAddComponent } from './employee/employee-department/employee-department-add/employee-department-add.component';
import { EmployeeDepartmentListComponent } from './employee/employee-department/employee-department-list/employee-department-list.component';
import { EmployeeDbListComponent } from './employee/employee-db/employee-db-list/employee-db-list.component';
import { DashboardSurveyGraphComponent } from './dashboard-survey-graph/dashboard-survey-graph.component';
import { PropertyQrHtmlComponent } from './property-qr-html/property-qr-html.component';
import { EmployeeQuestionDetailComponent } from './employee-question-detail/employee-question-detail.component';
import { LostArticleIndexListComponent } from './lost-and-found/lost-articles/lost-article-index-list.component';
import { FoundArticleIndexListComponent } from './lost-and-found/found-articles/found-article-index-list.component';
import { LostArticleAddComponent } from './lost-and-found/lost-article-add/lost-article-add.component';
import { FoundArticleAddComponent } from './lost-and-found/found-article-add/found-article-add.component';
import { LostArticleViewComponent } from './lost-and-found/lost-article-view/lost-article-view.component';
import { FoundArticleViewComponent } from './lost-and-found/found-article-view/found-article-view.component';
import { inspectionMonthlyDataComponent } from './inspection-monthly-data/inspection-monthly-data.component';
import { ConsolidatedReportComponent } from './consolidated-report/consolidated-report.component';
import { PartsConsumedReportsComponent } from './parts-consumed-reports/parts-consumed-reports.component';
import { SchedulesQrHtmlComponent } from './schedules-qr-html/schedules-qr-html.component';
import { InspectionsReportsComponent } from './inspections-reports/inspections-reports.component';
import { TicketsReportsComponent } from './tickets-reports/tickets-reports.component';
import { AssetsReportsComponent } from './assets-reports/assets-reports.component';
import { PartReportsComponent } from './part-reports/part-reports.component';
import { SchedulesHolidaysComponent } from './schedules-holidays/schedules-holidays.component';
import { PmViewCalendarComponent } from './pm-view-calendar/pm-view-calendar.component';
@NgModule({
  // providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  imports: [
    RouterModule.forRoot([
      // { path: '', redirectTo: '/meter-list', pathMatch: 'full' },
      { path: '', pathMatch: 'full', redirectTo: 'login-view' },
      // { path: 'myProfile', component: MyProfileComponent },
      // { path: 'dashboard', component: DashboardComponent },
      // { path: 'queries', component: QueriesComponent },
      // { path: 'my-reports', component: MyReportsComponent },
      // { path: 'how-to-invest', component: InvestmentComponent },
      { path: 'home', component: HomeComponent },
      { path: 'home/:tab', component: HomeComponent },

      { path: 'migrate-asset', component: MigrateAssetComponent },
      { path: 'asset-details/:id', component: AssetDetailsComponent },
      { path: 'view-destination-location', component: ViewDestinationLocationComponent },
      { path: 'edit-asset', component: EditAssetComponent },
      { path: 'amc-support-history', component: AmcSupportHistoryComponent },
      { path: 'amc-support-add-new/:assetId/:amcId', component: AmcSupportAddNewComponent },
      { path: 'amc-support-add-new/:assetId/:amcId/:serviceId', component: AmcSupportAddNewComponent },
      { path: 'warranty-support-add-new/:assetId/:amcId', component: WarrantySupportHistoryAddComponent },
      { path: 'warranty-support-add-new/:assetId/:amcId/:serviceId', component: WarrantySupportHistoryAddComponent },
      { path: 'amc-support-edit/:assetId/:amcId/:amcHistoryId', component: AmcSupportAddNewComponent },

      { path: 'warranty-support-add-new/:assetId', component: WarrantySupportHistoryAddComponent },
      { path: 'warranty-support-add-edit/:assetId/:amcHistoryId', component: WarrantySupportHistoryAddComponent },

      { path: 'amc-support-support-history/:assetId/:amcId', component: AmcSupportHistoryComponent },
      { path: 'warrany-support-support-history/:assetId', component: WarrantySupportHistoryComponent },
      { path: 'pm-list/:id', component: PreventiveMaintenaceComponent },
      { path: 'assets-add-new', component: AssetsDetailsPMCreateTemplateComponent },
      { path: 'assets-add-new', component: AssetsDetailsPMCreateTemplateComponent },
      { path: 'assets-details-depreciation/:id', component: AssetsDetailsDepreciationComponent },
      { path: 'assets-details-notification-settings/:id', component: NotificationSettingsComponent },
      { path: 'tickets-list/:id', component: TicketsListingComponent },
      { path: 'log-file-list/:id', component: LogsFilesListingComponent },
      { path: 'child-asset-list/:id', component: ChildAssetNewComponent },
      { path: 'asset-report/:id', component: ReportComponent },
      { path: 'asset-part-list/:id', component: AssetPartsListComponent },
      { path: 'asset-part-add/:id', component: AssetPartsAddComponent },
      { path: 'asset-part-view/:id/:partId', component: AssetPartsAddComponent },

      { path: 'asset-raise-ticket/:id', component: RaiseTicketComponent },
      // /////////////////////////////////////////////assetends//////////////////////////////////////////

      { path: 'meter-list', component: MetersListComponent },
      { path: 'meter-detail/:id', component: MeterDetailsComponent },
      { path: 'meter-add-new', component: MeterAddNewComponent },
      { path: 'meter-trigger-list/:id', component: MeterTriggerListComponent },
      { path: 'meter-trigger-add/:id', component: MeterAddTriggerComponent },
      { path: 'meter-trigger-edit/:id', component: MeterAddTriggerComponent },
      { path: 'meter-history/:id', component: MeterHistoryComponent },
      ////////////////////////metersends/////////////////////

      { path: 'inspection-list', component: InspectionListComponent },
      { path: 'inspection-list/:tab', component: InspectionListComponent },
      { path: 'inspection-view-report-todays/:id', component: IndividualViewReportComponent },
      { path: 'inspection-view-report/:id', component: IndividualViewReportComponent },

      { path: 'inspection-view-report-todays-new/:id', component: IndividualViewReportNewComponent },
      { path: 'inspection-view-report-new/:id', component: IndividualViewReportNewComponent },
      { path: 'inspection-checklist-list', component: InspectionChecklistListComponent },
      { path: 'inspection-checklist-create', component: InspectionChecklistCreateComponent },
      { path: 'inspection-checklist-edit/:id', component: InspectionChecklistCreateComponent },
      { path: 'inspection-checklist-view/:id', component: InspectionChecklistViewComponent },

      { path: 'inspection-schedule-list', component: InspectionScheduleListComponent },
      { path: 'inspection-schedule-add', component: InspectionScheduleAddComponent },
      { path: 'inspection-schedule-edit/:id', component: InspectionScheduleAddComponent },
      { path: 'inspection-schedule-view/:id', component: InspectionScheduleViewComponent },
      { path: 'inspection-dublicate', component: InspectionDublicateComponent },
      { path: 'inspection-schedule-dublicate', component: InspectionDublicateComponent },
      { path: 'survey-dublicate', component: SurveyDublicateComponent },
      ///////////////////////////////inspectionEnds////////////

      { path: 'ticket-main-list', component: TicketListComponent },
      { path: 'ticket-main-list/:tab', component: TicketListComponent },
      { path: 'ticket-main-list-customer', component: TicketListCustomerComponent },
      { path: 'ticket-main-list-customer/:tab', component: TicketListCustomerComponent },
      { path: 'ticket-raise-ticket', component: RaiseTicketComponent },
      { path: 'ticket-view/:id', component: TicketViewComponent },
      { path: 'ticket-view-new/:id', component: TicketViewNewComponent },
      { path: 'ticket-escalation', component: TicketEscalationComponent },
      { path: 'qr-generate-ticket/:oId/:bId', component: QrGenerateTicketComponent },
      ///////////////////////tiketsMain /////////////////////////

      { path: 'pm-index', component: PmIndexComponent },
      { path: 'pm-index/:tab', component: PmIndexComponent },
      { path: 'pm-templates', component: PmTemplatesComponent },
      { path: 'pm-templates/edit/:id', component: PmTemplatesEditComponent },

      { path: 'pm-view/:id', component: PmViewComponent },
      { path: 'pm-upcoming-view/:id', component: PmViewComponent },
      { path: 'pm-view-template/:id', component: PmViewComponent },

      { path: 'pm-view-new/:id', component: PmViewNewComponent },
      { path: 'pm-upcoming-view-new/:id', component: PmViewNewComponent },
      { path: 'pm-view-template-new/:id', component: PmViewNewComponent },

      { path: 'pm-ticket-raise-ticket/:pmId/:subinspectId', component: RaiseTicketComponent },
      { path: 'inspection-ticket-raise-ticket/:pmId/:subinspectId', component: RaiseTicketComponent },
      //////////////////////////////maintanance ///////////////////////////////////

      { path: 'survey-index', component: SurveyIndexComponent },
      { path: 'survey-add', component: SurveyAddComponent },
      { path: 'survey-edit/:id', component: SurveyAddComponent },
      { path: 'feedback-index', component: FeedbackIndexComponent },
      { path: 'feedback-index/:id', component: FeedbackIndexComponent },
      { path: 'customer-feedback/:base64', component: FeedbackAddComponent },
      { path: 'customer-feedback-view/:base64', component: FeedbackAddComponent },
      { path: 'feddback-details/:id', component: FeedbackDetailsComponent },
      { path: 'employee-survey-add', component: EmployeeSurveyAddComponent },
      { path: 'employee-survey-edit/:id', component: EmployeeSurveyAddComponent },
      { path: 'employee-survey', component: EmployeeSurveyComponent },
      { path: 'employee-survey-view/:surveyId', component: EmployeeSurveyViewComponent },
      { path: 'employee-question-detail/:id/:empCode/:qunAnsId', component: EmployeeQuestionDetailComponent },


      { path: 'department-list', component: DepartmentListComponent },
      { path: 'department-list/:tab', component: DepartmentListComponent },
      { path: 'department-add-new', component: DepartmentAddNewComponent },
      { path: 'department/edit/:id', component: DepartmentEditComponent },


      { path: 'setup', component: SetupComponent },
      { path: 'insurance', component: InsuranceComponent },
      { path: 'insurance-list', component: InsuranceListComponent },
      { path: 'insurance-add', component: InsuranceAddComponent },
      { path: 'insurance-details/:id', component: InsuranceDetailsComponent },
      { path: 'edit-insurance', component: EditInsuranceComponent },
      { path: 'insurance-notification-setting/:id', component: InsuranceNotificationSettingComponent },
      { path: 'asset-covered/:id', component: AssetCoveredComponent },
      { path: 'user-index', component: UserIndexComponent },
      { path: 'user-index/:tab', component: UserIndexComponent },
      { path: 'user-add', component: UserAddComponent },
      { path: 'user-edit/:id', component: UserEditComponent },
      { path: 'property-index', component: PropertyIndexComponent },
      { path: 'property-index/:tab', component: PropertyIndexComponent },
      { path: 'property-add', component: PropertyAddComponent },
      { path: 'property-edit/:id', component: PropertyAddComponent },
      { path: 'location-checklist-add/:propertyId/:locationId', component: LocationChecklistsComponent },
      { path: 'checklist-index', component: ChecklistIndexComponent },
      { path: 'insurance-files/:id', component: InsuranceFilesComponent },
      { path: 'parts-details/:id', component: PartsDetailsComponent },
      { path: 'sub-users/:id', component: SubUserIndexComponent },
      { path: 'sub-user-edit/:id', component: SubUserEditComponent },
      { path: 'property-details/:id', component: PropertyDetailsComponent },
      { path: 'property-locations/:id', component: PropertyLocationsComponent },
      { path: 'property-users/:id', component: PropertyUsersComponent },
      { path: 'template-library', component: TemplateLibraryComponent },
      { path: 'template-create', component: TemplateCreateComponent },
      { path: 'template-edit/:tempId', component: TemplateCreateComponent },
      { path: 'template-index', component: TemplateIndexComponent },
      { path: 'add-vendor/:partId', component: AddVendorComponent },
      { path: 'template-view/:tempId', component: TemplateViewComponent },

      ////////////////////////setup////////////////////////////////////////

      //////////////////////////////////////////////////////////////////////////////////////
      { path: 'part-transfer-add', component: PartTransferComponent },
      { path: 'part-transfer-view/:transferId', component: PartTransferViewComponent },
      { path: 'part-add', component: PartsAddComponent },
      { path: 'parts-inventory', component: PartsInventoryComponent },
      { path: 'parts-inventory/:tab', component: PartsInventoryComponent },
      { path: 'parts-vendors/:id', component: PartsVendorsComponent },
      { path: 'part-consume', component: PartsConsumeComponent },
      { path: 'parts-assets/:id', component: PartsAssetsComponent },
      { path: 'parts-logs/:id', component: PartsLogsComponent },
      { path: 'parts-reports/:id', component: PartsReportsComponent },
      { path: 'parts-notify/:id', component: PartsNotifyComponent },
      ////////////////////////////////////////partsInventory////////////

      { path: 'dashboard-ceo', component: DashboardCeoComponent },
      { path: 'dashboard-checklist', component: DashboardChecklistComponent },
      { path: 'dashboard-checklist-percentage', component: DashboardChecklistPercentageComponent },
      { path: 'dashboard-ticket', component: DashboardTicketComponent },
      { path: 'dashboard-asset', component: DashboardAssetComponent },
      { path: 'dashboard-parts', component: DashboardPartsComponent },
      { path: 'dashboard-survey', component: DashboardSurveyComponent },
      { path: 'dashboard-survey-graph', component: DashboardSurveyGraphComponent },

      { path: 'dashboard-index/:id', component: DashboardIndexComponent },
      { path: 'dashboard-checklist-index/:id', component: DashboardChecklistIndexComponent },
      { path: 'parts-dashboard-index/:id', component: PartsDashboardIndexComponent },
      { path: 'dashboard-pm', component: DashboardPmComponent },
      { path: 'dashboard-attendance', component: DashboardAttendanceComponent },
      /////////////////////////////dashboardEnd//////////////////

      // {path: 'dashboard-parts', component:DashboardPartComponent},

      { path: 'login-view', component: LoginComponentComponent },
      { path: 'print-qr', component: QrHtmlComponent },
      { path: 'print-qr-permit', component: QrPermitHtmlComponent },
      { path: 'qr-code', component: QrCodeComponent },
      { path: 'print-multiple-qr', component: QrMultipleHtmlComponent },
      { path: 'd3-plots', component: D3PlotsComponent },

      { path: 'user-subscription/:id', component: SubscriptionsComponent },
      { path: 'business-index', component: BusinessIndexComponent },
      { path: 'business-index/:tab', component: BusinessIndexComponent },
      { path: 'business-add', component: BusinessAddComponent },
      { path: 'business-delete', component: BusinessDeleteComponent },
      { path: 'business-admin/:id', component: BusinessAdminAddComponent },
      { path: 'business-features/:id', component: BusinessFeaturesComponent },
      { path: 'business-edit/:id', component: BusinessEditComponent },
      { path: 'business-admin-index/:id', component: BusinessAdminIndexComponent },
      { path: 'business-property/:id', component: BusinessPropertyComponent },

      // permit setting
      { path: 'permit-setting', component: PermitListComponent },
      { path: 'permit-setting/tenant-new', component: PermitTenantAddComponent },
      { path: 'permit-setting/tenant-edit/:id', component: PermitTenantAddComponent },
      { path: 'permit-setting/tenant-list', component: PermitTenantListComponent },
      { path: 'permit-setting/tenant-list/:tab', component: PermitTenantListComponent },
      { path: 'permit-setting/work-new', component: PermitWorkAddComponent },
      { path: 'permit-setting/work-edit/:id', component: PermitWorkAddComponent },
      { path: 'permit-setting/work-list', component: PermitWorkListComponent },
      { path: 'permit-setting/work-list/:tab', component: PermitWorkListComponent },
      { path: 'permit-setting/associated-list', component: PermitAssociatedListComponent },
      { path: 'permit-setting/associated-list/:tab', component: PermitAssociatedListComponent },
      { path: 'permit-setting/associated-new', component: PermitAssociatedAddComponent },
      { path: 'permit-setting/associated-edit/:id', component: PermitAssociatedAddComponent },
      { path: 'permit-setting/questions-new', component: PermitQuestionCheckpointAddComponent },
      { path: 'permit-setting/questions-edit/:id', component: PermitQuestionCheckpointAddComponent },
      { path: 'permit-setting/questions-list', component: PermitQuestionCheckpointListComponent },
      { path: 'permit-setting/questions-list/:tab', component: PermitQuestionCheckpointListComponent },

      // permits index
      { path: 'permits-list', component: PermitIndexListComponent },
      { path: 'permits-list/:tab', component: PermitIndexListComponent },
      { path: 'permits-new', component: PermitAddComponent },
      { path: 'permits-edit/:id', component: PermitAddComponent },
      { path: 'permits-view/:id', component: PermitViewComponent },

      // *******************property master*********************//
      { path: 'master-property', component: MasterPropertyComponent },
      { path: 'master-property/zone-add', component: PropertyZoneAddComponent },
      { path: 'master-property/zone-edit/:id', component: PropertyZoneAddComponent },
      { path: 'master-property/states-add', component: PropertyStatesAddComponent },
      { path: 'master-property/states-edit/:id', component: PropertyStatesAddComponent },
      { path: 'master-property/states-list', component: PropertyStatesListComponent },
      { path: 'master-property/client-add', component: PropertyClientAddComponent },
      { path: 'master-property/client-edit/:id', component: PropertyClientAddComponent },
      { path: 'master-property/cluster-add', component: PropertyClusterAddComponent },
      { path: 'master-property/cluster-edit/:id', component: PropertyClusterAddComponent },

      { path: 'master-property/districts-add', component: PropertyDistrictsAddComponent },
      { path: 'master-property/districts-edit/:id', component: PropertyDistrictsAddComponent },

      { path: 'master-property/cities-add', component: PropertyCitiesAddComponent },
      { path: 'master-property/cities-edit/:id', component: PropertyCitiesAddComponent },
      { path: 'master-property/sites-add', component: PropertySitesAddComponent },
      { path: 'master-property/sites-edit/:id', component: PropertySitesAddComponent },

      { path: 'shifts/shipment-new', component: ShipmentsAddComponent },
      { path: 'shifts/shipment-edit/:id', component: ShipmentsAddComponent },
      { path: 'shifts/shipment-list', component: ShipmentsListComponent },

      // Reports Rout
      { path: 'reports', component: ReportsComponent },
      { path: 'reports/automated-list', component: AutomatedListComponent },
      { path: 'reports/stock-statements', component: StockStatementsComponent },
      { path: 'reports/recived-inventory-expense', component: RecivedInventoryExpenseComponent },
      { path: 'reports/transfer-report', component: TransferReportComponent },
      { path: 'reports/asset-expense-report', component: AssetExpenseReportComponent },
      { path: 'reports/asset-due-to-return', component: AssetDueReturnComponent },
      { path: 'reports/asset-discard-summary', component: AssetDiscardSummaryComponent },
      { path: 'reports/asset-transfer-report', component: AssetTransferReportsComponent },
      { path: 'reports/inventory-moving', component: InventoryMovingComponent },
      { path: 'reports/vendor-reports', component: VendorListReportComponent },
      { path: 'reports/asset-vendor-list', component: AssetVendorListComponent },
      { path: 'reports/part-vendor-list', component: PartVendorListComponent },
      { path: 'reports/ticket-status-report', component: TicketStatusReportComponent },
      { path: 'reports/inspection-monthly-data', component: inspectionMonthlyDataComponent },
      { path: 'reports/consolidated-report', component: ConsolidatedReportComponent },
      { path: 'reports/parts-consumed-reports', component: PartsConsumedReportsComponent },
      { path: 'reports/inspections-reports', component: InspectionsReportsComponent },
      { path: 'reports/tickets-reports', component: TicketsReportsComponent },
      { path: 'reports/assets-reports', component: AssetsReportsComponent },
      { path: 'reports/part-reports', component: PartReportsComponent },

      //  Setup
      { path: 'setup-settings', component: SetupSettingsComponent },
      { path: 'roles-index', component: RolesIndexComponent },
      { path: 'roles-add', component: RolesAddComponent },
      { path: 'roles-edit/:roleId', component: RolesAddComponent },
      { path: 'view-permissions/:roleId', component: RolesViewPermissionComponent },
      { path: 'reset-password/:userId', component: ResetPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },

      { path: 'training-list', component: TrainingsListComponent },
      { path: 'training-add', component: TrainingsAddComponent },
      { path: 'training-assign-user', component: TrainingsAssignUserComponent },
      { path: 'training-assign-user/:tId', component: TrainingsAssignUserComponent },

      // employee//
      { path: 'employee/designation-new', component: EmployeeDesignationAddComponent },
      { path: 'employee/designation-edit/:id', component: EmployeeDesignationAddComponent },
      { path: 'employee/designation-list', component: EmployeeDesignationListComponent },
      { path: 'employee/department-new', component: EmployeeDepartmentAddComponent },
      { path: 'employee/department-edit/:id', component: EmployeeDepartmentAddComponent },
      { path: 'employee/department-list', component: EmployeeDepartmentListComponent },
      { path: 'employee/db-list', component: EmployeeDbListComponent },

      ////////////////////////trainingends/////////////////////
      { path: 'graph-list', component: GraphListComponent },
      { path: 'checklist-score-graph-list', component: CheckListScoreGraphListComponent },
      { path: 'checklist-schedule-score-list', component: ChecklistScheduleScoreListComponent },
      { path: 'checklist-section-score-list', component: ChecklistSectionScoreListComponent },
      { path: 'asset-qr-html', component: AssetQrHtmlComponent },
      { path: 'schedules-qr-html', component: SchedulesQrHtmlComponent },

      { path: 'attendance-list', component: AttendanceListComponent },
      { path: 'training-details/:id', component: TrainingsDetailsComponent },
      { path: 'training-details/:id/:userId', component: TrainingsDetailsComponent },

      { path: 'property-qr-html', component: PropertyQrHtmlComponent },

      { path: 'lost-article-list', component: LostArticleIndexListComponent },
      { path: 'found-article-list', component: FoundArticleIndexListComponent },
      { path: 'lost-article-add', component: LostArticleAddComponent },
      { path: 'lost-article-edit/:id', component: LostArticleAddComponent },
      { path: 'found-article-add', component: FoundArticleAddComponent },
      { path: 'found-article-edit/:id', component: FoundArticleAddComponent },
      { path: 'lost-article-view/:id', component: LostArticleViewComponent },
      { path: 'found-article-view/:id', component: FoundArticleViewComponent },
      { path: 'schedules-holidays', component: SchedulesHolidaysComponent },
      { path: 'pm-view-calendar', component: PmViewCalendarComponent },

      { path: '**', redirectTo: 'not-found' },
      { path: 'not-found', component: NotFoundComponent },
    ]),
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
