import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-raise-ticket',
  templateUrl: './raise-ticket.component.html',
  styleUrls: ['./raise-ticket.component.css']
})
export class RaiseTicketComponent implements OnInit {
  addTicektForm: FormGroup
  constructor(public _location: Location, public route: Router, public router: Router, private fb: FormBuilder, public apiService: ApiService, public storedData: StoredData,) { }

  pmId: any
  subPmId: any
  assetId

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  assetViewPermission = true;
  assetsFeaturePermission = false;
  partsFeaturePermission = false;
  partsPermission = false;
  ticketClossingConfirmationPermission = false;
  ticketClossingConfirmationFlag = 0;
  ngOnInit(): void {

    document.body.style.overflow = 'hidden';
    this.getOutLetList()
    // console.log(this.addTicektForm.value)
    if (this.router.url.split("/")[1] == 'pm-ticket-raise-ticket') {
      this.pmId = this.router.url.split("/")[2]
      this.subPmId = this.router.url.split("/")[3]
      this.getPmInspection();
    }
    if (this.router.url.split("/")[1] == 'inspection-ticket-raise-ticket') {
      this.pmId = this.router.url.split("/")[2]
      this.subPmId = this.router.url.split("/")[3]


      this.getInspectionToday();
    }

    if(this.router.url.split("/")[1] =="asset-raise-ticket")this.assetId = this.router.url.split("/")[2]
    if(this.assetId)this.getAssetDetails();
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 6);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
      if (permission[i]['permissionModuleId'] == 5 && permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.partsPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 16 && businessFeatures[i]['permission_allow'] == 1) {
          this.partsFeaturePermission = true
        }
        if (businessFeatures[i]['business_app_feature_id'] == 25 && businessFeatures[i]['permission_allow'] == 1) {
          this.ticketClossingConfirmationPermission = true;
          this.ticketClossingConfirmationFlag = 1;
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
      this.partsFeaturePermission = true;
      this.ticketClossingConfirmationPermission = true;
      this.ticketClossingConfirmationFlag = 1;
    }
    this.addTicektForm = this.fb.group({
      outletId: [null, Validators.required],
      departmentId: [null, Validators.required],
      businessId: [null],
      userId: [null],
      subject: [null, Validators.required],
      description: [null, Validators.required],
      // status:[null ,Validators.required],
      priorityId: [1],
      location: [null],
      outherLocation: [null],
      ticketClossingConfirmation:[this.ticketClossingConfirmationFlag],
      assetId: this.router.url.split("/")[1] == "asset-raise-ticket" ? [parseInt(this.router.url.split("/")[2])] : [null,]
    });
  }

  sectionArray = []
  inspctionDetailToday
  departMent = false
  getInspectionToday() {
    this.apiService.startLoader()
    this.apiService.getCall('TodaysSchedules/view/' + this.pmId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.inspctionDetailToday = result['data']
      this.addTicektForm.patchValue({ outletId: this.inspctionDetailToday.propertyId, departmentId: this.inspctionDetailToday.departmentId })
      this.getUserList()
      if (this.inspctionDetailToday.departmentId) {
        this.departMent = true
      }
    })
  }
  getPmInspection() {
    this.apiService.startLoader()
    this.apiService.getCall('pm-schedules/view/' + this.pmId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.inspctionDetailToday = result['data']
      this.addTicektForm.patchValue({ outletId: this.inspctionDetailToday.propertyId, departmentId: this.inspctionDetailToday.departmentId })
      this.getUserList()
      this.assetId = true
    })
  }

  userSelectedList = []
  selectUser() {
    // console.log(this.userSelectedList.indexOf(this.addTicektForm.value.userId))
    if (this.userSelectedList.indexOf(this.addTicektForm.value.userId) == -1) {
      // if(this.userSelectedList.length <5){
      this.userSelectedList.push(this.addTicektForm.value.userId)
      this.addTicektForm.patchValue({ userId: null })
      // }else{
      //   this.addTicektForm.patchValue({userId : null})
      //   this.showAssigneeLimitFlag= true
      // }
    } else {
      this.addTicektForm.patchValue({ userId: null })
    }
  }

  showAssigneeLimitFlag: boolean;
  removeUser(d) {
    let idx = this.userSelectedList.findIndex(x => x == d)

    this.userSelectedList.splice(idx, 1)
    this.showAssigneeLimitFlag = false
  }
  backClicked() {
    this._location.back();
  }

  getOutLetList() {


    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
      // console.log(result)
      this.departmentList = result['departments']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)
      if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {
        this.addTicektForm.patchValue({
          //outletId : this.propertyList[idx].id
        })
        this.getUserList()
      }

    })




    // this.apiService.postCall('asset-managements/index/'+this.apiService.buisnessId,{} , {pageNo: 1, limit: 100, sortType: "asc", sortBy: "outletName"} ).subscribe((result: any[])=>{
    //   // console.log(result);
    //   this.apiService.stopLoader()
    //   // this.assetList=[]
    //   this.assetList= result['data']['data'];
    //   // console.log(this.assetList)
    //   this.statusList = result["statusList"];

    // })




    //     this.apiService.postCall('tickets/getDepartmentUsers/84/71 ',{} , {} ).subscribe((result: any[])=>{
    //       // console.log(result);
    //       this.apiService.stopLoader()
    // this.  departmentList=result['data']

    //     })
  }
  getAsserList() {
    let obj1 = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.addTicektForm.value.outletId,
    }

    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj1).subscribe((result: any[]) => {
      // this.apiService.stopLoader()
      this.assetList = []
      // console.log("vaulesss", result);

      this.assetList = result['data'];

    })
  }

  getAssetList(outletId) {
    let obj1 = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": outletId,
      "statusNotIn": "5,8" //exclude lost and discard
    }
    this.apiService.postCall('asset-managements/assetMainLists/' + this.apiService.buisnessId, {}, obj1).subscribe((result: any[]) => {
      this.assetList = []
      // console.log("vaulesss", result);
      this.assetList = result['data'];
    })
  }
  getUserList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.addTicektForm.value.outletId,
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.addTicektForm.value.outletId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      // // console.log(jsonToBeUsed)
      this.userList = result['data']
      this.getAssetList(this.addTicektForm.value.outletId);
    },
      error => {
        this.apiService.stopLoader()
      });
  }
  getLocationList() {
    this.apiService.startLoader()
    let obj = {
      "businessId": this.apiService.buisnessId,
      "outletId": this.addTicektForm.value.outletId,
    }
    this.apiService.postCall('Locations/locationList/' + this.apiService.buisnessId + '/' + this.addTicektForm.value.outletId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.locationsList = result['data'];
    },
      error => {
        this.apiService.stopLoader()
      });
  }
  vedioSection: boolean;
  imageSection: boolean
  // departmentList=[]
  departmentList = []
  daysList = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  statusList = []
  assetList = []
  propertyList = []
  userList = []
  locationsList = []
  message: any;
  createTicket() {


    let userArry: any = []
    for (let d of this.userSelectedList) userArry.push(d.id)
    // console.log(this.addTicektForm.value)


    let testData: FormData = new FormData();

    testData.append('createdBy', this.apiService.userId);
    testData.append('outletId', this.addTicektForm.value.outletId);
    testData.append('departmentId', this.addTicektForm.value.departmentId);
    testData.append('businessId', this.apiService.buisnessId);
    testData.append('userId', userArry);
    testData.append('subject', this.addTicektForm.value.subject);
    if (this.addTicektForm.value.description == null) {
      testData.append('description', "");
    } else {
      testData.append('description', this.addTicektForm.value.description);
    }
    if (this.addTicektForm.value.location == '0') {
      testData.append('location', this.addTicektForm.value.outherLocation);
    } else {
      testData.append('location', this.addTicektForm.value.location);
    }
    // testData.append('status', this.addTicektForm.value.status);
    testData.append('priorityId', this.addTicektForm.value.priorityId);
    testData.append('ticketClossingConfirmation', this.addTicektForm.value.ticketClossingConfirmation);
    testData.append('assetManagementId', this.addTicektForm.value.assetId ? this.addTicektForm.value.assetId : "");
    if (this.uploadedFiles) testData.append('attach1', this.uploadedFiles);
    if (this.uploadedFiles2) testData.append('attach2', this.uploadedFiles2);
    if (this.uploadedFiles3) testData.append('attach3', this.uploadedFiles3);
    //if(this.vedioUploadFile)testData.append('video', this.vedioUploadFile);

    if (this.router.url.split("/")[1] == 'asset-raise-ticket') {
      testData.append('ticketType', "8");
    }
    if (this.router.url.split("/")[1] == 'pm-ticket-raise-ticket') {
      testData.append('ticketType', "9");
    }
    if (this.router.url.split("/")[1] == 'inspection-ticket-raise-ticket') {
      testData.append('ticketType', "2");
      testData.append('todaysScheduleId', this.pmId);
      testData.append('subInspectionId', this.subPmId);
    }
    if (this.router.url.split("/")[1] == 'pm-ticket-raise-ticket') {
      testData.append('todaysScheduleId', this.pmId);
      testData.append('subInspectionId', this.subPmId);
    }

    if (this.vedioUploadFile) {
      this.apiService.startLoader()
      let testData1: FormData = new FormData();
      testData1.append('file', this.vedioUploadFile);
      testData1.append('filename', this.vedioUploadFileName);
      this.apiService.postCall('tickets_videos/index.php/saveUploadedFile', {}, testData1).subscribe((result: any[]) => {
        if (result['success']) {
          let d = result['success'];
          testData.append("video", d.video);
          testData.append("videoTypeWeb", "0");
          this.apiService.startLoader()
          this.apiService.postCall('tickets/add', {}, testData).subscribe((result: any[]) => {
            // console.log(result);
            this.apiService.stopLoader()
          
            if(result['success'])this.message ='Ticket raised successfully';
            else this.message = result["msg"];

            (<HTMLInputElement>document.getElementById('openSuccess')).click();
          })
        } else {
          this.apiService.stopLoader()
        }
      })
    } else {
      this.apiService.startLoader()
      this.apiService.postCall('tickets/add', {}, testData).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        // this.assetList=[]
        if(result['success'])this.message ='Ticket raised successfully';
        else this.message = result["msg"];

        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      })
    }
  }

  uploadedFiles: any;
  uploadedFiles2: any;
  uploadedFiles3: any;
  fileName: any
  fileName2: any;
  fileName3: any;

  vedioUploadFile: any;
  vedioUploadFileName: any
  fileChangeVedio(event) {
    // console.log(event.target.files[0].type  )
    if (event.target.files[0].type == "video/mp4" || event.target.files[0].type == "video/quicktime" || event.target.files[0].type == "video/x-ms-wmv") {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.vedioUploadFile = file
        this.vedioUploadFileName = file.name




      }
    }
    else {

      this.message = "File with format " + event.target.files[0].type + " not acceptable, please choose Video";


      (<HTMLInputElement>document.getElementById('openError')).click();
    }
  }
  openFile() {
    (<HTMLInputElement>document.getElementById('uploadProfile')).click();
  }
  openVideo() {
    (<HTMLInputElement>document.getElementById('uploadProfileVedio')).click();
  }
  fileChange(event) {
    // console.log(event.target.files[0].type)

    // .png, .jpg, .jpeg ,  .mp4, .mov, .wmv,.pdf 
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
      var field = (<HTMLInputElement>document.getElementById('before_image'));


      // var image =<HTMLInputElement> document.getElementById('before_image');
      // image.src = URL.createObjectURL(event.target.files[0]);
      // console.log(this.fileName)
      if (!this.fileName) {
        var span = document.getElementById('images');
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
          var img = document.createElement('img');
          img.src = URL.createObjectURL(event.target.files[0]);
          img.id = "firstId";
          if (document.getElementById('firstId')) {
            document.getElementById('firstId').remove();
          }
          span.appendChild(img);

          // span.removeChild(img);
          img.style.height = "88px"
          // console.log(event)
        }
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles = file
          this.fileName = file.name




        }
      }
      else if (!this.fileName2) {
        var span = document.getElementById('images2');
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
          var img = document.createElement('img');
          img.src = URL.createObjectURL(event.target.files[0]);
          img.id = "firstId2";
          if (document.getElementById('firstId2')) {
            document.getElementById('firstId2').remove();
          }
          span.appendChild(img);

          // span.removeChild(img);
          img.style.height = "88px"
          // console.log(event)
        }
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles2 = file
          this.fileName2 = file.name




        }
      }
      else if (!this.fileName3) {
        var span = document.getElementById('images3');
        if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
          var img = document.createElement('img');
          img.src = URL.createObjectURL(event.target.files[0]);
          img.id = "firstId3";
          if (document.getElementById('firstId3')) {
            document.getElementById('firstId3').remove();
          }
          span.appendChild(img);

          // span.removeChild(img);
          img.style.height = "88px"
          // console.log(event)
        }
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
          let file: File = fileList[0];
          // console.log(file)
          this.uploadedFiles3 = file
          this.fileName3 = file.name




        }
      }
    }
    else {

      this.message = "File with format " + event.target.files[0].type + " not acceptable, please choose jpg";


      (<HTMLInputElement>document.getElementById('openError')).click();
    }
  }

  getValidation() {
    // console.log(this.addTicektForm.valid && this.userSelectedList[0])
    return this.addTicektForm.valid && this.userSelectedList[0] && this.addTicektForm.value.subject.trim() != ""
  }

  openQr() {
    if (this.addTicektForm.value.assetId) {
      let idx = this.assetList.findIndex(x => x.assetId == this.addTicektForm.value.assetId)
      this.storedData.qrData = { name: this.assetList[idx].assetName, image: this.assetList[idx].QRCode, customerComplaintName: "" }
      this.router.navigate(['print-qr'])
    }
  }


  assetDetails
  getAssetDetails() {
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/' + this.assetId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.assetDetails = result['assetDetails'];
      this.apiService.stopLoader()

      this.addTicektForm.patchValue({
        outletId: this.assetDetails.propertyId
      })

      this.getUserList()
    })
  }

  assignPriority(id) {
    this.addTicektForm.patchValue({
      priorityId: id
    })
  }


  assignDepartment(id) {
    this.addTicektForm.patchValue({
      departmentId: id
    })
  }

  priorityList = [{ id: 1, name: 'High' }, { id: 2, name: 'Medium' }, { id: 3, name: 'Low' }]


  fileChangeWarranty(event) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg") {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        this.uploadedFiles = file
        this.fileName = file.name


      }
    }
    else {

      this.message = "File with format " + event.target.files[0].type + " not acceptable, please choose jpg/video";


      (<HTMLInputElement>document.getElementById('openError')).click();
    }

  }




  goToLink(link) {

    const fileURL = URL.createObjectURL(link);
    // console.log(fileURL)
    window.open(fileURL, "_blank");
  }


  removeFile1() {

    if (document.getElementById('firstId')) {
      document.getElementById('firstId').remove();
    }
    this.uploadedFiles = undefined
    this.fileName = undefined
  }

  removeFile2() {

    if (document.getElementById('firstId2')) {
      document.getElementById('firstId2').remove();
    }
    this.uploadedFiles2 = undefined
    this.fileName2 = undefined
  }
  removeFile3() {

    if (document.getElementById('firstId3')) {
      document.getElementById('firstId3').remove();
    }
    this.uploadedFiles3 = undefined
    this.fileName3 = undefined
  }

  removeVedio() {
    this.vedioUploadFile = undefined
    this.vedioUploadFileName = undefined
  }
}




