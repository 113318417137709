import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-edit-amc',
  templateUrl: './edit-amc.component.html',
  styleUrls: ['./edit-amc.component.css']
})
export class EditAmcComponent implements OnInit {
  @Input() assetDetails: any;
  @Input() amcType:any;
  @Input() type: any;
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  constructor(public router:Router ,private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService, ) { }
  assetDetailsForm = this.fb.group({
    vendorName:[null,Validators.required],
    contactNo:[null , Validators.required],
    alternetNo:[null],
    email:[null , Validators.required],
    amcType:[null],
    startDate:[null , Validators.required],
    endDate:[null , Validators.required],
    RenewalDate:[null],
    scheduledService:[null , Validators.required],
    amcId:[null]
  });
  assetId:any;

  ngOnInit(): void {

    // console.log(this.assetDetails)
    // console.log(this.router.url.split("/")[2])
    this.assetId = this.router.url.split("/")[2]
  
  }

  scheduleService=["1","2","3","4"]
  public noOfService = [/[0-9]/, /\d/, /\d/, /\d/]
  // amcType=[1]

  ngOnChanges() {
    // console.log(this.type)
    // console.log(this.assetDetails);
    if (this.assetDetails) {
      this.assetDetailsForm.patchValue({
        assetId: this.assetDetails.assetId,
        vendorName:this.assetDetails.vendorName,
      contactNo:this.assetDetails.contactNo,
      alternetNo:this.assetDetails.alternetNo,
      email:this.assetDetails.email,
      amcType:this.assetDetails.amcType,
      startDate:new Date(this.assetDetails.startDateEdit),
      endDate:new Date(this.assetDetails.endDateEdit),
      RenewalDate:new Date(this.assetDetails.renewalDateEdit),
      scheduledService:this.assetDetails.scheduledService,
        amcId : this.assetDetails.id,
      })
    }

    // console.log(this.assetDetailsForm.value)
  }
  message

  getMobileValidation(data){
    if(data){
      // // console.log(data)
    return data.search('_') != -1
    }
  }


  submit(){

    // console.log(this.assetDetailsForm);
    let amcObj={
      
      vendorName:this.assetDetailsForm.value.vendorName,
      contactNo:parseInt(this.assetDetailsForm.value.contactNo),
      alternetNo:parseInt(this.assetDetailsForm.value.alternetNo),
      email:this.assetDetailsForm.value.email,
      amcType:this.assetDetailsForm.value.amcType,
      startDate:moment(this.assetDetailsForm.value.startDate).format('YYYY-MM-DD'),
      endDate:moment(this.assetDetailsForm.value.endDate).format('YYYY-MM-DD'),
      // RenewalDate:moment(this.assetDetailsForm.value.RenewalDate).format('YYYY-MM-DD'),
      scheduledService:this.assetDetailsForm.value.scheduledService,
      authId:this.apiService.userId,
      

    

  }

  if(!this.assetDetailsForm.value.amcId){
    amcObj["assetManagementId"] = this.assetId
  this.apiService.startLoader()
  this.apiService.postCall('amcs/add', {}, amcObj).subscribe((result: any[]) => {
    // console.log(result);
    
    this.apiService.stopLoader()
    if(result['success']){
    document.getElementById('dismissPopUp22').click()
    this.updateData.emit()
    }
  // this.message =result["msg"];


  // (<HTMLInputElement>document.getElementById('openSuccess')).click();
  })
}
else{

  this.apiService.startLoader()
  this.apiService.postCall('amcs/edit/'+this.assetDetailsForm.value.amcId, {}, amcObj).subscribe((result: any[]) => {
    // console.log(result);

    
    this.apiService.stopLoader()
    document.getElementById('dismissPopUp22').click()
    this.updateData.emit()
    
})
}
  }

  public contactmask= [ /[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

  errorFlag:boolean
  getValidation(){
    // console.log(this.getMobileValidation(this.assetDetailsForm.value.contactNo))
    this.errorFlag = false;
    return (this.assetDetailsForm.valid && !this.validateDate(this.assetDetailsForm.value.startDate,this.assetDetailsForm.value.endDate) && this.validateEmail(this.assetDetailsForm.value.email) 
    &&   !this.getMobileValidation(this.assetDetailsForm.value.contactNo)
    &&   !this.getMobileValidation(this.assetDetailsForm.value.alternetNo)
    )
    //  &&
    // !this.getMobileValidation(this.assetDetailsForm.value.alternetNo))
    
  }

  
  numberOnlyWarranty(){
    var str = new String(this.assetDetailsForm.value.scheduledService);
    // console.log(str.length)
    if(str.length>4) str = str.substring(0,4)

    this.assetDetailsForm.patchValue({
      scheduledService : str
    })
  }

  validateDate(satrtDate , endDate){
    // // console.log(satrtDate , endDate)
if(satrtDate && endDate){
   if(endDate <= satrtDate) return true
   else return false
  }
}

validateEmail(email) {
  if(email){
  const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regularExpression.test(String(email).toLowerCase());
  }
  else return true
 }
}
