import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-template-library',
  templateUrl: './template-library.component.html',
  styleUrls: ['./template-library.component.css']
})
export class TemplateLibraryComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public router: Router,
    public _location: Location,
  ) { }

  templetCategoryList: any
  templetList= []
  propertyList: []
  propertyId: any
  businessId: 0
  categoryId: ''
  selectedTemplateIds=[]

  importPermission=false
  ngOnInit(): void {
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "Import" && permission[i]['actionStatus'] == 1){
        this.importPermission=true
      }
    }

    if(this.apiService.roleId==1){

    }else if(this.apiService.roleId==2 || this.apiService.roleId==3){
      this.businessId= this.apiService.buisnessId
      let userId=0;
      if(this.apiService.roleId==3){
        userId=this.apiService.authId;
      }
      this.apiService.startLoader()
      this.apiService.getCall('outlets/outlet-list/' + this.businessId+"/"+userId, {}).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.propertyList = result['data']
      })
    }
    this.getTemplateCategoryList();
    this.getBusinessList();
  }

  setPagination(){
    localStorage.setItem("checklistIndexPagination",1+"");
  }
  getTemplateCategoryList() {
    this.apiService.startLoader()
    let obj={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.getCall('template-categories/index/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      this.apiService.stopLoader();
      if (result['success'] == true) {
        this.templetCategoryList = result['templateCategoryList'];
        let firstId = this.templetCategoryList[0].id;
        this.getTemplateData(firstId);
        //this.propertyList = result['propertyList'];
      } else {
        alert(result['msg']);
      }
    })
  }

  selectAll=false
  getTemplateData(id: any) {
    this.selectedTemplateIds = []
    this.categoryId = id;
    this.apiService.startLoader()
    let obj={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }

    this.apiService.postCall('inspection-templates/index/' + id, {} , obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      this.apiService.stopLoader();
      if (result['success'] == true) {
        this.propertyId=null;
        this.selectedTemplateIds=[]
        if(this.apiService.roleId==1){
          this.propertyList=[]
          this.businessId=null;
        }
        this.getBusinessList();

        this.templetList = result['templateList'];
      } else {
        this.templetList =[];
      }
    })
  }

  selectProperty(e) {
    this.propertyId = e.target.value;
    // console.log("PropertyId :", this.propertyId);
  }

  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  getPropertyList(e) {
    this.apiService.startLoader()
    this.businessId =e.target.value;
    // console.log(e.target.value)
    this.apiService.getCall('outlets/outlet-list/' + e.target.value, {}).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.propertyList = result['data']
    })
  }


  selectAllAssets(ev) {
    this.selectedTemplateIds = []
    if (ev.target.checked) {
      this.selectAll=true
      this.templetList.map(x => x.selected = true)
      for (let part of this.templetList) { 
        // console.log(part);
        this.selectedTemplateIds.push(part.id)
      }
    } else {
      this.selectAll=false
      this.templetList.map(x => x.selected = false)
      this.selectedTemplateIds = []
    }
    // console.log(this.selectedTemplateIds)
  }

  selectTemplate(ev, templateId : any) {
    // console.log(ev.target.checked, templateId)
    this.selectAll=false
    if (ev.target.checked) {
      this.selectedTemplateIds.push(templateId)
    }else {
      this.selectedTemplateIds.splice(this.selectedTemplateIds.findIndex(x => x == templateId), 1)
    }
  }

  message
  import(){
    let flag=true;
   if(this.apiService.roleId==1){
      if(!this.businessId || !this.propertyId || this.selectedTemplateIds.length == 0){
        if(!this.businessId){
          flag=false;
          this.message = 'Please select Business';
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }else if(!this.propertyId){
          flag=false;
          this.message = 'Please select Property';
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }else if(this.selectedTemplateIds.length == 0){
          flag=false;
          this.message = 'Please select Checklist';
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }
      }
    }
    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      if(!this.businessId || !this.propertyId || this.selectedTemplateIds.length == 0){
        if(!this.businessId){ 
          flag=false;
          this.message = 'Please select Business';
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }else if(!this.propertyId){
          flag=false;
          this.message = 'Please select Property';
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }else if(this.selectedTemplateIds.length == 0){
          flag=false;
          this.message = 'Please select Checklist';
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }
      }
    }
    if(flag){
      this.apiService.startLoader()
      let obj={
        authId:this.apiService.authId,
        businessId:this.businessId,
        outletId:this.propertyId,
        selectedTemplateIds:this.selectedTemplateIds,
        categoryId:this.categoryId 
      }
      this.apiService.postCall('inspection-templates/import', {},obj).subscribe((result: any[]) => {
        this.apiService.stopLoader();
        this.selectAll=false
        if (result['success'] == true) {
          this.propertyId = null
          if(this.apiService.role==1){
            this.businessId=null;
          }
          // this.propertyId=null;
          this.selectedTemplateIds=[]
          
          this.getBusinessList();
          this.templetList.map(x => x.selected = false)
           this.message = result["msg"];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        } else {
          this.message = result["msg"];
          (<HTMLInputElement>document.getElementById('openSuccess')).click();
        }
        if(this.apiService.role==1){
          this.propertyList=[]
        }
      })
    }
   }

}
