import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../common/services/apiCalls.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { StoredData } from '../../common/services/storedData.service';

@Component({
  selector: 'app-parts-assets',
  templateUrl: './parts-assets.component.html',
  styleUrls: ['./parts-assets.component.css']
})
export class PartsAssetsComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData) { }

  partsId: any;
  partsDetails: any
  partsStatus=[]
  partsVendorName=''
  assetList:[]
  saveNotify=false
  ngOnInit(): void {
    this.apiService.startLoader()
    this.partsId = this.router.url.split("/")[2]
    this.apiService.getCall('parts/showDetails/' + this.partsId + "/" + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.partsDetails = result['part']
      this.partsStatus = result['statusList']

      let idx2 = this.partsStatus.findIndex(x=>x.name== "Breakdown")
      this.partsStatus.splice(idx2,1)
    
      // console.log(this.partsDetails.vendors);
      if(this.partsDetails.vendors && this.partsDetails.vendors.length > 0){
        let vendors = this.partsDetails.vendors;
        if(this.partsDetails.vendors.length > 1)this.partsVendorName = "Multiple"
        else this.partsVendorName = this.partsDetails.vendors[0].name
      }
      this.apiService.stopLoader()
    })

    this.getPartsAssets();
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==5 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Update Notify" && permission[i]['actionStatus'] == 1){
        this.saveNotify=true
      }
    }
  }

  
  getAssetDetail(){
    this.ngOnInit()
  }

  navigateToDetails(d) {
    // console.log(d)
    
  this.route.navigate(['/asset-details/' + d.assetManagementId ])
    
  }

  getPartsAssets(){
    this.apiService.startLoader()
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize
    };
    this.partsId = this.router.url.split("/")[2]
    this.apiService.postCall('parts-assets/assetListPart/' + this.apiService.buisnessId+ "/" +this.partsId, {},body).subscribe((result: any[]) => {
      this.assetList = result['assets']['data']
      this.apiService.stopLoader()
      // console.log(result['assets']['data']);
      this.setPaginationArray(result['assets']);
    })
  }
  paginationArray=[]
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.paginationArray = []
    for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    // console.log(this.paginationArray)
  }


  activePage = 1
  pageSize = 10
  pagination(data) {
    if(data != 0 && data <= this.paginationArray.length)
    if(data != this.activePage){
      this.activePage = data
      this.getPartsAssets()
    }
  }
  changePage(ev) {
    // console.log(this.pageSize)
    this.getPartsAssets();
   
  }



  uploadedFiles: any
  fileName: any;
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name
    }
    let testData: FormData = new FormData();

    testData.append('image', this.uploadedFiles);
    testData.append("partId", this.partsDetails.partId);
    testData.append("authId", this.apiService.authId);
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.ngOnInit();
      document.getElementById('dismissPopUp').click()

    })

  }

  message
  changeStatus() {
    let testData: FormData = new FormData();
    testData.append("status", this.partsDetails.status);
    testData.append("authId", this.apiService.authId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/changeStatus/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      document.getElementById('dismissPopUp').click()

    })
  }
  closeAll() {
    (<HTMLInputElement>document.getElementById('closePopUp')).click();
  }

  remove(d) {
    this.apiService.startLoader()
    this.apiService.getCall('parts-assets/delete/' + d, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.getPartsAssets();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      document.getElementById('dismissPopUp').click()
    })
  }










}

