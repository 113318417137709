import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import * as moment from 'moment';
@Component({
  selector: 'app-assets-details-pm-create-template',
  templateUrl: './assets-details-pm-create-template.component.html',
  styleUrls: ['./assets-details-pm-create-template.component.css']
})
export class AssetsDetailsPMCreateTemplateComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  assetDetailsForm: FormGroup;
  amcDetailsForm: FormGroup;
  warrantyDetailsForm: FormGroup;

  statusList = []
  scheduleService = ["1", "2", "3", "4"]
  amcType = [1]

  addAmc = false;
  addWarrant = false;
  tabselected = "step1"
  changePage
  pageSize

  conditionListing =[{ "id": "Used", "name": "Used" }, { "id": "New", "name": "New" }]

  numberOnlyAmc(){
    var str = new String(this.amcDetailsForm.value.scheduledService);
    // console.log(str.length)
    str=str.replace('.', "");
    if(str.length>4) str = str.substring(0,4)

    this.amcDetailsForm.patchValue({
      scheduledService : str
    })
  }

  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }

  numberOnlyWarranty(){
    var str = new String(this.warrantyDetailsForm.value.scheduledService);
    // console.log(str.length)
    str=str.replace('.', "");
    if(str.length>4) str = str.substring(0,4)

    this.warrantyDetailsForm.patchValue({
      scheduledService : str
    })
  }

  trimmedValue(data){

     return data.replace(/_/g,"")
  }
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.assetDetailsForm = this.fb.group({
      assetName: [null ,Validators.required],
      make: [null],
      specRating: [null],
      srNo: [null],
      locationId: [null ,Validators.required],
      purchaseDate: [null],
      placeInService: [null],
      propertyId: [null , Validators.required],
      businessId: [null],
      model: [null],
      specification: [null ],
      isOperational: [null],
      renewalDate: [null ],
      warrantyExpiration: [null ],

      vendorName: [null],
      categoryName: [null],
      conditions: [null ],
      slaExpiryDate: [null ],

      ownership: [null],
      assetTagNo: [null ],
      status: [null , Validators.required],

    });

    this.amcDetailsForm = this.fb.group({

      vendorName: [null , Validators.required],
      contactNo: [null , Validators.required],
      alternetNo: [null],
      email: [null ,Validators.required],
      amcType: [null , Validators.required],
      startDate: [null ,Validators.required],
      endDate: [null , Validators.required],
      RenewalDate: [null ],
      scheduledService: [null ,Validators.required]


    });

    this.warrantyDetailsForm = this.fb.group({
      vendorName: [null , Validators.required],
      contactNo: [null , Validators.required],
      alternetNo: [null],
      email: [null , Validators.required],
      amcType: [null],
      startDate: [null , Validators.required],
      endDate: [null , Validators.required] ,
      RenewalDate: [null],
      scheduledService: [null ,Validators.required]


    });

    // this.getStatusList()
    this.getPropertyList()

    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "statusNotIn":"5,8",
      // "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
    }
    
    this.apiService.postCall('asset-managements/index/' + this.apiService.buisnessId, {}, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
     
      this.statusList = result['statusList']
      // let idx = this.statusList.findIndex(x=>x .name== "In Migration")
      // this.statusList.splice(idx,1)

      // let idx1 = this.statusList.findIndex(x=>x .name== "In Transit")
      // this.statusList.splice(idx1,1)
     
    })
  }

  isFieldValid(field, form) {
      return this.errorFlag && !form.get(field).valid ;
  }

  errorFlag:boolean;



  save() {


    // console.log(this.assetDetailsForm.value);

    // var amc = {
    //   vendorName: this.amcDetailsForm.value.vendorName,
    //   contactNo: this.amcDetailsForm.value.contactNo,
    //   alternetNo: this.amcDetailsForm.value.alternetNo,
    //   email: this.amcDetailsForm.value.email,
    //   amcType: this.amcDetailsForm.value.amcType,
    //   startDate: moment(this.amcDetailsForm.value.startDate).format('YYYY-MM-DD'),
    //   endDate: moment(this.amcDetailsForm.value.endDate).format('YYYY-MM-DD'),
    //   RenewalDate: moment(this.amcDetailsForm.value.RenewalDate).format('YYYY-MM-DD'),
    //   scheduledService: this.amcDetailsForm.value.scheduledService,
    //   file: ''
    // };

    let assetObj = {
      assetName: this.assetDetailsForm.value.assetName,
      assetTagNo: this.assetDetailsForm.value.assetTagNo,
      authId: this.apiService.userId,
      businessId: parseInt(this.apiService.buisnessId),
      categoryName: this.assetDetailsForm.value.categoryName,
      conditions: this.assetDetailsForm.value.conditions,
      isOperational: 1,
      locationId: this.assetDetailsForm.value.locationId ? parseInt(this.assetDetailsForm.value.locationId.id) : 0,
      make: this.assetDetailsForm.value.make,
      model: this.assetDetailsForm.value.model,
      ownership: this.assetDetailsForm.value.ownership,
      placeInService: moment(this.assetDetailsForm.value.placeInService).format('YYYY-MM-DD HH:MM:SS'),
      purchaseDate: moment(this.assetDetailsForm.value.purchaseDate).format('YYYY-MM-DD HH:MM:SS'),
      propertyId: parseInt(this.assetDetailsForm.value.propertyId.id),
      remark: 3,
      // renewalDate: moment(this.assetDetailsForm.value.warrantyExpiration).format('YYYY-MM-DD HH:MM:SS'),
      warrantyExpiration: this.assetDetailsForm.value.warrantyExpiration ? moment(this.assetDetailsForm.value.warrantyExpiration).format('YYYY-MM-DD HH:MM:SS'):null,
      slaExpiryDate: this.assetDetailsForm.value.warrantyExpiration ? moment(this.assetDetailsForm.value.warrantyExpiration).format('YYYY-MM-DD'):null,
      specRating: this.assetDetailsForm.value.specRating,
      specification: "spec",
      srNo: this.assetDetailsForm.value.srNo,
      status: this.assetDetailsForm.value.status.id,
      vendorName: this.assetDetailsForm.value.vendorName,
      warraantyAlternateNo: this.warrantyDetailsForm.value.alternetNo,
      warraantyAttach: "",
      warraantyContactNo: this.warrantyDetailsForm.value.contactNo,
      warraantyEmail: this.warrantyDetailsForm.value.email,
      warraantyEndDate: this.warrantyDetailsForm.value.endDate,
      // warraantyRenewalDate: this.warrantyDetailsForm.value.RenewalDate,
      warraantySheduledServices: this.warrantyDetailsForm.value.scheduledService,
      warraantyStartDate: this.warrantyDetailsForm.value.startDate,
      warraantyVendorName: this.warrantyDetailsForm.value.vendorName,
      // amc: amc
    }

    // console.log(assetObj);

    this.apiService.startLoader()
    this.apiService.postCall('asset-managements/add', {}, assetObj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if(result['success']){
      
      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      else{
        this.message = result["msg"];


        (<HTMLInputElement>document.getElementById('openError')).click();
      }
      let amcObj = {
        assetManagementId: result['assetId'],
        vendorName: this.amcDetailsForm.value.vendorName,
        contactNo: this.amcDetailsForm.value.contactNo,
        alternetNo: this.amcDetailsForm.value.alternetNo,
        email: this.amcDetailsForm.value.email,
        amcType: this.amcDetailsForm.value.amcType,
        startDate: moment(this.amcDetailsForm.value.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.amcDetailsForm.value.endDate).format('YYYY-MM-DD'),
        // RenewalDate: moment(this.amcDetailsForm.value.RenewalDate).format('YYYY-MM-DD'),
        scheduledService: this.amcDetailsForm.value.scheduledService,
        authId: this.apiService.userId



      }
      if (this.addAmc) {
        // this.apiService.startLoader()
        this.apiService.postCall('amcs/add', {}, amcObj).subscribe((result: any[]) => {
          // console.log(result);
          // this.apiService.stopLoader()
          //this.route.navigate(['/home']);

        // this.message =result["msg"];

        
          //(<HTMLInputElement>document.getElementById('navigattohome')).click(); 

      
        

        })
      } else {
      //   setTimeout(() => {
      //     (<HTMLInputElement>document.getElementById('navigattohome')).click();

      // }, 3000);
     
      }
    })


  }
  message

  amcEmailFlag = false
  warEmailFlag = false
  assetNameFlag = false
  statusFlag = false
  placeServiceFlag = false
  specRatingFlag = false
  datePerchaFlag = false
  warrantyExpiFlag = false
  tagNoFlag = false
  propertyFlag = false
  ownershipFlag =false
  locationFlag=false

  dateValid= false;
  changePageNext(step) {
    this.errorFlag= true;
    this.amcEmailFlag = false
    this.warEmailFlag = false
    this.assetNameFlag = false
    this.statusFlag = false
    this.placeServiceFlag = false
    this.specRatingFlag = false
    this.datePerchaFlag = false
    this.warrantyExpiFlag = false
    this.tagNoFlag = false
    this.propertyFlag = false
    this.ownershipFlag =false
    this.locationFlag=false

    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (this.amcDetailsForm.value.email != null) {
      this.amcEmailFlag = false;
      if (this.amcDetailsForm.value.email.length <= 5 || !EMAIL_REGEXP.test(this.amcDetailsForm.value.email)) {
        this.amcEmailFlag = true;
      }
      else if(this.validateEmail(this.amcDetailsForm.value.email)){
        this.amcEmailFlag = true;
      }
    }
    if (this.warrantyDetailsForm.value.email != null) {
      this.warEmailFlag = false;
      if (this.warrantyDetailsForm.value.email.length <= 5 || !EMAIL_REGEXP.test(this.warrantyDetailsForm.value.email)) {
        this.warEmailFlag = true;
      }
      else if(this.validateEmail(this.warrantyDetailsForm.value.email)){
        this.amcEmailFlag = true;
      }
    }
    if (this.assetDetailsForm.value.assetName == null || this.assetDetailsForm.value.assetName == "") {
      this.assetNameFlag = true;
    }
    if (this.assetDetailsForm.value.status == null ||  this.assetDetailsForm.value.status.id == "") {
      this.statusFlag = true;
    }
    if (this.assetDetailsForm.value.placeInService == null ||  this.assetDetailsForm.value.placeInService == "") {
      //this.placeServiceFlag = true;
    }
    // if (this.assetDetailsForm.value.specRating == null ||  this.assetDetailsForm.value.specRating == "") {
    //   this.specRatingFlag = true;
    // }
    if (this.assetDetailsForm.value.purchaseDate == null || this.assetDetailsForm.value.purchaseDate == "") {
      //this.datePerchaFlag = true;
    }
    // if (this.assetDetailsForm.value.warrantyExpiration == null ||  this.assetDetailsForm.value.warrantyExpiration == "") {
    //   this.warrantyExpiFlag = true;
    // }
    // if (this.assetDetailsForm.value.assetTagNo == null || this.assetDetailsForm.value.assetTagNo == "") {
    //   this.tagNoFlag = true;
    // }
    if (this.assetDetailsForm.value.propertyId == null || this.assetDetailsForm.value.propertyId.id == "") {
      this.propertyFlag = true;
    }
    if (this.assetDetailsForm.value.ownership == null || this.assetDetailsForm.value.ownership == "") {
      //this.ownershipFlag = true;
    }
    if (this.assetDetailsForm.value.locationId == null || this.assetDetailsForm.value.locationId.id == "") {
      this.locationFlag = true;
    }

    // if (this.validateDate(this.warrantyDetailsForm.value.startDate,this.warrantyDetailsForm.value.endDate)) {
    //   this.dateValid = true;
    // }
    
    // if (this.validateDate(this.amcDetailsForm.value.startDate,this.amcDetailsForm.value.endDate)) {
    //   this.dateValid = true;
    // }


    

    if((this.addWarrant && !this.warrantyDetailsForm.valid)|| (this.dateValid) || (this.addAmc && !this.amcDetailsForm.valid) || this.locationFlag || this.amcEmailFlag || this.warEmailFlag || this.assetNameFlag || this.statusFlag || this.placeServiceFlag || this.specRatingFlag || this.datePerchaFlag || this.warrantyExpiFlag || this.tagNoFlag || this.propertyFlag || 
      this.getMobileValidation(this.amcDetailsForm.value.contactNo) ||
      this.getMobileValidation(this.amcDetailsForm.value.alternetNo) ||
      this.getMobileValidation(this.warrantyDetailsForm.value.contactNo) ||
      this.getMobileValidation(this.warrantyDetailsForm.value.alternetNo)){ 
      this.message = "Please enter all the required fileds";


      (<HTMLInputElement>document.getElementById('validationButton')).click();
      return false;
    }

    

    this.tabselected = step;
  }

  propertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
    }
   
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {} , obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.propertyList = result['data']
      this.amcTypeList = result['amcTypes']
      let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)
      // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
      //   this.assetDetailsForm.patchValue({
      //     propertyId : this.propertyList[idx]
      //   })
      //   this.getLocationList()
      // }

    })
  }
  userList = []
  locationList = []
  amcTypeList = []
  getLocationList() {
    this.apiService.startLoader()

    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.assetDetailsForm.value.propertyId?this.assetDetailsForm.value.propertyId.id : "",
    }
   
    // http://52.14.126.174/synccit/locations/location-list/:businessId/:outletId
    this.apiService.postCall('locations/location-list/' + this.apiService.buisnessId + '/' + this.assetDetailsForm.value.propertyId.id, {} , obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.locationList = result['data']



    })

    
    let obj1 ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.assetDetailsForm.value.propertyId.id,
    }

    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.assetDetailsForm.value.propertyId.id, {} , obj1).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.userList = result['data'];
      // console.log("List of Users", this.userList);

    })
  }

  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  public noOfService = [/[0-9]/, /\d/, /\d/, /\d/]
  public emailMask = [/[a-z]/, '@', /[a-z]/, '.', /[a-z]/]


  getOwnerShipName(data) {
    let idx = this.userList.findIndex(x => x.id == data)
    if (idx != -1) return this.userList[idx].fullName
    else return ""
  }

getMobileValidation(data){
  if(data)
  return this.errorFlag && data.search('_') != -1
}
  getValidation(){
    //  // console.log(this.addAmc && this.amcDetailsForm.value.contactNo.search('_') == -1)
    return (this.assetDetailsForm.valid && !this.validateDate(this.amcDetailsForm.value.startDate,this.amcDetailsForm.value.endDate) && !this.validateDate(this.warrantyDetailsForm.value.startDate,this.warrantyDetailsForm.value.endDate) && !this.validateDate(this.assetDetailsForm.value.purchaseDate,this.assetDetailsForm.value.placeInService) && !this.validateDate(this.assetDetailsForm.value.purchaseDate,this.assetDetailsForm.value.warrantyExpiration)
    && !this.validateDate(this.assetDetailsForm.value.purchaseDate,this.amcDetailsForm.value.startDate) && !this.validateDate(this.assetDetailsForm.value.purchaseDate,this.warrantyDetailsForm.value.startDate)
    
    //  && !(this.addAmc && this.amcDetailsForm.value.contactNo.search('_') != -1)
    //  && (this.addAmc && this.amcDetailsForm.value.alternetNo.search('_') == -1) && (this.addWarrant && this.warrantyDetailsForm.value.contactNo.search('_') == -1) && (this.addWarrant && this.warrantyDetailsForm.value.alternetNo.search('_') == -1)
     
     )
  }


  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return this.errorFlag && !regularExpression.test(String(email).toLowerCase());
   }

   validateDate(satrtDate , endDate){
    //  // console.log(satrtDate , endDate)
if(satrtDate && endDate){
    if(endDate < satrtDate) return true
    else return false
   }
  }

  maxLength=10

  
  checkValue(){
    // console.log(this.amcDetailsForm.value.contactNo.length)
    if(this.amcDetailsForm.value.contactNo.length>10){
      // console.log("enter", this.amcDetailsForm.value.contactNo.substring(0, 10))
      this.amcDetailsForm.value.contactNo = this.amcDetailsForm.value.contactNo.substring(0, 10)
      this.amcDetailsForm.patchValue({
        contactNo :this.amcDetailsForm.value.contactNo.substring(0, 10)
      })
    }
  }
}


// 43)Amc add


