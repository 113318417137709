import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/services/apiCalls.service';
import { StoredData } from 'src/app/common/services/storedData.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ticket-escalation',
  templateUrl: './ticket-escalation.component.html',
  styleUrls: ['./ticket-escalation.component.css']
})
export class TicketEscalationComponent implements OnInit {
  escalationForm: FormGroup
  accepted: boolean = true;

  constructor(public _location: Location, public route: Router, public router: Router, private fb: FormBuilder, public apiService: ApiService, public storedData: StoredData,) { }


  backClicked() {
    this._location.back();
  }

  clear(){
    this.outletId=null
    this.departmentId=null
    this.getData()
    for (let i = 0; i <= 30; i++)this.dayArray.push(i)
    for (let i = 0; i <= 23; i++)this.hoursArray.push(i)
    for (let i = 0; i <= 59; i++)this.minutesArray.push(i)
    this.level1userArray = []
    this.level2userArray = []
    this.level3userArray = []
    this.level4userArray = []
    this.level5userArray = []

    this.escalationForm = this.fb.group({
      level1user: [null],
      level2user: [null],
      level3user: [null],
      level4user: [null],
      level5user: [null],

      level1minute: [null],
      level2minute: [null],
      level3minute: [null],
      level4minute: [null],
      level5minute: [null],

      level1hours: [0],
      level2hours: [0],
      level3hours: [0],
      level4hours: [0],
      level5hours: [0],

      level1days: [0],
      level2days: [0],
      level3days: [0],
      level4days: [0],
      level5days: [0],

      level1checked: false,
      level2checked: false,
      level3checked: false,
      level4checked: false,
      level5checked: false,
    })
  }

  getDisabledList(list ,data){

    let idx  = list.findIndex(x=> x.id == data.id)
    if(idx != -1 ) return true
  }

  dayArray = []
  hoursArray = []
  minutesArray = []
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.getData()
    for (let i = 0; i <= 30; i++)this.dayArray.push(i)
    for (let i = 0; i <= 23; i++)this.hoursArray.push(i)
    for (let i = 0; i <= 59; i++)this.minutesArray.push(i)


    this.escalationForm = this.fb.group({
      level1user: [null],
      level2user: [null],
      level3user: [null],
      level4user: [null],
      level5user: [null],

      level1minute: [null],
      level2minute: [null],
      level3minute: [null],
      level4minute: [null],
      level5minute: [null],

      level1hours: [0],
      level2hours: [0],
      level3hours: [0],
      level4hours: [0],
      level5hours: [0],

      level1days: [0],
      level2days: [0],
      level3days: [0],
      level4days: [0],
      level5days: [0],

      level1checked: false,
      level2checked: false,
      level3checked: false,
      level4checked: false,
      level5checked: false,
    })
  }

  propertyList = []
  departmentList = []
  userList = []
  getData() {

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.propertyList = result['data']
      // console.log(result)
      this.departmentList = result['departments']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // console.log(this.apiService.roleId)
      if (this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx != -1) {

        this.outletId = this.propertyList[idx].id

        // this.getLocationList()
      }
    })


    let obj1 = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.apiService.outletId,
    }

    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.apiService.outletId, {}, obj1).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      // // console.log(jsonToBeUsed)
      this.userList = result['data']
    },
      error => {
        this.apiService.stopLoader()
      });
  }

  getUserList() {

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.outletId,
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId + '/' + this.outletId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.getexcelationData()
      this.userList = result['data']
    },
      error => {
        this.apiService.stopLoader()
      });
  }

  outletId = null;
  departmentId = null;
  getexcelationData() {
    if (!this.departmentId) {
      return false;
    }
    let body = {
      businessId: this.apiService.buisnessId,
      outletId: this.outletId,
      departmentId: this.departmentId,

    }

    this.apiService.startLoader()
    this.apiService.postCall('departmentSla/index', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // console.log(result)
      let escelateData = result['data']
      // result['data'].findIndex(x=> x.level )
      if(escelateData){
        if (escelateData[0].level) {
          var str = escelateData[0].time;
          var daystr = str.replace(/Day/gi, ",");
          var minString = daystr.replace(/Hours/gi, ",");
          var newstr = minString.replace(/Minutes/gi, "");
          let myArray = newstr.split(',')
          // console.log(myArray)
          this.escalationForm.patchValue({
            level1checked: true,
            level1hours: parseInt(myArray[1]),
            level1minute: parseInt(myArray[2]),
            level1days: parseInt(myArray[0]),

          })

          this.level1userArray = this.getUSer(escelateData[0].users)
        }


      if (escelateData[1].level) {
        var str = escelateData[1].time;
        var daystr = str.replace(/Day/gi, ",");
        var minString = daystr.replace(/Hours/gi, ",");
        var newstr = minString.replace(/Minutes/gi, "");
        let myArray = newstr.split(',')
        // console.log(myArray)
        this.escalationForm.patchValue({
          level2checked: true,
          level2hours: parseInt(myArray[1]),
          level2minute: parseInt(myArray[2]),
          level2days: parseInt(myArray[0]),

        })
        this.level2userArray = this.getUSer(escelateData[1].users)
      }

      if (escelateData[2].level) {
        var str = escelateData[2].time;
        var daystr = str.replace(/Day/gi, ",");
        var minString = daystr.replace(/Hours/gi, ",");
        var newstr = minString.replace(/Minutes/gi, "");
        let myArray = newstr.split(',')
        // console.log(myArray)
        this.escalationForm.patchValue({
          level3checked: true,
          level3hours: parseInt(myArray[1]),
          level3minute: parseInt(myArray[2]),
          level3days: parseInt(myArray[0]),

        })
        this.level3userArray = this.getUSer(escelateData[2].users)
      }


      if (escelateData[3].level) {
        var str = escelateData[3].time;
        var daystr = str.replace(/Day/gi, ",");
        var minString = daystr.replace(/Hours/gi, ",");
        var newstr = minString.replace(/Minutes/gi, "");
        let myArray = newstr.split(',')
        // console.log(myArray)
        this.escalationForm.patchValue({
          level4checked: true,
          level4hours: parseInt(myArray[1]),
          level4minute: parseInt(myArray[2]),
          level4days: parseInt(myArray[0]),

        })
        this.level4userArray = this.getUSer(escelateData[3].users)
      }

      if (escelateData[4].level) {
        var str = escelateData[4].time;
        var daystr = str.replace(/Day/gi, ",");
        var minString = daystr.replace(/Hours/gi, ",");
        var newstr = minString.replace(/Minutes/gi, "");
        let myArray = newstr.split(',')
        // console.log(myArray)
        this.escalationForm.patchValue({
          level5checked: true,
          level5hours: parseInt(myArray[1]),
          level5minute: parseInt(myArray[2]),
          level5days: parseInt(myArray[0]),

        })
        this.level5userArray = this.getUSer(escelateData[4].users)
      }
    }else{
        this.escalationForm.patchValue({ 
          level1checked: false,
          level1hours: 0,
          level1minute: null,
          level1days: 0,
        })
        this.level1userArray = []


      this.escalationForm.patchValue({
        level2checked: false,
        level2hours: 0,
        level2minute: null,
        level2days: 0,

      })
      this.level2userArray = []

      this.escalationForm.patchValue({
        level3checked: false,
        level3hours: 0,
        level3minute: null,
        level3days: 0,

      })
      this.level3userArray = []

      this.escalationForm.patchValue({
        level4checked: false,
        level4hours: 0,
        level4minute: null,
        level4days: 0,

      })
      this.level4userArray = []
      this.escalationForm.patchValue({
        level5checked: false,
        level5hours: 0,
        level5minute: null,
        level5days: 0,

      })
      this.level5userArray = []
    }

    })
  }

  removeUserLevel1(d,i) {
    this.level1userArray.splice(i, 1)
  }
  removeUserLevel2(d , i) {
    this.level2userArray.splice(i, 1)
  }
  removeUserLevel3(d , i) {
    this.level3userArray.splice(i, 1)
  }
  removeUserLevel4(d , i) {
    this.level4userArray.splice(i, 1)
  }
  removeUserLevel5(d , i) {
    this.level5userArray.splice(i, 1)
  }
  getUSer(data) {

    let array = []
    let myArray = data.split(',')

    for (let d of myArray) {
      let idx = this.userList.findIndex(x => x.fullName == d)

      if (idx != -1) array.push(this.userList[idx])
      // else return null
    }
    // console.log(array)

    return array
  }



  level1userArray = []
  level2userArray = []
  level3userArray = []
  level4userArray = []
  level5userArray = []

  selectUser(d) {
    if (d == 'level1') {
      this.level1userArray.push(this.escalationForm.value.level1user)
      this.escalationForm.patchValue({ level1user: null })
    }
    else if (d == 'level2') {
      this.level2userArray.push(this.escalationForm.value.level2user)
      this.escalationForm.patchValue({ level2user: null })
    }
    else if (d == 'level3') {
      this.level3userArray.push(this.escalationForm.value.level3user)
      this.escalationForm.patchValue({ level3user: null })
    }
    if (d == 'level4') {
      this.level4userArray.push(this.escalationForm.value.level4user)
      this.escalationForm.patchValue({ level4user: null })
    }
    if (d == 'level5') {
      this.level5userArray.push(this.escalationForm.value.level5user)
      this.escalationForm.patchValue({ level5user: null })
    }
  }

  message: any;
  save() {
    let userArry1 = []
    for (let d of this.level1userArray) userArry1.push(JSON.stringify(d.id))

    let userArry2 = []
    for (let d of this.level2userArray) userArry2.push(JSON.stringify(d.id))

    let userArry3 = []
    for (let d of this.level3userArray) userArry3.push(JSON.stringify(d.id))

    let userArry4 = []
    for (let d of this.level4userArray) userArry4.push(JSON.stringify(d.id))

    let userArry5 = []
    for (let d of this.level5userArray) userArry5.push(JSON.stringify(d.id))

    let body = {
      businessId: this.apiService.buisnessId,
      outletId: this.outletId,
      departmentId: this.departmentId,
      check1: this.escalationForm.value.level1checked ? 1 : 0,
      level1Day: this.escalationForm.value.level1days ? this.escalationForm.value.level1days : '',
      level1Hour: this.escalationForm.value.level1hours ? this.escalationForm.value.level1hours : '',
      level1Min: this.escalationForm.value.level1minute ? this.escalationForm.value.level1minute : '',
      level1User: userArry1[0] ? userArry1 : '',
      check2: this.escalationForm.value.level2checked ? 1 : 0,
      level2Day: this.escalationForm.value.level2days ? this.escalationForm.value.level2days : '',
      level2Hour: this.escalationForm.value.level2hours ? this.escalationForm.value.level2hours : '',
      level2Min: this.escalationForm.value.level2minute ? this.escalationForm.value.level2minute : '',
      level2User: userArry2[0] ? userArry2 : '',
      check3: this.escalationForm.value.level3checked ? 1 : 0,
      level3Day: this.escalationForm.value.level3days ? this.escalationForm.value.level3days : '',
      level3Hour: this.escalationForm.value.level3hours ? this.escalationForm.value.level3hours : '',
      level3Min: this.escalationForm.value.level3minute ? this.escalationForm.value.level3minute : '',
      level3User: userArry3[0] ? userArry3 : '',
      check4: this.escalationForm.value.level4checked ? 1 : 0,
      level4Day: this.escalationForm.value.level4days ? this.escalationForm.value.level4days : '',
      level4Hour: this.escalationForm.value.level4hours ? this.escalationForm.value.level4hours : '',
      level4Min: this.escalationForm.value.level4minute ? this.escalationForm.value.level4minute : '',
      level4User: userArry4[0] ? userArry4 : '',
      check5: this.escalationForm.value.level5checked ? 1 : 0,
      level5Day: this.escalationForm.value.level5days ? this.escalationForm.value.level5days : '',
      level5Hour: this.escalationForm.value.level5hours ? this.escalationForm.value.level5hours : '',
      level5Min: this.escalationForm.value.level5minute ? this.escalationForm.value.level5minute : '',
      level5User: userArry5[0] ? userArry5 : '',

    }


    this.apiService.startLoader()
    // console.log(body)
    this.apiService.postCall('departmentSla/sla', {}, body).subscribe((result: any[]) => {

      // console.log(result)
      this.apiService.stopLoader()

      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    }
      ,
      error => {
        this.apiService.stopLoader()
      });


  }


  validate() {
    let valid = true
    // console.log(this.escalationForm.value.level1daysx)
    if (!this.escalationForm.value.level1checked && !this.escalationForm.value.level2checked && !this.escalationForm.value.level3checked && !this.escalationForm.value.level4checked && !this.escalationForm.value.level5checked) valid = false
    if (this.escalationForm.value.level1checked && (this.escalationForm.value.level1days == null || this.escalationForm.value.level1minute == null || this.escalationForm.value.level1hours == null || !this.level1userArray[0])) valid = false;
    if (this.escalationForm.value.level2checked && (this.escalationForm.value.level2days == null || this.escalationForm.value.level2minute == null || this.escalationForm.value.level2hours == null || !this.level2userArray[0])) valid = false;
    if (this.escalationForm.value.level3checked && (this.escalationForm.value.level3days == null || this.escalationForm.value.level3minute == null || this.escalationForm.value.level3hours == null || !this.level3userArray[0])) valid = false;
    if (this.escalationForm.value.level4checked && (this.escalationForm.value.level4days == null || this.escalationForm.value.level4minute == null || this.escalationForm.value.level4hours == null || !this.level4userArray[0])) valid = false;
    if (this.escalationForm.value.level5checked && (this.escalationForm.value.level5days == null || this.escalationForm.value.level5minute == null || this.escalationForm.value.level5hours == null || !this.level5userArray[0])) valid = false;

    return valid
  }


  chengeLevel2() {
    this.escalationForm.patchValue({

      level3checked: false,
      level4checked: false,
      level5checked: false,
    })

  }

  chengeLevel3() {
    this.escalationForm.patchValue({

      level4checked: false,
      level5checked: false,
    })

  }

  chengeLevel4() {
    this.escalationForm.patchValue({


      level5checked: false,
    })

  }

  chengeLevel1() {
    this.escalationForm.patchValue({

      // level1checked:false,
      level2checked: false,
      level3checked: false,
      level4checked: false,
      level5checked: false,
    })

  }
}
