import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {Location} from '@angular/common';
@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.css']
}) 
export class InsuranceListComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, public storedData: StoredData) { }
  type

  addPermission=false
  editPermission=false
  viewPermission=false

  getIds(ev){
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property=ev.property
    if(ev.flag==1){
      this.clear()
    }else if(ev.flag==0){
      this.applyFilter()
    }
  
    // console.log(ev);
    //this.applyFilter();
  }
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {

    var insuranceListPagination = localStorage.getItem('insuranceListPagination');
    if(insuranceListPagination){
      this.activePage = parseInt(insuranceListPagination);
    }

    //this.type = this.route.url.split("/")[2]
    this.getInsurenceList();
    this.getBusinessList();
    this.getPropertyList();
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==9 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1){
        this.addPermission=true
      }
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermission=true
      }
      if(permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1){
        this.viewPermission=true
      }
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  selectedFiler = { business: null, property: null, date: null, insuranceProvider: null,type: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null, }
  clear() {
    this.selectedFiler = { business: null, property: null, date: null, insuranceProvider: null,type: null,zoneId:null,cluserId:null, stateId:null,cityId:null,clientId:null, }
    this.filterSelect = false;
    this.getInsurenceList();
  }

  addInsurence() {
    this.route.navigate(['/insurance-add'])
  }


  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }

  propertyList = []
  filterPropertySearch
  filterProperty(ev){
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      } } )
  }
  filterpropertyList = []
  getPropertyList() {
    var businessId = 0;
    var userId = 0;
    if (this.apiService.roleId==1) {
      businessId = this.selectedFiler.business? this.selectedFiler.business.id : 0
    }else if(this.apiService.roleId==2 || this.apiService.roleId==3){
      businessId = this.apiService.buisnessId
    }
    if(this.apiService.roleId==3){
      userId=this.apiService.authId
    }
    let obj = {
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": businessId,
    }

    this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + businessId,{} ,obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']
    })
  }

  jumpToPage
  getArray(d){
    let array=[]
    for(let i=1; i<= d; i++) array.push(i)

    return array
  }

  filterBusinessSearch
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  getInsurenceList() {
    this.apiService.startLoader();
    var busineId = 0;
    if (this.apiService.roleId==1) {
      busineId = this.selectedFiler.business? this.selectedFiler.business.id : 0
    }else if(this.apiService.roleId==2 || this.apiService.roleId==3){
      busineId = this.apiService.buisnessId
    }
    let body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      type: this.selectedFiler.type,
      sortBy: this.sortSelected,
      sortType: this.sort,
      insuranceProvider: this.selectedFiler.insuranceProvider,
      bussinessId: busineId,
      outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
      startDate: this.selectedFiler.date ? moment(this.selectedFiler.date[0]).format('YYYY-MM-DD') : null,
      endDate: this.selectedFiler.date ? moment(this.selectedFiler.date[1]).format('YYYY-MM-DD') : null,
      roleId:this.apiService.roleId,
      userId:this.apiService.authId,
      zoneId:this.selectedFiler.zoneId,
        cluserId:this.selectedFiler.cluserId ,
        stateId :this.selectedFiler.stateId,
        cityId:this.selectedFiler.cityId,
        clientId:this.selectedFiler.clientId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('insurances/index/' + busineId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.insurenceList = result['data']
      this.setPaginationArray(result)
      this.filterSelect = false;
      this.apiService.stopLoader()

    })
  }
  message = '';


  paginationArray = []

  pageSize = 10;
  activePage: any = 1
  totalnoOfPages
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  insurenceList = []
  selectedAssets = []

  pagination(d) {
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('insuranceListPagination',d);
      // console.log("Active Page :", d)
      this.getInsurenceList()
    }
  }

  sortSelected: any = 'insuranceId'
  sort: any = 'desc'

  sortData(data) {
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    this.getInsurenceList()
  }
  goBack(){
    this.route.navigate(['/setup'])
  }

  applyFlag = false;
  applyFilter() {
    this.applyFlag = true;
    this.activePage = 1;
    this.getInsurenceList();
  }

  getFilterStatus() {
    // console.log(JSON.stringify(this.selectedFiler));
    let length = 0;
    if (this.selectedFiler.business != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.date != null) length = length + 1
    if (this.selectedFiler.insuranceProvider != null) length = length + 1
    if (this.selectedFiler.type != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1;
    if (this.selectedFiler.zoneId != null) length = length + 1;
    if (this.selectedFiler.cityId != null) length = length + 1;
    if (this.selectedFiler.cluserId != null) length = length + 1;
    if (this.selectedFiler.stateId != null) length = length + 1;


    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`

    if (length == 1 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${1 - length} Filter Selected)`
  }

  navigateToDetails(d) {
    this.route.navigate(['/insurance-details/'+ d.insuranceId])
  }
  assetList = []

  openDropdown(d) {
    if ($(".stama" + d).css('display') == 'none') {
      $(".stama" + d).css("display", "block");
    } else {
      $(".stama" + d).css("display", "none");
    }
  }
  getDate(d) {
    let pattern = 'dd-MM-yyyy h:m:s a'
    return moment(d, pattern).utc(false)
  }
  deactiveSelected() { }
  dublicateSelected() { }
  selectAllAssets


  businessFilter = false;
  filterSelect = false;
  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      // this.showFilter = false
      //this.filterSelect = false;
      this.businessFilter = false;
      this.propertFilter = false;
    }else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.businessFilter = false;
      this.propertFilter = false;
    }
  }
  closeAll() {
    this.businessFilter = false;
    this.propertFilter = false;
  }

  getInsuranceProperties(data){
    let str=''
    for(let d of data) str=(str != '')?',':''+d.outletName
     return str
    }
    openDate(id){
      (<HTMLInputElement>document.getElementById(id)).click();
    }


    getAssetName(assets){
      let str=''
    //   if(assets[0]){
    //   for(let data of assets){
    //   str = str+ (str == ''?'' : ', ') + data.assetManagementName 
    //   }
    // }
    // console.log(assets)
    if(assets.length == 1) return assets[0].assetManagementName
   else if(assets.length > 1) return "Multiple"
   else return ""
    }

}

