import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public formBuilder: FormBuilder,
  ) { }
  businesId;
  businessList = [];
  selectedOutlet;
  departmentArray = [];
  saveData = false;
  message: any;
  selectedPropertys: any
  role: any
  roleIdCheck = this.apiService.roleId;
  selectedDepartments = null
  propertyList = [];
  roleList = [];
  userTypeList = []
  userTypeListMobile = []
  departmentList = [];
  userId: any
  dropdownSettings: IDropdownSettings;
  dropdownSettings2: IDropdownSettings
  outletId1 = [];
  userForm
  ngOnInit(): void {
    if(this.apiService.buisnessId == 71){
      this.userForm = this.formBuilder.group({
        businessId: this.apiService.buisnessId,
        modifiedBy: this.apiService.authId,
        outletId: [null],
        options: [null],
        fullName: [null, [Validators.required, Validators.maxLength(40)]],
        email: [null, [Validators.email]],
        empCompanyCode: [null, [Validators.required]],
        passwordNew: [null],
        mobile: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        designation: [null, [Validators.maxLength(40)]],
        shipmentId: 0,
        roleId: [null, [Validators.required]],
        userType: [2], //,[Validators.required]
        userTypeMobile: [1], //,[Validators.required]
        subUsers: this.formBuilder.array([this.initSubUsers(null)]),
      })
    }else{
      this.userForm = this.formBuilder.group({
        businessId: this.apiService.buisnessId,
        modifiedBy: this.apiService.authId,
        outletId: [null],
        options: [null],
        fullName: [null, [Validators.required, Validators.maxLength(40)]],
        email: [null, [Validators.required, Validators.email]],
        empCompanyCode: [null],
        passwordNew: [null],
        mobile: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        designation: [null, [Validators.maxLength(40)]],
        shipmentId: 0,
        roleId: [null, [Validators.required]],
        userType: [2], //,[Validators.required]
        userTypeMobile: [1], //,[Validators.required]
        subUsers: this.formBuilder.array([this.initSubUsers(null)]),
      })
    }
    this.dropdownSettings = {
      singleSelection: false,
      "defaultOpen": false,
      idField: 'id',
      textField: 'outletName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      "enableCheckAll": true,
      //itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    }
    this.userId = this.router.url.split("/")[2];
    document.body.style.overflow = 'hidden';
    this.getpropertyList();
    this.getShift();
  }

  getuserType1(typ): void {
    this.userArry = []
    var type = 0;
    if (typ == 3 || typ == 2) {
      type = 2;
    } else if (typ == 5) {
      type = 1;
    } else {
      this.userTypeList = []
    }
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "isActive": 1,
      // "type":type,
      "businessId": this.userForm.value.businessId ? this.userForm.value.businessId : this.apiService.buisnessId,
    }
    // console.log(formData);
    this.apiService.startLoader()
    this.apiService.postCall('roles/index', {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      var da = result['data'];
      this.userTypeList = da.filter(item => item.type == 2);
      this.userTypeListMobile = da.filter(item => item.type == 1);
      // console.log(this.userTypeListMobile)
    });
  }



  getDisbaled(id, userArry) {
    let idx = userArry.findIndex(x => x == id)
    if (idx != -1) return true
  }

  shiftMent = []
  getShift() {
    this.userArry = []
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "isActive": 1,
      "businessId": this.apiService.buisnessId,
      "page_no": 1,
      "limit": 100
    }
    // console.log(formData);
    this.apiService.startLoader()
    this.apiService.postCall('shipments/index', {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      this.shiftMent = result['data'];

    });
  }

  getuserType(e) {
    var type = 0;
    if (e.target.value == 3 || e.target.value == 2) {
      type = 2;
    } else if (e.target.value == 5) {
      type = 1;
    } else {
      this.userTypeList = []
    }
    //this.userArry = []
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "isActive": 1,
      //"type":type,
      "businessId": this.userForm.value.businessId ? this.userForm.value.businessId : this.apiService.buisnessId,
    }
    // console.log(formData);
    this.apiService.startLoader()
    this.apiService.postCall('roles/index', {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      var da = result['data'];
      this.userTypeList = da.filter(item => item.type == 2);
      this.userTypeListMobile = da.filter(item => item.type == 1);
    });
  }
  goBack(): void {
    this.router.navigate(['user-index']);
  }

  resetForm(): void {
    this.userForm.setControl('subUsers', this.formBuilder.array([this.initSubUsers(null)]));
    this.userForm.reset();
  }

  getUserDetails(): void {
    this.apiService.getCall('users/view/' + this.userId, {}).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        // console.log("User Data :", result['data']);
        let userDetails = result['data'];
        if(this.apiService.buisnessId == 71){
          this.userForm = this.formBuilder.group({
            businessId: userDetails.businessId,
            modifiedBy: this.apiService.authId,
            outletId: [userDetails.selectedProperty],
            options: [null],
            fullName: [userDetails.fullName, [Validators.required, Validators.maxLength(40)]],
            email: [userDetails.email, [Validators.email]],
            empCompanyCode: [userDetails.empCompanyCode,[Validators.required]],
            passwordNew: [null],
            mobile: [userDetails.mobile, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            designation: [userDetails.designation, [Validators.maxLength(40)]],
            shipmentId: userDetails.shipmentId,
            roleId: [userDetails.role, [Validators.required]],
            userType: [userDetails.userType, [Validators.required]],
            userTypeMobile: [userDetails.userTypeMobile, [Validators.required]],
            subUsers: this.formBuilder.array([]),
            //outletId: userDetails.selectedProperty
          })
        }else{
          this.userForm = this.formBuilder.group({
            businessId: userDetails.businessId,
            modifiedBy: this.apiService.authId,
            outletId: [userDetails.selectedProperty],
            options: [null],
            fullName: [userDetails.fullName, [Validators.required, Validators.maxLength(40)]],
            email: [userDetails.email, [Validators.required, Validators.email]],
            empCompanyCode: [userDetails.empCompanyCode],
            passwordNew: [null],
            mobile: [userDetails.mobile, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            designation: [userDetails.designation, [Validators.maxLength(40)]],
            shipmentId: userDetails.shipmentId,
            roleId: [userDetails.role, [Validators.required]],
            userType: [userDetails.userType, [Validators.required]],
            userTypeMobile: [userDetails.userTypeMobile, [Validators.required]],
            subUsers: this.formBuilder.array([]),
            //outletId: userDetails.selectedProperty
          })
        }
        //this.outletId1 = [userDetails.selectedProperty],
        this.selectedPropertys = userDetails.selectedProperty;
        this.dropdownSettings = {
          singleSelection: false,
          "defaultOpen": false,
          idField: 'id',
          textField: 'outletName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          "enableCheckAll": true,
          //itemsShowLimit: 5,
          allowSearchFilter: true
        };
        this.getuserType1(userDetails.role);
        this.userArry = userDetails.selectedProperty != "" ? userDetails.selectedProperty : [];
        this.departmentArray = userDetails.assignDepartments != "" ? userDetails.assignDepartments : [];
        for (let d of userDetails['subUsers']) {
          this.addMoreSubUser(d);
        }
      } else {
        alert(result['msg']);
        this.goBack();
      }
    });
  }

  getBusinessList(): void {
    // this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      // this.apiService.stopLoader()
      this.businessList = []
      this.businessList = result['data'];
    })
  }

  getpropertyList(): void {
    this.userArry = []
    this.departmentArray = []
    this.propertyList = []
    let formData = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.userForm.value.businessId ? this.userForm.value.businessId : this.apiService.buisnessId,
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, formData).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.propertyList = result['data'];
        if (this.apiService.roleId == 3) {
          result['roleList'].splice(0, 1)
          result['roleList'].splice(0, 1)
        } else if (this.apiService.roleId == 2) {
          result['roleList'].splice(0, 1)
        }
        this.roleList = result['roleList'];
        this.departmentList = result['departments'];
      }
    });
    this.getUserDetails();
  }

  initSubUsers(data: any | null) {
    // console.log(data)
    if (data != undefined) {
      return this.formBuilder.group({
        id: data['id'],
        fullName: [data['full_name'], [Validators.maxLength(40)]],
        mobile: [data['mobile'], Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
        designa: [data['designation'], [Validators.maxLength(40)]],
      });
    } else {
      return this.formBuilder.group({
        id: [null],
        fullName: [null, [Validators.maxLength(40)]],
        mobile: [null, [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        designa: [null, [Validators.maxLength(40)]],
      });
    }
  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  removeMoreSubUser(i): void {
    this.formSubUsers.removeAt(i);
  }
  get formSubUsers() {
    return this.userForm.get('subUsers') as FormArray;
  }

  addMoreSubUser(data?): void {
    this.formSubUsers.push(this.initSubUsers(data));
  }

  onItemSelect(item: any) {
    if (this.userArry.indexOf(item.id) == -1) {
      this.userArry.push(item)
      // this.userForm.patchValue({ outletId: null })
    } else {
      // this.userForm.patchValue({ outletId: null })
    }
  }
  onItemDeSelect(item: any) {
    //console.log('onItemDeSelect', item);
    let idx = this.userArry.findIndex(x => x.id == item.id)
    this.userArry.splice(idx, 1)
  }

  onSelectAll(items: any) {
    for (let i = 0; i < items.length; i++) {
      this.userArry.push(items[i]);
    }
  }
  onUnSelectAll() {
    this.userArry = []
  }


  onDepartmentSelect(item: any) {
    // console.log(item);
  }

  onDepartmentSelectAll(items: any) {
    // console.log(items);
  }

  saveUser(): void {
    let formData = this.userForm.value;
    let propertyId = [];
    for (let p of this.userArry) {
      // console.log(p);
      propertyId.push(p.id);
    }
    let departmentId = [];
    for (let d of this.departmentArray) {
      departmentId.push(d);
    }
    formData.outletId = propertyId;
    formData.options = departmentId;
    // console.log("Form Data : ", formData);
    this.apiService.startLoader();
    this.apiService.postCall('users/edit/' + this.userId, {}, formData).subscribe((result: any[]) => {
      // console.log("Data Saved :", result);
      this.apiService.stopLoader();
      if (result['success']) {
        this.saveData = result['success'];
        this.message = "User(s) details updated successfully";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      else {
        this.saveData = result['success'];
        if (result['msg']['email']) {
          this.message = result['msg']['email'] ? "Email is already taken" : "Something went wrong";
        } else {
          this.message = result['msg'] ? result['msg'] : "Something went wrong";
        }
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
    });

  }

  getOutletName(id: any) {
    for (let i = 0; i < this.propertyList.length; i++) {
      if (id == this.propertyList[i].id) {
        return this.propertyList[i].outletName;
      }
    }
  }
  userArry = []
  removeOutlet(item: any) {
    //this.userArry.splice(i, 1)
    let idx = this.userArry.findIndex(x => x.id == item.id)
    this.userArry.splice(idx, 1)
  }
  selectOutlet(d: any) {
    if (this.userArry.indexOf(this.userForm.value.outletId) == -1) {
      this.userArry.push(this.userForm.value.outletId)
      this.userForm.patchValue({ outletId: null })
    } else {
      this.userForm.patchValue({ outletId: null })
    }
  }

  getDepartmentName(id: any) {
    for (let i = 0; i < this.departmentList.length; i++) {
      if (id == this.departmentList[i].id) {
        return this.departmentList[i].name;
      }
    }
  }
  selectDepartments(d: any) {
    if (this.departmentArray.indexOf(this.userForm.value.options) == -1) {
      this.departmentArray.push(this.userForm.value.options)
      this.userForm.patchValue({ options: null })
    } else {
      this.userForm.patchValue({ options: null })
    }
  }
  removeDepartment(i: any) {
    this.departmentArray.splice(i, 1);
  }

  getValidation() {
    if (this.userForm.value.roleId == 2) {
      this.userForm.patchValue({ userTypeMobile: 1 });
    } else if (this.userForm.value.roleId == 5) {
      this.userForm.patchValue({ userType: 2 });
    }
    if (this.userForm.value.roleId != 2 && this.userForm.value.roleId != 4 && this.userForm.value.roleId != 5 && this.userArry.length == 0) {
      return false;
    }
    if (this.departmentArray.length == 0 && this.userForm.value.roleId == 3) {
      return false;
    } else if (this.departmentArray.length == 0 && this.userForm.value.roleId == 5) {
      return false;
    }
    if (this.userArry.length == 0 && this.userForm.value.roleId == 3) {
      return false;
    } else if (this.userArry.length == 0 && this.userForm.value.roleId == 4) {
      return false;
    } else if (this.userArry.length == 0 && this.userForm.value.roleId == 5) {
      return false;
    }
    if (this.departmentArray.length == 0 && this.apiService.role != 2 && this.userForm.value.roleId != 4) {
      return false;
    }
    return (this.userForm.valid);
  }
}
