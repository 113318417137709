import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-department-edit',
  templateUrl: './department-edit.component.html',
  styleUrls: ['./department-edit.component.css']
})
export class DepartmentEditComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  departmentForm: FormGroup

  id
  name
  description
  isActive = 1;

  ngOnInit(): void {
    this.id = this.route.url.split("/")[3];
    this.getDepartmentList(this.id);
    document.body.style.overflow = 'hidden';
    this.name = this.name,
      this.description = this.description,
      this.isActive = this.isActive

    this.departmentForm = this.fb.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      description: [null],
      isActive: [null]
    });
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  department
  getDepartmentList(id) {
    this.apiService.startLoader()
    let body = {
      id:id
    }
    this.apiService.postCall('types/view/' + id, {}, body).subscribe((result: any[]) => {
      if(result['success'] && result['data']){
        this.department = result['data'][0];
        this.name = this.department.name,
        this.description = this.department.description,
        this.isActive = 1,//this.department.isActive
        this.departmentForm.patchValue({ isActive: this.isActive })
      }
      // console.log(this.department);
      this.apiService.stopLoader()
    })
  }

  selectUnit = false;
  selectIsActivedata(data) {
    this.isActive =data
    this.selectUnit = !this.selectUnit
    this.departmentForm.patchValue({ isActive: data })
  }

  submit() {
    var isActive =0;
    // console.log(this.departmentForm.value)
    if(this.departmentForm.value.isActive){
      isActive=1;
    }else{
      isActive=0;
    }
    let obj = {
      name:this.departmentForm.value.name,
      description:this.departmentForm.value.description,
      businessId:this.apiService.buisnessId,
      isActive:1,//isActive,
      authId:this.apiService.authId
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('types/edit/'+this.id, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    })

  }
  backClicked() {
    this.route.navigate(['/department-list'])
  }

  message
  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]


  getValidation() {
    // console.log(this.departmentForm.valid)
    return this.departmentForm.valid
  }
}
