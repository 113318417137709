import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from '../common/services/apiCalls.service';

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.css']
})
export class InspectionListComponent implements OnInit {

  constructor(public route: Router,public router: Router, public apiService: ApiService,) { }

  propertyClients = false;
  propertyZone = false;
  isCluster = false;
  isCityForFilter = false;
  isPropertyTypeFilter = false;
  isPropertyFilter = false;
  isDepartmentFilter = false;
  isState = false;
  propertyFiletr = false;

  filterZoneSearch: any;
  filterClusterSearch: any;
  filterCitySearch: any;
  filterPropertyTypeSearch: any;
  filterStateSearch: any;

  ZoneListData: any[] = [];
  filteredZoneListData: any[] = [];

  clusterListData: any[] = [];
  filteredclusterListData: any[] = [];

  cityListData: any[] = [];
  filteredcityListData: any[] = [];

  propertyTypeListData: any[] = [];
  filteredTypeListData: any[] = [];

  propertyListData: any[] = [];
  filteredpropertyListData: any[] = [];

  departmentListData: any[] = [];
  filtereddepartmentListData: any[] = [];

  stateListData: any[] = [];
  filteredstateListData: any[] = [];
  filterClientsSearch: any;
  ClientsListData: any[] = [];
  filteredClientsListData: any[] = [];


  sendEmailPermission = false
  downloadPdfPermission = false
  viewReportPermission = true
  scheduleViewPermission = false
  checklistViewPermission = false
  todayDate: any;
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  completionStatusLists = [{ id: 1, name: 'Completed' }, { id: 0, name: 'Incompleted' }];
  frequencyLists = [{ id: 1, name: 'Once' }, { id: 2, name: 'Daily' },{id:3,name:'Weekly'},{id:4,name:'Monthly'},{id:5,name:'Quarterly'},{id:6,name:'Custom'}];
  setTabSelection() {
    // this.apiService.startLoader();
    //    setTimeout(() => {
    //      // console.log("in");
    //     this.apiService.stopLoader();
    //   }, 5000);
    // if(this.tabSelected =='prevReports'){
    //    this.getInspectionMainListPrev();
    // }else{
    //   this.getInspectionMainList()
    // }
    localStorage.setItem("tabselection", JSON.stringify({ inspectionList: this.tabSelected, ticketList: 'openTicket', assetList: 'allasset', partsList: 'allparts', maintananceList: 'today' }))
  }
  ngOnInit(): void {
    if(this.router.url.split("/")[2]){
      this.tabSelected = this.router.url.split("/")[2];
      this.clear();
      this.setTabSelection();
    }
    if (localStorage.getItem("tabselection")) {
      this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).inspectionList
    }
    var inspectionPagination = localStorage.getItem('inspectionPagination');
    if (inspectionPagination) {
      this.activePage = parseInt(inspectionPagination);
    }
    var inspectionPaginationPrev = localStorage.getItem('inspectionPaginationPrev');
    if (inspectionPaginationPrev) {
      this.activePagePrev = parseInt(inspectionPaginationPrev);
    }
    this.todayDate = moment().add(0, 'd').set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    this.getPropertyList()
    this.getClients()
    if (this.tabSelected == 'todaysReport') this.getInspectionMainList()
    else this.getInspectionMainListPrev()
    //this.getInspectionMainListPrev()
    this.getDepartmentList();
    this.getUserList()
    this.getZoneListData();
    this.getBusinessList()
    //checkPermission
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    // console.log("harshal--------");
    permission = permission.filter(item => item['permissionModuleId'] == 1);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Inspection" && permission[i]['action'] == "Send Email" && permission[i]['actionStatus'] == 1) {
        this.sendEmailPermission = true
      }
      if (permission[i]['subModuleName'] == "Inspection" && permission[i]['action'] == "Download Pdf" && permission[i]['actionStatus'] == 1) {
        this.downloadPdfPermission = true
      }
      if (permission[i]['subModuleName'] == "Schedules" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.scheduleViewPermission = true
      }
      if (permission[i]['subModuleName'] == "Checklist" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.checklistViewPermission = true
      }
    }
  }

  getClients() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.ClientsListData = []
      //this.locationList = result['data'] 
      //this.filterpropertyList = result['data']
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

      // let idx = this.locationList.findIndex(x => x.id == this.apiService.outletId)
      // // console.log(this.apiService.roleId)

    })
  }

  getSelectdEmail() {
    return 2;
  }
  selectedAssets = []
  // pageSize=10;
  // pageNo=1;
  tabSelected = ''
  inspectionList
  inspectionListPrevious

  sortSelected: any;
  sort: any;
  getInspectionMainList() {
    localStorage.removeItem("inspectionFilterPrev");
    localStorage.setItem("inspectionFilterPrev", null);
    let body = {};
    this.apiService.startLoader()
    if (localStorage.getItem("inspectionFilter") && localStorage.getItem("inspectionFilter") != null && localStorage.getItem("inspectionFilter") != 'null') {
      let inspectionFilter = JSON.parse(localStorage.getItem("inspectionFilter"));
      body = inspectionFilter;
      if (inspectionFilter.outletId && inspectionFilter.outletId != null && inspectionFilter.outletId != 'null') {
        this.selectedFiler.property = { id: inspectionFilter.outletId, outletName: inspectionFilter.frontOutletNameFilter }
        this.getLocationList();
        this.getUserList();
      }
      if (inspectionFilter.clientId && inspectionFilter.clientId != null && inspectionFilter.clientId != 'null') {
        this.selectedFiler.Clients = { id: inspectionFilter.clientId, name: inspectionFilter.frontClientNameDp }
      }
      if (inspectionFilter.zoneId && inspectionFilter.zoneId != null && inspectionFilter.zoneId != 'null') {
        this.selectedFiler.Zone = { id: inspectionFilter.zoneId, name: inspectionFilter.frontZoneNameDp }
      }
      if (inspectionFilter.stateId && inspectionFilter.stateId != null && inspectionFilter.stateId != 'null') {
        this.selectedFiler.state = { id: inspectionFilter.stateId, name: inspectionFilter.frontStateNameDp }
      }
      if (inspectionFilter.clusterId && inspectionFilter.clusterId != null && inspectionFilter.clusterId != 'null') {
        this.selectedFiler.cluster = { id: inspectionFilter.clusterId, name: inspectionFilter.frontClusterNameDp }
      }
      if (inspectionFilter.cityId && inspectionFilter.cityId != null && inspectionFilter.cityId != 'null') {
        this.selectedFiler.city = { id: inspectionFilter.cityId, name: inspectionFilter.frontCityNameDp }
      }
      this.selectedFiler.department = inspectionFilter.departmentId ? inspectionFilter.departmentId : null
      this.selectedFiler.name = inspectionFilter.name
      this.applyFlag = true;
    } else {
      body = {
        sortType: this.sort,
        sortBy: this.sortSelected,
        pageNo: this.activePage,
        limit: this.pageSize,
        name: this.selectedFiler.name,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
        //  from:this.selectedFiler.dateRange?this.selectedFiler.dateRange[0]:null,
        //  to:this.selectedFiler.dateRange?this.selectedFiler.dateRange[1]:null,
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        floorPlanId: this.selectedFiler.location ? this.selectedFiler.location.id : "",
        //  bussinessId:this.selectedFiler.business?this.selectedFiler.business.id:null,
        userId: this.apiService.userId,
        "roleId": this.apiService.roleId,
        "businessId": this.apiService.buisnessId,

        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontLocationNameFilter: this.selectedFiler.location ? this.selectedFiler.location.name : "",
        frontUserNameFilter: this.selectedFiler.user ? this.selectedFiler.user.fullName : "",
        frontClientNameDp: this.selectedFiler.Clients ? this.selectedFiler.Clients.name : "",
        frontZoneNameDp: this.selectedFiler.Zone ? this.selectedFiler.Zone.name : "",
        frontStateNameDp: this.selectedFiler.state ? this.selectedFiler.state.name : "",
        frontClusterNameDp: this.selectedFiler.cluster ? this.selectedFiler.cluster.name : "",
        frontCityNameDp: this.selectedFiler.city ? this.selectedFiler.city.name : "",
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
        completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : "",
        completionStatusFilter: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.name : "",
        frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      }
      localStorage.setItem('inspectionFilter', JSON.stringify(body));
    }
    this.apiService.postCall('TodaysSchedules/index', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.inspectionList = result['data']
      this.setPaginationArray(result)
      this.apiService.stopLoader()
    }
    )


  }
  sortData(data) {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    this.sortSelected = data
    if (this.sort == 'asc') this.sort = 'desc'
    else this.sort = 'asc'

    if (this.tabSelected == 'todaysReport') this.getInspectionMainList()
    else this.getInspectionMainListPrev()
  }

  setFileterValue() {
    if (localStorage.getItem("inspectionFilterPrev") && localStorage.getItem("inspectionFilterPrev") != null && localStorage.getItem("inspectionFilterPrev") != 'null') {
      let inspectionFilterPrev = JSON.parse(localStorage.getItem("inspectionFilterPrev"));
      if (inspectionFilterPrev.floorPlanId && inspectionFilterPrev.floorPlanId != null && inspectionFilterPrev.floorPlanId != 'null') {
        this.selectedFiler.location = { id: inspectionFilterPrev.floorPlanId, name: inspectionFilterPrev.frontLocationNameFilter }
      }
      if (inspectionFilterPrev.userIdFilter && inspectionFilterPrev.userIdFilter != null && inspectionFilterPrev.userIdFilter != 'null') {
        this.selectedFiler.user = { id: inspectionFilterPrev.userIdFilter, fullName: inspectionFilterPrev.frontUserNameFilter }
      }
      if (inspectionFilterPrev.completionStatus && inspectionFilterPrev.completionStatus != null && inspectionFilterPrev.completionStatus != 'null') {
        this.selectedFiler.completionStatus = { id: inspectionFilterPrev.completionStatus, name: inspectionFilterPrev.completionStatusFilter }
      }
    } else if (localStorage.getItem("inspectionFilter") && localStorage.getItem("inspectionFilter") != null && localStorage.getItem("inspectionFilter") != 'null') {
      let inspectionFilter = JSON.parse(localStorage.getItem("inspectionFilter"));
      if (inspectionFilter.floorPlanId && inspectionFilter.floorPlanId != null && inspectionFilter.floorPlanId != 'null') {
        this.selectedFiler.location = { id: inspectionFilter.floorPlanId, name: inspectionFilter.frontLocationNameFilter }
      }
      if (inspectionFilter.userIdFilter && inspectionFilter.userIdFilter != null && inspectionFilter.userIdFilter != 'null') {
        this.selectedFiler.user = { id: inspectionFilter.userIdFilter, fullName: inspectionFilter.frontUserNameFilter }
      }
      if (inspectionFilter.completionStatus && inspectionFilter.completionStatus != null && inspectionFilter.completionStatus != 'null') {
        this.selectedFiler.completionStatus = { id: inspectionFilter.completionStatus, name: inspectionFilter.completionStatusFilter }
      }
    }
  }

  getInspectionMainListPrev() {
    this.apiService.startLoader()
    // this.selectedFiler={name:null , property:null  , department:null, user:null , dateRange:null , }
    this.inspectionListPrevious = []
    let body = {}
    if (localStorage.getItem("inspectionFilterPrev") && localStorage.getItem("inspectionFilterPrev") != null && localStorage.getItem("inspectionFilterPrev") != 'null') {
      let inspectionFilterPrev = JSON.parse(localStorage.getItem("inspectionFilterPrev"));
      // console.log(inspectionFilterPrev);
      body = inspectionFilterPrev;
      if (inspectionFilterPrev.outletId && inspectionFilterPrev.outletId != null && inspectionFilterPrev.outletId != 'null') {
        this.selectedFiler.property = { id: inspectionFilterPrev.outletId, outletName: inspectionFilterPrev.frontOutletNameFilter }
        this.getLocationList();
        this.getUserList();
      }
      if (inspectionFilterPrev.clientId && inspectionFilterPrev.clientId != null && inspectionFilterPrev.clientId != 'null') {
        this.selectedFiler.Clients = { id: inspectionFilterPrev.clientId, name: inspectionFilterPrev.frontClientNameDp }
      }
      if (inspectionFilterPrev.zoneId && inspectionFilterPrev.zoneId != null && inspectionFilterPrev.zoneId != 'null') {
        this.selectedFiler.Zone = { id: inspectionFilterPrev.zoneId, name: inspectionFilterPrev.frontZoneNameDp }
      }
      if (inspectionFilterPrev.stateId && inspectionFilterPrev.stateId != null && inspectionFilterPrev.stateId != 'null') {
        this.selectedFiler.state = { id: inspectionFilterPrev.stateId, name: inspectionFilterPrev.frontStateNameDp }
      }
      if (inspectionFilterPrev.clusterId && inspectionFilterPrev.clusterId != null && inspectionFilterPrev.clusterId != 'null') {
        this.selectedFiler.cluster = { id: inspectionFilterPrev.clusterId, name: inspectionFilterPrev.frontClusterNameDp }
      }
      if (inspectionFilterPrev.cityId && inspectionFilterPrev.cityId != null && inspectionFilterPrev.cityId != 'null') {
        this.selectedFiler.city = { id: inspectionFilterPrev.cityId, name: inspectionFilterPrev.frontCityNameDp }
      }
      this.selectedFiler.department = inspectionFilterPrev.departmentId ? inspectionFilterPrev.departmentId : null
      this.selectedFiler.name = inspectionFilterPrev.name
      this.selectedFiler.startDate = inspectionFilterPrev.from
      this.selectedFiler.ensDate = inspectionFilterPrev.to
      this.applyFlag = true;
    } else {
      body = {
        sortType: this.sort,
        sortBy: this.sortSelected,
        pageNo: this.activePagePrev,
        limit: this.pageSizePrev,
        name: this.selectedFiler.name,
        outletId: this.selectedFiler.property ? this.selectedFiler.property.id : null,
        departmentId: this.selectedFiler.department ? this.selectedFiler.department.id : null,
        from: this.selectedFiler.startDate ? this.selectedFiler.startDate : null,
        to: this.selectedFiler.ensDate ? this.selectedFiler.ensDate : null,
        floorPlanId: this.selectedFiler.location ? this.selectedFiler.location.id : null,
        //  bussinessId:this.selectedFiler.business?this.selectedFiler.business.id:null,
        userIdFilter: this.selectedFiler.user ? this.selectedFiler.user.id : "",
        //  bussinessId:this.selectedFiler.business?this.selectedFiler.business.id:null,
        userId: this.apiService.userId,
        "roleId": this.apiService.roleId,

        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontLocationNameFilter: this.selectedFiler.location ? this.selectedFiler.location.name : "",
        frontUserNameFilter: this.selectedFiler.user ? this.selectedFiler.user.fullName : "",
        clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
        zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
        stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
        clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
        cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
        completionStatus: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.id : null,
        completionStatusFilter: this.selectedFiler.completionStatus ? this.selectedFiler.completionStatus.name : "",
        frequency: this.selectedFiler.frequency ? this.selectedFiler.frequency.id : "",
      }
      localStorage.setItem('inspectionFilterPrev', JSON.stringify(body));
    }
    this.apiService.postCall('inspections/inspectionsMainList', {}, body).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      this.inspectionListPrevious = result['data']
      this.setPaginationArrayPrev(result)
      if (this.inspectionListPrevious) {
        this.inspectionListPrevious.map(x => x.created = x.created.substring(0, 10))
      }
      this.apiService.stopLoader()
    }
    )



  }


  applyFilter() {
    this.selectedPrevInspection = [];
    this.selectedTodayInspection = [];
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    this.applyFlag = true;
    this.activePage = 1;
    this.activePagePrev = 1;
    this.getInspectionMainList()
    this.getInspectionMainListPrev()
  }


  // paginationArray=[]
  // setPaginationArray(result){
  //   let noOfPAges = Math.ceil(result['rowCount'] /this.pageSize)
  //   this.paginationArray=[]
  //   for(let i =1 ; i<= noOfPAges ; i++)this.paginationArray.push(i)
  // // console.log(this.paginationArray)
  // }

  selectedFiler = { name: null, property: null, user: null, department: null, location: null, startDate: null, ensDate: null, dateRange: null, business: null, Clients: null, Zone: null, state: null, cluster: null, city: null, completionStatus: null,frequency:null }
  filterSelect: boolean;

  clear() {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    localStorage.removeItem('inspectionPaginationPrev');
    this.selectedFiler = { name: null, property: null, user: null, department: null, location: null, startDate: null, ensDate: null, dateRange: null, business: null, Clients: null, Zone: null, state: null, cluster: null, city: null, completionStatus: null,frequency:null }
    this.propertyList = [];
    this.filterpropertyList = []
    this.filterdepartmentList = []
    this.clusterListData = [];
    this.cityListData = [];
    this.stateListData = [];
    this.selectedPrevInspection = [];
    this.selectedTodayInspection = [];
    this.getPropertyList()
    this.getInspectionMainList()
    this.getInspectionMainListPrev()
  }
  filterdepartmentList = []
  filterDepartmentSearch
  filterdepartment(ev) {
    this.departmentList = this.filterdepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmentSearch.toLowerCase())
      }
    })
  }
  filterPropertySearch
  filterProperty(ev) {
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  propertyList = []
  filterpropertyList = []
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      result['data']
      this.apiService.stopLoader()

      this.propertyList = result['data']
      this.filterpropertyList = result['data']

      let idx = this.propertyList.findIndex(x => x.id == this.apiService.outletId)
      // if(idx != -1){
      // if(this.apiService.roleId == '3')  {

      //    this.selectedFiler.property = this.propertyList[idx] 
      //    this.getUserList()

      //  }
      //  else{

      //    this.selectedFiler.property = this.propertyList[idx] 
      //    this.getUserList()
      //  }
      // }
      this.getUserList()
      //   if (result["clients"]) {
      //     this.ClientsListData = result["clients"];
      //   }
      //   this.filteredClientsListData = this.ClientsListData.slice();
    })
  }

  filterUserSearch
  filterUsers(ev) {
    this.userList = this.filetrUserList.filter((item) => {
      if (item.fullName != null) {
        return item.fullName.toLowerCase().includes(this.filterUserSearch.toLowerCase())
      }
    })
  }
  userList
  filetrUserList
  getUserList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.selectedFiler.property ? this.selectedFiler.property.id : "",
    }
    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()

      this.userList = result['data']
      this.filetrUserList = this.userList
      this.setFileterValue();
    })
  }
  departmentList
  filterDepartmentList = []
  getDepartmentList() {
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      cityId: this.selectedFiler.city ? this.selectedFiler.city.id : "",
    }
    this.apiService.postCall('types/pluckDepartments/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log("Response Listing :", result);
      if (result['success'] == true) {
        this.departmentList = result['data'];
        this.filterdepartmentList = result['data'];
      }
    });
  }


  locationList: any;
  getLocationList() {
    this.apiService.startLoader()
    this.locationList = []
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
    }
    this.apiService.postCall('locations/locationList/' + this.apiService.buisnessId + "/" + this.selectedFiler.property.id, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.startLoader()
      this.apiService.stopLoader()
      this.locationList = result['data']
      // console.log(this.apiService.roleId)
      this.setFileterValue();
      if (result["clients"]) {
        this.ClientsListData = result["clients"];
      }
      this.filteredClientsListData = this.ClientsListData.slice();

    })

  }

  // departmentList=[{"departmentId": 1, "departmentName": "Operations,",}, {"departmentId": 108, "departmentName": "Security",}, {"departmentId": 106, "departmentName": "Cleaning",},]
  // filterDepartmentList =[{"departmentId": 1, "departmentName": "Operations,",}, {"departmentId": 108, "departmentName": "Security",}, {"departmentId": 106, "departmentName": "Cleaning",},]

  filterDepartmetsearch;
  filterDepartmet(ev) {
    this.departmentList = this.filterDepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterDepartmetsearch.toLowerCase())
      }
    })
  }

  filterLocationSearch
  filterLocation(ev) {
    this.locationList = this.filterDepartmentList.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterLocationSearch.toLowerCase())
      }
    })
  }

  navigatetoReport(data) {
    this.apiService.startLoader();
    this.route.navigate(['/inspection-view-report-new/' + data.id])
  }

  navigatetoReportTodays(data) {
    this.route.navigate(['/inspection-view-report-todays-new/' + data.id])
  }

  deactiveSelected() { }
  dublicateSelected() { }


  // selectAllAssets
  chnageStatus
  message
  applyFlag



  totalnoOfPages
  activePage: any = 1
  pageSize = 10;
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  paginationArray = []

  pagination(d) {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.selectedAssets = []
      this.activePage = d
      localStorage.setItem('inspectionPagination', d)
      // console.log(d)
      this.getInspectionMainList()

    }
  }
  changePage(ev) {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    // console.log(this.pageSize)
    this.selectedAssets = []
    // this.pageSize = d
    this.getInspectionMainList()

  }



  totalnoOfPagesPrev
  activePagePrev: any = 1
  pageSizePrev = 10;
  paginationArrayPrev = []

  setPaginationArrayPrev(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSizePrev)
    this.totalnoOfPagesPrev = noOfPAges;
    this.paginationArrayPrev = []
    this.activePagePrev = parseInt(this.activePagePrev)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArrayPrev.push(i)
    else if (4 + this.activePagePrev < this.totalnoOfPagesPrev) {
      for (let i = this.activePagePrev; i <= 4 + this.activePagePrev; i++)this.paginationArrayPrev.push(i)
    }
    else if (4 + this.activePagePrev >= 6) {

      let start = (4 + this.activePagePrev) - noOfPAges
      for (let i = this.activePagePrev - start; i <= noOfPAges; i++)this.paginationArrayPrev.push(i)
    }
    // console.log(this.paginationArrayPrev)
  }
  // paginationArray=[]

  paginationPrev(d) {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPagesPrev && this.activePagePrev != d) {
      // this.selectedAssets = []
      this.activePagePrev = d
      localStorage.setItem('inspectionPaginationPrev', d)
      // console.log(d)
      this.getInspectionMainListPrev()

    }
  }
  changePagePrev(ev) {
    localStorage.removeItem('inspectionFilter');
    localStorage.removeItem('inspectionFilterPrev');
    localStorage.setItem("inspectionFilter", null);
    localStorage.setItem("inspectionFilterPrev", null);
    // console.log(this.pageSize)
    this.selectedAssets = []
    // this.pageSize = d
    this.getInspectionMainListPrev()

  }

  propertFilter = false;
  locationFilter = false
  businessFilter = false;
  departmentFilter = false
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      // console.log('in');
      this.filterSelect = false
      this.assetFilter = false;
      this.assignedFilter = false;
      this.businessFilter = false;
      this.propertFilter = false;
      this.selectedPrevInspectiondownload = false
      this.download = false
      this.locationFilter = false
      this.departmentFilter = false
      this.completionStatusFilter = false;
      this.frequencyFilter = false;

      // this.selectFreq = false
    } else {

    }
  }
  assignedFilter = false;
  assetFilter = false;
  completionStatusFilter = false;
  frequencyFilter = false;
  closeAll() {
    this.assignedFilter = false;
    this.assetFilter = false;
    this.businessFilter = false;
    this.propertFilter = false;
    this.departmentFilter = false;
    this.completionStatusFilter = false;
    this.frequencyFilter = false;
  }

  getFilterStatus() {
    // // console.log(JSON.stringify(this.selectedFiler))
    // let length =JSON.stringify(this.selectedFiler).split(null).length - 1
    // // console.log(length)
    let length = 0;
    if (this.selectedFiler.name != null && this.selectedFiler.name != "") length = length + 1
    if (this.selectedFiler.department != null && this.selectedFiler.department) length = length + 1
    if (this.selectedFiler.location != null && this.selectedFiler.location) length = length + 1
    if (this.selectedFiler.user != null && this.selectedFiler.user) length = length + 1
    if (this.selectedFiler.property != null && this.selectedFiler.property != "") length = length + 1
    if (this.selectedFiler.business != null && this.selectedFiler.business != "") length = length + 1
    if (this.tabSelected == 'prevReports' && this.selectedFiler.dateRange != null && this.selectedFiler.dateRange != "") length = length + 1
    if (this.tabSelected == 'prevReports' && this.selectedFiler.startDate != null && this.selectedFiler.startDate != "") length = length + 1
    if (this.tabSelected == 'prevReports' && this.selectedFiler.ensDate != null && this.selectedFiler.ensDate != "") length = length + 1
    if (this.selectedFiler.Clients != null) length = length + 1;
    if (this.selectedFiler.Zone != null) length = length + 1;
    if (this.selectedFiler.city != null) length = length + 1;
    if (this.selectedFiler.cluster != null) length = length + 1;
    if (this.selectedFiler.state != null) length = length + 1;
    if (this.selectedFiler.completionStatus != null && this.selectedFiler.completionStatus != "") length = length + 1

    // for(let d of this.selectedFiler)
    // // console.log(this.selectedFiler);
    // // console.log(length)
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  filterBusinessSearch
  // businessFilter = false;
  filterBuisness(ev) {
    // console.log(ev)
    this.bussnissList = this.bussnissListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.filterBusinessSearch.toLowerCase())
      }
    })
  }

  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']

      // let idx = this.bussnissList.findIndex(x => x.id == this.apiService.buisnessId)

      // if(this.apiService.roleId == '2') {
      //   this.selectedFiler.business =  this.bussnissList[idx]
      //   this.getPropertyList()
      //   }
      //   else if(this.apiService.roleId == '3')  {
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()

      //   }
      //   else{
      //     this.selectedFiler.business = this.bussnissList[idx] 
      //     // this.selectedFiler.property = JSON.stringify(this.apiService.outletId) 
      //     this.getPropertyList()
      //   }

      this.getPropertyList()
    })
  }


  jumpToPagePre = 1
  jumpToPage = 1
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }



  selectedTodayInspection = []
  selectAllInspection: boolean
  selectAllAssets(ev) {
    this.selectedTodayInspection = []

    this.selectAllInspection = ev.target.checked;
    if (ev.target.checked) {
      // this.selectAllInspection = true;
      this.inspectionList.map(x => x.selected = true)
      for (let department of this.inspectionList) {
        this.selectedTodayInspection.push(department.id)
        // this.selectedTodayInspectionDetailsList.push(asset)
      }
    }
    else {

      this.inspectionList.map(x => x.selected = false)
      this.selectedTodayInspection = []
      // this.selectedTodayInspectionDetailsList = []
    }
    // console.log(this.selectedTodayInspection)
  }

  selectAsset(ev, department) {
    if (ev.target.checked) {
      this.selectedTodayInspection.push(department.id)
      // this.selectedTodayInspectionDetailsList.push(asset)
    }
    else {
      this.selectedTodayInspection.splice(this.selectedTodayInspection.findIndex(x => x == department.id), 1)
      // this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.assetId == asset.assetId), 1)
    }

    if (this.selectedTodayInspection.length == this.inspectionList.length) this.selectAllInspection = true;
    else this.selectAllInspection = false;
  }

  selectedPrevInspection = []
  selectAllInspectionPrev: boolean
  selectAllAssetsPrev(ev) {
    this.selectedPrevInspection = []
    this.selectAllInspectionPrev = ev.target.checked;
    if (ev.target.checked) {

      this.inspectionListPrevious.map(x => x.selected = true)
      for (let department of this.inspectionListPrevious) {
        this.selectedPrevInspection.push(department.id)
        // this.selectedPrevInspectionDetailsList.push(asset)
      }
    }
    else {
      this.inspectionListPrevious.map(x => x.selected = false)
      this.selectedPrevInspection = []
      // this.selectedPrevInspectionDetailsList = []
    }
    // console.log(this.selectedPrevInspection)
  }

  selectAssetPrev(ev, department) {
    if (ev.target.checked) {
      this.selectedPrevInspection.push(department.id)
      // this.selectedPrevInspectionDetailsList.push(asset)
    }
    else {
      this.selectedPrevInspection.splice(this.selectedPrevInspection.findIndex(x => x == department.id), 1)
      // this.selectedAssetsDetailsList.splice(this.selectedAssets.findIndex(x => x.assetId == asset.assetId), 1)
    }


    if (this.selectedPrevInspection.length == this.inspectionListPrevious.length) this.selectAllInspectionPrev = true;
    else this.selectAllInspectionPrev = false;
  }


  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(regularExpression.test(String(email).toLowerCase()))
    return regularExpression.test(String(email).toLowerCase());
  }
  errorFlag: boolean
  sendEmail() {
    this.errorFlag = true
    if (this.validateEmail(this.emailAddress)) this.emailReport()
    else {
      // console.log("invalid email")
    }
  }


  emailAddress: any;
  // errorFlag:boolean;
  // message:any;
  emailReport() {
    let obj = {
      reportId: this.tabSelected == 'prevReports' ? this.selectedPrevInspection : this.selectedTodayInspection,
      email: this.emailAddress
    }
    this.apiService.startLoader()
    this.apiService.uploadCall('TodaysChecklists/download', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      // window.open(result['data'], "_blank");
      this.errorFlag = false
      this.emailAddress = undefined
      this.apiService.stopLoader();
      (<HTMLInputElement>document.getElementById('cancelEmailPopup')).click();

      this.message = "Inspection report(s) mailed successfully";
      (<HTMLInputElement>document.getElementById('validationButton')).click();
    })
  }

  pdfButton = null
  exportAsPDF(div_id) {

    this.downloadFun()
    let obj = {
      reportId: this.tabSelected == 'prevReports' ? this.selectedPrevInspection : this.selectedTodayInspection,
      // email:this.emailAddress   
    }


    this.apiService.startLoader()
    this.pdfButton = "true"
    this.apiService.uploadCall('TodaysChecklists/download', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      // console.log(result);
      this.pdfButton = null
      window.open(result['data'], "_blank");
      this.clear();
    })
    this.selectAllInspectionPrev = false;
    this.selectAllInspection = false;



  }

  selectedPrevInspectiondownload = false
  download = false
  exportExcel() {
    let obj = {
      reportId: this.tabSelected == 'prevReports' ? this.selectedPrevInspection : this.selectedTodayInspection,
      // email:this.emailAddress   
    }
    this.apiService.startLoader()
    this.apiService.uploadCall('TodaysChecklists/exportExcel', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader();
      // console.log(result);
      //window.open(result['data'], "_blank");

      if (result['success'] == true) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = result['data'];
        a.download = "inspection_report.csv";
        a.click();
        document.body.removeChild(a);
      }

    })
  }
  selectedPrevInspectiondownloadFu() {
    this.selectedPrevInspectiondownload != this.selectedPrevInspectiondownload;
  }
  downloadFun() {
    this.download != this.download;
  }

  getZoneListData() {
    this.ZoneListData = []
    this.filteredZoneListData = null;
    this.selectedFiler.Zone = null;
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      clientId: this.selectedFiler.Clients ? this.selectedFiler.Clients.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.apiService
      .postCall("zones/getZonesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.ZoneListData = []
        this.filteredZoneListData = null
        if (result["data"]) {
          this.ZoneListData = result["data"];
          this.filteredZoneListData = this.ZoneListData.slice();
        } else {
          this.filteredZoneListData = null
          this.ZoneListData = []
          this.selectedFiler.Zone = null;
        }
      });
  }
  getStateListData() {
    let obj = {
      roleId: this.apiService.roleId,
      userId: this.apiService.userId,
      businessId: this.apiService.buisnessId,
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      pageNo: "",
      limit: "",
      name: "",
    };
    this.selectedFiler.state = null;
    this.selectedFiler.city = null;
    this.selectedFiler.cluster = null;
    this.selectedFiler.property = null;

    this.apiService
      .postCall("states/getStatesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        if (result["success"]) {
          this.stateListData = result["data"];
          if (this.stateListData.length > 0) {
            this.filteredstateListData = this.stateListData.slice();
          }
        } else {
          this.stateListData = [];
          this.selectedFiler.state = null;
        }
      });
  }

  getClusterListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
    };
    this.apiService
      .postCall("Clusters/getClustersPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);

        if (result["success"]) {
          this.clusterListData = result["data"];
          if (this.clusterListData.length > 0) {
            //this.filterClusterSearch = this.clusterListData.slice();
          }
        } else {
          this.clusterListData = [];
          this.selectedFiler.cluster = null;
        }
      });
  }

  getCityListData() {
    let obj = {
      businessId: this.apiService.buisnessId,
      authId: this.apiService.authId,
      name: "",
      zoneId: this.selectedFiler.Zone ? this.selectedFiler.Zone.id : "",
      stateId: this.selectedFiler.state ? this.selectedFiler.state.id : "",
      clusterId: this.selectedFiler.cluster ? this.selectedFiler.cluster.id : "",
      districtId: "",
    };
    this.apiService
      .postCall("cities/getCitiesPluck", {}, obj)
      .subscribe((result: any[]) => {
        // console.log(result);
        this.cityListData = result["data"];

        if (result["success"]) {
          this.cityListData = result["data"];
          if (this.cityListData.length > 0) {
            this.filteredcityListData = this.cityListData.slice();
            this.selectedFiler.city = null;
          }
        } else {
          this.cityListData = [];
          this.selectedFiler.city = null;
        }
      });
  }
  filterClients(event: any) {
    this.ClientsListData = this.filteredClientsListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClientsSearch.toLowerCase());
      }
    });
  }
  filterZone(event: any) {
    this.ZoneListData = this.filteredZoneListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterZoneSearch.toLowerCase());
      }
    });
  }
  filterState(event: any) {
    this.stateListData = this.filteredstateListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterStateSearch.toLowerCase());
      }
    });
  }
  filterCluster(event: any) {
    this.clusterListData = this.filteredclusterListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterClusterSearch.toLowerCase());
      }
    });
  }
  filterCity(event: any) {
    this.cityListData = this.filteredcityListData.filter((item: any) => {
      if (item.name != null) {
        return item.name
          .toLowerCase()
          .includes(this.filterCitySearch.toLowerCase());
      }
    });
  }
  getFrequency(d) {
    if (d.scheduleType == 1) return "Once"
    if (d.scheduleType == 2) return "Every day"
    if (d.scheduleType == 3) return "Every week"
    if (d.scheduleType == 4) return "Every month"
    if (d.scheduleType == 7) return "Every quarterly"
    if (d.scheduleType == 6) {

      let str = ""

      if (d.customType == 1) return "Every " + d.repeatDays + " Days"
      else if (d.customType == 2) return "Every " + d.repeatDays + " Week"
      else if (d.customType == 3) return "Every " + d.repeatDays + " Month"
      else if (d.customType == 4) return "Every " + d.repeatDays + " Year"
    }
  }
}





[{ "key": "name", "value": "", "description": "" }, { "key": "isActive", "value": "", "description": "" }, { "key": "outletId", "value": "", "description": "" }, { "key": "departmentId", "value": "", "description": "" }, { "key": "floorPlanId", "value": "", "description": "" }, { "key": "from", "value": "", "description": "" }, { "key": "to", "value": "", "description": "" }]