import { Component, HostListener, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../common/services/apiCalls.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { StoredData } from '../../common/services/storedData.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
declare var d3: any;
@Component({
  selector: 'app-parts-reports',
  templateUrl: './parts-reports.component.html',
  styleUrls: ['./parts-reports.component.css']
})
export class PartsReportsComponent implements OnInit {

  constructor(public route: Router, public apiService: ApiService, private fb: FormBuilder, public router: Router, public _location: Location, public storedData: StoredData) { }

  partsId: any;
  partsDetails: any
  partsStatus = []
  partsVendorName = ''
  assetList: []
  tabSelected = 'Active Logs'
  selectedFiler = { entry: null, fileName: null, fileType: null, dueDate: null, uploadedBy: null }
  logsList = []
  filesList = []

  report: FormGroup

  range: any;
  exportReportPermission = false
  saveNotify = false
  ngOnInit(): void {
    //     this.screenWidth= window.innerWidth;
    //     if (this.screenWidth <= 1000) {
    //       this.marginLeft = 0;
    //       this.fixedIWdth = 550;
    //     }
    //     else if (this.screenWidth <= 1380) {
    //       this.marginLeft = 0;
    //       this.fixedIWdth = 750;
    //     }
    //     else if (this.screenWidth <= 1450) {
    //       this.marginLeft = 0;
    //       this.fixedIWdth = 900;
    //     }
    //     else if (this.screenWidth <= 1500) {
    //       this.marginLeft = 2;
    //       this.fixedIWdth = 950;
    //     }
    //     else if (this.screenWidth <= 1600) {
    //       this.marginLeft = 3;
    //       this.fixedIWdth = 1000;
    //     }
    //     else if (this.screenWidth <= 1700) {
    //       this.marginLeft = 4;
    //       this.fixedIWdth = 1050;
    //     }
    //     else if (this.screenWidth <= 1800) {
    //       this.marginLeft = 6;
    //       this.fixedIWdth = 1100;
    //     }

    //     else if (this.screenWidth <= 1920) {
    //       this.marginLeft = 8;
    //       this.fixedIWdth = 1150;
    //     }

    //     else if (this.screenWidth <= 2048) {
    //       this.marginLeft = 9;
    //       this.fixedIWdth = 1200;
    //     }
    //     else if (this.screenWidth <= 2100) {
    //       this.marginLeft = 9;
    //       this.fixedIWdth = 1300;
    //     }
    //     else if (this.screenWidth <= 2400) {
    //       this.marginLeft = 9;
    //       this.fixedIWdth = 1500;
    //     }

    //     else if (this.screenWidth <= 2800) {
    //       this.marginLeft = 9;
    //       this.fixedIWdth = 1900;
    //     }


    // d3.selectAll("#chart2YAxis > *").remove();
    // d3.selectAll("#chart2 > *").remove();

    const date = new Date();
    let starDate = new Date(date.getFullYear(), date.getMonth(), 1);

    this.range = [starDate, date];


    this.apiService.startLoader()
    this.partsId = this.router.url.split("/")[2]
    this.apiService.getCall('parts/showDetails/' + this.partsId + "/" + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.partsDetails = result['part']
      this.partsStatus = result['statusList']
      let idx2 = this.partsStatus.findIndex(x => x.name == "Breakdown")
      this.partsStatus.splice(idx2, 1)

      // console.log(this.partsDetails.vendors);
      if (this.partsDetails.vendors && this.partsDetails.vendors.length > 0) {
        let vendors = this.partsDetails.vendors;
        if (this.partsDetails.vendors.length > 1) this.partsVendorName = "Multiple"
        else this.partsVendorName = this.partsDetails.vendors[0].name
      }
      this.apiService.stopLoader()
    })

    this.getReports(this.partsId);
    this.getUserList();

    this.report = this.fb.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required]
    });


    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 5);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Download Report" && permission[i]['actionStatus'] == 1) {
        this.exportReportPermission = true
      }
      if (permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Update Notify" && permission[i]['actionStatus'] == 1) {
        this.saveNotify = true
      }
    }

  }

  chartDataLine = {
    type: 'Line',
    data: [],
    chartColumns: [],
    options: {
      responsive: true,
      borderRadius: 15,
      colors: ['#33CCB2', '#EF6B6B'],
      legend: { position: 'none' },
      hAxis: { title: '' },
      vAxis: { title: '' },
      seriesType: 'bars',
      series: { 2: { type: 'line' } }
    },
    width: 500,
    height: 400
  };

  getAssetDetail() {
    // d3.selectAll("#chart2YAxis > *").remove();
    // d3.selectAll("#chart2 > *").remove();
    this.filterSelect = false
    this.ngOnInit()
  }



  applyFilter() {
    // console.log(this.range[0])
    this.getReports(this.partsId);
    // d3.selectAll("#chart2YAxis > *").remove();
    // d3.selectAll("#chart2x` > *").remove();
    this.filterSelect = false
  }
  filterSelect: boolean
  totalPartUsed: 0
  totalPartConsumed: 0
  totalCostUsed: 0
  daySinceLastUsed: 0
  quantityInHand: 0
  currentPrice: 0

  entry = []
  graphData = []
  getReports(partsId) {
    this.apiService.startLoader()
    let obj = {
      startDate: moment(this.range[0]).format('YYYY-MM-DD'),
      endDate: moment(this.range[1]).format('YYYY-MM-DD'),

    }
    this.apiService.postCall('parts/partRepors/' + this.partsId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      if (result['success'] == true) {
        let data = result['partsReports'];
        this.totalPartUsed = data.totalPartUsed;
        this.totalPartConsumed = data.totalPartConsumed;
        this.totalCostUsed = data.totalCostUsed;
        this.daySinceLastUsed = data.daySinceLastUsed;
        this.quantityInHand = data.quantityInHand;
        this.currentPrice = data.currentPrice;
        this.entry = result['entry']
        this.graphData = result['data']['graphArray']
        this.singleLinePlot()
      }
      this.apiService.stopLoader()
    })
  }
  exportAsPDF() {
    var HTML_Width = $("#divIdCharts").width();
    var HTML_Height = $("#divIdCharts").height();

    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    var t = this;
    html2canvas($("#divIdCharts")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      t.saveGraphImage('img1', imgData);
      // console.log(imgData);
    });
  };
  chart1
  saveGraphImage(imageName: any, url: any) {
    this.chart1 = url;
    this.getReportsDownload();
  }
  downloadReportButton() {
    this.exportAsPDF();
  }
  getReportsDownload() {
    this.apiService.startLoader()
    let obj = {
      startDate: moment(this.range[0]).format('YYYY-MM-DD'),
      endDate: moment(this.range[1]).format('YYYY-MM-DD'),
      download: true,
      dowbloadPartName: this.partsDetails?.partName,
      chart1:this.chart1
    }
    this.apiService.graphPostCall('parts/partRepors/' + this.partsDetails?.partId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }
  startDate
  endDate
  sortType = 'desc'
  sortBy = 'createdAt'
  sorted() {
    let body = {
      sortBy: this.sortBy,
      sortType: this.sortType,
      startDate: this.report.value.startDate ? moment(this.report.value.startDate).format('YYYY-MM-DD') : null,
      endDate: this.report.value.endDate ? moment(this.report.value.endDate).format('YYYY-MM-DD') : null
    };
    this.apiService.startLoader()
    this.apiService.postCall('parts/partRepors/' + this.partsId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      if (result['success'] == true) {
        let data = result['partsReports'];
        this.totalPartUsed = data.totalPartUsed;
        this.totalPartConsumed = data.totalPartConsumed;
        this.totalCostUsed = data.totalCostUsed;
        this.daySinceLastUsed = data.daySinceLastUsed;
        this.quantityInHand = data.quantityInHand;
        this.currentPrice = data.currentPrice;
        this.entry = result['entry']
        this.singleLinePlot()
      } else {
        this.totalPartUsed = 0;
        this.totalPartConsumed = 0;
        this.totalCostUsed = 0;
        this.daySinceLastUsed = 0;
        this.quantityInHand = 0;
        this.currentPrice = 0;
        this.entry = []
      }

      this.apiService.stopLoader()
    })
  }

  sortData(d) {
    this.sortBy = d
    if (this.sortType == 'asc') this.sortType = 'desc';
    else this.sortType = 'asc'
    this.sorted()
  }

  openDate(id) {
    (<HTMLInputElement>document.getElementById(id)).click();
  }

  search() {
    // console.log(this.report.value.startDate + "&&" + this.report.value.endDate)
    if (this.report.value.startDate && this.report.value.endDate) {
      if (this.report.value.startDate > this.report.value.endDate) {
        // console.log("in");
        this.message = "End Date should be greater than Start Date";
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.report.patchValue({ endDate: null })
      } else {
        d3.selectAll("#chart2YAxis > *").remove();
        d3.selectAll("#chart2 > *").remove();
        this.sorted();
        // console.log("out");
      }
    }
  }
  onChange(e, flag) {
    if (flag == 1) {
      this.startDate = e.target.value
    } else {
      this.endDate = e.target.value
    }
  }


  getValidation() {
    return (this.report.valid)
  }

  clear() {
    this.report.patchValue({ startDate: null })
    this.report.patchValue({ endDate: null })
    this.sortType = 'desc'
    this.sortBy = 'createdAt'
    this.sorted();
  }




  filterUserList = []
  userFilter = false;
  userList
  getUserList(): void {
    this.apiService.startLoader()
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }

    this.apiService.postCall('users/user-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.userList = result['data'];
      this.filterUserList = result['data'];
      // console.log("List of Users", this.userList);

    })

  }


  uploadedFilesImage: any
  fileName: any;
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFilesImage = file
      this.fileName = file.name
    }
    let testData: FormData = new FormData();

    testData.append('image', this.uploadedFilesImage);
    testData.append("partId", this.partsDetails.partId);
    testData.append("authId", this.apiService.authId);
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.ngOnInit();
      this.fileName = '';
      document.getElementById('dismissPopUp').click()

    })

  }

  message
  changeStatus() {
    let testData: FormData = new FormData();
    testData.append("status", this.partsDetails.status);
    testData.append("authId", this.apiService.authId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/changeStatus/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      document.getElementById('dismissPopUp').click()

    })
  }




  propertFilter = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      //     this.assetFilter= false;
      // this.locationFilter= false;
      // this.businessFilter= false;
      this.propertFilter = false;
      this.userFilter = false;
      this.downloadDiv = false
      this.filterSelect = false
    }
  }
  closeAll() {
    // this.assetFilter= false;
    // this.locationFilter= false;
    // this.businessFilter= false;
    this.propertFilter = false;
    this.userFilter = false;
  }



  singleLinePlot() {
    this.graphData.splice(0, 1);
    // this.graphData.push([0,0])
    // console.log(this.graphData)
    this.chartDataLine = {
      type: 'Line',
      data: this.graphData,
      chartColumns: ['Date', 'Count'],
      options: {
        responsive: true,
        borderRadius: 15,
        colors: ['#33CCB2', '#EF6B6B'],
        legend: { position: 'none' },
        hAxis: { title: '' },
        vAxis: { title: '' },
        seriesType: 'bars',
        series: { 2: { type: 'line' } }
      },
      width: 500,
      height: 400
    };
  }
  singleLinePlot2() {

    // console.log(this.graphData)

    var data = [{ ser2: 0, ser1: 0 }]
    // this.historyList.map(x=> x.createdAt = moment(x.createdAt).format('YYYY-MM-DD HH:MM:SS'))

    for (let i = 1; i < this.graphData.length; i++)data.push({ ser2: this.graphData[i][1], ser1: this.graphData[i][0] })
    // console.log(data)
    var margin = { top: 10, right: 30, bottom: 30, left: 50 },
      width = this.fixedIWdth,
      height = 400 - margin.top - margin.bottom;

    //  var parseDate = d3.time.format("%Y-%m-%d %X");
    var parseDate = d3.timeFormat("%Y-%m-%d %H:%M:%S").parse;

    data.forEach(function (d) {
      //// console.log(d.date_time)
      // d.ser1 = moment(d.ser1).format('YYYY-MM-DD HH:MM:SS');

    });

    // console.log(data)

    var div = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);


    var svgAxis = d3.select("#chart2YAxis").append("svg")
      .attr("width", 60)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + 50 + "," + margin.top + ")");

    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return +d.ser2; })])
      .range([height, 0]);
    svgAxis.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");




    var svg = d3.select("#chart2")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + -10 + "," + margin.top + ")");



    // svg.append("text")
    // .attr("class", "x label cart-title")
    // .style("fill", "#33CCB2")
    // .attr("text-anchor", "end")
    // .attr("x", width -50 )
    // .attr("y", height + 30)
    // .text("Time Chart");


    svgAxis.append("text")
      .attr("class", "y label cart-title")
      .style("fill", "#33CCB2")
      .attr("text-anchor", "end")
      .attr("x", -86)
      .attr("y", -46)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("Value");
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "myXaxis")

    var area = d3.area()
      .curve(d3.curveBasis)
      .x(function (d) { return x(d.ser1); })
      .y0(height)
      .y1(function (d) { return y(d.ser2); });

    ///////////////////////////////////////////////////////////
    // Initialise a X axis:
    var x = d3.scaleBand()
      .domain(data.map(function (d) { return d.ser1; 1 }))
      .range([0, width])


    svg.append("g")
      .attr("transform", "translate(-300,360)")
      .call(d3.axisBottom(x))
      .attr("class", "myXaxis");

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) { return +d.ser2; })])
      .range([height, 0]);
    svg.append("g")
      .call(d3.axisLeft(y))
      .attr("class", "myYaxis");

    /////////////////////////////////

    //////////////////////////////////////////////////////

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 4.5)
      .attr("class", "lineTest")
      .attr("d", d3.line()
        .curve(d3.curveBasis)
        .x(function (d) { return x(d.ser1) })
        .y(function (d) { return y(d.ser2) })
      )
    var dots = svg.selectAll("dot")
      .data(data)
      .enter().append("circle")
      .attr("r", 5)
      .attr("fill", "#33CCB2")
      //  .attr("transform", "translate(80,0)")
      .attr("cx", function (d) { return x(d.ser1); })
      .attr("cy", function (d) { return y(d.ser2); })
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", .9);
        div.html((d.ser1) + "<br/>" + d.ser2)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(500)
          .style("opacity", 0);
      });

    svg.append("path")
      .data([data])
      .attr("class", "myArea")
      .attr("d", area);






  }

  showIcon() {
    return this.downloadDiv = !this.downloadDiv
  }
  // exportAsPDF(div_id) {
  //   document.getElementById("pdfButton").style.display = "none";
  //   document.getElementById("pdfButton1").style.opacity = "0";
  //   document.getElementById("pdfButton2").style.opacity = "0";
  //   document.getElementById("pdfButton3").style.opacity = "0";

  //   let ele = document.getElementById('headerText');
  //   ele.innerHTML = 'Consolidated Inventory Report for ' + this.partsDetails.partName;
  //   let data = document.getElementById(div_id);
  //   // console.log(data.clientHeight)
  //   html2canvas(data, {
  //     width: 2480,
  //     height: data.clientHeight + 100
  //   }).then((canvas) => {
  //     let imgWidth = 500;
  //     let pageHeight = 480;
  //     let imgHeight =
  //       ((canvas.height * imgWidth) / 2454) * 1.24;
  //     var heightLeft = imgHeight;

  //     const imgData = canvas.toDataURL("image/png");

  //     const pdf = new jspdf({
  //       orientation: "p",
  //       unit: "mm",
  //       format: [400, 480],
  //     });

  //     let position = 0;
  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       pdf.addPage();
  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }
  //     pdf.save("Result.pdf");
  //     document.getElementById("pdfButton").style.display = "block";
  //     document.getElementById("pdfButton1").style.opacity = "1";
  //     document.getElementById("pdfButton2").style.opacity = "1";
  //     document.getElementById("pdfButton3").style.opacity = "1";
  //     let ele = document.getElementById('headerText');
  //     ele.innerHTML = 'Consolidated Inventory Report';
  //   });


  // }

  downloadDiv: boolean;


  screenWidth: any
  fixedIWdth: any = 1200;
  marginLeft: any
  @HostListener('window:resize', ['$event'])
  resize(event) {

    // console.log(event.target.innerWidth)
    this.screenWidth = event.target.innerWidth;
    if (this.screenWidth <= 1000) {
      this.marginLeft = 0;
      this.fixedIWdth = 550;
    }
    else if (this.screenWidth <= 1380) {
      this.marginLeft = 0;
      this.fixedIWdth = 750;
    }
    else if (this.screenWidth <= 1450) {
      this.marginLeft = 0;
      this.fixedIWdth = 900;
    }
    else if (this.screenWidth <= 1500) {
      this.marginLeft = 2;
      this.fixedIWdth = 950;
    }
    else if (this.screenWidth <= 1600) {
      this.marginLeft = 3;
      this.fixedIWdth = 1000;
    }
    else if (this.screenWidth <= 1700) {
      this.marginLeft = 4;
      this.fixedIWdth = 1050;
    }
    else if (this.screenWidth <= 1800) {
      this.marginLeft = 6;
      this.fixedIWdth = 1100;
    }

    else if (this.screenWidth <= 1920) {
      this.marginLeft = 8;
      this.fixedIWdth = 1150;
    }

    else if (this.screenWidth <= 2048) {
      this.marginLeft = 9;
      this.fixedIWdth = 1200;
    }
    else if (this.screenWidth <= 2100) {
      this.marginLeft = 9;
      this.fixedIWdth = 1300;
    }
    else if (this.screenWidth <= 2400) {
      this.marginLeft = 9;
      this.fixedIWdth = 1500;
    }

    else if (this.screenWidth <= 2800) {
      this.marginLeft = 9;
      this.fixedIWdth = 1900;
    }


    d3.selectAll("#chart2YAxis > *").remove();
    d3.selectAll("#chart2 > *").remove();

    this.singleLinePlot()
    // this.linecurveChart()
  }


}

