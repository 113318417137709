import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';

@Component({
  selector: 'app-meter-details-edit',
  templateUrl: './meter-details-edit.component.html',
  styleUrls: ['./meter-details-edit.component.css']
})
export class MeterDetailsEditComponent implements OnInit {

  @Input() meterDetails: any;
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  constructor(private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService, ) { }
  // meterDetailsForm: FormGroup

  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false,
    dateType: 'start'
  }


  selectUnitdataCustom(){
    this.selectUnit = !this.selectUnit
    this.meterDetailsForm.patchValue({
      unit: {id :this.meterDetailsForm.value.frequencyCustom , name: this.meterDetailsForm.value.frequencyCustom}
    })
      
    // )}
  }

  selectFreq=false;
selectUnit=false;
selectFreqdata(data){
  this.selectFreq = !this.selectFreq
  this.meterDetailsForm.patchValue({frequency:data})
}
selectCustomData(data){
  this.meterDetailsForm.patchValue({frequency:data})
}
selectUnitdata(data){
  this.selectUnit = !this.selectUnit
  this.meterDetailsForm.patchValue({unit:data})
}
unitOfMeasure=false
selectFreqUnitdata(data){
  this.unitOfMeasure = !this.unitOfMeasure
  this.meterDetailsForm.patchValue({frequencyUnit:data})
}

meterDetailsForm = this.fb.group({
  property:[null , Validators.required],
  meterName:[null , Validators.required],
  location:[null ,Validators.required],
  asset:[null],
  frequency:[null , Validators.required],
  frequencyNumber:[null] ,
  frequencyUnit:[null],
  unit:[null ,Validators.required],
  frequencyCustom:[null],
  
  meterId:[null],
  filterUnit:[null],
});

unitListFilter=[];
assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {
    
    // console.log(this.meterDetails)
    // this.

    // this.getAssetList()
    // this.getPropertyList()
    this.getUnitList();
    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==6 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  userAssign=[]

  submit() {
    // console.log(this.meterDetailsForm.value)
    let userAssignList=[]
    for(let d of this.userAssign){userAssignList.push(d.userId)}
    let obj = {
    
      meterName:this.meterDetailsForm.value.meterName,
      unitId:this.meterDetailsForm.value.unit.id,
      businessId:this.apiService.buisnessId,
      frequency:this.meterDetailsForm.value.frequency,
      frequencyUnit:this.meterDetailsForm.value.frequencyUnit,

      frequencyNo:this.meterDetailsForm.value.frequencyNumber,
      assetManagementId:this.meterDetailsForm.value.asset,
      outletId:this.meterDetailsForm.value.property,
      locationId:this.meterDetailsForm.value.location,
      userIds:userAssignList.toString(),
      userNames:"hp s,pk",
      authId:this.apiService.userId
    }
// console.log(obj)
this.apiService.startLoader()
    this.apiService.postCall('meters/edit/'+this.meterDetailsForm.value.meterId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.updateData.emit(result['msg'])
      document.getElementById('dismissPopUp').click()
      
    })
  
}
statusList = [
  { id: 0, name: 'Deactive' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Dormant' },
  { id: 3, name: 'Migrated' },
  { id: 4, name: 'In Progress' },
  { id: 5, name: 'In Transit' },
]


getFrequencyName(){
  if(this.meterDetailsForm.value.frequency == null) return 'Set a frequency'
  else if(this.meterDetailsForm.value.frequency == 'Custom') return 'Every '+(this.meterDetailsForm.value.frequencyNumber?this.meterDetailsForm.value.frequencyNumber:'') +" "+ (this.meterDetailsForm.value.frequencyUnit?this.meterDetailsForm.value.frequencyUnit:'')
  else return this.meterDetailsForm.value.frequency
}
selectCustom=false;
ngOnChanges() {
  // this.ngOnInit()
  this.userAssign=[]
  // console.log(this.meterDetails);
  if (this.meterDetails) {
    this.meterDetailsForm.patchValue({
      property:this.meterDetails.outletId,
      meterName:this.meterDetails.meterName,
      location:this.meterDetails.locationId,
      asset:this.meterDetails.assetManagementId,
      frequency:this.meterDetails.frequency,
      
      meterId:this.meterDetails.meterId,

      frequencyNumber:this.meterDetails.frequency_no ,
  frequencyUnit:this.meterDetails.frequency_unit,

      // assignedto:parseInt(this.meterDetails.userId)
      
    })

    let toArray =  this.meterDetails.userId.split(",");

    for(let d of toArray)this.userAssign.push({userId : parseInt(d)})
    // this.getLocationList()
    // this.getUserList()
    this.getPropertyList()
  }
}

getUnitData(data){
  let idx  = this.unitList.findIndex(x=> x.id == data)
  if(idx != -1) return this.unitList[idx]
  else null
}
addMoreUser():void{
  // this.formUserArr.push(this.initUser());
  this.userAssign.push({userId : null})
  
}

removeUser(idx){
  this.userAssign.splice(idx,1)
}

propertyList=[]
getPropertyList(){
  // console.log("getProperty12121")
  this.apiService.startLoader()
  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    // "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
  }
  this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj  ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.propertyList = result['data']

  let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
  // console.log(this.apiService.roleId)

  // if(this.apiService.roleId != 1 && this.apiService.roleId != 2 && idx !=-1 ){
  //   this.meterDetailsForm.patchValue({
  //     property :this.propertyList[idx].id 
  //   })
    
  //   this.getLocationList();this.getUserList();this.getAssetList()
  // }
  // else{
    this.getLocationList();this.getUserList();this.getAssetList()
  // }
 
 
  
})
}

locationList=[]
getLocationList(){
  this.apiService.startLoader()
  let obj ={
    "roleId":this.apiService.roleId,
    "userId":this.apiService.userId,
    "businessId": this.apiService.buisnessId,
    "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
  }
  // http://52.14.126.174/synccit/locations/location-list/:businessId/:outletId
  this.apiService.postCall('locations/location-list/'+this.apiService.buisnessId,{} , obj  ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.locationList = result['data']
 
  
})
}



assetList=[]
  getAssetList(){
    this.apiService.startLoader()
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
      "statusNotIn":"5,8",
    }
		this.apiService.postCall('asset-managements/assetMainLists/'+this.apiService.buisnessId,{} , obj ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.assetList=[]
      this.assetList= result['data']

    })
  }

userList
  getUserList(){
    this.apiService.startLoader()
    // http://52.14.126.174/synccit/users/user-list/:businessId/:outletId  (26/71)
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.meterDetailsForm.value.property?this.meterDetailsForm.value.property : "",
    }
  this.apiService.postCall('users/user-list/'+this.apiService.buisnessId,{} ,obj ).subscribe((result: any[])=>{
  // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // console.log(result);
    result['data']
    this.apiService.stopLoader()

  
  this.userList = result['data']
 
  } )

}

filterLocation(ev){
  // console.log(ev)

    this.unitList = this.unitListFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.meterDetailsForm.value.filterUnit.toLowerCase())
      }
    }
    )

}

getUnitList(){
  this.apiService.getCall('units/unitList',{}  ).subscribe((result: any[])=>{
    this.unitList = result['data']
    this.unitListFilter = result['data']

    // console.log(this.meterDetails.unitId)
    this.meterDetailsForm.patchValue({
    unit:this.getUnitData(this.meterDetails.unitId),
    })
  })
}


  unitList = []


  frquencyList=[{id:"1"},{id:"2"},{id:"3"},{id:"4"},{id:"5"},{id:"6"},{id:"7"}]

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if(btn.id != 'custom') {
      this.selectUnit= false
      this.selectFreq = false
    }
 }

}

