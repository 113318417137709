import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-features',
  templateUrl: './business-features.component.html',
  styleUrls: ['./business-features.component.css']
})
export class BusinessFeaturesComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService: ApiService,
    public storedData: StoredData,
  ) { }
  businessId: any;
  featureList: any;
  timeList = [{ id: '00', name: 'Select Time' }, { id: '01', name: '01:00' }, { id: '02', name: '02:00' }, { id: '03', name: '03:00' }, { id: '04', name: '04:00' }, { id: '05', name: '05:00' }, { id: '06', name: '06:00' }, { id: '07', name: '07:00' }, { id: '08', name: '08:00' }, { id: '09', name: '09:00' }, { id: '10', name: '10:00' }, { id: '11', name: '11:00' }, { id: '12', name: '12:00' }, { id: '13', name: '13:00' }, { id: '14', name: '14:00' }, { id: '15', name: '15:00' }, { id: '16', name: '16:00' }, { id: '17', name: '17:00' }, { id: '18', name: '18:00' }, { id: '19', name: '19:00' }, { id: '20', name: '20:00' }, { id: '21', name: '21:00' }, { id: '22', name: '22:00' }, { id: '23', name: '23:00' }]
  timeListMin = [{ id: '', name: 'Select Min' }, { id: '15', name: '15' }, { id: '30', name: '30' }, { id: '45', name: '45' }, { id: '60', name: '60' }, { id: '75', name: '75' }, { id: '90', name: '90' }, { id: '105', name: '105' }, { id: '120', name: '120' }, { id: '135', name: '135' }, { id: '150', name: '150' }]
  featureForm = this.fb.group({
    features: this.fb.array([]),
  });
  saveData = false;
  message: any;
  ngOnInit(): void {
    this.businessId = this.route.url.split("/")[2];
    this.getFeatures();
  }

  getFeatures(): void {
    this.apiService.startLoader()
    this.apiService.getCall('business-app-features/index/' + this.businessId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.featureList = []
      if (result['success'] == true) {
        for (let d of result['data']) {
          this.addMoreUser(d);
        }
      }
    })
  }

  initFeatures(data: any) {
    // console.log(data)
    return this.fb.group({
      name: data.name,
      featureId: data.featureId,
      id: data.id,
      permissionAllow: data.permissionAllow,
      cronTime: data.cronTime,
      lockMin: data.lockMin,
    });
  }

  get formFeatureArr() {
    return this.featureForm.get('features') as FormArray;
  }

  addMoreUser(data: any): void {
    this.formFeatureArr.push(this.initFeatures(data));
  }

  submitUserData(): void {
    // console.log("formData",this.featureForm.value);
    let formData = this.featureForm.value;
    this.apiService.startLoader()
    this.apiService.postCall('/business-app-features/edit', {}, formData).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.saveData = result['success'];
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }

  changePermission(d, event) {
    // console.log(d.value['featureId'],event.target.checked);
    // console.log("formData",this.featureForm.value['features']);
    if (event.target.checked) {

    } else {
      if (d.value['featureId'] == 14) {
        var chkPassport = (<HTMLInputElement>document.getElementById('permition16'))
        var chkPassport2 = (<HTMLInputElement>document.getElementById('permition17'))
        if (chkPassport.checked) {
          $("#permition16").trigger("click");
        }
        if (chkPassport2.checked) {
          $("#permition17").trigger("click");
        }
      }
    }

    if (d.value['featureId'] == 16 || d.value['featureId'] == 17) {
      if (event.target.checked) {
        var chkPassport = (<HTMLInputElement>document.getElementById('permition14'))
        if (chkPassport.checked) {

        } else {
          $("#permition" + d.value['featureId']).trigger("click");
        }
      }
    }

  }
  goToIndex() {
    this.route.navigate(['/business-index'])
  }

}
