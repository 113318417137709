import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../common/services/apiCalls.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormArray, FormBuilder,Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-parts-add',
  templateUrl: './parts-add.component.html',
  styleUrls: ['./parts-add.component.css']
})
export class PartsAddComponent implements OnInit {

  constructor(
    public apiService:ApiService,
    public _location:Location,
    public router:Router,
    public formBuilder:FormBuilder,

  ) { }
  todayDate: any = new Date();
  propertyList=[]
  locationList =[]
  propertyId=''
  partForm = this.formBuilder.group({
    businessId: this.apiService.buisnessId,
    authId: this.apiService.authId,
    propertyId: [null, Validators.required],
    partName: [null, Validators.required],
    number: [null, Validators.required],
    serialNo:[null, ],
    unitOfMeasure: [null, Validators.required],
    quentityInHand:[null, [Validators.required,Validators.min(1)]],
    pricePerPice:[null,Validators.required],
    minimumQuentity:[null,[Validators.required,Validators.min(0)]],
    locationId:[null,Validators.required],
    departmentId:[null],
    categoryType:[null],
    image:[null],
    purchaseDate:[null],
    //groupId:[null,],
    expiry:[null],
    warrantyExpiry:[null],
  });

  businessId=0;
  outletId=0
  // ngOnInit(): void {
    ngOnDestroy(){
      document.body.style.overflowY = 'scroll';
    }
    
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    if(this.apiService.roleId==1){
      this.getPropertyList(0,0);
    }
    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      this.businessId=this.apiService.buisnessId;
      if(this.apiService.roleId==3){
        this.outletId=this.apiService.outletId;
        this.getPropertyList(this.businessId,this.apiService.userId);
      }else{
        this.getPropertyList(this.businessId,0);
      }
    }
  }
  goBack():void{
    this._location.back();
  }
  departmentList:[]
  groupList:[]
  getPropertyList(businessId,userId){
    this.apiService.startLoader()
    
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/'+this.apiService.buisnessId,{} , obj  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()
      this.propertyList = [];
      this.propertyList = result['data'];
      this.groupList = result['groups'];
      this.departmentList = result['departments'];
    })
  }

  getLocationList(){
    this.apiService.startLoader()
    let obj ={
      "roleId":this.apiService.roleId,
      "userId":this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId":this.partForm.value.propertyId?this.partForm.value.propertyId : "",
    }
    this.apiService.postCall('locations/locationList/'+this.businessId,{}  , obj  ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.locationList = [];
      this.locationList = result['data'];
    })
  }

  validateDate(satrtDate , endDate){
    //  // console.log(satrtDate , endDate)
if(satrtDate && endDate){
    if(endDate <= satrtDate) return true
    else return false
   }
  }

  getValidationDate(){
    if(this.partForm.value.purchaseDate ){
    if( this.partForm.value.expiry && (this.partForm.value.purchaseDate > this.partForm.value.expiry) ) return true
    else if (this.partForm.value.warrantyExpiry && (this.partForm.value.purchaseDate > this.partForm.value.warrantyExpiry) ) return true
    else return false
    }
    else return false
  }


  fileName
  uploadedFiles
  fileChange(event) {


    // console.log(event)

    if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/jpeg" ){
      var span = document.getElementById('images');
      var img = document.createElement('img');
    img.src =  URL.createObjectURL(event.target.files[0]);
    img.id =  "firstId";
    if(document.getElementById('firstId')){
      document.getElementById('firstId').remove();
    }
    span.appendChild(img);
    
    // span.removeChild(img);
    img.style.height="88px"
    // console.log(event)



    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        // console.log(file)
        file = file
        this.uploadedFiles=file;
        this.fileName = file.name
      }
    }
    else{
      
      this.message = "File with format "+event.target.files[0].type+" not acceptable, please choose images only" ;
     
       
      (<HTMLInputElement>document.getElementById('openError')).click();
    }
    }
    removeImage(){
      this.fileName= null;
      this.uploadedFiles=[];
      if(document.getElementById('firstId')){
        document.getElementById('firstId').remove();
      }
    }

  getValidation() {
    return (this.partForm.valid  && !this.validateDate(this.partForm.value.purchaseDate,this.partForm.value.expiry) && !this.validateDate(this.partForm.value.purchaseDate,this.partForm.value.warrantyExpiry))
  }

  onchangeDate(event,flag){
    //(ngModelChange)="onchangeDate($event,1)"
    /*// console.log(this.partForm.value.purchaseDate +" "+this.partForm.value.expiry);
    if(flag == 1 && this.partForm.value.expiry && this.partForm.value.purchaseDate > this.partForm.value.expiry){
      this.partForm.patchValue({expiry : null})
      this.message = "Expiry Date should be greater than purchase date";
      (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      // console.log("in");
    }
    if(flag == 2 && this.partForm.value.warrantyExpiry && this.partForm.value.purchaseDate > this.partForm.value.warrantyExpiry){
      this.partForm.patchValue({warrantyExpiry : null})
      this.message = "Warranty Expiry Date should be greater than purchase date";
      (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      // console.log("out");
    }*/
  }
  message
  savePart():void{

    if(this.partForm.value.expiry && this.partForm.value.purchaseDate > this.partForm.value.expiry){
      this.partForm.patchValue({expiry : null})
      this.message = "Expiry Date should be greater than purchase date";
      (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      // console.log("in");
      return;
    }
    if(this.partForm.value.warrantyExpiry && this.partForm.value.purchaseDate > this.partForm.value.warrantyExpiry){
      this.partForm.patchValue({warrantyExpiry : null})
      this.message = "Warranty Expiry Date should be greater than purchase date";
      (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      // console.log("out");
      return;
    }

    let bId;
    if(this.apiService.roleId==2 || this.apiService.roleId==3){
      bId=this.apiService.buisnessId
    }

    let testData: FormData = new FormData();
    testData.append('businessId',this.apiService.buisnessId);
    testData.append('propertyId', this.partForm.value.propertyId);
    testData.append('authId',this.apiService.authId);
    testData.append('partName',this.partForm.value.partName);
    testData.append('categoryType',this.partForm.value.categoryType);
    testData.append('number',this.partForm.value.number);
    testData.append('serialNo',this.partForm.value.serialNo);
    testData.append('unitOfMeasure',this.partForm.value.unitOfMeasure);
    testData.append('quentityInHand',this.partForm.value.quentityInHand);
    testData.append('pricePerPice',this.partForm.value.pricePerPice);
    testData.append('minimumQuentity',this.partForm.value.minimumQuentity);
    testData.append('locationId',this.partForm.value.locationId);
    testData.append('departmentId',this.partForm.value.departmentId);
    if(this.uploadedFiles){
      testData.append('image',this.uploadedFiles);
    }else{
      testData.append('image','');
    }
    testData.append('purchaseDate',this.partForm.value.purchaseDate ? moment(this.partForm.value.purchaseDate).format('YYYY-MM-DD') : '');
    //testData.append('groupId',this.partForm.value.groupId);
    testData.append('expiry',this.partForm.value.expiry ? moment(this.partForm.value.expiry).format('YYYY-MM-DD') : '');
    testData.append('warrantyExpiry',this.partForm.value.warrantyExpiry ? moment(this.partForm.value.warrantyExpiry).format('YYYY-MM-DD') : '');

    this.apiService.startLoader()
    this.apiService.postCall('parts/add',{},testData).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader();
      if(result['success'] == true){
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }else{
        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess2')).click();
      }
    })
  }

  openDate(id){
    (<HTMLInputElement>document.getElementById(id)).click();
  }
  backClicked(){
    this.router.navigate(['/parts-inventory'])
  }

  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

}
