import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoredData } from '../common/services/storedData.service';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-insurance-notification-setting',
  templateUrl: './insurance-notification-setting.component.html',
  styleUrls: ['./insurance-notification-setting.component.css']
})
export class InsuranceNotificationSettingComponent implements OnInit {

  constructor(public _location: Location, public route: Router, private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }
  insuranceForm: FormGroup

  conditionListing = [{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]

  insuranceId
  insuranceDetails
  userTo = []
  userList = []
  selectedUserList = []
  count = [1]
  type
  insuranceDetailsForm = this.fb.group({
    userTo: [null]
  });
  fileName
  cureentDate
  endDateCheckInsurance
  businessId
  saveNotify = false
  assetViewPermission = true;
  assetsFeaturePermission = false;
  ngOnInit(): void {
    this.cureentDate = (new Date()).toISOString().split('T')[0];
    //this.type = this.route.url.split("/")[2]
    this.insuranceId = this.route.url.split("/")[2]
    this.apiService.startLoader()
    this.apiService.getCall('insurances/view/' + this.insuranceId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.insuranceDetails = result['data']
      this.businessId = this.insuranceDetails.businessId
      this.period = result['data']['period']
      this.periodType = result['data']['periodType']
      this.getUserList();
      this.changePeriodTYpe(0);
      if (result['data']['userTo']) {
        this.selectedUserList = result['data']['userTo']
        this.userTo = this.selectedUserList;
        if (this.selectedUserList.length !== 1) {
          this.count.push(this.selectedUserList.length);
        }
      }

      if (this.apiService.roleId == 1) {
        this.businessId = this.businessId
      } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
        this.businessId = this.apiService.buisnessId
      }
      this.endDateCheckInsurance = this.insuranceDetails.endDate;
      this.apiService.stopLoader()
    })


    this.insuranceForm = this.fb.group({
      userTo: [null, Validators.required],
      assignee: null
    });

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Insurance" && permission[i]['action'] == "Notification Setting" && permission[i]['actionStatus'] == 1) {
        this.saveNotify = true
      }
      if (permission[i]['permissionModuleId'] == 3 && permission[i]['subModuleName'] == "Assets" && permission[i]['action'] == "View" && permission[i]['actionStatus'] == 1) {
        this.assetViewPermission = true
      }
    }
    let businessFeatures = [];
    businessFeatures = JSON.parse(localStorage.getItem('businessFeatures'));
    if (businessFeatures != null) {
      for (var i = 0; i < businessFeatures.length; i++) {
        if (businessFeatures[i]['business_app_feature_id'] == 14 && businessFeatures[i]['permission_allow'] == 1) {
          this.assetsFeaturePermission = true
        }
      }
    }
    if (this.apiService.role == 1) {
      this.assetsFeaturePermission = true;
    }
  }

  getUserList() {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      "outletId": this.insuranceDetails.outletId,
      //"onlyUsers":1,
    }
    this.apiService.postCall('users/user-list/' + this.businessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.userList = result['data']
      this.apiService.stopLoader()
    })
  }

  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  getSelectedValue(id) {
    for (var i = 0; i < this.userList.length; i++) {
      if (this.userList[i].id == id) {
        return this.userList[i].fullName;
      }
    }
  }

  addUser(id) {
    if (id.target.value != "Select User") {
      this.userTo.push(id.target.value);
      this.submit();
    }
  }
  addMoreTeam(): void {
    this.count.push(this.count.length + 1);
    // console.log(this.count);
  }

  selectUnit = false;
  selectIsActivedata(data) {
    this.selectUnit = !this.selectUnit
    this.insuranceForm.patchValue({ unit: data })
  }
  userArry = []
  removeUser(i) {
    this.selectedUserList.splice(i, 1)
  }
  selectUserUsers = null
  selectUser(d) {
    this.selectUserUsers = null;
    if (d.target.value > 0) {
      this.selectedUserList.push(d.target.value)
      this.selectedUserList = this.selectedUserList.filter((v, i, a) => a.indexOf(v) === i);
      this.insuranceForm.patchValue({ assignee: null });
      var ds = this.userList;
      this.userList = [];
      this.userList = ds;
    }
    console.log(this.selectUserUsers)
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  submit() {
    let obj = {
      businessId: this.apiService.buisnessId,
      userTo: this.selectedUserList,
      authId: this.apiService.authId,
      period: this.period,
      periodType: parseInt(this.periodType)
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('insurances/edit/' + this.insuranceDetails.insuranceId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.ngOnInit();
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      this.selectedUserList = []
    })

  }
  backClicked() {
    this.route.navigate(['/insurance-list'])
  }

  message
  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]

  getValidation() {
    if (this.selectedUserList.length > 0 && this.periodType != null && this.period != null) {
      return true;
    }
    return false;
  }

  period = null
  periodType = null
  selectList = []
  changePeriodTYpe(flag) {
    if (flag == 1) {
      this.selectList = [];
      this.period = null;
    }
    if (this.periodType == '2') {
      for (let i = 1; i <= 12; i++)this.selectList.push(i)
    } else {
      for (let i = 1; i <= 30; i++)this.selectList.push(i)
    }
  }
}
