import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../common/services/apiCalls.service';
import { HashLocationStrategy, Location } from '@angular/common';
import { StoredData } from '../../common/services/storedData.service';

@Component({
  selector: 'app-parts-details',
  templateUrl: './parts-details.component.html',
  styleUrls: ['./parts-details.component.css']
})
export class PartsDetailsComponent implements OnInit {
  searchPromise2 =null;

  constructor(public route: Router, public apiService: ApiService, public router: Router, public _location: Location, public storedData: StoredData) { 
    router.events.subscribe((val) => {
      clearTimeout(this.searchPromise2);
      this.searchPromise2 = setTimeout(() => {
        this.ngOnInit()
      } ,500)
     });
  }

  partsId: any;
  partsDetails: any
  partsStatus=[]
  partsVendorName=''
  locationList=[]

  printBarcodePermission=false
  editPermissionPermission=false
  updateQuantityPermission=false
  viewEditLocationPermission=false
  saveNotify=false

  ngOnInit(): void {
    this.apiService.startLoader()
    this.partsId = this.router.url.split("/")[2]
    this.apiService.getCall('parts/showDetails/' + this.partsId + "/" + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.partsDetails = result['part']
      this.partsStatus = result['statusList']

      if(this.partsStatus){
        let idx4 = this.partsStatus.findIndex(x=> x.name== "Breakdown")
        this.partsStatus.splice(idx4,1)
        // idx4 = this.partsStatus.findIndex(x=>x .name== "In Transit")
        // this.partsStatus.splice(idx4,1)
        // idx4 = this.partsStatus.findIndex(x=>x .name== "In Migration")
        // this.partsStatus.splice(idx4,1)
      }

      this.locationList = result['locationList']
      if(this.partsDetails){
        if(this.partsDetails.vendors && this.partsDetails.vendors.length > 0){
          let vendors = this.partsDetails.vendors;
          if(this.partsDetails.vendors.length > 1)this.partsVendorName = "Multiple"
          else this.partsVendorName = this.partsDetails.vendors[0].name
        }
      }
      this.apiService.stopLoader()
    })

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==5 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Print Barcode" && permission[i]['actionStatus'] == 1){
        this.printBarcodePermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1){
        this.editPermissionPermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Update Quantity" && permission[i]['actionStatus'] == 1){
        this.updateQuantityPermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "View Edit Location" && permission[i]['actionStatus'] == 1){
        this.viewEditLocationPermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Update Notify" && permission[i]['actionStatus'] == 1){
        this.saveNotify=true
      }
    }

  }

  openQR() {
    this.storedData.qrData = { name: this.partsDetails.partName, image: this.partsDetails.partQrCode, customerComplaintName:"" }
    this.router.navigate(['print-qr'])
  }

  uploadedFiles: any
  fileName: any;
  fileChange(event) {
    // // console.log(event)
    // let fileList: FileList = event.target.files;
    // if (fileList.length > 0) {
    //   let file: File = fileList[0];
    //   // console.log(file)
    //   this.uploadedFiles = file
    //   this.fileName = file.name
    // }
    this.uploadedFiles = event;
    let testData: FormData = new FormData();

    testData.append('image', this.uploadedFiles);
    testData.append("partId", this.partsDetails?.partId);
    testData.append("authId", this.apiService.authId);
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails?.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.ngOnInit();
      document.getElementById('dismissPopUp').click()

    })

  }

  message
  changeStatus() {
    let testData: FormData = new FormData();
    testData.append("status", this.partsDetails.status);
    testData.append("authId", this.apiService.authId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/changeStatus/' + this.partsDetails?.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess3')).click();
      document.getElementById('dismissPopUp').click()

    })
  }
  closeAll() {
    (<HTMLInputElement>document.getElementById('closePopUp')).click();
  }



  getAssetDetail(){
    this.ngOnInit()
  }









  

  statusList = [
    { id: 1, name: 'Active' },
    { id: 0, name: 'Deactive' }
  ]
  conditionListing = [{ "id": "Used", "name": "Used" }, { "id": "Unused", "name": "Unused" }]


  goToLink(url: string) {
    window.open(url, "_blank");
  }
  getFileName(name) {
    if (name) {
      return name.split("/")[5];
    }
  }

  getFileNameWarranty(name) {
    if (name && name.split("/")[6]) return name.split("/")[6];
  }
  amcType: any;

  getAmcText(id) {
    if (this.amcType && id) return this.amcType.find(x => x.id == id).name
  }


  userList
  getUserList(): void {
    this.apiService.startLoader()
    this.apiService.startLoader()
    this.apiService.getCall('users/user-list/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.userList = result['data'];
      // console.log("List of Users", this.userList);

    })

  }

  getUserName(id) {
    let idx = this.userList.findIndex(x => x.id == id)

    if (idx != -1) return this.userList[idx].fullName
    else return ''
  }
  showLoader = true
  updateData() {
    this.ngOnInit()
  }

  getStatus(id) {

    if (id) {
      let idx = this.statusList.findIndex(x => x.id == id)
      if (idx != -1) return this.statusList[idx].name
      else return "Lost"

    }
  }





  // upload() {


  //   let testData: FormData = new FormData();
  //   testData.append("file", this.uploadedFiles)
  //   this.apiService.startLoader()
  //   this.apiService.uploadCall('amcs/edit/' + this.asseAmcDetails.id, {}, testData).subscribe((result: any[]) => {
  //     // console.log(result);
  //     this.apiService.stopLoader()

  //     this.message = result["msg"];
  //     this.uploadedFiles = undefined
  //     this.fileName = undefined

  //     // (<HTMLInputElement>document.getElementById('openSuccess')).click();

  //   })
  // }

  //uploadedFilesAsset

  // fileChangeAsset(event) {
  //   // console.log(event)
  //   let fileList: FileList = event.target.files;
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     // console.log(file)
  //     this.uploadedFilesAsset = file



  //   }

  //   let testData: FormData = new FormData();


  //   // // console.log(obj)
  //   testData.append('image', this.uploadedFilesAsset);
  //   testData.append("assetName", this.assetDetails.assetName);
  //   testData.append("make", this.assetDetails.make);
  //   testData.append("specRating", this.assetDetails.specRating);
  //   testData.append("srNo", this.assetDetails.srNo);
  //   testData.append("locationId", this.assetDetails.locationId);
  //   testData.append("purchaseDate", this.assetDetails.purchaseDate);
  //   testData.append("placeInService", this.assetDetails.placeInService);
  //   testData.append("propertyId", this.assetDetails.propertyId);
  //   testData.append("businessId", this.assetDetails.businessId);
  //   testData.append("model", this.assetDetails.model);
  //   testData.append("specification", this.assetDetails.specification);
  //   testData.append("isOperational", this.assetDetails.isOperational);
  //   testData.append("renewalDate", this.assetDetails.renewalDate);
  //   testData.append("warrantyExpiration", this.assetDetails.warrantyExpiration);
  //   testData.append("authId", "1");
  //   testData.append("vendorName", this.assetDetails.vendorName);
  //   testData.append("categoryName", this.assetDetails.categoryName);
  //   testData.append("conditions", this.assetDetails.conditions);
  //   testData.append("slaExpiryDate", this.assetDetails.slaExpiryDate);
  //   testData.append("remark", this.assetDetails.remark);
  //   testData.append("ownership", this.assetDetails.ownership);
  //   testData.append("assetTagNo", this.assetDetails.assetTagNo);
  //   testData.append("status", this.assetDetails.status);
  //   // status:this.assetDetailsForm.value.status,



  //   // // console.log(obj)
  //   this.apiService.startLoader()
  //   this.apiService.postCall('asset-managements/edit/' + this.assetDetails.assetId, {}, testData).subscribe((result: any[]) => {
  //     // console.log(result);
  //     this.apiService.stopLoader()
  //     this.getAsset()
  //     document.getElementById('dismissPopUp').click()

  //   })
  // }

  fileChangeWarranty(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name


    }
  }

  openCropperEvent=false;
  openCropper(){
  
    // console.log("assd");
    (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
    this.openCropperEvent= true;
  }
  
  openCropperWithImage(){
    (<HTMLInputElement>document.getElementById('cropeerPopupSuccessWithImage')).click();
  }

  editImage(){
    // console.log("assd");
    (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
    this.openCropperEvent= true;
  }
}

