import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inspection-checklist-create',
  templateUrl: './inspection-checklist-create.component.html',
  styleUrls: ['./inspection-checklist-create.component.css']
})
export class InspectionChecklistCreateComponent implements OnInit {
  propertyList: any = [];
  departmentList: any = [];

  constructor(public router: Router, public apiService: ApiService, public _location: Location,) { }
  checklistId
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.getPropertyList()
    this.getSectionList()
    this.getMultichoiceresponcetypeLis()

    for (let i = 1; i <= 20; i++)this.scoreList.push(i)
  }


  disableSection(d) {
    let idx = this.sections.findIndex(x => x.sectionName == d.id)
    if (idx != -1) return true
    else return false
  }

  getSectionName(id) {
    return this.sectionsList.find(x => x.id == id).name
  }

  sectionsList
  getSectionList() {
    this.apiService.getCall('inspections/categoryLists/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }
      this.sectionsList = jsonToBeUsed
      // this.apiService.stopLoader()
    })
  }

  selectReponceSectionIDId
  selectReponceTaskIDId
  scoreList = []
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }
  inspectionDetails
  notifyChecklist(){
    alert('inn');
  }
  getChecklistData() {
    // this.apiService.startLoader()
    this.apiService.getCall('inspections/view/' + this.checklistId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      // console.log(result);
      this.inspectionDetails = result['data'];
      this.cheklistName = this.inspectionDetails.name;
      this.property = this.propertyList.find(x => x.id == this.inspectionDetails.outletId)
      if (this.inspectionDetails.departmentId) this.department = this.departmentList.find(x => x.id == this.inspectionDetails.departmentId)

      if (this.inspectionDetails.section[0]) {
        this.sections = []
        for (let d of this.inspectionDetails.section) {
          let task = []
          for (let d2 of d.item) {
            let myArray
            if (d2.statusName) {
              myArray = d2.statusName.split('/')
              // // console.log(myArray)
            }
            task.push({ id: d2.id, parking: d2.name, part: null, isDescription: d2.description ? true : false, description: d2.description, responseType: parseInt(d2.isSignature), response: d2.isSignature == "1" ? 'Digital Signature' : d2.isSignature == "3" ? 'Text Field' : d2.isSignature == "4" ? 'Numeric' : d2.isSignature == "5" ? 'Image' : d2.isSignature == "6" ? 'Date' : d2.statusName, responseSeelcted: 'Mutiple Choice', responceGroup: d2.statusId, searchResponceType: null, buttons: myArray })
          }
          // console.log(task)
          // console.log(d.sectionId)
          let idx = this.sectionsList.findIndex(x => x.id == d.sectionId)
          //(idx!=-1)?this.sectionsList[idx]:
          this.sections.push({ sectionId: d.sectionId, sectionName: d.sectionName, task: task, addCuston: (idx != -1) ? false : true })


          // console.log(this.sections)
        }
      }

      // // console.log()
      // if(!this.sections[0])this.sections=[{sectionName: null , task :[{parking : null , part:null , isDescription:false , description:null ,responseType: null, response: null,responseSeelcted:null, responceGroup : null,searchResponceType:null }]}]


    })
  }
  // responseType: null, response: null, responceGroup : null

  tabSelected = "tab1"

  cheklistName;
  department = null;
  property = null;
  sections = [{ sectionId: null, sectionName: null, addCuston: true, task: [{ id: null, parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null }] }]
  getPropertyList() {
    this.apiService.startLoader()
    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      // "outletId":this.assetDetails.propertyId?this.assetDetails.propertyId : "",
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.apiService.stopLoader()


      this.propertyList = result['data']
      this.departmentList = result['departments']
      this.checklistId = this.router.url.split("/")[2];
      if (this.checklistId) this.getChecklistData()

      // // let idx = this.propertyList.findIndex(x=> x.id == this.apiService.outletId)
      // // if(idx != -1){
      // // if(this.apiService.roleId == '3')  {

      // //    this.property = this.propertyList[idx] 
      // //    // this.getPropertyList()

      // //  }
      // //  else{

      // //    this.property = this.propertyList[idx] 
      // //  }
      // }
    })
  }

  backClicked() {
    this._location.back()
  }

  addBNewSection() {
    this.sections.push({ sectionId: undefined, sectionName: null, addCuston: true, task: [{ id: undefined, parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null }] })
  }

  addNewTask(i) {
    this.sections[i].task.push({ id: undefined, parking: null, part: null, isDescription: false, description: null, responseType: null, response: null, responseSeelcted: null, responceGroup: null, searchResponceType: null })
  }

  removedItem = []
  removedSection = []
  qI = 0
  qJ = 0
  removeTask(i, j) {
    this.qI = i;
    this.qJ = j;
    (<HTMLInputElement>document.getElementById('openSuccess1111Tas')).click();
  }

  removeTaskConfimation(flag) {
    if (flag == 1) {
      if (this.sections[this.qI].task[this.qJ].id) this.removedItem.push(this.sections[this.qI].task[this.qJ].id)
      this.sections[this.qI].task.splice(this.qJ, 1)
    }
  }

  removeSection(i) {
    this.qI = i;
    (<HTMLInputElement>document.getElementById('openSuccess1111Sec')).click();
  }
  removeSectionConfir(flag) {
    if (flag == 1) {
      if (this.sections[this.qI].sectionId) this.removedSection.push(this.sections[this.qI].sectionId)

      this.sections.splice(this.qI, 1)
    }
  }

  responceType
  responcetypePopup
  openResponceTypePopup(name) {


    if (this.responcetypePopup == name) {
      this.responceType = null;
      this.responcetypePopup = null;
    }
    else this.responcetypePopup = name
    // console.log(this.responcetypePopup, name)
  }
  getFreqtext(d) {
    // // console.log(d)
    if (d) {
      if (d.scheduleFrequency != "Custom") return d.scheduleFrequency
      else return d.frequencyNo + " " + d.frequencyUnit
    }
  }

  selectUnit: boolean;
  selectFreq: boolean;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id != 'custom') {
      this.selectUnit = false
      this.selectFreq = false
      this.responceType = null
      this.responcetypePopup = null
    }
    // console.log(this.responcetypePopup, )
  }

  openResponceType(name) {
    // console.log(this.responceType, name)
    if (this.responceType == name) this.responceType = null
    else this.responceType = name
    // var modal = document.getElementById(name);

    // if(modal.style.display == 'none')modal.style.display = "block";
    // else modal.style.display = "none";
  }
  selectResponceTypr(i, j, data) {
    // console.log(i,j,data)
    // responseType: 0,
    //   response: data.name,
    //   responceGroup : data.id
    this.sections[i].task[j].response = data.name
    this.sections[i].task[j].responseType = 2
    this.sections[i].task[j].responceGroup = data.id

    let myArray = this.sections[i].task[j].response.split('/')
    // console.log(myArray)
    this.sections[i].task[j]['buttons'] = myArray
    // // console.log(this.pmTemplateForm.value)
  }
  selectResponse(data, i, j) {
    // console.log("Data :",data);
    this.sections[i].task[j].response = data
    this.sections[i].task[j].responseType = data == 'Text Field' ? 3 : data == 'Numeric' ? 4 : data == 'Image' ? 5 : data == 'Date' ? 6 : 1,
      this.sections[i].task[j].responceGroup = null

  }

  statusListresponceType = []
  statusListresponceTypeFilter = []

  onItemSelect(item: any) {
    // console.log(item);

  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  getMultichoiceresponcetypeLis() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      let idx = jsonToBeUsed.findIndex(x => x.name == "Add a Response")
      jsonToBeUsed.splice(idx, 1)

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;

    })

    this.apiService.getCall('inspections/groupAllStatus/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

      this.imagesArray = result['data']
      this.imagePath = result['path']

      // console.log(this.imagesArray)
      this.imagesArray.map(x => x.selected = false)
    })


    this.apiService.getCall('inspections/groupAllStatusImages', {}).subscribe((result: any[]) => {

      this.allImageArray = result['data']
      this.allImageArray.map(x => x.selected = false)
      this.allImagePath = result['path']

      // console.log(this.imagesArray)
      // this.imagesArray.map(x => x.selected = false)
    })
  }

  allImageArray = []
  allImagePath: any;
  imagesArray = []
  imagePath: any;
  searchResponceType: any;

  filterLocation(ev, i, j) {
    // console.log(ev)

    this.statusListresponceType = this.statusListresponceTypeFilter.filter((item) => {
      if (item.name != null) {
        return item.name.toLowerCase().includes(this.sections[i].task[j].searchResponceType.toLowerCase())
      }
    }
    )

  }

  selelctedImageNew(ev, d) {
    this.allImageArray.map(x => x.selected = false)

    if (ev.target.checked) {
      this.allImageArray.find(x => x.id == d.id).selected = true
      this.selectedNewStaus.imageId = d.id
    }
  }
  newGroupName: any;
  newGroupIcon: any = [];

  addnewStatus() {
    // console.log(this.imagesArray, this.newGroupName)
    let statusImages = []
    for (let d of this.imagesArray) if (d.selected) statusImages.push(d.id)
    let obj = {
      name: this.newGroupName,
      businessId: this.apiService.buisnessId,
      status: statusImages

    }
    this.apiService.startLoader()
    this.apiService.postCall('inspections/addNewGroup', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      if (result['success']) {

        this.message = result["msg"];


        (<HTMLInputElement>document.getElementById('openSuccess1111')).click();
        this.getMultichoiceresponcetypeListLast()
      }
    })

  }

  getMultichoiceresponcetypeListLast() {
    this.apiService.getCall('inspections/statusesLists', {}).subscribe((result: any[]) => {
      // console.log(result)
      let jsonToBeUsed = []
      for (var type in result['data']) {
        let item = {};
        item['id'] = type;
        item['name'] = result['data'][type];
        jsonToBeUsed.push(item);


      }

      let idx = jsonToBeUsed.findIndex(x => x.name == "Add a Response")
      jsonToBeUsed.splice(idx, 1)

      this.statusListresponceType = jsonToBeUsed
      this.statusListresponceType.slice(0, 1)
      this.statusListresponceTypeFilter = this.statusListresponceType;
      // console.log(this.statusListresponceType.length)
      this.selectResponceTypr(this.selectReponceSectionIDId, this.selectReponceTaskIDId, this.statusListresponceType[this.statusListresponceType.length - 1])

    })
  }

  selectedNewStaus = { name: null, imageId: null, score: null, alert: null }

  addnewStatusGroup() {
    let obj = {
      "name": this.selectedNewStaus.name,
      "value": this.selectedNewStaus.score,
      "statusImageId": this.selectedNewStaus.imageId,
      "isAlert": parseInt(this.selectedNewStaus.alert),
      "businessId": this.apiService.buisnessId,

    }

    // console.log(obj)

    this.apiService.startLoader()
    this.apiService.postCall('inspections/addNewStatus', {}, obj).subscribe((result: any[]) => {
      this.apiService.stopLoader()

      this.message = result["msg"];


      (<HTMLInputElement>document.getElementById('dismisssNewStatusPopup')).click();
      this.apiService.getCall('inspections/groupAllStatus/' + this.apiService.buisnessId, {}).subscribe((result: any[]) => {

        this.imagesArray = result['data']
        this.imagePath = result['path']

        // console.log(this.imagesArray)
        this.imagesArray.map(x => x.selected = false)
      })
    })

  }


  message
  saveChecklist() {
    if (!this.checklistId) {
       //console.log(this.sections)
      let sectionObj = []
      for (let d of this.sections) {
        let task = []

        for (let d2 of d.task) task.push({ itemId: d2.id ? d2.id : undefined, itemName: d2.parking, description: d2.description, statusId: d2.responceGroup, isSignature: d2.response == 'Digital Signature' ? 1 : d2.response == 'Text Field' ? 3 : d2.response == 'Numeric' ? 4 : d2.response == 'Image' ? 5 : d2.response == 'Date' ? 6 : 2 })

        // console.log(d)
        sectionObj.push({ sectionId: d.sectionId ? d.sectionId : (d.addCuston) ? undefined : d.sectionName.id, sectionName: d.addCuston ? d.sectionName : d.sectionName.name, item: task })
      }
      let body = {
        checklistName: this.cheklistName,
        propertyId: this.property.id,
        businessId: this.apiService.buisnessId,
        departmentId: this.department.id,
        section: sectionObj,

      }

      this.apiService.startLoader()
      this.apiService.postCall('inspections/add', {}, body).subscribe((result: any[]) => {
        // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
        // console.log(result);

        // this.setPaginationArray(result)
        this.apiService.stopLoader()

        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      )
    }
    else {
      // console.log(this.sections)
      let sectionObj = []
      for (let d of this.sections) {
        let task = []

        for (let d2 of d.task) task.push({ id: d2.id ? d2.id : undefined, itemName: d2.parking, description: d2.description, statusId: d2.responceGroup, isSignature: d2.response == 'Digital Signature' ? 1 : d2.response == 'Text Field' ? 3 : d2.response == 'Numeric' ? 4 : d2.response == 'Image' ? 5 : d2.response == 'Date' ? 6 : 2 })

        sectionObj.push({ sectionName: d.sectionName, sectionId: d.sectionId ? d.sectionId : (d.addCuston) ? undefined : d.sectionName.id, item: task })
      }
      let body = {
        checklistName: this.cheklistName,
        propertyId: this.property.id,
        businessId: this.apiService.buisnessId,
        departmentId: this.department.id,
        section: sectionObj,
        removeItem: this.removedItem,
        removeSection: this.removedSection,

      }

      this.apiService.startLoader()
      this.apiService.postCall('inspections/edit/' + this.checklistId, {}, body).subscribe((result: any[]) => {
        // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
        // console.log(result);

        // this.setPaginationArray(result)
        this.apiService.stopLoader()

        this.message = result["msg"];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      }
      )

      // // console.log(obj)
    }
  }

  getValid() {

    let idx1 = JSON.stringify(this.sections).search('"sectionName":null')
    let idx2 = JSON.stringify(this.sections).search('"sectionName":""')
    let idx3 = JSON.stringify(this.sections).search('"parking":null')
    let idx4 = JSON.stringify(this.sections).search('"response":null')
    let idx5 = JSON.stringify(this.sections).search('"parking":""')
    let idx6 = JSON.stringify(this.sections).search('"response":""')



    // console.log(idx1 , idx2,idx3)
    // "parking":null,
    return this.cheklistName && this.property && idx1 == -1 && idx2 == -1 && idx3 == -1 && idx4 == -1 && idx5 == -1 && idx6 == -1
    //return (!this.cheklistName || !this.department || !this.property) || (idx1 != -1 || idx2 != -1 || idx3 != -1  )
  }
}

