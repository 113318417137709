import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, FormBuilder, Validators} from '@angular/forms';
import * as moment from 'moment';

import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ApiService} from '../../../../common/services/apiCalls.service';

@Component({
  selector: 'app-permit-sub-question-add',
  templateUrl: './permit-sub-question-add.component.html',
  styleUrls: ['./permit-sub-question-add.component.css']
})
export class PermitSubQuestionAddComponent implements OnInit {
  shide = false;
  hide = false;
  tenantForm: FormGroup;
  message;
  dropdownSettings?: IDropdownSettings;
  userDropdownSettings?: IDropdownSettings;
  userList = [];
  businessId = 0;
  propertyList = [];
  routeId;

  constructor(
    public apiService: ApiService,
    public _location: Location,
    public router: Router,
    public fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      // console.log(params); //log the entire params object
      this.routeId = (params['id']); //log the value of id
    });


  }

  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.tenantForm = this.fb.group({
      businessId: [null],
      outletId: [null],
      type: [null],
      name: [null, Validators.required],
      authId: [null],
      underTaking: [null, Validators.required],
      level1Users: [null, Validators.required],
      level2Users: [null, Validators.required],
      level3Users: [null, Validators.required],
      level4Users: [null, Validators.required],
    });
    this.userDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'outletName',
      allowSearchFilter: true
    };

    this.getPropertyList();
    // if (this.apiService.outletId) {
    //   this.onItemSelect({id: this.apiService.outletId});
    // }
    if (this.routeId) {
      this.apiService.postCall('permits/view/' + this.routeId, {}, {}).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader();
        this.tenantForm.patchValue(result['data']);
      });
    }
  }

  goBack(): void {
    this._location.back();
  }


  save(): void {
    this.businessId = 0;
    if (this.apiService.roleId == 1) {
      this.businessId = this.tenantForm.value.businessId ? this.tenantForm.value.businessId : 0;
    } else if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      this.businessId = this.apiService.buisnessId;
    }
    this.tenantForm.value.businessId = this.businessId;
    this.tenantForm.value.authId = this.apiService.authId;
    this.tenantForm.value.type = this.apiService.userType;
    for (let i = 0; i < this.tenantForm.value.level1Users.length; i++) {
      this.tenantForm.value.level1Users[i] = this.tenantForm.value.level1Users[i].id;
    }
    for (let i = 0; i < this.tenantForm.value.level2Users.length; i++) {
      this.tenantForm.value.level2Users[i] = this.tenantForm.value.level2Users[i].id;
    }
    for (let i = 0; i < this.tenantForm.value.level3Users.length; i++) {
      this.tenantForm.value.level3Users[i] = this.tenantForm.value.level3Users[i].id;
    }
    for (let i = 0; i < this.tenantForm.value.level4Users.length; i++) {
      this.tenantForm.value.level4Users[i] = this.tenantForm.value.level4Users[i].id;
    }
    // this.tenantForm.value.outletId = this.apiService.outletId;
    let formData = this.tenantForm.value;
    this.apiService.startLoader();
    this.apiService.postCall('permits/add', {}, formData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();
      // this.saveData = result['success'];
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });

  }

  getValidation() {
    return this.tenantForm.valid;
  }

  getPropertyList() {
    this.apiService.startLoader();
    this.apiService.postCall('outlets/outlet-list/' + this.businessId, {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.authId,
        'businessId': this.businessId
      }
    ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();

      this.propertyList = result['data'];
      // this.groups = result['groups']

      // console.log(this.propertyList);
    });
  }

  onItemSelect(item: any) {

    this.apiService.startLoader();
    this.apiService.postCall('users/user-list/' + this.businessId + '/' + item.id, {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.authId,
        'businessId': this.businessId,
        'outletId': item.id,
        'onlyUsers': 1
      }
    ).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader();

      this.userList = result['data'];
      // this.groups = result['groups']

      // console.log(this.userList);
    });
  }

  backClicked() {
    this.router.navigate(['/permit-setting/questions-list']);
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
