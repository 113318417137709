import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StoredData } from '../common/services/storedData.service';
import { Router } from '@angular/router';
import { identifierModuleUrl, NONE_TYPE } from '@angular/compiler';

@Component({
  selector: 'app-property-index',
  templateUrl: './property-index.component.html',
  styleUrls: ['./property-index.component.css']
})
export class PropertyIndexComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public apiService: ApiService,
    public root: Router,
    public router: Router,
    public storedData: StoredData
  ) { }

  tabSelected = 'active';
  selectedProperty = [];
  selectedPropertyList = [];
  propertyList: any;
  activePage: any = 1;
  pageSize = 10;
  selectedFiler = { businessId: null, outletName: null, property: null, status: null, zone: null, state: null, cluster: null, district: null, city: null, site: null, client: null }
  paginationArray = [];
  statusSelection: any;
  applyFlag = false;
  sortType = 'desc';
  sortBy = 'id';
  filterSelect = false;
  propertyFiletr = false;
  statusList = [
    { id: '0', name: 'Deactive' },
    { id: '1', name: 'Active' }
  ]

  dropdownShow = false

  addPermission = false
  addBulkPermission = false
  editPermission = false
  changeStatusPermission = false
  printBarcodePermission = false
  filterpropertyList = []
  setTabSelection() {
    //localStorage.setItem("tabselection", JSON.stringify({ propertyListTab: this.tabSelected, ticketList: 'openTicket', assetList: 'allasset', partsList: 'allparts', maintananceList: 'today' }))
  }
  ngOnInit(): void {
    // if (localStorage.getItem("tabselection")) {
    //   this.tabSelected = JSON.parse(localStorage.getItem("tabselection")).propertyListTab
    // }else{
    //   this.tabSelected = 'active';
    // }
    if(this.router.url.split("/")[2]){
      this.tabSelected = this.router.url.split("/")[2];
      this.clearFilter();
      this.setTabSelection();
      this.getPropertys();
    }else{
      this.getPropertys();
    }
    if (this.apiService.roleId == 1) {
      this.getBusinessList();
    }

    this.getOutletListFor();
    this.getZoneList();
    this.getSiteTypes();
    this.getClientList();

    var propertyIndexPagination = localStorage.getItem('propertyIndexPagination');
    if (propertyIndexPagination) {
      this.activePage = parseInt(propertyIndexPagination);
    }

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] == 9);
    // console.log(permission);
    for (var i = 0; i < permission.length; i++) {
      if (permission[i]['subModuleName'] == "Properties" && permission[i]['action'] == "Add" && permission[i]['actionStatus'] == 1) {
        this.addPermission = true
      }
      if (permission[i]['subModuleName'] == "Properties" && permission[i]['action'] == "Bulk Add" && permission[i]['actionStatus'] == 1) {
        this.addBulkPermission = true
      }
      if (permission[i]['subModuleName'] == "Properties" && permission[i]['action'] == "Edit" && permission[i]['actionStatus'] == 1) {
        this.editPermission = true
      }
      if (permission[i]['subModuleName'] == "Properties" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1) {
        this.changeStatusPermission = true
      }
      if (permission[i]['subModuleName'] == "Properties" && permission[i]['action'] == "Print QR Code" && permission[i]['actionStatus'] == 1) {
        this.printBarcodePermission = true
      }
    }

  }
  navigateTo(s) {
    this.root.navigate([s])
  }
  bussnissList = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
    })
  }

  pagination(d) {
    localStorage.removeItem('localPropertyFilter');
    localStorage.setItem("localPropertyFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      localStorage.setItem('propertyIndexPagination', d);
      // console.log("Active Page :", d)
      this.getPropertys()
    }
  }

  goBack() {
    this.root.navigate(['setup'])
  }
  totalnoOfPages;
  setPaginationArray(result) {
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    } else if (4 + this.activePage >= 6) {
      let start = (4 + this.activePage) - noOfPAges
      // console.log(start)
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }


  selectStatus(d) {
    if ('status' + d.id != this.statusSelection) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined
    // console.log(this.statusSelection)
  }

  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.zone != null) length = length + 1
    if (this.selectedFiler.state != null) length = length + 1
    if (this.selectedFiler.cluster != null) length = length + 1
    if (this.selectedFiler.district != null) length = length + 1
    if (this.selectedFiler.city != null) length = length + 1
    if (this.selectedFiler.site != null) length = length + 1
    if (this.selectedFiler.client != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.outletName != null) length = length + 1
    if (this.selectedFiler.status != null) length = length + 1

    if (length == 0 || !this.applyFlag) return "(No Filter Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
    // console.log(JSON.stringify(this.selectedFiler))
  }

  clearFilter() {
    localStorage.removeItem('localPropertyFilter');
    localStorage.setItem("localPropertyFilter", null);
    this.activePage = 1
    this.jumpToPage = 1
    this.selectedFiler = { businessId: null, outletName: null, property: null, status: null, zone: null, state: null, cluster: null, district: null, city: null, site: null, client: null }
    this.filterSelect = false;
    this.getPropertys();
  }

  applyFilter() {
    localStorage.removeItem('localPropertyFilter');
    localStorage.setItem("localPropertyFilter", null);
    this.applyFlag = true;
    this.activePage = 1
    this.getPropertys();
  }

  sortData(data) {
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'
    this.getPropertys();
  }

  openQR(outletName, qRCode) {
    this.storedData.qrData = { name: outletName, image: qRCode, customerComplaintName: "" }
    this.root.navigate(['print-qr'])
  }
  openQRPermit(outletName, permitImageQRCodeUrl) {
    this.storedData.qrData = { name: outletName, image: permitImageQRCodeUrl, customerComplaintName: "" }
    this.root.navigate(['print-qr-permit'])
  }
  openQREmp(outletName, qRCode, qRCodeEmpVoice, qRCodeEmpVoiceWhistleblowerPolicy) {
    this.storedData.qrDataEmp = { name: outletName, image: qRCode, qRCodeEmpVoice: qRCodeEmpVoice, qRCodeEmpVoiceWhistleblowerPolicy: qRCodeEmpVoiceWhistleblowerPolicy }
    this.root.navigate(['qr-code'])
  }

  changePage() {
    localStorage.removeItem('localPropertyFilter');
    localStorage.setItem("localPropertyFilter", null);
    this.getPropertys()
  }
  jumpToPage
  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)
    return array
  }

  propertyList1 = []
  getOutletListFor() {
    let body = {
      userId: this.apiService.authId,
      businessId: this.apiService.buisnessId,
      roleId: this.apiService.role
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/outletList', {}, body).subscribe((result: any[]) => {
      // console.log("Property :", result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.propertyList1 = result['data'];
        this.filterpropertyList = result['data'];
      }
    })
  }
  filterPropertySearch
  filterProperty(ev) {
    // console.log(ev)
    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    })
  }
  outletAddLimit = false
  exeedMsg = ""
  getPropertys(): void {
    let businessId = 0;
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.businessId ? this.selectedFiler.businessId.id : 0
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId;
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId;
    }
    let isActive;
    if (this.tabSelected == 'active') {
      isActive = 1;
    } else {
      isActive = 0;
    }
    let body = {}
    if (localStorage.getItem("localPropertyFilter") && localStorage.getItem("localPropertyFilter") != null && localStorage.getItem("localPropertyFilter") != 'null') {
      let localPropertyFilter = JSON.parse(localStorage.getItem("localPropertyFilter"));
      body = localPropertyFilter;
      if (localPropertyFilter.id && localPropertyFilter.id != null && localPropertyFilter.id != 'null') {
        this.selectedFiler.property = { id: localPropertyFilter.id, outletName: localPropertyFilter.frontOutletNameFilter }
      }
      if (localPropertyFilter.zoneId && localPropertyFilter.zoneId != null && localPropertyFilter.zoneId != 'null') {
        this.selectedFiler.zone = { id: localPropertyFilter.zoneId, name: localPropertyFilter.frontRequestZoneNameFilter }
      }
      if (localPropertyFilter.stateId && localPropertyFilter.stateId != null && localPropertyFilter.stateId != 'null') {
        this.selectedFiler.state = { id: localPropertyFilter.stateId, name: localPropertyFilter.frontRequestStateNameFilter }
      }
      if (localPropertyFilter.clusterId && localPropertyFilter.clusterId != null && localPropertyFilter.clusterId != 'null') {
        this.selectedFiler.cluster = { id: localPropertyFilter.clusterId, name: localPropertyFilter.frontRequestClusterNameFilter }
      }
      if (localPropertyFilter.districtId && localPropertyFilter.districtId != null && localPropertyFilter.districtId != 'null') {
        this.selectedFiler.district = { id: localPropertyFilter.districtId, name: localPropertyFilter.frontRequestDistrictNameFilter }
      }
      if (localPropertyFilter.citieId && localPropertyFilter.citieId != null && localPropertyFilter.citieId != 'null') {
        this.selectedFiler.city = { id: localPropertyFilter.citieId, name: localPropertyFilter.frontRequestCityNameFilter }
      }
      if (localPropertyFilter.clientId && localPropertyFilter.clientId != null && localPropertyFilter.clientId != 'null') {
        this.selectedFiler.client = { id: localPropertyFilter.clientId, name: localPropertyFilter.frontRequestClientNameFilter }
      }
      if (localPropertyFilter.siteType && localPropertyFilter.siteType != null && localPropertyFilter.siteType != 'null') {
        this.selectedFiler.site = { id: localPropertyFilter.siteType, name: localPropertyFilter.frontRequestSiteTypeNameFilter }
      }
      this.selectedFiler.outletName = localPropertyFilter.outletName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletName: this.selectedFiler.outletName,
        //outletCode: this.selectedFiler.code,
        isActive: isActive,
        // businessId: businessId,
        authId: this.apiService.authId,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "zoneId": this.selectedFiler.zone ? this.selectedFiler.zone.id : '',
        "stateId": this.selectedFiler.state ? this.selectedFiler.state.id : '',
        "clusterId": this.selectedFiler.cluster ? this.selectedFiler.cluster.id : '',
        "districtId": this.selectedFiler.district ? this.selectedFiler.district.id : '',
        "citieId": this.selectedFiler.city ? this.selectedFiler.city.id : '',
        "clientId": this.selectedFiler.client ? this.selectedFiler.client.id : '',
        "siteType": this.selectedFiler.site ? this.selectedFiler.site.id : '',
        "id": this.selectedFiler.property ? this.selectedFiler.property.id : '',
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRequestZoneNameFilter: this.selectedFiler.zone ? this.selectedFiler.zone.name : "",
        frontRequestStateNameFilter: this.selectedFiler.state ? this.selectedFiler.state.name : "",
        frontRequestClusterNameFilter: this.selectedFiler.cluster ? this.selectedFiler.cluster.name : "",
        frontRequestDistrictNameFilter: this.selectedFiler.district ? this.selectedFiler.district.name : "",
        frontRequestCityNameFilter: this.selectedFiler.city ? this.selectedFiler.city.name : "",
        frontRequestClientNameFilter: this.selectedFiler.client ? this.selectedFiler.client.name : "",
        frontRequestSiteTypeNameFilter: this.selectedFiler.site ? this.selectedFiler.site.name : "",
      }
      localStorage.setItem('localPropertyFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/index', {}, body).subscribe((result: any[]) => {
      // console.log("Property :", result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.outletAddLimit = result['outletAddLimit']
        if (this.outletAddLimit == false) {
          this.exeedMsg = result['exeedMsg'];
        }
        this.propertyList = result['data'];
        this.setPaginationArray(result)
      }
    })
  }
  propertyListDeactive = []
  getPropertysDeactive(): void {
    let businessId = 0;
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.businessId ? this.selectedFiler.businessId.id : 0
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId;
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId;
    }

    let body = {}
    if (localStorage.getItem("localPropertyFilter") && localStorage.getItem("localPropertyFilter") != null && localStorage.getItem("localPropertyFilter") != 'null') {
      let localPropertyFilter = JSON.parse(localStorage.getItem("localPropertyFilter"));
      body = localPropertyFilter;
      if (localPropertyFilter.id && localPropertyFilter.id != null && localPropertyFilter.id != 'null') {
        this.selectedFiler.property = { id: localPropertyFilter.id, outletName: localPropertyFilter.frontOutletNameFilter }
      }
      if (localPropertyFilter.zoneId && localPropertyFilter.zoneId != null && localPropertyFilter.zoneId != 'null') {
        this.selectedFiler.zone = { id: localPropertyFilter.zoneId, name: localPropertyFilter.frontRequestZoneNameFilter }
      }
      if (localPropertyFilter.stateId && localPropertyFilter.stateId != null && localPropertyFilter.stateId != 'null') {
        this.selectedFiler.state = { id: localPropertyFilter.stateId, name: localPropertyFilter.frontRequestStateNameFilter }
      }
      if (localPropertyFilter.clusterId && localPropertyFilter.clusterId != null && localPropertyFilter.clusterId != 'null') {
        this.selectedFiler.cluster = { id: localPropertyFilter.clusterId, name: localPropertyFilter.frontRequestClusterNameFilter }
      }
      if (localPropertyFilter.districtId && localPropertyFilter.districtId != null && localPropertyFilter.districtId != 'null') {
        this.selectedFiler.district = { id: localPropertyFilter.districtId, name: localPropertyFilter.frontRequestDistrictNameFilter }
      }
      if (localPropertyFilter.citieId && localPropertyFilter.citieId != null && localPropertyFilter.citieId != 'null') {
        this.selectedFiler.city = { id: localPropertyFilter.citieId, name: localPropertyFilter.frontRequestCityNameFilter }
      }
      if (localPropertyFilter.clientId && localPropertyFilter.clientId != null && localPropertyFilter.clientId != 'null') {
        this.selectedFiler.client = { id: localPropertyFilter.clientId, name: localPropertyFilter.frontRequestClientNameFilter }
      }
      if (localPropertyFilter.siteType && localPropertyFilter.siteType != null && localPropertyFilter.siteType != 'null') {
        this.selectedFiler.site = { id: localPropertyFilter.siteType, name: localPropertyFilter.frontRequestSiteTypeNameFilter }
      }
      this.selectedFiler.outletName = localPropertyFilter.outletName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletName: this.selectedFiler.property,
        //outletCode: this.selectedFiler.code,
        isActive: 0,
        // businessId: businessId,
        authId: this.apiService.authId,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "zoneId": this.selectedFiler.zone ? this.selectedFiler.zone.id : '',
        "stateId": this.selectedFiler.state ? this.selectedFiler.state.id : '',
        "clusterId": this.selectedFiler.cluster ? this.selectedFiler.cluster.id : '',
        "districtId": this.selectedFiler.district ? this.selectedFiler.district.id : '',
        "citieId": this.selectedFiler.city ? this.selectedFiler.city.id : '',
        "clientId": this.selectedFiler.client ? this.selectedFiler.client.id : '',
        "siteType": this.selectedFiler.site ? this.selectedFiler.site.id : '',
        "id": this.selectedFiler.property ? this.selectedFiler.property.id : '',
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRequestZoneNameFilter: this.selectedFiler.zone ? this.selectedFiler.zone.name : "",
        frontRequestStateNameFilter: this.selectedFiler.state ? this.selectedFiler.state.name : "",
        frontRequestClusterNameFilter: this.selectedFiler.cluster ? this.selectedFiler.cluster.name : "",
        frontRequestDistrictNameFilter: this.selectedFiler.district ? this.selectedFiler.district.name : "",
        frontRequestCityNameFilter: this.selectedFiler.city ? this.selectedFiler.city.name : "",
        frontRequestClientNameFilter: this.selectedFiler.client ? this.selectedFiler.client.name : "",
        frontRequestSiteTypeNameFilter: this.selectedFiler.site ? this.selectedFiler.site.name : "",
      }
      localStorage.setItem('localPropertyFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/index', {}, body).subscribe((result: any[]) => {
      // console.log("Property :", result);
      this.apiService.stopLoader()
      if (result['success'] == true) {
        this.propertyListDeactive = result['data'];
        this.setPaginationArray(result)
      }
    })
  }

  deactivateProperty(data: any, status: any) {
    // console.log("is Active : ", data);
    let statusArray = [];
    let dataObj = {
      id: data.id,
      isActive: status
    }
    statusArray.push(dataObj);
    let body = {
      statusArray: statusArray,
      authId: this.apiService.authId
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/changeStatus', {}, body).subscribe((result: any[]) => {
      // console.log("Property :", result);
      this.apiService.stopLoader()
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      if (result['success'] == true) {
        this.getPropertys();
      }
    })
  }
  selectAll = false
  selectAllProperty(ev: any) {
    this.selectedProperty = []

    if (ev.target.checked) {
      this.selectAll = true
      this.propertyList.map(x => x.selected = true)
      for (let asset of this.propertyList) {
        if (asset.isActive == 1) {
          this.selectedProperty.push(asset.id)
          this.selectedPropertyList.push(asset)
        }
      }
    } else {
      this.selectAll = false
      this.propertyList.map(x => x.selected = false)
      this.selectedProperty = []
      this.selectedPropertyList = []
    }
    // console.log(this.selectedProperty)
  }

  selectProperty(ev: any, property: any) {
    // console.log(ev.target.checked, property)
    this.selectAll = false
    if (ev.target.checked) {
      this.selectedProperty.push(property.id)
      this.selectedPropertyList.push(property)
    }
    else {
      this.selectedProperty.splice(this.selectedProperty.findIndex(x => x == property.id), 1)
      this.selectedPropertyList.splice(this.selectedProperty.findIndex(x => x.assetId == property.id), 1)
    }

    // console.log(this.selectedProperty)
  }
  message
  deactivateMultipleProperty() {
    let deactivateArray = [];
    for (let d = 0; d < this.selectedProperty.length; d++) {
      let dataObj = {
        id: this.selectedProperty[d],
        isActive: 0
      }
      deactivateArray.push(dataObj);
    }
    let body = {
      statusArray: deactivateArray,
      authId: this.apiService.authId
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/changeStatus', {}, body).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      this.message = result['msg'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
      if (result['success'] == true) {
        this.getPropertys();
      }
    })
  }

  businessFilter = false
  statusFilter = false
  zoneFilter = false
  stateFilter = false
  clusterFilter = false
  districtFilter = false
  cityFilter = false
  siteFilter = false
  clientFilter = false
  propertyFilter = false

  //statusFilter = false;zoneFilter = false;stateFilter = false;clusterFilter = false;districtFilter = false;cityFilter = false;siteFilter = false;clientFilter = false

  @HostListener('click', ['$event.target'])
  onClick(btn: any) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.filterSelect = true;
    } else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.dropdownShow = false


      this.zoneFilter = false
      this.stateFilter = false
      this.clusterFilter = false
      this.districtFilter = false
      this.cityFilter = false
      this.siteFilter = false
      this.clientFilter = false
      this.propertyFilter = false

    }
    if (btn.id == 'changeBUton') {
    } else if (btn.id != 'changeBUton') {
      this.statusSelection = undefined
    }
  }
  closeAll() {
    this.propertyFilter = false;
    this.clientFilter = false
    this.statusFilter = false;
    this.zoneFilter = false;
    this.stateFilter = false;
    this.clusterFilter = false;
    this.districtFilter = false;
    this.cityFilter = false;
    this.siteFilter = false;
    this.statusFilter = false;
  }

  uploadedFiles
  fileName
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log(file)
      this.uploadedFiles = file
      this.fileName = file.name
    }
  }
  getValidation() {
    if (!this.uploadedFiles) {
      return false;
    }
    return true;
  }
  errorMessages = []
  uploadWarranty() {
    let testData: FormData = new FormData();
    if (!this.uploadedFiles) {
      return;
    }
    // // console.log(obj)
    testData.append('file', this.uploadedFiles);
    testData.append('authId', this.apiService.authId);
    testData.append('businessId', this.apiService.buisnessId);
    this.apiService.startLoader()
    this.apiService.postCall('outlets/bulkupload', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
        this.getPropertys();
      } else {
        this.message = result['msg'];
        this.errorMessages = result['data'];
        (<HTMLInputElement>document.getElementById('fileUploadopenSuccess')).click();
      }
      this.uploadedFiles = null
      this.fileName = null
    });
  }






  getZoneList() {
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId,
    };
    this.zoneList = []
    this.apiService.postCall('zones/getZonesPluck', {}, body).subscribe((result: any[]) => {
      if (result['success']) {
        this.zoneList = result['data'];
      }
      this.apiService.stopLoader();

    });
  }
  getStateList() {
    this.stateList = []
    this.clusterList = []
    this.districtList = []
    this.cityList = []

    if (this.selectedFiler.zone?.id > 0) {
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.selectedFiler.zone.id,
      };
      this.apiService.postCall('states/getStatesPluck', {}, body).subscribe((result: any[]) => {
        // console.log(result);
        if (result['success']) {
          this.stateList = result['data'];
        }
        if (this.selectedFiler.zone?.id > 0 && this.selectedFiler.state?.id > 0) {
          this.getclusterList();
        }
        this.apiService.stopLoader();

      });
    }
  }

  stateList = []
  clusterList = []
  zoneList = []
  districtList = []


  getclusterList() {
    this.clusterList = []
    this.districtList = []
    this.cityList = []
    if (this.selectedFiler.zone?.id > 0 && this.selectedFiler.state?.id > 0) {
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.selectedFiler.zone?.id,
        stateId: this.selectedFiler.state?.id
      };
      this.apiService.postCall('clusters/getClustersPluck', {}, body).subscribe((result: any[]) => {
        if (result['success']) {
          this.clusterList = result['data'];
        }
        this.apiService.stopLoader();
      });
    }
  }

  getDistrictList() {
    this.districtList = []
    this.cityList = []
    if (this.selectedFiler.zone?.id > 0 && this.selectedFiler.cluster?.id > 0 && this.selectedFiler.state?.id > 0) {
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.selectedFiler.zone?.id,
        stateId: this.selectedFiler.state?.id,
        clusterId: this.selectedFiler.cluster?.id
      };
      this.apiService.postCall('districts/getDistrictsPluck', {}, body).subscribe((result: any[]) => {
        if (result['success']) {
          this.districtList = result['data'];
        }
        this.apiService.stopLoader();
      });
    }
  }

  cityList = []
  getCityList() {
    if (this.selectedFiler.zone?.id > 0 && this.selectedFiler.cluster?.id > 0 && this.selectedFiler.state?.id > 0 && this.selectedFiler.district?.id > 0) {
      this.apiService.startLoader();
      let body = {
        'roleId': this.apiService.roleId,
        'userId': this.apiService.userId,
        'businessId': this.apiService.buisnessId,
        zoneId: this.selectedFiler.zone?.id,
        stateId: this.selectedFiler.state?.id,
        clusterId: this.selectedFiler.cluster?.id,
        districtId: this.selectedFiler.district?.id
      };
      this.apiService.postCall('cities/getCitiesPluck', {}, body).subscribe((result: any[]) => {
        if (result['success']) {
          this.cityList = result['data'];
        }
        this.apiService.stopLoader();
      });
    }
  }

  clientList = []
  getClientList() {
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId
    };
    this.apiService.postCall('clients/getClientsPluck', {}, body).subscribe((result: any[]) => {
      if (result['success']) {
        this.clientList = result['data'];
      }
      this.apiService.stopLoader();
    });
  }

  siteList = []
  getSiteTypes() {
    this.apiService.startLoader();
    let body = {
      'roleId': this.apiService.roleId,
      'userId': this.apiService.userId,
      'businessId': this.apiService.buisnessId
    };
    this.apiService.postCall('siteTypes/getSiteTypesPluck', {}, body).subscribe((result: any[]) => {
      if (result['success']) {
        this.siteList = result['data'];
      }
      this.apiService.stopLoader();
    });
  }



  rowCount = 0
  qrAssetList = []
  getAll() {

    let businessId = 0;
    if (this.apiService.roleId == 1) {
      businessId = this.selectedFiler.businessId ? this.selectedFiler.businessId.id : 0
    } else if (this.apiService.roleId == 2) {
      businessId = this.apiService.buisnessId;
    } else if (this.apiService.roleId == 3) {
      businessId = this.apiService.buisnessId;
    }

    let body = {}
    if (localStorage.getItem("localPropertyFilter") && localStorage.getItem("localPropertyFilter") != null && localStorage.getItem("localPropertyFilter") != 'null') {
      let localPropertyFilter = JSON.parse(localStorage.getItem("localPropertyFilter"));
      body = localPropertyFilter;
      if (localPropertyFilter.id && localPropertyFilter.id != null && localPropertyFilter.id != 'null') {
        this.selectedFiler.property = { id: localPropertyFilter.id, outletName: localPropertyFilter.frontOutletNameFilter }
      }
      if (localPropertyFilter.zoneId && localPropertyFilter.zoneId != null && localPropertyFilter.zoneId != 'null') {
        this.selectedFiler.zone = { id: localPropertyFilter.zoneId, name: localPropertyFilter.frontRequestZoneNameFilter }
      }
      if (localPropertyFilter.stateId && localPropertyFilter.stateId != null && localPropertyFilter.stateId != 'null') {
        this.selectedFiler.state = { id: localPropertyFilter.stateId, name: localPropertyFilter.frontRequestStateNameFilter }
      }
      if (localPropertyFilter.clusterId && localPropertyFilter.clusterId != null && localPropertyFilter.clusterId != 'null') {
        this.selectedFiler.cluster = { id: localPropertyFilter.clusterId, name: localPropertyFilter.frontRequestClusterNameFilter }
      }
      if (localPropertyFilter.districtId && localPropertyFilter.districtId != null && localPropertyFilter.districtId != 'null') {
        this.selectedFiler.district = { id: localPropertyFilter.districtId, name: localPropertyFilter.frontRequestDistrictNameFilter }
      }
      if (localPropertyFilter.citieId && localPropertyFilter.citieId != null && localPropertyFilter.citieId != 'null') {
        this.selectedFiler.city = { id: localPropertyFilter.citieId, name: localPropertyFilter.frontRequestCityNameFilter }
      }
      if (localPropertyFilter.clientId && localPropertyFilter.clientId != null && localPropertyFilter.clientId != 'null') {
        this.selectedFiler.client = { id: localPropertyFilter.clientId, name: localPropertyFilter.frontRequestClientNameFilter }
      }
      if (localPropertyFilter.siteType && localPropertyFilter.siteType != null && localPropertyFilter.siteType != 'null') {
        this.selectedFiler.site = { id: localPropertyFilter.siteType, name: localPropertyFilter.frontRequestSiteTypeNameFilter }
      }
      this.selectedFiler.outletName = localPropertyFilter.outletName
      this.applyFlag = true;
    } else {
      body = {
        pageNo: 1,
        limit: this.rowCount,
        sortType: this.sortType,
        sortBy: this.sortBy,
        outletName: this.selectedFiler.property,
        isActive: 1,
        authId: this.apiService.authId,
        "roleId": this.apiService.roleId,
        "userId": this.apiService.userId,
        "businessId": this.apiService.buisnessId,
        "zoneId": this.selectedFiler.zone ? this.selectedFiler.zone.id : '',
        "stateId": this.selectedFiler.state ? this.selectedFiler.state.id : '',
        "clusterId": this.selectedFiler.cluster ? this.selectedFiler.cluster.id : '',
        "districtId": this.selectedFiler.district ? this.selectedFiler.district.id : '',
        "citieId": this.selectedFiler.city ? this.selectedFiler.city.id : '',
        "clientId": this.selectedFiler.client ? this.selectedFiler.client.id : '',
        "siteType": this.selectedFiler.site ? this.selectedFiler.site.id : '',
        "id": this.selectedFiler.property ? this.selectedFiler.property.id : '',
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontRequestZoneNameFilter: this.selectedFiler.zone ? this.selectedFiler.zone.name : "",
        frontRequestStateNameFilter: this.selectedFiler.state ? this.selectedFiler.state.name : "",
        frontRequestClusterNameFilter: this.selectedFiler.cluster ? this.selectedFiler.cluster.name : "",
        frontRequestDistrictNameFilter: this.selectedFiler.district ? this.selectedFiler.district.name : "",
        frontRequestCityNameFilter: this.selectedFiler.city ? this.selectedFiler.city.name : "",
        frontRequestClientNameFilter: this.selectedFiler.client ? this.selectedFiler.client.name : "",
        frontRequestSiteTypeNameFilter: this.selectedFiler.site ? this.selectedFiler.site.name : "",
      }
      localStorage.setItem('localPropertyFilter', JSON.stringify(body));
    }
    this.apiService.startLoader()
    this.apiService.postCall('outlets/index', {}, body).subscribe((result: any[]) => {
      // console.log("Property :", result);
      this.apiService.stopLoader()
      this.qrAssetList = result['data']
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrAssetList.length; i++) {
        this.qrAssetList[i]['selected'] = false
        this.qrAssetList[i]['assetName'] = this.qrAssetList[i]['outletName']
        this.qrAssetList[i]['QRCode'] = this.qrAssetList[i]['qRCode']
      }
    });
  }

  AllSelectedCheck = false
  assetsForPrintQr = []
  AllSelected(event, asset) {
    if (event.target.checked && asset == "All") {
      this.AllSelectedCheck = true
      for (var i = 0; i < this.qrAssetList.length; i++) {
        this.qrAssetList[i]['selected'] = true
      }
      this.assetsForPrintQr = this.qrAssetList
    } else if (event.target.checked) {
      // console.log("in" + event.target.checked);
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrAssetList.length; i++) {
        if (this.qrAssetList[i]['id'] == asset.id) {
          this.qrAssetList[i]['selected'] = true
        }
      }
      this.assetsForPrintQr.push(asset);
    } else if (!event.target.checked && asset == "All") {
      this.assetsForPrintQr = []
      this.AllSelectedCheck = false
      for (var i = 0; i < this.qrAssetList.length; i++) {
        this.qrAssetList[i]['selected'] = false
      }
    } else if (!event.target.checked) {
      this.AllSelectedCheck = false
      this.assetsForPrintQr = this.assetsForPrintQr.filter(item => item.id != asset.id);
      for (var i = 0; i < this.qrAssetList.length; i++) {
        if (this.qrAssetList[i]['id'] == asset.id) {
          this.qrAssetList[i]['selected'] = false
        }
      }
    }
  }
  gotoPrint() {
    this.storedData.selectedAssetsDetailsList = this.assetsForPrintQr
    localStorage.setItem("selectedPropertyDetailsList", JSON.stringify(this.assetsForPrintQr));
    window.open('/property-qr-html')
  }

}
