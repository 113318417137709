import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-part-transfer-view',
  templateUrl: './part-transfer-view.component.html',
  styleUrls: ['./part-transfer-view.component.css']
})
export class PartTransferViewComponent implements OnInit {

  constructor(
    public apiService: ApiService,
    public router: Router,
    public _location : Location
  ) { }
  transferDetails
  transferId
  ngOnInit(): void {
    this.transferId = this.router.url.split("/")[2]
    this.getDetails();
  }
  getDetails():void{
    this.apiService.startLoader()
    this.apiService.getCall('part-transfers/view/'+this.transferId, {}).subscribe((result: any[]) => {
      this.apiService.stopLoader()
      // console.log("vaulesss",result);
      if(result['success'] == true){
        this.transferDetails = result['data']; 
      }
    })
  }

  goBack():void{
    this.router.navigateByUrl('/parts-inventory');
    //this._location.back();
  }
}
