import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../common/services/apiCalls.service';
import { StoredData } from '../../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-lost-and-found-lost-article-view',
  templateUrl: './lost-article-view.component.html',
  styleUrls: ['./lost-article-view.component.css']
})
export class LostArticleViewComponent implements OnInit {
  addTicektForm: FormGroup;
  searchPromise2 = null;
  constructor(public _location: Location, public route: Router, public router: Router, private fb: FormBuilder, public apiService: ApiService, public storedData: StoredData,) {
    router.events.subscribe((val) => {
      clearTimeout(this.searchPromise2);
      this.searchPromise2 = setTimeout(() => {
        this.ngOnInit()
      }, 500)
    });

  }
  lostArticleId
  ngOnInit(): void {
    this.lostArticleId = this.router.url.split("/")[2]
    this.getTicektDetails()
  }
  lostArticleDetails: any
  getTicektDetails() {
    if (this.lostArticleId) {
      this.apiService.startLoader()
      this.apiService.getCall('LostArticleComplaints/view/' + this.lostArticleId, {}).subscribe((result: any[]) => {
        // console.log(result);
        this.apiService.stopLoader()
        this.lostArticleDetails = result['data'];
      })
    }
  }
  openEdit() {
    this.router.navigate(['/lost-article-edit/' + this.lostArticleId])
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
}






