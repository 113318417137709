import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-parts-consumed-reports',
  templateUrl: './parts-consumed-reports.component.html',
  styleUrls: ['./parts-consumed-reports.component.css']
})
export class PartsConsumedReportsComponent implements OnInit {
  constructor(private http: HttpClient, public _location: Location, private route: ActivatedRoute, public apiService: ApiService, public root: Router) { }

  businessId = 0
  outletId = 0

  getIds(ev) {
    this.selectedFiler.zoneId = ev.zoneId
    this.selectedFiler.cluserId = ev.clusterId
    this.selectedFiler.stateId = ev.stateId
    this.selectedFiler.cityId = ev.cityId
    this.selectedFiler.clientId = ev.clientId
    this.selectedFiler.property = ev.property
    if (ev.flag == 1) {
      this.clear()
    } else if (ev.flag == 0) {
      this.applyFilter()
    }

    // console.log(ev);

    // this.applyFilter();

  }
  fromDate: Date = new Date();
  toDate: Date = new Date();
  ngOnInit() {
    this.fromDate.setDate(this.fromDate.getDate() - (this.fromDate.getDate() - 1));
    this.toDate.setDate(this.toDate.getDate());
    this.selectedFiler.startDate = this.fromDate;
    this.selectedFiler.endDate = this.toDate;
    var partsPagination = localStorage.getItem('partsPagination');
    if (partsPagination) {
      this.activePage = parseInt(partsPagination);
    }
    if (this.apiService.roleId == 2 || this.apiService.roleId == 3) {
      this.businessId = this.apiService.buisnessId;
      if (this.apiService.roleId == 3) {
        this.outletId = this.apiService.outletId;
        this.getPropertyList(this.businessId, this.apiService.authId);
      } else {
        this.getPropertyList(this.businessId, 0);
      }
    }

    if (this.apiService.roleId == 1) {
      this.getPropertyList(0, 0);
    }
    this.partConsumeList();
  }


  selectedParts = []
  selectedPartsDetailsList = []
  paginationArray = []
  outletList = []
  activePage: any = 1
  pageSize = 10
  assetList: any;
  selectedFiler = { business: null, property: null, status: null, name: null, type: null, partNo: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, startDate: null, endDate: null, departmentId: null, isAdded: null, categoryType: null }
  sortType = "desc"
  sortBy = 'partId'
  partsList = []
  statusList = []

  applyFlag = false;

  jumpToOpen

  getArray(d) {
    let array = []
    for (let i = 1; i <= d; i++) array.push(i)

    return array
  }


  statusSelection: any;
  selectStatus(d) {
    // console.log(d)
    if (this.statusSelection != 'status' + d.id) this.statusSelection = 'status' + d.id
    else this.statusSelection = undefined

    // console.log(this.statusSelection)
  }

  message
  trPartId = 0;
  propertyList = []
  filterpropertyList = []
  departmentList = [];
  getPropertyList(bId, userId) {
    this.apiService.startLoader()

    let obj = {
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "businessId": this.apiService.buisnessId,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
      // "outletId":outletId,
    }
    this.apiService.postCall('outlets/outlet-list/' + this.apiService.buisnessId, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.propertyList = result['data']
      this.filterpropertyList = result['data']
      this.departmentList = result['departments'];
    })
  }

  partNoList = []
  filterpartNoList = []
  getPartsNoList() {
    this.apiService.startLoader()
    let obj = {
      "businessId": this.apiService.buisnessId,
      "status": 1,
      "roleId": this.apiService.roleId,
      "userId": this.apiService.userId,
      "propertyId": this.apiService.outletId ? this.apiService.outletId : 0,
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
    }
    this.apiService.postCall('parts/index', {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      this.partNoList = result['data']
      this.apiService.stopLoader()
      this.filterpartNoList = this.partNoList;

    })
  }
  filterPartNoSearch
  filterProperty(ev) {
    // console.log(ev)
    this.partNoList = this.filterpartNoList.filter((item) => {
      if (item.number != null) {
        return item.number.toLowerCase().includes(this.filterPartNoSearch.toLowerCase())
      }
    })
  }
  filterPropertySearch
  filterLocation(ev) {
    // console.log(ev)

    this.propertyList = this.filterpropertyList.filter((item) => {
      if (item.outletName != null) {
        return item.outletName.toLowerCase().includes(this.filterPropertySearch.toLowerCase())
      }
    }
    )

  }
  pagination(d) {
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.partsList = [];
      this.activePage = d
      localStorage.setItem('partsPagination', d);
      this.partConsumeList();
    }
  }
  changePage(ev) {
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    // console.log(this.pageSize)
    this.partConsumeList();
  }


  showFilter() {
    let ele = (<HTMLInputElement>document.getElementById('filterSection'))
    if (ele.style.display == "block") ele.style.display = "none"
    else ele.style.display = "block"
  }
  getFilterStatus() {
    let length = 0;
    if (this.selectedFiler.partNo != null) length = length + 1
    if (this.selectedFiler.name != null) length = length + 1
    if (this.selectedFiler.zoneId != null) length = length + 1
    if (this.selectedFiler.property != null) length = length + 1
    if (this.selectedFiler.cluserId != null) length = length + 1
    if (this.selectedFiler.stateId != null) length = length + 1
    if (this.selectedFiler.cityId != null) length = length + 1
    if (this.selectedFiler.clientId != null) length = length + 1
    if (this.selectedFiler.startDate != null) length = length + 1
    if (this.selectedFiler.endDate != null) length = length + 1
    if (this.selectedFiler.departmentId != null) length = length + 1
    if (this.selectedFiler.categoryType != null) length = length + 1
    if (this.selectedFiler.isAdded != null) length = length + 1
    if (length == 0 || !this.applyFlag) return "(None Selected)"
    else if (this.applyFlag) return `(${length} Filter Selected)`
  }

  selectAllAssets(ev) {
    this.selectedParts = []
    if (ev.target.checked) {
      for (let part of this.partsList) {
        this.selectedParts.push(part.partId)
        this.selectedPartsDetailsList.push(part)
      }
    } else {
      this.selectedParts = []
      this.selectedPartsDetailsList = []
    }
    // console.log(this.selectedParts)
  }

  selectAsset(ev, part, i) {

    this.partsList.map(x => x.selected = false)
    // console.log(ev.target.checked, part)

    this.partsList[i].selected = ev.target.checked;
    if (ev.target.checked) {
      // this.selectedParts.push(part.partId)
      // this.selectedPartsDetailsList.push(part)

      this.selectedParts[0] = part.partId
      this.selectedPartsDetailsList[0] = part.partId
    } else {
      this.selectedParts = []
      this.selectedPartsDetailsList = []
    }
    // console.log(this.selectedParts)
  }

  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []
    this.activePage = parseInt(this.activePage)
    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }
  applyFor = 0
  applyFilter() {
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    this.applyFlag = true;
    this.activePage = 1;
    this.applyFor = 3;
    let body = {}
    if (localStorage.getItem("localPartConsumeFilter") && localStorage.getItem("localPartConsumeFilter") != null && localStorage.getItem("localPartConsumeFilter") != 'null') {
      let localPartConsumeFilter = JSON.parse(localStorage.getItem("localPartConsumeFilter"));
      body = localPartConsumeFilter;
      if (localPartConsumeFilter.propertyId && localPartConsumeFilter.propertyId != null && localPartConsumeFilter.propertyId != 'null') {

        this.selectedFiler.property = { id: localPartConsumeFilter.propertyId, outletName: localPartConsumeFilter.frontOutletNameFilter }
      }
      this.selectedFiler.name ? localPartConsumeFilter.name : null
      this.selectedFiler.partNo ? localPartConsumeFilter.partNo : null
      this.selectedFiler.categoryType ? localPartConsumeFilter.categoryType : null
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
        startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
        endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate : this.toDate,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
        isAdded: this.selectedFiler.isAdded ? this.selectedFiler.isAdded : null,
        propertyId: this.selectedFiler.property ? parseInt(this.selectedFiler.property.id) : null,
        name: this.selectedFiler.name ? this.selectedFiler.name : null,
        categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
        roleId: this.apiService.roleId,
        userId: this.apiService.authId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localPartConsumeFilter', JSON.stringify(body));
    }
    let bId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      bId = this.selectedFiler.business.id;
    }
    this.partsList = []
    // console.log("Body", body);
    this.apiService.startLoader()
    this.apiService.postCall('part-consumes/index/' + bId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.partsList = result["data"];
      this.statusList = result['statusList'];
      this.setPaginationArray(result)
    });
  }

  partConsumeTabSelect() {
    this.activePage = 1;
    this.pageSize = 10;
    this.clear();
    this.partConsumeList();
  }

  clear() {
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    this.applyFor = 0
    this.activePage = 1
    this.selectedFiler = { business: null, property: null, status: null, name: null, type: null, partNo: null, zoneId: null, cluserId: null, stateId: null, cityId: null, clientId: null, startDate: this.fromDate, endDate: this.toDate, departmentId: null, isAdded: null,categoryType:null }
    this.filterSelect = false;
    this.partConsumeList();
  }

  sortData(data) {
    localStorage.removeItem('localPartConsumeFilter');
    localStorage.setItem("localPartConsumeFilter", null);
    this.sortBy = data
    if (this.sortType == 'asc') this.sortType = 'desc'
    else this.sortType = 'asc'
    this.partConsumeList();
  }

  getData() {
    this.partConsumeList();
  }
  partConsumeList(): void {
    let body = {}
    if (localStorage.getItem("localPartConsumeFilter") && localStorage.getItem("localPartConsumeFilter") != null && localStorage.getItem("localPartConsumeFilter") != 'null') {
      let localPartConsumeFilter = JSON.parse(localStorage.getItem("localPartConsumeFilter"));
      body = localPartConsumeFilter;
      if (localPartConsumeFilter.propertyId && localPartConsumeFilter.propertyId != null && localPartConsumeFilter.propertyId != 'null') {

        this.selectedFiler.property = { id: localPartConsumeFilter.propertyId, outletName: localPartConsumeFilter.frontOutletNameFilter }
      }
      this.selectedFiler.name ? localPartConsumeFilter.name : null
      this.selectedFiler.partNo ? localPartConsumeFilter.partNo : null
      this.applyFlag = true;
    } else {
      body = {
        pageNo: this.activePage,
        limit: this.pageSize,
        sortType: this.sortType,
        sortBy: this.sortBy,
        partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
        startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
        endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate : this.toDate,
        departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
        isAdded: this.selectedFiler.isAdded ? this.selectedFiler.isAdded : null,
        propertyId: this.selectedFiler.property ? parseInt(this.selectedFiler.property.id) : null,
        name: this.selectedFiler.name ? this.selectedFiler.name : null,
        roleId: this.apiService.roleId,
        userId: this.apiService.authId,
        "businessId": this.apiService.buisnessId,
        frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
        frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
        zoneId: this.selectedFiler.zoneId,
        cluserId: this.selectedFiler.cluserId,
        stateId: this.selectedFiler.stateId,
        cityId: this.selectedFiler.cityId,
        clientId: this.selectedFiler.clientId,
      };
      localStorage.setItem('localPartConsumeFilter', JSON.stringify(body));
    }
    let bId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      bId = this.selectedFiler.business.id;
    }
    this.partsList = []
    // console.log("Body", body);
    this.apiService.startLoader()
    this.apiService.postCall('part-consumes/index/' + bId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.partsList = result["data"];
      this.statusList = result['statusList'];
      this.setPaginationArray(result)
    })
  }
  bussnissList = []
  bussnissListFilter = []
  getBusinessList() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getBusinessPluck', {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.bussnissList = result['data']
      this.bussnissListFilter = result['data']
    })
  }
  showConsumeData
  showConsumesDatas(data) {
    this.showConsumeData = data;
  }
  filterSelect = false;
  businessFilter = false;
  propertyFiletr = false;
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.filterSelect = true;
      this.businessFilter = false;
      this.propertyFiletr = false;
    } else if (btn.id != 'custom') {
      this.filterSelect = false;
      this.businessFilter = false;
      this.propertyFiletr = false;
      this.dropdownShow = false;
    }
    if (btn.id == 'changeBUton') {
    } else if (btn.id != 'changeBUton') {
      this.statusSelection = undefined
    }
  }
  closeAll() {
  }
  dropdownShow = false;
  errorMessages = []
  goBack(): void {
    this._location.back();
  }
  downloadReport(): void {
    let body = {}
    body = {
      pageNo: this.activePage,
      limit: this.pageSize,
      sortType: this.sortType,
      sortBy: this.sortBy,
      partNo: this.selectedFiler.partNo ? this.selectedFiler.partNo : null,
      startDate: this.selectedFiler.startDate ? this.selectedFiler.startDate : this.fromDate,
      endDate: this.selectedFiler.endDate ? this.selectedFiler.endDate : this.toDate,
      departmentId: this.selectedFiler.departmentId ? this.selectedFiler.departmentId : null,
      isAdded: this.selectedFiler.isAdded ? this.selectedFiler.isAdded : null,
      propertyId: this.selectedFiler.property ? parseInt(this.selectedFiler.property.id) : null,
      name: this.selectedFiler.name ? this.selectedFiler.name : null,
      categoryType: this.selectedFiler.categoryType ? this.selectedFiler.categoryType : null,
      roleId: this.apiService.roleId,
      userId: this.apiService.authId,
      "businessId": this.apiService.buisnessId,
      frontOutletNameFilter: this.selectedFiler.property ? this.selectedFiler.property.outletName : "",
      frontStatusName: this.selectedFiler.status ? this.selectedFiler.status.name : "",
      zoneId: this.selectedFiler.zoneId,
      cluserId: this.selectedFiler.cluserId,
      stateId: this.selectedFiler.stateId,
      cityId: this.selectedFiler.cityId,
      clientId: this.selectedFiler.clientId,
      download: 1
    };
    let bId = this.apiService.buisnessId;
    if (this.selectedFiler.business) {
      bId = this.selectedFiler.business.id;
    }
    // console.log("Body", body);
    this.apiService.startLoader()
    this.apiService.postCall('part-consumes/index/' + bId, {}, body).subscribe((result: any[]) => {
      if (result['success'] == true) {
        const link = document.createElement('a');
        link.setAttribute('href', result['url']);
        link.setAttribute('download', `products.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      this.apiService.stopLoader()
    })
  }
}
