import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { StoredData } from '../../common/services/storedData.service';
import { ApiService } from '../../common/services/apiCalls.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-vendors',
  templateUrl: './edit-vendors.component.html',
  styleUrls: ['./edit-vendors.component.css']
})
export class EditVendorsComponent implements OnInit {
  @Input() vendors: any;
  @Input() partsStatus: any;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder, public storedData: StoredData, public apiService: ApiService,) { }

  propertyList = []
  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: false,
    dateType: 'start'
  }

  groups: []
  vendorForm = this.fb.group({
    id: [null],
    name: [null,Validators.required],
    email: [null,[Validators.required, Validators.email]],
    mobile: [null,[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
  });


  id = ""
  ngOnInit(): void {
    // console.log(this.vendors)
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
    const lengthOfCode = 5;
    this.id = this.makeRandom(lengthOfCode, possible);
  }
  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  message
  submit() {
    // console.log(this.vendorForm.value)
    let obj = {
      id: this.vendors.id,
      vendorName: this.vendorForm.value.name,
      authId: this.apiService.authId,
      email: this.vendorForm.value.email,
      mobile: this.vendorForm.value.mobile
    }
    // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('vendors/edit/' + this.vendors.id, {}, obj).subscribe((result: any[]) => {
      // console.log(result);
      document.getElementById('dismissPopUp').click()
      this.apiService.stopLoader()
      this.message = result["msg"];
      //(<HTMLInputElement>document.getElementById("openSuccess121")).click();
      //(<HTMLInputElement>document.getElementById(this.id)).click();
      // document.getElementById('dismissPopUp').click()
      this.updateData.emit();
    })

  }
  public contactmask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  ngOnChanges() {
    // console.log(this.vendors);
    if (this.vendors) {
      this.vendorForm.patchValue({
        authId: this.apiService.authId,
        name: this.vendors.name,
        email: this.vendors.email,
        mobile: this.vendors.mobile
      })
      // console.log(this.vendorForm)
    }
  }
  getValidation() {
    return (this.vendorForm.valid)
  }
}
