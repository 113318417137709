import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-question-detail',
  templateUrl: './employee-question-detail.component.html',
  styleUrls: ['./employee-question-detail.component.css']
})
export class EmployeeQuestionDetailComponent implements OnInit {

  constructor(public _location: Location, http: HttpClient, public route: Router, public router: Router, public apiService: ApiService) { }

  paginationArray = [];
  activePage = 1;
  pageSize = 10;
  showAddMenu = false
  showFilter = false;
  authId = this.apiService.authId;
  sortType = 'desc';
  // sortBy = 'id';

  search: any;
  selectedFiler = { buisness: null, property: null, survayName: null, type: null, status: null, email: null, userName: null }
  message
  feedbackArray = [];
  surveyId
  empCode
  qunAnsId
  ngOnInit(): void {
    this.surveyId = this.route.url.split("/")[2]
    this.empCode = this.route.url.split("/")[3]
    this.qunAnsId = this.route.url.split("/")[4]
    this.getSurveyList();
    if (this.authId == 5747 || this.authId == 5710 || this.authId == 5749 || this.authId == 5708) {

    } else {
      this.router.navigate(['/login-view'])
    }
  }


  clear() {
    this.selectedFiler = { buisness: null, property: null, survayName: null, type: null, status: null, email: null, userName: null }
    this.getSurveyList()
  }



  sortSelected: any = 'id';
  sortData(d) {
    this.sortSelected = d
    if (this.sortType == 'asc') this.sortType = 'desc';
    else this.sortType = 'asc'
    this.getSurveyList()
  }



  totalnoOfPages
  setPaginationArray(result) {
    // this.totalrowCount = result['rowCount']
    let noOfPAges = Math.ceil(result['rowCount'] / this.pageSize)
    this.totalnoOfPages = noOfPAges;
    this.paginationArray = []

    if (noOfPAges <= 5) for (let i = 1; i <= noOfPAges; i++)this.paginationArray.push(i)
    else if (4 + this.activePage < this.totalnoOfPages) {
      for (let i = this.activePage; i <= 4 + this.activePage; i++)this.paginationArray.push(i)
    }
    else if (4 + this.activePage >= 6) {

      let start = (4 + this.activePage) - noOfPAges
      for (let i = this.activePage - start; i <= noOfPAges; i++)this.paginationArray.push(i)
    }
    // console.log(this.paginationArray)
  }

  changePage() {
    // console.log(this.pageSize)
    this.getSurveyList();
  }

  pagination(d) {
    // console.log(d)
    if (d != 0 && d <= this.totalnoOfPages && this.activePage != d) {
      this.activePage = d
      // console.log(d)
      this.getSurveyList()
    }
  }

  downloadReportPDFFile() {
    this.showAddMenu = this.showAddMenu ? false : true
    const body = {
      "downloadReport": this.downloadflag
    };
    this.apiService.startLoader()
    this.apiService.postCall2('feedbacks/feedbackDetailsPdf/' + this.surveyId, {}, body).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['url']) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = result['url'];
        a.target = "_blank";
        a.download = "feedback-details.pdf";
        a.click();
        document.body.removeChild(a);
      }
      this.downloadflag = false
    })
  }
  feedbackDetail
  average: any;
  surveyName
  outletName

  serveyData
  getSurveyList() {


    this.apiService.startLoader()
    this.apiService.getCall('EmpSurveys/empAnswer/' + this.surveyId + '/' + this.empCode + '/' + this.qunAnsId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      if (result['success']) {
        this.feedbackArray = result['questions'];
        this.serveyData = result['surveyData'];
      }
      //this.setPaginationArray(result);
    })
  }

  downloadflag = false
  downloadReport() {
    this.downloadflag = true
    this.getSurveyList();
  }

  downloadReportPDF() {
    this.downloadflag = true
    this.downloadReportPDFFile();
  }

  businessFilter
  @HostListener('click', ['$event.target'])
  onClick(btn) {
    // console.log('button', btn.id, 'number of clicks:');
    if (btn.id == 'custom11') {
      this.showFilter = false
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
    }
    else if (btn.id != 'custom') {
      this.showFilter = false
      this.statsuFilter = false;
      this.typeFilter = false;
      this.businessFilter = false;
    }

  }
  typeFilter = false;
  statsuFilter = false;
  closeAll() {
    this.typeFilter = false;
    this.businessFilter = false;
  }

}
