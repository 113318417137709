import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/services/apiCalls.service';

@Component({
  selector: 'app-parts-inventory-header',
  templateUrl: './parts-inventory-header.component.html',
  styleUrls: ['./parts-inventory-header.component.css']
})
export class PartsInventoryHeaderComponent implements OnInit {

  @Input() partsDetails;
  @Input() meterId
  @Input() partsStatus
  @Output() updateData :EventEmitter<any> = new EventEmitter<any>();
  constructor(public apiService:ApiService,public router:Router,) { }

  changeImagePermission=false
  changeStatusPermission=false
  ngOnInit(): void {

    // let idx = this.partsStatus.findIndex(x=>x .name== "In Migration")
    // this.partsStatus.splice(idx,1)
    // let idx1 = this.partsStatus.findIndex(x=>x .name== "In Active")
    // this.partsStatus.splice(idx1,1)

    let idx2 = this.partsStatus.findIndex(x=>x.name== "Breakdown")
    this.partsStatus.splice(idx2,1)
    

    let permission = [];
    permission = JSON.parse(localStorage.getItem('permissions'));
    permission = permission.filter(item => item['permissionModuleId'] ==5 );
    // console.log(permission);
    for(var i=0;i<permission.length;i++){
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Change Image" && permission[i]['actionStatus'] == 1){
        this.changeImagePermission=true
      }
      if(permission[i]['subModuleName'] == "Parts & Inventory" && permission[i]['action'] == "Change Status" && permission[i]['actionStatus'] == 1){
        this.changeStatusPermission=true
      }
    }
  }
 
  uploadedFiles: any
  fileName: any;
  fileChange(event) {
    // // console.log(event)
    // let fileList: FileList = event.target.files;
    // if (fileList.length > 0) {
    //   let file: File = fileList[0];
    //   // console.log(file)
    //   this.uploadedFiles = file
    //   this.fileName = file.name
    // }
    this.uploadedFiles = event;
    let testData: FormData = new FormData();

    testData.append('image', this.uploadedFiles);
    testData.append("partId", this.partsDetails.partId);
    testData.append("authId", this.apiService.authId);
    // // console.log(obj)
    this.apiService.startLoader()
    this.apiService.postCall('parts/edit/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.updateData.emit()

    })

  }

  partsVendorName:any

  getVendorName(){
    if(this.partsDetails?.vendors && this.partsDetails?.vendors.length > 0){
      let vendors = this.partsDetails.vendors;
      if(this.partsDetails?.vendors.length > 1)this.partsVendorName = "Multiple"
      else this.partsVendorName = this.partsDetails.vendors[0].name
    }

    return this.partsVendorName
  }


  openCropperEvent=false;
  openCropper(){
    if(this.changeImagePermission){
      // console.log("assd");
      (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
      this.openCropperEvent= true;
    }
  }
  
  openCropperWithImage(){
    if(this.changeImagePermission){
    (<HTMLInputElement>document.getElementById('cropeerPopupSuccessWithImage')).click();
    }
  }

  editImage(){
    if(this.changeImagePermission){
    // console.log("assd");
    (<HTMLInputElement>document.getElementById('cropeerPopupSuccess')).click();
    this.openCropperEvent= true;
    }
  }


  message
  changeStatus() {
    let testData: FormData = new FormData();
    testData.append("status", this.partsDetails.status);
    testData.append("authId", this.apiService.authId);
    this.apiService.startLoader()
    this.apiService.postCall('parts/changeStatus/' + this.partsDetails.partId, {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.message = result["msg"];
      (<HTMLInputElement>document.getElementById('openSuccess3')).click();
      document.getElementById('dismissPopUp').click()

    })
  }
}
