import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-sub-user',
  templateUrl: './add-sub-user.component.html',
  styleUrls: ['./add-sub-user.component.css']
})
export class AddSubUserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  message
  erroMsg
  statusList:any;
}
