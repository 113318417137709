import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-business-add',
  templateUrl: './business-add.component.html',
  styleUrls: ['./business-add.component.css']
})
export class BusinessAddComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService: ApiService,
    public storedData: StoredData,
  ) { }
  businessForm: FormGroup
  saveData = false;
  message: any;
  businessId: any;
  minDate: Date;
  curencyList = [];
  regionList = [];
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.businessForm = this.fb.group({
      name: [null, Validators.required],
      isSingleProperty: [null],
      maxUsers: [null, Validators.required],
      maxProperties: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      city: [null, Validators.required],
      address: [null, Validators.required],
      description: [''],
      latLogStatus: [0],
      logo: [''],
      permitStartTime: [null],
      permitEndTime: [null],
      permitMobileNo: [null],
      permitUserId: [null],
      region: [null],
      cureency: [null],
    });
    this.getCurencys();
  }
  ngOnDestroy() {
    document.body.style.overflowY = 'scroll';
  }

  getValidation() {
    return (this.businessForm.valid && !this.validateDate(this.businessForm.value.startDate, this.businessForm.value.endDate));
  }

  submitBusinessForm(): void {
    let formData = this.businessForm.value;

    let testData: FormData = new FormData();
    if (this.uploadedFiles) {
      testData.append('logo', this.uploadedFiles);
    } else {
      testData.append('logo', "");
    }
    testData.append('name', this.businessForm.value.name);
    testData.append('maxUsers', this.businessForm.value.maxUsers);
    testData.append('city', this.businessForm.value.city);
    testData.append('address', this.businessForm.value.address);
    testData.append('description', this.businessForm.value.description);
    testData.append('latLogStatus', this.businessForm.value.latLogStatus);
    testData.append('userId', this.apiService.authId);
    if (formData.isSingleProperty == true) {
      testData.append('isSingleProperty', 1 + "");
      testData.append('maxProperties', 1 + "");
    } else {
      testData.append('isSingleProperty', 0 + "");
      testData.append('maxProperties', this.businessForm.value.maxProperties);
    }
    testData.append('startDate', moment(this.businessForm.value.startDate).format('YYYY-MM-DD'));
    testData.append('endDate', moment(this.businessForm.value.endDate).format('YYYY-MM-DD'));
    testData.append('permitStartTime', moment(this.businessForm.value.permitStartTime).format('HH:mm:ss'));
    testData.append('permitEndTime', moment(this.businessForm.value.permitEndTime).format('HH:mm:ss'));
    testData.append('permitMobileNo', this.businessForm.value.permitMobileNo);
    testData.append('permitUserId', this.businessForm.value.permitUserId);
    testData.append('region', this.businessForm.value.region);
    testData.append('cureency', this.businessForm.value.cureency);
    // console.log("FormData : ",testData);
    this.apiService.startLoader()
    this.apiService.postCall('/businesses/add', {}, testData).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.saveData = result['success'];
      this.message = result['msg'];
      this.businessId = result['businessId'];
      (<HTMLInputElement>document.getElementById('openSuccess')).click();
    });
  }
  goToIndex() {
    this.route.navigate(['/business-index']);
  }
  nextStep() {
    this.route.navigate(['/business-admin/' + this.businessId]);
  }

  validateDate(startDate, endDate) {
    // console.log(startDate , endDate)
    if (startDate && endDate) {
      if (endDate <= startDate) return true
      else return false
    }
  }

  changeMaxProperty() {
    if (this.businessForm.value.isSingleProperty) this.businessForm.patchValue({
      maxProperties: 1
    })
  }

  attchments = []
  uploadedFiles
  fileName
  fileSize: boolean
  fileChange(event) {
    this.fileSize = false
    // console.log(event)
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      // console.log('size', file.size);
      if (file.size < 5269767) {
        // console.log(file)
        file = file
        this.uploadedFiles = file;
        this.fileName = file.name
        this.attchments.push({ file: file, fileName: file.name })
      }
      else {
        this.fileSize = true
      }
    }
  }
  removeImage() {
    this.fileName = null;
    this.attchments = [];
  }
  getCurencys() {
    this.apiService.startLoader()
    this.apiService.getCall('businesses/getRegionCurrencys', {}).subscribe((result: any[]) => {

      if (result['success']) {
        this.regionList = result['data']['regions'];
        this.curencyList = result['data']['currency'];
      }
      this.apiService.stopLoader()
    })
  }
}