import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from '../common/services/apiCalls.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { StoredData } from '../common/services/storedData.service';

@Component({
  selector: 'app-amc-support-history',
  templateUrl: './amc-support-history.component.html',
  styleUrls: ['./amc-support-history.component.css']
})
export class AmcSupportHistoryComponent implements OnInit {
  // @Input() amcId:any
  // @Input() assetId:any
  constructor(private http: HttpClient, public route: Router,public apiService:ApiService , public storedData:StoredData) { }
  assetId
  amcId
  amcDetails
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.getOutlet()
    this.assetId = this.route.url.split("/")[2]; 
    this.amcId = this.route.url.split("/")[3]; 
    this.getAmcServiceList();
    this.getAssetDetail();
  }
  amcServiceListing:any;
  assetAMCDetails 
  assetDetails
  getAssetDetail() {
    this.apiService.startLoader()
    this.apiService.getCall('asset-managements/view/' + this.assetId, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.apiService.stopLoader()
      this.assetDetails = result['assetDetails'];
      this.assetAMCDetails = result['amcDetails'];

    })
  }

  unamcServiceListing=[]
  scheduleServiceListing=[]
  getAmcServiceList(): void {
		// console.log("getAmcServiceList call")
    this.apiService.startLoader()
		this.apiService.postCall('amc-services/index/'+this.assetId+'/'+this.amcId,{} ).subscribe((result: any[])=>{
      // console.log(result);
      this.apiService.stopLoader()
      this.amcServiceListing=[]
      this.amcServiceListing= result['data']
      
      this.unamcServiceListing = this.amcServiceListing.filter(item => item.schedule_unschedule ==0 );
      this.scheduleServiceListing =this.amcServiceListing.filter(item => item.schedule_unschedule ==1 );

      let jsonToBeUsed=[]
      for (var type in result['config']) {
        let item = {};
        item['key'] = parseInt(type);
        item['value'] = result['config'][type];
        jsonToBeUsed.push(item);
  
  
    }

    this.config = jsonToBeUsed
  



      // console.log(this.config);
    })
	}

  getDate(d)
  {
    let pattern = 'YYYY-MM-DDTHH:mm:ss'
    return moment(d ,pattern).utc(false)
  }
getReason(id){
  // // console.log(id)
 return this.reasonList.find(x => x.id == id).name
}

getUserName(user){
  let string=[]
  for(let d of user)  string.push(d.name)

  return (string).toString()
}

getCost(d){
  return d.price * d.quentity
}



getTotalCost(data){
let total=0
if(data.partsRepaired)for(let d of data.partsRepaired) total = total + d.quentity*d.price
if(data.partsReplaced)for(let d of data.partsReplaced) total = total + d.quentity*d.price

return total
}

reasonList

getOutlet(){
  this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
    // this.apiService.getCall('outlets/outlet-list/'+this.apiService.buisnessId,{}  ).subscribe((result: any[])=>{
      // console.log(result);
      result['data']
      this.reasonList = result['reasonServices']
      this.apiService.stopLoader()
    
  })
}

getFileName(name){
  // // console.log(name)
  let name1 = name.split("/")
  if(name) return name1[name1.length-1];
 }

 getFileType(name){

  let name1 = name.split("/")

  let lastSTring = name1[name1.length-1];
  let type = lastSTring.split(".")

  // console.log(type)
  return type[type.length-1]
 }
 goToLink(url: string){
  window.open(url, "_blank");
}

goBack(){
  this.route.navigate(['/asset-details/'+this.assetId])
}
  config


  calculateDiff(sentDate , endDate) {
    var date1:any = new Date(sentDate);
    var date2:any = new Date(endDate);
    var diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    if(diffDays==0){
      const diffInMs = Date.parse(date2) - Date.parse(date1);
      const diffInHours = diffInMs / 1000 / 60 / 60;
      return Math.floor(diffInHours)+' Hrs';
    }
    return diffDays+ ' days';
}

getServiceLeft(){
  
  if(this.amcServiceListing && this.amcServiceListing[0] && this.assetAMCDetails){
  return  this.assetAMCDetails.scheduledService -  this.amcServiceListing.length 
  }else if(!this.amcServiceListing){
    return this.assetAMCDetails ? this.assetAMCDetails.scheduledService:  0;
  }else return 0
}

selectedAMC
editAmc(){
  // // console.log(d)
  // this.storedData.amcDetails = d
  // this.route.navigate(['/amc-services/amcServiceDelete/'+d.id])
  this.apiService.startLoader()
    this.apiService.deletFile('amc-services/amcServiceDelete/' + this.selectedAMC, {}).subscribe((result: any[]) => {
      // console.log(result);
      this.getAmcServiceList()
    })
}

}
