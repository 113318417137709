import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../common/services/apiCalls.service';
import { StoredData } from '../common/services/storedData.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-business-add',
  templateUrl: './business-delete.component.html',
  styleUrls: ['./business-delete.component.css']
})
export class BusinessDeleteComponent implements OnInit {

  constructor(
    public _location: Location,
    public route: Router,
    private fb: FormBuilder,
    public apiService:ApiService,
    public storedData:StoredData,
  ) { }
  businessForm: FormGroup
  saveData=false;
  message:any;
  businessId:any;
  minDate: Date;
  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    this.businessForm = this.fb.group({
      businessId:[null , Validators.required],
    });
    this.getBusinesses();
  }
  ngOnDestroy(){
    document.body.style.overflowY = 'scroll';
  }

  getValidation(){
    return (this.businessForm.valid);
  }
  businessList = [];
  getBusinesses(){
    this.apiService.startLoader()
    this.apiService.getCall('businesses/businessList',{}  ).subscribe((result: any[])=>{
      this.apiService.stopLoader();
      this.businessList = result['businessList'];
    })
  }
  submitBusinessForm():void{
    let formData = this.businessForm.value;
    
    let testData: FormData = new FormData();
    testData.append('businessId', this.businessForm.value.businessId);
    // console.log("FormData : ",testData);
      this.apiService.startLoader()
      this.apiService.postCall('/businesses/deleteAll', {}, testData).subscribe((result: any[]) => {
        // console.log(result);
        this.businessId = null;
        this.businessForm = this.fb.group({
          businessId:[null , Validators.required],
        });
        this.getBusinesses();
        this.apiService.stopLoader();
        this.saveData = result['success'];
        this.message = result['msg'];
        (<HTMLInputElement>document.getElementById('openSuccess')).click();
      });
  }
  goToIndex(){
    this.route.navigate(['/business-index']);
  }
}